import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { replaceLogo } from '@APICalls/lockerManager/actions';
import { lockerMgrS3Logos } from '@constants/common';
import { decorationMethodsOptions } from '@constants/options/optionsValues';
import {
  fetchAllLockerProducts,
  fetchUsedLogos,
  fetchLockerFloodColors,
} from '@redux/lockerManager/actions';
import { materialSwal } from '@util/componentHelper';
import { fetchColors } from 'redux/support/actions';
import {
  selectItem,
  selectAllItems,
  isSelectedItem,
} from '@util/selectionHelpers';
import ImagePreviewCell from '@sharedComponents/Table/TableCells/ImagePreviewCell';
import TitleDescriptionCell from '@sharedComponents/LockerItems/LockerItemsTableContent/TitleDescriptionCell';
import MultiSelectDropdown from '@sharedComponents/Inputs/Dropdowns/MultiSelectDropdown/MultiSelectDropdown';
import SelectCell from '@sharedComponents/Table/TableCells/SelectCell';
import SelectAllCell from '@sharedComponents/Table/TableCells/SelectAllCell';
import Modal from '@sharedComponents/Modal/Modal';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import ImageModal from '@sharedComponents/Modal/ImageModal';
import Table from '@sharedComponents/Table/Table';
import Icon from '@sharedComponents/Icons/Icon';
import LogoDropdownWithImage from '@sharedComponents/LogoDisplay/LogoDropdown/LogoDropdownWithImage';
import ItemLocationAndSizeCell from '@sharedComponents/LockerItems/LockerItemsTableContent/ItemLocationAndSizeCell';
import { productTypeEnum } from '@constants/enums/lockerEnums';

const ProductsTable = Table();

class LogoBankReplaceLogoModal extends Component {
  state = {
    selectedLogo: null,
    selectedItems: [],
    selectAll: false,
    decorations: [],
    floodColorsFilter: [],
    imageModalIsOpen: false,
    imageUrl: '',
    lockerFloodColors: this.props.colors?.filter((c) => (this.props.lockerFloodColorCodes || [])?.includes(c.code)),
  };

  componentDidMount() {
    const {
      dispatch,
      lockerId,
    } = this.props;

    dispatch(fetchLockerFloodColors(lockerId));
    dispatch(fetchColors());
    this.search();
  }

  componentDidUpdate(prevProps) {
    const {
      logo,
      isOpen,
      colors,
      lockerFloodColorCodes,
    } = this.props;

    if ((isOpen && !prevProps.isOpen) || (logo && prevProps.logo !== logo)) {
      this.search();
    }

    if (colors
      && lockerFloodColorCodes
      && (prevProps.lockerFloodColorCodes !== lockerFloodColorCodes
        || prevProps.colors !== colors)) {
      const lockerFloodColors = colors.filter((c) => lockerFloodColorCodes.includes(c.code));
      this.setState(() => ({ lockerFloodColors }));
    }
  }

  logoChange = (logo) => {
    const { lockerLogos } = this.props;

    if (logo.id) {
      const selectedLogo = lockerLogos.find((l) => l.id === logo.id);
      this.setState(() => ({ selectedLogo }));
    }
  };

  confirm = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    const {
      selectedLogo,
      selectedItems,
    } = this.state;

    const {
      logo,
      lockerId,
      dispatch,
    } = this.props;

    const logoIdToRemove = logo.id;

    const logoIdToAdd = selectedLogo.id;
    const cssIds = selectedItems.map((p) => p.id);

    const res = await replaceLogo(lockerId, logoIdToRemove, logoIdToAdd, cssIds);
    if (res?.success) {
      materialSwal('Success', 'Logo replacement was successful!', 'success');
      this.onCloseModal(e);
      dispatch(fetchUsedLogos(lockerId, '', ''));
    }
  };

  selectItem = (item, isSelected) => {
    const {
      selectedItems,
      selectAll,
    } = this.state;

    const { products } = this.props;

    const {
      newSelectedItems,
      newSelectAll,
    } = selectItem(products, selectedItems, selectAll, item, isSelected, 'id');

    this.setState(() => ({
      selectedItems: newSelectedItems,
      selectAll: newSelectAll,
    }));
  };

  selectAllItems = () => {
    const { products } = this.props;
    const {
      selectAll,
      selectedItems,
    } = this.state;

    const {
      newSelectedItems,
      newSelectAll,
    } = selectAllItems(products, selectedItems, selectAll, 'id');

    this.setState(() => ({
      selectedItems: newSelectedItems,
      selectAll: newSelectAll,
    }));
  };

  colorChange = (colors) => {
    if (colors) {
      this.setState(() => ({
        floodColorsFilter: colors,
      }), this.search);
    }
  };

  decorationChange = (decorations) => {
    if (decorations) {
      this.setState(() => ({
        decorations,
      }), this.search);
    }
  };

  search = () => {
    const {
      dispatch,
      lockerId,
      logo,
    } = this.props;

    const {
      floodColorsFilter,
      decorations,
    } = this.state;

    return dispatch(fetchAllLockerProducts(
      lockerId,
      [logo.id],
      [],
      decorations,
      '',
      floodColorsFilter,
      true,
      productTypeEnum.Css
    ));
  };

  openModalWithImage = (imageUrl) => {
    this.setState(() => ({
      imageModalIsOpen: true,
      imageUrl,
    }));
  };

  closeImageModal = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.setState(() => ({
      imageModalIsOpen: false,
      imageUrl: '',
    }));
  };

  initializeModal = () => {
    this.setState(() => ({
      selectedLogo: null,
      selectedItems: [],
      selectAll: false,
      decorations: [],
      floodColorsFilter: [],
      imageModalIsOpen: false,
      imageUrl: '',
    }));
  };

  onCloseModal = (e) => {
    this.initializeModal();
    const { closeModal } = this.props;
    closeModal(e);
  };

  getColumns = () => {
    const {
      selectAll,
      selectedItems,
    } = this.state;

    const columns = [
      {
        Header: () => (
          <SelectAllCell
            isSelected={selectAll}
            selectAllItems={this.selectAllItems}
          />
        ),
        width: 60,
        accessor: '',
        Cell: (cellProps) => (
          <SelectCell
            select={this.selectItem}
            item={cellProps.value}
            isSelected={isSelectedItem(selectedItems, cellProps.value, 'id')}
          />
        ),
      },
      {
        Header: 'Image',
        accessor: 'cached_image',
        className: 'no-padding',
        width: 120,
        Cell: (cellProps) => (
          <ImagePreviewCell
            imageUrl={cellProps.value}
            openModalWithUrl={this.openModalWithImage}
          />
        ),
      },
      {
        Header: 'Code & Description',
        accessor: '',
        width: 350,
        Cell: (cellProps) => (
          <div className='logo-bank__assignment--code'>
            <TitleDescriptionCell item={cellProps.value} />
          </div>
        ),
      },
      {
        Header: 'Garment Color',
        accessor: 'color',
        width: 150,
      },
      {
        Header: 'Flood Color',
        accessor: 'flood_color',
        width: 150,
      },
      {
        Header: 'Decoration',
        accessor: 'decoration_method',
        width: 150,
      },
      {
        Header: 'Location & Size',
        accessor: '',
        Cell: (cellProps) => <ItemLocationAndSizeCell item={cellProps.value} />,
      },
    ];

    return columns;
  };

  render() {
    const {
      isOpen,
      logo,
      lockerLogos,
      products,
    } = this.props;

    const {
      selectedItems,
      selectedLogo,
      imageUrl,
      imageModalIsOpen,
      lockerFloodColors,
    } = this.state;

    const logoImage = logo.image;
    const columns = this.getColumns();

    if (!logo) {
      return null;
    }

    const confirmDisabled =
      !selectedLogo || !selectedLogo.id || selectedLogo.id === logo.id || (selectedItems.length === 0);

    return (
      <Modal
        title={'Replace Logo on Items'}
        modalSize={'xl'}
        isOpen={isOpen}
        closeModal={this.onCloseModal}
        buttons={(
          <ModalButtons
            confirmBtnText={'Confirm'}
            cancelBtnText={'Cancel'}
            onConfirm={this.confirm}
            onClose={this.onCloseModal}
            confirmBtnDisabled={confirmDisabled}
          />
        )}
      >
        <ImageModal
          isOpen={imageModalIsOpen}
          closeModal={this.closeImageModal}
          imageUrl={imageUrl}
        />
        <div>
          <div className='logo-bank__replace-logo--logos mt-0'>
            <div className='original-logo'>
              <span>Original logo</span>
              <div className='original-logo__image-wrapper'>
                <div
                  className='original-logo__image'
                  style={{ backgroundImage: `url("${lockerMgrS3Logos}/${logoImage}")` }}
                />
              </div>
            </div>
            <Icon materialIcon={'autorenew'} />
            <div className='replacement-logo'>
              <span>Replacement logo</span>
              <LogoDropdownWithImage
                logos={lockerLogos}
                updateCallback={this.logoChange}
              />
            </div>
          </div>
          <div className='logo-bank__filters'>
            <div className='logo-bank__replace-logo--logos'>
              <span>Items affected ({selectedItems.length})</span>
            </div>
            <div className='flex'>
              <MultiSelectDropdown
                objects={lockerFloodColors}
                itemText={'flood colors'}
                updateCallback={this.colorChange}
                textKey={'code'}
                valueKey={'code'}
                color={true}
              />
              <MultiSelectDropdown
                objects={decorationMethodsOptions}
                itemText={'decorations'}
                updateCallback={this.decorationChange}
                textKey={'name'}
                valueKey={'value'}
                classNames={'margin-left'}
              />
            </div>
          </div>
          <div className='logo-bank__replace-logo--table custom-scrollbar'>
            <ProductsTable
              data={products}
              columns={columns}
              showPagination={false}
              defaultPageSize={-1}
              classNames={'logo-bank__replace-logo--products'}
            />
          </div>
        </div>
      </Modal>
    );
  }
}

LogoBankReplaceLogoModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  lockerId: PropTypes.number.isRequired,
  logo: PropTypes.object.isRequired,
  lockerLogos: PropTypes.array.isRequired,
  products: PropTypes.array.isRequired,
  colors: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    hexValue: PropTypes.string,
    brightness: PropTypes.string.isRequired,
  })).isRequired,
  lockerFloodColorCodes: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const mapStateToProps = ({
  lockerManager,
  support,
}) => ({
  lockerLogos: lockerManager.logoBankLockerLogos,
  products: lockerManager.allLockerProducts.filter((product) => !product.is_locker_item),
  colors: support.colors,
  lockerFloodColorCodes: lockerManager.lockerFloodColors,
});

export default connect(mapStateToProps)(LogoBankReplaceLogoModal);
