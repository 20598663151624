import React, { useMemo } from 'react';
import Grid from '@material-ui/core/Grid';
import { Box, makeStyles, Typography } from '@material-ui/core';

interface UploadErrorsProps {
  data: any;
}

const useStyles = makeStyles(() => ({
  errors: {
    color: 'red',
  },
}));

const UploadErrors: React.FC<UploadErrorsProps> = ({ data }) => {
  const classes = useStyles();
  const reformattedErrors = useMemo(() => {
    const keys = Object.keys(data);
    const errors: { orgs: any; stores: any } = {
      orgs: {},
      stores: {},
    };
    keys.forEach((key) => {
      if (key.startsWith('orgs')) {
        if (key === 'orgs') {
          errors.orgs['global'] = data[key];
        } else {
          const rowNumber = key.replace('orgs ', '');
          errors.orgs[rowNumber] = data[key];
        }
      }
      if (key.startsWith('stores')) {
        if (key === 'stores') {
          errors.stores['global'] = data[key];
        } else {
          const rowNumber = key.replace('stores ', '');
          errors.stores[rowNumber] = data[key];
        }
      }
    });

    return errors;
  }, [data]);

  return (
    <Box p={2}>
      <Grid container={true} spacing={2}>
        <Grid item={true} xs={12}>
          <Typography variant='h5' color='error'>Please fix these errors and upload the file again.</Typography>
        </Grid>
        <Grid item={true} xs={12}>
          <pre className={classes.errors}>{JSON.stringify(reformattedErrors, null, 2)}</pre>
        </Grid>
      </Grid>
    </Box>
  );
};

export default UploadErrors;
