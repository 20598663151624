/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */
import type { ArtworkTableByStatusDto } from './artworkTableByStatusDto';

export interface ArtworkTableByStatusDtoPagedList {
  pageNumber?: number;
  pageSize?: number;
  totalCount?: number;
  items?: ArtworkTableByStatusDto[] | null;
  readonly totalPages?: number;
  readonly hasPreviousPage?: boolean;
  readonly hasNextPage?: boolean;
}
