/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */

export interface CalculatePriceChangeForPersonalizationUpdateCommandObsolete {
  orderNumber?: number;
  orderItemIds?: number[] | null;
  personalizationIds?: number[] | null;
  decorationLocation?: string | null;
  text?: string | null;
  number?: string | null;
  outlineColorId?: number | null;
  colorId?: number;
  decorationHeight?: number | null;
  personalizationBasePrice?: number | null;
}
