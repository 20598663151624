/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Catalog Homefield API
 * OpenAPI spec version: v1
 */
import type { ProductAgeEnum } from './productAgeEnum';
import type { ProductGenderEnum } from './productGenderEnum';

export type GetHomefieldApiInventoryOnhandinventoryParams = { pageNumber?: number; pageSize?: number; search?: string; brandId?: number[]; categoryId?: number[]; sportId?: number[]; color?: string[]; size?: string[]; age?: ProductAgeEnum[]; gender?: ProductGenderEnum[] };
