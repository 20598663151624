/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Catalog Homefield API
 * OpenAPI spec version: v1
 */

export type SetupStatusEnum = 'Initial' | 'QualityCheck' | 'Completed';


export const SetupStatusEnum = {
  Initial: 'Initial' as SetupStatusEnum,
  QualityCheck: 'QualityCheck' as SetupStatusEnum,
  Completed: 'Completed' as SetupStatusEnum,
};
