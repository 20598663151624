import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Icon from '@sharedComponents/Icons/Icon';
import ImagePreviewCell from '@sharedComponents/Table/TableCells/ImagePreviewCell';
import ItemInfoModal from '@sharedComponents/ItemInfo/ItemInfoModal';

class ItemImage extends Component {
  state = {
    itemInfoModalIsOpen: false,
    imageUrl: '',
  };

  openItemInfoModal = (imageUrl) => {
    this.setState({
      itemInfoModalIsOpen: true,
      imageUrl,
    });
  };

  closeItemInfoModal = () => {
    this.setState({
      itemInfoModalIsOpen: false,
      imageUrl: '',
    });
  };

  render() {
    const { itemInfoModalIsOpen } = this.state;

    const {
      item,
      resolvedUrl,
      productsRefreshing = [],
    } = this.props;

    if (!item) return null;

    const hasUnapprovedLogos = (item.logos || []).filter((l) => !l.artwork_approved).length > 0;
    const { id } = item;
    let img = (
      <ImagePreviewCell
        imageUrl={item?.isCustomItem && item?.imageFront ? item.imageFront : (resolvedUrl || item.cached_image)}
        openModalWithUrl={this.openItemInfoModal}
        openInfoModal={true}
      />
    );

    if (!resolvedUrl && productsRefreshing.indexOf(id) >= 0) {
      img = <ImagePreviewCell noImageText={'Refreshing'} />;
    }

    const lockerItemId = item.isLockerItem ? id : null;

    return (
      <div className='item__warning'>
        <ItemInfoModal
          isOpen={itemInfoModalIsOpen}
          closeModal={this.closeItemInfoModal}
          coloredStyleItemId={item.coloredStyleId}
          lockerItemId={lockerItemId}
        />
        {img}
        <div className='flex'>
          {
            hasUnapprovedLogos &&
            <div className='material-icons-container flex'>
              <div className='position-absolute'>
                <div className='tooltip-container'>
                  <div>
                    <Icon
                      materialIcon={'warning'}
                      classes={'item__warning--icon'}
                    />
                  </div>
                  <div className='tooltip-text'>Item contains unapproved logo(s)</div>
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    );
  }
}

ItemImage.propTypes = {
  item: PropTypes.object.isRequired,
  resolvedUrl: PropTypes.string,
  productsRefreshing: PropTypes.array,
};

export default ItemImage;
