/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */
import type { DecorationStatusEnumStatusModel } from './decorationStatusEnumStatusModel';
import type { FlagDto } from './flagDto';

export interface LogoItemDto {
  id?: number;
  decorationMethod?: string | null;
  decorationLocation?: string | null;
  decorationWidth?: number | null;
  decorationHeight?: number | null;
  status?: DecorationStatusEnumStatusModel;
  barcode?: string | null;
  files?: string[] | null;
  logoId?: number | null;
  logoUrl?: string | null;
  previewUrl?: string | null;
  imageUrl?: string | null;
  artworkTaskId?: number | null;
  environmentName?: string | null;
  pretreatmentPercent?: number | null;
  dtgConfigurationWidth?: number;
  dtgConfigurationHeight?: number;
  flag?: FlagDto;
}
