/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */

export interface OrderDto {
  orderNumber?: number;
  isCanceled?: boolean;
  isPaused?: boolean;
  isRushed?: boolean;
  isFlagged?: boolean;
  isExpressProduction?: boolean;
}
