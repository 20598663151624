/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Accounts Homefield API
 * OpenAPI spec version: 2.0
 */

export interface CreateGraphicsForTeamsCommand {
  uploadContentType?: string | null;
  uploadImageExtension?: string | null;
  s3Folder?: string | null;
  jobId?: number;
}
