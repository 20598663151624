/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */
import type { CouponTypeEnum } from './couponTypeEnum';

export interface ApplyCouponToOrderCommand {
  orderNumber?: number;
  orderItemId?: number | null;
  couponValue?: number;
  couponType?: CouponTypeEnum;
  couponCode?: string | null;
  couponAppliesToShipping?: boolean;
  reason?: string | null;
}
