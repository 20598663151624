import React, { useCallback, useState } from 'react';
import LinesEllipsis from 'react-lines-ellipsis';

const ShowMoreText = ({ anchorClass, lines, moreLabel, lessLabel, children }) => {
  const [expanded, setExpanded] = useState(false);
  const [maxLines, setMaxLines] = useState(lines);

  const toggleLines = useCallback((event) => {
    event.preventDefault();
    setExpanded(!expanded);
    setMaxLines(!expanded ? 10 : lines);
  }, [expanded, lines]);

  return (
    <>
      <LinesEllipsis
        text={children}
        maxLine={maxLines}
        ellipsis={(
          <span>
            ...
            <a
              href='#'
              className={anchorClass}
              onClick={toggleLines}
            >
              {moreLabel || 'Show more'}
            </a>
          </span>
        )}
        trimRight={true}
        basedOn='letters'
      />
      {expanded && (
        <a
          href='#'
          className={anchorClass}
          onClick={toggleLines}
        >
          {lessLabel || 'Show less'}
        </a>
      )}
    </>
  );
};

export default ShowMoreText;
