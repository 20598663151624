import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reset } from 'redux-form';
import {
  addDecorationLocation,
  editDecorationLocation,
  deleteDecorationLocation,
} from '@APICalls/decorationLocation/actions';
import { decorationLocationForm } from '@constants/reduxForms';
import { keyNameEnum } from '@constants/enums/commonEnums';
import { getDecorationLocations } from '@redux/productCatalog/actions';
import { parseDateTimeNumeric } from '@util/dateHandler';
import { materialSwal } from '@util/componentHelper';
import Button from '@sharedComponents/Buttons/Button';
import Table from '@sharedComponents/Table/Table';
import SearchFilter from '@sharedComponents/Inputs/SearchFilter';
import DecorationLocationsActionsColumn from './DecorationLocationsActionsColumn';
import DecorationLocationAddModal from './DecorationLocationModals/DecorationLocationAddModal';
import DecorationLocationEditModal from './DecorationLocationModals/DecorationLocationEditModal';
import DecorationLocationDeleteModal from './DecorationLocationModals/DecorationLocationDeleteModal';

const DecorationLocationsTable = Table();

class DecorationLocations extends Component {
  state = {
    addDecorationLocationModalIsOpened: false,
    editDecorationLocationModalIsOpened: false,
    deleteDecorationLocationModalIsOpened: false,
    selectedDecorationLocation: null,
    filteredData: this.props.queue,
  };

  componentDidMount() {
    this.fetchDecorationLocations();
  }

  componentDidUpdate(oldProps) {
    const { queue } = this.props;

    if (oldProps.queue !== queue) {
      this.setState({ filteredData: this.filterData() });
    }
  }

  getColumns = () => [
    {
      Header: 'ID',
      accessor: 'id',
      minWidth: 20,
      sortable: true,
      Cell: (cellProps) => cellProps.value && <span>{cellProps.value}</span>,
    },
    {
      Header: 'Created',
      accessor: 'created',
      minWidth: 100,
      Cell: (cellProps) => cellProps.value && <div>{parseDateTimeNumeric(cellProps.value)}</div>,
    },
    {
      Header: 'Updated',
      accessor: 'updated',
      minWidth: 100,
      Cell: (cellProps) => cellProps.value && <div>{parseDateTimeNumeric(cellProps.value)}</div>,
    },
    {
      Header: 'Code',
      accessor: 'code',
      minWidth: 100,
      Cell: (cellProps) => cellProps.value && <span>{cellProps.value}</span>,
    },
    {
      Header: 'Label',
      accessor: 'label',
      minWidth: 100,
      Cell: (cellProps) => cellProps.value && <span>{cellProps.value}</span>,
    },
    {
      Header: '',
      accessor: '',
      minWidth: 40,
      resizable: false,
      style: { overflow: 'visible' },
      Cell: (cellProps) => (
        <DecorationLocationsActionsColumn
          decorationLocation={cellProps.value}
          onDecorationLocationEdit={this.openEditDecorationLocationModal}
          onDecorationLocationDelete={this.openDeleteDecorationLocationModal}
        />
      ),
    },
  ];

  fetchDecorationLocations = () => {
    const { dispatch } = this.props;

    dispatch(getDecorationLocations());
  };

  filterData = (searchInput) => {
    const search = (searchInput || '').toLowerCase();
    const { queue } = this.props;

    if (search === '') {
      return queue;
    }

    return queue.filter((location) => location.code.toLowerCase().includes(search)
      || location.label.toLowerCase().includes(search));
  };

  clearSearch = () => {
    this.setState({ filteredData: this.filterData('') });
  };

  onSearch = (e) => {
    if (e.key && e.key !== keyNameEnum.Enter) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();

    const searchInput = e.target.value;

    this.setState(() => ({ filteredData: this.filterData(searchInput) }));
  };

  addDecorationLocation = async (form) => {
    const result = await addDecorationLocation(form);

    if (result?.success) {
      materialSwal('Success', result.message, 'success');
      this.closeAddDecorationLocationModal();
      this.fetchDecorationLocations();
    }
  };

  editDecorationLocation = async (form) => {
    const result = await editDecorationLocation(form);

    if (result?.success) {
      materialSwal('Success', result.message, 'success');
      this.closeEditDecorationLocationModal();
      this.fetchDecorationLocations();
    }
  };

  deleteDecorationLocation = async (decorationLocationId) => {
    const result = await deleteDecorationLocation(decorationLocationId);

    if (result?.success) {
      materialSwal('Success', result.message, 'success');
      this.closeDeleteDecorationLocationModal();
      this.fetchDecorationLocations();
    }
  };

  openAddDecorationLocationModal = () => {
    this.setState({ addDecorationLocationModalIsOpened: true });
  };

  closeAddDecorationLocationModal = () => {
    const { dispatch } = this.props;

    dispatch(reset(decorationLocationForm));
    this.setState({ addDecorationLocationModalIsOpened: false });
  };

  openEditDecorationLocationModal = (decorationLocation) => {
    this.setState({
      selectedDecorationLocation: decorationLocation,
      editDecorationLocationModalIsOpened: true,
    });
  };

  closeEditDecorationLocationModal = () => {
    const { dispatch } = this.props;

    dispatch(reset(decorationLocationForm));
    this.setState({
      editDecorationLocationModalIsOpened: false,
      selectedDecorationLocation: null,
    });
  };

  openDeleteDecorationLocationModal = (decorationLocation) => {
    this.setState({
      selectedDecorationLocation: decorationLocation,
      deleteDecorationLocationModalIsOpened: true,
    });
  };

  closeDeleteDecorationLocationModal = () => {
    this.setState({
      deleteDecorationLocationModalIsOpened: false,
      selectedDecorationLocation: null,
    });
  };

  render() {
    const {
      selectedDecorationLocation,
      addDecorationLocationModalIsOpened,
      editDecorationLocationModalIsOpened,
      deleteDecorationLocationModalIsOpened,
      filteredData,
    } = this.state;
    const initialValues = !selectedDecorationLocation ? {} : selectedDecorationLocation;

    return (
      <div className='container'>
        <DecorationLocationAddModal
          isOpen={addDecorationLocationModalIsOpened}
          closeModal={this.closeAddDecorationLocationModal}
          onSubmit={this.addDecorationLocation}
        />

        <DecorationLocationEditModal
          isOpen={editDecorationLocationModalIsOpened}
          closeModal={this.closeEditDecorationLocationModal}
          onSubmit={this.editDecorationLocation}
          initialValues={initialValues}
        />

        <DecorationLocationDeleteModal
          isOpen={deleteDecorationLocationModalIsOpened}
          closeModal={this.closeDeleteDecorationLocationModal}
          deleteDecorationLocation={this.deleteDecorationLocation}
          decorationLocation={initialValues}
        />

        <div className='table-options w-100'>
          <div className='flex'>
            <SearchFilter
              search={this.onSearch}
              clearSearch={this.clearSearch}
            />
          </div>
          <div className='flex'>
            <Button
              type={'primary'}
              text={'Add Decoration Location'}
              onClick={this.openAddDecorationLocationModal}
              classes={'colors__add-btn'}
            />
          </div>
        </div>
        <div className='sheet'>
          <DecorationLocationsTable
            data={filteredData}
            columns={this.getColumns()}
            showPagination={false}
          />
        </div>
      </div>
    );
  }
}

DecorationLocations.propTypes = {
  queue: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const mapStateToProps = ({ productCatalog }) => ({
  queue: productCatalog.decorationLocations,
});

export default connect(mapStateToProps)(DecorationLocations);
