import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useGetLockersForOrganization } from '@api/squadlockerServices/organizations';
import { ApiResponse, CalculateOrderPricingDetailsQuery, CouponTypeEnum, OrderPricingDetailsApiResponse, QueriesVariationDto, SpreadsheetItemDto } from '@api/orders/models';
import { orderDetailsNewOrderAddItems } from '@constants/clientUrls/clientUrls';
import { RootState } from '@redux/index/reducers';
import * as orderManagementActions from '@redux/orderManagement/actions';
import * as productCatalogActions from '@redux/productCatalog/actions';
import { OrderManagementTempItem, OrderManagementTempItemPersonalization } from '@redux/orderManagement/models';
import { materialSwal, toggleArrayMember } from '@util/componentHelper';
import ButtonLink from '@sharedComponents/Navigation/ButtonLink';
import ItemInfoModal from '@sharedComponents/ItemInfo/ItemInfoModal';
import SearchFilter from '@sharedComponents/Inputs/SearchFilter';
import Expander from '@sharedComponents/Buttons/Expander';
import Button from '@sharedComponents/Buttons/Button';
import SimpleConfirmationModal from '@sharedComponents/Modal/SimpleConfirmationModal';
import ImageModal from '@sharedComponents/Modal/ImageModal';
import SearchResults from '@sharedComponents/Display/SearchResults';
import OrderItems from './NewOrderDetailsContent/OrderItems';
import ChangeItemQuantityModal from '../OrderManagementModals/ChangeItemQuantityModal';
import DuplicateItemModal from '../OrderManagementModals/DuplicateItemModal';
import AddPersonalizationModal from '../OrderManagementModals/AddPersonalizationModal';
import EditPersonalizationModal from '../OrderManagementModals/EditPersonalizationModal';
import UploadTeamOrganizerModal from '../OrderManagementModals/UploadTeamOrganizerModal';
import OrderFinancialsModal from '../OrderManagementModals/OrderFinancialsModal';
import OrderSummary from './NewOrderDetailsContent/OrderSummary';
import AddCouponModal from './AddCouponModal';
import CouponDetailsModal from './CouponDetailsModal';
import { useCheckRedeemCoupon } from '@api/squadlockerServices/coupons';
import { UseMutateAsyncFunction } from 'react-query';

interface OwnProps {
  couponCode: Nullable<string>;
  couponType: Nullable<string>;
  couponValue: Nullable<number>;
  couponAppliesToShipping: Nullable<boolean>;
  setCouponCode: (code: Nullable<string>) => void;
  setCouponType: (type: Nullable<string>) => void;
  setCouponValue: (value: Nullable<number>) => void;
  setCouponAppliesToShipping: (applies: Nullable<boolean>) => void;
  orderPricingDetails: OrderPricingDetailsApiResponse | undefined;
  fetchOrderPricingDetails: UseMutateAsyncFunction<OrderPricingDetailsApiResponse, ApiResponse, {
    data: CalculateOrderPricingDetailsQuery;
  }, unknown>;
}

const mapStateToProps = ({
  orderManagement,
  productCatalog,
}: RootState) => ({
  items: orderManagement.tempItems,
  order: orderManagement.tempOrder,
  colorsDictionary: productCatalog.colorsDictionary,
});

const mapDispatchToProps = {
  saveTempItems: orderManagementActions.saveTempItems,
  getColors: productCatalogActions.getColors,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = OwnProps & ConnectedProps<typeof connector>;

const NewOrderDetails = React.memo(({
  couponCode,
  couponType,
  couponValue,
  couponAppliesToShipping,
  setCouponCode,
  setCouponType,
  setCouponValue,
  setCouponAppliesToShipping,
  items,
  order,
  getColors,
  saveTempItems,
  colorsDictionary,
  orderPricingDetails,
  fetchOrderPricingDetails,
}: Props) => {
  const [
    itemInfoModalIsOpen,
    setItemInfoModalIsOpen,
  ] = useState<boolean>(false);
  const [
    collapsedItemIds,
    setCollapsedItemIds,
  ] = useState<symbol[]>([]);
  const [
    collapseAll,
    setCollapseAll,
  ] = useState<boolean>(false);
  const [
    filteredItems,
    setFilteredItems,
  ] = useState<OrderManagementTempItem[]>(items);
  const [
    isFilteringItems,
    setIsFilteringItems,
  ] = useState<boolean>(false);
  const [
    resetSearchOrderItemsInput,
    setResetSearchOrderItemsInput,
  ] = useState<boolean>(false);
  const [
    changeQuantityModalIsOpen,
    setChangeQuantityModalIsOpen,
  ] = useState<boolean>(false);
  const [
    duplicateItemModalIsOpen,
    setDuplicateItemModalIsOpen,
  ] = useState<boolean>(false);
  const [
    removeItemModalIsOpen,
    setRemoveItemModalIsOpen,
  ] = useState<boolean>(false);
  const [
    previewModalIsOpen,
    setPreviewModalIsOpen,
  ] = useState<boolean>(false);
  const [
    addPersonalizationModalIsOpen,
    setAddPersonalizationModalIsOpen,
  ] = useState<boolean>(false);
  const [
    editPersonalizationModalIsOpen,
    setEditPersonalizationModalIsOpen,
  ] = useState<boolean>(false);
  const [
    uploadTeamOrganizerModalIsOpen,
    setUploadTeamOrganizerModalIsOpen,
  ] = useState<boolean>(false);
  const [
    orderFinancialsModalIsOpen,
    setOrderFinancialsModalIsOpen,
  ] = useState<boolean>(false);
  const [
    selectedImageUrl,
    setSelectedImageUrl,
  ] = useState<string>('');
  const [
    selectedItem,
    setSelectedItem,
  ] = useState<Nullable<OrderManagementTempItem>>(null);
  const [
    selectedPersonalization,
    setSelectedPersonalization,
  ] = useState<Nullable<OrderManagementTempItemPersonalization>>(null);
  const [
    applyCouponModalIsOpen,
    setApplyCouponModalIsOpen,
  ] = useState<boolean>(false);
  const [
    couponDetailsModalIsOpen,
    setCouponDetailsModalIsOpen,
  ] = useState<boolean>(false);

  const [
    applyCouponNextAction,
    setApplyCouponNextAction,
  ] = useState<Nullable<{ apply: boolean; couponType: string }>>(null);
  const [
    fetchOrderPricingDetailsCallback,
    setFetchOrderPricingDetailsCallback,
  ] = useState<boolean>(false);

  const {
    isLoading: isLoadingOrganizationLockers,
    isError: isErrorOrganizationLockers,
    data: organizationLockers,
    refetch: fetchOrganizationLockers,
  } = useGetLockersForOrganization(order!.organizationId!, undefined);

  const {
    data: checkCouponAvailabilityResponse,
    refetch: checkCouponAvailability,
  } = useCheckRedeemCoupon(couponCode ?? '');

  const orderItems = useMemo(() => {
    const list = [];

    for (const item of items) {
      const personalizationsDict: Record<string, number> = {};
      for (const personalization of item.personalizations) {
        personalizationsDict[personalization.id!] = personalization.unitPrice ?? 0;
      }

      list.push({
        variationId: item.variationId,
        sku: item.sku,
        quantity: item.quantity,
        personalizations: personalizationsDict,
      });
    }

    return list;
  }, [items]);

  useEffect(() => {
    if (!order?.organizationId) { return; }
    fetchOrganizationLockers();
  }, [
    fetchOrganizationLockers,
    order?.organizationId,
  ]);

  useEffect(() => {
    if (!orderItems.length) { return; }

    const data = {
      organizationId: order?.organizationId ?? undefined,
      salesChannelId: !order?.organizationId ? order?.lockerId : undefined,
      shippingInfo: {
        name: order?.name,
        addressLine1: order?.addressLine1,
        addressLine2: order?.addressLine2,
        city: order?.city,
        state: order?.state,
        zip: order?.zip,
        country: order?.country,
        pOBox: order?.poBox,
        email: order?.email,
        method: order?.method,
        phoneNumber: order?.phoneNumber,
      },
      shippingBasePrice: order?.shippingBasePrice,
      items: orderItems,
      couponCode,
      couponType: couponType! as CouponTypeEnum ?? undefined,
      couponValue: couponValue ?? undefined,
      couponAppliesToShipping: couponAppliesToShipping ?? undefined,
    } as CalculateOrderPricingDetailsQuery;
    fetchOrderPricingDetails({ data });
  }, [couponAppliesToShipping, couponCode, couponType, couponValue, fetchOrderPricingDetails, order, orderItems]);

  useEffect(() => {
    getColors();
  }, [getColors]);

  const openItemInfoModal = useCallback((item) => {
    setItemInfoModalIsOpen(true);
    setSelectedItem(item);
  }, []);

  const closeItemInfoModal = useCallback(() => {
    setItemInfoModalIsOpen(false);
    setSelectedItem(null);
  }, []);

  const openPreviewModal = useCallback((imageUrl: string) => {
    setSelectedImageUrl(imageUrl);
    setPreviewModalIsOpen(true);
  }, []);

  const closePreviewModal = useCallback(() => {
    setSelectedImageUrl('');
    setPreviewModalIsOpen(false);
  }, []);

  const openChangeQuantityModal = useCallback((item) => {
    setChangeQuantityModalIsOpen(true);
    setSelectedItem(item);
  }, []);

  const closeChangeQuantityModal = useCallback(() => {
    setChangeQuantityModalIsOpen(false);
    setSelectedItem(null);
  }, []);

  const openDuplicateItemModal = useCallback((item) => {
    setDuplicateItemModalIsOpen(true);
    setSelectedItem(item);
  }, []);

  const closeDuplicateItemModal = useCallback(() => {
    setDuplicateItemModalIsOpen(false);
    setSelectedItem(null);
  }, []);

  const openRemoveItemModal = useCallback((item) => {
    setRemoveItemModalIsOpen(true);
    setSelectedItem(item);
  }, []);

  const closeRemoveItemModal = useCallback(() => {
    setRemoveItemModalIsOpen(false);
    setSelectedItem(null);
  }, []);

  const openAddPersonalizationModal = useCallback((
    item: OrderManagementTempItem,
    personalization: OrderManagementTempItemPersonalization,
  ) => {
    setAddPersonalizationModalIsOpen(true);
    setSelectedItem(item);
    setSelectedPersonalization(personalization);
  }, []);

  const closeAddPersonalizationModal = useCallback(() => {
    setAddPersonalizationModalIsOpen(false);
    setSelectedItem(null);
    setSelectedPersonalization(null);
  }, []);

  const openEditPersonalizationModal = useCallback((
    item: OrderManagementTempItem,
    personalization: OrderManagementTempItemPersonalization,
  ) => {
    setEditPersonalizationModalIsOpen(true);
    setSelectedItem(item);
    setSelectedPersonalization(personalization);
  }, []);

  const closeEditPersonalizationModal = useCallback(() => {
    setEditPersonalizationModalIsOpen(false);
    setSelectedItem(null);
    setSelectedPersonalization(null);
  }, []);

  const openUploadTeamOrganizerModal = useCallback(() => {
    setUploadTeamOrganizerModalIsOpen(true);
  }, []);

  const closeUploadTeamOrganizerModal = useCallback(() => {
    setUploadTeamOrganizerModalIsOpen(false);
  }, []);

  const openOrderFinancialsModal = useCallback(() => {
    setOrderFinancialsModalIsOpen(true);
  }, []);

  const closeOrderFinancialsModal = useCallback(() => {
    setOrderFinancialsModalIsOpen(false);
  }, []);

  const openApplyCouponModal = useCallback(() => {
    setApplyCouponModalIsOpen(true);
  }, []);

  const openCouponDetailsModal = useCallback(() => {
    setCouponDetailsModalIsOpen(true);
  }, []);

  const closeApplyCouponModal = useCallback(() => {
    setApplyCouponModalIsOpen(false);
    setCouponCode(null);
    setCouponType(null);
    setCouponValue(null);
    setCouponAppliesToShipping(null);
  }, [
    setCouponAppliesToShipping,
    setCouponCode,
    setCouponType,
    setCouponValue,
  ]);

  const getApplyCouponNextAction = useCallback((
    newCouponCode: string,
    newCouponType: string,
    newCouponValue: number,
    newCouponAppliesToShipping: boolean,
  ) => {
    setCouponCode(newCouponCode);
    setCouponType(newCouponType);
    setCouponValue(newCouponValue);
    setCouponAppliesToShipping(newCouponAppliesToShipping);
    setApplyCouponNextAction({
      apply: true,
      couponType: newCouponType,
    });
  }, [
    setCouponAppliesToShipping,
    setCouponCode,
    setCouponType,
    setCouponValue,
  ]);

  const addItems = useCallback((newItems: SpreadsheetItemDto[]) => {
    const lockers = (!isErrorOrganizationLockers && !isLoadingOrganizationLockers) ? organizationLockers : [];

    const newTempItems: any[] = newItems.map((item) => ({
      id: Symbol(),
      variationId: item.variationId,
      sku: item.sku,
      lockerId: item.salesChannelId,
      lockerTeamName: lockers?.find((locker) => locker.id === item.salesChannelId)?.teamName,
      quantity: item.quantity,
      personalizations: item.personalizations,
      price: item.pricePaid,
      images: [],
      availableSizes: [],
    }));

    saveTempItems([
      ...items,
      ...newTempItems,
    ]);

    closeUploadTeamOrganizerModal();
  }, [
    items,
    saveTempItems,
    closeUploadTeamOrganizerModal,
    isErrorOrganizationLockers,
    isLoadingOrganizationLockers,
    organizationLockers,
  ]);

  const updateItemVariation = useCallback((
    itemId: symbol,
    variation: QueriesVariationDto,
  ) => {
    const itemToUpdateIndex = items.findIndex((item) => item.id === itemId);
    const itemToUpdate = items[itemToUpdateIndex];

    const availableSizes: string[] = variation.products?.map((product) => product.size!) ?? [];
    const size = variation.products?.find((product) => product.sku === itemToUpdate?.sku)?.size;

    const variationPersonalizations: OrderManagementTempItemPersonalization[] = (variation.item?.personalizations ?? []).map((personalization) => ({
      id: personalization.id,
      name: undefined,
      number: undefined,
      personalizationType: personalization.personalizationType,
      decorationMethod: personalization.decorationMethod,
      decorationLocation: personalization.decorationLocation,
      decorationHeight: personalization.decorationHeight,
      colorId: personalization.colorId!,
      outlineColorId: personalization.outlineColorId,
      required: personalization.required,
    }));

    const newItems: OrderManagementTempItem[] = [...items];

    newItems[itemToUpdateIndex] = {
      ...itemToUpdate,
      itemId: variation.item!.id!,
      // LockerId: variation.item?.salesChannelId as number,
      coloredStyleId: variation.coloredStyleId!,
      name: variation.item!.name!,
      size: size!,
      availableSizes,
      custom: variation.item!.custom!,
      colorGroup: variation.color?.name as (string | undefined),
      image: variation.images?.find((image) => image.isPrimary)?.src as (string | undefined),
      images: variation.images!,
      personalizations: (itemToUpdate.personalizations.map((personalization) => {
        const variationPersonalization = variationPersonalizations.find((p) => p.id === personalization.id);

        return ({
          ...variationPersonalization,
          id: personalization.id,
          name: personalization.name,
          number: personalization.number,
        });
      })),
    };

    saveTempItems(newItems);
  }, [
    items,
    saveTempItems,
  ]);

  const changeItemQuantity = useCallback((item: OrderManagementTempItem, quantity: number) => {
    const newItems = [...items];
    const index = newItems.findIndex((it) => it.id === item.id);
    newItems[index] = {
      ...item,
      quantity,
    };

    saveTempItems(newItems);

    closeChangeQuantityModal();
  }, [
    items,
    saveTempItems,
    closeChangeQuantityModal,
  ]);

  const duplicateItem = useCallback((item: OrderManagementTempItem, quantity: number, size: string) => {
    const newItems = [...items];
    newItems.push({
      ...item,
      id: Symbol(),
      quantity,
      size,
    });

    saveTempItems(newItems);

    closeDuplicateItemModal();
  }, [
    items,
    saveTempItems,
    closeDuplicateItemModal,
  ]);

  const removeItem = useCallback(() => {
    const newTempItems = items.filter((item) => item.id !== selectedItem?.id);
    saveTempItems(newTempItems);
    closeRemoveItemModal();
  }, [
    saveTempItems,
    closeRemoveItemModal,
    selectedItem,
    items,
  ]);

  const addPersonalization = useCallback((
    item: OrderManagementTempItem,
    personalization: OrderManagementTempItemPersonalization,
    unitPrice: number,
    name?: string,
    number?: string,
  ) => {
    const newItems = [...items];
    const itemIndex = newItems.findIndex((it) => it.id === item.id);

    const personalizationIndex = item.personalizations.findIndex((p) => p.id === personalization.id);

    let newPersonalizations = [...item.personalizations];
    if (!item?.custom) {
      newPersonalizations[personalizationIndex] = {
        ...personalization,
        name,
        number,
        unitPrice,
      };
    } else {
      newPersonalizations = newPersonalizations.map((p) => ({
        ...p,
        name,
        number,
        unitPrice,
      }));
    }

    newItems[itemIndex] = {
      ...item,
      personalizations: newPersonalizations,
    };

    saveTempItems(newItems);
    closeAddPersonalizationModal();
  }, [
    items,
    saveTempItems,
    closeAddPersonalizationModal,
  ]);

  const editPersonalization = useCallback((
    item: OrderManagementTempItem,
    personalization: OrderManagementTempItemPersonalization,
    unitPrice: number,
    name?: string,
    number?: string,
  ) => {
    const newItems = [...items];
    const itemIndex = newItems.findIndex((it) => it.id === item.id);

    const personalizationIndex = item.personalizations.findIndex((p) => p.id === personalization.id);

    let newPersonalizations = [...item.personalizations];
    if (!item?.custom) {
      newPersonalizations[personalizationIndex] = {
        ...personalization,
        name,
        number,
        unitPrice,
      };
    } else {
      newPersonalizations = newPersonalizations.map((p) => ({
        ...p,
        name,
        number,
        unitPrice,
      }));
    }

    newItems[itemIndex] = {
      ...item,
      personalizations: newPersonalizations,
    };

    saveTempItems(newItems);
    closeEditPersonalizationModal();
  }, [
    items,
    saveTempItems,
    closeEditPersonalizationModal,
  ]);

  const removePersonalization = useCallback((
    item: OrderManagementTempItem,
    personalization: OrderManagementTempItemPersonalization,
  ) => {
    const newItems = [...items];
    const itemIndex = newItems.findIndex((it) => it.id === item.id);

    const personalizationIndex = item.personalizations.findIndex((p) => p.id === personalization.id);

    let newPersonalizations = [...item.personalizations];
    if (!item?.custom) {
      newPersonalizations[personalizationIndex] = {
        ...personalization,
        name: undefined,
        number: undefined,
        colorId: undefined,
        unitPrice: undefined,
      };
    } else {
      newPersonalizations = newPersonalizations.map((p) => ({
        ...p,
        name: undefined,
        number: undefined,
        colorId: undefined,
        unitPrice: undefined,
      }));
    }

    newItems[itemIndex] = {
      ...item,
      personalizations: newPersonalizations,
    };

    saveTempItems(newItems);
  }, [
    items,
    saveTempItems,
  ]);

  const toggleCollapseItem = useCallback((id: symbol) => {
    const collapsedItemIdList = toggleArrayMember(collapsedItemIds, id);
    const filteredItemsIds = filteredItems.map((i) => i.id);
    let newCollapseAll = collapseAll;

    if (filteredItemsIds.length > 0) {
      let collapsedCounter = 0;
      for (const itemId of filteredItemsIds) {
        if (collapsedItemIdList.includes(itemId)) {
          collapsedCounter++;
        }
      }

      if (collapsedCounter === filteredItemsIds.length && !collapseAll) {
        newCollapseAll = true;
      } else if (collapsedCounter === 0 && collapseAll) {
        newCollapseAll = false;
      }
    }

    setCollapseAll(newCollapseAll);
    setCollapsedItemIds(collapsedItemIdList);
  }, [
    collapseAll,
    collapsedItemIds,
    filteredItems,
  ]);

  const collapseOrExpandItems = useCallback(() => {
    if (collapseAll) {
      setCollapseAll(false);
      setCollapsedItemIds([]);

      return;
    }

    setCollapseAll(true);
    setCollapsedItemIds(filteredItems.map((i) => i.id));
  }, [
    collapseAll,
    filteredItems,
  ]);

  const searchItemsInOrder = useCallback((newSearchInputValue) => {
    let newIsFilteringItems;
    let newFilteredItems;

    if (newSearchInputValue === '') {
      newIsFilteringItems = false;
      newFilteredItems = items;
    } else {
      newIsFilteringItems = true;
      newFilteredItems = items.filter((i: OrderManagementTempItem) => (
        (i.sku || '').toLowerCase().includes(newSearchInputValue.toLowerCase())
        || (i.name ?? '').toLowerCase().includes(newSearchInputValue.toLowerCase())
      ));
    }

    setFilteredItems(newFilteredItems);
    setIsFilteringItems(newIsFilteringItems);
  }, [items]);

  const handleSearchInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const newSearchInputValue = e.target.value.toLowerCase().trim();
    searchItemsInOrder(newSearchInputValue);
  }, [searchItemsInOrder]);

  const clearSearch = useCallback(() => {
    searchItemsInOrder('');
    setResetSearchOrderItemsInput(true);
  }, [searchItemsInOrder]);

  const onResetSearchOrderItemsInput = useCallback(() => {
    setResetSearchOrderItemsInput(false);
  }, []);

  const closeCouponDetailsModal = useCallback(() => {
    setCouponDetailsModalIsOpen(false);
  }, []);

  const removeCoupon = useCallback(() => {
    setCouponCode(null);
    setCouponType(null);
    setCouponValue(null);
    setCouponAppliesToShipping(null);
    setCouponDetailsModalIsOpen(false);
    setFetchOrderPricingDetailsCallback(true);
  }, [setCouponAppliesToShipping, setCouponCode, setCouponType, setCouponValue]);

  const filteredQuantity = filteredItems ? filteredItems.length : 0;
  const itemsList = isFilteringItems ? filteredItems : items;

  useEffect(() => {
    if (fetchOrderPricingDetailsCallback) {
      const data = {
        organizationId: order?.organizationId ?? undefined,
        salesChannelId: !order?.organizationId ? order?.lockerId : undefined,
        shippingInfo: {
          name: order?.name,
          addressLine1: order?.addressLine1,
          addressLine2: order?.addressLine2,
          city: order?.city,
          state: order?.state,
          zip: order?.zip,
          country: order?.country,
          pOBox: order?.poBox,
          email: order?.email,
          method: order?.method,
          phoneNumber: order?.phoneNumber,
        },
        shippingBasePrice: order?.shippingBasePrice,
        items: orderItems,
        couponCode,
        couponType: couponType! as CouponTypeEnum ?? undefined,
        couponValue: couponValue ?? undefined,
        couponAppliesToShipping: couponAppliesToShipping ?? undefined,
      } as CalculateOrderPricingDetailsQuery;
      fetchOrderPricingDetails({ data });
      setFetchOrderPricingDetailsCallback(false);
    }
  }, [
    couponAppliesToShipping,
    couponCode,
    couponType,
    couponValue,
    fetchOrderPricingDetails,
    fetchOrderPricingDetailsCallback,
    order?.addressLine1,
    order?.addressLine2,
    order?.city,
    order?.country,
    order?.email,
    order?.lockerId,
    order?.method,
    order?.name,
    order?.organizationId,
    order?.phoneNumber,
    order?.poBox,
    order?.shippingBasePrice,
    order?.state,
    order?.zip,
    orderItems,
  ]);

  useEffect(() => {
    if (!applyCouponNextAction?.apply) { return; }

    checkCouponAvailability();
  }, [
    applyCouponNextAction?.apply,
    checkCouponAvailability,
  ]);

  useEffect(() => {
    if (checkCouponAvailabilityResponse?.result) {
      const data = {
        organizationId: order?.organizationId ?? undefined,
        salesChannelId: !order?.organizationId ? order?.lockerId : undefined,
        shippingInfo: {
          name: order?.name,
          addressLine1: order?.addressLine1,
          addressLine2: order?.addressLine2,
          city: order?.city,
          state: order?.state,
          zip: order?.zip,
          country: order?.country,
          pOBox: order?.poBox,
          email: order?.email,
          method: order?.method,
          phoneNumber: order?.phoneNumber,
        },
        shippingBasePrice: order?.shippingBasePrice,
        items: orderItems,
        couponCode,
        couponType: couponType! as CouponTypeEnum,
        couponValue,
        couponAppliesToShipping,
      } as CalculateOrderPricingDetailsQuery;

      fetchOrderPricingDetails({ data });

      setApplyCouponNextAction(null);
      setApplyCouponModalIsOpen(false);
    } else if (checkCouponAvailabilityResponse) {
      materialSwal('Coupon is currently not redeemable');
      setCouponCode(null);
      setCouponType(null);
      setCouponValue(null);
      setCouponAppliesToShipping(null);
      setFetchOrderPricingDetailsCallback(true);
    }
  }, [
    checkCouponAvailabilityResponse,
    couponAppliesToShipping,
    couponCode,
    couponType,
    couponValue,
    fetchOrderPricingDetails,
    order?.addressLine1,
    order?.addressLine2,
    order?.city,
    order?.country,
    order?.email,
    order?.lockerId,
    order?.method,
    order?.name,
    order?.organizationId,
    order?.phoneNumber,
    order?.poBox,
    order?.shippingBasePrice,
    order?.state,
    order?.zip,
    orderItems,
    setCouponAppliesToShipping,
    setCouponCode,
    setCouponType,
    setCouponValue,
  ]);

  if (items && items.length > 0) {
    return (
      <div className='new-order__order-items__main'>
        <div>
          <ImageModal
            isOpen={previewModalIsOpen}
            closeModal={closePreviewModal}
            imageUrl={selectedImageUrl}
          />
          <ItemInfoModal
            isOpen={itemInfoModalIsOpen}
            closeModal={closeItemInfoModal}
            sku={selectedItem?.sku}
            lockerItemId={selectedItem?.custom ? selectedItem.itemId : undefined}
          />
          <ChangeItemQuantityModal
            isOpen={changeQuantityModalIsOpen}
            item={selectedItem}
            changeItemQuantity={changeItemQuantity}
            closeModal={closeChangeQuantityModal}
          />
          <DuplicateItemModal
            isOpen={duplicateItemModalIsOpen}
            item={selectedItem}
            duplicateItem={duplicateItem}
            closeModal={closeDuplicateItemModal}
          />
          <SimpleConfirmationModal
            isOpen={removeItemModalIsOpen}
            closeModal={closeRemoveItemModal}
            title={'Remove Item'}
            confirm={removeItem}
            confirmationBody={`Are you sure you want to remove item ${selectedItem?.sku} and all of its decorations?`}
          />
          <AddPersonalizationModal
            isOpen={addPersonalizationModalIsOpen}
            item={selectedItem}
            personalization={selectedPersonalization}
            addPersonalization={addPersonalization}
            closeModal={closeAddPersonalizationModal}
          />
          <EditPersonalizationModal
            isOpen={editPersonalizationModalIsOpen}
            item={selectedItem}
            personalization={selectedPersonalization}
            editPersonalization={editPersonalization}
            closeModal={closeEditPersonalizationModal}
          />
          <OrderFinancialsModal
            isOpen={orderFinancialsModalIsOpen}
            closeModal={closeOrderFinancialsModal}
            orderPricingDetails={orderPricingDetails?.result}
            order={order}
            items={items}
          />

          <CouponDetailsModal
            couponCode={couponCode}
            isOpen={couponDetailsModalIsOpen}
            removeCoupon={removeCoupon}
            closeModal={closeCouponDetailsModal}
          />

          <AddCouponModal
            isOpen={applyCouponModalIsOpen}
            closeModal={closeApplyCouponModal}
            onConfirm={getApplyCouponNextAction}
          />
          <div className='order__search-container'>
            <SearchFilter
              onChange={handleSearchInputChange}
              placeholder={'Search'}
              resetValue={resetSearchOrderItemsInput}
              onResetValue={onResetSearchOrderItemsInput}
            />
            <Expander
              isExpanded={!collapseAll}
              toggle={collapseOrExpandItems}
              collapseText={'Collapse All'}
              expandText={'Expand All'}
              hideIcon={true}
              classes={'ml-20'}
            />
          </div>
          <div className='h-100'>
            <SearchResults
              isFilteringItems={isFilteringItems}
              filteredItemsQuantity={filteredQuantity}
              clearSearch={clearSearch}
            />

            <OrderItems
              items={itemsList}
              order={order}
              isFilteringItems={false}
              collapsedItemIds={collapsedItemIds}
              toggleCollapseItem={toggleCollapseItem}
              openItemInfoModal={openItemInfoModal}
              openPreviewModal={openPreviewModal}
              openChangeQuantityModal={openChangeQuantityModal}
              openDuplicateItemModal={openDuplicateItemModal}
              openRemoveItemModal={openRemoveItemModal}
              openAddPersonalizationModal={openAddPersonalizationModal}
              openEditPersonalizationModal={openEditPersonalizationModal}
              colorsDictionary={colorsDictionary}
              removePersonalization={removePersonalization}
              updateItemVariation={updateItemVariation}
            />
          </div>
        </div>
        <OrderSummary
          items={items}
          openApplyCouponModal={openApplyCouponModal}
          orderPricingDetails={orderPricingDetails?.result}
          openOrderFinancialsModal={openOrderFinancialsModal}
          openCouponDetailsModal={openCouponDetailsModal}
          order={null}
          couponCode={couponCode}
        />
      </div>
    );
  }

  return (
    <div className='new-order__order-items__main'>
      <UploadTeamOrganizerModal
        isOpen={uploadTeamOrganizerModalIsOpen}
        closeModal={closeUploadTeamOrganizerModal}
        addItems={addItems}
        order={order}
      />
      <div className='new-order__order-items__container new-order__order-items__container--no-items'>
        <div className='new-order__order-items__button'>
          Add items to place an order
          <div className='align__center mt-10'>
            <ButtonLink
              type={'primary'}
              text={'Add item(s)'}
              classes={'mr-10'}
              linkTo={orderDetailsNewOrderAddItems}
            />
            or
            <Button
              type={'primary'}
              text={'Upload team organizer'}
              classes={'ml-10'}
              onClick={openUploadTeamOrganizerModal}
            />
          </div>
        </div>
      </div>
    </div>
  );
});

export default connector(NewOrderDetails);
