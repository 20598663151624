import { RootState } from '@redux/index/reducers';
import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import * as productCatalogActions from '@redux/productCatalog/actions';
import { changeSkuGetInfoDelay, defaultPageSizeBigTable, minInputLengthForSku } from '@constants/values';
import { generalOptions } from '@util/optionsMap';
import Modal from '@sharedComponents/Modal/Modal';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import Input from '@sharedComponents/Form/Input';
import Select from '@sharedComponents/Form/Select';
import OrderManagementOrderItemProduct from './OrderManagementOrderItemProductNew';
import { replaceItemBySkuForm } from '@constants/reduxForms';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { lockerMgrS3Logos } from '@constants/common';
import { parseNumber } from '@util/numberHelpers';
import { validateOrderItemReplaceBySku } from '@redux/orderManagement/validations';
import { ReplaceItemBySkuFormData } from '@models/forms/OrderManagement/ReplaceItemBySkuFormData';
import { OrderDetailsDto, OrderItemDetailsDto } from '@api/fulfillment/models';
import { ProductDetailDto } from '@api/productCatalog/models';
import { LockerProductsCssLogoViewModel } from '@api/squadlockerServices/models';
import { usePostHomefieldApiIntegrationSsapidatasync } from '@api/orders/integration';
import Spinner from '@components/shared/Display/Spinner';
import { useGetHomefieldApiVariations } from '@api/orders/variations';
import { useGetHomefieldApiOrganizationsIdSaleschannels } from '@api/orders/organizations';
import { variationsToPCProducts } from '@util/VariationToProductCatalogProductHelper';
import QuickViewProduct from '@components/shared/QuickView/QuickViewProduct';
import { VariationCatalogProductDetailModel } from '@models/Products/VariationCatalogProductDetailModel';
import { QueriesVariationDto as VariationDto } from '@api/orders/models';
import { getQueryClientInstance } from '@util/queryClientHelper';
import { useGetHomefieldApiOrdermanagementOrdersgetdetails } from '@api/orders/orders';

interface OwnProps {
  isOpen: boolean;
  closeModal: () => void;
  handleChangeSkuInput: (newSkuInput: string) => void;
  onSubmit: (form: ReplaceItemBySkuFormData) => Promise<void>;
  skuInput: string;
  order: OrderDetailsDto;
  orderItem: OrderItemDetailsDto;
  orderItemProduct: ProductDetailDto;
  product: ProductDetailDto;
  selectedLogo: LockerProductsCssLogoViewModel;
  canUpdateBasePrice: boolean;
  synced: boolean;
  setSynced: (synch: boolean) => void;
  handleSelectedProductChange: (variationId: number) => void;
  setInitialBasePrice: (initialBasePrice: Nullable<number>) => void;
  orderExistsOnCore: boolean;
}

const mapStateToProps = ({ productCatalog }: RootState) => ({
  orderItemProduct: productCatalog.orderItemProduct,
});

const mapDispatchToProps = {
  getProductBySku: productCatalogActions.getProductBySku,
  getOrderItemProductBySku: productCatalogActions.getOrderItemProductBySku,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type FormProps = OwnProps & ConnectedProps<typeof connector>;

export type Props = FormProps & InjectedFormProps<ReplaceItemBySkuFormData, FormProps, string[]>;

const queryClient = getQueryClientInstance();

const OrderManagementDetailsReplaceBySkuModalNew = React.memo<Props>(({
  isOpen,
  handleSubmit,
  closeModal,
  handleChangeSkuInput,
  skuInput,
  order,
  orderItem,
  orderItemProduct,
  product,
  selectedLogo,
  canUpdateBasePrice,
  synced,
  setSynced,
  getProductBySku,
  getOrderItemProductBySku,
  handleSelectedProductChange,
  setInitialBasePrice,
  orderExistsOnCore,
}) => {
  const [
    throttledCall,
    setThrottledCall,
  ] = useState<Nullable<ReturnType<typeof setTimeout>>>(null);
  const [
    isReplacingDisabled,
    setIsReplacingDisabled,
  ] = useState<boolean | undefined>(false);
  const [
    logoImageUrl,
    setLogoImageUrl,
  ] = useState<string>('');
  const [
    quantityOptionsMapped,
    setQuantityOptionsMapped,
  ] = useState<
    Nullable<
      Array<{
        key: number;
        value: number;
        name: number;
      }>
    >
  >(null);
  const [
    isSynching,
    setIsSynching,
  ] = useState<boolean>(false);
  const [
    variationProducts,
    setVariationProducts,
  ] = useState<VariationCatalogProductDetailModel[] | null>(null);
  const [
    fetchedVariations,
    setFetchedVariations,
  ] = useState<Array<VariationDto>>([]);
  const [
    currentVariationsPage,
    setCurrentVariationsPage,
  ] = useState<number>(1);
  const [
    pickedVariation,
    setPickedVariaton,
  ] = useState<Nullable<VariationCatalogProductDetailModel>>(null);

  const { mutateAsync: syncLockersAsync } = usePostHomefieldApiIntegrationSsapidatasync(
    { request: { isBlockingRequest: false } },
  );

  const {
    data: accountSalesChannels,
    refetch: fetchAccountSalesChannels,
  } = useGetHomefieldApiOrganizationsIdSaleschannels(
    order.organizationId ? order.organizationId! : 0,
    undefined,
    {
      query: { enabled: false },
    },
  );

  const {
    data: coreOrderDetails,
    refetch: fetchCoreOrderDetails,
  } = useGetHomefieldApiOrdermanagementOrdersgetdetails(
    {
      id: order ? order.orderNumber! : 0,
    },
  );

  const {
    data: variationsPagedList,
    refetch: fetchVariationsBySalesChannelIds,
    isFetching: fetchingVariations,
    isError: errorFetchingVariations,
  } = useGetHomefieldApiVariations(
    {
      pageNumber: currentVariationsPage,
      pageSize: defaultPageSizeBigTable,
      salesChannelsId: accountSalesChannels?.items?.map((sc) => sc!.id!) ?? [order!.lockerId!],
    },
    { query: { enabled: false } },
  );

  useEffect(() => {
    if (orderItemProduct && fetchedVariations && isOpen && orderExistsOnCore) {
      fetchCoreOrderDetails();
    }
  }, [
    fetchCoreOrderDetails,
    fetchedVariations,
    isOpen,
    orderExistsOnCore,
    orderItem,
    orderItemProduct,
    product,
  ]);

  useEffect(() => {
    if (coreOrderDetails && isOpen && fetchedVariations && orderExistsOnCore) {
      setPickedVariaton(
        variationsToPCProducts(fetchedVariations).find(
          (pcVariation) =>
            pcVariation.variationId === coreOrderDetails?.items?.find(
              (item) => item.id === orderItem.coreId,
            )?.variationId,
        ) ?? null,
      );
    }
  }, [
    coreOrderDetails,
    fetchedVariations,
    isOpen,
    orderItem?.coreId,
    orderExistsOnCore,
  ]);

  useEffect(() => {
    if (variationsPagedList?.items && isOpen && orderExistsOnCore) {
      setFetchedVariations([
        ...fetchedVariations,
        ...variationsPagedList.items,
      ]);
    }
    if (variationsPagedList?.hasNextPage && orderExistsOnCore) {
      setCurrentVariationsPage(currentVariationsPage + 1);
    }
  }, [variationsPagedList, isOpen, orderExistsOnCore, fetchedVariations, currentVariationsPage]);

  useEffect(() => {
    if (synced && isOpen && orderExistsOnCore) {
      fetchVariationsBySalesChannelIds();
    }
  }, [
    isOpen,
    currentVariationsPage,
    fetchVariationsBySalesChannelIds,
    synced,
    orderExistsOnCore,
  ]);

  const syncLockersForNewOrder = useCallback(async (lockerNumb?: number) => {
    if (orderExistsOnCore) {
      const variables = {
        params: {
          organizationId: undefined,
          salesChannelId: lockerNumb ? [lockerNumb] : [],
        },
      };

      setIsSynching(true);

      const res = await syncLockersAsync(variables);

      if (res?.success) {
        setSynced(true);
      }

      setIsSynching(false);
    } else {
      setSynced(true);
    }
  }, [
    setSynced,
    syncLockersAsync,
    orderExistsOnCore,
  ]);

  useEffect(() => {
    if (isOpen && !synced && orderItem?.lockerId) {
      syncLockersForNewOrder(orderItem.lockerId);
    }
  }, [
    isOpen,
    orderItem,
    syncLockersForNewOrder,
    synced,
  ]);

  useEffect(() => {
    if (synced && isOpen && orderExistsOnCore) {
      if (order?.organizationId) {
        fetchAccountSalesChannels();
      } else {
        fetchVariationsBySalesChannelIds();
      }
    }
  }, [
    fetchAccountSalesChannels,
    fetchVariationsBySalesChannelIds,
    isOpen,
    order,
    synced,
    orderExistsOnCore,
  ]);

  useEffect(() => {
    if (accountSalesChannels && synced && isOpen && orderExistsOnCore) {
      fetchVariationsBySalesChannelIds();
    }
  }, [
    accountSalesChannels,
    fetchVariationsBySalesChannelIds,
    isOpen,
    synced,
    orderExistsOnCore,
  ]);

  useEffect(() => {
    if (fetchedVariations.length > 0 && isOpen) {
      setVariationProducts(variationsToPCProducts(fetchedVariations));
    }
  }, [
    fetchedVariations,
    isOpen,
  ]);

  useEffect(() => {
    if (isOpen && skuInput && product?.sku !== skuInput && synced) {
      getProductBySku(skuInput);
    }
  }, [
    skuInput,
    product,
    isOpen,
    getProductBySku,
    synced,
    orderExistsOnCore,
  ]);

  useEffect(() => {
    if (isOpen && orderItem && orderItemProduct?.sku !== orderItem.sku) {
      getOrderItemProductBySku(orderItem.sku);
    }
  }, [
    orderItemProduct,
    orderItem,
    isOpen,
    getOrderItemProductBySku,
  ]);

  useEffect(() => {
    setIsReplacingDisabled(skuInput.trim() === '');
  }, [skuInput]);

  useLayoutEffect(() => {
    if (throttledCall) {
      clearTimeout(throttledCall);
    }

    setThrottledCall(null);
  }, [throttledCall]);

  const getQuantityOptions = useCallback(() => {
    const quantityOptions = [];
    if (orderItem) {
      for (let i = 1; i <= orderItem.quantity!; i++) {
        const option = {
          key: i,
          value: i,
          name: i,
        };
        quantityOptions.push(option);
      }
    }

    return quantityOptions;
  }, [orderItem]);

  useEffect(() => {
    const length = orderItem?.logos?.length ?? 0;
    if (length > 0) {
      const url = orderItem.logos?.[0].logoUrl ?? '';
      setLogoImageUrl(url);
    } else {
      setLogoImageUrl('');
    }
  }, [orderItem]);

  useEffect(() => {
    setQuantityOptionsMapped(generalOptions(getQuantityOptions()));
  }, [
    getQuantityOptions,
    orderItem,
  ]);

  const updateProduct = useCallback(() => {
    if (throttledCall) {
      clearTimeout(throttledCall);
    }

    getProductBySku(skuInput);
  }, [
    getProductBySku,
    skuInput,
    throttledCall,
  ]);

  const changeSkuInput = useCallback((e) => {
    const newSkuInput = e.target.value ? e.target.value.trim() : '';
    handleChangeSkuInput(newSkuInput);

    if (newSkuInput.length < minInputLengthForSku) {
      setThrottledCall(null);

      return;
    }

    if (throttledCall) {
      clearTimeout(throttledCall);
      setThrottledCall(null);
    }

    const newThrottle = setTimeout(updateProduct, changeSkuGetInfoDelay);
    setThrottledCall(newThrottle);
  }, [
    handleChangeSkuInput,
    throttledCall,
    updateProduct,
  ]);

  const onCloseModal = useCallback(() => {
    setThrottledCall(null);
    setSynced(false);
    setVariationProducts(null);
    setFetchedVariations([]);
    queryClient.cancelMutations();
    closeModal();
  }, [
    closeModal,
    setSynced,
  ]);

  const changeSelectedProductVariation = useCallback((variationId: number) => {
    setInitialBasePrice(
      fetchedVariations.find(
        (variation) => variation.id === variationId,
      )?.item?.pricing?.basePrice ?? null,
    );
    handleSelectedProductChange(variationId);
  }, [
    fetchedVariations,
    handleSelectedProductChange,
    setInitialBasePrice,
  ]);

  const getMaxQuantity = () =>
    getQuantityOptions().reduce(
      (prev, current) => ((prev > current.value) ? prev : current.value),
      0,
    );

  useEffect(() => {
    if (!isOpen) {
      setVariationProducts(null);
      setFetchedVariations([]);
    }
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      closeModal={onCloseModal}
      title={'Replace item by SKU'}
      modalHeight={'l'}
      modalWidth={'m'}
      buttons={(
        <ModalButtons
          confirmBtnText={'Next'}
          cancelBtnText={'Cancel'}
          onClose={onCloseModal}
          isDangerousAction={true}
          formSubmission={true}
          confirmBtnDisabled={isReplacingDisabled ?? isSynching}
          formId={replaceItemBySkuForm}
        />
      )}
    >
      <>
        {isOpen
          && (
            <div>
              <form
                id={replaceItemBySkuForm}
                onSubmit={handleSubmit}
              >
                <div>
                  <OrderManagementOrderItemProduct
                    product={pickedVariation ?? product}
                    logoImageUrl={logoImageUrl}
                    borderize={true}
                    isSelected={false}
                    pickingEnabled={false}
                    orderExistsOnCore={orderExistsOnCore}
                  />
                  <h4 className='redux-form__row uppercase mt-10 mb-10'>
                    Replace with
                  </h4>
                  <div className='redux-form__row'>
                    <div className='redux-form__column--size-l'>
                      <label className='redux-form__label'>
                        Product SKU*
                      </label>
                      <div className='text-field'>
                        <Field
                          component={Input}
                          className={'w-100'}
                          name={'sku'}
                          type={'text'}
                          placeholder={'SKU'}
                          onChange={changeSkuInput}
                        />
                      </div>
                    </div>
                    <div className='redux-form__column--size-l ml-20'>
                      <label className='redux-form__label'>
                        Replace Qty*
                      </label>
                      <div className='select-extended-display'>
                        <Field<any>
                          component={Select}
                          name={'quantity'}
                          parse={parseNumber}
                        >
                          {quantityOptionsMapped}
                        </Field>
                        <div className='select-extended-info'>
                          /{getMaxQuantity}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='mt-10'>
                    {!orderExistsOnCore
                      && (
                        <OrderManagementOrderItemProduct
                          product={product}
                          logoImageUrl={selectedLogo && `${lockerMgrS3Logos}/${selectedLogo.image}`}
                          borderize={true}
                          isSelected={true}
                          pickingEnabled={false}
                          orderExistsOnCore={false}
                        />
                      )}
                    {!isSynching
                      && variationProducts
                      && !errorFetchingVariations
                      && orderExistsOnCore
                      && (
                        <QuickViewProduct
                          products={variationProducts}
                          logoImageUrl={selectedLogo
                            ? `${lockerMgrS3Logos}/${selectedLogo.image}`
                            : logoImageUrl}
                          skuInput={skuInput}
                          handleSelectedProductChange={changeSelectedProductVariation}
                          handleChangeSkuInput={handleChangeSkuInput}
                          isOpen={isOpen}
                          orderExistsOnCore={orderExistsOnCore}
                        />
                      )}
                    {orderExistsOnCore && isSynching
                      && (
                        <div>
                          <Spinner
                            size={'30px'}
                          />
                          <div>
                            Synching ...
                          </div>
                        </div>
                      )}
                    {fetchingVariations
                      && orderExistsOnCore
                      && !isSynching
                      && variationsPagedList === undefined
                      && orderExistsOnCore
                      && (
                        <div>
                          <Spinner
                            size={'30px'}
                          />
                          <div>
                            Fetching products ...
                          </div>
                        </div>
                      )}
                    {errorFetchingVariations && orderExistsOnCore
                      && (
                        <div>
                          Error occurred while fetching products.
                        </div>
                      )}
                  </div>
                  {canUpdateBasePrice
                    && (
                      <div className='redux-form__row mt-20'>
                        <div className='redux-form__column--size-s'>
                          <div className='text-field'>
                            <label className='redux-form__label'>
                              Base Price
                              <span className='required'>*</span>
                            </label>
                            <Field
                              component={Input as any}
                              className={'w-100'}
                              name={'basePrice'}
                              type={'number'}
                              parse={parseNumber}
                              placeholder={'Base Price'}
                              disabled={!!orderItem.voucherId}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                </div>
              </form>
            </div>
          )}
      </>
    </Modal>
  );
});

export default connector(
  reduxForm<ReplaceItemBySkuFormData, FormProps, string[]>({
    form: replaceItemBySkuForm,
    validate: validateOrderItemReplaceBySku,
    enableReinitialize: true,
  })(OrderManagementDetailsReplaceBySkuModalNew),
);
