import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import Expander from '@sharedComponents/Buttons/Expander';
import EditButton from '@sharedComponents/Display/EditButton';
import HeaderDetails from './HeaderDetails';

class Header extends PureComponent {
  state = {
    detailsAreExpanded: true,
    editOrganizationModalIsOpen: false,
    pageTitle: '',
  };

  componentDidMount() {
    const { organization } = this.props;
    this.setState({ pageTitle: organization.name || '-' });
  }

  componentDidUpdate(prevProps) {
    const { organization } = this.props;
    if (prevProps.organization.name !== organization.name) {
      this.setState({ pageTitle: organization.name || '-' });
    }
  }

  changeExpand = () => {
    this.setState((prevState) => ({
      detailsAreExpanded: !prevState.detailsAreExpanded,
    }));
  };

  render() {
    const {
      organization,
      organizationTotals,
      openEditOrganizationsModal,
      organizationLockers,
      teamColors,
      onToggleAutoMerchOptIn,
    } = this.props;

    const { detailsAreExpanded, pageTitle } = this.state;

    return (
      <div className='lockerManagerEdit__header'>
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
        <div className='lockerManagerEdit__details-header'>
          <div className='lockerManagerEdit__details-header--info'>
            {pageTitle}
            <EditButton
              onClick={openEditOrganizationsModal}
            />
          </div>
          <Expander
            isExpanded={detailsAreExpanded}
            toggle={this.changeExpand}
          />
        </div>
        {
          detailsAreExpanded &&
          <HeaderDetails
            organization={organization}
            organizationTotals={organizationTotals}
            organizationLockers={organizationLockers}
            teamColors={teamColors}
            onToggleAutoMerchOptIn={onToggleAutoMerchOptIn}
          />
        }
      </div>
    );
  }
}

Header.propTypes = {
  organization: PropTypes.object.isRequired,
  organizationTotals: PropTypes.object.isRequired,
  openEditOrganizationsModal: PropTypes.func.isRequired,
  organizationLockers: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
  })),
  teamColors: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    hexValue: PropTypes.string.isRequired,
    brightness: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
  })).isRequired,
  onToggleAutoMerchOptIn: PropTypes.func.isRequired,
};

const mapStateToProps = ({ lockerManager }) => ({
  partners: lockerManager.partners,
});

export default connect(mapStateToProps)(Header);
