/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */
import type { PrepressJobTypeEnum } from './prepressJobTypeEnum';
import type { PrepressJobItemDto } from './prepressJobItemDto';

export interface PrepressJobDto {
  id?: number;
  printQueueId?: number | null;
  jobType?: PrepressJobTypeEnum;
  barcode?: string | null;
  isReprint?: boolean;
  items?: PrepressJobItemDto[] | null;
}
