import React from 'react';
import MaterialTooltip from '@sharedComponents/Tooltips/MaterialTooltip';

interface OwnProps {
  type?: 'primary' | 'secondary' | 'warning' | 'danger';
  text: string;
  onClick: ((e: React.MouseEvent<HTMLButtonElement>) => void) | undefined;
  disabled?: boolean;
  classes?: string;
  icon?: Nullable<JSX.Element>;
  size?: string;
  tooltipText?: string;
  tooltipPlacement?: 'top' | 'bottom' | 'left' | 'right';
}

type Props = OwnProps;

const Button = React.memo(({
  type,
  text,
  onClick,
  disabled = false,
  classes = '',
  icon = null,
  size = 'm',
  tooltipText,
  tooltipPlacement = 'bottom',
}: Props) => {
  let btnTypeClasses = '';

  switch (type) {
    case 'primary':
      btnTypeClasses = 'btn-primary btn-primary--green';
      break;
    case 'secondary':
      btnTypeClasses = 'btn__secondary uppercase';
      break;
    case 'warning':
      btnTypeClasses = 'btn-primary btn-primary--orange';
      break;
    case 'danger':
      btnTypeClasses = 'btn-primary btn-primary--red';
      break;
  }

  const className = `${btnTypeClasses} ${classes} ${icon ? 'align__center' : ''} btn-primary--size-${size}`;

  if (tooltipText) {
    return (
      <MaterialTooltip
        tooltipText={tooltipText}
        placement={tooltipPlacement}
        contentClasses={'tt'}
      >
        <button
          type='button'
          className={disabled ? `${className} disabled` : className}
          onClick={!disabled ? onClick : undefined}
        >
          <>
            {
              icon &&
              <span className='mr-10'>
                {icon}
              </span>
            }
            {text}
          </>
        </button>
      </MaterialTooltip>
    );
  }

  return (
    <button
      type='button'
      className={className}
      onClick={!disabled ? onClick : undefined}
      disabled={disabled}
    >
      <>
        {
          icon &&
          <span className='mr-10'>
            {icon}
          </span>
        }
        {text}
      </>
    </button>
  );
});

export default Button;
