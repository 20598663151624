/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */
import type { DecorationStatusEnumStatusModel } from './decorationStatusEnumStatusModel';
import type { SelectedColorModel } from './selectedColorModel';

export interface LogoItemDetailsModel {
  ids?: number[] | null;
  logoId?: number | null;
  logoUrl?: string | null;
  decorationMethod?: string | null;
  decorationLocation?: string | null;
  decorationHeight?: number | null;
  decorationWidth?: number | null;
  imageUrl?: string | null;
  artworkTaskId?: number | null;
  previewUrl?: string | null;
  canceled?: string | null;
  status?: DecorationStatusEnumStatusModel;
  isCustomSize?: boolean;
  selectedColors?: SelectedColorModel[] | null;
  customArtworkColors?: string[] | null;
}
