/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Catalog Homefield API
 * OpenAPI spec version: v1
 */
import type { DtgConfigurationColoredStyleDto } from './dtgConfigurationColoredStyleDto';

export interface DtgConfigurationColoredStyleDtoPagedList {
  pageNumber?: number;
  pageSize?: number;
  totalCount?: number;
  items?: DtgConfigurationColoredStyleDto[] | null;
  readonly totalPages?: number;
  readonly hasPreviousPage?: boolean;
  readonly hasNextPage?: boolean;
}
