/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */
import type { HagQueriesSortByEnum } from './hagQueriesSortByEnum';
import type { SortDirectionEnum } from './sortDirectionEnum';
import type { DecorationStatusEnum } from './decorationStatusEnum';

export type GetHomefieldApiHagPrintroomorderassemblyParams = { orderNumber?: number; decorationId?: number; pageNumber?: number; pageSize?: number; sortBy?: HagQueriesSortByEnum; sortDirection?: SortDirectionEnum; decorationStatusEnum?: DecorationStatusEnum };
