/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Catalog Homefield API
 * OpenAPI spec version: v1
 */
import type { DecorationSizeEnum } from './decorationSizeEnum';

export interface DtgConfigurationDecorationLocationPersonalizationSizeDto {
  decorationLocationId?: number;
  decorationSize?: DecorationSizeEnum;
  rotation?: number;
  posX?: number;
  posY?: number;
  id?: number;
  uuid?: string | null;
  created?: string | null;
  updated?: string | null;
  deleted?: string | null;
  decorationLocationPersonalizationSizeId?: number;
  printerOverrideNameWidth?: number | null;
  printerOverrideNameHeight?: number | null;
  printerOverrideNumberHeightWithName?: number | null;
  printerOverrideNumberHeightWithoutName?: number | null;
  decorationLocationCode?: string | null;
  defaultNameWidth?: number | null;
  defaultNameHeight?: number | null;
  defaultNumberHeight?: number | null;
}
