import React, {
  useState,
  useCallback,
} from 'react';
import { Helmet } from 'react-helmet';
import {
  CollectionItemViewModel,
  RosterTeamMemberViewModel,
  RosterTeamViewModel,
} from '@api/squadlockerServices/models';
import Expander from '@sharedComponents/Buttons/Expander';
import HeaderDetails from './HeaderDetails';
import Label from '@sharedComponents/Display/Label';
import EditButton from '@sharedComponents/Display/EditButton';
import CollectionDetailsModal from '../RosterManagerModals/CollectionDetailsModal';

interface OwnProps {
  roster: RosterTeamViewModel;
  rosterMembers: RosterTeamMemberViewModel[];
  collectionItems: CollectionItemViewModel[];
  openEditRosterModal: () => void;
}

type Props = OwnProps;

const Header = React.memo<Props>(({
  roster,
  rosterMembers,
  collectionItems,
  openEditRosterModal,
}) => {
  const [
    detailsAreExpanded,
    setDetailsAreExpanded,
  ] = useState<boolean>(true);
  const [
    collectionDetailsModalIsOpen,
    setCollectionDetailsModalIsOpen,
  ] = useState<boolean>(false);

  const changeExpand = useCallback(() => {
    setDetailsAreExpanded(!detailsAreExpanded);
  }, [detailsAreExpanded]);

  const openCollectionDetailsModal = useCallback(() => {
    setCollectionDetailsModalIsOpen(true);
  }, []);

  const closeCollectionDetailsModal = useCallback(() => {
    setCollectionDetailsModalIsOpen(false);
  }, []);

  if (!roster) {
    return null;
  }

  const rosterMembersWithRequiredItemsCount = rosterMembers.reduce(
    (total, current) => total + ((current.orderedItemsCount > 0) ? 1 : 0), 0
  );

  const rosterMembersTotalCount = rosterMembers.length;

  const pageTitle = roster.teamName || '-';

  return (
    <div className='lockerManagerEdit__header'>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <CollectionDetailsModal
        isOpen={collectionDetailsModalIsOpen}
        closeModal={closeCollectionDetailsModal}
        lockerId={roster.lockerId!}
        lockerTeamName={roster.lockerTeamName!}
        collectionItems={collectionItems}
      />
      <div className='lockerManagerEdit__details-header'>
        <div className='lockerManagerEdit__details-header--info'>
          {pageTitle}
          {
            roster.disabled
              ? (
                <Label
                  text={'Disabled'}
                  type={'lynch'}
                  classes={'ml-10'}
                />
              )
              : (
                <Label
                  text={'Active'}
                  type={'status-green'}
                  classes={'ml-10'}
                />
              )
          }
          <EditButton
            onClick={openEditRosterModal}
            classes={'ml-10'}
          />
        </div>
        <Expander
          isExpanded={detailsAreExpanded}
          toggle={changeExpand}
        />
      </div>
      {
        detailsAreExpanded &&
        <HeaderDetails
          roster={roster}
          rosterMembersWithRequiredItemsCount={rosterMembersWithRequiredItemsCount}
          rosterMembersTotalCount={rosterMembersTotalCount}
          openCollectionDetailsModal={openCollectionDetailsModal}
        />
      }
    </div>
  );
});

export default Header;
