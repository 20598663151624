import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import Icon from '@sharedComponents/Icons/Icon';
import PermissionHandler from '@sharedComponents/Authorization/PermissionHandler';
import { Permission } from '@customTypes/auth';

export interface DropdownActionModel {
  text: string;
  icon?: string;
  linkTo?: Nullable<string>;
  isDangerous?: boolean;
  openInNewTab?: boolean;
  isVisible?: boolean;
  hasBottomBorder?: boolean;
  isCustomIcon?: boolean;
  iconWidth?: number | string;
  iconHeight?: number | string;
  permissions?: Permission[] | Permission;
  action?: () => void;
  disabled?: boolean;
}

interface OwnProps {
  item: DropdownActionModel;
  onClick: (e: React.MouseEvent, action: (() => void) | undefined, linkTo: Nullable<string> | undefined) => void;
}

type Props = OwnProps;

const DropdownAction = React.memo<Props>(({
  onClick,
  item,
}) => {
  const {
    linkTo = null,
    openInNewTab = false,
    isDangerous = false,
    isVisible = true,
    hasBottomBorder = true,
    isCustomIcon = false,
    iconWidth = 16,
    iconHeight = 16,
    permissions = null,
    icon,
    text,
    action,
    disabled = false,
  } = item;

  const onItemClick = useCallback((e: React.MouseEvent) => {
    onClick(e, action, linkTo);
  }, [
    action,
    linkTo,
    onClick,
  ]);

  if (!isVisible) return null;

  const actionIcon = isCustomIcon
    ? (
      <i
        className='fa mr-5'
        style={{
          width: `${iconWidth}px`,
          height: `${iconHeight}px`,
          backgroundImage: `url("${icon}")`,
        }}
      />
    )
    : <Icon materialIcon={icon} />;

  const button = (
    <div
      onClick={disabled ? undefined : onItemClick}
      className={`lockerManagerEdit__button ${disabled ? 'gray' : isDangerous ? 'red' : 'green'}-button`}
    >
      {actionIcon}
      <span>{text}</span>
    </div>
  );

  let content = button;

  if (linkTo !== null) {
    if (openInNewTab) {
      content = (
        <a
          target='_blank'
          href={linkTo}
          rel='noreferrer'
        >
          {button}
        </a>
      );
    } else {
      content = (
        <Link to={linkTo!}>
          {button}
        </Link>
      );
    }
  }

  return (
    permissions && permissions.length > 0
      ? (
        <PermissionHandler permissions={permissions}>
          <>
            <li>
              {content}
            </li>
            {
              hasBottomBorder &&
              <li className='lockerManager__item-list--border' />
            }
          </>
        </PermissionHandler>
      )
      : (
        <>
          <li>
            {content}
          </li>
          {
            hasBottomBorder &&
            <li className='lockerManager__item-list--border' />
          }
        </>
      )
  );
});

export default DropdownAction;
