import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import serverIcon from '@assets/icon_server.svg';
import { accountsSwagger, catalogSwagger, emailSwagger, financialSwagger, fulfillmentSwagger, ordersSwagger, slServicesSwagger } from '@constants/sqdApiUrls/supportUrls';
import * as supportActions from '@redux/support/actions';
import { RootState } from '@redux/index/reducers';
import Button from '@sharedComponents/Buttons/Button';
import MaterialTooltip from '@sharedComponents/Tooltips/MaterialTooltip';
import packageJson from '../../../package.json';

const mapStateToProps = ({ support, oidc }: RootState) => ({
  fulfillmentVersion: support.fulfillmentVersion,
  catalogVersion: support.catalogVersion,
  financialVersion: support.financialVersion,
  slServicesVersion: support.slServicesVersion,
  ordersVersion: support.ordersVersion,
  accountsVersion: support.accountsVersion,
  emailVersion: support.emailVersion,
  user: oidc.user,
});

const mapDispatchToProps = {
  fetchFulfillmentVersion: supportActions.fetchFulfillmentVersion,
  fetchCatalogVersion: supportActions.fetchCatalogVersion,
  fetchFinancialVersion: supportActions.fetchFinancialVersion,
  fetchSlServicesVersion: supportActions.fetchSlServicesVersion,
  fetchOrdersVersion: supportActions.fetchOrdersVersion,
  fetchAccountsVersion: supportActions.fetchAccountsVersion,
  fetchEmailVersion: supportActions.fetchEmailVersion,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectedProps<typeof connector>;

const Footer = ({
  fetchFulfillmentVersion,
  fetchCatalogVersion,
  fetchFinancialVersion,
  fetchSlServicesVersion,
  fetchOrdersVersion,
  fetchAccountsVersion,
  fetchEmailVersion,
  fulfillmentVersion,
  catalogVersion,
  financialVersion,
  slServicesVersion,
  ordersVersion,
  accountsVersion,
  emailVersion,
  user,
}: Props) => {
  const [versionsTooltipOpen, setVersionsTooltipOpen] = useState(false);

  useEffect(() => {
    if (user) {
      fetchFulfillmentVersion();
      fetchCatalogVersion();
      fetchFinancialVersion();
      fetchSlServicesVersion();
      fetchOrdersVersion();
      fetchAccountsVersion();
      fetchEmailVersion();
    }
  }, [fetchAccountsVersion, fetchCatalogVersion, fetchEmailVersion, fetchFinancialVersion, fetchFulfillmentVersion, fetchOrdersVersion, fetchSlServicesVersion, user]);

  const toggleVersionsTooltip = useCallback(() => {
    setVersionsTooltipOpen(!versionsTooltipOpen);
  }, [versionsTooltipOpen]);

  const allServices = useMemo(() => {
    return [
      {
        name: 'Fulfillment',
        version: fulfillmentVersion,
        swaggerLink: fulfillmentSwagger,
      },
      {
        name: 'Catalog',
        version: catalogVersion,
        swaggerLink: catalogSwagger,
      },
      {
        name: 'Financials',
        version: financialVersion,
        swaggerLink: financialSwagger,
      },
      {
        name: 'SL Services',
        version: slServicesVersion,
        swaggerLink: slServicesSwagger,
      },
      {
        name: 'Orders',
        version: ordersVersion,
        swaggerLink: ordersSwagger,
      },
      {
        name: 'Accounts',
        version: accountsVersion,
        swaggerLink: accountsSwagger,
      },
      {
        name: 'Email',
        version: emailVersion,
        swaggerLink: emailSwagger,
      },
    ];
  }, [fulfillmentVersion, catalogVersion, financialVersion, slServicesVersion, ordersVersion, accountsVersion, emailVersion]);

  return (
    <footer className='footer' data-toggle='drawer-off'>
      <MaterialTooltip
        open={versionsTooltipOpen}
        interactive={true}
        tooltipText={(
          <div>
            {allServices.map((service, i) => (
              <div key={i}>
                <a
                  target='_blank'
                  href={service.swaggerLink}
                  className='footer-link m-l-6 text--white'
                  rel='noreferrer'
                >
                  {service.name} - {service.version}
                </a>
              </div>
            ))}
          </div>
        )}
      >
        <Button
          icon={(
            <i
              className='material-icons'
              style={{
                width: '20px',
                height: '20px',
                backgroundImage: `url("${serverIcon}")`,
              }}
            />
          )}
          classes={'btn-borderless p-0 footer__version-btn'}
          onClick={toggleVersionsTooltip}
          type={undefined}
          text={''}
        />
      </MaterialTooltip>

      <span className='m-l-12'>
        Homefield Client {packageJson.version}
      </span>
    </footer>
  );
};

export default connector(Footer);
