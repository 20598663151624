/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */
import type { DeviceAssociationDto } from './deviceAssociationDto';

export interface AssociateItemsToDeviceCommand {
  deviceAssociations?: DeviceAssociationDto[] | null;
}
