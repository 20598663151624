/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Catalog Homefield API
 * OpenAPI spec version: v1
 */
import type { DecorationSizeEnum } from './decorationSizeEnum';

export interface UpsertDtgPersonalizationConfigurationCommand {
  printerOverrideNameWidth?: number | null;
  printerOverrideNameHeight?: number | null;
  printerOverrideNumberHeightWithName?: number | null;
  printerOverrideNumberHeightWithoutName?: number | null;
  decorationLocationId?: number;
  decorationSize?: DecorationSizeEnum;
  rotation?: number;
  posX?: number;
  posY?: number;
}
