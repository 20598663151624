/**
 * Squadlocker Api Utility
 */
import { swalAlert } from './componentHelper';
import store from '../store';

function setOptions(options) {
  let newHeaders = {};

  if (options.headers) { newHeaders = options.headers; }
  if (!options.mode) { options.mode = 'cors'; }
  if (store?.getState()?.oidc?.user?.access_token) {
    const token = store.getState().oidc.user.access_token;
    newHeaders.Authorization = `Bearer ${token}`;
  }

  options.headers = newHeaders;

  return options;
}

const Api = {
  fetch(url, options = {}) {
    // Add authetication headers

    options = setOptions(options);

    return new Promise((resolve, reject) => {
      const request = new Request(url, options);

      Api.listener.fire('fetchBefore', request);
      let jsonObj;

      fetch(request)
        // Process response
        .then((response) => {
          if (response.status === 204) { // No-content
            return null;
          }
          if (response.status === 500) {
            throw new Error('Something went wrong. Please try again!');
          }
          if (response.status >= 400) {
            response.json()
              .then((json) => reject(json));
          }

          Api.listener.fire('fetchSuccess', response);

          return response.json();
        })
        .then((json) => {
          if (json?.error) {
            if (json.error === 'invalid_grant') {
              jsonObj = json;
              reject(new Error(json.error_description));
            }
            reject(json.error);
          } else {
            resolve(json);
          }
        })
        .catch((error) => {
          if (jsonObj && jsonObj.error === 'invalid_grant') {
            swalAlert(error);
            reject(error);
          }

          Api.listener.fire('fetchError', error);
        });
    });
  },
  fetchFile(url, options = {}) {
    options = setOptions(options);
    const request = new Request(url, options);

    Api.listener.fire('fetchBefore', request);

    return fetch(request).then((res) => {
      Api.listener.fire('fetchSuccess', res);

      return res.blob();
    })
      .catch((err) => {
        Api.listener.fire('fetchError', err);

        return err;
      });
  },

  listener: {
    events: {
      fetchBefore: [],
      fetchSuccess: [],
      fetchError: [],
    },
    fire(eventName, args) {
      this.events[eventName].forEach((callback) => {
        callback(args);
      });
    },
    /**
     * @param {string} eventName
     * @param {callback} eventCallback
     * @param {null|object} options
     */
    on(eventName, eventCallback, options) {
      options = {
        singleCall: false,
        ...options,
      };

      if (this.events[eventName]) {
        this.events[eventName].push((response) => {
          eventCallback(response);
          if (options.singleCall === true) {
            const index = this.events[eventName].indexOf(eventCallback);
            this.events[eventName].splice(index, 1);
          }
        });
      }
    },
  },
};

export default Api;
