/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Catalog Homefield API
 * OpenAPI spec version: v1
 */
import type { ColoredStyleDetailDto } from './coloredStyleDetailDto';
import type { DtgTabletProfileDto } from './dtgTabletProfileDto';

export interface DtgConfigurationColoredStyleDto {
  id?: number;
  uuid?: string | null;
  created?: string | null;
  updated?: string | null;
  deleted?: string | null;
  dtgEnvironmentId?: number;
  coloredStyleId?: number;
  dtgTabletProfileId?: number;
  dtgDefaultPalletId?: number | null;
  dtgPretreatmentBatchId?: number | null;
  pretreatmentPercent?: number;
  pretreatmentPlatenFahrenheit?: number;
  pretreatmentPsi?: number;
  pretreatmentDwellTimeSeconds?: number;
  postprintDryerFahrenheit?: number;
  postprintProcessTimeMinutes?: number;
  postprintAirSpeed?: number;
  postprintPressFahrenheit?: number;
  postprintPsi?: number;
  postprintDwellTimeSeconds?: number;
  defaultPalletName?: string | null;
  pretreatmentBatchName?: string | null;
  environmentName?: string | null;
  coloredStyle?: ColoredStyleDetailDto;
  dtgTabletProfile?: DtgTabletProfileDto;
}
