import React, { useCallback, useState } from 'react';
import Button from '@components/shared/Buttons/Button';
import { useDropzone } from 'react-dropzone';
import UploadErrors from './UploadErrors';
import { usePostHomefieldApiV1BulkstoresStagingjobsUpload } from '@api/accountsv1/bulk-stores';
import { materialSwal } from '@util/componentHelper';
import { AppBar, Box, Dialog, IconButton, Toolbar, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

interface OwnProps {
  getStagingJobs?: () => void;
}

const UploadNewBulkBuild = ({ getStagingJobs }: OwnProps) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [errorData, setErrorData] = useState(null);

  const closeModal = useCallback(() => {
    setModalIsOpen(false);
  }, []);

  const { mutateAsync: uploadFile } = usePostHomefieldApiV1BulkstoresStagingjobsUpload({ request: { showErrorModal: false } });

  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept: {
      '"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
    },
    maxFiles: 1,
    onDrop: async (acceptedFiles) => {
      try {
        await uploadFile({ data: { file: acceptedFiles[0] }, params: { overrideApprovalStatus: true, createLogos: false } });
      } catch (ex: any) {
        if (!ex.result) {
          materialSwal('Error', ex.message, 'error');
          return;
        }

        const resultKeys = Object.keys(ex.result);
        if (resultKeys.filter((key) => key.startsWith('orgs') || key.startsWith('stores')).length > 0) {
          setErrorData(ex.result);
          setModalIsOpen(true);
        } else {
          materialSwal('Error', 'Unknown error', 'error');
        }
      } finally {
        if (getStagingJobs) {
          getStagingJobs();
        }
      }
    },
  });

  return (
    <>
      <Dialog fullScreen={true} open={modalIsOpen} onClose={closeModal}>
        <AppBar style={{ position: 'sticky' }}>
          <Toolbar>
            <Box flex={1} display='flex' justifyContent='space-between' alignItems='center'>
              <Typography variant='h6'>Build Build Upload Error(s)</Typography>
              <IconButton edge='start' color='inherit' onClick={closeModal} aria-label='close'>
                <CloseIcon />
              </IconButton>
            </Box>
          </Toolbar>
        </AppBar>
        <UploadErrors data={errorData} />
      </Dialog>
      <div {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
        <Button
          type='primary'
          text='Upload File'
          onClick={open}
        />
      </div>
    </>
  );
};

export default UploadNewBulkBuild;
