import { HagPrintRoomJobPagedListDto } from '@api/fulfillment/models';
import Button from '@sharedComponents/Buttons/Button';
import React, { useCallback } from 'react';

interface OwnProps {
  items: Array<HagPrintRoomJobPagedListDto>;
  handleStatusUpdate: (ids: number[]) => void;
  linkText: string;
}

type Props = OwnProps;

const UpdateBulkMarkInPrint = React.memo<Props>(({
  items,
  handleStatusUpdate,
  linkText,
}) => {
  const update = useCallback((e) => {
    e.preventDefault();
    const jobIds: number[] = items.map((selectedItem) => selectedItem.hagPrintRoomJobId!);
    handleStatusUpdate(jobIds);
  }, [
    items,
    handleStatusUpdate,
  ]);

  return (
    <Button
      type={'secondary'}
      text={linkText}
      onClick={update}
      size={'s'}
      classes={'actions-row__action--button ml-20'}
    />
  );
});

export default UpdateBulkMarkInPrint;
