import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

const OrganizationsManagePublicPageHeader = React.memo(({ organization }) => {
  const title = (organization?.name)
    ? `Manage Public Page - ${organization.name}`
    : 'Manage Public Page';

  return (
    <div className='bar__long'>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className='header__bar-content'>
        <h2 className='header__bar-title'>
          {title}
        </h2>
      </div>
    </div>
  );
});

OrganizationsManagePublicPageHeader.propTypes = {
  organization: PropTypes.object.isRequired,
};

const mapStateToProps = ({ organizations }) => ({
  organization: organizations.organization,
});

export default connect(mapStateToProps)(OrganizationsManagePublicPageHeader);
