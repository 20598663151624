/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Catalog Homefield API
 * OpenAPI spec version: v1
 */

export interface CreateProductVendorMappingCommand {
  sku?: string | null;
  vendorId?: string | null;
  esiUpcNumber?: string | null;
  rbiUpc?: string | null;
  vendorItemNumber?: string | null;
}
