/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */
import type { ArtworkTableByStatusEnum } from './artworkTableByStatusEnum';
import type { DecorationMethodEnum } from './decorationMethodEnum';
import type { ParentLockerEnum } from './parentLockerEnum';
import type { SortByEnum } from './sortByEnum';
import type { SortDirectionEnum } from './sortDirectionEnum';

export type HomefieldApiArtworkLockersGETParams = { pageNumber?: number; pageSize?: number; status?: ArtworkTableByStatusEnum; resourceId?: string; decorationMethod?: DecorationMethodEnum; lockerPartnerName?: string; parentLockerFilter?: ParentLockerEnum; sortBy?: SortByEnum; sortDirection?: SortDirectionEnum };
