import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import {
  addVoucherOrderUrl,
  adminDetailsBaseUrl,
  adminsUrl,
  advancedOptionsUrl,
  applicationSettingsUrl,
  artVectorizationUrl,
  artworkTasksUrl,
  backorderManagementUrl,
  batchWarehouseSchedulingUrl,
  brandsUrl,
  bulkActionsUrl,
  bulkAddItemUrl,
  bulkAddStyleUrl,
  bulkBuildUrl,
  categoriesUrl,
  collectionDetailsBaseUrl,
  colorsUrl,
  couponsUrl,
  decorationLocationArtworkSizesUrl,
  decorationLocationsUrl,
  dtgArtworkSettingsUrl,
  dtgEntitiesUrl,
  dtgEnvironmentsUrl,
  dtgPersonalizationSettingsUrl,
  dtgStationsUrl,
  dtgTabletProfilesUrl,
  fundraisingPayoutsUrl,
  layoutsUrl,
  llSchedulingDetailsUrl,
  llSchedulingUrl,
  lockerDetailsBaseUrl,
  lockerManagerUrl,
  logoBankUrl,
  logoReviewUrl,
  lookupEntitiesUrl,
  merchandisingUrl,
  onHandInventoryUrl,
  orderAssemblyUrl,
  orderDetailsBaseUrl,
  orderDetailsNewOrder,
  orderDetailsNewOrderAddItems,
  orderItemFilesUrl,
  orderSearchUrl,
  organizationDetailsBaseUrl,
  organizationGroupsBaseUrl,
  organizationsUrl,
  parentCategoriesUrl,
  printAssemblyUrl,
  printFilesUrl,
  printRoomOrderBaseUrl,
  printSheetBaseUrl,
  productCatalogUrl,
  productionAssemblyDtgUrl,
  productionAssemblyEmbUrl,
  productionAssemblyHagUrl,
  productionAssemblyUrl,
  productionOverviewUrl,
  productionUrl,
  qualityAssuranceCodesUrl,
  qualityCheckUrl,
  reorderCollectionItemsPath,
  reportsUrl,
  rosterDetailsBaseUrl,
  rosterManagerUrl,
  serviceManagerUrl,
  skuSetupUrl,
  sportsUrl,
  vendorOrdersUrl,
  vendorsUrl,
  voucherOrderDetailsBaseUrl,
  voucherOrdersUrl,
  warehouseSchedulingUrl,
} from '@constants/clientUrls/clientUrls';
import { RootState } from '@redux/index/reducers';
import TextHeaderTitle from './TextHeaderTitle';
import ArtworkFileTasksDetails from './ArtworkFileTasksHeader/ArtworkFileTasksDetails';
import OrderManagementSidebarSteps from './OrderManagementHeader/OrderManagementSidebarSteps';
import OrderManagementHeaderTitle from './OrderManagementHeader/OrderManagementHeaderTitle';
import PrintSheetWithStatusHeader from './PrintSheetHeader/PrintSheetWithStatusHeader';
import WeedingAndMaskingHeader from './WeedingAndMaskingHeader/WeedingAndMaskingHeader';
import OrderManagementTable from './OrderManagementHeader/OrderManagementTable';
import OrderManagementAddNewOrderHeaderTitle from './OrderManagementHeader/OrderManagementAddNewOrderHeaderTitle';
import LockerManagerDetailsHeader from './LockerManagerHeader/LockerManagerDetailsHeader';
import OrganizationsManagePublicPageHeader from './OrganizationsHeader/OrganizationsManagePublicPageHeader';
import CollectionDetailsHeader from './CollectionsHeader/CollectionDetailsHeader';
import ReorderCollectionItemsHeader from './CollectionsHeader/ReorderCollectionItemsHeader';
import VoucherOrderCreationHeaderSteps from './VouchersHeader/VoucherOrderCreationHeaderSteps';
import VoucherOrderDetailsHeader from './VouchersHeader/VoucherOrderDetailsHeader';
import { useFlags } from 'launchdarkly-react-client-sdk';
import StatusHeaderTitle from './StatusHeaderTitle';

interface OwnProps {
  location?: string;
  schedulingQueue: any;
}

const mapStateToProps = ({ llScheduling }: RootState) => ({
  schedulingQueue: llScheduling.schedulingQueue,
});

const connector = connect(mapStateToProps);

type Props = OwnProps & ConnectedProps<typeof connector>;

const HeaderContents = React.memo(({
  location,
  schedulingQueue,
}: Props) => {
  const { hfHideOldProduction } = useFlags();

  // Extract ID from URL if the last part is a number
  const getIdFromUrl = () => {
    if (!location) return undefined;
    const lastPart = location.split('/').pop();
    return lastPart && !isNaN(parseInt(lastPart)) ? parseInt(lastPart) : undefined;
  };

  const id = getIdFromUrl();

  if (location?.includes(adminsUrl)) {
    return (
      <TextHeaderTitle text={'Admin Manager'} id={id} />
    );
  }

  if (location?.includes(adminDetailsBaseUrl)) {
    return (
      <TextHeaderTitle text={'Admin Manager'} id={id} />
    );
  }

  if (location?.includes(advancedOptionsUrl)) {
    return (
      <TextHeaderTitle text={'Advanced Options'} id={id} />
    );
  }

  if (location?.includes(orderDetailsNewOrder)) {
    return (
      <div className='bar__long'>
        <div className='bar__order flex'>
          <OrderManagementAddNewOrderHeaderTitle />
          <OrderManagementSidebarSteps isTempOrder={true} />
        </div>
      </div>
    );
  }

  if (location?.includes(orderDetailsNewOrderAddItems)) {
    return (
      <TextHeaderTitle text={'Add Items to Order'} id={id} />
    );
  }

  if (location?.includes(applicationSettingsUrl)) {
    return (
      <TextHeaderTitle text={'Application Settings'} id={id} />
    );
  }

  if (location?.includes(artVectorizationUrl)) {
    return (
      <TextHeaderTitle text={'Production Artwork'} id={id} />
    );
  }

  if (location?.includes(artworkTasksUrl)) {
    return (
      <div className='bar__long'>
        <ArtworkFileTasksDetails />
      </div>
    );
  }

  if (location?.includes(backorderManagementUrl)) {
    return (
      <TextHeaderTitle text={'Backorder Management'} id={id} />
    );
  }

  if (location?.includes(brandsUrl)) {
    return (
      <TextHeaderTitle text={'Manage Brands'} id={id} />
    );
  }

  if (location?.includes(collectionDetailsBaseUrl) && location?.includes(reorderCollectionItemsPath)) {
    return <ReorderCollectionItemsHeader />;
  }

  if (location?.includes(collectionDetailsBaseUrl)) {
    return <CollectionDetailsHeader />;
  }

  if (location?.includes(couponsUrl)) {
    return (
      <TextHeaderTitle text={'Coupon Codes'} id={id} />
    );
  }

  if (location?.includes(categoriesUrl)) {
    return (
      <TextHeaderTitle text={'Manage Categories'} id={id} />
    );
  }

  if (location?.includes(parentCategoriesUrl)) {
    return (
      <TextHeaderTitle text={'Manage Parent Categories'} id={id} />
    );
  }

  if (location?.includes(decorationLocationsUrl)) {
    return (
      <TextHeaderTitle text={'Manage Decoration Locations'} id={id} />
    );
  }

  if (location?.includes(decorationLocationArtworkSizesUrl)) {
    return (
      <TextHeaderTitle text={'Manage Artwork Sizes'} id={id} />
    );
  }

  if (location?.includes(colorsUrl)) {
    return (
      <TextHeaderTitle text={'Manage Colors'} id={id} />
    );
  }

  if (location?.includes(layoutsUrl)) {
    return (
      <TextHeaderTitle text={'Manage Layouts'} id={id} />
    );
  }

  if (location?.includes(vendorsUrl)) {
    return (
      <TextHeaderTitle text={'Manage Vendors'} id={id} />
    );
  }

  if (location?.includes(fundraisingPayoutsUrl)) {
    return (
      <TextHeaderTitle text={'Fundraising Payouts'} id={id} />
    );
  }

  if (location?.includes(logoReviewUrl)) {
    return (
      <TextHeaderTitle text={'Logo Review'} id={id} />
    );
  }

  if (location?.includes(lockerDetailsBaseUrl) || location?.includes(logoBankUrl)) {
    return <LockerManagerDetailsHeader />;
  }

  if (location?.includes(lockerManagerUrl)) {
    return (
      <TextHeaderTitle text={'Locker Manager'} id={id} />
    );
  }

  if (location?.includes(lookupEntitiesUrl)) {
    return (
      <TextHeaderTitle text={'Lookup Entities'} id={id} />
    );
  }

  if (location?.includes(merchandisingUrl)) {
    return (
      <TextHeaderTitle text={'Merchandising'} id={id} />
    );
  }

  if (location?.includes(onHandInventoryUrl)) {
    return (
      <TextHeaderTitle text={'On-Hand Inventory'} id={id} />
    );
  }

  if (location?.includes(productCatalogUrl)) {
    return (
      <TextHeaderTitle text={'Product Catalog'} id={id} />
    );
  }

  if (location?.includes(printFilesUrl)) {
    return (
      <TextHeaderTitle text={'Print Room'} id={id} />
    );
  }

  if (location?.includes(rosterManagerUrl)) {
    return (
      <TextHeaderTitle text={'Roster Manager'} id={id} />
    );
  }

  if (location?.includes(rosterDetailsBaseUrl)) {
    return (
      <TextHeaderTitle text={'Roster Details'} id={id} />
    );
  }

  if (location?.includes(orderDetailsBaseUrl)) {
    const orderNumber = parseInt(location.split('/').pop()!, 10);
    return (
      <div className='bar__long'>
        <div className='bar__order flex'>
          <OrderManagementHeaderTitle orderNumber={orderNumber} />
          <OrderManagementSidebarSteps />
        </div>
      </div>
    );
  }

  if (location?.includes(orderSearchUrl)) {
    return <OrderManagementTable />;
  }

  if (location?.includes(organizationsUrl)) {
    return (
      <TextHeaderTitle text={'Organizations'} id={id} />
    );
  }

  if (location?.includes(organizationGroupsBaseUrl)) {
    return <OrganizationsManagePublicPageHeader />;
  }

  if (location?.includes(organizationDetailsBaseUrl)) {
    return (
      <TextHeaderTitle text={'Organization Details'} id={id} />
    );
  }

  if (location?.includes(printSheetBaseUrl)) {
    return <PrintSheetWithStatusHeader />;
  }

  if (location?.includes(printRoomOrderBaseUrl)) {
    return <StatusHeaderTitle />;
  }

  if (location?.includes(printAssemblyUrl)) {
    return <WeedingAndMaskingHeader />;
  }

  if (location?.includes(productCatalogUrl)) {
    return (
      <TextHeaderTitle text={'Product Catalog'} id={id} />
    );
  }

  if (location?.includes(productionAssemblyUrl) && !hfHideOldProduction) {
    return (
      <TextHeaderTitle text={'Production'} id={id} />
    );
  }

  if (location?.includes(orderAssemblyUrl)) {
    return (
      <TextHeaderTitle text={'Order Assembly'} id={id} />
    );
  }

  if (location?.includes(qualityCheckUrl)) {
    return (
      <TextHeaderTitle text={'Quality Check'} id={id} />
    );
  }

  if (location?.includes(productionAssemblyHagUrl)) {
    return (
      <TextHeaderTitle text={'HAG Production Assembly'} id={id} />
    );
  }

  if (location?.includes(productionAssemblyDtgUrl)) {
    return (
      <TextHeaderTitle text={'DTG Production Assembly'} id={id} />
    );
  }

  if (location?.includes(productionAssemblyEmbUrl)) {
    return (
      <TextHeaderTitle text={'EMB Production Assembly'} id={id} />
    );
  }

  if (location?.includes(productionOverviewUrl)) {
    return (
      <TextHeaderTitle text={'Production Overview'} id={id} />
    );
  }

  if (location?.includes(productionUrl)) {
    return (
      <TextHeaderTitle text={'Production'} id={id} />
    );
  }

  if (location?.includes(llSchedulingDetailsUrl)) {
    return (
      <TextHeaderTitle text={`${schedulingQueue.decorationMethod !== null ? schedulingQueue.decorationMethod : 'No decoration'} Scheduling`} id={id} />
    );
  }

  if (location?.includes(llSchedulingUrl)) {
    return (
      <TextHeaderTitle text={'LL Scheduling'} id={id} />
    );
  }

  if (location?.includes(serviceManagerUrl)) {
    return (
      <TextHeaderTitle text={'Service Manager'} id={id} />
    );
  }

  if (location?.includes(sportsUrl)) {
    return (
      <TextHeaderTitle text={'Manage Sports'} id={id} />
    );
  }

  if (location?.includes(skuSetupUrl)) {
    return (
      <TextHeaderTitle text={'SKU Setup'} id={id} />
    );
  }

  if (location?.includes(vendorOrdersUrl)) {
    return (
      <TextHeaderTitle text={'Vendor Order Management'} id={id} />
    );
  }

  if (location?.includes(dtgEnvironmentsUrl)) {
    return (
      <TextHeaderTitle text={'DTG Environment Settings'} id={id} />
    );
  }

  if (location?.includes(dtgArtworkSettingsUrl)) {
    return (
      <TextHeaderTitle text={'DTG Artwork Settings'} id={id} />
    );
  }

  if (location?.includes(dtgPersonalizationSettingsUrl)) {
    return (
      <TextHeaderTitle text={'DTG Personalization Settings'} id={id} />
    );
  }

  if (location?.includes(dtgStationsUrl)) {
    return (
      <TextHeaderTitle text={'DTG Stations'} id={id} />
    );
  }

  if (location?.includes(dtgTabletProfilesUrl)) {
    return (
      <TextHeaderTitle text={'DTG Tablet Profiles'} id={id} />
    );
  }

  if (location?.includes(dtgEntitiesUrl)) {
    return (
      <TextHeaderTitle text={'DTG Printer'} id={id} />
    );
  }

  if (location?.includes(warehouseSchedulingUrl)) {
    return (
      <TextHeaderTitle text={'Scheduling 2.0'} id={id} />
    );
  }

  if (location?.includes(addVoucherOrderUrl)) {
    return (
      <div className='bar__long'>
        <div className='bar__order flex'>
          <TextHeaderTitle text={'Add Voucher Order'} id={id} />
          <VoucherOrderCreationHeaderSteps />
        </div>
      </div>
    );
  }

  if (location?.includes(voucherOrderDetailsBaseUrl)) {
    return <VoucherOrderDetailsHeader location={location!} />;
  }

  if (location?.includes(voucherOrdersUrl)) {
    return (
      <TextHeaderTitle text={'Voucher Orders'} id={id} />
    );
  }

  if (location?.includes(bulkAddItemUrl)) {
    return (
      <TextHeaderTitle text={'Add Replacement Item'} id={id} />
    );
  }

  if (location?.includes(bulkActionsUrl)) {
    return <TextHeaderTitle text={'Bulk Actions'} />;
  }

  if (location?.includes(bulkBuildUrl) && location?.split('/').length === 3) {
    return <TextHeaderTitle text={'Bulk Build Details'} />;
  }

  if (location?.includes(bulkBuildUrl)) {
    return <TextHeaderTitle text={'Bulk Build'} />;
    return (
      <TextHeaderTitle text={'Bulk Actions'} id={id} />
    );
  }

  if (location?.includes(bulkAddStyleUrl)) {
    return (
      <TextHeaderTitle text={'Add Style to All Stores'} id={id} />
    );
  }

  if (location?.includes(qualityAssuranceCodesUrl)) {
    return (
      <TextHeaderTitle text={'Quality Assurance Codes'} id={id} />
    );
  }

  if (location?.includes(batchWarehouseSchedulingUrl)) {
    return <StatusHeaderTitle />;
  }

  if (location?.includes(reportsUrl)) {
    return (
      <TextHeaderTitle text={'Reports'} id={id} />
    );
  }

  if (location?.includes(orderItemFilesUrl)) {
    return (
      <TextHeaderTitle text={'Order Item Files'} id={id} />
    );
  }

  return null;
});

export default connector(HeaderContents);
