import React, { useMemo } from 'react';
import MaterialTooltip from '@components/shared/Tooltips/MaterialTooltip';
import { Box, Checkbox, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, ListSubheader, Typography } from '@material-ui/core';
import BusinessIcon from '@material-ui/icons/Business';
import StorefrontIcon from '@material-ui/icons/Storefront';
import { StagingJobDetailsDto } from '@api/accountsv1/models';
import { patchHomefieldApiV1BulkstoresStagingorgsIdUpdateusercompleted, patchHomefieldApiV1BulkstoresStagingstoresIdUpdateusercompleted } from '@api/accountsv1/bulk-stores';

type OwnProps = {
  rootClassName?: string;
  subHeaderClassName?: string;
  listItemClassName?: string;
  childListClassName?: string;
  nestedClassName?: string;
  refetchData: () => void;
  job?: StagingJobDetailsDto;
};

const BulkBuildJobSDetailsList = React.memo(({
  rootClassName,
  subHeaderClassName,
  listItemClassName,
  childListClassName,
  nestedClassName,
  refetchData,
  job,
}: OwnProps) => {
  const handleUserCompletedChange = async (checked: boolean, type: string, id: number) => {
    switch (type) {
      case 'organization':
        patchHomefieldApiV1BulkstoresStagingorgsIdUpdateusercompleted(id, { isComplete: checked }, { showErrorModal: true });
        break;
      case 'store':
        patchHomefieldApiV1BulkstoresStagingstoresIdUpdateusercompleted(id, { isComplete: checked }, { showErrorModal: true });
        break;
      default:
        break;
    }
  };

  const handleListItemOnClick = (url?: string | null) => {
    if (!url) {
      return;
    }

    window.open(url, '_blank');
  };

  const createCheckboxHandler = (orgId: number, type: string) => {
    return async (_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      await handleUserCompletedChange(checked, type, orgId);
      setTimeout(() => {
        refetchData();
      }, 100);
    };
  };

  const createListItemClickHandler = (url?: string | null) => {
    return () => {
      handleListItemOnClick(url);
    };
  };

  const disabled = useMemo(() => {
    return job?.status !== 'Done';
  }, [job?.status]);

  return (
    <List
      className={rootClassName}
      dense={true}
      subheader={(
        <ListSubheader className={subHeaderClassName}>
          <Box display='flex' justifyContent='space-between' alignItems='center'>
            <Typography>Organization</Typography>
            <Typography>Complete</Typography>
          </Box>
        </ListSubheader>
      )}
    >
      {job?.stagingOrganizations?.map((org) => (
        <React.Fragment key={org.id}>
          <MaterialTooltip tooltipText={org.homefieldUrl} placement='top'>
            <ListItem className={listItemClassName} button={true} disabled={disabled} {...(!disabled && { onClick: createListItemClickHandler(org.homefieldUrl) })}>
              <ListItemIcon>
                <BusinessIcon />
              </ListItemIcon>
              <ListItemText primary={org.organizationName ?? `Existing Organization: ${org.existingOrganizationId}`} />
              <ListItemSecondaryAction>
                <Checkbox
                  edge='end'
                  color='primary'
                  disabled={disabled}
                  checked={org.userCompleted}
                  onChange={createCheckboxHandler(org.id, 'organization')}
                  inputProps={{ 'aria-labelledby': org.id.toString() }}
                />
              </ListItemSecondaryAction>
            </ListItem>
          </MaterialTooltip>
          <List
            className={childListClassName}
            dense={true}
            subheader={(
              <ListSubheader className={subHeaderClassName}>
                <Box display='flex' justifyContent='space-between' alignItems='center'>
                  <Typography>{org.organizationName} - Stores</Typography>
                  <Typography>Complete</Typography>
                </Box>
              </ListSubheader>
            )}
          >
            {org.stagingStores?.map((store) => (
              <MaterialTooltip key={store.id} tooltipText={store.homefieldUrl} placement='top'>
                <ListItem
                  key={store.id}
                  className={`${nestedClassName} ${listItemClassName}`}
                  button={true}
                  disabled={disabled}
                  {...(!disabled && { onClick: createListItemClickHandler(store.homefieldUrl) })}
                >
                  <ListItemIcon>
                    <StorefrontIcon />
                  </ListItemIcon>
                  <ListItemText primary={store.storeName} />
                  <ListItemSecondaryAction>
                    <Checkbox
                      edge='end'
                      color='primary'
                      disabled={disabled}
                      checked={store.userCompleted}
                      onChange={createCheckboxHandler(store.id, 'store')}
                      inputProps={{ 'aria-labelledby': store.id.toString() }}
                    />
                  </ListItemSecondaryAction>
                </ListItem>
              </MaterialTooltip>
            ))}
          </List>
        </React.Fragment>
      ))}
    </List>
  );
});

export default BulkBuildJobSDetailsList;
