/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Orders Homefield API
 * OpenAPI spec version: v1
 */
import type { OrderPricingDetails } from './orderPricingDetails';

export interface OrderPricingDetailsApiResponse {
  success?: boolean;
  message?: string | null;
  result?: OrderPricingDetails;
}
