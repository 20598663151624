/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */
import type { DecorationFlagTypeEnum } from './decorationFlagTypeEnum';

export interface FlagDto {
  id?: number;
  orderItemDecorationId?: number | null;
  orderItemId?: number | null;
  dateCreated?: string | null;
  user?: string | null;
  reason?: string | null;
  note?: string | null;
  attachmentUrl?: string | null;
  type?: DecorationFlagTypeEnum;
  isUnflagged?: boolean;
}
