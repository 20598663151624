/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Catalog Homefield API
 * OpenAPI spec version: v1
 */

export interface UpdateVendorCommand {
  id?: number | null;
  code?: string | null;
  name?: string | null;
  leadTime?: number | null;
  vendorSystemUrl?: string | null;
  dropshipVFIs?: boolean;
  vfiOrderAutoSubmit?: boolean;
  vfiOrderTreshold?: number;
  vfiOrderTimeHours?: number;
  vfiMaxPersonalizationNameLength?: number | null;
}
