import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { uploadFile } from '@APICalls/support/actions';
import { uploadFile as uploadFileUrl } from '@constants/sqdApiUrls/productCatalogUtilityUrls';
import { catalogApi } from '../../../../sqdApis';
import Icon from '@sharedComponents/Icons/Icon';
import DropZoneWrapper from '@sharedComponents/Upload/DropZoneWrapper';

const acceptedFileType = { 'Custom Files': ['.png'] };

class EditColoredStyleUploadFile extends PureComponent {
  state = {
    loading: false,
  };

  onDrop = (acceptedFiles, rejectedFiles) => {
    const {
      change,
      changeImageUrl,
      accessor,
    } = this.props;
    let message = '';

    const reader = new FileReader();
    const file = acceptedFiles[0];

    if (rejectedFiles.length > 0) {
      message = {
        type: 'reject',
        body: `${acceptedFileType} files required.`,
      };
      changeImageUrl(null, message);
    } else {
      reader.onloadend = () => {
        const { result } = reader;
        message = {
          body: `File ${file.name} has been attached.`,
          type: 'default',
        };
        changeImageUrl(result, message);
      };

      reader.readAsDataURL(file);

      this.setState(() => ({ loading: true }), async () => {
        const res = await uploadFile(file, catalogApi, uploadFileUrl);
        if (res) {
          change(accessor, res);
        }
        this.setState(() => ({ loading: false }));
      });
    }
  };

  render() {
    const {
      uploadIsActive,
      message,
    } = this.props;
    const { loading } = this.state;

    const dropzoneContent = loading
      ? (
        <label className='button'>
          <Icon
            fontAwesomeIcon={'spinner'}
            classes={'fa-spin'}
          />
          Uploading...
        </label>
      )
      : <label className={`button message-${message.type}`}>{message.body}</label>;

    return (
      <div className={`mb-10 p-l-16 p-r-16 lockerEdit__information-change-logos ${uploadIsActive && 'is-active'}`}>
        <div className='upload-field--horizontal m-t-16 w-100'>
          <div className='upload-field '>
            <DropZoneWrapper
              onDrop={this.onDrop}
              multiple={false}
              className={'block-drop-zone'}
              accept={acceptedFileType}
            >
              {dropzoneContent}
            </DropZoneWrapper>
          </div>
        </div>
      </div>
    );
  }
}

EditColoredStyleUploadFile.propTypes = {
  message: PropTypes.object.isRequired,
  change: PropTypes.func,
  accessor: PropTypes.string.isRequired,
  changeImageUrl: PropTypes.func.isRequired,
  uploadIsActive: PropTypes.bool.isRequired,
};

export default connect()(EditColoredStyleUploadFile);
