import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import DropZoneWrapper from '@sharedComponents/Upload/DropZoneWrapper';
import Icon from '@sharedComponents/Icons/Icon';
import { Accept, FileRejection } from 'react-dropzone/.';

const acceptedFileType = { 'Custom Files': ['.png'] } as Accept;

export interface UploadFileMessage {
  type: 'default' | 'reject';
  body: string;
}

interface OwnProps {
  changeImageUrl: (
    url: Nullable<string>,
    message: UploadFileMessage,
    acceptedFile: Nullable<File>,
  ) => void;
  maxWidth?: number;
  uploadIsActive: boolean;
  message: UploadFileMessage;
  multiple?: boolean;
  acceptedFileTypes?: Accept;
}

type Props = OwnProps;

const UploadImage = React.memo<Props>(({
  uploadIsActive,
  message,
  changeImageUrl,
  maxWidth,
  multiple = false,
  acceptedFileTypes = acceptedFileType,
}) => {
  const [
    loading,
  ] = useState<boolean>(false);

  const onDrop = useCallback((acceptedFiles: File[], rejectedFiles: FileRejection[]): void => {
    let newMessage: UploadFileMessage | undefined;

    if (rejectedFiles.length > 0) {
      newMessage = {
        type: 'reject',
        body: `${acceptedFileType} files required.`,
      };
      changeImageUrl(null, newMessage, null);
    } else {
      acceptedFiles.forEach((file) => {
        const reader = new FileReader();
        const image = new Image();

        reader.onloadend = () => {
          const result = reader.result as string;

          // this component is used for uploading ai files as well, so
          // this check will prevent the image from being displayed
          if (file.type.toLowerCase().includes('image') === false) {
            newMessage = {
              body: `File ${file.name} has been attached.`,
              type: 'default',
            };
            changeImageUrl(result, newMessage, file);

            return;
          }

          image.src = result;
          image.onload = () => {
            if (maxWidth && image.width > maxWidth) {
              newMessage = {
                body: `Image width should be ${maxWidth}px or less.`,
                type: 'default',
              };
              changeImageUrl(null, newMessage, null);
            } else {
              newMessage = {
                body: `File ${file.name} has been attached.`,
                type: 'default',
              };
              changeImageUrl(result, newMessage, file);
            }
          };
        };

        reader.readAsDataURL(file);
      });
    }
  }, [
    changeImageUrl,
    maxWidth,
  ]);

  const dropzoneContent = loading
    ? (
      <label className='button'>
        <Icon
          fontAwesomeIcon={'spinner'}
          classes={'fa-spin'}
        />
        Uploading...
      </label>
    )
    : <label className={`button message-${message.type}`}>{message.body}</label>;

  return (
    <div className={`mb-10 p-l-16 p-r-16 lockerEdit__information-change-logos ${uploadIsActive && 'is-active'}`}>
      <div className='upload-field--horizontal m-t-16 w-100'>
        <div className='upload-field'>
          <DropZoneWrapper
            onDrop={onDrop}
            multiple={multiple}
            className={'block-drop-zone'}
            accept={acceptedFileTypes}
          >
            {dropzoneContent}
          </DropZoneWrapper>
        </div>
      </div>
    </div>
  );
});

export default connect()(UploadImage);
