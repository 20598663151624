/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Financials Homefield API
 * OpenAPI spec version: v1
 */
import type { LockerManagerDto } from './lockerManagerDto';

export interface PayoutRecipientDto {
  id?: number;
  uuid?: string | null;
  created?: string | null;
  updated?: string | null;
  deleted?: string | null;
  gpDocumentId?: string | null;
  name?: string | null;
  email?: string | null;
  lockerManager?: LockerManagerDto;
  addressName?: string | null;
  addressLine1?: string | null;
  addressLine2?: string | null;
  city?: string | null;
  state?: string | null;
  zip?: string | null;
  country?: string | null;
  erpRecipientId?: number | null;
}
