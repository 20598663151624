/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */
import type { ArtworkTaskItemTriageDto } from './artworkTaskItemTriageDto';

export interface TriageArtworkTaskCommand {
  items?: ArtworkTaskItemTriageDto[] | null;
}
