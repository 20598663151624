/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */
import type { ArtworkTaskStatusEnum } from './artworkTaskStatusEnum';

export interface UpdateArtworkTaskStatusCommand {
  taskId?: number;
  status?: ArtworkTaskStatusEnum;
}
