/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */
import type { HagPrintRoomJobStatusEnum } from './hagPrintRoomJobStatusEnum';

export interface ProgressHagPrintRoomJobStatusCommand {
  hagPrintRoomJobId?: number;
  toStatus?: HagPrintRoomJobStatusEnum;
  override?: boolean | null;
}
