import {
  DtgStationDto,
  SubmitStatusEnum,
} from '@api/fulfillment/models';
import { organizationLandingPageEnum } from '@constants/enums/organizationEnums';
import { decorationSizeEnum } from '@constants/enums/decorationEnums';
import { FilterOrdersOptionsEnum } from '@constants/enums/orderEnums';
import { decorationLocations } from '@constants/common';
import { Option } from '@models/common/Option';
import {
  organizationSizes,
  organizationTypes,
  adminRoles,
  playersCount,
  teamGenders,
  lockerTeamTypes,
  productAges,
  productGenders,
  decorationMethodsOptions,
  couponReasons,
  discountTypes,
  couponStatuses,
  rosterStatuses,
  lockerStatuses,
  classifications,
  decorationSizes,
  organizationLandingPageTypes,
  orderChargeReasonOptions,
  parentLockerOptions,
  personalizationTypeOptions,
  collectionStatuses,
  expressProductionTypeOptions,
  adminOrganizationTypeOptions,
  dtgPalletSizeOptions,
  dtgHighlightGeneratorOptions,
  numberOfRowsOptions,
  numberOfRowPositionsOptions,
  ptlWallBinSizeOptions,
} from './optionsValues';

export const getOrganizationSizeOptions = (
  includeEmptyValueOption = true,
  emptyValueOptionText = 'All sizes'
): Option<string>[] => {
  const options: Option<string>[] = [...organizationSizes];

  if (includeEmptyValueOption) {
    options.unshift({
      key: 0,
      value: '',
      name: emptyValueOptionText,
    });
  }

  return options;
};

export const getOrganizationTypeOptions = (
  includeEmptyValueOption = true,
  emptyValueOptionText = 'All types'
): Option<string>[] => {
  const options: Option<string>[] = [...organizationTypes];

  if (includeEmptyValueOption) {
    options.unshift({
      key: 0,
      value: '',
      name: emptyValueOptionText,
    });
  }

  return options;
};

export const getOrganizationLandingPageOptions = (): Option<boolean>[] => {
  const options = [
    {
      key: 1,
      value: false,
      name: organizationLandingPageEnum.OFF,
    },
    {
      key: 2,
      value: true,
      name: organizationLandingPageEnum.ON,
    },
  ];

  return options;
};

export const getOrganizationLandingPageFilterOptions = (
  includeEmptyValueOption = true,
  emptyValueOptionText = 'All landing page types'
): Option<string>[] => {
  const options: Option<string>[] = [...organizationLandingPageTypes];

  if (includeEmptyValueOption) {
    options.unshift({
      key: 0,
      value: '',
      name: emptyValueOptionText,
    });
  }

  return options;
};

export const getClassificationOptions = (
  includeEmptyValueOption = true,
  emptyValueOptionText = 'Unclassified'
): Option<string>[] => {
  const options: Option<string>[] = [...classifications];

  if (includeEmptyValueOption) {
    options.unshift({
      key: 0,
      value: '',
      name: emptyValueOptionText,
    });
  }

  return options;
};

export const getAdminRolesOptions = (
  includeEmptyValueOption = true,
  emptyValueOptionText = 'All types'
): Option<string>[] => {
  const options: Option<string>[] = [...adminRoles];

  if (includeEmptyValueOption) {
    options.unshift({
      key: 0,
      value: '',
      name: emptyValueOptionText,
    });
  }

  return options;
};

export const getPlayersCountOptions = (
  includeEmptyValueOption = true,
  emptyValueOptionText = 'All sizes'
): Option<string>[] => {
  const options: Option<string>[] = [...playersCount];

  if (includeEmptyValueOption) {
    options.unshift({
      key: 0,
      value: '',
      name: emptyValueOptionText,
    });
  }

  return options;
};

export const getTeamGendersOptions = (
  includeEmptyValueOption = true,
  emptyValueOptionText = 'All sizes'
): Option<string>[] => {
  const options: Option<string>[] = [...teamGenders];

  if (includeEmptyValueOption) {
    options.unshift({
      key: 0,
      value: '',
      name: emptyValueOptionText,
    });
  }

  return options;
};

export const getLockerTeamTypeOptions = (
  includeEmptyValueOption = true,
  emptyValueOptionText = 'Team type'
): Option<string>[] => {
  const options: Option<string>[] = [...lockerTeamTypes];

  if (includeEmptyValueOption) {
    options.unshift({
      key: 0,
      value: '',
      name: emptyValueOptionText,
    });
  }

  return options;
};

export const getProductAgeOptions = (
  includeEmptyValueOption = true,
  emptyValueOptionText = 'All ages'
): Option<string>[] => {
  const options: Option<string>[] = [...productAges];

  if (includeEmptyValueOption) {
    options.unshift({
      key: 0,
      value: '',
      name: emptyValueOptionText,
    });
  }

  return options;
};

export const getProductGenderOptions = (
  includeEmptyValueOption = true,
  emptyValueOptionText = 'All genders'
): Option<string>[] => {
  const options: Option<string>[] = [...productGenders];

  if (includeEmptyValueOption) {
    options.unshift({
      key: 0,
      value: '',
      name: emptyValueOptionText,
    });
  }

  return options;
};

export const getDecorationMethodsOptions = (
  includeEmptyValueOption = true,
  emptyValueOptionText = 'All decoration methods'
): Option<string>[] => {
  const options: Option<string>[] = [...decorationMethodsOptions];

  if (includeEmptyValueOption) {
    options.unshift({
      key: 0,
      value: 'Undefined',
      name: emptyValueOptionText,
    });
  }

  return options;
};

export const getTrueOrFalseOptions = (trueLabel = 'Yes', falseLabel = 'No'): Option<string>[] => {
  const options = [
    {
      key: 1,
      value: 'true',
      name: trueLabel,
    },
    {
      key: 2,
      value: 'false',
      name: falseLabel,
    },
  ];

  return options;
};

export const getDecorationLocationsOptions = (
  includeEmptyValueOption = true,
  includeUndefinedOption = true,
  emptyValueOptionText = 'All decorations',
  undefinedOptionText = 'Undefined'
): Option<string>[] => {
  const options: Option<string>[] = [...decorationLocations];

  if (includeEmptyValueOption) {
    options.unshift({
      key: 0,
      value: '',
      name: emptyValueOptionText,
    });
  }

  if (includeUndefinedOption) {
    const key = includeEmptyValueOption ? options.length : options.length + 1;
    options.push({
      key,
      value: 'Undefined',
      name: undefinedOptionText,
    });
  }

  return options;
};

export const getSubmitStatusOptions = (
  includeEmptyValueOption = true,
  emptyValueOptionText = 'All statuses'
): Option<SubmitStatusEnum | ''>[] => {
  const options: Option<SubmitStatusEnum | ''>[] = [
    {
      key: 1,
      value: SubmitStatusEnum.Failed,
      name: 'Failed',
    },
  ];

  if (includeEmptyValueOption) {
    options.unshift({
      key: 0,
      value: '',
      name: emptyValueOptionText,
    });
  }

  return options;
};

export const getOrderFilterOptions = (): Option<string>[] => {
  const options: Option<string>[] = [
    ...getSubmitStatusOptions(false),
    /*
     *{
     *  key: 2,
     *  value: FilterOrdersOptionsEnum.OnlyRequiredItemOrders,
     *  name: 'Only Required Item Orders',
     *},
     *{
     *  key: 3,
     *  value: FilterOrdersOptionsEnum.OnlyPRDOrders,
     *  name: 'Only PRD Orders',
     *},
     */
    {
      key: 4,
      value: FilterOrdersOptionsEnum.OnlyOrdersSubmittedByMe,
      name: 'Only Orders Submitted by Me',
    },
  ];

  return options;
};

export const getVendorOrderSubmitStatusOptions = (
  includeEmptyValueOption = true,
  emptyValueOptionText = 'All statuses'
): Option<string>[] => {
  const options = [
    {
      key: 1,
      value: 'Failed',
      name: 'Failed',
    },
    {
      key: 2,
      value: 'Canceled',
      name: 'Canceled',
    },
    {
      key: 3,
      value: 'Pending',
      name: 'Pending',
    },
  ];

  if (includeEmptyValueOption) {
    options.unshift({
      key: 0,
      value: '',
      name: emptyValueOptionText,
    });
  }

  return options;
};

export const getCouponReasonOptions = (
  includeEmptyValueOption = true,
  emptyValueOptionText = 'All reasons'
): Option<string>[] => {
  const options: Option<string>[] = [...couponReasons];

  if (includeEmptyValueOption) {
    options.unshift({
      key: 0,
      value: '',
      name: emptyValueOptionText,
    });
  }

  return options;
};

export const getDiscountTypeOptions = (
  includeEmptyValueOption = true,
  emptyValueOptionText = 'All discount types'
): Option<string>[] => {
  const options: Option<string>[] = [...discountTypes];

  if (includeEmptyValueOption) {
    options.unshift({
      key: 0,
      value: '',
      name: emptyValueOptionText,
    });
  }

  return options;
};

export const getCouponStatusOptions = (
  includeEmptyValueOption = true,
  emptyValueOptionText = 'All statuses'
): Option<boolean | ''>[] => {
  const options = [...couponStatuses] as Option<boolean | ''>[];

  if (includeEmptyValueOption) {
    options.unshift({
      key: 0,
      value: '',
      name: emptyValueOptionText,
    });
  }

  return options;
};

export const getRosterStatusOptions = (
  includeEmptyValueOption = true,
  emptyValueOptionText = 'All statuses'
): Option<boolean | ''>[] => {
  const options = [...rosterStatuses] as Option<boolean | ''>[];

  if (includeEmptyValueOption) {
    options.unshift({
      key: 0,
      value: '',
      name: emptyValueOptionText,
    });
  }

  return options;
};

export const getLockerStatusOptions = (
  includeEmptyValueOption = true,
  emptyValueOptionText = 'All statuses',
  includeCount = true
): Option<string>[] => {
  const options: Option<string>[] = [...lockerStatuses];
  let text = emptyValueOptionText;
  if (includeCount) {
    text = `${emptyValueOptionText} (${options.length})`;
  }

  if (includeEmptyValueOption) {
    options.unshift({
      key: 0,
      value: '',
      name: text,
    });
  }

  return options;
};

export const getCollectionStatusOptions = (
  includeEmptyValueOption = true,
  emptyValueOptionText = 'All statuses',
  emtyValueOptionDisabled = false
): Option<boolean | ''>[] => {
  const options = [...collectionStatuses] as Option<boolean | ''>[];

  if (includeEmptyValueOption) {
    options.unshift({
      key: 0,
      value: '',
      name: emptyValueOptionText,
      disabled: emtyValueOptionDisabled,
    });
  }

  return options;
};

export const getDecorationSizeOptions = (
  includeEmptyValueOption = true,
  emptyValueOptionText = 'All decoration sizes'
): Option<string>[] => {
  const options: Option<string>[] = [...decorationSizes];

  if (includeEmptyValueOption) {
    options.unshift({
      key: 0,
      value: decorationSizeEnum.Undefined,
      name: emptyValueOptionText,
    });
  }

  return options;
};

export const getOrderChargeReasonOptions = (
  includeEmptyValueOption = true,
  emptyValueOptionText = 'Choose reason'
): Option<string>[] => {
  const options: Option<string>[] = [...orderChargeReasonOptions];

  if (includeEmptyValueOption) {
    options.unshift({
      key: 0,
      value: '',
      name: emptyValueOptionText,
    });
  }

  return options;
};

export const getParentLockerOptions = (
  includeEmptyValueOption = true,
  emptyValueOptionText = 'All lockers (regarding parent)'
): Option<string>[] => {
  const options: Option<string>[] = [...parentLockerOptions];

  if (includeEmptyValueOption) {
    options.unshift({
      key: 0,
      value: 'all',
      name: emptyValueOptionText,
    });
  }

  return options;
};

export const getPersonalizationTypeOptions = (
  includeEmptyValueOption = true,
  emptyValueOptionText = 'Choose a configuration'
): Option<string>[] => {
  const options: Option<string>[] = [...personalizationTypeOptions];

  if (includeEmptyValueOption) {
    options.unshift({
      key: 0,
      value: '',
      name: emptyValueOptionText,
    });
  }

  return options;
};

export const getExpressProductionTypeOptions = (
  includeEmptyValueOption = true,
  emptyValueOptionText = 'All'
): Option<boolean | null>[] => {
  const options = [...expressProductionTypeOptions] as Option<boolean | null>[];

  if (includeEmptyValueOption) {
    options.unshift({
      key: 0,
      value: null,
      name: emptyValueOptionText,
    });
  }

  return options;
};

export const getAdminOrganizationTypeOptions = (
  includeEmptyValueOption = true,
  emptyValueOptionText = 'Choose organization type'
): Option<string>[] => {
  const options: Option<string>[] = [...adminOrganizationTypeOptions];

  if (includeEmptyValueOption) {
    options.unshift({
      key: 0,
      value: '',
      name: emptyValueOptionText,
    });
  }

  return options;
};

export const getDtgPalletSizeOptions = (
  includeEmptyValueOption = true,
  emptyValueOptionText = 'N/A'
): Option<string>[] => {
  const options: Option<string>[] = [...dtgPalletSizeOptions];
  if (includeEmptyValueOption) {
    options.unshift({
      key: 0,
      value: '',
      name: emptyValueOptionText,
    });
  }

  return options;
};

export const getDtgHighlightGeneratorOptions = (
  includeEmptyValueOption = true,
  emptyValueOptionText = 'N/A'
): Option<string>[] => {
  const options: Option<string>[] = [...dtgHighlightGeneratorOptions];
  if (includeEmptyValueOption) {
    options.unshift({
      key: 0,
      value: '',
      name: emptyValueOptionText,
    });
  }

  return options;
};

export const getDtgStationOptions = (
  dtgStations: DtgStationDto[],
  includeEmptyValueOption = true,
  emptyValueOptionText = 'Choose Printer'
): Option<string>[] => {
  const options = dtgStations.map((station: any) => ({
    key: station.id,
    value: station.stationIdentifier,
    name: `${station.stationDisplayName} - ${station.stationIdentifier}`,
  }));

  if (includeEmptyValueOption) {
    options.unshift({
      key: 0,
      value: '',
      name: emptyValueOptionText,
    });
  }

  return options;
};

export const getNumberOfRowsOptions = (): Option<string>[] => {
  const options = [...numberOfRowsOptions];

  return options;
};

export const getNumberOfRowPositionsOptions = (): Option<string>[] => {
  const options = [...numberOfRowPositionsOptions];

  return options;
};

export const getBinSizeOptions = (): Option<string>[] => {
  const options: Option<string>[] = [...ptlWallBinSizeOptions];

  return options;
};
