/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */
import type { QueriesLogoItemDto } from './queriesLogoItemDto';
import type { QueriesPersonalizationItemDto } from './queriesPersonalizationItemDto';
import type { ProductionAssemblyItemGroupDtoOrderItemIdsWithBarcode } from './productionAssemblyItemGroupDtoOrderItemIdsWithBarcode';
import type { OrderItemStatusEnumStatusModel } from './orderItemStatusEnumStatusModel';

export interface ProductionAssemblyItemGroupDto {
  sku?: string | null;
  quantity?: number;
  custom?: boolean;
  requiredItem?: boolean;
  decorationMethod?: string | null;
  imageUrlFront?: string | null;
  imageUrlRight?: string | null;
  imageUrlLeft?: string | null;
  imageUrlBack?: string | null;
  logos?: QueriesLogoItemDto[] | null;
  personalizations?: QueriesPersonalizationItemDto[] | null;
  orderItemIds?: number[] | null;
  orderItemIdsWithBarcode?: ProductionAssemblyItemGroupDtoOrderItemIdsWithBarcode;
  lockerId?: number;
  lockerItemId?: number | null;
  waterproofStepRequired?: boolean;
  promisedDate?: string | null;
  inventoryAvailable?: boolean | null;
  status?: OrderItemStatusEnumStatusModel;
  cartLocations?: string[] | null;
}
