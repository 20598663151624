/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */
import type { FlagDto } from './flagDto';
import type { PrepressJobItemReworkStatusEnumStatusModel } from './prepressJobItemReworkStatusEnumStatusModel';

export interface PriorityQueueDto {
  id?: number;
  orderNumber?: number;
  orderItemDecorationId?: number;
  orderId?: number;
  shipDate?: string;
  barcode?: string | null;
  readonly quantity?: number;
  previewUrl?: string | null;
  personalizationLabel?: string | null;
  personalizationColorId?: number | null;
  outlineColorId?: number | null;
  fileUrl?: string | null;
  readonly files?: string[] | null;
  flag?: FlagDto;
  status?: PrepressJobItemReworkStatusEnumStatusModel;
}
