/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Orders Homefield API
 * OpenAPI spec version: v1
 */
import type { BrandDto } from './brandDto';
import type { ImageDto } from './imageDto';
import type { ItemPricingSetup } from './itemPricingSetup';
import type { Variation } from './variation';

export interface ItemDto {
  id?: number;
  uuid?: string | null;
  created?: string | null;
  updated?: string | null;
  deleted?: string | null;
  name?: string | null;
  description?: string | null;
  brand?: BrandDto;
  images?: ImageDto[] | null;
  pricing?: ItemPricingSetup;
  price?: number;
  oldPrice?: number | null;
  disabled?: boolean;
  custom?: boolean;
  essential?: boolean;
  variations?: Variation[] | null;
}
