/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Catalog Homefield API
 * OpenAPI spec version: v1
 */
import type { DecorationTypeEnum } from './decorationTypeEnum';

export interface StyleDecorationLocationDto {
  type?: DecorationTypeEnum;
  location?: string | null;
  locationId?: number | null;
  custom?: boolean;
}
