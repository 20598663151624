/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */
import type { PtlWallBinSizeEnum } from './ptlWallBinSizeEnum';
import type { PtlWallStatusEnum } from './ptlWallStatusEnum';
import type { PtlWallRowDto } from './ptlWallRowDto';

export interface PtlWallDto {
  id?: number;
  uuid?: string | null;
  created?: string | null;
  updated?: string | null;
  name?: string | null;
  binSize?: PtlWallBinSizeEnum;
  status?: PtlWallStatusEnum;
  rows?: PtlWallRowDto[] | null;
}
