import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class OrderManagementAddItemQuantity extends PureComponent {
  state = {
    newQuantity: this.props.selectedQuantity,
  };

  handleChange = (e) => {
    const {
      selectedItem,
      selectQuantity,
    } = this.props;

    const newQuantity = e.target.value;

    this.setState(() => ({ newQuantity }));
    selectQuantity(newQuantity, selectedItem);
  };

  setQuantityRef = (r) => {
    this.quantity = r;
  };

  render() {
    const { newQuantity } = this.state;
    const {
      item,
      selectedItem,
    } = this.props;

    return (
      <div className='text-field'>
        <input
          type='text'
          ref={this.setQuantityRef}
          value={newQuantity}
          onChange={this.handleChange}
          disabled={!selectedItem || item.id !== selectedItem.id}
        />
      </div>
    );
  }
}

OrderManagementAddItemQuantity.propTypes = {
  selectQuantity: PropTypes.func.isRequired,
  selectedQuantity: PropTypes.number,
  selectedItem: PropTypes.object,
  item: PropTypes.object.isRequired,
};

export default OrderManagementAddItemQuantity;
