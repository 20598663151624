import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { reset } from 'redux-form';
import { PropTypes } from 'prop-types';
import {
  addVendor,
  editVendor,
  deleteVendor,
  editVendorTimelines,
} from '@APICalls/vendor/actions';
import { vendorForm } from '@constants/reduxForms';
import { keyNameEnum } from '@constants/enums/commonEnums';
import {
  getVendors,
  getVendorTimelines,
} from '@redux/productCatalog/actions';
import { parseDateTimeNumeric } from '@util/dateHandler';
import { materialSwal } from '@util/componentHelper';
import { getSelectableTableRowProps } from '@util/selectionHelpers';
import Table from '@sharedComponents/Table/Table';
import Button from '@sharedComponents/Buttons/Button';
import SearchFilter from '@sharedComponents/Inputs/SearchFilter';
import VendorAddModal from './VendorModals/VendorAddModal';
import VendorEditModal from './VendorModals/VendorEditModal';
import VendorDeleteModal from './VendorModals/VendorDeleteModal';
import VendorsActionsColumn from './VendorsActionsColumn';
import VendorQuickView from './VendorQuickView';

const VendorsTable = Table();

class Vendors extends PureComponent {
  state = {
    addVendorModalIsOpened: false,
    editVendorModalIsOpened: false,
    deleteVendorModalIsOpened: false,
    selectedVendor: null,
    sortOrder: '',
    sortByState: '',
    searchInput: '',
    pageNumber: this.props.pageNumber,
    pageSize: this.props.pageSize,
  };

  componentDidMount() {
    this.search();
  }

  search = () => {
    const { dispatch } = this.props;

    const {
      pageNumber,
      pageSize,
      searchInput,
    } = this.state;

    dispatch(getVendors(
      pageNumber,
      pageSize,
      searchInput
    ));
  };

  addVendor = async (form) => {
    const vendorResponse = await addVendor(form);

    if (vendorResponse?.success) {
      const vendorTimelinesResponse = await editVendorTimelines(form.code, form.timelines);

      if (vendorTimelinesResponse?.success) {
        materialSwal('Success', vendorResponse.message, 'success');
        this.closeAddVendorModal();
        this.search();
      }
    }
  };

  fetchData = (state, instance) => {
    const {
      sortByState,
      sortOrder,
    } = this.state;

    let sortBy = sortByState;
    let sortDirection = sortOrder;

    const {
      page,
      pageSize,
      sorted,
    } = instance.state;

    if (sorted[0]) {
      sortBy = instance.state.sorted[0].id;
      sortDirection = sorted[0].desc ? 'descending' : 'ascending';
    }

    this.setState({
      sortByState: sortBy,
      sortOrder: sortDirection,
      pageNumber: page + 1,
      pageSize,
    }, this.search);
  };

  getColumns = () => [
    {
      Header: 'Code',
      accessor: 'code',
      minWidth: 55,
      sortable: true,
      Cell: (cellProps) => cellProps.value && <span>{cellProps.value}</span>,
    },
    {
      Header: 'Created',
      accessor: 'created',
      minWidth: 80,
      Cell: (cellProps) => cellProps.value && <div>{parseDateTimeNumeric(cellProps.value)}</div>,
    },
    {
      Header: 'Updated',
      accessor: 'updated',
      minWidth: 80,
      Cell: (cellProps) => cellProps.value && <div>{parseDateTimeNumeric(cellProps.value)}</div>,
    },
    {
      Header: 'Name',
      accessor: 'name',
      minWidth: 100,
      Cell: (cellProps) => cellProps.value && <span>{cellProps.value}</span>,
    },
    {
      Header: '',
      accessor: '',
      minWidth: 40,
      resizable: false,
      style: { overflow: 'visible' },
      Cell: (cellProps) => (
        <VendorsActionsColumn
          vendor={cellProps.value}
          onVendorEdit={this.openEditVendorModal}
          onVendorDelete={this.openDeleteVendorModal}
        />
      ),
    },
  ];

  clearSearch = () => {
    this.setState({
      searchInput: '',
    }, this.search);
  };

  onSearch = (e) => {
    if (e.key && e.key !== keyNameEnum.Enter) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();

    const searchInput = e.target.value;

    this.setState(() => ({
      searchInput,
    }), this.search);
  };

  editVendor = async (form) => {
    const {
      pageNumber,
      pageSize,
    } = this.state;
    const vendorResponse = await editVendor(form);

    if (vendorResponse?.success) {
      const vendorTimelinesResponse = await editVendorTimelines(form.code, form.timelines);

      if (vendorTimelinesResponse?.success) {
        materialSwal('Success', vendorResponse.message, 'success');
        this.closeEditVendorModal();
        this.search(pageNumber, pageSize);
      }
    }
  };

  deleteVendor = async (code) => {
    const result = await deleteVendor(code);

    if (result?.success) {
      materialSwal('Success', result.message, 'success');
      this.closeDeleteVendorModal();
      this.search();
    }
  };

  openAddVendorModal = () => {
    this.setState({ addVendorModalIsOpened: true });
  };

  closeAddVendorModal = () => {
    const { dispatch } = this.props;

    dispatch(reset(vendorForm));
    this.setState({ addVendorModalIsOpened: false });
  };

  openEditVendorModal = (vendor) => {
    this.selectVendor(vendor);
    this.setState({ editVendorModalIsOpened: true });
  };

  closeEditVendorModal = () => {
    const { dispatch } = this.props;

    dispatch(reset(vendorForm));
    this.setState({
      editVendorModalIsOpened: false,
      selectedVendor: null,
    });
  };

  openDeleteVendorModal = (vendor) => {
    this.setState({
      deleteVendorModalIsOpened: true,
      selectedVendor: vendor,
    });
  };

  closeDeleteVendorModal = () => {
    this.setState({
      deleteVendorModalIsOpened: false,
      selectedVendor: null,
    });
  };

  selectVendor = (selectedVendor) => {
    const { dispatch } = this.props;

    this.setState({ selectedVendor });

    if (!selectedVendor) return;

    dispatch(getVendorTimelines(selectedVendor.code));
  };

  unselectVendor = () => {
    this.setState({ selectedVendor: null });
  };

  getTrProps = (state, rowInfo) => {
    const { selectedVendor } = this.state;

    return getSelectableTableRowProps(this.selectVendor, rowInfo, selectedVendor, 'id');
  };

  render() {
    const {
      addVendorModalIsOpened,
      editVendorModalIsOpened,
      deleteVendorModalIsOpened,
      selectedVendor,
    } = this.state;

    const {
      hasNextPage,
      hasPreviousPage,
      totalPages,
      queue,
      timelines,
    } = this.props;

    const initialValues = !selectedVendor ? {} : selectedVendor;
    const columns = this.getColumns();

    return (
      <div className='container'>
        <VendorAddModal
          isOpen={addVendorModalIsOpened}
          closeModal={this.closeAddVendorModal}
          onSubmit={this.addVendor}
          initialValues={{
            dropshipVFIs: false,
            vfiOrderAutoSubmit: false,
            timelines: [],
          }}
        />

        <VendorEditModal
          isOpen={editVendorModalIsOpened}
          closeModal={this.closeEditVendorModal}
          onSubmit={this.editVendor}
          initialValues={{
            ...initialValues,
            timelines,
          }}
        />

        <VendorDeleteModal
          isOpen={deleteVendorModalIsOpened}
          closeModal={this.closeDeleteVendorModal}
          deleteVendor={this.deleteVendor}
          vendor={initialValues}
        />

        <div className='table-options w-100'>
          <div className='flex'>
            <SearchFilter
              search={this.onSearch}
              clearSearch={this.clearSearch}
            />
          </div>
          <div className='flex'>
            <Button
              type={'primary'}
              text={'Add Vendor'}
              onClick={this.openAddVendorModal}
              classes={'colors__add-btn'}
            />
          </div>
        </div>

        <div className='master-detail'>
          <div className='lockerManager__master'>
            <div className='sheet'>
              <VendorsTable
                data={queue}
                columns={columns}
                totalPages={totalPages}
                hasNextPage={hasNextPage}
                hasPreviousPage={hasPreviousPage}
                onFetchData={this.fetchData}
                getTrProps={this.getTrProps}
              />
            </div>
          </div>
          {
            selectedVendor &&
            <VendorQuickView
              vendor={selectedVendor}
              timelines={timelines}
              openEditVendorModal={this.openEditVendorModal}
              closeDetails={this.unselectVendor}
            />
          }
        </div>
      </div>
    );
  }
}

Vendors.propTypes = {
  queue: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    created: PropTypes.string.isRequired,
    updated: PropTypes.string,
    name: PropTypes.string,
    leadTime: PropTypes.number,
    dropshipVFIs: PropTypes.bool.isRequired,
    vfiOrderAutoSubmit: PropTypes.bool.isRequired,
    vfiOrderTreshold: PropTypes.number,
    vfiOrderTimeHours: PropTypes.number,
  })).isRequired,
  timelines: PropTypes.arrayOf(PropTypes.shape({
    description: PropTypes.string.isRequired,
    timelineDays: PropTypes.number.isRequired,
  })).isRequired,
  totalPages: PropTypes.number.isRequired,
  hasPreviousPage: PropTypes.bool,
  hasNextPage: PropTypes.bool,
  pageNumber: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
};

const mapStateToProps = ({ productCatalog }) => ({
  queue: productCatalog.vendorsQueue.queue,
  timelines: productCatalog.vendorTimelines,
  pageNumber: productCatalog.vendorsQueue.pageNumber,
  pageSize: productCatalog.vendorsQueue.pageSize,
  hasPreviousPage: productCatalog.vendorsQueue.hasPreviousPage,
  hasNextPage: productCatalog.vendorsQueue.hasNextPage,
  totalPages: productCatalog.vendorsQueue.totalPages,
});

export default connect(mapStateToProps)(Vendors);
