/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Financials Homefield API
 * OpenAPI spec version: v1
 */
import type { NotificationSettingsDto } from './notificationSettingsDto';

export interface UpdateVoucherOrderCommand {
  voucherOrderId?: number;
  lockerManagerId?: number;
  startDate?: string;
  endDate?: string | null;
  notificationSettings?: NotificationSettingsDto;
}
