import React, { useCallback } from 'react';
import {
  PtlWallRowPositionDto,
  PtlWallOrderItemDto,
} from '@api/fulfillment/models';
import { mapRowPositionColor } from '@util/mappingHelper';
import LinkButton from '@sharedComponents/Buttons/LinkButton';
import MaterialTooltip from '@sharedComponents/Tooltips/MaterialTooltip';
import { orderDetailsUrl } from '@constants/clientUrls/clientUrls';
import { openInNewTab } from '@util/componentHelper';

interface OwnProps {
  rowPosition: PtlWallRowPositionDto;
}

const PtlWallRowPosition = React.memo<OwnProps>(({ rowPosition }) => {
  const emptyPosition = !rowPosition.orderItems?.length;

  const linkButtonOnClick = useCallback(() => {
    if (!rowPosition?.orderItems?.[0].orderNumber) return;
    openInNewTab(orderDetailsUrl(rowPosition?.orderItems?.[0].orderNumber?.toString()));
  }, [rowPosition?.orderItems]);

  const renderTooltipItems = (item: PtlWallOrderItemDto) => (
    <div
      key={item.id}
      className='items-remaining__items'
    >
      {!item.isScanned && <div>{item.orderItem?.barcode} {item.orderItem?.styleName}</div>}
    </div>
  );

  return (
    <div
      style={{
        borderTop: `5px solid ${mapRowPositionColor(rowPosition?.color ?? '')}`,
      }}
      className='ptl-wall-row__row-position-card'
    >
      {
        emptyPosition
          ? (
            <div className='align--center h-100 empty flex align__center justify__center'>
              <h4>Empty Bin Slot</h4>
            </div>
          )
          : (
            <div className='full'>
              <div className='pt-20 pl-20'>
                <LinkButton
                  text={`O${rowPosition?.orderItems?.[0].orderNumber}`}
                  onClick={linkButtonOnClick}
                />
              </div>
              <div className='flex'>
                <div className='align__flex-end justify__end w-100 pt-20 mr-20'>
                  <MaterialTooltip
                    tooltipText={(
                      <div className='items-remaining-tooltip w-100'>
                        <h3 className='pl-10 pt-10 mb-5'>Remaining Items</h3>
                        {rowPosition?.orderItems && rowPosition?.orderItems.map(renderTooltipItems)}
                      </div>
                    )}
                    placement={'top'}
                  >
                    <h3 className='items-remaining-indicator mt-15'>
                      {rowPosition?.orderItemCount}/{rowPosition?.totalOrderItemCount}
                    </h3>
                  </MaterialTooltip>
                </div>
              </div>
            </div>
          )
      }
    </div>
  );
});

export default PtlWallRowPosition;
