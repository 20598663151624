/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Catalog Homefield API
 * OpenAPI spec version: v1
 */

export interface BulkUpdateStyleCommand {
  styleIds?: number[] | null;
  toggleVisible?: boolean;
  toggleDiscontinued?: boolean;
  toggleAvailableForNewStores?: boolean;
  toggleRecommended?: boolean;
  toggleCustomizable?: boolean;
  discount?: number | null;
  discountPercentage?: number | null;
}
