import React, { useCallback, useEffect, useMemo } from 'react';
import Modal from '@sharedComponents/Modal/Modal';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import AssignLogosToItemsTabHeader from '../AssignLogosToItems/AssignLogosToItemsTabHeader';
import ChooseLogosTab from '../AssignLogosToItems/ChooseLogosTab';
import AssignLogosToCustomItemsTable from './AssignLogosToCustomItemsTable';
import ChooseCustomItemLogoLocationsTab from './ChooseCustomItemLogoLocationsTab';
import { materialSwal } from '@util/componentHelper';
import { useGetLockerItems } from '@api/squadlockerServices/locker-manager';
import { useUpdateLockerItemLogos } from '@api/squadlockerServices/logos';

const AssignLogosToCustomItemsModal = ({ isOpen, closeModal, logos, lockerId }) => {
  const [nextStep, setNextStep] = React.useState(false);
  const [activeTab, setActiveTab] = React.useState('chooseLogos');
  const [selectedLogos, setSelectedLogos] = React.useState([]);
  const [selectedItems, setSelectedItems] = React.useState([]);
  const [allItemsSelected, setAllItemsSelected] = React.useState(false);
  const [activeLogoLocation, setActiveLogoLocation] = React.useState(null);
  const [selectedNewLogos, setSelectedNewLogos] = React.useState({});
  const [activeLogoSelectionId, setActiveLogoSelectionId] = React.useState(null);

  const { data: initalItems, refetch: refetchCustomItems } = useGetLockerItems(lockerId, {
    query: {
      enabled: !!lockerId,
      refetchOnWindowFocus: false,
    },
  });

  const items = useMemo(() => {
    return initalItems?.filter((item) => item.logos.length > 0);
  }, [initalItems]);

  const { mutateAsync: swapLockerItemLogo } = useUpdateLockerItemLogos();

  useEffect(() => {
    if (activeTab === 'chooseLogos' || activeTab === 'chooseItems') {
      setNextStep(true);
    } else {
      setNextStep(false);
    }
  }, [activeTab]);

  const onClose = useCallback(() => {
    setSelectedLogos([]);
    setSelectedItems([]);
    setSelectedNewLogos({});
    setActiveLogoLocation(null);
    setActiveLogoSelectionId(null);
    setActiveTab('chooseLogos');
    setAllItemsSelected(false);
    closeModal();
  }, [closeModal]);

  const confirm = async () => {
    try {
      const swapOperations = Object.entries(selectedNewLogos).map(([lockerItemLogoId, newLogo]) => ({
        lockerItemLogoId,
        data: { newLogoId: newLogo.id },
      }));

      const res = await Promise.all(swapOperations.map((operation) => {
        return swapLockerItemLogo(operation);
      }));

      if (res) {
        materialSwal('Success', 'Logos successfully assigned', 'success');
        onClose();
        await refetchCustomItems();
      } else {
        materialSwal('Error', 'Error assigning logos', 'error');
      }
    } catch (error) {
      console.error('Error swapping logos:', error);
    }
  };

  const goToNextStep = () => {
    if (activeTab === 'chooseLogos') {
      setActiveTab('chooseItems');
    } else if (activeTab === 'chooseItems') {
      setActiveTab('chooseLogoLocations');
    }
  };

  const toggleSelectLogo = useCallback((logo) => {
    if (selectedLogos.includes(logo)) {
      setSelectedLogos(selectedLogos.filter((item) => item !== logo));
    } else {
      setSelectedLogos([...selectedLogos, logo]);
    }
  }, [selectedLogos]);

  const unselectLogo = useCallback((logo) => {
    setSelectedLogos(selectedLogos.filter((item) => item !== logo));
  }, [selectedLogos]);

  const isLogoSelected = useCallback((logo) => {
    return selectedLogos.includes(logo);
  }, [selectedLogos]);

  const removeAllLogos = useCallback(() => {
    setSelectedLogos([]);
  }, []);

  const selectItem = useCallback((item) => {
    if (selectedItems.includes(item)) {
      setSelectedItems(selectedItems.filter((selectedItem) => selectedItem.id !== item.id));
    } else {
      setSelectedItems([...selectedItems, item]);
    }
  }, [selectedItems]);

  const selectAllItems = useCallback(() => {
    if (!allItemsSelected) {
      const newSelectedItems = [...selectedItems];
      for (const item of items) {
        if (!selectedItems.find((selected) => selected.id === item.id)) {
          newSelectedItems.push(item);
        }
      }
      setSelectedItems(newSelectedItems);
    } else {
      setSelectedItems(selectedItems.filter((selected) => !items.find((item) => item.id === selected.id)));
    }

    setAllItemsSelected(!allItemsSelected);
  }, [allItemsSelected, selectedItems, items]);

  const handleNewLogoSelected = useCallback((currentLogoId, selectedLogo) => {
    setSelectedNewLogos((prev) => ({ ...prev, [currentLogoId]: selectedLogo }));
  }, []);

  const renderTabContent = () => {
    switch (activeTab) {
      case 'chooseLogos':
        return (
          <ChooseLogosTab
            logos={logos}
            selectedLogos={selectedLogos}
            toggleSelectLogo={toggleSelectLogo}
            unselectLogo={unselectLogo}
            isLogoSelected={isLogoSelected}
            isSelectingLogosEnabled={true}
            removeAllLogos={removeAllLogos}
          />
        );

      case 'chooseItems':
        return (
          <AssignLogosToCustomItemsTable
            items={items}
            selectedItems={selectedItems}
            selectItem={selectItem}
            selectAllItems={selectAllItems}
            allItemsSelected={allItemsSelected}
          />
        );

      case 'chooseLogoLocations':
        return (
          <ChooseCustomItemLogoLocationsTab
            selectedLogos={selectedLogos}
            selectedItems={selectedItems}
            activeLogoLocation={activeLogoLocation}
            setActiveLogoLocation={setActiveLogoLocation}
            handleNewLogoSelected={handleNewLogoSelected}
            selectedNewLogos={selectedNewLogos}
            activeLogoSelectionId={activeLogoSelectionId}
            setActiveLogoSelectionId={setActiveLogoSelectionId}
          />
        );

      default:
        return null;
    }
  };

  const setChooseLogosTab = useCallback(() => setActiveTab('chooseLogos'), []);
  const setChooseItemsTab = useCallback(() => setActiveTab('chooseItems'), []);
  const setChooseLogoLocationsTab = useCallback(() => setActiveTab('chooseLogoLocations'), []);

  return (
    <div>
      <Modal
        title={'Assign Logos to Custom Items'}
        modalSize={'xl'}
        isOpen={isOpen}
        closeModal={onClose}
        buttons={(
          <ModalButtons
            confirmBtnText={nextStep ? 'Next Step' : 'Done'}
            cancelBtnText={'Cancel'}
            onConfirm={nextStep ? goToNextStep : confirm}
            onClose={onClose}
          />
        )}
      >
        <div>
          <div className='tabs'>
            <AssignLogosToItemsTabHeader
              title={'1. Choose Logos'}
              isActiveTab={activeTab === 'chooseLogos'}
              selectTab={setChooseLogosTab}
              iconChecked={activeTab === 'chooseLogos' || activeTab === 'chooseItems'}
            />

            <AssignLogosToItemsTabHeader
              title={'2. Choose Item(s)'}
              isActiveTab={activeTab === 'chooseItems'}
              selectTab={setChooseItemsTab}
              iconChecked={activeTab === 'chooseItems'}
            />
            <AssignLogosToItemsTabHeader
              title={'3. Choose Logo Locations'}
              isActiveTab={activeTab === 'chooseLogoLocations'}
              selectTab={setChooseLogoLocationsTab}
              iconChecked={activeTab === 'chooseLogoLocations'}
            />
          </div>

          {renderTabContent()}
        </div>
      </Modal>
    </div>
  );
};

export default AssignLogosToCustomItemsModal;
