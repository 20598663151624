import React, {
  useState,
  useCallback,
} from 'react';
import {
  ColorDto,
  DecorationMethodEnum,
} from '@api/productCatalog/models';
import { regenerateImage } from '@APICalls/dtg/actions';
import { materialSwal } from '@util/componentHelper';
import MaterialTooltip from '@sharedComponents/Tooltips/MaterialTooltip';
import SimpleConfirmationModal from '@sharedComponents/Modal/SimpleConfirmationModal';
import RegenerateDtgImageButton from '@sharedComponents/Buttons/RegenerateDtgImageButton';

interface OwnProps {
  personalizationText?: string;
  personalizationNumber?: string;
  personalizationColor?: ColorDto;
  personalizationOutlineColor?: ColorDto;
  showThreadColorValue: boolean;
  orderItemDecorationId: number;
  decorationMethod?: string;
  regenerateButtonVisible: boolean;
}

type Props = OwnProps;

const ProductionAssemblyTextCell = React.memo<Props>(({
  personalizationText,
  personalizationNumber,
  personalizationColor,
  personalizationOutlineColor,
  showThreadColorValue = false,
  orderItemDecorationId,
  decorationMethod,
  regenerateButtonVisible,
}) => {
  const [
    isOpen,
    setIsOpen,
  ] = useState(false);

  const openRegenerateImageModal = useCallback(() => {
    setIsOpen(true);
  }, []);

  const closeRegenerateImageModal = useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleRegenerateImage = useCallback(async () => {
    const result = await regenerateImage({ orderItemDecorationId } as any);

    if (result?.success) {
      materialSwal('Success', result.message, 'success');

      closeRegenerateImageModal();
    }
  }, [
    orderItemDecorationId,
    closeRegenerateImageModal,
  ]);

  let colorField: JSX.Element | string = '';
  if (showThreadColorValue) {
    colorField = (
      <div className='thread-color'>
        <div className='color-swatch-item size--xs'>
          <div
            className='color-swatch-item__color size--xs'
            style={personalizationColor && { backgroundColor: personalizationColor.hexValue ?? undefined }}
          />
        </div>
        <div className='text'>{personalizationColor?.threadValue}</div>
      </div>
    );
  } else {
    let outlineElement: JSX.Element | string = '';
    const tooltipText = (
      <div className='flex__column'>
        <span>Fill: {personalizationColor?.code ?? '-'}</span>
        {personalizationOutlineColor && <span>Outline: {personalizationOutlineColor.code ?? '-'}</span>}
      </div>
    );

    if (personalizationOutlineColor?.hexValue) {
      outlineElement = (
        <span
          className='color--outline'
          style={{ borderColor: personalizationOutlineColor?.hexValue }}
        />
      );
    }

    colorField = (
      <MaterialTooltip
        tooltipText={tooltipText}
        placement={'right'}
        contentClasses={'color'}
      >
        <div
          className='w-100 h-100'
          style={{ backgroundColor: personalizationColor?.hexValue ?? undefined }}
        >
          {outlineElement}
        </div>
      </MaterialTooltip>
    );
  }

  return (
    <span className='sheet__list-table-personalization'>
      <span className='text'>
        {`${personalizationText ?? ''} ${personalizationNumber ?? ''}`}
      </span>
      <div className='flex'>
        {colorField}
        <SimpleConfirmationModal
          isOpen={isOpen}
          closeModal={closeRegenerateImageModal}
          confirm={handleRegenerateImage}
          confirmationBody={'This process can take up to 10 minutes please check back'}
          title={'Confirm Personalization Regeneration'}
        />
        {
          decorationMethod === DecorationMethodEnum.DTG && regenerateButtonVisible &&
          <RegenerateDtgImageButton
            iconButton={true}
            onClick={openRegenerateImageModal}
            orderItemDecorationId={orderItemDecorationId}
          />
        }
      </div>
    </span>
  );
});

export default ProductionAssemblyTextCell;
