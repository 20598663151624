import React from 'react';
import { BackorderDto } from '@api/fulfillment/models';
import Modal from '@sharedComponents/Modal/Modal';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';

interface OwnProps {
  isOpen: boolean;
  closeModal: () => void;
  orders: BackorderDto[];
  sendNotification: () => void;
}

type Props = OwnProps;

const SendNotificationForOrdersModal = React.memo<Props>(({
  isOpen,
  closeModal,
  orders,
  sendNotification,
}) => (
  <Modal
    title={'Send Notification for Order(s)'}
    isOpen={isOpen}
    modalSize={'m'}
    closeModal={closeModal}
    buttons={(
      <ModalButtons
        confirmBtnText={'Send'}
        cancelBtnText={'Cancel'}
        onConfirm={sendNotification}
        onClose={closeModal}
      />
    )}
  >
    <h3 className='modal__description'>
      {
        orders.map((order) => (
          <div
            className='ml-10 mb-5'
            key={order.orderNumber}
          >
            <b>O{order.orderNumber}</b> - {(order.shippingInfo?.email) ?? '' }
          </div>
        ))
      }
    </h3>
  </Modal>
));

export default SendNotificationForOrdersModal;
