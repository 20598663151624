/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Catalog Homefield API
 * OpenAPI spec version: v1
 */
import type { DtgPalletDto } from './dtgPalletDto';

export interface DtgPalletDtoPagedList {
  pageNumber?: number;
  pageSize?: number;
  totalCount?: number;
  items?: DtgPalletDto[] | null;
  readonly totalPages?: number;
  readonly hasPreviousPage?: boolean;
  readonly hasNextPage?: boolean;
}
