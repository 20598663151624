/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */
import type { ArtworkTaskReworkDto } from './artworkTaskReworkDto';

export interface ReworkPrintQueueCommand {
  printQueueId?: number;
  artworkTaskReworks?: ArtworkTaskReworkDto[] | null;
  rejectSheet?: boolean;
}
