import React, { useCallback } from 'react';
import { ColorDto } from '@api/productCatalog/models';
import { filterArtTeamColors } from '@util/colorHelper';

interface OwnProps {
  logoUrl: string;
  openModal: (logoUrl: string) => void;
  logoColors: {
    code?: string;
    selectionType?: number;
    id: number;
    hexValue?: string;
  }[];
  imageSize?: string;
  colors: ColorDto[] | undefined;
}

type Props = OwnProps;

const ProductionAssemblyLogoCell = React.memo<Props>(({
  logoUrl,
  openModal,
  logoColors,
  imageSize,
  colors,
}) => {
  const handleOpenModal = useCallback(() => {
    openModal(logoUrl);
  }, [
    openModal,
    logoUrl,
  ]);

  const renderColor = (color: ColorDto, index: number) => (
    <div
      className='color-swatch-item size--xs'
      key={index}
    >
      <div
        className='color-swatch-item__color size--xs'
        style={{ backgroundColor: color.hexValue ?? undefined }}
      />
      <div className='text'>{color.threadValue}</div>
    </div>
  );

  const colorGroups = [];
  const colorsList = colors ?? [];

  if (
    logoColors
    && logoColors.length > 0
    && Object.keys(colorsList).length > 0
  ) {
    const logoArtTeamColors: (typeof logoColors) = filterArtTeamColors(logoColors);
    const logoArtTeamColorsCodes = logoArtTeamColors.map((c) => c.code);
    const logoArtTeamColorInfo = colorsList.filter((c) => logoArtTeamColorsCodes.includes(c.code!));

    const colorElements = logoArtTeamColorInfo.map(renderColor);

    for (let i = 0; i < colorElements.length; i += 4) {
      colorGroups.push(
        <div
          className='color-swatches--horizontal'
          key={i}
        >
          {colorElements.slice(i, i + 4)}
        </div>
      );
    }
  }

  const imageSizeClass = imageSize ? `image-container--${imageSize}` : '';
  const thumbnailSizeClass = imageSize ? `sheet__list-table-thumbnail--${imageSize}` : '';

  return (
    <div className='flex'>
      <div
        className={`image-container ${imageSizeClass}`}
        onClick={handleOpenModal}
      >
        <div
          className={`sheet__list-table-thumbnail cursor-pointer ${thumbnailSizeClass}`}
          style={{ backgroundImage: `url("${logoUrl}")` }}
        >
          <div className='details__item-overlay'>
            <i className='material-icons details__item-preview-icon'>search</i>
          </div>
        </div>
      </div>
      <div className='logo-bank__color-swatches'>
        {colorGroups}
      </div>
    </div>
  );
});

export default ProductionAssemblyLogoCell;
