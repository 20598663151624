/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Financials Homefield API
 * OpenAPI spec version: v1
 */
import type { PayoutRecipientDto } from './payoutRecipientDto';

export interface PayoutRecipientDtoApiResponse {
  success?: boolean;
  message?: string | null;
  result?: PayoutRecipientDto;
}
