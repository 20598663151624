import React from 'react';
import { ColorDto } from '@api/productCatalog/models';
import MaterialTooltip from '@sharedComponents/Tooltips/MaterialTooltip';

interface OwnProps {
  personalizationText?: string;
  personalizationNumber?: string;
  personalizationColor?: ColorDto;
  personalizationOutlineColor?: ColorDto;
}

type Props = OwnProps;

const TextCell = React.memo<Props>(({
  personalizationText,
  personalizationNumber,
  personalizationColor,
  personalizationOutlineColor,
}) => {
  let outlineElement: string | JSX.Element = '';
  if (personalizationOutlineColor?.hexValue) {
    outlineElement = (
      <span
        className='color--outline'
        style={{ borderColor: personalizationOutlineColor.hexValue }}
      />
    );
  }

  const tooltipText = (
    <div className='flex__column'>
      <span>Fill: {personalizationColor?.code ?? 'Undefined'}</span>
      {personalizationOutlineColor && <span>Outline: {personalizationOutlineColor?.code ?? 'Undefined'}</span>}
    </div>
  );

  return (
    <span className='sheet__list-table-personalization'>
      <span className='text'>
        {`${personalizationText ?? ''} ${personalizationNumber ?? ''}`}
      </span>
      <MaterialTooltip
        tooltipText={tooltipText}
        placement={'right'}
      >
        <div
          className='color'
          style={{ backgroundColor: personalizationColor?.hexValue ?? undefined }}
        >
          {outlineElement}
        </div>
      </MaterialTooltip>
    </span>
  );
});

export default TextCell;
