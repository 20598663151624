import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import ReactTable, { Column } from 'react-table-6';
import {
  connect,
  ConnectedProps,
} from 'react-redux';
import {
  pageSizeOptionsSmallTable,
  defaultPageSizeSmallTable,
  noneBulkAction,
} from '@constants/values';
import { TableEnum } from '@constants/enums/tableEnums';
import { BulkOption } from '@models/common/Option';
import * as tableManagerActions from '@redux/tableManager/actions';
import {
  selectItem,
  selectAllItems,
  isSelectedItem,
} from '@util/selectionHelpers';
import SelectCell from '@sharedComponents/Table/TableCells/SelectCell';
import SelectAllCell from '@sharedComponents/Table/TableCells/SelectAllCell';
import TableBulkActions from '@sharedComponents/Table/TableComponents/TableBulkActions';
import BulkActionSelector from '@sharedComponents/Table/TableComponents/BulkActionSelector';
import {
  sortDirectionEnum,
  sortDirectionShortEnum,
} from '@constants/enums/commonEnums';
import {
  getPagingParamsFromTable,
  getSortParamsFromTable,
} from '@util/tableHelpers';

interface OwnProps<T> {
  columns: Array<Column<T>>;
  data: T[];
  totalPages?: number;

  onFetchData?: (state: any, instance: any) => void;
  hasNextPage?: boolean;
  hasPreviousPage?: boolean;
  showPagination?: boolean;
  defaultPageSize?: number;
  pageSizeOptions?: readonly number[];
  classNames?: string;
  striped?: boolean;
  highlight?: boolean;
  narrow?: boolean;
  stickyHeader?: boolean;
  subComponent?: any;
  customProps?: any;
  getTrProps?: (state: any, rowInfo: any) => any;

  /* Selectable */
  selectable?: boolean;
  selectedData?: T[];
  updateSelection?: ((newSelectedItems: T[], newIsPageSelected: boolean) => void)
  | ((newSelectedItems: T[], newIsPageSelected: boolean, newIsTableSelected: boolean) => void);
  selectPredicateOrKey?: keyof T | ((item1: T, item2: T) => boolean);
  isPageSelected?: boolean;
  isTableSelected?: boolean;
  selectWholeTableEnabled?: boolean;
  isSelectingItemDisabled?: (item?: T) => boolean;
  isSelectingPageDisabled?: () => boolean;
  showWrappedColumns?: boolean;

  /* Bulk actions */
  isBulkActionsMode?: boolean;
  bulkActionsList?: readonly BulkOption[];
  onSelectBulkAction?: (option: string) => void;
  totalCount?: number;
  tableName?: string;

  /* Preserve table state */
  tableId?: TableEnum;
  preserveState?: boolean;
  sortDirEnum?: typeof sortDirectionEnum | typeof sortDirectionShortEnum;
  filtersToPreserve?: { [key: string]: any; };

  /* Initializing table from the preserved state */
  initialPageNumber?: number;
  initialPageSize?: number;
  initialSortColumn?: string;
  initialSortDirection?: string;
}

const mapDispatchToProps = {
  updateTableState: tableManagerActions.updateTableState,
  updateTableFilters: tableManagerActions.updateTableFilters,
};

const connector = connect(null, mapDispatchToProps);

type Props<T> = OwnProps<T> & ConnectedProps<typeof connector>;

const Table = <T, >({
  classNames = 'sheet__list',
  data,
  totalPages,
  defaultPageSize,
  hasNextPage,
  hasPreviousPage,
  pageSizeOptions,
  getTrProps,
  onFetchData,
  columns,
  showPagination = undefined,
  striped = true,
  highlight = true,
  narrow = false,
  stickyHeader = true,
  selectedData = [],
  isBulkActionsMode = false, // Obsolete
  showWrappedColumns = false,
  subComponent,
  customProps = {},

  selectable = false,
  selectPredicateOrKey = 'id' as keyof T,
  isPageSelected = false,
  isTableSelected = false,
  selectWholeTableEnabled = false,
  tableName = 'Table',
  bulkActionsList = [],
  updateSelection = undefined,
  onSelectBulkAction = undefined,
  totalCount = undefined,
  isSelectingItemDisabled = undefined,
  isSelectingPageDisabled = undefined,

  tableId = undefined,
  preserveState,
  updateTableState,
  updateTableFilters,
  sortDirEnum,
  initialPageNumber = undefined,
  initialPageSize = undefined,
  initialSortColumn = undefined,
  initialSortDirection = undefined,
  filtersToPreserve,
}: Props<T>) => {
  const [
    selectedBulkAction,
    setSelectedBulkAction,
  ] = useState<string>(noneBulkAction.value);

  useEffect(() => {
    if (selectedData) {
      setSelectedBulkAction(noneBulkAction.value);
    }
  }, [selectedData]);

  useEffect(() => {
    if (!tableId || !filtersToPreserve) return;

    updateTableFilters(tableId, filtersToPreserve);
  }, [
    tableId,
    filtersToPreserve,
    updateTableFilters,
  ]);

  const handleSelectBulkAction = useCallback((bulkAction: string) => {
    setSelectedBulkAction(bulkAction);

    if (!onSelectBulkAction) return;
    onSelectBulkAction(bulkAction);
  }, [onSelectBulkAction]);

  /**
   * Returns true if selecting a specific row is disabled
   */
  const handleIsSelectingItemDisabled = (item: T) => {
    if (!isSelectingItemDisabled) {
      return false;
    }

    return isSelectingItemDisabled(item);
  };

  /**
   * Returns true if selecting whole page is disabled
   */
  const handleIsSelectingPageDisabled = () => {
    if (!isSelectingPageDisabled) {
      return false;
    }

    return isSelectingPageDisabled();
  };

  /**
   * Selects a row from the table
   */
  const handleSelectItem = useCallback((item: T, isSelected: boolean) => {
    const {
      newSelectedItems,
      newSelectAll,
    } = selectItem(data, selectedData, isPageSelected, item, isSelected, selectPredicateOrKey);

    if (!updateSelection) return;
    updateSelection(newSelectedItems, newSelectAll, false);
  }, [
    updateSelection,
    data,
    selectedData,
    isPageSelected,
    selectPredicateOrKey,
  ]);

  /**
   * Selects all rows on current page
   */
  const handleSelectAllItems = useCallback(() => {
    const {
      newSelectedItems,
      newSelectAll,
    } = selectAllItems(data, selectedData, isPageSelected, selectPredicateOrKey);

    if (!updateSelection) return;
    updateSelection(newSelectedItems, newSelectAll, false);
  }, [
    updateSelection,
    data,
    selectedData,
    isPageSelected,
    selectPredicateOrKey,
  ]);

  /**
   * Selects whole table (all rows in the table)
   */
  const selectWholeTable = useCallback(() => {
    if (!updateSelection) return;
    updateSelection(selectedData!, isPageSelected!, !isTableSelected);
  }, [
    updateSelection,
    selectedData,
    isPageSelected,
    isTableSelected,
  ]);

  /**
   * Unselect all items
   */
  const clearSelection = useCallback(() => {
    if (!updateSelection) return;
    updateSelection([], false, false);
  }, [updateSelection]);

  /**
   * Preserve table state in Redux (if preserveState is set to true and tableId is provided)
   */
  const preserveTableState = useCallback((instance: any) => {
    if (!tableId) return;

    const {
      page,
      pageSize,
    } = getPagingParamsFromTable(instance);

    const {
      sortColumn,
      sortDirection,
    } = getSortParamsFromTable(instance, sortDirEnum);

    const tableState = {
      pageNumber: page + 1,
      pageSize,
      sortColumn,
      sortDirection,
      ...filtersToPreserve,
    };

    updateTableState(tableId, tableState);
  }, [
    tableId,
    sortDirEnum,
    updateTableState,
    filtersToPreserve,
  ]);

  const handleFetchData = useCallback((state: any, instance: any) => {
    if (selectable && updateSelection) {
      updateSelection(selectedData!, isPageSelected!, false);
    }

    if (preserveState) {
      preserveTableState(instance);
    }

    if (onFetchData) {
      onFetchData(state, instance);
    }
  }, [
    onFetchData,
    selectable,
    updateSelection,
    selectedData,
    isPageSelected,
    preserveState,
    preserveTableState,
  ]);

  const getColumns = (): Array<Column<T>> => {
    if (!selectable) {
      return columns;
    }

    return [
      {
        Header: () => (
          <SelectAllCell
            isSelected={!!isPageSelected}
            selectAllItems={handleSelectAllItems}
            isDisabled={handleIsSelectingPageDisabled()}
          />
        ),
        width: 60,
        accessor: '',
        Cell: (cellProps) => (
          <SelectCell
            select={handleSelectItem}
            item={cellProps.value}
            isSelected={isSelectedItem(selectedData, cellProps.value, selectPredicateOrKey)}
            isDisabled={handleIsSelectingItemDisabled(cellProps.original)}
          />
        ),
      },
      ...columns,
    ];
  };

  /**
   * Returns columns wrapped with bulked actions
   */
  const getWrappedColumns = () => {
    const wrappedColumns = [
      {
        Header: (
          <TableBulkActions
            selectedItems={selectedData}
            tableName={tableName}
            isPageSelected={isPageSelected}
            isTableSelected={isTableSelected}
            selectWholeTableEnabled={selectWholeTableEnabled}
            totalCount={totalCount}
            clearAll={clearSelection}
            selectWholeTable={selectWholeTable}
            bulkActions={(
              <BulkActionSelector
                selectedBulkAction={selectedBulkAction}
                onSelectBulkAction={handleSelectBulkAction}
                options={bulkActionsList!}
              />
            )}
          />
        ),
        columns: getColumns(),
      },
    ];

    return wrappedColumns;
  };

  const stripedClass = striped ? '-striped' : '';
  const highlightClass = highlight ? '-highlight' : '';
  const narrowClass = narrow ? '-narrow' : '';
  const stickyHeaderClass = stickyHeader ? '-sticky-header' : '';

  const bulkActionsClass = (isBulkActionsMode || (bulkActionsList && bulkActionsList.length > 0 && selectedData && selectedData.length > 0)) ? 'is-bulk-actions-active' : '';

  const sorted = useMemo(() => {
    const sortedList = [];

    if (!initialSortColumn || !initialSortDirection) return;

    let sortDirection = initialSortDirection;
    if (sortDirEnum === sortDirectionEnum) {
      if (initialSortDirection === sortDirectionEnum.Asc) {
        sortDirection = sortDirectionShortEnum.Asc;
      } else {
        sortDirection = sortDirectionShortEnum.Desc;
      }
    }

    sortedList.push({
      id: initialSortColumn,
      sort: sortDirection,
      asc: sortDirection === sortDirectionShortEnum.Asc,
      desc: sortDirection === sortDirectionShortEnum.Desc,
    });

    return sortedList;
  }, [
    initialSortColumn,
    initialSortDirection,
    sortDirEnum,
  ]);

  return (
    <div className={classNames}>
      <div>
        <ReactTable<T>
          manual={true}
          filterable={false}
          sortable={false}
          multisort={false}
          minRows={0}

          data={data}
          columns={
            (bulkActionsList && bulkActionsList.length > 0 || showWrappedColumns)
              ? getWrappedColumns()
              : getColumns()
          }
          onFetchData={handleFetchData}
          pages={totalPages}

          page={initialPageNumber !== undefined ? (initialPageNumber - 1) : undefined}
          pageSize={initialPageSize}
          sorted={sorted}

          defaultPageSize={defaultPageSize ?? defaultPageSizeSmallTable}
          pageSizeOptions={pageSizeOptions ?? pageSizeOptionsSmallTable}
          showPagination={
            showPagination !== undefined
              ? showPagination
              : (data?.length && (hasNextPage || hasPreviousPage))
          }

          className={`${stripedClass} ${highlightClass} ${narrowClass} ${bulkActionsClass} ${stickyHeaderClass}`}
          getTrProps={getTrProps}
          SubComponent={subComponent}

          {...customProps}
        />
      </div>
    </div>
  );
};

export default function ConnectedTable<T>() {
  return connector(Table as (props: Props<T>) => JSX.Element);
}
