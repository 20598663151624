/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Financials Homefield API
 * OpenAPI spec version: v1
 */
import type { PayoutMethodEnum } from './payoutMethodEnum';

export interface RequestPayoutCommand {
  lockerManagerId?: number;
  method?: PayoutMethodEnum;
  payoutRecipientId?: number;
  lockerIds?: number[] | null;
  orderNumbers?: number[] | null;
  orderItemIds?: number[] | null;
}
