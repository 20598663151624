import React, { useCallback, useMemo, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Field, formValueSelector, InjectedFormProps, reduxForm } from 'redux-form';
import { validateOrderRefund } from '@redux/orderManagement/validations';
import { voucherOrderForm } from '@constants/reduxForms';
import Modal from '@sharedComponents/Modal/Modal';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import Input from '@sharedComponents/Form/Input';
import OrderManagementOrderRefundItems from './OrderManagementOrderRefundItems';
import { OrderDetailsDto } from '@api/fulfillment/models';
import { OrderVoucherFormData } from '@models/forms/OrderManagement/OrderVoucherFormData';
import { useGetHomefieldApiOrdermanagementOrdersvoucherapplypricecalculation } from '@api/fulfillment/order-management';
import { RootState } from 'store';
import SimpleConfirmationModal from '@components/shared/Modal/SimpleConfirmationModal';
import { Typography } from '@material-ui/core';
import { formatDollarAmount } from '@util/numberHelpers';

interface OwnProps {
  order: OrderDetailsDto;
  isOpen: boolean;
  closeModal: () => void;
  onSubmit: (form: OrderVoucherFormData) => void;
  voucherOrderNumber?: string;
  voucherCode?: string;
}

const selector = formValueSelector(voucherOrderForm);

const mapStateToProps = (state: RootState) => ({
  voucherOrderNumber: selector(state, 'voucherOrderNumber'),
  voucherCode: selector(state, 'voucherCode'),
});

const connector = connect(mapStateToProps);

type FormProps = OwnProps & ConnectedProps<typeof connector>;

export type Props = FormProps & InjectedFormProps<OrderVoucherFormData, FormProps, string[]>;

const OrderManagementOrderVoucherModal = React.memo<Props>(({
  order,
  isOpen,
  closeModal,
  handleSubmit,
  voucherOrderNumber,
  voucherCode,
  reset,
}) => {
  const [
    confirmationModalIsOpen,
    setConfirmationModalIsOpen,
  ] = useState<boolean>(false);

  const isEnabled = useMemo(() => {
    const validOrderNum = parseInt(voucherOrderNumber ?? '0', 10) > 5000000;
    const validCode = (voucherCode ? voucherCode.length : 0) > 5;

    return validOrderNum && validCode;
  }, [voucherOrderNumber, voucherCode]);

  const { data } = useGetHomefieldApiOrdermanagementOrdersvoucherapplypricecalculation({
    orderNumber: order.orderNumber,
    voucherOrderId: parseInt(voucherOrderNumber ?? '0', 10),
    voucherCode: voucherCode ?? '',
  }, {
    query: {
      enabled: isEnabled,
    },
  });

  const openConfirmModel = useCallback(() => {
    setConfirmationModalIsOpen(true);
  }, []);

  const closeConfirmModel = useCallback(() => {
    setConfirmationModalIsOpen(false);
  }, []);

  const closeMainModal = useCallback(() => {
    closeModal();
    reset();
  }, [closeModal, reset]);

  return (
    <Modal
      isOpen={isOpen}
      closeModal={closeMainModal}
      modalHeight={'l'}
      modalWidth={'l'}
      title={`Apply Voucher To Order O${order.orderNumber}?`}
      buttons={(
        <ModalButtons
          confirmBtnText={'Apply Voucher'}
          cancelBtnText={'Cancel'}
          onClose={closeMainModal}
          onConfirm={isEnabled ? openConfirmModel : undefined}
          confirmBtnDisabled={!data?.result}
        />
      )}
    >
      <>
        {isOpen
          && (
            <div className='mt-20'>
              <form
                className='refund__color-river-bed filter-group w-100 pr-10'
                id={voucherOrderForm}
                onSubmit={handleSubmit}
              >
                <Field
                  name={'voucherOrderNumber'}
                  placeholder={'Enter The Voucher Order Number'}
                  component={Input}
                  type={'text'}
                />

                <Field
                  name={'voucherCode'}
                  placeholder={'Enter The Voucher Code'}
                  component={Input}
                  type={'text'}
                />
              </form>
              {data
                && (
                  <>
                    <OrderManagementOrderRefundItems
                      items={order.items?.filter(
                        (item) => item.ids?.some((num) => data?.result?.items?.map((x) => x.id).includes(num)),
                      ) ?? []}
                      negate={true}
                      coreOrderItemsPricing={undefined}
                    />
                    <Typography
                      variant='h6'
                      className='order-financial-details__content'
                      style={{
                        textAlign: 'right',
                        marginRight: 60,
                      }}
                    >
                      Amount to refund: <span className='negative-amount'>{`-${formatDollarAmount(data?.result?.totalAmount ?? 0)}`}</span>
                    </Typography>
                  </>
                )}
            </div>
          )}
        <SimpleConfirmationModal
          isOpen={confirmationModalIsOpen}
          confirm={closeConfirmModel}
          closeModal={closeConfirmModel}
          title={'Are you sure?'}
          isDangerousAction={true}
          formId={voucherOrderForm}
          formSubmission={true}
          confirmationBody={'Are you sure you wish to apply this voucher to this order?  This will result in a refund to the customer.'}
        />
      </>
    </Modal>
  );
});

export default connector(
  reduxForm<OrderVoucherFormData, FormProps, string[]>({
    form: voucherOrderForm,
    validate: validateOrderRefund,
    shouldError: () => true,
    enableReinitialize: true,
  })(OrderManagementOrderVoucherModal),
);
