import React, { useRef } from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import ButtonLink from '@components/shared/Navigation/ButtonLink';
import { Typography } from '@material-ui/core';
import UploadNewBulkBuild from './UploadNewBulkBuild';
import BulkBuildJobTable from './BulkBuildJobTable';

export interface TableRefObject {
  getStagingJobs: () => void;
}

const BulkBuild = () => {
  const tableRef = useRef<TableRefObject>(null);

  return (
    <Grid container={true} spacing={2}>
      <Grid item={true} xs={12}>
        <Box display='flex' flexDirection='column'>
          <Box display='flex' justifyContent='space-between'>
            <Box display='flex' flexDirection='column'>
              <Typography variant='h6'>Bulk Build Organizations and Stores</Typography>
              <Typography variant='caption'>
                <em>Jobs with store counts greater than 1000 will not show details</em>
              </Typography>
            </Box>
            <Box gridGap={10} display='flex'>
              <ButtonLink
                type='secondary'
                text='Download Template'
                // Get this from ENV
                linkTo='https://teamlocker.s3.us-east-1.amazonaws.com/uploads/bulk-build-files/bulk_build_template.xlsx'
                linkClasses='ml-10'
                openInNewTab={true}
                size='s'
              />
              <UploadNewBulkBuild
                getStagingJobs={tableRef.current?.getStagingJobs}
              />
            </Box>
          </Box>
        </Box>
      </Grid>
      <Grid item={true} xs={12}>
        <BulkBuildJobTable ref={tableRef} />
      </Grid>
    </Grid>
  );
};

export default BulkBuild;
