import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  fetchPartners,
  enableCLCForLocker,
} from '@redux/lockerManager/actions';
import { materialSwal } from '@util/componentHelper';
import Expander from '@sharedComponents/Buttons/Expander';
import EditButton from '@sharedComponents/Display/EditButton';
import LockerStatus from '../LockerManagerTable/LockerManagerQuickView/LockerStatus';
import LockerManagerEditModal from '../LockerManagerModals/LockerManagerEditModal';
import LockerManagerCLCModal from '../LockerManagerModals/LockerManagerCLCModal';
import LockerChangeLogsModal from '../LockerManagerModals/LockerChangeLogsModal';
import LockerManagerHeaderDetails from './LockerManagerHeaderDetails';
import Label from '@components/shared/Display/Label';
import { Helmet } from 'react-helmet';

class LockerManagerDetailsHeader extends Component {
  state = {
    detailsAreExpanded: true,
    editLockerDetailsModalIsOpen: false,
    editCLCModalIsOpen: false,
    lockerChangeLogsModalIsOpen: false,
    browserTitle: 'Locker Manager',
  };

  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(fetchPartners());
  }

  changeExpand = () => {
    const { detailsAreExpanded } = this.state;
    this.setState({ detailsAreExpanded: !detailsAreExpanded });
  };

  openLockerChangeLogsModal = () => {
    this.setState({ lockerChangeLogsModalIsOpen: true });
  };

  closeLockerChangeLogsModal = () => {
    this.setState({ lockerChangeLogsModalIsOpen: false });
  };

  openEditLockerDetailsModal = () => {
    this.setState({ editLockerDetailsModalIsOpen: true, browserTitle: 'Edit Locker Details' });
  };

  closeEditLockerDetailsModal = () => {
    this.setState({ editLockerDetailsModalIsOpen: false });
  };

  openEditCLCModal = () => {
    this.setState({ editCLCModalIsOpen: true });
  };

  closeEditCLCModal = () => {
    this.setState({ editCLCModalIsOpen: false });
  };

  enableCLC = async (lockerId) => {
    const { dispatch } = this.props;

    const res = await dispatch(enableCLCForLocker(lockerId));
    if (res?.success) {
      materialSwal('Success', res.message, 'success');
      this.closeEditCLCModal();
    }
  };

  render() {
    const {
      lockerInfo,
      partners,
      orderItemsStatus,
      lockerChangeLogs,
    } = this.props;

    const {
      detailsAreExpanded,
      editCLCModalIsOpen,
      editLockerDetailsModalIsOpen,
      lockerChangeLogsModalIsOpen,
      browserTitle,
    } = this.state;

    const lockerId = lockerInfo.id;
    const logosUnapproved = !lockerInfo.logosApproved;

    return (
      <div className='lockerManagerEdit__header'>
        <Helmet>
          <title>{`${lockerInfo.storeName} - ${browserTitle} - L${lockerId}`}</title>
        </Helmet>
        <LockerManagerCLCModal
          isOpen={editCLCModalIsOpen}
          closeModal={this.closeEditCLCModal}
          enableCLC={this.enableCLC}
          locker={lockerInfo}
        />
        <LockerChangeLogsModal
          isOpen={lockerChangeLogsModalIsOpen}
          closeModal={this.closeLockerChangeLogsModal}
          lockerChangeLogs={lockerChangeLogs}
        />
        <LockerManagerEditModal
          isOpen={editLockerDetailsModalIsOpen}
          closeModal={this.closeEditLockerDetailsModal}
        />
        <div className='lockerManagerEdit__details-header'>
          <div className='lockerManagerEdit__details-header--info'>
            Locker L{lockerId}
            {
              lockerInfo.status &&
              <LockerStatus status={lockerInfo.status} />
            }
            {
              lockerInfo.meetsVerifiedDataRequirements &&
              <Label
                text={'No Additional Information Required'}
                tooltipText='No additional information required for this locker'
                type={'status-green'}
                dataTest='label-status'
              />
            }
            {
              !lockerInfo.meetsVerifiedDataRequirements &&
              <Label
                text={'Additional Information Required'}
                tooltipText='Additional information required for this locker'
                type={'status-orange'}
                dataTest='label-status'
              />
            }
            {
              logosUnapproved &&
              <div className='tooltip-container'>
                <div>
                  <div className='lockerManagerEdit__details-header--info__unapproved-logos-label button'>
                    <span className='lockerManager__details--status unapproved'>
                      <span className='material-icons logo-bank__used--logo--unapproved-label__icon'>warning</span>
                      No Logo
                    </span>
                  </div>
                </div>
                <div className='tooltip-text lockerManagerEdit__tooltip-text'>Locker doesn't have any approved logos</div>
              </div>
            }
            <EditButton
              onClick={this.openEditLockerDetailsModal}
              dataTest={'locker-edit'}
            />
          </div>
          <Expander
            isExpanded={detailsAreExpanded}
            toggle={this.changeExpand}
          />
        </div>
        {
          detailsAreExpanded &&
          <LockerManagerHeaderDetails
            locker={lockerInfo}
            partners={partners}
            openEditCLCModal={this.openEditCLCModal}
            openLockerChangeLogsModal={this.openLockerChangeLogsModal}
            orderItemsStatus={orderItemsStatus}
          />
        }
      </div>
    );
  }
}

LockerManagerDetailsHeader.propTypes = {
  lockerInfo: PropTypes.object.isRequired,
  partners: PropTypes.array.isRequired,
  orderItemsStatus: PropTypes.shape({
    available: PropTypes.number,
    processing: PropTypes.number,
    requestedProcessing: PropTypes.number,
    requestedCanceled: PropTypes.number,
    completed: PropTypes.number,
    items: PropTypes.arrayOf(PropTypes.object),
  }),
  lockerChangeLogs: PropTypes.arrayOf(PropTypes.shape({
    changedOn: PropTypes.string.isRequired,
    changedBy: PropTypes.string.isRequired,
    property: PropTypes.string.isRequired,
    oldValue: PropTypes.string.isRequired,
    newValue: PropTypes.string.isRequired,
  })),
};

const mapStateToProps = ({
  lockerManager,
  fundraisingPayouts,
}) => ({
  partners: lockerManager.partners,
  orderItemsStatus: fundraisingPayouts.orderItemsStatus,
  lockerChangeLogs: lockerManager.lockerChangeLogs,
});

export default connect(mapStateToProps)(LockerManagerDetailsHeader);
