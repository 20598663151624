/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */
import type { ApplicationSetting } from './applicationSetting';

export interface ApplicationSettingsDto {
  workerCount?: ApplicationSetting;
  artworkTerminalId?: ApplicationSetting;
  schedulingTerminalId?: ApplicationSetting;
}
