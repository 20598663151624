/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */
import type { ShippingInfoDto } from './shippingInfoDto';
import type { CouponTypeEnum } from './couponTypeEnum';
import type { OrderItemDetailsDto } from './orderItemDetailsDto';
import type { RushModel } from './rushModel';
import type { OrderStatusEnumStatusModel } from './orderStatusEnumStatusModel';
import type { SubmitStatusEnum } from './submitStatusEnum';
import type { OrderPaymentGiftCardDto } from './orderPaymentGiftCardDto';
import type { OrderPaymentHistory } from './orderPaymentHistory';

export interface OrderDetailsDto {
  id?: number;
  orderNumber?: number;
  created?: string | null;
  datePlaced?: string;
  shipDate?: string;
  customerShipDate?: string;
  userId?: number | null;
  homefieldUserId?: string | null;
  shippingInfo?: ShippingInfoDto;
  pricePaid?: number;
  taxableAmount?: number | null;
  taxAmount?: number | null;
  pretaxPrice?: number;
  shippingBasePrice?: number | null;
  shippingPretaxPrice?: number | null;
  shippingTaxableAmount?: number | null;
  shippingTaxAmount?: number | null;
  shippingPricePaid?: number | null;
  shippingCouponDiscount?: number | null;
  shippingRefundAmount?: number | null;
  shippingChargeAmount?: number | null;
  subtotal?: number | null;
  markdown?: number | null;
  subtotalCouponDiscount?: number | null;
  subtotalRefundAmount?: number | null;
  tradeDiscount?: number | null;
  couponValue?: number | null;
  couponType?: CouponTypeEnum;
  couponAppliesToShipping?: boolean | null;
  couponCode?: string | null;
  expressProduction?: boolean;
  items?: OrderItemDetailsDto[] | null;
  edited?: string;
  actualShipDate?: string | null;
  canceled?: string | null;
  refunded?: string | null;
  charged?: string | null;
  paused?: string | null;
  tradeDiscountTax?: number | null;
  markdownTax?: number | null;
  shippingRefundTaxAmount?: number | null;
  shippingChargeTaxAmount?: number | null;
  shippingCouponDiscountTax?: number | null;
  isCompleteRefund?: boolean | null;
  subtotalChargeAmount?: number | null;
  subtotalChargeTaxAmount?: number | null;
  rush?: RushModel;
  status?: OrderStatusEnumStatusModel;
  organizationId?: number | null;
  organizationName?: string | null;
  lockerId?: number | null;
  lockerTeamName?: string | null;
  gpCustomerId?: string | null;
  gpDocumentId?: string | null;
  gpDocId?: string | null;
  salesOrderNumber?: string | null;
  salesOrderId?: number | null;
  accountingId?: string | null;
  stripePaymentMethod?: string | null;
  accountingSubmitStatus?: SubmitStatusEnum;
  netSuiteAccountingSubmitStatus?: SubmitStatusEnum;
  isCoreOrder?: boolean;
  giftCards?: OrderPaymentGiftCardDto[] | null;
  orderPaymentHistory?: OrderPaymentHistory[] | null;
}
