import { BulkCreateJobStatusEnum } from '@api/accountsv1/models';

export const getBulkCreateJobStatusEnumDisplayName = (enumValue: BulkCreateJobStatusEnum): string => {
  switch (enumValue) {
    case BulkCreateJobStatusEnum.NotScheduled:
      return 'Not Scheduled';
    case BulkCreateJobStatusEnum.Pending:
      return 'Pending';
    case BulkCreateJobStatusEnum.InProgress:
      return 'In Progress';
    case BulkCreateJobStatusEnum.Failed:
      return 'Failed';
    case BulkCreateJobStatusEnum.Done:
      return 'Done';
    default:
      return enumValue.toString();
  }
};
