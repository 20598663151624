/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */
import type { PrintAssemblyStats } from './printAssemblyStats';
import type { PrepressWarningMessage } from './prepressWarningMessage';

export interface SheetJobDetails {
  jobId?: number;
  resultUrls?: string[] | null;
  printAssemblyInfo?: PrintAssemblyStats;
  warningMessages?: PrepressWarningMessage[] | null;
}
