/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Accounts Homefield API
 * OpenAPI spec version: 1.0
 */

export type BulkCreateJobStatusEnum = 'NotScheduled' | 'Pending' | 'InProgress' | 'Failed' | 'Done';


export const BulkCreateJobStatusEnum = {
  NotScheduled: 'NotScheduled' as BulkCreateJobStatusEnum,
  Pending: 'Pending' as BulkCreateJobStatusEnum,
  InProgress: 'InProgress' as BulkCreateJobStatusEnum,
  Failed: 'Failed' as BulkCreateJobStatusEnum,
  Done: 'Done' as BulkCreateJobStatusEnum,
};
