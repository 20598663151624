 
import React, { useEffect } from 'react';
import {
  FieldArray,
  InjectedFormProps,
  reduxForm,
} from 'redux-form';
import { scanOrderItemsBarcodesForm } from '@constants/reduxForms';
import { ScanBarcodesFormData } from '@models/forms/ScanBarcodesFormData';
import { validateOrderItemsScanBarcodesForm } from '@redux/productionAssembly/validations';
import ScanItemBarcodeInputs from './ScanBarcodesFormContent/ScanItemBarcodeInputs';

interface OwnProps {
  itemBarcodes: {
    orderItemId: number;
    barcode: string | null;
  }[];
}

type FormProps = OwnProps;

export type Props = FormProps & InjectedFormProps<ScanBarcodesFormData, FormProps, string[]>;

const ScanBarcodesForm = React.memo<Props>(({
  initialize,
  itemBarcodes,
  handleSubmit,
}) => {
  useEffect(() => {
    initialize({
      itemBarcodes: itemBarcodes.map((itemBarcode) => ({
        orderItemId: itemBarcode.orderItemId,
        barcode: itemBarcode.barcode,
      })),
    });
  }, [
    initialize,
    itemBarcodes,
  ]);

  return (
    <form
      className='redux-form'
      onSubmit={handleSubmit}
      id={scanOrderItemsBarcodesForm}
    >
      <div>
        <div className='ml-15 mr-10'>
          <div>
            <div className='redux-form__row'>
              <div className='redux-form__column--size-m'>
                <label className='redux-form__label'>
                  Item Barcodes
                  <span className='required'>*</span>
                </label>
                <FieldArray
                  name={'itemBarcodes'}
                  placeholder={'Scan or Enter an Item Barcode'}
                  component={ScanItemBarcodeInputs as any}
                  rerenderOnEveryChange={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
});

export default (reduxForm<ScanBarcodesFormData, FormProps, string[]>({
  form: scanOrderItemsBarcodesForm,
  validate: validateOrderItemsScanBarcodesForm as any,
}))(ScanBarcodesForm);
