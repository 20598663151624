/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Catalog Homefield API
 * OpenAPI spec version: v1
 */

export interface DtgEnvironmentSimpleDto {
  id?: number;
  environmentName?: string | null;
}
