/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Catalog Homefield API
 * OpenAPI spec version: v1
 */
import type { PersonalizationTypeEnum } from './personalizationTypeEnum';
import type { PersonalizationColorTypeEnum } from './personalizationColorTypeEnum';

export interface PersonalizationLayoutItemDto {
  locationId?: number;
  required?: boolean;
  personalizationType?: PersonalizationTypeEnum;
  personalizationColorType?: PersonalizationColorTypeEnum;
  decorationHeight?: number | null;
}
