import { Dispatch } from 'redux';
import * as actionTypes from './types';
import * as variations from '@api/orders/variations';
import { makeApiCall } from '@util/apiHelper';
import { VariationsQueue } from './models';

export const updateVariationsQueue = (data: VariationsQueue) => ({
  type: actionTypes.UPDATE_VARIATIONS_QUEUE,
  payload: {
    totalPages: data.totalPages,
    hasPreviousPage: data.hasPreviousPage,
    hasNextPage: data.hasNextPage,
    pageNumber: data.pageNumber,
    queue: data.items,
    totalCount: data.totalCount,
  },
});

export const fetchVariations = (
  pageNumber = 1,
  pageSize = 12,
  salesChannelsId: number[] = [],
  filter = '',
  categoryIdsFilter: number[] = [],
  colorGroupsFilter: string[] = [],
  decorationMethodsFilter: string[] = [],
  decorationLocationsFilter: string[] = [],
  logosFilter: number[] = []
) => async (dispatch: Dispatch) => {
  const params = {
    pageNumber,
    pageSize,
    salesChannelsId,
    search: filter,
    categoryId: categoryIdsFilter,
    colorGroup: colorGroupsFilter,
    decorationMethod: decorationMethodsFilter,
    decorationLocation: decorationLocationsFilter,
    logoId: logosFilter,
  };

  const call = variations.getHomefieldApiVariations(params);
  const data = await makeApiCall(call);

  if (data) {
    dispatch(updateVariationsQueue(data));
  }
};
