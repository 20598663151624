/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Catalog Homefield API
 * OpenAPI spec version: v1
 */
import type { SyncEventsResultDto } from './syncEventsResultDto';

export interface SyncEventsResultDtoApiResponse {
  success?: boolean;
  message?: string | null;
  result?: SyncEventsResultDto;
}
