/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */
import type { ShippingInfoDto } from './shippingInfoDto';
import type { OrderStatusEnumStatusModel } from './orderStatusEnumStatusModel';
import type { QueriesOrderItemDto } from './queriesOrderItemDto';

export interface ProductionAssemblyDetailsDto {
  id?: number;
  datePlaced?: string;
  shipDate?: string;
  oldShipDate?: string | null;
  customerShipDate?: string;
  orderNumber?: number;
  lockerId?: number | null;
  readonly lockerNumber?: number | null;
  lockerTeamName?: string | null;
  organizationId?: number | null;
  organizationName?: string | null;
  gpDocumentId?: string | null;
  gpCustomerId?: string | null;
  shippingInfo?: ShippingInfoDto;
  isCanceled?: boolean;
  isPaused?: boolean;
  isRushed?: boolean;
  isExpressProduction?: boolean;
  readonly isAssemblyDisabled?: boolean;
  status?: OrderStatusEnumStatusModel;
  items?: QueriesOrderItemDto[] | null;
  readonly itemsUniqueCount?: number;
  productionNote?: string | null;
}
