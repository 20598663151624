import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import html2canvas from 'html2canvas';
import { getColors } from '@redux/productCatalog/actions';
import { fetchArtworkFileTaskFailReasons } from '@redux/artworkFileTasks/actions';
import {
  fetchSheetDetails,
  changeArtworkInventoryLocation,
} from '@redux/printRoomArt/actions';
import { extractParameterFromPath } from '@util/stringHelpers';
import { materialSwal } from '@util/componentHelper';
import { getItemQuantityCount } from '@util/componentHelpers/sheetDetailsHelper';
import LogoPreviewModal from '@sharedComponents/Modal/LogoPreviewModal';
import Modal from '@sharedComponents/Modal/Modal';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import PrintSheetContent from './PrintSheetContent';
import PrintSheetRejectModal from './PrintSheetReject/PrintSheetRejectModal';

class PrintSheet extends Component {
  state = {
    modalIsOpen: false,
    modalOrderId: null,
    modalInventoryLocation: null,
    previewModalIsOpen: false,
    imageUrl: null,
    renderPrintSheet: false,
    rejectModalIsOpen: false,
    artworkTaskId: null,
    failReasonValue: null,
    failNoteValue: null,
  };

  componentDidMount() {
    const { dispatch } = this.props;
    const sheetId = extractParameterFromPath(this.props, 'sheetId', 'number');

    dispatch(getColors());
    dispatch(fetchArtworkFileTaskFailReasons());
    dispatch(fetchSheetDetails(sheetId));
  }

  componentDidUpdate(prevProps) {
    const { dispatch } = this.props;

    const sheetId = extractParameterFromPath(this.props, 'sheetId', 'number');
    const oldSheetId = extractParameterFromPath(prevProps, 'sheetId', 'number');

    if (sheetId !== oldSheetId) {
      dispatch(fetchSheetDetails(sheetId));
    }
  }

  openModal = (orderId, inventoryLocation) => {
    this.setState({
      modalIsOpen: true,
      modalOrderId: orderId,
      modalInventoryLocation: inventoryLocation,
    });
  };

  closeModal = () => {
    this.setState({
      modalIsOpen: false,
      modalOrderId: null,
      modalInventoryLocation: null,
    });
  };

  openPreviewModal = (imageUrl) => {
    this.setState({
      previewModalIsOpen: true,
      imageUrl,
    });
  };

  closePreviewModal = () => {
    this.setState({
      previewModalIsOpen: false,
      imageUrl: null,
    });
  };

  openRejectModal = () => {
    this.setState({ rejectModalIsOpen: true });
  };

  closeRejectModal = () => {
    this.setState({
      rejectModalIsOpen: false,
      artworkTaskId: null,
      failReasonValue: null,
      failNoteValue: null,
    });
  };

  changeInventoryLocation = async () => {
    const {
      modalOrderId,
      modalInventoryLocation,
    } = this.state;

    const {
      dispatch,
      sheet: { id },
    } = this.props;

    const inputField = document.getElementById('artwork-inventory-field');

    if (inputField) {
      const { value } = inputField;
      if (value !== modalInventoryLocation) {
        const res = await dispatch(changeArtworkInventoryLocation(modalOrderId, id, value));
        if (res) {
          this.closeModal();
        } else {
          materialSwal(`Artwork Inventory Location changed to ${value}.`);
        }
      } else {
        materialSwal('Error', 'Please choose a different Artwork Inventory Location.', 'error');
      }
    } else {
      materialSwal('Error', 'Something went wrong.', 'error');
    }
  };

  updateFailReason = (e) => {
    const failReasonValue = e.target.value;
    this.setState(() => ({ failReasonValue }));
  };

  updateFailNote = (e) => {
    const failNoteValue = e.target.value;
    this.setState(() => ({ failNoteValue }));
  };

  /*
   * Users can only print full documents, and not standalone elements
   * Since iframe creates a new document inside the DOM, we can print that instead.
   * This creates a canvas from the print sheet, appends it to a div in our iframe,
   * then prints the iframe. See inline styles for iframe -- display: none.
   */
  createPrintableSheet = () => {
    const options = {
      allowTaint: true,
      logging: false,
    };

    this.setState({ renderPrintSheet: true }, async () => {
      const w = this.sheet.style.width;
      this.sheet.style.width = 1000;
      try {
        const canvas = await html2canvas(this.sheet, options);
        this.canvasFrame.contentDocument.body.appendChild(canvas);
        this.canvasFrame.contentWindow.print();
        this.setState({ renderPrintSheet: false });
        this.canvasFrame.src = 'about:blank';
        this.sheet.style.width = w;
      } catch (err) {
        return console.error(err);
      }
    });
  };

  setSheetRef = (r) => {
    this.sheet = r;
  };

  setCanvasFrameRef = (r) => {
    this.canvasFrame = r;
  };

  render() {
    const {
      sheet,
      oidc,
      colorsDictionary,
      colors,
    } = this.props;

    const orderNumber = extractParameterFromPath(this.props, 'orderNumber', 'number');
    const barcode = extractParameterFromPath(this.props, 'barcode');

    const {
      imageUrl,
      renderPrintSheet,
      previewModalIsOpen,
      modalIsOpen,
      modalInventoryLocation,
      rejectModalIsOpen,
    } = this.state;

    const barcodeDecoded = barcode ? barcode.replace('&', '/') : barcode;

    const itemQuantityCount = getItemQuantityCount(sheet);

    if (sheet.id) {
      return (
        <>
          <Modal
            isOpen={modalIsOpen}
            closeModal={this.closeModal}
            title={'Artwork Inventory Location'}
            modalSize={'s'}
            buttons={(
              <ModalButtons
                onClose={this.closeModal}
                onConfirm={this.changeInventoryLocation}
                confirmBtnText={'Set inventory location'}
              />
            )}
          >
            <div className='align align--center'>
              <div className='modal__input'>
                <div className='text-field m-l-24'>
                  <input
                    id={'artwork-inventory-field'}
                    name={'Artwork Inventory Location'}
                    type={'text'}
                    placeholder={'Location'}
                    defaultValue={modalInventoryLocation}
                  />
                </div>
              </div>
            </div>
          </Modal>

          <LogoPreviewModal
            modalIsOpen={previewModalIsOpen}
            closeModal={this.closePreviewModal}
            imageUrl={imageUrl}
          />

          <div className='align align--center'>
            <PrintSheetRejectModal
              isOpen={rejectModalIsOpen}
              sheetId={sheet.id}
              closeModal={this.closeRejectModal}
            />
          </div>

          <div
            className='container'
            ref={this.setSheetRef}
          >
            {
              renderPrintSheet &&
                <div>
                  <h1 className='mb-10'>{`Sheet ${sheet.id}`}</h1>
                  <p>
                    Logos: {itemQuantityCount.logoCount} | Personalizations: {itemQuantityCount.personalizationCount}
                  </p>
                  <p>{`Printed By: ${oidc.user.profile.name}`}</p>
                </div>
            }

            <PrintSheetContent
              openModal={this.openModal}
              openRejectModal={this.openRejectModal}
              sheet={sheet}
              barcode={barcodeDecoded}
              createPrintableSheet={this.createPrintableSheet}
              orderNumber={orderNumber}
              openPreviewModal={this.openPreviewModal}
              renderPrintSheet={renderPrintSheet}
              colorsDictionary={colorsDictionary}
              colors={colors}
            />

            <iframe
              style={renderPrintSheet ? {} : { display: 'none' }}
              ref={this.setCanvasFrameRef}
            />
          </div>
        </>
      );
    }

    return (
      <div className='container' />
    );
  }
}

PrintSheet.propTypes = {
  sheet: PropTypes.object,
  match: PropTypes.shape({
    params: PropTypes.shape({
      sheetId: PropTypes.string.isRequired,
      orderId: PropTypes.string,
      barcode: PropTypes.string,
    }).isRequired,
  }),
  failReasons: PropTypes.array,
  oidc: PropTypes.object,
  colorsDictionary: PropTypes.objectOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    dtgCode: PropTypes.string,
    hexValue: PropTypes.string,
    cmykValue: PropTypes.string.isRequired,
    dtgCmykValue: PropTypes.string.isRequired,
    brightness: PropTypes.string.isRequired,
    threadValue: PropTypes.string,
  })).isRequired,
  colors: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    dtgCode: PropTypes.string,
    cmykValue: PropTypes.string.isRequired,
    dtgCmykValue: PropTypes.string.isRequired,
    hexValue: PropTypes.string,
    threadValue: PropTypes.string,
    brightness: PropTypes.string.isRequired,
    isArtwork: PropTypes.bool,
    isFlood: PropTypes.bool,
  })).isRequired,
};

const mapStateToProps = ({
  printRoomArt,
  productCatalog,
  oidc,
}) => ({
  oidc,
  sheet: printRoomArt.sheet,
  colorsDictionary: productCatalog.colorsDictionary,
  colors: productCatalog.colors,
});

export default connect(mapStateToProps)(PrintSheet);
