import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { adminDetailsUrl } from '@constants/clientUrls/clientUrls';
import { dateRangeEnum } from '@constants/enums/dateRangeEnum';
import { sortDirectionShortEnum } from '@constants/enums/commonEnums';
import {
  associateAdminWithLocker,
  removeManagerFromLocker,
  updateLockerOwner,
} from '@redux/lockerManager/actions';
import { fetchLockerAdmins } from '@redux/admins/actions';
import { parseDateNumeric } from '@util/dateHandler';
import { materialSwal } from '@util/componentHelper';
import {
  getPagingParamsFromTable,
  getSortParamsFromTable,
} from '@util/tableHelpers';
import Label from '@sharedComponents/Display/Label';
import HeaderCell from '@sharedComponents/Table/TableCells/HeaderCell';
import Table from '@sharedComponents/Table/Table';
import Button from '@sharedComponents/Buttons/Button';
import AssociateAdminWithLockerModal from '../../LockerManagerModals/AssociateAdminWithLockerModal';
import DeleteAdminModal from '../../LockerManagerModals/DeleteAdminModal';
import AdminActionColumn from './AdminsTabContent/AdminActionColumn';
import { claimLocker } from '@APICalls/lockerManager/actions';

const AdminsTable = Table();

class AdminTab extends Component {
  state = {
    sortOrder: '',
    sortByState: '',
    pageNumber: this.props.pageNumber,
    pageSize: this.props.pageSize,
    associateAdminModalIsOpen: false,
    selectedAdmin: null,
    deleteModalIsOpen: false,
  };

  componentDidUpdate(oldProps) {
    const { lockerId } = this.props;

    if (lockerId !== oldProps.lockerId && oldProps.lockerId !== null) {
      this.search();
    }
  }

  openAssociateAdminModal = () => {
    this.setState({ associateAdminModalIsOpen: true });
  };

  closeAssociateAdminModal = () => {
    this.setState({ associateAdminModalIsOpen: false });
  };

  associateAdmins = async (admins) => {
    const {
      dispatch,
      lockerId,
    } = this.props;

    await dispatch(associateAdminWithLocker(lockerId, admins));

    this.search();
  };

  claimLocker = async (claimed) => {
    const {
      dispatch,
      lockerId,
    } = this.props;

    await dispatch(claimLocker(lockerId, claimed));
  };

  confirmDeleteSingleItem = (admin) => {
    this.setState({
      selectedAdmin: admin,
      deleteModalIsOpen: true,
    });
  };

  closeDeleteAdminModal = () => {
    this.setState({
      selectedAdmin: null,
      deleteModalIsOpen: false,
    });
  };

  deleteAdmin = async (admin) => {
    const {
      lockerId,
      dispatch,
    } = this.props;

    const res = await dispatch(removeManagerFromLocker(lockerId, admin));
    if (res?.success) {
      materialSwal('Success', 'Locker manager was removed from this locker.', 'success');
    }
    this.search();
    this.closeDeleteAdminModal();
  };

  setAsLockerOwner = async (admin) => {
    const {
      lockerId,
      dispatch,
    } = this.props;

    await dispatch(updateLockerOwner(lockerId, admin));
    materialSwal('Success', 'Locker manager is successfully set as locker owner.', 'success');
    this.search();
    this.closeDeleteAdminModal();
  };

  fetchData = (state, instance) => {
    const {
      sortByState,
      sortOrder,
    } = this.state;

    const {
      page,
      pageSize,
    } = getPagingParamsFromTable(instance);

    const {
      sortColumn,
      sortDirection,
    } = getSortParamsFromTable(instance, sortDirectionShortEnum, sortByState, sortOrder);

    this.setState({
      pageNumber: page + 1,
      pageSize,
      sortByState: sortColumn,
      sortOrder: sortDirection,
    }, this.search);
  };

  search = () => {
    const {
      dispatch,
      lockerId,
    } = this.props;

    const {
      pageNumber,
      pageSize,
      sortByState,
      sortOrder,
    } = this.state;

    dispatch(fetchLockerAdmins(lockerId,
      pageNumber,
      pageSize,
      sortByState,
      sortOrder,
      dateRangeEnum.all.value,
      '',
      '',
      lockerId));
  };

  getColumns = (organizationDecisionMakerId) => {
    const columns = [
      {
        Header: <HeaderCell text={'ID'} />,
        accessor: 'id',
        minWidth: 20,
        sortable: true,
        Cell: (cellProps) => cellProps.value ? <Link to={adminDetailsUrl(cellProps.value)}>{cellProps.value}</Link> : '',
      },
      {
        Header: <HeaderCell text={'Full Name'} />,
        accessor: 'fullName',
        minWidth: 65,
        sortable: true,
        Cell: (cellProps) => cellProps.value && (
          <div className='lockerManager__manager--owner'>
            <span>{cellProps.value}</span>
            {
              cellProps.original.isLockerOwner &&
              <Label
                text={'Owner'}
                type={'gossamer'}
                classes={'ml-10'}
              />
            }
          </div>
        ),
      },
      {
        Header: 'Email',
        accessor: 'email',
        minWidth: 60,
        Cell: (cellProps) => cellProps.value ? <span>{cellProps.value}</span> : '',
      },
      {
        Header: 'Phone Number',
        accessor: 'phoneNumber',
        minWidth: 35,
        Cell: (cellProps) => cellProps.value ? cellProps.value : '',
      },
      {
        Header: 'Permission',
        accessor: 'id',
        minWidth: 40,
        Cell: (cellProps) => cellProps.value === organizationDecisionMakerId ? 'Decision Maker' : 'Locker Admin',
      },
      {
        Header: 'Role',
        accessor: 'role',
        minWidth: 40,
        Cell: (cellProps) => cellProps.value ? cellProps.value : '',
      },
      {
        Header: <HeaderCell text={'Signup Date'} />,
        accessor: 'createdAt',
        sortable: true,
        minWidth: 40,
        Cell: (cellProps) => cellProps.value ? <div>{parseDateNumeric(cellProps.value)}</div> : '',
      },
      {
        Header: '',
        width: 60,
        resizable: false,
        accessor: '',
        style: { overflow: 'visible' },
        Cell: (cellProps) => (
          <AdminActionColumn
            admin={cellProps.value}
            setAsLockerOwner={this.setAsLockerOwner}
            openDeleteModal={this.confirmDeleteSingleItem}
            refreshAction={this.search}
          />
        ),
      },
    ];

    return columns;
  };

  render() {
    const {
      associateAdminModalIsOpen,
      deleteModalIsOpen,
      selectedAdmin,
    } = this.state;

    const {
      queue,
      totalPages,
      hasNextPage,
      hasPreviousPage,
      organizationDecisionMakerId,
    } = this.props;

    return (
      <div>
        <AssociateAdminWithLockerModal
          isOpen={associateAdminModalIsOpen}
          closeModal={this.closeAssociateAdminModal}
          associateAdmins={this.associateAdmins}
          claimLocker={this.claimLocker}
        />
        <DeleteAdminModal
          admin={selectedAdmin}
          closeModal={this.closeDeleteAdminModal}
          deleteAdmin={this.deleteAdmin}
          isOpen={deleteModalIsOpen}
        />
        <div className='lockerManagerEdit__filters'>
          <div className='lockerManagerEdit__filters-fields' />
          <div className='mb-10'>
            <Button
              type={'primary'}
              text={'Add Admin'}
              onClick={this.openAssociateAdminModal}
              classes={'lockerManagerEdit__button--add'}
            />
          </div>
        </div>
        <div className='sheet'>
          <AdminsTable
            data={queue}
            columns={this.getColumns(organizationDecisionMakerId)}
            showPagination={hasNextPage || hasPreviousPage}
            totalPages={totalPages}
            onFetchData={this.fetchData}
          />
        </div>
      </div>
    );
  }
}

AdminTab.propTypes = {
  totalPages: PropTypes.number.isRequired,
  queue: PropTypes.arrayOf(PropTypes.object).isRequired,
  lockerId: PropTypes.number.isRequired,
  organizationDecisionMakerId: PropTypes.number,
  hasPreviousPage: PropTypes.bool,
  hasNextPage: PropTypes.bool,
  pageNumber: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
};

const mapStateToProps = ({ admins }) => ({
  totalPages: admins.adminsList.totalPages,
  queue: admins.adminsList.items,
  hasPreviousPage: admins.adminsList.hasPreviousPage,
  hasNextPage: admins.adminsList.hasNextPage,
  pageNumber: admins.adminsList.pageNumber,
  pageSize: admins.adminsList.pageSize,
});

export default connect(mapStateToProps)(AdminTab);
