/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Accounts Homefield API
 * OpenAPI spec version: 1.0
 */
import type { BulkCreateJobStatusEnum } from './bulkCreateJobStatusEnum';
import type { IdentityUserDto } from './identityUserDto';
import type { StagingOrganizationDetailsDto } from './stagingOrganizationDetailsDto';

export interface StagingJobDetailsDto {
  id: number;
  sourceFileUrl?: string | null;
  created: string;
  status: BulkCreateJobStatusEnum;
  user?: IdentityUserDto;
  stagingOrganizations: StagingOrganizationDetailsDto[];
}
