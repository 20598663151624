import React from 'react';
import { lockerMgrS3Logos } from '@constants/common';
import ImageActions, { ImageAction } from '@sharedComponents/ImageActions';
import AssignLogoCard from './AssignLogoCard';

export interface ChooseLogosTabProps {
  logos: { id: number; image: string }[];
  selectedLogos: { id: number; image: string }[];
  toggleSelectLogo: (logo: any, isSelected: boolean) => void;
  unselectLogo: (logo: any) => void;
  isLogoSelected: (logo: any) => boolean;
  isSelectingLogosEnabled: boolean;
  removeAllLogos: () => void;
}

const ChooseLogosTab = React.memo(({
  logos,
  selectedLogos,
  toggleSelectLogo,
  unselectLogo,
  isLogoSelected,
  isSelectingLogosEnabled,
  removeAllLogos,
}: ChooseLogosTabProps) => {
  const imageActions: Array<ImageAction> = [
    {
      icon: 'close',
      text: 'Unselect logo',
      action: unselectLogo,
      isVisible: () => true,
    },
  ];

  return (
    <>
      <div>
        <div className='add-product__table--products custom-scrollbar logo-bank__assignment--logos mb-10'>
          {logos.map((logo: any) => (
            <AssignLogoCard
              key={logo.id}
              logo={logo}
              isSelected={isLogoSelected(logo)}
              isDisabled={!isSelectingLogosEnabled && !isLogoSelected(logo)}
              toggleSelectLogo={toggleSelectLogo}
            />
          ))}
        </div>
        <div className='add-product__selected logo-bank__assignment--selected-logos'>
          <div className='add-product__selected--header'>
            <span>
              {`Selected Logos ${selectedLogos.length}`}
            </span>
            <a onClick={removeAllLogos}>
              Remove All
            </a>
          </div>
          <div className='add-product__selected--items custom-scrollbar--horizontal'>
            {selectedLogos.map((logo) => (
              <ImageActions
                key={logo.id}
                item={logo}
                image={`${lockerMgrS3Logos}/${logo.image}`}
                imageActionsData={imageActions}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
});

export default ChooseLogosTab;
