import { useQuery } from 'react-query';
import { catalogApi } from '../../../sqdApis';
import { makeApiCall } from 'util/apiHelper';
import productCatalogUrls from 'constants/sqdApiUrls/productCatalogUrls';

export const getProductBySku = async (sku) => {
  const call = catalogApi.get(productCatalogUrls.product(sku), { handleBlockingLoading: false });
  const res = await makeApiCall(call);

  return res;
};

export const useGetProductBySku = (sku) =>
  useQuery([
    'product-detail',
    sku,
  ], () => {
    const product = getProductBySku(sku);

    return product;
  });
