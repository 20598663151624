import React, {
  useState,
  useCallback,
} from 'react';
import {
  WarehouseOrderItemDto,
  OrderItemStatusEnum,
} from '@api/fulfillment/models';
import ItemInfoModal from '@sharedComponents/ItemInfo/ItemInfoModal';
import Image from '@sharedComponents/Display/Image';
import LabelsLegendDisplay from '@sharedComponents/Labels/LabelsLegendDisplay';
import Tag from '@sharedComponents/Display/Tag';
import { decorationMethodDisplayNames } from '@constants/common';

interface OwnProps {
  item: WarehouseOrderItemDto;
  isSecondary?: boolean;
}

type Props = OwnProps;

export const WarehouseSchedulingDetailsItem = React.memo<Props>(({
  item, isSecondary,
}) => {
  const [
    isModalOpen,
    setIsModalOpen,
  ] = useState(false);
  const openModal = useCallback(() => setIsModalOpen(true), []);
  const closeModal = useCallback(() => setIsModalOpen(false), []);

  let statusLabel: string;
  switch (item.status) {
    case OrderItemStatusEnum.OnHold: {
      statusLabel = 'Awaiting Inventory';
      break;
    }
    case OrderItemStatusEnum.AwaitingDispatchForPicking: {
      statusLabel = 'Awaiting Dispatch';
      break;
    }
    case OrderItemStatusEnum.InPicking: {
      statusLabel = 'In Picking';
      break;
    }
    default: {
      statusLabel = '';
      break;
    }
  }

  return (
    <div
      key={item.id}
      className={!isSecondary ? 'order__item' : 'order__item secondary-item'}
    >
      <ItemInfoModal
        isOpen={isModalOpen}
        closeModal={closeModal}
        sku={item.sku}
      />
      <div className='order__item-column scheduling__details--content__items-image'>
        <Image
          url={item.imageUrl}
          showImageInModal={true}
          classes='image order__item-thumbnail button'
          openInModal={openModal}
        />
      </div>
      <div className='order__item-column'>
        <div className={!isSecondary ? 'order__item--sku-info' : 'order__item--sku-info secondary-item'}>
          <div className='flex'>
            <span>{item.sku}</span>
            <LabelsLegendDisplay
              labels={[
                {
                  isActive: item.requiredItem,
                  text: 'Required',
                  shortText: 'R',
                  type: 'electric-indigo',
                },
                {
                  isActive: !!statusLabel,
                  text: `Item is ${statusLabel}`,
                  shortText: statusLabel,
                  type: 'status-blue',
                },
                {
                  isActive: item.status === OrderItemStatusEnum.OnHold && !!item.isInventoryAvailable,
                  text: 'Inventory Available',
                  shortText: 'Available',
                  type: 'status-orange',
                },
              ]}
            />
          </div>
        </div>
        <div className='order__item--style-name'>{item.styleName ?? ''}</div>
        <ul className='order__item-tags'>
          <li>
            <Tag value={item.size} />
          </li>
          <li>
            <Tag
              label={'Color'}
              value={item.colorGroup}
            />
          </li>
          <li>
            <Tag value={item.brandName} />
          </li>
          <li>
            <Tag value={decorationMethodDisplayNames[item.decorationMethod!]?.short || 'Unknown'} />
          </li>
        </ul>
      </div>
    </div>
  );
});

export default WarehouseSchedulingDetailsItem;
