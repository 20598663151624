import React from 'react';
import { Helmet } from 'react-helmet';
import { voucherOrderDetailsBaseUrl } from '@constants/clientUrls/clientUrls';
interface OwnProps {
  location: string;
}

type Props = OwnProps;

const VoucherOrderDetailsHeader = React.memo<Props>(({ location }) => {
  const voucherOrderId = location.split(`${voucherOrderDetailsBaseUrl}/`)[1];
  const title = `Voucher Order Details - V${voucherOrderId}`;

  return (
    <div className='header__bar-content'>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className='header__bar-title align__center'>
        <span>{title}</span>
      </div>
    </div>
  );
});

export default VoucherOrderDetailsHeader;
