/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Orders Homefield API
 * OpenAPI spec version: v1
 */

export interface OrderItemPricingDetails {
  fundraisingInitialAmount?: number;
  fundraisingBaseAmount?: number;
  fundraisingCouponDiscount?: number;
  readonly fundraisingAmount?: number;
  readonly fundraisingTax?: number;
  readonly fundraisingPricePaid?: number;
  markdown?: number;
  voucherAmount?: number;
  basePrice?: number;
  unitPricePaid?: number;
  pricePaid?: number;
  unitTaxableAmount?: number | null;
  taxableAmount?: number | null;
  unitTaxAmount?: number | null;
  taxAmount?: number | null;
  unitPretaxPrice?: number;
  pretaxPrice?: number;
  refundAmount?: number;
  refundTaxAmount?: number;
  styleDiscountAmount?: number;
  salesChannelDiscountAmount?: number;
  salesChannelDiscountPercent?: number;
  salesChannelFundraisingPercent?: number;
  subtotalCouponDiscount?: number;
  couponDiscountPercent?: number;
  personalizationsPrice?: number;
  personalizationsBasePrice?: number;
  personalizationsCouponDiscount?: number;
}
