import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { cancelVendorOrder } from '@APICalls/vendorOrders/actions';
import { orderDetailsUrl } from '@constants/clientUrls/clientUrls';
import { submitStatusEnum } from '@constants/enums/orderEnums';
import { fetchVendorOrderItems } from '@redux/vendorOrders/actions';
import { parseDateNumeric } from '@util/dateHandler';
import { materialSwal } from '@util/componentHelper';
import { groupBy } from '@util/arrayHelper';
import Link from '@sharedComponents/Navigation/Link';
import TableQuickViewHeader from '@sharedComponents/Table/TableQuickView/TableQuickViewHeader';
import InfoField from '@sharedComponents/Display/InfoField';
import RefundPrice from '@sharedComponents/Display/RefundPrice';
import DescriptionField from '@sharedComponents/Display/DescriptionField';
import Button from '@sharedComponents/Buttons/Button';
import QuickViewOrderItemGroup from '@sharedComponents/QuickView/QuickViewOrderItemGroup';
import Icon from '@sharedComponents/Icons/Icon';
import SimpleConfirmationModal from '@sharedComponents/Modal/SimpleConfirmationModal';
import TableQuickViewContainer from '@sharedComponents/Table/TableQuickView/TableQuickViewContainer';
import VendorOrderSubmitResultsModal from '../VendorOrdersModals/VendorOrderSubmitResultsModal/VendorOrderSubmitResultsModal';
import { postHomefieldApiVendorordersAddpoandcancel } from '@api/fulfillment/vendor-order';

class ScheduledVendorOrdersQuickView extends PureComponent {
  state = {
    isSubmitResultsModalOpen: false,
    confirmationModalIsOpen: false,
  };

  componentDidMount() {
    this.fetchItems();
  }

  componentDidUpdate(prevProps) {
    const { vendorOrder } = this.props;

    if (vendorOrder && prevProps.vendorOrder !== vendorOrder) {
      this.fetchItems();
    }
  }

  openConfirmationModal = () => {
    this.setState(() => ({ confirmationModalIsOpen: true }));
  };

  closeConfirmationModal = () => {
    this.setState(() => ({ confirmationModalIsOpen: false }));
  };

  handleModalConfirm = async () => {
    const {
      vendorOrder,
      refreshData,
    } = this.props;

    const res = await cancelVendorOrder(vendorOrder.id);

    if (res?.success) {
      materialSwal('Success', res.message, 'success');
    }
    refreshData(vendorOrder.id);
    this.closeConfirmationModal();
  };

  fetchItems = () => {
    const {
      vendorOrder,
      dispatch,
    } = this.props;

    dispatch(fetchVendorOrderItems(vendorOrder.id));
  };

  openSubmitResultsModal = () => {
    this.setState(() => ({ isSubmitResultsModalOpen: true }));
  };

  closeSubmitResultsModal = () => {
    this.setState(() => ({ isSubmitResultsModalOpen: false }));
  };

  renderStatusDetails = () => {
    const { vendorOrder } = this.props;

    switch (vendorOrder.submitStatus) {
      case submitStatusEnum.Failed:
        return (
          <div className='flex'>
            <div className='order__failed-text'>Failed</div>
            <Button
              type={'danger'}
              text={'Details'}
              onClick={this.openSubmitResultsModal}
              size={'s'}
              classes={'button--size-s ml-20'}
            />
          </div>
        );
      case submitStatusEnum.NotScheduled:
        return 'Not Scheduled';
      case submitStatusEnum.Pending:
        return 'Pending';
      case submitStatusEnum.Done:
        return 'Done';
      default:
        return '';
    }
  };

  handleAddPoNumber = async (vendorOrderId, orderNumber) => {
    const poNumber = document.querySelector('#po-number-input-vom').value;
    const message = `Are you sure you want to add PO# ${poNumber} to order O${orderNumber}.  After submitting a PO#, it will not be able to be changed.`;
    if (!confirm(message)) {
      return false;
    }

    const input = {
      vendorOrderId,
      poNumber,
    };

    const response = await postHomefieldApiVendorordersAddpoandcancel(input);
    alert(response.message);

    window.location.reload();
  };

  handleAddPoNumberClick = (vendorOrderId, orderNumber) => () => {
    this.handleAddPoNumber(vendorOrderId, orderNumber);
  };

  renderAddPoNumber = (vendorOrderId, orderNumber) => (
    <>
      <input
        type='text'
        style={{
          'border': 'solid 1px #e2e2e2',
          'margin': 'auto 3px',
        }}
        placeholder='PO Number...'
        id='po-number-input-vom'
      />
      <a
        style={{ 'cursor': 'pointer' }}
        onClick={this.handleAddPoNumberClick(vendorOrderId, orderNumber)}
      >
        Add PO
      </a>
    </>
  );

  renderItemsByOrder = (vendorOrder) => {
    const { items } = this.props;

    const itemsByOrder = groupBy(items, 'orderNumber');
    const sortedOrderNumbers = Object.keys(itemsByOrder).sort();

    const itemsGroups = [];
    for (const orderNumber of sortedOrderNumbers) {
      const itemsGroup = itemsByOrder[orderNumber];
      const poNumbers = [...new Set(itemsGroup.filter((i) => !!i.poNumber).map((i) => i.poNumber))];
      const isVfi = itemsGroup.some((i) => i.vendorFulfilledItem === true);

      itemsGroups.push(
        (
          <QuickViewOrderItemGroup
            key={orderNumber}
            title={(
              <div className='align__center'>
                <Link
                  url={orderDetailsUrl(orderNumber)}
                  text={`O${orderNumber}`}
                />
                <div className='expandable-group__header--info ml-10'>
                  (
                  {(isVfi && poNumbers?.length === 0) && this.renderAddPoNumber(vendorOrder.id, orderNumber)}
                  {poNumbers?.length > 0
                    && (poNumbers.map((poNumber, index) => (
                      <span
                        key={poNumber}
                        className='highlight'
                      >
                        {(index >= 1) ? `, PO#${poNumber}` : `PO#${poNumber}`}
                      </span>
                    )))}
                  )
                </div>
              </div>
            )}
            items={itemsGroup}
          />
        ),
      );
    }

    return itemsGroups;
  };

  render() {
    const {
      isSubmitResultsModalOpen,
      confirmationModalIsOpen,
    } = this.state;

    const {
      vendorOrder,
      items,
      closeDetails,
      vendors,
    } = this.props;

    const vendor = vendors.find((x) => x.code === vendorOrder.vendorId);
    const totalPricePaid = items.reduce((total, current) => total + current.pricePaid, 0);

    return (
      <>
        <SimpleConfirmationModal
          isOpen={confirmationModalIsOpen}
          confirm={this.handleModalConfirm}
          closeModal={this.closeConfirmationModal}
          title={'Cancel Vendor Order'}
          confirmationBody={`Are you sure you want to cancel vendor order with ID ${vendorOrder.id}?`}
        />
        <TableQuickViewContainer
          noPadding={true}
        >
          {isSubmitResultsModalOpen
            && (
              <VendorOrderSubmitResultsModal
                vendorOrder={vendorOrder}
                vendor={vendor}
                isOpen={isSubmitResultsModalOpen}
                closeModal={this.closeSubmitResultsModal}
              />
            )}

          <TableQuickViewHeader
            title={(
              <div className='justify__space-between'>
                <span>{vendor?.name}</span>
                {!vendorOrder.canceled
                  && (vendorOrder.submitStatus === submitStatusEnum.Failed
                    || vendorOrder.submitStatus === submitStatusEnum.NotScheduled
                    || vendorOrder.submitStatus === submitStatusEnum.Pending)
                  && (
                    <button
                      onClick={this.openConfirmationModal}
                      className='btn-borderless btn-borderless--red has-icon size--s ml-20'
                    >
                      <Icon materialIcon={'block'} />
                      <span>Cancel Order</span>
                    </button>
                  )}
              </div>
            )}
            onClose={closeDetails}
          />
          <div className='tableView__details--content'>
            <div className='tableView__details--content-container no-border pt-0'>
              <div className='flex'>
                <InfoField
                  label={'Vendor Order Status'}
                  value={this.renderStatusDetails()}
                  fieldClass={'order__shipping__column--short'}
                />
                <InfoField
                  label={'Submission Date'}
                  value={parseDateNumeric(vendorOrder.created)}
                  fieldClass={'order__shipping__column--long'}
                />
              </div>
              <div className='flex'>
                <InfoField
                  label={'Vendor Order ID'}
                  value={vendorOrder.vendorOrderId}
                  fieldClass={'order__shipping__column--short'}
                />
                <InfoField
                  label={'Date Shipped'}
                  value={vendorOrder.shipDate && parseDateNumeric(vendorOrder.shipDate)}
                  fieldClass={'order__shipping__column--long'}
                />
              </div>
            </div>
            <div className='order__items backorder-management__sidebar__tab-content--items custom-scrollbar'>
              {this.renderItemsByOrder(vendorOrder)}
            </div>
            <div className='orderManagement__details-item-details'>
              <DescriptionField
                label={'Total Items'}
                value={vendorOrder.itemsCount}
              />
              <DescriptionField
                label={'Total Orders'}
                value={vendorOrder.ordersCount}
              />
              <DescriptionField
                label={'Vendor Order Total'}
                value={<RefundPrice paid={totalPricePaid} />}
              />
            </div>
          </div>
        </TableQuickViewContainer>
      </>
    );
  }
}

ScheduledVendorOrdersQuickView.propTypes = {
  vendorOrder: PropTypes.shape({
    id: PropTypes.number.isRequired,
    vendorOrderId: PropTypes.string,
    vendorId: PropTypes.string.isRequired,
    created: PropTypes.string.isRequired,
    updated: PropTypes.string.isRequired,
    canceled: PropTypes.string,
    status: PropTypes.string,
    submitStatus: PropTypes.string,
    itemsCount: PropTypes.number.isRequired,
    ordersCount: PropTypes.number.isRequired,
    shipDate: PropTypes.string,
  }),
  items: PropTypes.arrayOf(PropTypes.shape({
    ids: PropTypes.arrayOf(PropTypes.number).isRequired,
    sku: PropTypes.string.isRequired,
    imageUrl: PropTypes.string,
    styleName: PropTypes.string,
    quantity: PropTypes.number,
    size: PropTypes.string,
    floodColor: PropTypes.string,
    pricePaid: PropTypes.number,
    refundAmount: PropTypes.number,
    shipDate: PropTypes.string,
    promisedDate: PropTypes.string,
  })).isRequired,
  closeDetails: PropTypes.func.isRequired,
  vendors: PropTypes.arrayOf(PropTypes.object),
  refreshData: PropTypes.func.isRequired,
};

const mapStateToProps = ({
  vendorOrders,
  productCatalog,
}) => ({
  items: vendorOrders.vendorOrderItems,
  vendors: productCatalog.vendors,
});

export default connect(mapStateToProps)(ScheduledVendorOrdersQuickView);
