/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */
import type { LogoItemDetailsModel } from './logoItemDetailsModel';
import type { PersonalizationItemModel } from './personalizationItemModel';
import type { OrderItemStatusEnumStatusModel } from './orderItemStatusEnumStatusModel';
import type { OrderItemBackorderNotificationDto } from './orderItemBackorderNotificationDto';

export interface BackorderedItemDto {
  ids?: number[] | null;
  barcodes?: string[] | null;
  storefrontId?: number | null;
  coreId?: number | null;
  gpLineItemSequence?: number | null;
  gpComponentSequence?: number | null;
  sku?: string | null;
  quantity?: number;
  basePrice?: number;
  styleDiscountAmount?: number | null;
  lockerDiscountAmount?: number | null;
  subtotalCouponDiscount?: number | null;
  fundraisingBaseAmount?: number | null;
  fundraisingCouponDiscount?: number | null;
  fundraisingAmount?: number | null;
  personalizationBasePrice?: number | null;
  personalizationCouponDiscount?: number | null;
  personalizationPrice?: number | null;
  refundAmount?: number | null;
  markdown?: number | null;
  pretaxPrice?: number | null;
  taxableAmount?: number | null;
  taxAmount?: number | null;
  pricePaid?: number;
  fundraisingPercent?: number | null;
  couponDiscountPercent?: number | null;
  lockerDiscountPercent?: number | null;
  imageUrlFront?: string | null;
  imageUrlLeft?: string | null;
  imageUrlRight?: string | null;
  imageUrlBack?: string | null;
  shipDate?: string | null;
  expressProductionAvailable?: boolean;
  preferredVendor?: boolean;
  vendorFulfilledItem?: boolean;
  vendorDesignId?: string | null;
  vendorId?: string | null;
  vendorOrderId?: number | null;
  dropshipVFI?: boolean;
  rosterTeamMemberId?: number | null;
  collectionId?: number | null;
  voucherId?: number | null;
  voucherCode?: string | null;
  voucherAmount?: number | null;
  lockerItemId?: number | null;
  custom?: boolean;
  allowDiscounts?: boolean | null;
  requiredItem?: boolean;
  logos?: LogoItemDetailsModel[] | null;
  personalizations?: PersonalizationItemModel[] | null;
  lockerId?: number | null;
  readonly lockerNumber?: number | null;
  lockerTeamName?: string | null;
  lck?: string | null;
  inventoryAvailable?: boolean | null;
  shipmentNumber?: number | null;
  promisedDate?: string | null;
  poNumber?: string | null;
  backorderNotificationId?: number | null;
  canceled?: string | null;
  replaced?: string | null;
  refunded?: string | null;
  isCompleteRefund?: boolean | null;
  barcode?: string | null;
  pdfPrintFileUrl?: string | null;
  status?: OrderItemStatusEnumStatusModel;
  styleName?: string | null;
  size?: string | null;
  colorGroup?: string | null;
  imageUrl?: string | null;
  styleDecorationMethod?: string | null;
  notification?: OrderItemBackorderNotificationDto;
}
