import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FieldArray, reduxForm } from 'redux-form';
import { scanOrderItemsBarcodesForm } from '@constants/reduxForms';
import ScanItemBarcodeInputs from './ScanItemBarcodeInputs';
import { validateOrderItemsScanBarcodesForm } from '@redux/productionAssembly/validations';

const ProductionAssemblyScanBarcodesForm = React.memo(({
  initialize,
  itemBarcodes,
  handleSubmit,
}) => {
  useEffect(() => {
    initialize({
      itemBarcodes: itemBarcodes.map((itemBarcode) => ({
        orderItemId: itemBarcode.orderItemId,
        barcode: itemBarcode.barcode,
      })),
    });
  }, [initialize, itemBarcodes]);

  return (
    <form
      className='redux-form'
      onSubmit={handleSubmit}
      id={scanOrderItemsBarcodesForm}
    >
      <div>
        <div className='ml-15 mr-10'>
          <div>
            <div className='redux-form__row'>
              <div className='redux-form__column--size-m'>
                <label className='redux-form__label'>
                  Item Barcodes
                  <span className='required'>*</span>
                </label>
                <FieldArray
                  name={'itemBarcodes'}
                  placeholder={'Scan or Enter an Item Barcode'}
                  component={ScanItemBarcodeInputs}
                  rerenderOnEveryChange={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
});

ProductionAssemblyScanBarcodesForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  initialize: PropTypes.func.isRequired,
  itemBarcodes: PropTypes.arrayOf(PropTypes.shape({
    orderItemId: PropTypes.number.isRequired,
    barcode: PropTypes.string,
  })).isRequired,
};

export default (reduxForm({
  form: scanOrderItemsBarcodesForm,
  validate: validateOrderItemsScanBarcodesForm,
}))(ProductionAssemblyScanBarcodesForm);
