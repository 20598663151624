/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Orders Homefield API
 * OpenAPI spec version: v1
 */
import type { AccountingActionTypeEnum } from './accountingActionTypeEnum';

export interface RefundOrderItemCommand {
  orderId?: number;
  amount?: number;
  reason?: string | null;
  actionType?: AccountingActionTypeEnum;
  orderItemId?: number;
}
