/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Financials Homefield API
 * OpenAPI spec version: v1
 */

export interface SavePayableTransactionCommand {
  documentNumber?: string | null;
  checkNumber?: string | null;
  checkDate?: string | null;
  checkAmount?: number | null;
  isVoided?: boolean;
}
