import { materialSwal } from '../componentHelper';

export const onLockerEditSuccess = (response) => {
  materialSwal('Success', response.message, 'success');
};

export const onLockerEditError = (error) => {
  let errorMessage = '';
  if (error.result && Array.isArray(error.result)) {
    for (const err of error.result) {
      errorMessage += err;
    }
  }

  materialSwal('Error', errorMessage || error.message || 'Something went wrong', 'error');
};
