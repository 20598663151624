/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Orders Homefield API
 * OpenAPI spec version: v1
 */
import type { QueriesItemPersonalizations } from './queriesItemPersonalizations';

export interface QueriesItem {
  variationId?: number;
  sku?: string | null;
  quantity?: number;
  personalizations?: QueriesItemPersonalizations;
}
