import { GetHomefieldApiWarehouseschedulingOrdergroupsListParams } from '@api/fulfillment/models';
import { storefront } from '@constants/common';

export const adminPerspectiveUrl = (lockerId: number | string): string => `${storefront}/#/lockers/${lockerId}/edit`;

export const autoLoginUrl = '/auto-login' as const;
export const loginUrl = '/login' as const;
export const callbackUrl = '/callback' as const;

/* Advanced Options */
export const advancedOptionsUrl = '/advanced-options' as const;

export const applicationSettingsUrl = '/application-settings' as const;
export const serviceManagerUrl = '/service-manager' as const;

/* Admins */
export const adminsUrl = '/admins' as const;
export const adminDetailsBaseUrl = '/admin-details' as const;
export const adminDetailsUrl = (adminId: number | string): string => `${adminDetailsBaseUrl}/${adminId}`;

/* Backorder Management */
export const backorderManagementUrl = '/backorder-management' as const;

/* Bulk Actions */
export const bulkActionsUrl = '/bulk-actions' as const;
export const bulkAddItemUrl = '/bulk-add-item' as const;
export const bulkAddStyleUrl = '/bulk-add-style' as const;
export const bulkBuildUrl = '/bulk-store-build' as const;
export const bulkBuildJobDetailsUrl = (jobId: number | string) => (`/bulk-store-build/${jobId}`);

/* Collections */
export const collectionDetailsBaseUrl = '/collection-details' as const;
export const collectionDetailsUrl = (collectionId: number | string): string => `${collectionDetailsBaseUrl}/${collectionId}`;
export const reorderCollectionItemsPath = '/reorder-items' as const;
export const reorderCollectionItemsUrl = (collectionId: number | string): string => `${collectionDetailsUrl(collectionId)}${reorderCollectionItemsPath}`;

/* Coupons */
export const couponsUrl = '/coupons' as const;

/* Design Configuration */
export const designConfigurationUrl = '/design-configuration' as const;

/* Fundraising Payouts */
export const fundraisingPayoutsUrl = '/fundraising-payouts' as const;

/* Logo Review */
export const logoReviewUrl = '/logo-review' as const;
export const logoReviewTasksUrl = '/logo-review-tasks' as const;

/* Locker Manager */
export const lockerManagerUrl = '/locker-manager' as const;
export const logoBankUrl = `${lockerManagerUrl}/logo-bank`;
export const lockerDetailsBaseUrl = '/locker-manager-edit' as const;
export const lockerDetailsUrl = (lockerId: number | string): string => `${lockerDetailsBaseUrl}/${lockerId}`;
export const lockerDetailBulkUploadImage = (lockerId: number | string): string => `${lockerDetailsBaseUrl}/${lockerId}/bulk-upload-logos`;
export const lockerLogoBankUrl = (lockerId: number | string): string => `${logoBankUrl}/${lockerId}`;
export const lockerManagerAddProductsPageUrl = (lockerId: number | string): string => `${lockerDetailsBaseUrl}/${lockerId}/add-products`;

/* Lookup Entities */
export const lookupEntitiesUrl = '/lookup-entities' as const;

export const brandsUrl = '/brands' as const;
export const categoriesUrl = '/categories' as const;
export const parentCategoriesUrl = '/parent-categories' as const;
export const decorationLocationsUrl = '/decoration-locations' as const;
export const decorationLocationArtworkSizesUrl = '/decoration-location-artwork-sizes' as const;
export const colorsUrl = '/colors' as const;
export const sportsUrl = '/sports' as const;
export const vendorsUrl = '/vendors' as const;
export const layoutsUrl = '/location-layouts' as const;
export const qualityAssuranceCodesUrl = '/quality-assurance-codes' as const;

/* Merchandising */
export const merchandisingUrl = '/merchandising' as const;
export const merchandisingEffortDetailsUrl = (effortId: number | string): string => `${merchandisingUrl}/${effortId}`;

/* On-Hand Inventory */
export const onHandInventoryUrl = '/on-hand-inventory' as const;

/* Order Management */
export const orderDetailsBaseUrl = '/order-management' as const;
// Export const orderDetailsNewOrder = `${orderDetailsBaseUrl}/new-order`;
export const orderDetailsNewOrder = '/new-order' as const;
export const orderDetailsNewOrderAddItems = '/add-items' as const;
export const orderDetailsUrl = (orderNumber: number | string): string => `${orderDetailsBaseUrl}/${orderNumber}`;

export const orderSearchUrl = '/order-search' as const;
export const orderSearchLockerUrl = (lockerId: number | string): string => `${orderSearchUrl}/L${lockerId}`;
export const orderSearchOrganizationUrl = (organizationId: string, lockerIds: string): string => `${orderSearchUrl}/ORG${organizationId}/${lockerIds}`;
export const orderManagementWithFilterUrl = (filter: string, lockerIds: string): string => `${orderSearchUrl}/${filter}/${lockerIds}`;

/* Organizations */
export const organizationsUrl = '/organizations' as const;
export const organizationDetailsBaseUrl = '/organization-details' as const;
export const organizationDetailsUrl = (organizationId: number | string): string => `${organizationDetailsBaseUrl}/${organizationId}`;
export const organizationGroupsBaseUrl = '/organization-groups' as const;
export const organizationGroupsUrl = (organizationId: number | string): string => `${organizationGroupsBaseUrl}/${organizationId}`;

/* Print Room */
export const printFilesUrl = '/print-files' as const;
export const printSheetBaseUrl = '/print-sheet' as const;
export const printSheetUrl = (sheetId: number | string): string => `${printSheetBaseUrl}/${sheetId}`;
export const printAssemblyUrl = '/print-assembly' as const;
export const printAssemblyOrderUrl = (sheetId: number | string, orderNumber: number | string): string => `${printAssemblyUrl}/${sheetId}/order/${orderNumber}`;
export const printSheetLabelsUrl = '/print-sheet-labels' as const;

/* Print Room New */
export const printRoomUrl = '/print-room' as const;
export const printRoomOrderBaseUrl = `${printRoomUrl}/order` as const;
export const printRoomOrderUrl = (orderNumber: number | string): string => `${printRoomOrderBaseUrl}/${orderNumber}`;
export const printRoomOrderAssemblyUrl = (orderNumber: number | string): string => `${printRoomUrl}/order/${orderNumber}/assembly`;

/* Production Assembly */

export const productionUrl = '/production' as const;

export const productionAssemblyHagUrl = `${productionUrl}/hag` as const;
export const productionAssemblyDtgUrl = `${productionUrl}/dtg` as const;
export const productionAssemblyEmbUrl = `${productionUrl}/emb` as const;
export const productionAssemblyPtlWallsUrl = `${productionUrl}/ptl/walls}` as const;
export const productionAssemblyHagReviewByOrderUrl = (orderId: number | string): string => `${productionAssemblyHagUrl}/${orderId}`;
export const productionAssemblyDtgReviewByOrderUrl = (orderId: number | string): string => `${productionAssemblyDtgUrl}/${orderId}`;
export const productionAssemblyEmbReviewByOrderUrl = (orderId: number | string): string => `${productionAssemblyEmbUrl}/${orderId}`;
export const productionAssemblyHagReviewByBarcodeUrl = (orderId: number | string, barcode: string): string => `${productionAssemblyHagUrl}/${orderId}/${barcode}`;
export const productionAssemblyDtgReviewByBarcodeUrl = (orderId: number | string, barcode: string): string => `${productionAssemblyDtgUrl}/${orderId}/${barcode}`;
export const productionAssemblyEmbReviewByBarcodeUrl = (orderId: number | string, barcode: string): string => `${productionAssemblyEmbUrl}/${orderId}/${barcode}`;

export const orderAssemblyUrl = `${productionUrl}/order-assembly` as const;
export const orderAssemblyReviewByOrderUrl = (orderId: number | string): string => `${orderAssemblyUrl}/${orderId}`;
export const orderAssemblyReviewByBarcodeUrl = (orderId: number | string, barcode: string): string => `${orderAssemblyUrl}/${orderId}/${barcode}`;

export const qualityCheckUrl = `${productionUrl}/quality-check` as const;
export const qualityCheckReviewByOrderUrl = (orderId: number | string): string => `${qualityCheckUrl}/${orderId}`;
export const qualityCheckReviewByBarcodeUrl = (orderId: number | string, barcode: string): string => `${qualityCheckUrl}/${orderId}/${barcode}`;

export const productionAssemblyUrl = '/production-assembly' as const;
export const productionAssemblyDetailsUrl = (orderId: number | string): string => `${productionAssemblyUrl}/${orderId}`;
export const productionAssemblyDetailsBarcodeUrl = (orderId: number | string, barcode: string): string => `${productionAssemblyUrl}/${orderId}/${barcode}`;

export const productionOverviewUrl = `${productionUrl}/production-overview` as const;
export const productionOverviewReviewByOrderUrl = (orderId: number | string): string => `${productionOverviewUrl}/${orderId}`;
export const productionOverviewReviewByBarcodeUrl = (orderId: number | string, barcode: string): string => `${productionOverviewUrl}/${orderId}/${barcode}`;

/* Production Artwork */
export const artworkTasksUrl = '/artwork-file-tasks' as const;
export const lockerArtworkTasksUrl = (lockerId: number | string): string => `${artworkTasksUrl}/${lockerId}`;
export const artVectorizationUrl = '/art-vectorization' as const;
export const artVectorizationTabUrl = (status: string): string => `${artVectorizationUrl}/${status}`;
export const artVectorizationUnassignedUrl = `${artVectorizationUrl}/unassigned/`;
export const artworkTasksWithStatusUrl = (lockerId: number | string, status: string): string => `${artworkTasksUrl}/${lockerId}/${status}`;

/* Product Catalog */
export const productCatalogUrl = '/product-catalog' as const;
export const styleUrl = (styleId: number | string): string => `${productCatalogUrl}/${styleId}`;
export const syncStyleUrl = (styleId: number | string): string => `${styleUrl(styleId)}/sync`;

/* Roster Manager */
export const rosterManagerUrl = '/roster-manager' as const;
export const rosterDetailsBaseUrl = '/roster-details' as const;
export const rosterDetailsUrl = (rosterId: number | string): string => `${rosterDetailsBaseUrl}/${rosterId}`;

/* Scheduling */
export const llSchedulingUrl = '/ll-scheduling' as const;
export const llSchedulingDetailsUrl = '/ll-scheduling-details' as const;
export const llSchedulingQueueDetailsUrl = (queueId: number | string): string => `${llSchedulingDetailsUrl}/${queueId}`;
export const warehouseSchedulingUrl = '/warehouse-scheduling' as const;
export const batchWarehouseSchedulingUrl = '/batch-warehouse-scheduling' as const;
export const batchWarehouseSchedulingListUrl = `${batchWarehouseSchedulingUrl}/list` as const;
type BatchApiParams = GetHomefieldApiWarehouseschedulingOrdergroupsListParams;
export type BatchWarehouseSchedulingListUrlWithParamsArgsType = {
  [Key in keyof Omit<BatchApiParams, 'pageNumber' | 'pageSize' | 'sortBy' | 'sortDirection'>]?: BatchApiParams[Key];
};
export const batchWarehouseSchedulingListUrlWithParams = (params: BatchWarehouseSchedulingListUrlWithParamsArgsType) => `${batchWarehouseSchedulingListUrl}?${new URLSearchParams(params).toString()}`;

/* SKU Setup */
export const skuSetupUrl = '/sku-setup' as const;

/* Vendor Orders */
export const vendorOrdersUrl = '/vendor-orders' as const;

/* Voucher Orders */
export const voucherOrdersUrl = '/voucher-orders' as const;
export const voucherOrderDetailsBaseUrl = '/voucher-order-details' as const;
export const voucherOrderDetailsUrl = (voucherOrderId: number | string): string => `${voucherOrderDetailsBaseUrl}/${voucherOrderId}`;

export const addVoucherOrderUrl = '/add-voucher-order';

/* DTG */
export const dtgEntitiesUrl = '/dtg' as const;
export const dtgEnvironmentsUrl = '/dtg-environments' as const;
export const dtgArtworkSettingsUrl = '/dtg-artwork-settings' as const;
export const dtgPersonalizationSettingsUrl = '/dtg-personalization-settings' as const;
export const dtgStationsUrl = '/dtg-stations' as const;
export const dtgTabletProfilesUrl = '/dtg-tablet-profiles' as const;

/* Devices */
export const devicesUrl = '/devices' as const;

/* Walls */
export const wallsUrl = '/walls' as const;

/* NetSuite */
export const netSuiteSalesOrderUrl = (baseUrl: string, salesOrderId: number) => `${baseUrl}/app/accounting/transactions/salesord.nl?id=${salesOrderId}`;

export const netSuiteVendorBillUrl = (baseUrl: string, erpPayoutId: number) => `${baseUrl}/app/accounting/transactions/vendbill.nl?id=${erpPayoutId}`;

export const netSuiteInvoiceUrl = (baseUrl: string, erpId: number) => `${baseUrl}/app/accounting/transactions/custinvc.nl?id=${erpId}`;

export const reportsUrl = '/reports' as const;
export const reportSelectionUrl = (storeName: string): string => `${reportsUrl}/${storeName}`;
export const reportQueryUrl = (storeName: string, reportName: string): string => `${reportSelectionUrl(storeName)}/${reportName}`;

/* Order Item Files */
export const orderItemFilesUrl = '/order-item-files' as const;

/* Cross Sells */
export const crossSellsUrl = '/cross-sells' as const;

export default {
  autoLoginUrl,
  loginUrl,
  logoReviewUrl,
  orderSearchLockerUrl,
  orderSearchOrganizationUrl,
  lockerDetailsUrl,
  organizationsUrl,
  organizationDetailsUrl,
  printFilesUrl,
  printSheetUrl,
  printSheetLabelsUrl,
  printAssemblyOrderUrl,
  productionAssemblyUrl,
  productionAssemblyDetailsUrl,
  productionAssemblyDetailsBarcodeUrl,
  artVectorizationUnassignedUrl,
  llSchedulingDetailsUrl,
  llSchedulingQueueDetailsUrl,
  productCatalogUrl,
  rosterManagerUrl,
  rosterDetailsUrl,
  styleUrl,
  syncStyleUrl,
  vendorOrdersUrl,
  voucherOrdersUrl,
  dtgEnvironmentsUrl,
  dtgArtworkSettingsUrl,
  dtgPersonalizationSettingsUrl,
  dtgStationsUrl,
  devicesUrl,
  adminsUrl,
  adminDetailsBaseUrl,
  advancedOptionsUrl,
  applicationSettingsUrl,
  serviceManagerUrl,
  artVectorizationUrl,
  artworkTasksUrl,
  backorderManagementUrl,
  collectionDetailsBaseUrl,
  reorderCollectionItemsPath,
  couponsUrl,
  fundraisingPayoutsUrl,
  llSchedulingUrl,
  lockerManagerUrl,
  logoBankUrl,
  lockerDetailsBaseUrl,
  lookupEntitiesUrl,
  brandsUrl,
  categoriesUrl,
  decorationLocationsUrl,
  decorationLocationArtworkSizesUrl,
  colorsUrl,
  sportsUrl,
  onHandInventoryUrl,
  orderDetailsBaseUrl,
  orderSearchUrl,
  organizationDetailsBaseUrl,
  organizationGroupsBaseUrl,
  parentCategoriesUrl,
  printAssemblyUrl,
  printSheetBaseUrl,
  rosterDetailsBaseUrl,
  skuSetupUrl,
  layoutsUrl,
  vendorsUrl,
  dtgEntitiesUrl,
  wallsUrl,
  printRoomUrl,
  printRoomOrderBaseUrl,
  printRoomOrderUrl,
  printRoomOrderAssemblyUrl,
  netSuiteSalesOrderUrl,
  reportsUrl,
  reportSelectionUrl,
  reportQueryUrl,
  orderItemFilesUrl,
};
