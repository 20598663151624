import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Link,
  withRouter,
} from 'react-router-dom';
import { markDoneByBarcode } from '@APICalls/weedingAndMasking/actions';
import { putHomefieldApiPrintroomPrintassemblyqueuestatusprintqueueid } from 'api/fulfillment/print-room';
import { printStatusEnum } from '@constants/enums/printRoomEnums';
import {
  sortDirectionEnum,
  keyNameEnum,
} from '@constants/enums/commonEnums';
import {
  printSheetUrl,
  printAssemblyOrderUrl,
} from '@constants/clientUrls/clientUrls';
import { getColors } from '@redux/productCatalog/actions';
import {
  fetchQueue,
  updateSheetAndOrder,
  getTabCounts,
  fetchOrdersList,
} from '@redux/weedingAndMaskingRoom/actions';
import { extractParameterFromPath } from '@util/stringHelpers';
import { navigateToPage } from '@util/componentHelper';
import {
  getPagingParamsFromTable,
  getSortParamsFromTable,
} from '@util/tableHelpers';
import LogoPreviewModal from '@sharedComponents/Modal/LogoPreviewModal';
import DoneTable from './tables/DoneTable';
import ToBeWeededAndMaskedTable from './tables/ToBeWeededAndMaskedTable';
import FinalizeModal from './FinalizeModal';

class WeedingAndMasking extends Component {
  state = {
    toBeWeededAndMaskedIsActive: true,
    doneIsActive: false,
    data: [],
    pages: null,
    loading: true,
    status: 'Initial',
    pageNumber: 1,
    pageSize: 10,
    orderFilter: '',
    modalIsOpen: false,
    doneIfEmpty: false,
    logoImagePreview: {
      isOpen: false,
      imageUrl: null,
    },
  };

  componentDidMount() {
    const { dispatch } = this.props;
    const orderNumber = extractParameterFromPath(this.props, 'orderNumber', 'number');

    dispatch(getColors());

    this.refreshData(orderNumber, true)
      .then((data) => {
        /*
         * By default, status is toBeWeededAndMasked, so we can just check to see
         * if the queue is full
         */
        if (data) {
          this._renderDoneIfWeededIsEmpty(data.items);
        }
      });
  }

  componentDidUpdate(prevProps) {
    const orderNumber = extractParameterFromPath(this.props, 'orderNumber', 'number');
    const oldOrderNumber = extractParameterFromPath(prevProps, 'orderNumber', 'number');

    if (orderNumber !== oldOrderNumber) {
      this.setState({
        toBeWeededAndMaskedIsActive: true,
        doneIsActive: false,
        data: [],
        pages: null,
        loading: true,
        status: 'Initial',
        pageNumber: 1,
        pageSize: 10,
        orderFilter: '',
        modalIsOpen: false,
        doneIfEmpty: false,
        logoImagePreview: {
          isOpen: false,
          imageUrl: null,
        },
      }, () => {
        this.refreshData(orderNumber, false)
          .then((data) => {
            /*
             * By default, status is toBeWeededAndMasked, so we can just check to see
             * if the queue is full
             */
            if (data) {
              this._renderDoneIfWeededIsEmpty(data.items);
            }
          });
      });
    }
  }

  _renderDoneIfWeededIsEmpty = (items) => {
    if (items.length === 0) {
      this.doneActive();
      if (!this.isCurrentOrderFinalized()) {
        this.openModal();
      }
    } else {
      this.toBeWeededAndMaskedActive();
    }
  };

  refreshData = async (order, doneIfEmpty, markingDone) => {
    const {
      status,
      pageNumber,
      pageSize,
    } = this.state;

    const { dispatch } = this.props;

    const orderNumber = extractParameterFromPath(this.props, 'orderNumber', 'number');
    const sheetId = extractParameterFromPath(this.props, 'sheetId', 'number');

    await dispatch(getTabCounts(sheetId, orderNumber));

    const orderId = order ? order : orderNumber;
    dispatch(updateSheetAndOrder(sheetId, orderId));

    const data = await dispatch(fetchQueue(sheetId, status, pageNumber, pageSize, orderId));
    if (doneIfEmpty) {
      if (data) {
        if (markingDone && data.items && data.items.length === 0) {
          this.setState({
            modalIsOpen: true,
          });
        }
        this._renderDoneIfWeededIsEmpty(data.items);
      }
    } else {
      return data;
    }
  };

  filter = () => {
    const search = this.search.value;
    this.setState({
      orderFilter: search,
    }, this.refreshData);
  };

  barcodeKey = (e) => {
    if (e.key && e.key !== keyNameEnum.Enter) {
      return;
    }
    e.preventDefault();
    e.stopPropagation();

    this.markAsDoneByBarcode();
  };

  markAsDoneByBarcode = async () => {
    if (this.barcode?.value) {
      const res = await markDoneByBarcode(this.barcode.value);
      if (res?.success) {
        this.refreshData(null, true);
      }
    }
  };

  filterKey = (e) => {
    if (e.key && e.key !== keyNameEnum.Enter) {
      return;
    }
    e.preventDefault();
    e.stopPropagation();

    this.filter();
  };

  openModal = () => {
    this.setState({
      modalIsOpen: true,
    });
  };

  closeModal = () => {
    this.setState({
      modalIsOpen: false,
    });
  };

  openLogoPreviewModal = (imageUrl) => {
    this.setState({
      logoImagePreview: {
        isOpen: true,
        imageUrl,
      },
    });
  };

  closeLogoPreviewModal = () => {
    this.setState({
      logoImagePreview: {
        isOpen: false,
        imageUrl: null,
      },
    });
  };

  toBeWeededAndMaskedActive = () => {
    this.setState({
      toBeWeededAndMaskedIsActive: true,
      doneIsActive: false,
      status: printStatusEnum.Initial,
    }, this.refreshData);
  };

  doneActive = () => {
    this.setState({
      toBeWeededAndMaskedIsActive: false,
      doneIsActive: true,
      status: printStatusEnum.Done,
    }, this.refreshData);
  };

  handlePagination = (pageNumber) => {
    this.setState({
      pageNumber,
    }, this.refreshData);
  };

  handlePageSizeChange = (pageSize) => {
    this.setState({
      pageSize,
    }, this.refreshData);
  };

  fetchData = async (state, instance) => {
    this.setState({ loading: true });

    const { dispatch } = this.props;
    const { status } = this.state;

    const orderNumber = extractParameterFromPath(this.props, 'orderNumber', 'number');
    const sheetId = extractParameterFromPath(this.props, 'sheetId', 'number');

    const {
      page,
      pageSize,
    } = getPagingParamsFromTable(instance);

    const {
      sortColumn,
      sortDirection,
    } = getSortParamsFromTable(instance, sortDirectionEnum);

    await dispatch(fetchQueue(sheetId, status, page + 1, pageSize, orderNumber, sortColumn, sortDirection));

    this.setState({
      loading: false,
      pageNumber: page + 1,
      pageSize,
    });
  };

  afterFinalize = async () => {
    const orderBlock = this.getNextAndCurrentOrder();
    const { dispatch } = this.props;

    const sheetId = extractParameterFromPath(this.props, 'sheetId', 'number');

    await dispatch(fetchOrdersList(sheetId));

    if (orderBlock.nextOrderNumber) {
      navigateToPage(printAssemblyOrderUrl(sheetId, orderBlock.nextOrderNumber));
    } else {
      navigateToPage(printSheetUrl(sheetId));
    }
  };

  getNextAndCurrentOrder = () => {
    const orderNumber = extractParameterFromPath(this.props, 'orderNumber', 'number');

    const { orders } = this.props;
    const index = orders.findIndex((order) => Number(orderNumber) === order.orderNumber);
    const orderCount = orders.length;

    for (let i = 1; i < orderCount; i++) {
      const testOrder = orders[(index + i) % orderCount];
      if (testOrder?.status?.status !== printStatusEnum.Done) {
        return {
          currentOrder: orders[index],
          nextOrderNumber: testOrder.orderNumber,
        };
      }
    }

    return {
      currentOrder: orders[index],
    };
  };

  isCurrentOrderFinalized = () => {
    const { orders } = this.props;
    const orderNumber = extractParameterFromPath(this.props, 'orderNumber', 'number');

    const index = orders.findIndex((order) => Number(orderNumber) === order.orderNumber);
    const currentOrder = orders[index];

    return !!currentOrder && currentOrder.status && currentOrder.status.status === printStatusEnum.Done;
  };

  markAllAsDone = async () => {
    const sheetId = extractParameterFromPath(this.props, 'sheetId', 'number');
    const orderNumber = extractParameterFromPath(this.props, 'orderNumber', 'number');
    const res = await putHomefieldApiPrintroomPrintassemblyqueuestatusprintqueueid({
      printQueueId: sheetId,
      orderNumber,
      status: printStatusEnum.Done,
    });
    if (res?.success) {
      this.refreshData(null, true);
    }
  };

  setBarcodeRef(b) {
    this.barcode = b;
  }

  render() {
    const {
      toBeWeededAndMaskedIsActive,
      doneIsActive,
      loading,
      modalIsOpen,
      logoImagePreview,
    } = this.state;

    const {
      items,
      totalPages,
      counts,
      hasPreviousPage,
      hasNextPage,
    } = this.props;

    const orderNumber = extractParameterFromPath(this.props, 'orderNumber', 'number');
    const sheetId = extractParameterFromPath(this.props, 'sheetId', 'number');

    const isFinalized = this.isCurrentOrderFinalized();

    return (
      <div>
        <FinalizeModal
          isOpen={modalIsOpen}
          orderNumber={orderNumber}
          sheetId={sheetId}
          closeModal={this.closeModal}
          callback={this.afterFinalize}
        />

        <LogoPreviewModal
          modalIsOpen={logoImagePreview.isOpen}
          closeModal={this.closeLogoPreviewModal}
          imageUrl={logoImagePreview.imageUrl}
        />
        <div className='container' >
          <div className='sheet'>
            <div className='sheet__header'>
              <div className='sheet__header-left'>
                <Link
                  to={printSheetUrl(sheetId)}
                  className='button button--link'
                >
                  <span className='margin margin--right-s'>
                    <i className='material-icons'>keyboard_arrow_left</i>
                  </span>
                  {`Sheet S${sheetId}`}
                </Link>
              </div>
              {
                doneIsActive &&
                <ul className='sheet__header-right'>
                  <li>
                    {
                      isFinalized
                        ? (
                          <div className='button--link'>
                            Finalized
                          </div>
                        )
                        : (
                          <button
                            onClick={this.openModal}
                            className='button button--color-gossamer button--size-l button--style-default button--radius-square button--shadow-m button--transform-uppercase'
                          >
                            Finalize
                          </button>
                        )
                    }
                  </li>
                </ul>
              }

              {
                toBeWeededAndMaskedIsActive &&
                <ul className='sheet__header-right'>
                  <li>
                    <div className='text-field flex'>
                      <input
                        className='has-icon--right w-320'
                        type='text'
                        placeholder='Barcode'
                        onKeyDown={this.barcodeKey}
                        ref={this.setBarcodeRef}
                      />
                      <i className='material-icons text-field__icon--right'>arrow_forward</i>
                    </div>
                  </li>
                  <li>
                    <button
                      onClick={this.markAllAsDone}
                      className='button button--color-gossamer button--size-l button--style-default button--radius-square button--shadow-m button--transform-uppercase'
                    >
                      Mark All As Done
                    </button>
                  </li>
                </ul>
              }
            </div>
            <div className='sheet__list'>
              <ul className='sheet__list-tabs sheet__list-tabs--columns-2'>
                <li>
                  <button
                    type='button'
                    className={toBeWeededAndMaskedIsActive ? 'is-active' : ''}
                    onClick={this.toBeWeededAndMaskedActive}
                  >
                    <strong>{`To be Print Assembled (${counts.initial})`}</strong>
                  </button>
                </li>
                <li>
                  <button
                    type='button'
                    className={doneIsActive ? 'is-active' : ''}
                    onClick={this.doneActive}
                  >
                    <strong>{`Done (${counts.done})`}</strong>
                  </button>
                </li>
              </ul>
              {
                toBeWeededAndMaskedIsActive &&
                <div className={`print__content ${toBeWeededAndMaskedIsActive ? 'is-active' : ''}`}>
                  <ToBeWeededAndMaskedTable
                    openLogoPreviewModal={this.openLogoPreviewModal}
                    data={items}
                    refreshData={this.refreshData}
                    pages={totalPages}
                    loading={loading}
                    fetchData={this.fetchData}
                    showPagination={hasNextPage || hasPreviousPage}
                  />
                </div>
              }
              {
                doneIsActive &&
                <div className={`print__content ${doneIsActive ? 'is-active' : ''}`}>
                  <DoneTable
                    openLogoPreviewModal={this.openLogoPreviewModal}
                    data={items}
                    pages={totalPages}
                    loading={loading}
                    showPagination={hasNextPage || hasPreviousPage}
                    refreshData={this.refreshData}
                    fetchData={this.fetchData}
                    isFinalized={isFinalized}
                  />
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

WeedingAndMasking.propTypes = {
  hasPreviousPage: PropTypes.bool.isRequired,
  hasNextPage: PropTypes.bool.isRequired,
  pageNumber: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  sortBy: PropTypes.string.isRequired,
  sortDirection: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      sheetId: PropTypes.string.isRequired,
      orderNumber: PropTypes.string.isRequired,
    }).isRequired,
  }),
  counts: PropTypes.object.isRequired,
  sheetId: PropTypes.number,
  orderNumber: PropTypes.number,
  orders: PropTypes.array,
};

const mapStateToProps = ({ weedingAndMaskingRoom }) => ({
  hasPreviousPage: weedingAndMaskingRoom.currentQueue.hasPreviousPage,
  hasNextPage: weedingAndMaskingRoom.currentQueue.hasNextPage,
  pageNumber: weedingAndMaskingRoom.currentQueue.pageNumber,
  totalPages: weedingAndMaskingRoom.currentQueue.totalPages,
  sortBy: weedingAndMaskingRoom.currentQueue.sortBy,
  sortDirection: weedingAndMaskingRoom.currentQueue.sortDirection,
  items: weedingAndMaskingRoom.currentQueue.items,
  counts: weedingAndMaskingRoom.counts,
  orders: weedingAndMaskingRoom.ordersList,
});

export default connect(mapStateToProps)(withRouter(WeedingAndMasking));
