/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Catalog Homefield API
 * OpenAPI spec version: v1
 */
import type { SportDto } from './sportDto';

export interface SportDtoApiResponse {
  success?: boolean;
  message?: string | null;
  result?: SportDto;
}
