/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Orders Homefield API
 * OpenAPI spec version: v1
 */
import type { ShippingInfo } from './shippingInfo';
import type { QueriesItem } from './queriesItem';

export interface CalculateOrderPricingDetailsCommand {
  organizationId?: number | null;
  salesChannelId?: number | null;
  prdOrder?: boolean;
  shippingInfo?: ShippingInfo;
  shippingBasePrice?: number;
  items?: QueriesItem[] | null;
}
