import React, { useCallback, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Field, formValueSelector, InjectedFormProps, reduxForm } from 'redux-form';
import { useGetAllCollectionsForLocker } from '@api/squadlockerServices/locker-manager';
import { keyNameEnum } from '@constants/enums/commonEnums';
import { rosterForm } from '@constants/reduxForms';
import { getRosterStatusOptions } from '@constants/options/options';
import { RosterFormData } from '@models/forms/RosterFormData';
import * as organizationActions from '@redux/organizations/actions';
import { RootState } from '@redux/index/reducers';
import { validateRoster } from '@redux/rosters/validations';
import { parseStringToBoolean } from '@util/valueHelpers';
import { mapToOptionsList } from '@util/mappingHelper';
import Input from '@sharedComponents/Form/Input';
import FormError from '@sharedComponents/Form/FormError';
import AutocompleteInput from '@sharedComponents/Inputs/AutocompleteInput';
import FormDropdown from '@sharedComponents/Form/FormDropdown';
import DatePicker from '@sharedComponents/Form/DatePicker';

const rosterStatusOptions = getRosterStatusOptions(true, 'Choose Status');

interface OwnProps {
  disabled?: {
    teamName?: boolean;
    lockerId?: boolean;
    collectionId?: boolean;
    disabled?: boolean;
  };
  buttons?: JSX.Element;
  hasActiveVouchers?: boolean;
}

const selector = formValueSelector(rosterForm);
const mapStateToProps = (state: RootState) => ({
  lockerId: selector(state, 'lockerId'),
  formErrors: (state?.form[rosterForm]?.submitErrors) || {},
  lockers: state.organizations.lockerSuggestions,
});

const mapDispatchToProps = {
  fetchLockerSuggestions: organizationActions.fetchLockerSuggestions,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type FormProps = OwnProps & ConnectedProps<typeof connector>;

type Props = FormProps & InjectedFormProps<RosterFormData, FormProps, string[]>;

const RosterForm = React.memo<Props>(({
  lockerId,
  handleSubmit,
  initialValues,
  error,
  formErrors,
  disabled = {},
  buttons,
  lockers,
  change,
  fetchLockerSuggestions,
  hasActiveVouchers = false,
}) => {
  const [
    selectedLocker,
    setSelectedLocker,
  ] = useState<
    Nullable<{
      id: number;
      teamName?: string;
    }>
  >(
    initialValues?.lockerId
      ? {
        id: initialValues.lockerId,
        teamName: initialValues.lockerTeamName,
      }
      : null,
  );

  const {
    data: lockerCollections,
    refetch: fetchAllCollectionsForLocker,
  } = useGetAllCollectionsForLocker(lockerId);

  useEffect(() => {
    if (!lockerId) { return; }
    fetchAllCollectionsForLocker();
  }, [
    fetchAllCollectionsForLocker,
    lockerId,
  ]);

  const onKeyPress = useCallback((e) => {
    if (e.key === keyNameEnum.Enter) {
      e.preventDefault();
    }
  }, []);

  const displayLocker = useCallback((locker) => (`L${locker.id} - ${locker.teamName}`), []);

  const selectLocker = useCallback((
    newSelectedLocker: Nullable<{
      id: number;
      teamName?: string;
    }>,
  ) => {
    setSelectedLocker(newSelectedLocker);

    change('lockerId', newSelectedLocker?.id);
    change('lockerTeamName', newSelectedLocker?.teamName);
    change('collectionId', null);
  }, [change]);

  const onFetchLockerSuggestions = useCallback((searchInput) => {
    fetchLockerSuggestions(searchInput);
  }, [fetchLockerSuggestions]);

  const { membersCount } = initialValues;

  const collectionOptions = mapToOptionsList({
    list: lockerCollections?.map((collection) => ({
      ...collection,
      disabled: collection.disabled,
    })) ?? [],
    key: 'id',
    value: 'id',
    name: 'name',
    emptyOption: {
      name: 'Choose a Collection',
    },
  });

  return (
    <form
      className='redux-form'
      onSubmit={handleSubmit}
      onKeyPress={onKeyPress}
      id={rosterForm}
    >
      <div className='rosters__form'>
        <div className='ml-15 mr-10'>
          <div className='redux-form__row'>
            <div className='redux-form__column--size-m'>
              <label className='redux-form__label'>
                Roster Team Name
              </label>
              <Field
                name={'teamName'}
                placeholder={'Enter Roster Team Name'}
                component={Input}
                type={'text'}
                disabled={disabled.teamName}
              />
            </div>

            <div className='redux-form__column--size-m ml-20'>
              <label className='redux-form__label'>
                Associated Locker
              </label>
              <div className='select-field'>
                <AutocompleteInput
                  suggestions={lockers}
                  fetchSuggestions={onFetchLockerSuggestions}
                  displayItem={displayLocker}
                  displaySuggestionText={displayLocker}
                  selectedItem={selectedLocker}
                  selectItem={selectLocker}
                  placeholder={'Enter locker'}
                  error={formErrors.lockerId}
                  hasSearchIcon={true}
                  size={'l'}
                  isDropdownFixed={true}
                  disabled={disabled.lockerId}
                />
              </div>
            </div>
          </div>

          <div className='redux-form__row w-100'>
            <div className='redux-form__column--size-m'>
              <label className='redux-form__label'>
                Status
              </label>
              <Field
                name={'disabled'}
                disabled={disabled.disabled}
                component={FormDropdown}
                initialValue={initialValues.disabled}
                change={change}
                options={rosterStatusOptions}
                parse={parseStringToBoolean}
                disabledTooltipText={hasActiveVouchers
                  ? "Cannot change roster team's status because the roster team has an active voucher associated with it"
                  : undefined}
              />
            </div>
            <div className='redux-form__column--size-m ml-20'>
              <label className='redux-form__label'>
                Associated Collection
              </label>
              <Field
                name={'collectionId'}
                disabled={disabled.collectionId}
                component={FormDropdown}
                initialValue={initialValues.collectionId}
                change={change}
                options={collectionOptions}
                disabledTooltipText={hasActiveVouchers
                  ? "Cannot change roster team's collection because the roster team has an active voucher associated with it"
                  : undefined}
              />
            </div>
          </div>

          <div className='redux-form__row w-100'>
            <div className='redux-form__column--size-m'>
              <label className='redux-form__label'>
                Members
              </label>
              <div>{membersCount ?? 0}</div>
            </div>
            <div className='redux-form__column--size-m ml-20'>
              <label className='redux-form__label'>
                Ordering Deadline
              </label>
              <Field
                name={'orderingDeadline'}
                placeholder={'Choose Ordering Deadline'}
                component={DatePicker}
                minDate={new Date()}
              />
            </div>
          </div>
        </div>
        <FormError error={error} />
      </div>
      <div>
        {buttons}
      </div>
    </form>
  );
});

export default connector(
  reduxForm<RosterFormData, FormProps, string[]>({
    form: rosterForm,
    enableReinitialize: true,
    validate: validateRoster,
  })(RosterForm),
);
