/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Catalog Homefield API
 * OpenAPI spec version: v1
 */
import type { DecorationSizeEnum } from './decorationSizeEnum';

export interface UpdateProductCommand {
  sku?: string | null;
  size?: string | null;
  coloredStyleId?: number | null;
  description?: string | null;
  weight?: number | null;
  vendorId?: string | null;
  qtyVendorMinimum?: number | null;
  alwaysAvailableVendorInventory?: boolean;
  decorationSize?: DecorationSizeEnum;
  disabled?: boolean;
  discontinued?: boolean;
}
