/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Catalog Homefield API
 * OpenAPI spec version: v1
 */

export interface DtgConfigurationProductArtworkDto {
  sku?: string | null;
  decorationLocationCode?: string | null;
  environmentName?: string | null;
  pretreatmentPercent?: number;
  width?: number;
  height?: number;
  rotation?: number;
  posX?: number;
  posY?: number;
  printArea?: string | null;
}
