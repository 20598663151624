/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */
import type { OrderInventoryStatusEnum } from './orderInventoryStatusEnum';
import type { HagPrintRoomJobStatusEnum } from './hagPrintRoomJobStatusEnum';
import type { HagPrintRoomJobTypeEnum } from './hagPrintRoomJobTypeEnum';
import type { OrderPrintStatusEnum } from './orderPrintStatusEnum';
import type { HagPrintRoomJobPriorityItemDto } from './hagPrintRoomJobPriorityItemDto';

export interface HagPrintRoomJobPagedListDto {
  hagPrintRoomJobId?: number;
  orderNumber?: number;
  orderId?: number;
  priorityOrderItemDecorationId?: number | null;
  isRush?: boolean;
  isExpressProduction?: boolean;
  decorationCount?: number;
  shipDate?: string;
  receivedDate?: string | null;
  claimedBy?: string | null;
  printingDate?: string | null;
  printingBy?: string | null;
  printedDate?: string | null;
  printedBy?: string | null;
  assemblingDate?: string | null;
  assemblingBy?: string | null;
  assembledDate?: string | null;
  assembledBy?: string | null;
  inventoryStatus?: OrderInventoryStatusEnum;
  status?: HagPrintRoomJobStatusEnum;
  type?: HagPrintRoomJobTypeEnum;
  printerNumber?: number | null;
  printStatus?: OrderPrintStatusEnum;
  priorityItemInfo?: HagPrintRoomJobPriorityItemDto;
}
