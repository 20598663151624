import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Column } from 'react-table-6';
import { useGetColoredStylesDecorationMethods } from '@api/squadlockerServices/colored-styles';
import { ColoredStyleDecorationMethodViewModel, LogoByLockerModel } from '@api/squadlockerServices/models';
import { useGetSettings } from '@api/squadlockerServices/utility';
import { ImageDto, ItemsQueriesPersonalization, PersonalizationTypeEnum, QueriesVariationDto } from '@api/orders/models';
import { useGetLockersForOrganization } from '@api/squadlockerServices/organizations';
import { decorationMethodsOptions } from '@constants/options/optionsValues';
import { keyNameEnum, sortDirectionShortEnum } from '@constants/enums/commonEnums';
import { orderDetailsNewOrder } from '@constants/clientUrls/clientUrls';
import { decorationMethodEnum } from '@constants/enums/decorationEnums';
import { SortDirectionLong } from '@customTypes/table';
import * as lockerManagerActions from '@redux/lockerManager/actions';
import * as productCatalogActions from '@redux/productCatalog/actions';
import * as orderManagementActions from '@redux/orderManagement/actions';
import * as coreActions from '@redux/core/actions';
import * as supportActions from '@redux/support/actions';
import { OrderManagementTempItemLogo, OrderManagementTempItemPersonalization } from '@redux/orderManagement/models';
import { RootState } from '@redux/index/reducers';
import { Variation } from '@redux/core/models';
import decorationMethodHelper from '@util/decorationMethodHelper';
import { getPagingParamsFromTable, getSortParamsFromTable } from '@util/tableHelpers';
import { materialSwal, navigateToPage } from '@util/componentHelper';
import { centsToDollars, formatDollarAmount } from '@util/numberHelpers';
import MultiSelectDropdown from '@sharedComponents/Inputs/Dropdowns/MultiSelectDropdown/MultiSelectDropdown';
import HeaderCell from '@sharedComponents/Table/TableCells/HeaderCell';
import Table from '@sharedComponents/Table/Table';
import SearchFilter from '@sharedComponents/Inputs/SearchFilter';
import LogoDropdown from '@sharedComponents/LogoDisplay/LogoDropdown/LogoDropdown';
import TooltipCell from '@sharedComponents/Table/TableCells/TooltipCell';
import Button from '@sharedComponents/Buttons/Button';
import ImageModal from '@sharedComponents/Modal/ImageModal';
import BackLink from '@sharedComponents/Navigation/BackLink';
import ImagePreviewCell from '@sharedComponents/Table/TableCells/ImagePreviewCell';
import ItemQuantityInput from './NewOrderAddItemsTableContent/ItemQuantityInput';
import ItemSizesDropdown from './NewOrderAddItemsTableContent/ItemSizesDropdown';
import ItemDescriptionCell from './NewOrderAddItemsTableContent/ItemDescriptionCell';
import LockersMultiSelect from './NewOrderAddItemsTableContent/LockersMultiSelect';

const VariationsTable = Table<Variation>();

interface IncompatableItem {
  name: string;
  coloredStyleId: number;
}

const mapStateToProps = ({
  core,
  support,
  lockerManager,
  productCatalog,
  orderManagement,
}: RootState) => ({
  queue: core.variationsQueue.queue,
  pageNumber: core.variationsQueue.pageNumber,
  pageSize: core.variationsQueue.pageSize,
  totalPages: core.variationsQueue.totalPages,
  hasPreviousPage: core.variationsQueue.hasPreviousPage,
  hasNextPage: core.variationsQueue.hasNextPage,
  sortOrder: lockerManager.currentQueue.sortOrder,
  sortByState: lockerManager.currentQueue.sortByState,
  lockerLogos: lockerManager.lockerLogos,
  allColors: support.colors,
  allDecorationLocations: productCatalog.decorationLocations,
  allCategories: productCatalog.categories as any[],
  items: orderManagement.tempItems || [],
  lockerId: orderManagement.tempOrder?.lockerId,
  organizationId: orderManagement.tempOrder?.organizationId,
});

const mapDispatchToProps = {
  fetchVariations: coreActions.fetchVariations,
  fetchLockerManagerProducts: lockerManagerActions.fetchLockerManagerProducts,
  fetchLockerLogos: lockerManagerActions.fetchLockerLogos,
  getDecorationLocations: productCatalogActions.getDecorationLocations,
  getDecorationLocationArtworkSizes: productCatalogActions.getDecorationLocationArtworkSizes,
  getCategories: productCatalogActions.getCategories,
  fetchColors: supportActions.fetchColors,
  saveTempItems: orderManagementActions.saveTempItems,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectedProps<typeof connector>;

const NewOrderAddItemsTable = React.memo(({
  lockerId,
  organizationId,
  items,
  queue,
  totalPages,
  pageNumber,
  pageSize,
  hasNextPage,
  hasPreviousPage,
  allColors,
  lockerLogos,
  allDecorationLocations,
  allCategories,
  fetchVariations,
  fetchColors,
  fetchLockerLogos,
  getDecorationLocations,
  getDecorationLocationArtworkSizes,
  getCategories,
  saveTempItems,
}: Props) => {
  const [
    isPageSelected,
    setIsPageSelected,
  ] = useState(false);
  const [
    selectedItems,
    setSelectedItems,
  ] = useState<typeof queue>([]);
  const [
    quantitiesDict,
    setQuantitiesDict,
  ] = useState<Record<number, number>>({});
  const [
    sizesDict,
    setSizesDict,
  ] = useState<Record<number, string>>({});
  const [
    pageNumberState,
    setPageNumberState,
  ] = useState(pageNumber);
  const [
    pageSizeState,
    setPageSizeState,
  ] = useState(pageSize);
  const [
    sortByState,
    setSortByState,
  ] = useState('');
  const [
    sortOrderState,
    setSortOrderState,
  ] = useState<SortDirectionLong>('');
  const [
    selectedLogos,
    setSelectedLogos,
  ] = useState<number[]>([]);
  const [
    selectedLockerIds,
    setSelectedLockerIds,
  ] = useState<number[]>([]);
  const [
    categoriesFilter,
    setCategoriesFilter,
  ] = useState<number[]>([]);
  const [
    colorFilter,
    setColorFilter,
  ] = useState<string[]>([]);
  const [
    decorationFilter,
    setDecorationFilter,
  ] = useState<string[]>([]);
  const [
    decorationLocationFilter,
    setDecorationLocationFilter,
  ] = useState<string[]>([]);
  const [
    searchInput,
    setSearchInput,
  ] = useState<string>('');
  const [
    imageModalIsOpen,
    setImageModalIsOpen,
  ] = useState<boolean>(false);
  const [
    selectedImageUrl,
    setSelectedImageUrl,
  ] = useState<string>('');
  const [
    allItemsCompatible,
    setAllItemsCompatible,
  ] = useState<boolean>(true);
  const [
    incompatibleList,
    setIncompatibleList,
  ] = useState<IncompatableItem[]>([]);
  const [
    coloredStylesIds,
    setColoredStylesIds,
  ] = useState<number[]>([]);

  const {
    data: coloredStylesDecorationMethods,
    refetch: fetchColoredStylesDecorationMethods,
  } = useGetColoredStylesDecorationMethods(
    { coloredStyleIds: coloredStylesIds },
  );

  type Item = (typeof queue)[number];

  const {
    isLoading: isLoadingOrganizationLockers,
    isError: isErrorOrganizationLockers,
    data: organizationLockers,
    refetch: fetchOrganizationLockers,
  } = useGetLockersForOrganization(organizationId!, undefined);

  const {
    data: personalizationSettings,
    refetch: fetchPersonalizationSettings,
  } = useGetSettings();

  useEffect(() => {
    fetchPersonalizationSettings();
  }, [fetchPersonalizationSettings]);

  useEffect(() => {
    if (!organizationId) { return; }
    fetchOrganizationLockers();
  }, [
    fetchOrganizationLockers,
    organizationId,
  ]);

  const lockerIds: number[] = useMemo(() => {
    if (isLoadingOrganizationLockers || isErrorOrganizationLockers) { return []; }

    return organizationLockers ? organizationLockers.map((locker) => locker.id) : [];
  }, [
    isLoadingOrganizationLockers,
    isErrorOrganizationLockers,
    organizationLockers,
  ]);

  useEffect(() => {
    fetchColors();
    fetchLockerLogos(lockerId);
    getDecorationLocations();
    getDecorationLocationArtworkSizes();
    getCategories();
  }, [
    fetchColors,
    fetchLockerLogos,
    getDecorationLocations,
    getDecorationLocationArtworkSizes,
    getCategories,
    lockerId,
  ]);

  useEffect(() => {
    setColoredStylesIds(queue.map(
      (element: QueriesVariationDto) => element.coloredStyleId!,
    ));
  }, [
    lockerLogos,
    queue,
  ]);

  useEffect(() => {
    fetchColoredStylesDecorationMethods();
  }, [
    coloredStylesIds,
    fetchColoredStylesDecorationMethods,
  ]);

  const search = useCallback(() => {
    let lockerIdsFilter: number[] = [];
    if (lockerId) {
      lockerIdsFilter = [lockerId];
    } else if (selectedLockerIds.length > 0) {
      lockerIdsFilter = selectedLockerIds;
    } else {
      lockerIdsFilter = lockerIds;
    }

    if (!lockerIdsFilter.length) { return; }

    fetchVariations(
      pageNumberState,
      pageSizeState,
      lockerIdsFilter,
      searchInput,
      categoriesFilter,
      colorFilter,
      decorationFilter,
      decorationLocationFilter,
      selectedLogos,
    );
  }, [lockerId, selectedLockerIds, fetchVariations, pageNumberState, pageSizeState, searchInput, categoriesFilter, colorFilter, decorationFilter, decorationLocationFilter, selectedLogos, lockerIds]);

  useEffect(() => {
    search();
  }, [
    search,
    searchInput,
    fetchVariations,
    pageNumberState,
    pageSizeState,
    lockerId,
    lockerIds,
    selectedLockerIds,
    organizationId,
    sortByState,
    sortOrderState,
    colorFilter,
    categoriesFilter,
    decorationFilter,
    decorationLocationFilter,
    searchInput,
    selectedLogos,
  ]);

  // Check selected items and logo compatibility
  useEffect(() => {
    let allCompatible = true;
    for (const item of queue) {
      if (
        !selectedItems.some((selectedItem) => selectedItem.coloredStyleId === item.coloredStyleId)
        && incompatibleList.some((incompatableItem) => incompatableItem.coloredStyleId === item.coloredStyleId)
      ) {
        setIncompatibleList(
          incompatibleList.filter((incompatableItem) => incompatableItem.coloredStyleId !== item.coloredStyleId),
        );
      }
    }
    for (const selectedItem of selectedItems) {
      let decorationMethod: string;
      if (selectedItem.coloredStyleId) {
        decorationMethod = decorationMethodHelper.transform(
          coloredStylesDecorationMethods?.decorationMethods?.find(
            (method: ColoredStyleDecorationMethodViewModel) => method.id === selectedItem.coloredStyleId,
          )?.decorationMethod ?? '',
        );
      } else {
        decorationMethod = decorationMethodEnum.Undefined;
      }
      if (decorationMethod === decorationMethodEnum.Undefined) {
        continue;
      }

      const found = lockerLogos
        .some(
          (logo: LogoByLockerModel) =>
            logo.decoration_methods
              .some((method: string) => method === decorationMethod),
        );

      const skuParts = selectedItem!.products![0]?.sku!.split('-') || [
        '',
        '',
      ];
      const itemIdentificator = `${skuParts[0]}-${skuParts[1]}`;
      const itemColoredStyleId = selectedItem.coloredStyleId;
      if (!found) {
        const toAdd: IncompatableItem = {
          name: itemIdentificator,
          coloredStyleId: itemColoredStyleId!,
        };
        const contains = incompatibleList.some((item) => item.coloredStyleId === toAdd.coloredStyleId);
        if (!contains) {
          setIncompatibleList([
            ...incompatibleList,
            toAdd,
          ]);
        }
        if (allCompatible) { allCompatible = false; }
      }
    }
    if (allCompatible) {
      setIncompatibleList([]);
    }
    setAllItemsCompatible(true); // TODO set to actual value (allCompatible) after testing
  }, [selectedItems, selectedLogos, coloredStylesDecorationMethods, queue, lockerLogos, incompatibleList]);

  const validateSelectedItems = useCallback((newItems: typeof items): boolean => {
    for (const item of newItems) {
      if (!item.size || !item.quantity) {
        materialSwal('Error', 'Size or quantity not selected', 'error');

        return false;
      }
    }

    return true;
  }, []);

  const getPersonalizationUnitPrice = useCallback((
    personalization: ItemsQueriesPersonalization,
  ) => {
    if (!personalizationSettings || !personalization.required) {
      return 0;
    }

    if (personalization.personalizationType === PersonalizationTypeEnum.NameAndNumber) {
      return centsToDollars(personalizationSettings?.defaultPersonalizationPrices.textAndNumber);
    }

    return centsToDollars(personalizationSettings?.defaultPersonalizationPrices.textOrNumber);
  }, [personalizationSettings]);

  const mapVariation = useCallback((variation: typeof selectedItems[number]) => {
    const variationId: number = variation.id!;
    const size = sizesDict[variationId];
    const quantity = quantitiesDict[variationId];
    const availableSizes: string[] = variation.products?.map((product: any) => product.size) ?? [];
    const sku = variation.products?.find((product: any) => product.size === size)?.sku;

    return ({
      id: Symbol(),
      variationId,
      itemId: variation.item!.id!,
      lockerId: variation.item!.salesChannelId!,
      coloredStyleId: variation.coloredStyleId!,
      sku: sku!,
      name: variation.item!.name!,
      quantity,
      size,
      availableSizes,
      custom: variation.item!.custom!,
      colorGroup: variation.color?.name as (string | undefined),
      pricePaid: variation.item?.price,
      image: variation.images?.find((image) => image.isPrimary)?.src as (string | undefined),
      images: variation.images!,
      personalizations: (variation.item?.personalizations ?? []).map((personalization) => ({
        id: personalization.id,
        name: undefined,
        number: undefined,
        personalizationType: personalization.personalizationType,
        decorationMethod: personalization.decorationMethod,
        decorationLocation: personalization.decorationLocation,
        decorationHeight: personalization.decorationHeight,
        colorId: personalization.colorId,
        outlineColorId: personalization.outlineColorId,
        required: personalization.required,
        maxPersonalizationNameLength: personalization.maxPersonalizationNameLength,
        unitPrice: getPersonalizationUnitPrice(personalization),
      } as OrderManagementTempItemPersonalization)),
      logos: (variation.item?.logos ?? []).map((logo) => ({
        id: logo.logoId,
        logoUrl: logo.logoUrl,
        decorationMethod: logo.decorationMethod,
        decorationLocation: logo.decorationLocation,
        decorationHeight: logo.decorationHeight,
        decorationWidth: logo.decorationWidth,
      } as OrderManagementTempItemLogo)),
    });
  }, [
    sizesDict,
    quantitiesDict,
    getPersonalizationUnitPrice,
  ]);

  const addItems = useCallback(async (): Promise<void> => {
    const newTempItems = selectedItems.map(mapVariation);

    if (!validateSelectedItems(newTempItems)) {
      return;
    }

    const tempItems = [
      ...items,
      ...newTempItems,
    ];

    saveTempItems(tempItems);
    navigateToPage(orderDetailsNewOrder);
  }, [
    items,
    selectedItems,
    mapVariation,
    saveTempItems,
    validateSelectedItems,
  ]);

  const selectQuantity = useCallback((newQuantity: number, item): void => {
    const newQuantitiesDict = {
      ...quantitiesDict,
      [item.id]: newQuantity,
    };

    setQuantitiesDict(newQuantitiesDict);
  }, [quantitiesDict]);

  const selectSize = useCallback((newSizes, item) => {
    const newSizesDict = {
      ...sizesDict,
      [item.id]: newSizes,
    };

    setSizesDict(newSizesDict);
  }, [sizesDict]);

  const fetchData = useCallback((state, instance) => {
    const {
      page,
      pageSize: newPageSize,
    } = getPagingParamsFromTable(instance);

    const {
      sortColumn,
      sortDirection,
    } = getSortParamsFromTable(instance, sortDirectionShortEnum as any, sortByState, sortOrderState);

    setPageNumberState(page + 1);
    setPageSizeState(newPageSize);
    setSortByState(sortColumn);
    setSortOrderState(sortDirection);
  }, [
    sortByState,
    sortOrderState,
  ]);

  const clearSearch = useCallback(() => {
    setSearchInput('');
  }, []);

  const filterKey = useCallback((e) => {
    if (e.key && e.key !== keyNameEnum.Enter) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();

    setSearchInput(e.target.value);
  }, []);

  const lockersChange = useCallback((lockerIdsList: number[]) => {
    if (!lockerIdsList) { return; }
    setSelectedLockerIds(lockerIdsList);
  }, []);

  const logosSelectOnChange = useCallback((logos: number[]) => {
    if (!logos) { return; }
    setSelectedLogos(logos);
  }, []);

  const colorChange = useCallback((colors: string[]) => {
    if (!colors) { return; }
    setColorFilter(colors);
  }, []);

  const decorationChange = useCallback((decorations: string[]) => {
    if (!decorations) { return; }
    setDecorationFilter(decorations);
  }, []);

  const decorationLocationChange = useCallback((locations: string[]) => {
    if (!locations) { return; }
    setDecorationLocationFilter(locations);
  }, []);

  const categoriesChange = useCallback((categoryIds: number[]) => {
    if (!categoryIds) { return; }
    setCategoriesFilter(categoryIds);
  }, []);

  const openModalWithImage = useCallback((imageUrl: string) => {
    setImageModalIsOpen(true);
    setSelectedImageUrl(imageUrl);
  }, []);

  const closeModalWithImage = useCallback(() => {
    setImageModalIsOpen(false);
    setSelectedImageUrl('');
  }, []);

  const updateSelection = useCallback((newSelectedItems: Item[], newIsPageSelected: boolean) => {
    const newQuantitiesDict = { ...quantitiesDict };

    for (const item of newSelectedItems) {
      const itemId = item.id!;
      if (!newQuantitiesDict[itemId]) {
        newQuantitiesDict[itemId] = 1;
      }
    }

    setSelectedItems(newSelectedItems);
    setIsPageSelected(newIsPageSelected);
    setQuantitiesDict(newQuantitiesDict);
  }, [quantitiesDict]);

  const columns: Array<Column<Item>> = useMemo(() => [
    {
      Header: 'Image',
      accessor: '',
      className: 'no-padding',
      minWidth: 10,
      Cell: (cellProps) => {
        const images = cellProps.original.images as ImageDto[];
        const imageUrl = images.find((image) => image.isPrimary)?.src;

        if (!imageUrl) { return null; }

        return (
          <ImagePreviewCell
            imageUrl={imageUrl ?? undefined}
            openModalWithUrl={openModalWithImage}
          />
        );
      },
    },
    {
      Header: <HeaderCell text={'SKU & Name'} />,
      accessor: '',
      sortable: true,
      minWidth: 40,
      Cell: (cellProps) => {
        const products = cellProps.original.products || [];
        const sku = products[0]?.sku.split('-').slice(0, -1)
          .join('-');
        const { name } = cellProps.original.item;

        return (
          <ItemDescriptionCell
            sku={sku}
            name={name}
            showLabels={true}
          />
        );
      },
    },
    {
      Header: 'Decoration Location(s)',
      accessor: '',
      minWidth: 30,
      resizable: false,
      Cell: (cellProps) => {
        const locations: string = cellProps.original.item.personalizations
          .map((x: OrderManagementTempItemPersonalization) => x.decorationLocation);

        return (
          locations.length > 0
            ? (
              <TooltipCell
                items={locations}
                displayName={locations.length === 1 ? 'Location' : 'Locations'}
              />
            )
            : '-'
        );
      },
    },
    {
      Header: 'Color',
      accessor: 'color.name',
      minWidth: 10,
      Cell: (cellProps) => <span>{cellProps.value}</span>,
    },
    {
      Header: 'Size',
      accessor: '',
      minWidth: 30,
      Cell: (cellProps) => {
        const variation = cellProps.original as (typeof queue)[number];
        const sizes = variation.products?.map((product) => product.size!) ?? [];
        const selectedItemIds: number[] = selectedItems.map((it) => it.id!);
        const isSelected = selectedItemIds.includes(cellProps.original.id);

        return (
          <ItemSizesDropdown
            selectSize={selectSize}
            selectedItem={cellProps.original}
            sizes={sizes}
            disabled={!isSelected}
          />
        );
      },
    },
    {
      Header: 'Quantity',
      accessor: '',
      minWidth: 10,
      Cell: (cellProps) => {
        const value = quantitiesDict[cellProps.original.id] || 0;
        const selectedItemIds: number[] = selectedItems.map((it) => it.id!);
        const isSelected = selectedItemIds.includes(cellProps.original.id);

        return (
          <ItemQuantityInput
            selectQuantity={selectQuantity}
            selectedQuantity={value}
            selectedItem={cellProps.original}
            disabled={!isSelected}
          />
        );
      },
    },
    {
      Header: 'Price',
      accessor: 'item.price',
      minWidth: 10,
      Cell: (cellProps) => cellProps.value ? `${formatDollarAmount(cellProps.value)}` : '-',
    },
  ], [
    quantitiesDict,
    selectQuantity,
    selectSize,
    openModalWithImage,
    selectedItems,
  ]);

  const addItemsButtonDisabled = (): boolean => {
    if (selectedItems.length === 0) {
      return true;
    }

    for (const item of selectedItems) {
      if (!sizesDict[item.id!] || !quantitiesDict[item.id!]) {
        return true;
      }
    }

    if (!allItemsCompatible) { return true; }

    return false;
  };

  const reportIncompatableItem = (incompatableItem: IncompatableItem) => (
    <div
      key={incompatableItem.name}
      className='warning-text warning-message mt-20'
    >
      {incompatableItem.name}
    </div>
  );

  return (
    <div>
      <ImageModal
        isOpen={imageModalIsOpen}
        closeModal={closeModalWithImage}
        imageUrl={selectedImageUrl}
        isCompact={true}
      />
      <div className='justify__space-between align__center mb-15'>
        <BackLink
          text={'Back to order'}
          url={orderDetailsNewOrder}
        />
        <Button
          type={'primary'}
          text={`Add (${selectedItems.length}) selected item(s)`}
          classes={'mr-10 ml-10'}
          onClick={addItems}
          disabled={addItemsButtonDisabled()}
        />
      </div>
      <div>
        {!allItemsCompatible
          && (
            <div>
              <div className='warning-text warning-message mt-20'>
                Some items selected are not compatible with any of the locker logos
              </div>
              {incompatibleList.map(reportIncompatableItem)}
            </div>
          )}
      </div>
      <div className='lockerManagerEdit__filters'>
        <div className='lockerManagerEdit__filters-fields'>
          <SearchFilter
            search={filterKey}
            clearSearch={clearSearch}
          />
          {lockerIds.length > 0
            && (
              <LockersMultiSelect
                lockerIds={lockerIds.map((id) => ({ id }))}
                lockersChange={lockersChange}
              />
            )}
          <MultiSelectDropdown<any, 'code'>
            objects={allColors}
            itemText={'colors'}
            updateCallback={colorChange}
            textKey={'code'}
            valueKey={'code'}
            classNames={'margin-left'}
            color={true}
          />
          <MultiSelectDropdown<any, 'value'>
            objects={decorationMethodsOptions}
            itemText={'decorations'}
            updateCallback={decorationChange}
            textKey={'name'}
            valueKey={'value'}
            classNames={'margin-left'}
          />
          <MultiSelectDropdown<any, 'code'>
            objects={allDecorationLocations}
            itemText={'locations'}
            updateCallback={decorationLocationChange}
            textKey={'code'}
            valueKey={'code'}
            classNames={'margin-left'}
          />
          <MultiSelectDropdown<any, 'id'>
            objects={allCategories}
            itemText={'categories'}
            updateCallback={categoriesChange}
            textKey={'name'}
            valueKey={'id'}
            classNames={'margin-left'}
          />
          <LogoDropdown
            logos={lockerLogos}
            updateCallback={logosSelectOnChange}
            className={'margin-left'}
          />
        </div>
      </div>

      <div className='sheet'>
        <VariationsTable
          data={queue}
          columns={columns}
          showPagination={hasNextPage || hasPreviousPage}
          totalPages={totalPages}
          onFetchData={fetchData}
          isBulkActionsMode={selectedItems?.length > 0}
          selectable={true}
          selectPredicateOrKey={'id'}
          updateSelection={updateSelection}
          selectedData={selectedItems}
          isPageSelected={isPageSelected}
          stickyHeader={true}
          showWrappedColumns={true}
        />
      </div>
    </div>
  );
});

export default connector(NewOrderAddItemsTable);
