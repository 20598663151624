import React, { useCallback } from 'react';
import Radio from '@material-ui/core/Radio';

export interface RadioGroupItemModel<T extends string | number | boolean> {
  value: T;
  label: string | JSX.Element;
  rootClassName?: string;
  className?: string;
  disabled?: boolean;
  isVisible?: boolean;
  hasInput?: boolean;
  inputNode?: Nullable<JSX.Element>;
}

interface OwnProps<T extends string | number | boolean> {
  item: RadioGroupItemModel<T>;
  value: string | number | boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClickOnLabel?: (value: T) => void;
}

type Props<T extends string | number | boolean> = OwnProps<T>;

const RadioGroupItem = <T extends string | number | boolean>({
  item,
  value,
  onChange,
  onClickOnLabel,
}: Props<T>) => {
  const handleClickOnLabel = useCallback(() => {
    onClickOnLabel!(item.value);
  }, [
    onClickOnLabel,
    item,
  ]);

  if (item.isVisible === false) {
    return null;
  }

  return (
    <div className={`radio__group--item ${item.className ?? ''} ${item.disabled ? 'disabled' : ''}`}>
      <Radio
        checked={item.value === value}
        onChange={onChange}
        value={item.value}
        classes={{
          checked: item.disabled ? '' : 'radio__green',
          root: item.rootClassName,
        }}
        disabled={item.disabled}
      />
      <span
        className={`${onClickOnLabel ? 'cursor-pointer' : ''}`}
        onClick={onClickOnLabel && handleClickOnLabel}
      >
        {item.label}
      </span>
      {
        item.hasInput &&
        item.inputNode
      }
    </div>
  );
};

export default RadioGroupItem;
