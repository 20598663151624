/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Financials Homefield API
 * OpenAPI spec version: v1
 */
import type { OrderItemDto } from './orderItemDto';

export interface OrderItemsStatusDto {
  fundraisingAmountTotal?: number;
  fundraisingAmountProcessing?: number;
  fundraisingAmountAvailable?: number;
  payoutAmountRequested?: number;
  payoutAmountCanceled?: number;
  payoutAmountCompleted?: number;
  readonly processing?: number;
  readonly available?: number;
  readonly requestedProcessing?: number;
  readonly requestedCanceled?: number;
  readonly completed?: number;
  items?: OrderItemDto[] | null;
}
