/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */
import type { DecorationSizeEnum } from './decorationSizeEnum';

export interface PrintQueueDetailsPersonalizationItem {
  prepressJobItemId?: number[] | null;
  label?: string | null;
  isCanceled?: boolean;
  decorationLocation?: string | null;
  colorId?: number | null;
  outlineColorId?: number | null;
  barcodes?: string[] | null;
  quantity?: number;
  decorationSize?: DecorationSizeEnum;
  decorationHeight?: number | null;
}
