/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Product Catalog API
 * OpenAPI spec version: v1
 */

export interface EdiCsvFileImportDto {
  vendorId?: string | null;
  csvHasHeader?: boolean;
  csvColumnIndexUpc?: number | null;
  csvColumnIndexQty?: number | null;
  csvColumnIndexDate?: number | null;
  csvDateFormat?: string | null;
  fileNameDateRegex?: string | null;
  csvFile?: string | null;
}
