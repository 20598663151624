/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Catalog Homefield API
 * OpenAPI spec version: v1
 */
import type { SetupStatusEnum } from './setupStatusEnum';
import type { PersonalizationColorDto } from './personalizationColorDto';
import type { ProductDto } from './productDto';

export interface ColoredStyleDto {
  id?: number;
  uuid?: string | null;
  styleId?: number;
  created?: string | null;
  updated?: string | null;
  deleted?: string | null;
  status: SetupStatusEnum;
  code: string;
  colorGroup: string;
  colorA?: string | null;
  colorB?: string | null;
  floodColorId?: number | null;
  imageUrlFront?: string | null;
  imageUrlBack?: string | null;
  imageUrlSide?: string | null;
  imageUrlGrid?: string | null;
  imageUrlGridPreview?: string | null;
  qtyVendorMinimum?: number | null;
  alwaysAvailableVendorInventory: boolean;
  disabled: boolean;
  discontinued: boolean;
  preferredVendor: boolean;
  personalizationColors?: PersonalizationColorDto[] | null;
  rowVersion?: string | null;
  products?: ProductDto[] | null;
}
