/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Financials Homefield API
 * OpenAPI spec version: v1
 */

export interface RefundVoucherOrderCommand {
  id?: number;
  amount?: number;
}
