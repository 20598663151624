/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */
import type { OrderStatusEnumStatusModel } from './orderStatusEnumStatusModel';
import type { ShippingInfoDto } from './shippingInfoDto';
import type { SubmitStatusEnum } from './submitStatusEnum';

export interface ProductionAssemblyOrderHeaderDto {
  orderId?: number;
  orderNumber?: number;
  gpDocumentId?: string | null;
  status?: OrderStatusEnumStatusModel;
  shippingInfo?: ShippingInfoDto;
  productionNote?: string | null;
  shipDate?: string;
  uniqueItemsCount?: number;
  isRushed?: boolean;
  isCanceled?: boolean;
  isPaused?: boolean;
  isExpressProduction?: boolean;
  cartLocations?: string[] | null;
  readonly isAssemblyDisabled?: boolean;
  salesOrderNumber?: string | null;
  netSuiteAccountingSubmitStatus?: SubmitStatusEnum;
  salesOrderId?: number | null;
  sports?: string[] | null;
}
