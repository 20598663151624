/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Orders Homefield API
 * OpenAPI spec version: v1
 */
import type { ImageDto } from './imageDto';

export interface BrandDto {
  id?: number;
  name?: string | null;
  image?: ImageDto;
}
