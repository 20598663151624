import React from 'react';
import { lockerMgrS3Logos } from '@constants/common';

const LogoImage = ({ logo, hoveredColor }) => (
  <div
    className='color-detection__logo transparent logo-bank__upload-modal--logo'
    style={hoveredColor
      ? {
        backgroundImage: 'none',
        backgroundColor: hoveredColor,
      }
      : {}
    }
  >
    <div
      className='color-detection__logo'
      style={{ backgroundImage: `url("${lockerMgrS3Logos}/${logo?.image}")` }}
    />
  </div>
);

export default LogoImage;
