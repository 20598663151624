import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  reduxForm,
  stopSubmit,
} from 'redux-form';
import { validateColoredStyle } from '@redux/productCatalog/validations';
import { updateGridPreview } from '@APICalls/productCatalog/actions';
import { editColoredStyleForm } from '@constants/reduxForms';
import { materialSwal } from '@util/componentHelper';
import Modal from '@sharedComponents/Modal/Modal';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import ColoredStyleForm from '../../ColoredStyleForm';

class EditColoredStyleModal extends PureComponent {
  handleCloseModal = () => {
    const {
      dispatch,
      closeModal,
    } = this.props;

    closeModal();
    dispatch(stopSubmit(editColoredStyleForm, {}));
  };

  updateGridPreview = async (coloredStyleId) => {
    const { refresh } = this.props;

    const res = await updateGridPreview(coloredStyleId);

    if (res?.success) {
      materialSwal('Success', res.message, 'success');
      refresh();
      this.handleCloseModal();
    }
  };

  render() {
    const {
      modalIsOpen,
      handleSubmit,
      error,
      initialValues,
      change,
    } = this.props;

    return (
      <Modal
        title={'Edit Colored Style'}
        modalSize={'xl'}
        isOpen={modalIsOpen}
        closeModal={this.handleCloseModal}
        buttons={(
          <ModalButtons
            onClose={this.handleCloseModal}
            formSubmission={true}
            confirmBtnText={'Save'}
            formId={editColoredStyleForm}
          />
        )}
      >
        {
          modalIsOpen &&
          <div className='catalog-details__modal--container'>
            <ColoredStyleForm
              onSubmit={handleSubmit}
              error={error}
              change={change}
              customizable={initialValues?.style?.customizable}
              style={initialValues.style}
              updateGridPreview={this.updateGridPreview}
              initialValues={initialValues}
            />
          </div>
        }
      </Modal>
    );
  }
}

EditColoredStyleModal.propTypes = {
  modalIsOpen: PropTypes.bool,
  closeModal: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  error: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  change: PropTypes.func,
  customizable: PropTypes.bool,
  refresh: PropTypes.func,
};

export default connect()(reduxForm({
  form: editColoredStyleForm,
  enableReinitialize: true,
  validate: validateColoredStyle,
})(EditColoredStyleModal));
