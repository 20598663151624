/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Orders Homefield API
 * OpenAPI spec version: v1
 */
import type { ContactDto } from './contactDto';

export interface OrganizationDetailDto {
  id?: number;
  uuid?: string | null;
  created?: string | null;
  updated?: string | null;
  deleted?: string | null;
  platformMemberId?: number;
  name?: string | null;
  slug?: string | null;
  accountingId?: string | null;
  decisionMaker?: ContactDto;
  accountExecutive?: ContactDto;
}
