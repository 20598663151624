import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import Modal from '@sharedComponents/Modal/Modal';

class DeleteAdminModal extends PureComponent {
  render() {
    const {
      admin,
      deleteAdmin,
      closeModal,
      isOpen,
    } = this.props;

    return (
      <Modal
        title={'Remove Admin'}
        modalHeight={'l'}
        modalWidth={'s'}
        modalClass={'lockerManager__delete-modal'}
        isOpen={isOpen}
        closeModal={closeModal}
        buttons={(
          <ModalButtons
            confirmBtnText={'Yes, Remove'}
            cancelBtnText={'Cancel'}
            onConfirm={deleteAdmin.bind(null, admin)}
            onClose={closeModal}
            isDangerousAction={true}
          />
        )}
      >
        <div>
          <h3>
            Are you sure you want to remove the&nbsp;
            <b>
              {admin?.fullName} ({admin?.email})&nbsp;
            </b>
            from the locker managers?
          </h3>
        </div>
      </Modal>
    );
  }
}

DeleteAdminModal.propTypes = {
  admin: PropTypes.shape({
    id: PropTypes.number.isRequired,
    fullName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  }),
  deleteAdmin: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default DeleteAdminModal;
