/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Catalog Homefield API
 * OpenAPI spec version: v1
 */
import type { SortByEnum } from './sortByEnum';
import type { SortDirectionEnum } from './sortDirectionEnum';
import type { DecorationMethodEnum } from './decorationMethodEnum';
import type { SetupStatusEnum } from './setupStatusEnum';
import type { DeletedFilterEnum } from './deletedFilterEnum';

export type GetHomefieldApiColoredstylesParams = { pageNumber?: number; pageSize?: number; sortBy?: SortByEnum; sortDirection?: SortDirectionEnum; search?: string; brandId?: number[]; categoryId?: number[]; sportId?: number[]; decorationMethod?: DecorationMethodEnum[]; personalizationDecorationLocation?: string[]; color?: string[]; size?: string[]; styleId?: number; status?: SetupStatusEnum; priceMin?: number; priceMax?: number; customizable?: boolean; recommended?: boolean; deleted?: DeletedFilterEnum };
