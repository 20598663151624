import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
} from 'react';
import { useLocationUrlSearchParams } from '@hooks/helpers';
import Table from '@components/shared/Table/Table';
import { BatchWarehouseCategoryEnum, BatchWarehouseGroupingDateTypeEnum, BatchWarehouseListOrderByEnum, BatchWarehouseOrderDto } from '@api/fulfillment/models';
import { useGetHomefieldApiWarehouseschedulingOrdergroupsList, usePutHomefieldApiWarehouseschedulingOrdersmovetopicking } from '@api/fulfillment/warehouse-scheduling';
import { BatchWarehouseSchedulingListUrlWithParamsArgsType } from '@constants/clientUrls/clientUrls';
import { parseDateNumeric } from '@util/dateHandler';
import { getPagingParamsFromTable, getSortParamsFromTable } from '@util/tableHelpers';
import { sortDirectionEnum } from '@constants/enums/commonEnums';
import { getSelectableTableRowProps, selectAllItems } from '@util/selectionHelpers';
import HeaderCell from '@components/shared/Table/TableCells/HeaderCell';
import useHeaderContext from '@components/shared/Contexts/HeaderContext';
import ProgressBarCell from '@components/shared/Table/TableCells/ProgressBarCell';
import BatchWarehouseSchedulingDetails from './BatchWarehouseSchedulingDetails';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import Button from '@components/shared/Buttons/Button';

const OrdersTable = Table<BatchWarehouseOrderDto>();

export const BatchWarehouseSchedulingList: React.FC = () => {
  const params = useLocationUrlSearchParams() as BatchWarehouseSchedulingListUrlWithParamsArgsType;
  const filter = params.filter ?? undefined;
  const minDate = params.minDate ?? '2000-01-01';
  const maxDate = params.maxDate ?? '2999-01-01';
  const category = params.category ?? BatchWarehouseCategoryEnum.TotalOrders;
  const groupingDateType = params.groupingDateType ?? BatchWarehouseGroupingDateTypeEnum.Shipping;

  const [pageConfig, setPageConfig] = useState({
    pageNumber: 1,
    pageSize: 10,
    sortBy: BatchWarehouseListOrderByEnum.OrderNumber,
    sortDesc: true,
  });
  const [focusedOrder, setFocusedOrder] = useState<BatchWarehouseOrderDto | null>(null);
  const [selectedOrders, setSelectedOrders] = useState<BatchWarehouseOrderDto[]>([]);
  const { headerMutator } = useHeaderContext();

  const { data, refetch } = useGetHomefieldApiWarehouseschedulingOrdergroupsList({
    filter,
    minDate,
    maxDate,
    category,
    groupingDateType,
    partners: params.partners,
    vendors: params.vendors,
    ...pageConfig,
  }, {
    query: {
      enabled: true,
      refetchOnWindowFocus: false,
    },
  });

  const queryClient = useQueryClient();

  const resetQueryClient = useCallback(() => {
    queryClient.invalidateQueries();
  }, [queryClient]);

  // Single Order Operations (React Query)
  const {
    mutate: mutDispatchTicket,
    data: mutDispatchResult,
    // isLoading: mutDispatchIsLoading,
    isError: mutDispatchIsError,
    isSuccess: mutDispatchIsSuccess,
  } = usePutHomefieldApiWarehouseschedulingOrdersmovetopicking({
    mutation: {
      onSuccess: () => {
        resetQueryClient();
      },
    },
  });

  const handleFetchData = useCallback((_: unknown, instance: unknown) => {
    const {
      page: instPageNumber,
      pageSize: instPageSize,
    } = getPagingParamsFromTable(instance);

    const {
      sortColumn,
      sortDirection,
    } = getSortParamsFromTable(instance, sortDirectionEnum);

    let sortBy: BatchWarehouseListOrderByEnum = BatchWarehouseListOrderByEnum.OrderNumber;
    switch (sortColumn) {
      case 'orderNumber':
        sortBy = BatchWarehouseListOrderByEnum.OrderNumber;
        break;
      case 'shipDate':
        sortBy = BatchWarehouseListOrderByEnum.ShipDate;
        break;
      case 'datePlaced':
        sortBy = BatchWarehouseListOrderByEnum.DatePlaced;
        break;
      default:
        break;
    }

    setPageConfig({
      pageNumber: instPageNumber + 1,
      pageSize: instPageSize,
      sortBy,
      sortDesc: sortDirection === sortDirectionEnum.Desc,
    });
  }, []);

  const isSelectingItemDisabled = useCallback((ord?: BatchWarehouseOrderDto) => {
    if (!ord) return true;

    return !ord.readyItemCount || ord.readyItemCount <= 0;
  }, []);

  const handleFocusOrder =
    useCallback((state: unknown, rowInfo: unknown) => getSelectableTableRowProps<BatchWarehouseOrderDto>(
      (ord) => setFocusedOrder(ord),
      rowInfo as { original: BatchWarehouseOrderDto; },
      focusedOrder,
      'orderNumber'
    ), [focusedOrder]);

  const handleSelect = useCallback((ord: BatchWarehouseOrderDto[]) => {
    setSelectedOrders([...ord]);
  }, []);

  const allAreSelected = !!data?.items?.length
    && data.items.every(
      (x) => isSelectingItemDisabled(x) || selectedOrders.some((y) => x.orderNumber === y.orderNumber)
    );
  const handleSelectAll = useCallback(() => {
    if (!data?.items) return;
    const { newSelectedItems } = selectAllItems(data.items, selectedOrders, allAreSelected, 'orderNumber');
    setSelectedOrders(newSelectedItems);
  }, [
    allAreSelected,
    data?.items,
    selectedOrders,
  ]);

  const tableColumn = useCallback(
    <TKey extends keyof BatchWarehouseOrderDto>(
      name: string, accessor: TKey, cb: (arg: BatchWarehouseOrderDto[TKey]) => React.ReactNode, sortable?: boolean
    ) => ({
      Header: sortable ? <HeaderCell text={name} /> : name,
      accessor: '',
      id: accessor,
      minWidth: 150,
      sortable,
      Cell: ({ original }: { original: BatchWarehouseOrderDto; }) => cb(original[accessor]),
    }), []
  );

  const stringTableColumn = useCallback(
    (
      name: string,
      accessor: keyof BatchWarehouseOrderDto,
      sortable?: boolean
    ) => tableColumn(name, accessor, (arg) => arg?.toString(), sortable)
    , [tableColumn]
  );

  const dateTableColumn = useCallback(
    (
      name: string,
      accessor: keyof BatchWarehouseOrderDto,
      sortable?: boolean
    ) => tableColumn(name, accessor, parseDateNumeric, sortable)
    , [tableColumn]
  );

  const columns = useMemo(() => [
    stringTableColumn('Order No.', 'orderNumber', true),
    dateTableColumn('Ship Date', 'shipDate', true),
    dateTableColumn('Date Placed', 'datePlaced', true),
    stringTableColumn('Customer Name', 'customerName'),
    stringTableColumn('Partner Name', 'partnerName'),
    {
      Header: 'Inventory',
      accessor: '',
      id: 'inventory',
      minWidth: 150,
      Cell: ({ original }: { original: BatchWarehouseOrderDto; }) => (
        <ProgressBarCell
          current={original.allocatedItemCount ?? 0}
          total={original.totalItemCount ?? 0}
        />
      ),
    },
  ], [stringTableColumn, dateTableColumn]);

  const handleSingleDispatch = useCallback((order: BatchWarehouseOrderDto) => {
    if (!order.orderNumber) return;
    mutDispatchTicket({ data: { orderNumbers: [order.orderNumber] } });
  }, [mutDispatchTicket]);

  const handleMultiDispatch = useCallback(() => {
    if (!selectedOrders.length) {
      return;
    }
    const orderNumbers = selectedOrders.map((ord) => ord.orderNumber!);
    mutDispatchTicket({ data: { orderNumbers } });
  }, [mutDispatchTicket, selectedOrders]);

  const handleCloseFocus = useCallback(() => {
    setFocusedOrder(null);
  }, []);

  useEffect(() => {
    if (filter) {
      headerMutator.setTitle(`Scheduling - Search for "${filter}"`);

      return;
    }
    let dateStr = '';
    if (minDate && maxDate) {
      dateStr = minDate === maxDate
        ? `on ${parseDateNumeric(minDate)} (${groupingDateType})`
        : ` from ${parseDateNumeric(minDate)} to ${parseDateNumeric(maxDate)} (${groupingDateType})`;
    }

    headerMutator.setTitle(`Scheduling - ${category} ${dateStr}`);
  }, [
    headerMutator, filter, minDate, maxDate, category, groupingDateType,
  ]);

  useEffect(() => {
    if (mutDispatchIsError) {
      toast.error('An error occurred while dispatching orders.');
      setSelectedOrders([]);
      refetch();
    }
    if (mutDispatchIsSuccess) {
      toast.info(mutDispatchResult?.message);
      setSelectedOrders([]);
      refetch();
    }
  }, [mutDispatchIsError, mutDispatchIsSuccess, mutDispatchResult?.message, refetch]);

  return (
    <div className='container'>
      <div className='scheduling__container'>
        <div className='table-options w-200'>
          <div className='text-field margin-left'>
            <Button
              type={'primary'}
              text={`Dispatch ${selectedOrders.length} Order${selectedOrders.length === 1 ? '' : 's'}`}
              onClick={handleMultiDispatch}
              disabled={!selectedOrders.length}
            />
          </div>
        </div>
      </div>
      <div className='master-detail'>
        <div className='lockerManager__master'>
          <div className='sheet'>
            <OrdersTable
              data={data?.items ?? []}
              onFetchData={handleFetchData}
              columns={columns}

              hasNextPage={data?.hasNextPage}
              hasPreviousPage={data?.hasPreviousPage}
              totalPages={data?.totalPages}
              getTrProps={handleFocusOrder}
              pageSizeOptions={[
                10,
                25,
                50,
                100,
                200,
                500,
              ]}

              selectable={true}
              selectedData={selectedOrders}
              onSelectBulkAction={handleSelectAll}
              updateSelection={handleSelect}
              selectPredicateOrKey={'orderNumber'}
              isPageSelected={allAreSelected}
              isSelectingItemDisabled={isSelectingItemDisabled}
            />
          </div>
        </div>
        {focusedOrder && (
          <BatchWarehouseSchedulingDetails
            order={focusedOrder}
            onDispatch={handleSingleDispatch}
            onClose={handleCloseFocus}
          />
        )}
      </div>
    </div>
  );
};

export default BatchWarehouseSchedulingList;
