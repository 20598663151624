import {
  HagPrintRoomJobPagedListDto,
} from '@api/fulfillment/models';
import Modal from '@components/shared/Modal/Modal';
import ModalButtons from '@components/shared/Modal/ModalButtons';
import Button from '@sharedComponents/Buttons/Button';
import React, {
  useCallback, useState,
} from 'react';

interface OwnProps {
  items: Array<HagPrintRoomJobPagedListDto>;
  handleStatusUpdate: (ids: number[]) => void;
  linkText: string;
  warnUserTitle?: string;
  warnUserContent?: string;
}

type Props = OwnProps;

const UpdateBulkClaimedBy = React.memo<Props>(({
  items,
  handleStatusUpdate,
  linkText,
  warnUserTitle,
  warnUserContent,
}) => {
  const [
    isOpen,
    setOpen,
  ] = useState(false);

  const handleUpdate = useCallback(() => {
    const jobIds: number[] = items.map((selectedItem) => selectedItem.hagPrintRoomJobId!);
    setOpen(false);
    handleStatusUpdate(jobIds);
  }, [
    items,
    handleStatusUpdate,
  ]);

  const handleClick = useCallback((e?: React.BaseSyntheticEvent) => {
    e?.preventDefault?.();
    if (warnUserContent) {
      setOpen(true);
    } else {
      handleUpdate();
    }
  }, [
    handleUpdate,
    warnUserContent,
  ]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <>
      <Button
        type={'secondary'}
        text={linkText}
        onClick={handleClick}
        size={'s'}
        classes={'actions-row__action--button ml-20'}
      />
      <Modal
        title={warnUserTitle}
        isOpen={isOpen}
        closeModal={handleClose}
        modalSize={'l'}
        buttons={(
          <ModalButtons
            confirmBtnText={'Confirm'}
            cancelBtnText={'Cancel'}
            onConfirm={handleUpdate}
            onClose={handleClose}
          />
        )}
      >
        <span>{warnUserContent}</span>
      </Modal>
    </>
  );
});

export default UpdateBulkClaimedBy;
