import React, { PureComponent } from 'react';
import { minInputLengthForSuggestions } from '@constants/values';
import OutsideClickWrapper from '@sharedComponents/OutsideClickWrapper';
import Icon from '@sharedComponents/Icons/Icon';
import FormError from '@sharedComponents/Form/FormError';

interface OwnProps {
  callback: (searchInput: string) => void;
  placeholder?: string;
  size?: string;
  classes?: string;
  hasSearchIcon?: boolean;
  error?: any;
  disabled?: boolean;
  keyValue?: number;
  isDropdownFixed?: boolean;
  initialValue?: string;
}

type Props = OwnProps;

interface State {
  isInputActive: boolean;
  searchInput: string;
  throttledCall: Nullable<ReturnType<typeof setTimeout>>;
  value: string;
}

class SearchInputWithCallback extends PureComponent<Props, State> {
  state = {
    isInputActive: false,
    searchInput: this.props.initialValue ?? '',
    throttledCall: null,
  } as State;

  searchInput: any;

  setSearchInputRef = (r: any) => {
    this.searchInput = r;
  };

  componentWillUnmount() {
    const { throttledCall } = this.state;
    if (throttledCall) {
      clearTimeout(throttledCall);
    }
    this.setState(() => ({ throttledCall: null }));
  }

  onInputFocus = () => {
    this.setState(() => ({ isInputActive: true }));
  };

  onInputBlur = () => {
    this.setState(() => ({ isInputActive: false }));
  };

  onInputChange = () => {
    const { searchInput } = this.state;
    const newSearchInput = this.searchInput.value ? this.searchInput.value.trim() : '';
    const searchInputHasChanged = searchInput !== newSearchInput;

    this.setState(() => ({ searchInput: newSearchInput }));
    const { throttledCall } = this.state;

    if (throttledCall) {
      clearTimeout(throttledCall);
    }

    if (newSearchInput.length < minInputLengthForSuggestions) {
      this.setState(() => ({ throttledCall: null }));

      return;
    }

    if (searchInputHasChanged) {
      const newThrottle = setTimeout(this.handleCallback, 300);
      this.setState(() => ({ throttledCall: newThrottle }));
    }
  };

  handleCallback = async () => {
    const { callback } = this.props;
    const { throttledCall } = this.state;

    const newSearchInput = this.searchInput.value ? this.searchInput.value.trim() : '';

    await callback(newSearchInput);

    if (throttledCall) {
      clearTimeout(throttledCall);
    }
  };

  render() {
    const {
      classes = '',
      hasSearchIcon = false,
      error = null,
      disabled = false,
      keyValue = 0,
      initialValue,
    } = this.props;

    const { isInputActive } = this.state;

    const inputContent = (
      <div className='w-100'>
        <div className='text-field mb-0'>
          <OutsideClickWrapper onClick={this.onInputBlur}>
            <>
              <input
                className={`w-100 ${hasSearchIcon ? 'no-border-bottom' : ''}`}
                type='text'
                ref={this.setSearchInputRef}
                onChange={this.onInputChange}
                onFocus={this.onInputFocus}
                defaultValue={initialValue}
                disabled={disabled}
                 
                // keyValue is to force re-rendering of component in case defaultValue is externally changed after initial render.
                key={`searchInputWithValidation:${keyValue}`}
              />
            </>
          </OutsideClickWrapper>
        </div>
        <FormError
          error={error}
          enableMultiple={true}
          classes={'lockerManagerEdit__custom-items--decoration__error'}
        />
      </div>
    );

    if (hasSearchIcon) {
      return (
        <div className={`chips-search has-icon ${isInputActive ? 'active' : ''} ${classes}`}>
          <div className='mb-5'>
            <Icon materialIcon={'search'} />
          </div>
          {inputContent}
        </div>
      );
    }

    return inputContent;
  }
}

export default SearchInputWithCallback;
