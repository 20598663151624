import {
  cancelItemReasons,
  featureFlags,
  refundItemReasons,
} from '@constants/common';
import { refundReasonEnum } from '@constants/enums/refundEnums';
import { refundOrderItemForm } from '@constants/reduxForms';
import { validateOrderItemRefund } from '@redux/orderManagement/validations';
import {
  mapListIds, mapQuantityToOptions,
} from '@util/mappingHelper';
import { ObjectWithId } from '@models/common/ObjectWithId';
import {
  formatDollarAmount,
  parseNumber,
  toLocaleString,
} from '@util/numberHelpers';
import {
  createOptionsList,
  generalOptions,
} from '@util/optionsMap';
import React, {
  useCallback,
  useEffect, useState,
} from 'react';
import {
  connect,
  ConnectedProps,
} from 'react-redux';
import {
  Field,
  formValueSelector,
  InjectedFormProps,
  reduxForm,
} from 'redux-form';
import { RootState } from 'store';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import Modal from '@sharedComponents/Modal/Modal';
import Select from '@sharedComponents/Form/Select';
import RadioGroup from '@sharedComponents/Form/RadioGroup';
import Textarea from '@sharedComponents/Form/Textarea';
import Input from '@sharedComponents/Form/Input';
import OrderManagementOrderRefundItem from './OrderManagementOrderRefundItem';
import { accountingActionTypeEnum } from '@constants/enums/orderEnums';
import { canRefundOrderItem } from '@util/paymentHelper';
import { RefundOrderItemFormData } from '@models/forms/OrderManagement/RefundOrderItemFormData';
import { OrderPricingDetailsDto } from '@api/orders/models';
import { OrderItemDetailsDto } from '@api/fulfillment/models';

interface InitialValues {
  quantity: number;
  actionType: string;
}

interface QuantityOptions {
  key: number;
  value: number;
  name: number;
}

interface OwnProps {
  orderItem: OrderItemDetailsDto;
  isOpen: boolean;
  closeModal: () => void;
  handleSubmit: () => void;
  isCancelAction: boolean;
  canRefundSubtotal: boolean;
  initialValues: InitialValues;
  actionType: string;
  quantity: number;
  coreOrderPricingDetails: OrderPricingDetailsDto | undefined;
  orderExistsOnCore: boolean;
}

const selector = formValueSelector(refundOrderItemForm);
const mapStateToProps = (state: RootState) => ({
  actionType: selector(state, 'actionType'),
  quantity: selector(state, 'quantity'),
});

const connector = connect(mapStateToProps);

type FormProps = OwnProps & ConnectedProps<typeof connector>;

type Props = FormProps &
  InjectedFormProps<RefundOrderItemFormData, FormProps, string[]>;

const OrderManagementOrderItemRefundModal = React.memo<Props>(({
  orderItem,
  isOpen,
  closeModal,
  handleSubmit,
  isCancelAction,
  canRefundSubtotal,
  initialValues,
  actionType,
  quantity,
  coreOrderPricingDetails,
  orderExistsOnCore,
}) => {
  const [
    quantityOptions,
    setQuantityOptions,
  ] = useState<Array<QuantityOptions>>([]);
  const [
    otherReasonSelected,
    setOtherReasonSelected,
  ] = useState<boolean>(false);

  const handleChangeReason = useCallback((e) => {
    const otherReason = e.target.value === refundReasonEnum.Other;
    setOtherReasonSelected(otherReason);
  }, []);

  useEffect(() => {
    if (orderItem) {
      const quants = mapQuantityToOptions(orderItem.quantity!);
      setQuantityOptions(quants);
    } else {
      setQuantityOptions([]);
    }
  }, [
    orderItem,
    orderItem?.quantity,
  ]);

  const allRefundReasons = isCancelAction
    ? mapListIds([
      ...refundItemReasons,
      ...cancelItemReasons,
    ])
    : mapListIds([...refundItemReasons]);

  const refundReasonsOptions = createOptionsList({
    // @ts-expect-error - TS doesn't like the type of allRefundReasons
    list: allRefundReasons as ObjectWithId[],
    key: 'id',
    value: 'reason',
    name: 'reason',
    emptyOption: {
      disabled: true,
      name: 'Choose Reason',
    },
  });

  const label = isCancelAction ? 'Cancel' : 'Refund';
  const quantityLabel = orderExistsOnCore ? 'Cancel Quantity:' : 'Quantity';

  const fullRefundPrice = useCallback(() => (orderItem
    ? coreOrderPricingDetails?.orderItemsPricingDetails && orderExistsOnCore
      ? coreOrderPricingDetails.orderItemsPricingDetails[orderItem.coreId!]!.unitPricePaid! * quantity
      : quantity
        ? (toLocaleString(orderItem.pricePaid!) as unknown as number) * quantity
        : (toLocaleString(orderItem.pricePaid!) as unknown as number) * initialValues.quantity
    : 0), [
    coreOrderPricingDetails,
    initialValues.quantity,
    orderItem,
    quantity,
    orderExistsOnCore,
  ]);

  const quantityOptionsMapped = () => generalOptions(quantityOptions);

  return (
    <Modal
      isOpen={isOpen}
      closeModal={closeModal}
      title={`${label} order items?`}
      modalHeight={'l'}
      modalWidth={'s'}
      buttons={(
        <ModalButtons
          confirmBtnText={`${label} Order Items`}
          cancelBtnText={'Cancel'}
          onClose={closeModal}
          formSubmission={true}
          isDangerousAction={true}
          formId={refundOrderItemForm}
        />
      )}
    >
      {
        <div>
          {
            isOpen &&
            <div className='mt-20'>
              <div className='refund__items'>
                <OrderManagementOrderRefundItem
                  item={orderItem}
                  coreItemPricing={
                    (orderItem && coreOrderPricingDetails?.orderItemsPricingDetails?.[orderItem.coreId!])
                    ?? undefined}
                />
              </div>
              <form
                className='refund__color-river-bed filter-group w-100 pr-10'
                id={refundOrderItemForm}
                onSubmit={handleSubmit}
              >
                <Field
                  name={'reason'}
                  component={Select}
                  onChange={handleChangeReason}
                >
                  {refundReasonsOptions}
                </Field>
                {
                  (!orderExistsOnCore || isCancelAction) &&
                  <div className='flex__row w-100 align__baseline'>
                    {quantityLabel}
                    <div className='select-field m-l-24 w-100'>
                      <Field
                        name={'quantity'}
                        className={'w-100'}
                        component={Select}
                        parse={parseNumber}
                      >
                        {quantityOptionsMapped()}
                      </Field>
                    </div>
                  </div>
                }
                {
                  otherReasonSelected &&
                  <Field
                    name={'reasonOther'}
                    placeholder={'Enter other reason'}
                    component={Textarea as any}
                    type={'text'}
                  />
                }
                <Field
                  name={'actionType'}
                  component={RadioGroup as any}
                  groupClassName={'radio__group radio__gray-02 m__w--540'}
                  radioItems={[
                    {
                      value: accountingActionTypeEnum.NoChange,
                      label: 'No Refund',
                      disabled: !isCancelAction,
                    },
                    {
                      value: accountingActionTypeEnum.OrderItemFullRefund,
                      disabled: !canRefundOrderItem(
                        !canRefundSubtotal,
                        orderItem?.isCompleteRefund ?? false,
                        !!orderItem?.voucherId
                      )
                      || !featureFlags.orderItemRefundEnabled,
                      label: `Full Refund (${formatDollarAmount(fullRefundPrice()!)})`,
                    },
                    {
                      value: accountingActionTypeEnum.OrderItemPartialRefund,
                      className: 'with-input mt-20',
                      rootClassName: 'with-input__radio',
                      hasInput: true,
                      disabled: !canRefundOrderItem(
                        !canRefundSubtotal,
                        orderItem?.isCompleteRefund ?? false,
                        !!orderItem?.voucherId
                      )
                      || !featureFlags.orderItemRefundEnabled,
                      inputNode: (
                        <div className='with-input__label'>
                          <div className='text-field'>
                            <Field
                              name={'amount'}
                              component={Input}
                              type={'number'}
                              parse={parseNumber}
                              placeholder={'Partial Refund'}
                              disabled={actionType !== accountingActionTypeEnum.OrderItemPartialRefund}
                            />
                          </div>
                        </div>
                      ),
                    },
                  ]}
                />
              </form>
            </div>
          }
        </div>
      }
    </Modal>
  );
});

export default connector(reduxForm<RefundOrderItemFormData, any, Props>({
  form: refundOrderItemForm,
  validate: validateOrderItemRefund,
  shouldError: () => true,
  enableReinitialize: true,
})(OrderManagementOrderItemRefundModal));
