/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */
import type { PtlWallRowStatusEnum } from './ptlWallRowStatusEnum';
import type { PtlWallRowPositionDto } from './ptlWallRowPositionDto';

export interface PtlWallRowDto {
  id?: number;
  uuid?: string | null;
  created?: string | null;
  updated?: string | null;
  deviceId?: string | null;
  sortOrder?: number;
  status?: PtlWallRowStatusEnum;
  ptlWallRowPositions?: PtlWallRowPositionDto[] | null;
}
