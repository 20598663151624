import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { reset } from 'redux-form';
import {
  addCategory,
  editCategory,
  editCategories,
  deleteCategory,
} from '@APICalls/category/actions';
import { keyNameEnum } from '@constants/enums/commonEnums';
import { categoryForm } from '@constants/reduxForms';
import { getCategories } from '@redux/productCatalog/actions';
import { parseDateTimeNumeric } from '@util/dateHandler';
import { materialSwal } from '@util/componentHelper';
import Button from '@sharedComponents/Buttons/Button';
import Table from '@sharedComponents/Table/Table';
import SearchFilter from '@sharedComponents/Inputs/SearchFilter';
import CategoryAddModal from './CategoryModals/CategoryAddModal';
import CategoryEditModal from './CategoryModals/CategoryEditModal';
import CategoryDeleteModal from './CategoryModals/CategoryDeleteModal';
import CategoriesReorderModal from './CategoryModals/CategoriesReorderModal';
import CategoriesActionsColumn from './CategoriesActionsColumn';

const CategoriesTable = Table();

class Categories extends Component {
  state = {
    addCategoryModalIsOpened: false,
    editCategoryModalIsOpened: false,
    deleteCategoryModalIsOpened: false,
    reorderCategoriesModalIsOpened: false,
    selectedCategory: null,
    filteredData: this.props.queue,
  };

  componentDidMount() {
    this.fetchCategories();
  }

  componentDidUpdate(oldProps) {
    const { queue } = this.props;

    if (oldProps.queue !== queue) {
      this.setState({ filteredData: this.filterData() });
    }
  }

  getColumns = () => [
    {
      Header: 'ID',
      accessor: 'id',
      minWidth: 20,
      sortable: true,
      Cell: (cellProps) => cellProps.value && <span>{cellProps.value}</span>,
    },
    {
      Header: 'Created',
      accessor: 'created',
      minWidth: 100,
      Cell: (cellProps) => cellProps.value && <div>{parseDateTimeNumeric(cellProps.value)}</div>,
    },
    {
      Header: 'Updated',
      accessor: 'updated',
      minWidth: 100,
      Cell: (cellProps) => cellProps.value && <div>{parseDateTimeNumeric(cellProps.value)}</div>,
    },
    {
      Header: 'Code',
      accessor: 'code',
      minWidth: 100,
      Cell: (cellProps) => cellProps.value && <span>{cellProps.value}</span>,
    },
    {
      Header: 'Name',
      accessor: 'name',
      minWidth: 100,
      Cell: (cellProps) => cellProps.value && <span>{cellProps.value}</span>,
    },
    {
      Header: '',
      accessor: '',
      minWidth: 40,
      resizable: false,
      style: { overflow: 'visible' },
      Cell: (cellProps) => (
        <CategoriesActionsColumn
          category={cellProps.value}
          onCategoryEdit={this.openEditCategoryModal}
          onCategoryDelete={this.openDeleteCategoryModal}
        />
      ),
    },
  ];

  fetchCategories = () => {
    const { dispatch } = this.props;

    dispatch(getCategories());
  };

  filterData = (searchInput) => {
    const search = (searchInput || '').toLowerCase();
    const { queue } = this.props;

    if (search === '') {
      return queue;
    }

    return queue.filter((category) => category.name.toLowerCase().includes(search));
  };

  clearSearch = () => {
    this.setState({ filteredData: this.filterData('') });
  };

  onSearch = (e) => {
    if (e.key && e.key !== keyNameEnum.Enter) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();

    const searchInput = e.target.value;

    this.setState(() => ({ filteredData: this.filterData(searchInput) }));
  };

  addCategory = async (form) => {
    const result = await addCategory(form);

    if (result?.success) {
      materialSwal('Success', result.message, 'success');
      this.closeAddCategoryModal();
      this.fetchCategories();
    }
  };

  editCategory = async (form) => {
    const result = await editCategory(form);

    if (result?.success) {
      materialSwal('Success', result.message, 'success');
      this.closeEditCategoryModal();
      this.fetchCategories();
    }
  };

  deleteCategory = async (categoryId) => {
    const result = await deleteCategory(categoryId);

    if (result?.success) {
      materialSwal('Success', result.message, 'success');
      this.closeDeleteCategoryModal();
      this.fetchCategories();
    }
  };

  saveCategoriesOrder = async (newCategories) => {
    this.closeReorderCategoriesModal();

    const oldCategories = [...this.props.queue];
    const categoriesToUpdate = [];

    for (let i = 0; i < newCategories.length; i++) {
      const category = newCategories[i];
      const correctSortOrder = i + 1;

      if (category.id !== oldCategories[i].id || category.sortOrder !== correctSortOrder) {
        category.sortOrder = correctSortOrder;
        categoriesToUpdate.push(category);
      }
    }

    await editCategories(categoriesToUpdate);
    this.fetchCategories();
  };

  openAddCategoryModal = () => {
    this.setState({ addCategoryModalIsOpened: true });
  };

  closeAddCategoryModal = () => {
    const { dispatch } = this.props;

    dispatch(reset(categoryForm));
    this.setState({ addCategoryModalIsOpened: false });
  };

  openEditCategoryModal = (category) => {
    this.setState({
      selectedCategory: category,
      editCategoryModalIsOpened: true,
    });
  };

  closeEditCategoryModal = () => {
    const { dispatch } = this.props;

    dispatch(reset(categoryForm));
    this.setState({
      editCategoryModalIsOpened: false,
      selectedCategory: null,
    });
  };

  openDeleteCategoryModal = (category) => {
    this.setState({
      selectedCategory: category,
      deleteCategoryModalIsOpened: true,
    });
  };

  closeDeleteCategoryModal = () => {
    this.setState({
      deleteCategoryModalIsOpened: false,
      selectedCategory: null,
    });
  };

  openReorderCategoriesModal = () => {
    this.setState({ reorderCategoriesModalIsOpened: true });
  };

  closeReorderCategoriesModal = () => {
    this.setState({ reorderCategoriesModalIsOpened: false });
  };

  render() {
    const {
      addCategoryModalIsOpened,
      editCategoryModalIsOpened,
      deleteCategoryModalIsOpened,
      reorderCategoriesModalIsOpened,
      selectedCategory,
      filteredData,
    } = this.state;
    const { queue } = this.props;
    const initialValues = !selectedCategory ? {} : selectedCategory;

    return (
      <div className='container'>
        <CategoryAddModal
          isOpen={addCategoryModalIsOpened}
          closeModal={this.closeAddCategoryModal}
          onSubmit={this.addCategory}
        />

        <CategoryEditModal
          isOpen={editCategoryModalIsOpened}
          closeModal={this.closeEditCategoryModal}
          onSubmit={this.editCategory}
          initialValues={initialValues}
        />

        <CategoryDeleteModal
          isOpen={deleteCategoryModalIsOpened}
          closeModal={this.closeDeleteCategoryModal}
          deleteCategory={this.deleteCategory}
          category={initialValues}
        />

        <CategoriesReorderModal
          isOpen={reorderCategoriesModalIsOpened}
          categories={queue}
          save={this.saveCategoriesOrder}
          closeModal={this.closeReorderCategoriesModal}
        />

        <div className='table-options w-100'>
          <div className='flex'>
            <SearchFilter
              search={this.onSearch}
              clearSearch={this.clearSearch}
            />
          </div>
          <div className='flex'>
            <Button
              type={'secondary'}
              text={'Reorder Categories'}
              onClick={this.openReorderCategoriesModal}
              classes={'colors__add-btn'}
            />
            <Button
              type={'primary'}
              text={'Add Category'}
              onClick={this.openAddCategoryModal}
              classes={'colors__add-btn ml-20'}
            />
          </div>
        </div>

        <div className='sheet'>
          <CategoriesTable
            data={filteredData}
            columns={this.getColumns()}
            showPagination={false}
          />
        </div>
      </div>
    );
  }
}

Categories.propTypes = {
  queue: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const mapStateToProps = ({ productCatalog }) => ({
  queue: productCatalog.categories,
});

export default connect(mapStateToProps)(Categories);
