import React, {
  FC,
  useEffect,
  useState,
  useCallback,
} from 'react';
import {
  OrderWarehouseStatusEnum,
  WarehouseOrderPagedListDto,
  SortDirectionEnum,
  WarehouseSchedulingQueriesSortByEnum,
  GetHomefieldApiWarehouseschedulingOrdersParams as OrdersParams,
  OrderInventoryStatusEnum,
} from '@api/fulfillment/models';
import {
  getGetHomefieldApiWarehouseschedulingOrdersQueryKey as getAllOrdersQueryKey,
  getGetHomefieldApiWarehouseschedulingOrdersOrderNumberQueryKey as getOrderNumberQueryKey,
  putHomefieldApiWarehouseschedulingOrdersmovetopicking as moveOrderToPicking,
  useGetHomefieldApiWarehouseschedulingOrders,
  usePutHomefieldApiWarehouseschedulingOrdersmovetodispatch,
  usePutHomefieldApiWarehouseschedulingOrdersmovetopicking,
} from '@api/fulfillment/warehouse-scheduling';
import WarehouseSchedulingDetails from './WarehouseSchedulingDetails/WarehouseSchedulingDetails';
import {
  keyNameEnum,
  sortDirectionEnum,
} from '@constants/enums/commonEnums';
import {
  getPagingParamsFromTable,
  getSortParamsFromTable,
} from '@util/tableHelpers';
import {
  getSelectableTableRowProps,
  selectAllItems,
} from '@util/selectionHelpers';
import { toast } from 'react-toastify';
import { getAllPartners } from '@api/squadlockerServices/locker-manager';
import Dropdown from '@sharedComponents/Inputs/Dropdowns/Dropdown/Dropdown';
import MultiSelectDropdown from '@sharedComponents/Inputs/Dropdowns/MultiSelectDropdown/MultiSelectDropdown';
import {
  schedulingDecorationMethods,
  inventoryStatuses,
} from '@constants/common';
import DateInput from '@sharedComponents/Inputs/DateInput';
import SearchFilter from '@sharedComponents/Inputs/SearchFilter';
import Button from '@sharedComponents/Buttons/Button';
import ProgressModal, { ProgressModalOnCompleteCallbackArgType } from '@sharedComponents/Modal/ProgressModal';
import WarehouseSchedulingTable from './WarehouseSchedulingTable';
import { useQueryClient } from 'react-query';
import { DecorationMethodEnum } from '@api/productCatalog/models';
import { ApiResponse } from '@models/common/ApiResponse';
import { materialSwal } from '@util/componentHelper';

const defaultOrderStatus = OrderWarehouseStatusEnum.AwaitingInventory;
const gpErrorMessage = 'Error occurred when dispatching items' as const;
const elipsis = '...' as const;

export const WarehouseScheduling: FC = React.memo(() => {
  // Local States and Callbacks
  const [
    focusedOrder,
    setFocusedOrder,
  ] = useState(null as WarehouseOrderPagedListDto | null);

  const [partnerOptions, setPartnerData] = useState<any>([]);

  const fetchPartners = async () => {
    try {
      const response = await getAllPartners();
      if (response) {
        setPartnerData(response);
      } else {
        console.error('Failed to fetch partner data');
      }
    } catch (error) {
      console.error('An error occurred while fetching partner data', error);
    }
  };

  const [
    selectedOrders,
    setSelectedOrders,
  ] = useState([] as WarehouseOrderPagedListDto[]);

  const [
    ordersParams,
    setOrdersParams,
  ] = useState({
    pageNumber: 1,
    pageSize: 10,
    orderWarehouseStatus: defaultOrderStatus,
  } as OrdersParams);

  const [
    manyToPrint,
    setManyToPrint,
  ] = useState<number[]>([]);

  const clearSelections = useCallback(() => {
    setFocusedOrder(null);
    setSelectedOrders([]);
  }, []);

  // Details operations
  const closeDetails = useCallback(() => setFocusedOrder(null), []);

  const getTrProps = useCallback((_: unknown, rowInfo: any) => getSelectableTableRowProps(
    (selected: WarehouseOrderPagedListDto) => setFocusedOrder(selected), rowInfo, focusedOrder, 'orderNumber'
  ),
  [focusedOrder]);

  // Data states
  const queryClient = useQueryClient();

  const resetQueryClient = useCallback(() => {
    queryClient.invalidateQueries(getAllOrdersQueryKey());
    for (const order of selectedOrders) {
      if (order?.orderNumber) {
        queryClient.invalidateQueries(
          getOrderNumberQueryKey(order.orderNumber)
        );
      }
    }
  }, [
    queryClient,
    selectedOrders,
  ]);

  const fetchData = useCallback((_: unknown, instance: any) => {
    clearSelections();
    const {
      page: instPageNumber,
      pageSize: instPageSize,
    } = getPagingParamsFromTable(instance);

    const {
      sortColumn,
      sortDirection,
    } = getSortParamsFromTable(instance, sortDirectionEnum);

    setOrdersParams({
      ...ordersParams,
      pageNumber: instPageNumber + 1,
      pageSize: instPageSize,
      sortBy: sortColumn as WarehouseSchedulingQueriesSortByEnum,
      sortDirection: sortDirection as SortDirectionEnum,
    });
  }, [
    ordersParams,
    clearSelections,
  ]);

  const { data: queryOrders } = useGetHomefieldApiWarehouseschedulingOrders(ordersParams, { query: { enabled: true } });

  // Bulk Print Progress Modal
  const onPrintByOrderNumber =
    useCallback((orderNumber: number) => moveOrderToPicking({ orderNumbers: [orderNumber] }, {
      isBlockingRequest: false,
      showErrorModal: false,
    }), []);

  const onPrintsCompletedOrCanceled = useCallback(
    (res?: ProgressModalOnCompleteCallbackArgType<number, ApiResponse>) => {
      setManyToPrint([]);
      if (res) {
        if (res.successes < res.total) {
          const errs = [];
          for (const result of res.results) {
            if (!result.success) {
              const msgLen = gpErrorMessage.length - elipsis.length;
              let message = result.response?.substring(0, msgLen);
              if (result.response?.includes(gpErrorMessage)) {
                message = gpErrorMessage;
              } else if (result.response?.length > msgLen) {
                message += elipsis;
              }
              errs.push(`O${result.request}: ${message}`);
            }
          }
          const errMsg = `${res.total - res.successes} out of ${res.total} of the prints failed!\n${errs.join('\n')}`;
          materialSwal('Dispatch Failures', errMsg, 'error');
        } else {
          materialSwal('Dispatch Successful', `${res.total} dispatches succeeded`, 'success');
        }
      }
      resetQueryClient();
      clearSelections();
    }, [
      clearSelections,
      resetQueryClient,
    ]
  );

  // Single Order Operations (React Query)
  const {
    mutate: mutPrintTicket,
    data: mutPrintResult,
    isLoading: mutPrintIsLoading,
    isError: mutPrintIsError,
    isSuccess: mutPrintIsSuccess,
  } = usePutHomefieldApiWarehouseschedulingOrdersmovetopicking({
    mutation: {
      onSuccess: () => {
        resetQueryClient();
      },
    },
  });

  const {
    mutate: mutOnHoldToDispatch,
    data: mutOnHoldToDispatchResult,
    isLoading: mutOnHoldToDispatchIsLoading,
    isError: mutOnHoldToDispatchIsError,
    isSuccess: mutOnHoldToDispatchIsSuccess,
  } = usePutHomefieldApiWarehouseschedulingOrdersmovetodispatch({
    mutation: {
      onSuccess: () => {
        resetQueryClient();
      },
    },
  });

  // Single Order Operations (Event Handlers)
  const onPrintTicketQueue = useCallback(() => {
    const orderNumbers = [...selectedOrders]
      .map((x) => x.orderNumber!)
      .filter((x) => x)
      .sort();

    setManyToPrint(orderNumbers);
    clearSelections();
  }, [
    selectedOrders,
    clearSelections,
  ]);

  const onSendToDispatch = useCallback(() => {
    const orderNumbers = selectedOrders.map((x) => x.orderNumber!).filter((x) => x);
    mutOnHoldToDispatch({
      data: {
        orderNumbers,
      },
    });
    clearSelections();
  }, [
    mutOnHoldToDispatch,
    selectedOrders,
    clearSelections,
  ]);

  const onSelect = useCallback((ord: WarehouseOrderPagedListDto[]) => {
    setSelectedOrders([...ord]);
  }, []);

  const onPrintNow = useCallback((ord: WarehouseOrderPagedListDto) => {
    if (!ord.orderNumber) {
      return;
    }
    const orderNumbers = [ord.orderNumber!];
    mutPrintTicket({
      data: {
        orderNumbers,
      },
    });
  }, [mutPrintTicket]);

  useEffect(() => {
    fetchPartners();
  }, []);

  // React query status handling
  useEffect(() => {
    if (mutPrintIsError) {
      toast.error('Error occurred when dispatching items');
      clearSelections();
    }
    if (mutPrintIsSuccess) {
      toast.info(mutPrintResult?.message);
      clearSelections();
    }
  }, [
    mutPrintIsError,
    mutPrintIsSuccess,
    mutPrintIsLoading,
    mutPrintResult?.message,
    clearSelections,
  ]);

  useEffect(() => {
    if (mutOnHoldToDispatchIsError) {
      toast.error('Error occurred when dispatching items');
      clearSelections();
    }
    if (mutOnHoldToDispatchIsSuccess) {
      toast.info(mutOnHoldToDispatchResult?.message);
      clearSelections();
    }
  }, [
    mutOnHoldToDispatchIsError,
    mutOnHoldToDispatchIsSuccess,
    mutOnHoldToDispatchIsLoading,
    mutOnHoldToDispatchResult?.message,
    clearSelections,
  ]);

  // Setting table parameters
  const onSetTableParameter = useCallback(<T extends keyof OrdersParams>(key: T, value: OrdersParams[T]) => {
    clearSelections();
    setOrdersParams({
      ...ordersParams,
      [key]: value,
    });
  }, [
    clearSelections,
    ordersParams,
  ]);

  const clearSearch = useCallback(() => {
    setOrdersParams({
      ...ordersParams,
      filter: undefined,
    });
    clearSelections();
  }, [
    ordersParams,
    clearSelections,
  ]);

  const onSearch = useCallback((e: any) => {
    if (e.key && e.key !== keyNameEnum.Enter) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();

    const filter = e.target.value;
    clearSelections();
    setOrdersParams({
      ...ordersParams,
      filter,
    });
  }, [
    clearSelections,
    ordersParams,
  ]);

  const onSetMinShipDate = useCallback(
    (value) => onSetTableParameter('minShipDate', value?.toISOString() || undefined),
    [onSetTableParameter]
  );

  const onSetMaxShipDate = useCallback(
    (value) => onSetTableParameter('maxShipDate', value?.toISOString() || undefined),
    [onSetTableParameter]
  );

  const onSetDecorationMethod = useCallback(
    (value: string) => onSetTableParameter('decorationMethod', value.split(',') as DecorationMethodEnum[]),
    [onSetTableParameter]
  );

  const onSetInventoryStatus = useCallback(
    (value: OrderInventoryStatusEnum | '') => onSetTableParameter('inventoryStatus', value || undefined),
    [onSetTableParameter]
  );

  const onSetStatusAwaitingInventory = useCallback(
    () => onSetTableParameter('orderWarehouseStatus', OrderWarehouseStatusEnum.AwaitingInventory),
    [onSetTableParameter]
  );

  const onSetStatusAwaitingDispatch = useCallback(
    () => onSetTableParameter('orderWarehouseStatus', OrderWarehouseStatusEnum.AwaitingDispatch),
    [onSetTableParameter]
  );

  const onSetPartner = useCallback(
    (value: any) => {
      const commaSeparatedPartnerValue = value.join(', ');
      onSetTableParameter('partnerName', commaSeparatedPartnerValue);
    },
    [onSetTableParameter]
  );

  const onSetStatusInPicking = useCallback(
    () => onSetTableParameter('orderWarehouseStatus', OrderWarehouseStatusEnum.InPicking),
    [onSetTableParameter]
  );

  // Record handlers
  const isSelectingItemDisabled = useCallback((order?: WarehouseOrderPagedListDto): boolean => {
    const oStatus = ordersParams?.orderWarehouseStatus;
    if (oStatus === OrderWarehouseStatusEnum.AwaitingInventory) {
      return (order?.qtyAvailableAwaitingInventory ?? 0) <= 0;
    }

    return false;
  }, [ordersParams?.orderWarehouseStatus]);
  const allAreSelected = !!queryOrders?.items?.length
    && queryOrders.items.every(
      (x) => isSelectingItemDisabled(x) || selectedOrders.some((y) => x.orderNumber === y.orderNumber)
    );
  const onSelectAll = useCallback(() => {
    if (!queryOrders?.items) return;
    const { newSelectedItems } = selectAllItems(queryOrders.items, selectedOrders, allAreSelected, 'orderNumber');
    setSelectedOrders(newSelectedItems);
  }, [
    allAreSelected,
    queryOrders?.items,
    selectedOrders,
  ]);

  // Render props
  const { orderWarehouseStatus: status } = ordersParams;

  // Final JSX
  return (
    <div className='container'>
      <div className='scheduling__container'>
        <div className='table-options w-100'>
          <div className='flex'>
            <Dropdown
              options={schedulingDecorationMethods}
              onChange={onSetDecorationMethod}
              defaultValue={''}
              classes={'mr-20'}
            />
            <Dropdown
              options={inventoryStatuses}
              onChange={onSetInventoryStatus}
              defaultValue={'' as any}
              classes={'mr-20'}
            />
            <MultiSelectDropdown<any, 'name'>
              objects={partnerOptions}
              itemText={'partners'}
              updateCallback={onSetPartner}
              textKey={'name'}
              valueKey={'name'}
              classNames={'margin-left'}
            />
            <div className='scheduling__datepicker'>
              <DateInput
                value={ordersParams.minShipDate ?? null}
                label={'From ship date'}
                onChange={onSetMinShipDate}
              />
            </div>
            <div className='scheduling__datepicker'>
              <DateInput
                value={ordersParams.maxShipDate ?? null}
                label={'To ship date'}
                onChange={onSetMaxShipDate}
              />
            </div>
          </div>
          <div className='text-field margin-left'>
            <SearchFilter
              search={onSearch}
              clearSearch={clearSearch}
              placeholder={'ID, SOP, LCK, ORG'}
            />
          </div>
        </div>
      </div>
      <div className='scheduling__container'>
        <div className='table-options w-200'>
          <div className='text-field margin-left'>
            <Button
              type={'primary'}
              text={`Send ${selectedOrders.length} Order${selectedOrders.length === 1 ? '' : 's'} to dispatch`}
              onClick={onPrintTicketQueue}
              disabled={!selectedOrders.length || status !== OrderWarehouseStatusEnum.AwaitingInventory}
            />
          </div>
          <div className='text-field margin-left'>
            <Button
              type={'primary'}
              text={`Dispatch ${selectedOrders.length} Order${selectedOrders.length === 1 ? '' : 's'}`}
              onClick={onPrintTicketQueue}
              disabled={!selectedOrders.length || status !== OrderWarehouseStatusEnum.AwaitingDispatch}
            />
          </div>
        </div>
      </div>
      <ul className='tabs tabs--size-l'>
        <li
          onClick={onSetStatusAwaitingInventory}
          className={`tab-item ${status === OrderWarehouseStatusEnum.AwaitingInventory ? 'is-active' : ''} tab-item`}
        >
          Awaiting Inventory
        </li>
        <li
          onClick={onSetStatusAwaitingDispatch}
          className={`tab-item ${status === OrderWarehouseStatusEnum.AwaitingDispatch ? 'is-active' : ''} tab-item`}
        >
          Awaiting Dispatch
        </li>
        <li
          onClick={onSetStatusInPicking}
          className={`tab-item ${status === OrderWarehouseStatusEnum.InPicking ? 'is-active' : ''} tab-item`}
        >
          In Picking
        </li>
      </ul>
      <div className='master-detail'>
        <div className='lockerManager__master'>
          <div className='sheet'>
            <WarehouseSchedulingTable
              status={status!}
              queryData={queryOrders}
              onFetchData={fetchData}
              onFocusedOrder={getTrProps}
              selectedOrders={selectedOrders}
              onSelectAll={onSelectAll}
              onSelect={onSelect}
              isSelectingItemDisabled={isSelectingItemDisabled}
            />
          </div>
        </div>
        {
          focusedOrder &&
          <WarehouseSchedulingDetails
            onClose={closeDetails}
            order={focusedOrder}
            onPrintNow={onPrintNow}
            orderWarehouseStatus={status!}
            onSendToDispatch={onSendToDispatch}
          />
        }
      </div>
      <ProgressModal
        title={'Dispatching...'}
        callback={onPrintByOrderNumber}
        work={manyToPrint}
        cancelable={true}
        sleepMs={200}
        onComplete={onPrintsCompletedOrCanceled}
        onCancel={onPrintsCompletedOrCanceled}
      />
    </div>
  );
});

export default WarehouseScheduling;
