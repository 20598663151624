import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
} from 'react';
import {
  withRouter,
  RouteComponentProps,
} from 'react-router-dom';
import {
  connect,
  ConnectedProps,
} from 'react-redux';
import BarcodeReader from 'react-barcode-reader';
import {
  useGetHomefieldApiProductionassemblynewOrdersheaderssearch,
  useGetHomefieldApiProductionassemblynewOrderitemssearch,
} from '@api/fulfillment/production-assembly-new';
import { useGetHomefieldApiColors } from '@api/productCatalog/colors';
import { ColorDto } from '@api/productCatalog/models';
import { ProductionAssemblyItemGroupDto } from '@api/fulfillment/models';
import { removeItemAssociation } from '@APICalls/devices/actions';
import { decorationTypeEnum } from '@constants/enums/decorationEnums';
import {
  orderAssemblyReviewByBarcodeUrl,
  orderAssemblyReviewByOrderUrl,
  orderAssemblyUrl,
} from '@constants/clientUrls/clientUrls';
import * as productionAssemblyActions from '@redux/productionAssembly/actions';
import * as supportActions from '@redux/support/actions';
import * as productCatalogActions from '@redux/productCatalog/actions';
import { RootState } from '@redux/index/reducers';
import {
  swalAlert,
  navigateToPage,
  materialSwal,
} from '@util/componentHelper';
import BackLink from '@sharedComponents/Navigation/BackLink';
import CheckButton from '@sharedComponents/Inputs/CheckButton';
import SearchFilter from '@sharedComponents/Inputs/SearchFilter';
import OrderHeader from '@sharedComponents/Production/Review/OrderHeader/OrderHeader';
import GoToOrderInput from '@sharedComponents/Production/Review/GoToOrderInput';
import ReviewItems from './ReviewItems/ReviewItems';
import ActivityTracker from '@sharedComponents/Production/Review/ActivityTracker';
import { useGetHomefieldApiOrderassemblyActivitytracker } from '@api/fulfillment/order-assembly';
import { featureFlags } from '@constants/common';

interface RouteProps {
  orderNumber: string;
  barcode?: string;
}

const mapStateToProps = ({
  productionAssembly,
  productCatalog,
}: RootState) => ({
  order: productionAssembly.order,
  logoDecorationLocations: productCatalog.logoDecorationLocations,
  personalizationDecorationLocations: productCatalog.personalizationDecorationLocations,
  decorationLocationArtworkSizesList: productCatalog.decorationLocationArtworkSizesList,
  decorationLocationPersonalizationSizesList: productCatalog.decorationLocationPersonalizationSizesList,
});

const mapDispatchToProps = {
  clearLogos: productionAssemblyActions.clearLogos,
  getDecorationLocationArtworkSizes: productCatalogActions.getDecorationLocationArtworkSizes,
  getDecorationLocations: productCatalogActions.getDecorationLocations,
  getDecorationLocationPersonalizationSizes: productCatalogActions.getDecorationLocationPersonalizationSizes,
  fetchShippingOptions: supportActions.fetchShippingOptions,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectedProps<typeof connector> & RouteComponentProps<RouteProps>;

const OrderAssemblyReview = React.memo<Props>(({
  getDecorationLocations,
  getDecorationLocationPersonalizationSizes,
  getDecorationLocationArtworkSizes,
  clearLogos,
  fetchShippingOptions,
  logoDecorationLocations,
  personalizationDecorationLocations,
  decorationLocationArtworkSizesList,
  decorationLocationPersonalizationSizesList,
  match: {
    params: {
      orderNumber: orderNumberParam,
      barcode: barcodeParam,
    },
  },
}) => {
  const [
    lookupOrderInput,
    setLookupOrderInput,
  ] = useState('');
  const [
    lookupOrderNumber,
    setLookupOrderNumber,
  ] = useState('');
  const [
    isStatusHistoryShown,
    setIsStatusHistoryShown,
  ] = useState(false);
  const [
    barcodeSearch,
    setBarcodeSearch,
  ] = useState<Nullable<string>>(null);
  const [
    filteredItems,
    setFilteredItems,
  ]
    = useState<ProductionAssemblyItemGroupDto[]>([]);
  const [
    isFilteringItems,
    setIsFilteringItems,
  ] = useState<boolean>(false);
  const [
    colorsDictionary,
    setColorsDictionary,
  ] = useState<Record<string, ColorDto>>({});

  const barcodeSearchItemRef = useRef<HTMLDivElement>(null);

  const {
    data: orderHeader,
    refetch: fetchOrderHeader,
  } = useGetHomefieldApiProductionassemblynewOrdersheaderssearch(
    { searchQuery: orderNumberParam || '' }
  );

  const {
    data: orderItemGroups,
    refetch: fetchOrderItemGroups,
  } = useGetHomefieldApiProductionassemblynewOrderitemssearch(
    { searchQuery: orderNumberParam || '' }
  );

  const {
    data: orderToLookup,
    refetch: searchOrder,
  } = useGetHomefieldApiProductionassemblynewOrdersheaderssearch(
    { searchQuery: lookupOrderNumber }
  );

  const {
    data: orderItemsToLookup,
    refetch: searchOrderItems,
  } = useGetHomefieldApiProductionassemblynewOrderitemssearch(
    { searchQuery: lookupOrderNumber || '' }
  );

  const {
    isLoading: isLoadingColors,
    isError: isErrorColors,
    data: colors,
  } = useGetHomefieldApiColors({ query: { enabled: true } });

  useEffect(() => {
    if (!orderNumberParam) return;

    fetchOrderHeader();
    fetchOrderItemGroups();
  }, [
    orderNumberParam,
    fetchOrderHeader,
    fetchOrderItemGroups,
  ]);

  useEffect(() => {
    if (orderItemGroups?.length) {
      setFilteredItems(orderItemGroups);
    }
  }, [orderItemGroups]);

  useEffect(() => {
    const orderNumber: number | null = orderNumberParam ? Number(orderNumberParam) : null;

    if (orderNumber) {
      setIsStatusHistoryShown(false);
    }
  }, [orderNumberParam]);

  useEffect(() => {
    if (isLoadingColors || isErrorColors || !colors?.length) return;

    const newColorsDictionary: Record<string, ColorDto> = {};
    for (const color of (colors || [])) {
      newColorsDictionary[color.id!] = color;
    }

    setColorsDictionary(newColorsDictionary);
  }, [
    isLoadingColors,
    isErrorColors,
    colors,
  ]);

  const scrollToItem = useCallback(() => {
    const barcodeSearchParam: string | null = barcodeParam ?? null;

    if (barcodeSearchParam) {
      setBarcodeSearch(barcodeSearchParam);
    }
  }, [barcodeParam]);

  useEffect(() => {
    if (!barcodeSearch) return;

    if (barcodeSearchItemRef?.current) {
      barcodeSearchItemRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [barcodeSearch]);

  useEffect(() => {
    fetchShippingOptions();
    getDecorationLocationPersonalizationSizes();
    getDecorationLocationArtworkSizes();

    getDecorationLocations(decorationTypeEnum.Logo as any);
    getDecorationLocations(decorationTypeEnum.Personalization as any);

    return () => {
      clearLogos();
    };
  }, [
    fetchShippingOptions,
    getDecorationLocationPersonalizationSizes,
    getDecorationLocationArtworkSizes,
    clearLogos,
    getDecorationLocations,
  ]);

  useEffect(() => {
    if (!isStatusHistoryShown) {
      clearLogos();
    }
  }, [
    isStatusHistoryShown,
    clearLogos,
  ]);

  useEffect(() => {
    if (!orderToLookup || !orderItemsToLookup) {
      return;
    }

    const barcodes = orderItemsToLookup.map((i) => Object.values(i.orderItemIdsWithBarcode!)).flat();

    if (barcodes.includes(lookupOrderInput)) {
      navigateToPage(orderAssemblyReviewByBarcodeUrl(orderToLookup.orderNumber!, lookupOrderInput));
    } else {
      navigateToPage(orderAssemblyReviewByOrderUrl(orderToLookup.orderNumber!));
    }
  }, [
    orderToLookup,
    orderItemsToLookup,
    lookupOrderInput,
  ]);

  useEffect(() => {
    if (!lookupOrderNumber) return;

    searchOrder();
    searchOrderItems();
  }, [
    lookupOrderNumber,
    searchOrder,
    searchOrderItems,
  ]);

  const lookupOrder = useCallback(async (newSearchInput: string) => {
    const orderParsed = newSearchInput.toLowerCase().startsWith('o')
      ? newSearchInput.substring(1)
      : newSearchInput;

    setLookupOrderInput(newSearchInput);
    setLookupOrderNumber(orderParsed);
  }, []);

  const search = useCallback((orderNumber: string) => {
    if (!orderNumber) {
      swalAlert('Please fill in an Order Number first.');
    } else {
      lookupOrder(orderNumber.toString());
    }
  }, [lookupOrder]);

  const toggleStatusHistory = useCallback(() => {
    setIsStatusHistoryShown(!isStatusHistoryShown);
  }, [isStatusHistoryShown]);

  const filterOrderItems = useCallback((newSearchInputValue) => {
    let newIsFilteringItems;
    let newFilteredItems;

    if (newSearchInputValue === '') {
      newIsFilteringItems = false;
      newFilteredItems = orderItemGroups;
    } else {
      newIsFilteringItems = true;
      newFilteredItems = orderItemGroups?.filter((i) => (
        (i.sku ?? '').toLowerCase().includes(newSearchInputValue.toLowerCase())
      ));
    }

    setFilteredItems(newFilteredItems ?? []);
    setIsFilteringItems(newIsFilteringItems);
  }, [orderItemGroups]);

  const handleSearchInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const newSearchInputValue = e.target.value.toLowerCase().trim();
    filterOrderItems(newSearchInputValue);
  }, [filterOrderItems]);

  const clearOrderItemsSearch = useCallback(() => {
    filterOrderItems('');
  }, [filterOrderItems]);

  const handleScan = useCallback(async (data: any) => {
    const result = await removeItemAssociation(data);

    if (result?.success) {
      materialSwal('Success', result.message, 'success');
    }
  }, []);

  const itemsList = isFilteringItems ? filteredItems : orderItemGroups;

  return (
    <>
      <div
        className='container'
        data-test='order-assembly'
      >
        <div className='order-assembly__navigation mb-20'>
          <BackLink
            url={orderAssemblyUrl}
            text={'Back'}
          />
          <div className='flex__row'>
            <GoToOrderInput
              searchOrder={search}
            />
            <BarcodeReader
              onScan={handleScan}
            />
          </div>
        </div>
        <OrderHeader
          order={orderHeader}
          isRequired={(orderItemGroups ?? []).some((oi) => oi.requiredItem) || false}
        />
        {
          featureFlags.activityTrackerEnabled &&
          <ActivityTracker
            orderItems={orderItemGroups}
            query={useGetHomefieldApiOrderassemblyActivitytracker}
            itemsLabel={'Items'}
            unitsLabel={'Orders'}
          />
        }
        <div className='order-assembly__header--row align__center mt-20'>
          <SearchFilter
            onChange={handleSearchInputChange}
            placeholder={'Search'}
            clearSearch={clearOrderItemsSearch}
          />
          <CheckButton
            checked={isStatusHistoryShown}
            onClick={toggleStatusHistory}
            text={'Show Status History on Items'}
            clickableText={true}
            classes={'order-assembly__status-history-checkbox'}
          />
        </div>
        <ReviewItems
          orderNumber={orderHeader?.orderNumber}
          isAssemblyDisabled={orderHeader?.isAssemblyDisabled}
          items={itemsList}
          barcodeSearch={barcodeParam ?? null}
          logoDecorationLocations={logoDecorationLocations}
          personalizationDecorationLocations={personalizationDecorationLocations}
          decorationLocationArtworkSizesList={decorationLocationArtworkSizesList}
          decorationLocationPersonalizationSizesList={decorationLocationPersonalizationSizesList}
          isStatusHistoryShown={isStatusHistoryShown}
          refreshOrder={fetchOrderHeader}
          refreshOrderItemGroups={fetchOrderItemGroups}
          barcodeSearchItemRef={barcodeSearchItemRef}
          scrollToItem={scrollToItem}
          colorsDictionary={colorsDictionary}
          colors={colors}
        />
      </div>
    </>
  );
});

export default withRouter(connector(OrderAssemblyReview));
