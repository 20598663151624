/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Financials Homefield API
 * OpenAPI spec version: v1
 */

export interface LockerManagerDto {
  id?: number;
  uuid?: string | null;
  created?: string | null;
  updated?: string | null;
  deleted?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  organizationType?: string | null;
}
