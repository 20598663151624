import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { serviceStatusEnum } from '@constants/enums/servicesEnums';
import {
  fetchFulfillmentServices,
  fetchCatalogServices,
  fetchFinancialServices,
  startService,
  stopService,
  restartService,
} from '@redux/advancedOptions/actions';
import { materialSwal } from '@util/componentHelper';
import HeaderCell from '@sharedComponents/Table/TableCells/HeaderCell';
import Table from '@sharedComponents/Table/Table';
import Button from '@sharedComponents/Buttons/Button';
import SimpleConfirmationModal from '@sharedComponents/Modal/SimpleConfirmationModal';

const ServicesTable = Table();

class ServiceManagerTable extends PureComponent {
  state = {
    confirmationModalIsOpen: false,
    selectedService: null,
  };

  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(fetchFulfillmentServices());
    dispatch(fetchCatalogServices());
    dispatch(fetchFinancialServices());
  }

  getColumns = () => {
    const columns = [
      {
        Header: <HeaderCell text={'Name'} />,
        accessor: 'name',
        minWidth: 400,
        Cell: (cellProps) => cellProps.value && <span>{cellProps.value}</span>,
      },
      {
        Header: <HeaderCell text={'API'} />,
        accessor: 'apiLocation',
        minWidth: 150,
        Cell: (cellProps) => cellProps.value && <span>{cellProps.value}</span>,
      },
      {
        Header: <HeaderCell text={'Status'} />,
        accessor: 'status',
        minWidth: 150,
        Cell: (cellProps) => cellProps.value &&
          <span className={`status-label ${cellProps.value === serviceStatusEnum.Started ? 'green' : 'red'}`}>{cellProps.value}</span>,
      },
      {
        Header: <HeaderCell text={'Start/Stop'} />,
        accessor: 'status',
        minWidth: 120,
        Cell: (cellProps) => (
          <span>
            {
              cellProps.value === serviceStatusEnum.Started
                ? (
                  <Button
                    type={'danger'}
                    text={'Stop'}
                    onClick={this.openConfirmationModal.bind(null, cellProps.original)}
                    classes={'w-100'}
                  />
                )
                : (
                  <Button
                    type={'primary'}
                    text={'Start'}
                    onClick={this.startService.bind(null, cellProps.original)}
                    classes={'w-100'}
                  />
                )
            }
          </span>
        ),
      },
      {
        Header: <HeaderCell text={'Restart'} />,
        accessor: 'status',
        minWidth: 120,
        Cell: (cellProps) => (
          <span>
            <Button
              type={'warning'}
              text={'Restart'}
              onClick={this.restartService.bind(null, cellProps.original)}
              disabled={cellProps.value !== serviceStatusEnum.Started}
              classes={'w-100'}
            />
          </span>
        ),
      },
    ];

    return columns;
  };

  openConfirmationModal = (selectedService) => {
    this.state(() => ({
      selectedService,
      confirmationModalIsOpen: true,
    }));
  };

  closeConfirmationModal = () => {
    this.setState(() => ({
      selectedService: null,
      confirmationModalIsOpen: false,
    }));
  };

  stopService = async (service) => {
    const { dispatch } = this.props;

    const res = await dispatch(stopService(service));
    if (res?.message) {
      materialSwal('Success', res.message, 'Success');
    }

    this.closeConfirmationModal();
  };

  startService = async (service) => {
    const { dispatch } = this.props;
    const res = await dispatch(startService(service));
    if (res) {
      materialSwal('Success', 'Successfully started the service', 'success');
    }
  };

  restartService = async (service) => {
    const { dispatch } = this.props;
    const res = await dispatch(restartService(service));
    if (res) {
      materialSwal('Success', 'Successfully restarted the service', 'success');
    }
  };

  render() {
    const { services } = this.props;
    const {
      confirmationModalIsOpen,
      selectedService,
    } = this.state;

    return (
      <>
        <div className='sheet'>
          <ServicesTable
            data={services}
            columns={this.getColumns()}
            showPagination={false}
          />
        </div>

        <SimpleConfirmationModal
          isOpen={confirmationModalIsOpen}
          confirm={this.handleModalConfirm}
          closeModal={this.closeConfirmationModal}
          title={'Stop Service'}
          confirmationBody={`Are you sure you want to stop '${selectedService ? selectedService.name : ''}'?`}
        />
      </>
    );
  }
}

ServiceManagerTable.propTypes = {
  services: PropTypes.array,
};

const mapStateToProps = ({ advancedOptions }) => ({
  services: [
    ...advancedOptions.catalogServices,
    ...advancedOptions.financialServices,
    ...advancedOptions.fulfillmentServices,
    ...advancedOptions.gpServices,
  ],
});

export default connect(mapStateToProps)(ServiceManagerTable);
