/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions
} from 'react-query'
import type {
  OrderDtoPagedList,
  GetHomefieldApiOrdermanagementOrdersParams,
  OrderDetailsDto,
  OrderInventoryInfoDto,
  ApiResponse,
  OrderAccountingSubmitResultDto,
  OrderShipmentDto,
  UpdateOrderShipmentCommand,
  CreateOrderShipmentCommand,
  ChangeLogItemDto,
  OrderStatusEnumStatusModel,
  FlagDto,
  RushOrderCommand,
  SubmitOrderToAccountingCommand,
  UpdateOrderShippingInfoCommand,
  CalculateShippingPriceChangeCommand,
  SendRefundConfirmationCommand,
  NoteDto,
  PostHomefieldApiOrdermanagementOrdersOrderNumberNotesBody,
  ReplaceOrderItemCommandObsolete,
  ReplaceOrderItemCommand,
  UpdateOrderItemPriceCalculationDtoApiResponse,
  CalculatePriceChangeForOrderItemReplaceCommand,
  AddOrderItemCommand,
  ChangeArtworkCommand,
  CreatePersonalizationCommandObsolete,
  AddPersonalizationCommand,
  CalculatePriceChangeForPersonalizationUpdateCommandObsolete,
  UpdatePersonalizationCommandObsolete,
  EditPersonalizationCommand,
  ChangeDecorationMethodCommand,
  RemovePersonalizationCommandObsolete,
  RemovePersonalizationCommand,
  CalculatePriceChangeForPersonalizationRemoveCommandObsolete,
  LockerLogoDto,
  QueriesLockerDto,
  GetHomefieldApiOrdermanagementLockersParams,
  RefundOrderCommandObsolete,
  ApplyCouponToOrderCommandObsolete,
  ApplyVoucherToOrderCommand,
  ApplyVoucherToOrderPriceCalculationDtoApiResponse,
  GetHomefieldApiOrdermanagementOrdersvoucherapplypricecalculationParams,
  CalculatePriceChangeForCouponApplyCommandObsolete,
  RefundOrderItemCommandObsolete,
  CancelOrderItemCommand,
  Int32Int32DictionaryApiResponse,
  GetOrdersForVoucherInstancesQuery
} from './models'
import { fulfillmentApi } from '../mutator/custom-instance'


type AsyncReturnType<
T extends (...args: any) => Promise<any>,
U = unknown
> = T extends (...args: any) => Promise<infer R> ? (U extends R ? U : R) : any;


type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const getHomefieldApiOrdermanagementOrders = <TData = OrderDtoPagedList>(
    params?: GetHomefieldApiOrdermanagementOrdersParams,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/ordermanagement/orders`, method: 'get',
        params,
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiOrdermanagementOrdersQueryKey = (params?: GetHomefieldApiOrdermanagementOrdersParams,) => [`/homefield/api/ordermanagement/orders`, ...(params ? [params]: [])]

    
export const useGetHomefieldApiOrdermanagementOrders = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiOrdermanagementOrders, OrderDtoPagedList>, TError = unknown, TData = TQueryFnData>(
 params?: GetHomefieldApiOrdermanagementOrdersParams, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof fulfillmentApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiOrdermanagementOrdersQueryKey(params);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiOrdermanagementOrders<TQueryFnData>(params, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const getHomefieldApiOrdermanagementOrdersOrderNumber = <TData = OrderDetailsDto>(
    orderNumber: number,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/ordermanagement/orders/${orderNumber}`, method: 'get'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiOrdermanagementOrdersOrderNumberQueryKey = (orderNumber: number,) => [`/homefield/api/ordermanagement/orders/${orderNumber}`]

    
export const useGetHomefieldApiOrdermanagementOrdersOrderNumber = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiOrdermanagementOrdersOrderNumber, OrderDetailsDto>, TError = unknown, TData = TQueryFnData>(
 orderNumber: number, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof fulfillmentApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiOrdermanagementOrdersOrderNumberQueryKey(orderNumber);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiOrdermanagementOrdersOrderNumber<TQueryFnData>(orderNumber, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const getHomefieldApiOrdermanagementOrdersSopNumber = <TData = OrderDetailsDto>(
    sopNumber: string,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/ordermanagement/orders/${sopNumber}`, method: 'get'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiOrdermanagementOrdersSopNumberQueryKey = (sopNumber: string,) => [`/homefield/api/ordermanagement/orders/${sopNumber}`]

    
export const useGetHomefieldApiOrdermanagementOrdersSopNumber = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiOrdermanagementOrdersSopNumber, OrderDetailsDto>, TError = unknown, TData = TQueryFnData>(
 sopNumber: string, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof fulfillmentApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiOrdermanagementOrdersSopNumberQueryKey(sopNumber);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiOrdermanagementOrdersSopNumber<TQueryFnData>(sopNumber, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const getHomefieldApiOrdermanagementInventorySopNumber = <TData = OrderInventoryInfoDto>(
    sopNumber: string,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/ordermanagement/inventory/${sopNumber}`, method: 'get'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiOrdermanagementInventorySopNumberQueryKey = (sopNumber: string,) => [`/homefield/api/ordermanagement/inventory/${sopNumber}`]

    
export const useGetHomefieldApiOrdermanagementInventorySopNumber = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiOrdermanagementInventorySopNumber, OrderInventoryInfoDto>, TError = ApiResponse, TData = TQueryFnData>(
 sopNumber: string, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof fulfillmentApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiOrdermanagementInventorySopNumberQueryKey(sopNumber);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiOrdermanagementInventorySopNumber<TQueryFnData>(sopNumber, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const putHomefieldApiOrdermanagementOrdersOrderNumberInventory = <TData = ApiResponse>(
    orderNumber: number,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/ordermanagement/orders/${orderNumber}/inventory`, method: 'put',
      data: undefined
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePutHomefieldApiOrdermanagementOrdersOrderNumberInventory = <TData = AsyncReturnType<typeof putHomefieldApiOrdermanagementOrdersOrderNumberInventory,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{orderNumber: number}, TContext>, request?: SecondParameter<typeof fulfillmentApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {orderNumber: number}, TContext>((props) => {
        const {orderNumber} = props || {};

        return  putHomefieldApiOrdermanagementOrdersOrderNumberInventory<TData>(orderNumber,requestOptions)
      }, mutationOptions)
    }
    export const getHomefieldApiOrdermanagementOrdersOrderNumberAccountingsubmitresults = <TData = OrderAccountingSubmitResultDto[]>(
    orderNumber: number,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/ordermanagement/orders/${orderNumber}/accounting-submit-results`, method: 'get'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiOrdermanagementOrdersOrderNumberAccountingsubmitresultsQueryKey = (orderNumber: number,) => [`/homefield/api/ordermanagement/orders/${orderNumber}/accounting-submit-results`]

    
export const useGetHomefieldApiOrdermanagementOrdersOrderNumberAccountingsubmitresults = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiOrdermanagementOrdersOrderNumberAccountingsubmitresults, OrderAccountingSubmitResultDto[]>, TError = unknown, TData = TQueryFnData>(
 orderNumber: number, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof fulfillmentApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiOrdermanagementOrdersOrderNumberAccountingsubmitresultsQueryKey(orderNumber);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiOrdermanagementOrdersOrderNumberAccountingsubmitresults<TQueryFnData>(orderNumber, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const getHomefieldApiOrdermanagementOrdersOrderNumberShipments = <TData = OrderShipmentDto[]>(
    orderNumber: number,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/ordermanagement/orders/${orderNumber}/shipments`, method: 'get'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiOrdermanagementOrdersOrderNumberShipmentsQueryKey = (orderNumber: number,) => [`/homefield/api/ordermanagement/orders/${orderNumber}/shipments`]

    
export const useGetHomefieldApiOrdermanagementOrdersOrderNumberShipments = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiOrdermanagementOrdersOrderNumberShipments, OrderShipmentDto[]>, TError = unknown, TData = TQueryFnData>(
 orderNumber: number, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof fulfillmentApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiOrdermanagementOrdersOrderNumberShipmentsQueryKey(orderNumber);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiOrdermanagementOrdersOrderNumberShipments<TQueryFnData>(orderNumber, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const putHomefieldApiOrdermanagementOrdersOrderNumberShipments = <TData = ApiResponse>(
    orderNumber: number,
    updateOrderShipmentCommand: UpdateOrderShipmentCommand,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/ordermanagement/orders/${orderNumber}/shipments`, method: 'put',
      data: updateOrderShipmentCommand
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePutHomefieldApiOrdermanagementOrdersOrderNumberShipments = <TData = AsyncReturnType<typeof putHomefieldApiOrdermanagementOrdersOrderNumberShipments,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{orderNumber: number;data: UpdateOrderShipmentCommand}, TContext>, request?: SecondParameter<typeof fulfillmentApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {orderNumber: number;data: UpdateOrderShipmentCommand}, TContext>((props) => {
        const {orderNumber,data} = props || {};

        return  putHomefieldApiOrdermanagementOrdersOrderNumberShipments<TData>(orderNumber,data,requestOptions)
      }, mutationOptions)
    }
    export const postHomefieldApiOrdermanagementOrdersOrderNumberShipments = <TData = ApiResponse>(
    orderNumber: number,
    createOrderShipmentCommand: CreateOrderShipmentCommand,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/ordermanagement/orders/${orderNumber}/shipments`, method: 'post',
      data: createOrderShipmentCommand
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePostHomefieldApiOrdermanagementOrdersOrderNumberShipments = <TData = AsyncReturnType<typeof postHomefieldApiOrdermanagementOrdersOrderNumberShipments,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{orderNumber: number;data: CreateOrderShipmentCommand}, TContext>, request?: SecondParameter<typeof fulfillmentApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {orderNumber: number;data: CreateOrderShipmentCommand}, TContext>((props) => {
        const {orderNumber,data} = props || {};

        return  postHomefieldApiOrdermanagementOrdersOrderNumberShipments<TData>(orderNumber,data,requestOptions)
      }, mutationOptions)
    }
    export const getHomefieldApiOrdermanagementOrdersOrderNumberChangelogs = <TData = ChangeLogItemDto[]>(
    orderNumber: number,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/ordermanagement/orders/${orderNumber}/change-logs`, method: 'get'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiOrdermanagementOrdersOrderNumberChangelogsQueryKey = (orderNumber: number,) => [`/homefield/api/ordermanagement/orders/${orderNumber}/change-logs`]

    
export const useGetHomefieldApiOrdermanagementOrdersOrderNumberChangelogs = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiOrdermanagementOrdersOrderNumberChangelogs, ChangeLogItemDto[]>, TError = unknown, TData = TQueryFnData>(
 orderNumber: number, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof fulfillmentApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiOrdermanagementOrdersOrderNumberChangelogsQueryKey(orderNumber);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiOrdermanagementOrdersOrderNumberChangelogs<TQueryFnData>(orderNumber, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const getHomefieldApiOrdermanagementOrdersOrderNumberStatuses = <TData = OrderStatusEnumStatusModel[]>(
    orderNumber: number,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/ordermanagement/orders/${orderNumber}/statuses`, method: 'get'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiOrdermanagementOrdersOrderNumberStatusesQueryKey = (orderNumber: number,) => [`/homefield/api/ordermanagement/orders/${orderNumber}/statuses`]

    
export const useGetHomefieldApiOrdermanagementOrdersOrderNumberStatuses = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiOrdermanagementOrdersOrderNumberStatuses, OrderStatusEnumStatusModel[]>, TError = unknown, TData = TQueryFnData>(
 orderNumber: number, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof fulfillmentApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiOrdermanagementOrdersOrderNumberStatusesQueryKey(orderNumber);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiOrdermanagementOrdersOrderNumberStatuses<TQueryFnData>(orderNumber, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const getHomefieldApiOrdermanagementOrdersOrderNumberFlags = <TData = FlagDto[]>(
    orderNumber: number,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/ordermanagement/orders/${orderNumber}/flags`, method: 'get'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiOrdermanagementOrdersOrderNumberFlagsQueryKey = (orderNumber: number,) => [`/homefield/api/ordermanagement/orders/${orderNumber}/flags`]

    
export const useGetHomefieldApiOrdermanagementOrdersOrderNumberFlags = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiOrdermanagementOrdersOrderNumberFlags, FlagDto[]>, TError = unknown, TData = TQueryFnData>(
 orderNumber: number, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof fulfillmentApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiOrdermanagementOrdersOrderNumberFlagsQueryKey(orderNumber);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiOrdermanagementOrdersOrderNumberFlags<TQueryFnData>(orderNumber, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const putHomefieldApiOrdermanagementOrderspauseOrderNumber = <TData = ApiResponse>(
    orderNumber: number,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/ordermanagement/orders.pause/${orderNumber}`, method: 'put',
      data: undefined
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePutHomefieldApiOrdermanagementOrderspauseOrderNumber = <TData = AsyncReturnType<typeof putHomefieldApiOrdermanagementOrderspauseOrderNumber,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{orderNumber: number}, TContext>, request?: SecondParameter<typeof fulfillmentApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {orderNumber: number}, TContext>((props) => {
        const {orderNumber} = props || {};

        return  putHomefieldApiOrdermanagementOrderspauseOrderNumber<TData>(orderNumber,requestOptions)
      }, mutationOptions)
    }
    export const putHomefieldApiOrdermanagementOrdersunpauseOrderNumber = <TData = ApiResponse>(
    orderNumber: number,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/ordermanagement/orders.unpause/${orderNumber}`, method: 'put',
      data: undefined
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePutHomefieldApiOrdermanagementOrdersunpauseOrderNumber = <TData = AsyncReturnType<typeof putHomefieldApiOrdermanagementOrdersunpauseOrderNumber,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{orderNumber: number}, TContext>, request?: SecondParameter<typeof fulfillmentApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {orderNumber: number}, TContext>((props) => {
        const {orderNumber} = props || {};

        return  putHomefieldApiOrdermanagementOrdersunpauseOrderNumber<TData>(orderNumber,requestOptions)
      }, mutationOptions)
    }
    export const putHomefieldApiOrdermanagementOrdersrush = <TData = ApiResponse>(
    rushOrderCommand: RushOrderCommand,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/ordermanagement/orders.rush`, method: 'put',
      data: rushOrderCommand
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePutHomefieldApiOrdermanagementOrdersrush = <TData = AsyncReturnType<typeof putHomefieldApiOrdermanagementOrdersrush,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: RushOrderCommand}, TContext>, request?: SecondParameter<typeof fulfillmentApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {data: RushOrderCommand}, TContext>((props) => {
        const {data} = props || {};

        return  putHomefieldApiOrdermanagementOrdersrush<TData>(data,requestOptions)
      }, mutationOptions)
    }
    export const putHomefieldApiOrdermanagementOrdersaccountingsubmitOrderNumber = <TData = ApiResponse>(
    orderNumber: number,
    submitOrderToAccountingCommand: SubmitOrderToAccountingCommand,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/ordermanagement/orders.accounting-submit/${orderNumber}`, method: 'put',
      data: submitOrderToAccountingCommand
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePutHomefieldApiOrdermanagementOrdersaccountingsubmitOrderNumber = <TData = AsyncReturnType<typeof putHomefieldApiOrdermanagementOrdersaccountingsubmitOrderNumber,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{orderNumber: number;data: SubmitOrderToAccountingCommand}, TContext>, request?: SecondParameter<typeof fulfillmentApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {orderNumber: number;data: SubmitOrderToAccountingCommand}, TContext>((props) => {
        const {orderNumber,data} = props || {};

        return  putHomefieldApiOrdermanagementOrdersaccountingsubmitOrderNumber<TData>(orderNumber,data,requestOptions)
      }, mutationOptions)
    }
    export const putHomefieldApiOrdermanagementOrdersshippinginfo = <TData = ApiResponse>(
    updateOrderShippingInfoCommand: UpdateOrderShippingInfoCommand,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/ordermanagement/orders.shipping-info`, method: 'put',
      data: updateOrderShippingInfoCommand
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePutHomefieldApiOrdermanagementOrdersshippinginfo = <TData = AsyncReturnType<typeof putHomefieldApiOrdermanagementOrdersshippinginfo,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: UpdateOrderShippingInfoCommand}, TContext>, request?: SecondParameter<typeof fulfillmentApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {data: UpdateOrderShippingInfoCommand}, TContext>((props) => {
        const {data} = props || {};

        return  putHomefieldApiOrdermanagementOrdersshippinginfo<TData>(data,requestOptions)
      }, mutationOptions)
    }
    export const postHomefieldApiOrdermanagementOrdersshippinginfopricecalculation = <TData = ApiResponse>(
    calculateShippingPriceChangeCommand: CalculateShippingPriceChangeCommand,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/ordermanagement/orders.shipping-info-price-calculation`, method: 'post',
      data: calculateShippingPriceChangeCommand
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePostHomefieldApiOrdermanagementOrdersshippinginfopricecalculation = <TData = AsyncReturnType<typeof postHomefieldApiOrdermanagementOrdersshippinginfopricecalculation,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: CalculateShippingPriceChangeCommand}, TContext>, request?: SecondParameter<typeof fulfillmentApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {data: CalculateShippingPriceChangeCommand}, TContext>((props) => {
        const {data} = props || {};

        return  postHomefieldApiOrdermanagementOrdersshippinginfopricecalculation<TData>(data,requestOptions)
      }, mutationOptions)
    }
    export const postHomefieldApiOrdermanagementOrderssendconfirmationOrderNumber = <TData = ApiResponse>(
    orderNumber: number,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/ordermanagement/orders.send-confirmation/${orderNumber}`, method: 'post',
      data: undefined
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePostHomefieldApiOrdermanagementOrderssendconfirmationOrderNumber = <TData = AsyncReturnType<typeof postHomefieldApiOrdermanagementOrderssendconfirmationOrderNumber,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{orderNumber: number}, TContext>, request?: SecondParameter<typeof fulfillmentApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {orderNumber: number}, TContext>((props) => {
        const {orderNumber} = props || {};

        return  postHomefieldApiOrdermanagementOrderssendconfirmationOrderNumber<TData>(orderNumber,requestOptions)
      }, mutationOptions)
    }
    export const postHomefieldApiOrdermanagementOrderssendrefundconfirmation = <TData = ApiResponse>(
    sendRefundConfirmationCommand: SendRefundConfirmationCommand,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/ordermanagement/orders.send-refund-confirmation`, method: 'post',
      data: sendRefundConfirmationCommand
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePostHomefieldApiOrdermanagementOrderssendrefundconfirmation = <TData = AsyncReturnType<typeof postHomefieldApiOrdermanagementOrderssendrefundconfirmation,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: SendRefundConfirmationCommand}, TContext>, request?: SecondParameter<typeof fulfillmentApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {data: SendRefundConfirmationCommand}, TContext>((props) => {
        const {data} = props || {};

        return  postHomefieldApiOrdermanagementOrderssendrefundconfirmation<TData>(data,requestOptions)
      }, mutationOptions)
    }
    export const postHomefieldApiOrdermanagementOrdersOrderNumberPurchaseorders = <TData = ApiResponse>(
    orderNumber: number,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/ordermanagement/orders/${orderNumber}/purchase-orders`, method: 'post',
      data: undefined
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePostHomefieldApiOrdermanagementOrdersOrderNumberPurchaseorders = <TData = AsyncReturnType<typeof postHomefieldApiOrdermanagementOrdersOrderNumberPurchaseorders,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{orderNumber: number}, TContext>, request?: SecondParameter<typeof fulfillmentApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {orderNumber: number}, TContext>((props) => {
        const {orderNumber} = props || {};

        return  postHomefieldApiOrdermanagementOrdersOrderNumberPurchaseorders<TData>(orderNumber,requestOptions)
      }, mutationOptions)
    }
    export const putHomefieldApiOrdermanagementOrdersOrderNumberPurchaseordersPoNumber = <TData = ApiResponse>(
    orderNumber: number,
    poNumber: string,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/ordermanagement/orders/${orderNumber}/purchase-orders/${poNumber}`, method: 'put',
      data: undefined
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePutHomefieldApiOrdermanagementOrdersOrderNumberPurchaseordersPoNumber = <TData = AsyncReturnType<typeof putHomefieldApiOrdermanagementOrdersOrderNumberPurchaseordersPoNumber,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{orderNumber: number;poNumber: string}, TContext>, request?: SecondParameter<typeof fulfillmentApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {orderNumber: number;poNumber: string}, TContext>((props) => {
        const {orderNumber,poNumber} = props || {};

        return  putHomefieldApiOrdermanagementOrdersOrderNumberPurchaseordersPoNumber<TData>(orderNumber,poNumber,requestOptions)
      }, mutationOptions)
    }
    export const getHomefieldApiOrdermanagementOrdersOrderNumberNotes = <TData = NoteDto[]>(
    orderNumber: number,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/ordermanagement/orders/${orderNumber}/notes`, method: 'get'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiOrdermanagementOrdersOrderNumberNotesQueryKey = (orderNumber: number,) => [`/homefield/api/ordermanagement/orders/${orderNumber}/notes`]

    
export const useGetHomefieldApiOrdermanagementOrdersOrderNumberNotes = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiOrdermanagementOrdersOrderNumberNotes, NoteDto[]>, TError = ApiResponse, TData = TQueryFnData>(
 orderNumber: number, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof fulfillmentApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiOrdermanagementOrdersOrderNumberNotesQueryKey(orderNumber);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiOrdermanagementOrdersOrderNumberNotes<TQueryFnData>(orderNumber, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const postHomefieldApiOrdermanagementOrdersOrderNumberNotes = <TData = ApiResponse>(
    orderNumber: number,
    postHomefieldApiOrdermanagementOrdersOrderNumberNotesBody: PostHomefieldApiOrdermanagementOrdersOrderNumberNotesBody,
 options?: SecondParameter<typeof fulfillmentApi>) => {const formData = new FormData();
if(postHomefieldApiOrdermanagementOrdersOrderNumberNotesBody.orderNumber) {
 formData.append('orderNumber', postHomefieldApiOrdermanagementOrdersOrderNumberNotesBody.orderNumber.toString())
 }
if(postHomefieldApiOrdermanagementOrdersOrderNumberNotesBody.content) {
 formData.append('content', postHomefieldApiOrdermanagementOrdersOrderNumberNotesBody.content)
 }
if(postHomefieldApiOrdermanagementOrdersOrderNumberNotesBody.fileName) {
 formData.append('fileName', postHomefieldApiOrdermanagementOrdersOrderNumberNotesBody.fileName)
 }

      return fulfillmentApi<TData>(
      {url: `/homefield/api/ordermanagement/orders/${orderNumber}/notes`, method: 'post',
       data: formData
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePostHomefieldApiOrdermanagementOrdersOrderNumberNotes = <TData = AsyncReturnType<typeof postHomefieldApiOrdermanagementOrdersOrderNumberNotes,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{orderNumber: number;data: PostHomefieldApiOrdermanagementOrdersOrderNumberNotesBody}, TContext>, request?: SecondParameter<typeof fulfillmentApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {orderNumber: number;data: PostHomefieldApiOrdermanagementOrdersOrderNumberNotesBody}, TContext>((props) => {
        const {orderNumber,data} = props || {};

        return  postHomefieldApiOrdermanagementOrdersOrderNumberNotes<TData>(orderNumber,data,requestOptions)
      }, mutationOptions)
    }
    export const deleteHomefieldApiOrdermanagementOrdersOrderNumberNotesNoteId = <TData = ApiResponse>(
    orderNumber: number,
    noteId: number,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/ordermanagement/orders/${orderNumber}/notes/${noteId}`, method: 'delete'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const useDeleteHomefieldApiOrdermanagementOrdersOrderNumberNotesNoteId = <TData = AsyncReturnType<typeof deleteHomefieldApiOrdermanagementOrdersOrderNumberNotesNoteId,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{orderNumber: number;noteId: number}, TContext>, request?: SecondParameter<typeof fulfillmentApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {orderNumber: number;noteId: number}, TContext>((props) => {
        const {orderNumber,noteId} = props || {};

        return  deleteHomefieldApiOrdermanagementOrdersOrderNumberNotesNoteId<TData>(orderNumber,noteId,requestOptions)
      }, mutationOptions)
    }
    export const putHomefieldApiOrdermanagementOrderitemsreplace = <TData = ApiResponse>(
    replaceOrderItemCommandObsolete: ReplaceOrderItemCommandObsolete,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/ordermanagement/order-items.replace`, method: 'put',
      data: replaceOrderItemCommandObsolete
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePutHomefieldApiOrdermanagementOrderitemsreplace = <TData = AsyncReturnType<typeof putHomefieldApiOrdermanagementOrderitemsreplace,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: ReplaceOrderItemCommandObsolete}, TContext>, request?: SecondParameter<typeof fulfillmentApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {data: ReplaceOrderItemCommandObsolete}, TContext>((props) => {
        const {data} = props || {};

        return  putHomefieldApiOrdermanagementOrderitemsreplace<TData>(data,requestOptions)
      }, mutationOptions)
    }
    export const postHomefieldApiOrdermanagementOrderitemsreplace = <TData = ApiResponse>(
    replaceOrderItemCommand: ReplaceOrderItemCommand,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/ordermanagement/order-items.replace`, method: 'post',
      data: replaceOrderItemCommand
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePostHomefieldApiOrdermanagementOrderitemsreplace = <TData = AsyncReturnType<typeof postHomefieldApiOrdermanagementOrderitemsreplace,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: ReplaceOrderItemCommand}, TContext>, request?: SecondParameter<typeof fulfillmentApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {data: ReplaceOrderItemCommand}, TContext>((props) => {
        const {data} = props || {};

        return  postHomefieldApiOrdermanagementOrderitemsreplace<TData>(data,requestOptions)
      }, mutationOptions)
    }
    export const postHomefieldApiOrdermanagementOrderitemsreplacepricecalculation = <TData = UpdateOrderItemPriceCalculationDtoApiResponse>(
    calculatePriceChangeForOrderItemReplaceCommand: CalculatePriceChangeForOrderItemReplaceCommand,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/ordermanagement/order-items.replace-price-calculation`, method: 'post',
      data: calculatePriceChangeForOrderItemReplaceCommand
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePostHomefieldApiOrdermanagementOrderitemsreplacepricecalculation = <TData = AsyncReturnType<typeof postHomefieldApiOrdermanagementOrderitemsreplacepricecalculation,UpdateOrderItemPriceCalculationDtoApiResponse>,
    TError = ApiResponse,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: CalculatePriceChangeForOrderItemReplaceCommand}, TContext>, request?: SecondParameter<typeof fulfillmentApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {data: CalculatePriceChangeForOrderItemReplaceCommand}, TContext>((props) => {
        const {data} = props || {};

        return  postHomefieldApiOrdermanagementOrderitemsreplacepricecalculation<TData>(data,requestOptions)
      }, mutationOptions)
    }
    export const postHomefieldApiOrdermanagementOrdersaddorderitems = <TData = ApiResponse>(
    addOrderItemCommand: AddOrderItemCommand,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/ordermanagement/orders.add-order-items`, method: 'post',
      data: addOrderItemCommand
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePostHomefieldApiOrdermanagementOrdersaddorderitems = <TData = AsyncReturnType<typeof postHomefieldApiOrdermanagementOrdersaddorderitems,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: AddOrderItemCommand}, TContext>, request?: SecondParameter<typeof fulfillmentApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {data: AddOrderItemCommand}, TContext>((props) => {
        const {data} = props || {};

        return  postHomefieldApiOrdermanagementOrdersaddorderitems<TData>(data,requestOptions)
      }, mutationOptions)
    }
    export const putHomefieldApiOrdermanagementOrderschangeartwork = <TData = ApiResponse>(
    changeArtworkCommand: ChangeArtworkCommand,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/ordermanagement/orders.change-artwork`, method: 'put',
      data: changeArtworkCommand
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePutHomefieldApiOrdermanagementOrderschangeartwork = <TData = AsyncReturnType<typeof putHomefieldApiOrdermanagementOrderschangeartwork,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: ChangeArtworkCommand}, TContext>, request?: SecondParameter<typeof fulfillmentApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {data: ChangeArtworkCommand}, TContext>((props) => {
        const {data} = props || {};

        return  putHomefieldApiOrdermanagementOrderschangeartwork<TData>(data,requestOptions)
      }, mutationOptions)
    }
    export const postHomefieldApiOrdermanagementOrderspersonalizationaddobsolete = <TData = ApiResponse>(
    createPersonalizationCommandObsolete: CreatePersonalizationCommandObsolete,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/ordermanagement/orders.personalization-add-obsolete`, method: 'post',
      data: createPersonalizationCommandObsolete
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePostHomefieldApiOrdermanagementOrderspersonalizationaddobsolete = <TData = AsyncReturnType<typeof postHomefieldApiOrdermanagementOrderspersonalizationaddobsolete,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: CreatePersonalizationCommandObsolete}, TContext>, request?: SecondParameter<typeof fulfillmentApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {data: CreatePersonalizationCommandObsolete}, TContext>((props) => {
        const {data} = props || {};

        return  postHomefieldApiOrdermanagementOrderspersonalizationaddobsolete<TData>(data,requestOptions)
      }, mutationOptions)
    }
    export const postHomefieldApiOrdermanagementOrderspersonalizationadd = <TData = ApiResponse>(
    addPersonalizationCommand: AddPersonalizationCommand,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/ordermanagement/orders.personalization-add`, method: 'post',
      data: addPersonalizationCommand
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePostHomefieldApiOrdermanagementOrderspersonalizationadd = <TData = AsyncReturnType<typeof postHomefieldApiOrdermanagementOrderspersonalizationadd,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: AddPersonalizationCommand}, TContext>, request?: SecondParameter<typeof fulfillmentApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {data: AddPersonalizationCommand}, TContext>((props) => {
        const {data} = props || {};

        return  postHomefieldApiOrdermanagementOrderspersonalizationadd<TData>(data,requestOptions)
      }, mutationOptions)
    }
    export const postHomefieldApiOrdermanagementOrderspersonalizationaddpricecalculation = <TData = ApiResponse>(
    calculatePriceChangeForPersonalizationUpdateCommandObsolete: CalculatePriceChangeForPersonalizationUpdateCommandObsolete,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/ordermanagement/orders.personalization-add-price-calculation`, method: 'post',
      data: calculatePriceChangeForPersonalizationUpdateCommandObsolete
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePostHomefieldApiOrdermanagementOrderspersonalizationaddpricecalculation = <TData = AsyncReturnType<typeof postHomefieldApiOrdermanagementOrderspersonalizationaddpricecalculation,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: CalculatePriceChangeForPersonalizationUpdateCommandObsolete}, TContext>, request?: SecondParameter<typeof fulfillmentApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {data: CalculatePriceChangeForPersonalizationUpdateCommandObsolete}, TContext>((props) => {
        const {data} = props || {};

        return  postHomefieldApiOrdermanagementOrderspersonalizationaddpricecalculation<TData>(data,requestOptions)
      }, mutationOptions)
    }
    export const putHomefieldApiOrdermanagementOrderspersonalizationeditobsolete = <TData = ApiResponse>(
    updatePersonalizationCommandObsolete: UpdatePersonalizationCommandObsolete,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/ordermanagement/orders.personalization-edit-obsolete`, method: 'put',
      data: updatePersonalizationCommandObsolete
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePutHomefieldApiOrdermanagementOrderspersonalizationeditobsolete = <TData = AsyncReturnType<typeof putHomefieldApiOrdermanagementOrderspersonalizationeditobsolete,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: UpdatePersonalizationCommandObsolete}, TContext>, request?: SecondParameter<typeof fulfillmentApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {data: UpdatePersonalizationCommandObsolete}, TContext>((props) => {
        const {data} = props || {};

        return  putHomefieldApiOrdermanagementOrderspersonalizationeditobsolete<TData>(data,requestOptions)
      }, mutationOptions)
    }
    export const putHomefieldApiOrdermanagementOrderspersonalizationedit = <TData = ApiResponse>(
    editPersonalizationCommand: EditPersonalizationCommand,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/ordermanagement/orders.personalization-edit`, method: 'put',
      data: editPersonalizationCommand
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePutHomefieldApiOrdermanagementOrderspersonalizationedit = <TData = AsyncReturnType<typeof putHomefieldApiOrdermanagementOrderspersonalizationedit,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: EditPersonalizationCommand}, TContext>, request?: SecondParameter<typeof fulfillmentApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {data: EditPersonalizationCommand}, TContext>((props) => {
        const {data} = props || {};

        return  putHomefieldApiOrdermanagementOrderspersonalizationedit<TData>(data,requestOptions)
      }, mutationOptions)
    }
    export const postHomefieldApiOrdermanagementOrderspersonalizationeditpricecalculation = <TData = ApiResponse>(
    calculatePriceChangeForPersonalizationUpdateCommandObsolete: CalculatePriceChangeForPersonalizationUpdateCommandObsolete,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/ordermanagement/orders.personalization-edit-price-calculation`, method: 'post',
      data: calculatePriceChangeForPersonalizationUpdateCommandObsolete
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePostHomefieldApiOrdermanagementOrderspersonalizationeditpricecalculation = <TData = AsyncReturnType<typeof postHomefieldApiOrdermanagementOrderspersonalizationeditpricecalculation,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: CalculatePriceChangeForPersonalizationUpdateCommandObsolete}, TContext>, request?: SecondParameter<typeof fulfillmentApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {data: CalculatePriceChangeForPersonalizationUpdateCommandObsolete}, TContext>((props) => {
        const {data} = props || {};

        return  postHomefieldApiOrdermanagementOrderspersonalizationeditpricecalculation<TData>(data,requestOptions)
      }, mutationOptions)
    }
    export const postHomefieldApiOrdermanagementOrdersdecorationmethod = <TData = ApiResponse>(
    changeDecorationMethodCommand: ChangeDecorationMethodCommand,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/ordermanagement/orders.decoration-method`, method: 'post',
      data: changeDecorationMethodCommand
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePostHomefieldApiOrdermanagementOrdersdecorationmethod = <TData = AsyncReturnType<typeof postHomefieldApiOrdermanagementOrdersdecorationmethod,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: ChangeDecorationMethodCommand}, TContext>, request?: SecondParameter<typeof fulfillmentApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {data: ChangeDecorationMethodCommand}, TContext>((props) => {
        const {data} = props || {};

        return  postHomefieldApiOrdermanagementOrdersdecorationmethod<TData>(data,requestOptions)
      }, mutationOptions)
    }
    export const deleteHomefieldApiOrdermanagementOrderspersonalizationremoveobsolete = <TData = ApiResponse>(
    removePersonalizationCommandObsolete: RemovePersonalizationCommandObsolete,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/ordermanagement/orders.personalization-remove-obsolete`, method: 'delete'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const useDeleteHomefieldApiOrdermanagementOrderspersonalizationremoveobsolete = <TData = AsyncReturnType<typeof deleteHomefieldApiOrdermanagementOrderspersonalizationremoveobsolete,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: RemovePersonalizationCommandObsolete}, TContext>, request?: SecondParameter<typeof fulfillmentApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {data: RemovePersonalizationCommandObsolete}, TContext>((props) => {
        const {data} = props || {};

        return  deleteHomefieldApiOrdermanagementOrderspersonalizationremoveobsolete<TData>(data,requestOptions)
      }, mutationOptions)
    }
    export const postHomefieldApiOrdermanagementOrderspersonalizationremove = <TData = ApiResponse>(
    removePersonalizationCommand: RemovePersonalizationCommand,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/ordermanagement/orders.personalization-remove`, method: 'post',
      data: removePersonalizationCommand
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePostHomefieldApiOrdermanagementOrderspersonalizationremove = <TData = AsyncReturnType<typeof postHomefieldApiOrdermanagementOrderspersonalizationremove,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: RemovePersonalizationCommand}, TContext>, request?: SecondParameter<typeof fulfillmentApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {data: RemovePersonalizationCommand}, TContext>((props) => {
        const {data} = props || {};

        return  postHomefieldApiOrdermanagementOrderspersonalizationremove<TData>(data,requestOptions)
      }, mutationOptions)
    }
    export const postHomefieldApiOrdermanagementOrderspersonalizationremovepricecalculation = <TData = ApiResponse>(
    calculatePriceChangeForPersonalizationRemoveCommandObsolete: CalculatePriceChangeForPersonalizationRemoveCommandObsolete,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/ordermanagement/orders.personalization-remove-price-calculation`, method: 'post',
      data: calculatePriceChangeForPersonalizationRemoveCommandObsolete
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePostHomefieldApiOrdermanagementOrderspersonalizationremovepricecalculation = <TData = AsyncReturnType<typeof postHomefieldApiOrdermanagementOrderspersonalizationremovepricecalculation,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: CalculatePriceChangeForPersonalizationRemoveCommandObsolete}, TContext>, request?: SecondParameter<typeof fulfillmentApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {data: CalculatePriceChangeForPersonalizationRemoveCommandObsolete}, TContext>((props) => {
        const {data} = props || {};

        return  postHomefieldApiOrdermanagementOrderspersonalizationremovepricecalculation<TData>(data,requestOptions)
      }, mutationOptions)
    }
    export const getHomefieldApiOrdermanagementLockersLockerIdLogos = <TData = LockerLogoDto[]>(
    lockerId: number,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/ordermanagement/lockers/${lockerId}/logos`, method: 'get'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiOrdermanagementLockersLockerIdLogosQueryKey = (lockerId: number,) => [`/homefield/api/ordermanagement/lockers/${lockerId}/logos`]

    
export const useGetHomefieldApiOrdermanagementLockersLockerIdLogos = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiOrdermanagementLockersLockerIdLogos, LockerLogoDto[]>, TError = unknown, TData = TQueryFnData>(
 lockerId: number, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof fulfillmentApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiOrdermanagementLockersLockerIdLogosQueryKey(lockerId);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiOrdermanagementLockersLockerIdLogos<TQueryFnData>(lockerId, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const getHomefieldApiOrdermanagementLockersLockerId = <TData = QueriesLockerDto>(
    lockerId: number,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/ordermanagement/lockers/${lockerId}`, method: 'get'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiOrdermanagementLockersLockerIdQueryKey = (lockerId: number,) => [`/homefield/api/ordermanagement/lockers/${lockerId}`]

    
export const useGetHomefieldApiOrdermanagementLockersLockerId = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiOrdermanagementLockersLockerId, QueriesLockerDto>, TError = unknown, TData = TQueryFnData>(
 lockerId: number, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof fulfillmentApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiOrdermanagementLockersLockerIdQueryKey(lockerId);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiOrdermanagementLockersLockerId<TQueryFnData>(lockerId, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const getHomefieldApiOrdermanagementLockers = <TData = QueriesLockerDto[]>(
    params?: GetHomefieldApiOrdermanagementLockersParams,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/ordermanagement/lockers`, method: 'get',
        params,
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiOrdermanagementLockersQueryKey = (params?: GetHomefieldApiOrdermanagementLockersParams,) => [`/homefield/api/ordermanagement/lockers`, ...(params ? [params]: [])]

    
export const useGetHomefieldApiOrdermanagementLockers = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiOrdermanagementLockers, QueriesLockerDto[]>, TError = unknown, TData = TQueryFnData>(
 params?: GetHomefieldApiOrdermanagementLockersParams, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof fulfillmentApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiOrdermanagementLockersQueryKey(params);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiOrdermanagementLockers<TQueryFnData>(params, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const postHomefieldApiOrdermanagementOrdersrefundwithcancel = <TData = ApiResponse>(
    refundOrderCommandObsolete: RefundOrderCommandObsolete,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/ordermanagement/orders.refund-with-cancel`, method: 'post',
      data: refundOrderCommandObsolete
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePostHomefieldApiOrdermanagementOrdersrefundwithcancel = <TData = AsyncReturnType<typeof postHomefieldApiOrdermanagementOrdersrefundwithcancel,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: RefundOrderCommandObsolete}, TContext>, request?: SecondParameter<typeof fulfillmentApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {data: RefundOrderCommandObsolete}, TContext>((props) => {
        const {data} = props || {};

        return  postHomefieldApiOrdermanagementOrdersrefundwithcancel<TData>(data,requestOptions)
      }, mutationOptions)
    }
    export const postHomefieldApiOrdermanagementOrderscouponapply = <TData = ApiResponse>(
    applyCouponToOrderCommandObsolete: ApplyCouponToOrderCommandObsolete,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/ordermanagement/orders.coupon-apply`, method: 'post',
      data: applyCouponToOrderCommandObsolete
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePostHomefieldApiOrdermanagementOrderscouponapply = <TData = AsyncReturnType<typeof postHomefieldApiOrdermanagementOrderscouponapply,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: ApplyCouponToOrderCommandObsolete}, TContext>, request?: SecondParameter<typeof fulfillmentApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {data: ApplyCouponToOrderCommandObsolete}, TContext>((props) => {
        const {data} = props || {};

        return  postHomefieldApiOrdermanagementOrderscouponapply<TData>(data,requestOptions)
      }, mutationOptions)
    }
    export const postHomefieldApiOrdermanagementOrdersvoucherapply = <TData = ApiResponse>(
    applyVoucherToOrderCommand: ApplyVoucherToOrderCommand,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/ordermanagement/orders.voucher-apply`, method: 'post',
      data: applyVoucherToOrderCommand
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePostHomefieldApiOrdermanagementOrdersvoucherapply = <TData = AsyncReturnType<typeof postHomefieldApiOrdermanagementOrdersvoucherapply,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: ApplyVoucherToOrderCommand}, TContext>, request?: SecondParameter<typeof fulfillmentApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {data: ApplyVoucherToOrderCommand}, TContext>((props) => {
        const {data} = props || {};

        return  postHomefieldApiOrdermanagementOrdersvoucherapply<TData>(data,requestOptions)
      }, mutationOptions)
    }
    export const getHomefieldApiOrdermanagementOrdersvoucherapplypricecalculation = <TData = ApplyVoucherToOrderPriceCalculationDtoApiResponse>(
    params?: GetHomefieldApiOrdermanagementOrdersvoucherapplypricecalculationParams,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/ordermanagement/orders.voucher-apply-price-calculation`, method: 'get',
        params,
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiOrdermanagementOrdersvoucherapplypricecalculationQueryKey = (params?: GetHomefieldApiOrdermanagementOrdersvoucherapplypricecalculationParams,) => [`/homefield/api/ordermanagement/orders.voucher-apply-price-calculation`, ...(params ? [params]: [])]

    
export const useGetHomefieldApiOrdermanagementOrdersvoucherapplypricecalculation = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiOrdermanagementOrdersvoucherapplypricecalculation, ApplyVoucherToOrderPriceCalculationDtoApiResponse>, TError = ApiResponse, TData = TQueryFnData>(
 params?: GetHomefieldApiOrdermanagementOrdersvoucherapplypricecalculationParams, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof fulfillmentApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiOrdermanagementOrdersvoucherapplypricecalculationQueryKey(params);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiOrdermanagementOrdersvoucherapplypricecalculation<TQueryFnData>(params, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const postHomefieldApiOrdermanagementOrderscouponapplypricecalculation = <TData = UpdateOrderItemPriceCalculationDtoApiResponse>(
    calculatePriceChangeForCouponApplyCommandObsolete: CalculatePriceChangeForCouponApplyCommandObsolete,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/ordermanagement/orders.coupon-apply-price-calculation`, method: 'post',
      data: calculatePriceChangeForCouponApplyCommandObsolete
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePostHomefieldApiOrdermanagementOrderscouponapplypricecalculation = <TData = AsyncReturnType<typeof postHomefieldApiOrdermanagementOrderscouponapplypricecalculation,UpdateOrderItemPriceCalculationDtoApiResponse>,
    TError = ApiResponse,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: CalculatePriceChangeForCouponApplyCommandObsolete}, TContext>, request?: SecondParameter<typeof fulfillmentApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {data: CalculatePriceChangeForCouponApplyCommandObsolete}, TContext>((props) => {
        const {data} = props || {};

        return  postHomefieldApiOrdermanagementOrderscouponapplypricecalculation<TData>(data,requestOptions)
      }, mutationOptions)
    }
    export const postHomefieldApiOrdermanagementOrdersrefunditemswithcancel = <TData = ApiResponse>(
    refundOrderItemCommandObsolete: RefundOrderItemCommandObsolete,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/ordermanagement/orders.refund-items-with-cancel`, method: 'post',
      data: refundOrderItemCommandObsolete
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePostHomefieldApiOrdermanagementOrdersrefunditemswithcancel = <TData = AsyncReturnType<typeof postHomefieldApiOrdermanagementOrdersrefunditemswithcancel,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: RefundOrderItemCommandObsolete}, TContext>, request?: SecondParameter<typeof fulfillmentApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {data: RefundOrderItemCommandObsolete}, TContext>((props) => {
        const {data} = props || {};

        return  postHomefieldApiOrdermanagementOrdersrefunditemswithcancel<TData>(data,requestOptions)
      }, mutationOptions)
    }
    export const postHomefieldApiOrdermanagementOrderitemscancel = <TData = ApiResponse>(
    cancelOrderItemCommand: CancelOrderItemCommand,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/ordermanagement/order-items.cancel`, method: 'post',
      data: cancelOrderItemCommand
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePostHomefieldApiOrdermanagementOrderitemscancel = <TData = AsyncReturnType<typeof postHomefieldApiOrdermanagementOrderitemscancel,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: CancelOrderItemCommand}, TContext>, request?: SecondParameter<typeof fulfillmentApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {data: CancelOrderItemCommand}, TContext>((props) => {
        const {data} = props || {};

        return  postHomefieldApiOrdermanagementOrderitemscancel<TData>(data,requestOptions)
      }, mutationOptions)
    }
    export const postHomefieldApiOrdermanagementVoucherinstancesOrders = <TData = Int32Int32DictionaryApiResponse>(
    getOrdersForVoucherInstancesQuery: GetOrdersForVoucherInstancesQuery,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/ordermanagement/voucher-instances/orders`, method: 'post',
      data: getOrdersForVoucherInstancesQuery
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePostHomefieldApiOrdermanagementVoucherinstancesOrders = <TData = AsyncReturnType<typeof postHomefieldApiOrdermanagementVoucherinstancesOrders,Int32Int32DictionaryApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: GetOrdersForVoucherInstancesQuery}, TContext>, request?: SecondParameter<typeof fulfillmentApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {data: GetOrdersForVoucherInstancesQuery}, TContext>((props) => {
        const {data} = props || {};

        return  postHomefieldApiOrdermanagementVoucherinstancesOrders<TData>(data,requestOptions)
      }, mutationOptions)
    }
    