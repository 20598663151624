/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Product Catalog API
 * OpenAPI spec version: v1
 */
import type { SkuInfo } from './skuInfo';
import type { ModeEnum } from './modeEnum';

export interface BulkUpdateProductVendorCommand {
  skus?: SkuInfo[] | null;
  mode?: ModeEnum;
  setStyleVendorCode?: boolean;
  skipNotFoundSkus?: boolean;
  skipNotFoundVendorCodes?: boolean;
}
