/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */
import type { DecorationTypeEnum } from './decorationTypeEnum';
import type { DecorationSizeEnum } from './decorationSizeEnum';
import type { DecorationFlagDto } from './decorationFlagDto';

export interface HagPrintRoomOrderAssemblyPagedListDto {
  type?: DecorationTypeEnum;
  text?: string | null;
  number?: string | null;
  previewUrl?: string | null;
  colorId?: number | null;
  outlineColorId?: number | null;
  decorationId?: number;
  decorationLocation?: string | null;
  decorationSize?: DecorationSizeEnum;
  decorationWidth?: number | null;
  decorationHeight?: number | null;
  completedOn?: string | null;
  flag?: DecorationFlagDto;
}
