/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */

export type PutHomefieldApiArtworkTasksreworkBody = {
  taskId?: number;
  reason?: string;
  notes?: string;
  fileName?: string;
};
