import React from 'react';
import {
  dtgEnvironmentsUrl,
  dtgArtworkSettingsUrl,
  dtgPersonalizationSettingsUrl,
  dtgEntitiesUrl,
  dtgStationsUrl,
  dtgTabletProfilesUrl,
} from '@constants/clientUrls/clientUrls';
import MenuTile from './MenuTile';

 
export const DtgEntitiesLink = React.memo(() => (
  <MenuTile
    title={'DTG Printer'}
    url={dtgEntitiesUrl}
    icon={'print'}
  />
));

export const DtgEnvironmentsLink = React.memo(() => (
  <MenuTile
    title={'DTG Environments'}
    url={dtgEnvironmentsUrl}
    icon={'landscape'}
  />
));

export const DtgArtworkLink = React.memo(() => (
  <MenuTile
    title={'DTG Artwork'}
    url={dtgArtworkSettingsUrl}
    icon={'format_shapes'}
  />
));

export const DtgPersonalizationLink = React.memo(() => (
  <MenuTile
    title={'DTG Personalization'}
    url={dtgPersonalizationSettingsUrl}
    icon={'text_format'}
  />
));

export const DtgStationsLink = React.memo(() => (
  <MenuTile
    title={'DTG Stations'}
    url={dtgStationsUrl}
    icon={'print'}
  />
));

export const DtgTabletProfilesLink = React.memo(() => (
  <MenuTile
    title={'DTG Tablet Profiles'}
    url={dtgTabletProfilesUrl}
    icon={'tablet'}
  />
));

export default DtgEntitiesLink;

 
