/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */
import type { OrderWarehouseStatusEnum } from './orderWarehouseStatusEnum';
import type { OrderInventoryStatusEnum } from './orderInventoryStatusEnum';
import type { DecorationMethodEnum } from './decorationMethodEnum';
import type { WarehouseSchedulingQueriesSortByEnum } from './warehouseSchedulingQueriesSortByEnum';
import type { SortDirectionEnum } from './sortDirectionEnum';

export type HomefieldApiWarehouseSchedulingOrdersGETParams = { orderWarehouseStatus?: OrderWarehouseStatusEnum; pageNumber?: number; pageSize?: number; inventoryStatus?: OrderInventoryStatusEnum; decorationMethod?: DecorationMethodEnum[]; minShipDate?: string; maxShipDate?: string; filter?: string; sortBy?: WarehouseSchedulingQueriesSortByEnum; sortDirection?: SortDirectionEnum };
