import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { lockerMgrS3Logos } from '@constants/common';
import {
  fetchManagerLogos,
  updateProductAssignment,
} from '@redux/lockerManager/actions';
import { materialSwal } from '@util/componentHelper';
import ImageActions from '@sharedComponents/ImageActions';
import Button from '@sharedComponents/Buttons/Button';
import RemoveLogosModal from '../../../LockerManagerModals/RemoveLogosModal';
import AddLogosModal from '../../../LockerManagerModals/AddLogosModal';
import TableLogo from './TableLogo';

class LockerManagerDetailsTableLogos extends Component {
  state = {
    modalIsOpen: false,
    removeModalIsOpen: false,
    selectedLogo: null,
    selectedLogos: [],
  };

  openModal = () => {
    const {
      dispatch,
      lockerId,
    } = this.props;

    dispatch(fetchManagerLogos(lockerId));
    this.setState({ modalIsOpen: true });
  };

  closeModal = () => {
    this.setState({
      modalIsOpen: false,
      selectedLogos: [],
    });
  };

  selectedLogos = (logo) => {
    this.setState((prevState) => ({
      selectedLogos: [
        ...prevState.selectedLogos,
        logo,
      ],
    }));
  };

  deselectLogo = (logo) => {
    this.setState((prevState) => ({
      selectedLogos: prevState.selectedLogos.filter((l) => l.id !== logo.id),
    }));
  };

  addLogos = () => {
    const {
      item,
      lockerId,
      search,
    } = this.props;

    const { selectedLogos } = this.state;

    const itemId = item.id;
    const updateProductAssignmentCalls = [];
    let countSuccess = 0;

    if (selectedLogos.length < 1) {
      materialSwal('Error', 'You haven\'t selected a logo!', 'error');
      this.openModal();
    } else {
      selectedLogos.forEach((logo) => {
        updateProductAssignmentCalls.push(updateProductAssignment(lockerId, logo.id, [itemId]));
      });

      Promise.all(updateProductAssignmentCalls).then((res) => {
        for (const r of res) {
          if (r?.success === true) {
            countSuccess++;
          }
        }
        if (countSuccess === res.length) {
          if (updateProductAssignmentCalls.length > 1) {
            materialSwal('Success', 'Logos have been successfully uploaded!', 'success');
          }
        } else {
          materialSwal('Error', 'Something went wrong.', 'error');
        }

        search();
        this.closeModal();
      });
    }
  };

  showRemoveLogoModal = (logo) => {
    this.setState({
      removeModalIsOpen: true,
      selectedLogo: logo,
    });
  };

  closeRemoveLogoModal = () => {
    this.setState({
      removeModalIsOpen: false,
      selectedLogo: null,
    });
  };

  removeLogo = async () => {
    const { selectedLogo } = this.state;
    const {
      item,
      lockerId,
      search,
    } = this.props;

    const itemId = item.id;

    try {
      const res = await updateProductAssignment(lockerId, selectedLogo.id, [], [itemId]);
      if (res?.success) {
        materialSwal('Success', 'Logo has been successfully deleted!', 'success');
      }
      this.closeRemoveLogoModal();
      search();
    } catch (e) {
      materialSwal('Error', e.message, 'error');
    }
  };

  isLogoActionVisible = (logoItem) => logoItem?.uuid ? true : false;

  copyUuidToClipboard = (logoItem) => {
    navigator.clipboard.writeText(logoItem?.uuid);
  };

  renderLogoItem = (item) => {
    const { allDecorationLocations } = this.props;
    const decoration = allDecorationLocations.find((d) => d.code === item.decoration_location);
    const decorationLocationDisplay = `${item.decoration_location}${decoration ? ` - ${decoration.label}` : ''}`;

    if (item.logos.length === 1) {
      const logo = item.logos[0];

      return (
        <div className='decorationList__column'>
          <span
            key={0}
            className='decorationList__preview-item'
          >
            <div className='decorationList__preview-item--logo'>
              <img src={`${lockerMgrS3Logos}/${logo.image}`} />
            </div>
            {
              !logo.artwork_approved
                ? (
                  <div className='logoList__logopreview--unapproved-label'>
                    <span className='logo-bank__used--logo--unapproved-label'>
                      <span className='material-icons logo-bank__used--logo--unapproved-label__icon'>warning</span>
                      Unapproved
                    </span>
                  </div>
                )
                : <div className='decorationList__preview-location'>{decorationLocationDisplay}</div>
            }
          </span>
        </div>
      );
    }
  };

  render() {
    const {
      item,
      logoBank,
      enableMultipleLogos,
    } = this.props;

    const {
      removeModalIsOpen,
      modalIsOpen,
      selectedLogos,
    } = this.state;

    const imageActionsData = [
      {
        icon: 'close',
        text: 'Remove logo from item',
        action: this.showRemoveLogoModal,
      },
      {
        icon: 'content_copy',
        text: 'Copy CLC UUID',
        action: this.copyUuidToClipboard,
        isVisible: this.isLogoActionVisible,
        color: '#ffffff', // TODO add to constants
        backgroundColor: 'rgb(2, 148, 135, 0.8)', // TODO add to constants
      },
    ];

    const logos = item.logos.length > 1
      ? item.logos.map((logo, index) => (
        <span
          key={index}
          className='logoList__logopreview--logo-container'
        >
          <ImageActions
            key={index}
            item={logo}
            image={`${lockerMgrS3Logos}/${logo.image}`}
            imageActionsData={imageActionsData}
          />
        </span>
      ))
      : this.renderLogoItem(item);

    const logosFromLogoBank = logoBank.map((logo) => (
      <TableLogo
        key={logo.id}
        logo={logo}
        selectedLogos={this.selectedLogos}
        deselectLogo={this.deselectLogo}
        item={item}
      />
    ));

    return (
      <div>
        <RemoveLogosModal
          isOpen={removeModalIsOpen}
          closeModal={this.closeRemoveLogoModal}
          removeLogo={this.removeLogo}
        />
        <AddLogosModal
          isOpen={modalIsOpen}
          closeModal={this.closeModal}
          addLogos={this.addLogos}
          item={item}
          logosFromLogoBank={logosFromLogoBank}
          selectedLogos={selectedLogos}
        />
        <div className='logoList__container'>
          {logos}
          {
            enableMultipleLogos &&
            <Button
              type={'primary'}
              text={'+ Add logo'}
              onClick={this.openModal}
              classes={'button--color-bali-hai ml-20'}
            />
          }
        </div>
      </div>
    );
  }
}

LockerManagerDetailsTableLogos.propTypes = {
  item: PropTypes.object.isRequired,
  logoBank: PropTypes.array.isRequired,
  lockerId: PropTypes.number.isRequired,
  search: PropTypes.func.isRequired,
  enableMultipleLogos: PropTypes.bool,
  allDecorationLocations: PropTypes.array.isRequired,
};

const mapStateToProps = ({ lockerManager }) => ({
  logoBank: lockerManager.managerLogos,
  enableMultipleLogos: lockerManager.lockerInfo.enableMultipleLogos,
});

export default connect(mapStateToProps)(LockerManagerDetailsTableLogos);
