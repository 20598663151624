/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Financials Homefield API
 * OpenAPI spec version: v1
 */
import type { PayoutStatusEnum } from './payoutStatusEnum';

export interface PayoutStatusDto {
  id?: number;
  uuid?: string | null;
  created?: string | null;
  updated?: string | null;
  deleted?: string | null;
  payoutId?: number;
  status?: PayoutStatusEnum;
  userId?: string | null;
}
