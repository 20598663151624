import React, { PureComponent } from 'react';
import {
  Field,
  FieldArray,
} from 'redux-form';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  decorationMethodEnum,
  decorationTypeEnum,
} from '@constants/enums/decorationEnums';
import PermissionsEnum from '@constants/enums/permissionsEnum';
import {
  getDecorationMethodsOptions,
  getTrueOrFalseOptions,
} from '@constants/options/options';
import { getDecorationLocations } from '@redux/productCatalog/actions';
import { generalOptions } from '@util/optionsMap';
import { parseStringToBoolean } from '@util/valueHelpers';
import SearchInput from '@sharedComponents/Form/SearchInput/SearchInput';
import Select from '@sharedComponents/Form/Select';
import PermissionHandler from '@sharedComponents/Authorization/PermissionHandler';
import LayoutsInput from '@sharedComponents/Layouts/LayoutsInput';
import EditDecorationLocationsInput from '../ProductCatalogModals/EditStyleModal/EditDecorationLocationsInput';
import LayoutsInputErrors from '../ProductCatalogModals/EditStyleModal/LayoutsInputErrors';

class DecorationsAndLayoutsTabContent extends PureComponent {
  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(getDecorationLocations(decorationTypeEnum.Logo));
    dispatch(getDecorationLocations(decorationTypeEnum.Personalization));
  }

  renderLogoLocations = ({
    fields,
    currentDefaultLogo,
    logoDecorationLocations,
  }) => (
    <EditDecorationLocationsInput
      key={1}
      allDecorationLocations={logoDecorationLocations}
      disabled={currentDefaultLogo ? [currentDefaultLogo] : []}
      decorationTypeText={`Additional Custom ${decorationTypeEnum.Logo}`}
      decorationType={decorationTypeEnum.Logo}
      fields={fields}
    />
  );

  renderPersonalizationLocations = ({
    fields,
    defaultPersonalizations,
    personalizationDecorationLocations,
  }) => (
    <EditDecorationLocationsInput
      key={2}
      allDecorationLocations={personalizationDecorationLocations}
      disabled={defaultPersonalizations}
      decorationTypeText={`Additional Custom ${decorationTypeEnum.Personalization}`}
      decorationType={decorationTypeEnum.Personalization}
      fields={fields}
    />
  );

  renderDefaultPersonalizations = ({
    fields,
    customOnlyPersonalizations,
    currentDefaultLogo,
    personalizationDecorationLocations,
  }) => {
    const disabled = [...customOnlyPersonalizations];
    if (currentDefaultLogo) {
      disabled.push(currentDefaultLogo);
    }

    return (
      <EditDecorationLocationsInput
        key={3}
        allDecorationLocations={personalizationDecorationLocations}
        disabled={disabled}
        decorationTypeText={`Default ${decorationTypeEnum.Personalization}`}
        decorationType={decorationTypeEnum.Personalization}
        fields={fields}
      />
    );
  };

  renderLayouts = ({
    fields,
    meta: { error },
    logoDecorationLocations,
    personalizationDecorationLocations,
    validationErrors,
  }) => (
    <>
      <LayoutsInput
        fields={fields}
        isDropUp={true}
      />
      <LayoutsInputErrors
        formErrors={error}
        validationErrors={validationErrors}
        decorationLocations={logoDecorationLocations.concat(personalizationDecorationLocations)}
        addLocation={this.addLocation}
      />
    </>
  );

  changeDefaultLogo = async (locationId) => {
    const {
      change,
      logoDecorationLocations,
    } = this.props;

    if (locationId === null) {
      await change('defaultLogoLocation', null);

      return;
    }

    const locationCode = logoDecorationLocations.find((l) => l.id === locationId).code;
    const decorationLocation = {
      type: decorationTypeEnum.Logo,
      locationId,
      location: locationCode,
    };

    await change('defaultLogoLocation', decorationLocation);
  };

  addLocation = async (location) => {
    const {
      change,
      logoLocations,
      customOnlyPersonalizations,
    } = this.props;

    if (location.type === decorationTypeEnum.Logo) {
      await change('customOnlyLogos', [
        ...logoLocations,
        location,
      ]);
    } else if (location.type === decorationTypeEnum.Personalization) {
      await change('customOnlyPersonalizations', [
        ...customOnlyPersonalizations,
        location,
      ]);
    }
  };

  render() {
    const {
      disabled = {},
      decorationMethod,
      customizable,
      currentDefaultLogo,
      logoLocations = [],
      defaultPersonalizations,
      logoDecorationLocations,
      personalizationDecorationLocations,
      customOnlyPersonalizations,
      layoutValidationErrors,
    } = this.props;

    const decorationMethodsOptions = generalOptions(getDecorationMethodsOptions(true, 'None'));
    const trueOrFalseOptions = generalOptions(getTrueOrFalseOptions());
    const defaultLogoLocationOptions = logoDecorationLocations.map((l) => ({
      type: decorationTypeEnum.Logo,
      locationId: l.id,
      location: l.code,
    }));

    const disabledDefaultLogoLocations = logoLocations.concat(defaultPersonalizations);

    return (
      <>
        <div className='tableView__details--header--title mb-30'>
          <span>Decorations</span>
        </div>
        <div className='redux-form__row ml-10'>
          <div className='redux-form__column--size-s'>
            <label className='redux-form__label'>
              Customizable
            </label>
            <Field
              name={'customizable'}
              component={Select}
              disabled={disabled.customizable}
              parse={parseStringToBoolean}
            >
              {trueOrFalseOptions}
            </Field>
          </div>
        </div>

        <PermissionHandler permissions={PermissionsEnum.ProductCatalogEdit}>
          <div className='redux-form__row ml-10'>
            <div className='redux-form__column--size-s'>
              <label className='redux-form__label'>
                Method
              </label>
              <Field
                name={'decorationMethod'}
                component={Select}
                disabled={disabled.decorationMethod}
              >
                {decorationMethodsOptions}
              </Field>
            </div>
          </div>
        </PermissionHandler>

        <PermissionHandler
          permissions={PermissionsEnum.ProductCatalogEdit}
          withCondition={decorationMethod !== decorationMethodEnum.Undefined}
        >
          <div className='redux-form__row mb-30 ml-10'>
            <div className='redux-form__column--size-s'>
              <label className='redux-form__label'>
                Default Logo Location
              </label>
              <Field
                name={'defaultLogoLocation'}
                component={SearchInput}
                disabled={disabled.defaultLogoLocation}
                allItems={defaultLogoLocationOptions}
                disabledItems={disabledDefaultLogoLocations}
                currentItem={currentDefaultLogo?.locationId}
                changeItem={this.changeDefaultLogo}
                searchKey={'locationId'}
                displayKey={'location'}
                returnWholeItem={true}
                placeholderText={'Search default decoration location'}
                isFixed={false}
              />
            </div>
          </div>
        </PermissionHandler>

        {
          decorationMethod !== decorationMethodEnum.Undefined && (customizable === 'true' || customizable === true) &&
          <div className='redux-form__row mb-30 ml-10'>
            <div className='redux-form__column--size-xl'>
              <div className='redux-form__row'>
                <FieldArray
                  name={'customOnlyLogos'}
                  component={this.renderLogoLocations}
                  currentDefaultLogo={currentDefaultLogo}
                  logoDecorationLocations={logoDecorationLocations}
                  rerenderOnEveryChange={true}
                />
              </div>
            </div>
          </div>
        }

        <PermissionHandler
          permissions={PermissionsEnum.ProductCatalogEdit}
          withCondition={decorationMethod !== decorationMethodEnum.Undefined}
        >
          <div className='redux-form__row mb-30 ml-10'>
            <div className='redux-form__column--size-xl'>
              <div className='redux-form__row'>
                <FieldArray
                  name={'defaultPersonalizations'}
                  component={this.renderDefaultPersonalizations}
                  customOnlyPersonalizations={customOnlyPersonalizations}
                  currentDefaultLogo={currentDefaultLogo}
                  personalizationDecorationLocations={personalizationDecorationLocations}
                  rerenderOnEveryChange={true}
                />
              </div>
            </div>
          </div>
        </PermissionHandler>

        {
          decorationMethod !== decorationMethodEnum.Undefined && (customizable === 'true' || customizable === true) &&
          <div className='redux-form__row mb-30 ml-10'>
            <div className='redux-form__column--size-xl'>
              <div className='redux-form__row'>
                <FieldArray
                  name={'customOnlyPersonalizations'}
                  component={this.renderPersonalizationLocations}
                  defaultPersonalizations={defaultPersonalizations}
                  personalizationDecorationLocations={personalizationDecorationLocations}
                  rerenderOnEveryChange={true}
                />
              </div>
            </div>
          </div>
        }

        <PermissionHandler permissions={PermissionsEnum.ProductCatalogEdit}>
          <div className='tableView__details--header--title mt-30 mb-30'>
            <span>Layouts</span>
          </div>
          <FieldArray
            name={'layoutsInputList'}
            component={this.renderLayouts}
            logoDecorationLocations={logoDecorationLocations}
            personalizationDecorationLocations={personalizationDecorationLocations}
            validationErrors={layoutValidationErrors}
            rerenderOnEveryChange={true}
          />
        </PermissionHandler>
      </>
    );
  }
}

DecorationsAndLayoutsTabContent.propTypes = {
  disabled: PropTypes.object,
  decorationMethod: PropTypes.string,
  customizable: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  currentDefaultLogo: PropTypes.object,
  logoLocations: PropTypes.arrayOf(PropTypes.object),
  defaultPersonalizations: PropTypes.arrayOf(PropTypes.object),
  logoDecorationLocations: PropTypes.arrayOf(PropTypes.object).isRequired,
  personalizationDecorationLocations: PropTypes.arrayOf(PropTypes.object).isRequired,
  customOnlyPersonalizations: PropTypes.arrayOf(PropTypes.object),
  layoutsInputList: PropTypes.arrayOf(PropTypes.object).isRequired,
  layoutValidationErrors: PropTypes.object,
  change: PropTypes.func.isRequired,
};

const mapStateToProps = ({
  productCatalog,
  layouts,
}) => ({
  logoDecorationLocations: productCatalog.logoDecorationLocations,
  personalizationDecorationLocations: productCatalog.personalizationDecorationLocations,
  layoutValidationErrors: layouts.layoutValidationErrors,
});

export default connect(mapStateToProps)(DecorationsAndLayoutsTabContent);
