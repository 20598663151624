import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button, ButtonGroup, makeStyles, Paper, Popover, Snackbar, TextField, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { ThemeProvider } from '@material-ui/core/styles';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import { useGetColoredStylesForStyleBySku } from '@api/squadlockerServices/styles';
import yup from '@util/yupValidationHelper';
import { useBulkUpdateDesignByStyleId, useCreateDesign, useDeleteDesign, useGetDesignByColoredStyle, useGetDesignBySku, useUpdateDesign } from '@api/squadlockerServices/designs';

import useMouseDown from './useMouseDown';
import NewHookformSelect from '@components/shared/Form/NewHookFormSelect';
import { designLocationMeasureFromOptionsRaw } from '@constants/squimgur';
import StylesMissingDesignsTable from './StylesMissingDesignsTable.tsx';
import { muiTheme } from '@constants/values';
import HookformInput from '@components/shared/Form/HookformInput';
import noImage from '@assets/no_image.png';
import tenBlack from '@assets/squimgur/10x8_black.png';
import tenWhite from '@assets/squimgur/10x8_white.png';
import horizontalWhite from '@assets/squimgur/horizontal_white.png';
import horizontalBlack from '@assets/squimgur/horizontal_black.png';
import verticalWhite from '@assets/squimgur/vertical_white.png';
import verticalBlack from '@assets/squimgur/vertical_black.png';

import { Alert, Autocomplete } from '@material-ui/lab';
import { lockerMgrS3GridFiles, lockerMgrS3Products } from '@constants/common';
import useDebounce from '@hooks/useDebounce';
import { getQueryClientInstance } from '@util/queryClientHelper';

const initialText = 'Select or find a style by sku to get started...';

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    position: 'relative',
    width: '100%',
  },
  image: {
    height: 400,
    width: 400,
  },
  smallImage: {
    height: 50,
    width: 50,
    cursor: 'pointer',
  },
  loading: {
    height: 400,
    width: 400,
    background: 'red',
    display: 'none',
  },
  styleInput: {
    width: 150,
  },
  formGridItem: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    top: '4px',
  },
  imageGridItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  coloredStyleGridItem: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  textField: {
    minWidth: 'initial!important',
  },
  selectInput: {
    height: 27,
  },
  csImageGridItem: {
    display: 'flex',
    justifyContent: 'center',
    marginRight: 2,
  },
  imagePaper: {
    padding: 5,
  },
  flexEnd: {},
  findButton: {
    marginLeft: 5,
  },
  availableColoredStylesPaper: {
    padding: 20,
    display: 'flex',
    flexWrap: 'wrap',
  },
}));

const defaultValues = {
  locationX: 150,
  locationY: 150,
  baseWidth: 100,
  rotation: 0,
  position: 'top',
};
const rootUrl = process.env.PUBLIC_HOME_URL;

const logoOptions = [
  {
    value: 'https://teamlocker.s3.amazonaws.com/uploads/7451b6309f94d9debac73f0613f3cc51340b4de6sld26_warwick_02_darks_EMB.png',
    label: 'Warwick Logo',
  },
  {
    value: rootUrl + tenBlack,
    label: '10 x 8 - Black',
  },
  {
    value: rootUrl + tenWhite,
    label: '10 x 8 - White',
  },
  {
    value: rootUrl + verticalBlack,
    label: 'Vertical - Black',
  },
  {
    value: rootUrl + verticalWhite,
    label: 'Vertical - White',
  },
  {
    value: rootUrl + horizontalBlack,
    label: 'Horizontal - Black',
  },
  {
    value: rootUrl + horizontalWhite,
    label: 'Horizontal - White',
  },
];

const DesignConfiguration = () => {
  const classes = useStyles();

  const queryClient = getQueryClientInstance();

  const tableRef = useRef();

  // Begin State
  const [keyPressed, setKeyPressed] = useState('');
  const [snackBarOpened, setSnackBarOpened] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [selectedStyleId, setSelectedStyleId] = useState(null);
  const [selectedSku, setSelectedSku] = useState(null);
  const debouncedSelectedSku = useDebounce(selectedSku, 500);
  const [currentColoredStyle, setCurrentColoredStyle] = useState(null);
  const [logo, setLogo] = useState(logoOptions[0].value);
  const [frontImage, setFrontImage] = useState(null);
  const [lpGrid, setLpGrid] = useState(null);
  const [squimgurLoading, setSquimgurLoading] = useState(false);
  const [lpLoading, setLpLoading] = useState(false);
  const [liquidPixelsError, setLiquidPixelsError] = useState(false);
  const [toolTipAnchorEl, setToolTipAnchorEl] = React.useState(null);
  // End State

  const schema = yup.object({
    locationX: yup.number().required('Location X is required.'),
    locationY: yup.number().required('Location Y is required.'),
    rotation: yup.number().required('Rotation is required.'),
    baseWidth: yup.number().min(10, 'Must be greater than 10')
      .required('Logo Width is required.'),
  });

  const {
    control,
    setValue,
    reset,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const [locationX, locationY, baseWidth, rotation, position] = watch(['locationX', 'locationY', 'baseWidth', 'rotation', 'position']);

  useEffect(() => {
    const handleWheel = (event) => {
      event.preventDefault();
      if (
        keyPressed === 'r'
        || keyPressed === 't'
      ) { return; }

      if (event.ctrlKey) {
        const zoomFactor = 0.1;
        const currentZoom = parseFloat(document.body.style.zoom || 1);
        if (event.deltaY < 0) {
          document.body.style.zoom = currentZoom + zoomFactor;
        }
        if (event.deltaY > 0) {
          document.body.style.zoom = Math.max(currentZoom - zoomFactor, zoomFactor);
        }

        return;
      }

      if (event.shiftKey) {
        window.scrollBy(event.deltaY, 0);

        return;
      }

      window.scrollBy(0, event.deltaY);
    };

    const handleKeyDown = (event) => {
      if (event.key === keyPressed) { return; }
      setKeyPressed(event.key);
    };

    const handleKeyUp = () => {
      setKeyPressed('');
    };

    window.addEventListener('wheel', handleWheel, { passive: false });
    window.addEventListener('keydown', handleKeyDown, { passive: false });
    window.addEventListener('keyup', handleKeyUp, { passive: false });

    return () => {
      window.removeEventListener('wheel', handleWheel, { passive: false });
      window.removeEventListener('keydown', handleKeyDown, { passive: false });
      window.removeEventListener('keyup', handleKeyUp, { passive: false });
    };
  }, [keyPressed]);

  // Track Mouse Movements
  const { selectedBind } = useMouseDown((x) => setValue('locationX', x), (y) => setValue('locationY', y));

  // Begin React Query
  const requestOptions = { request: { isBlockingRequest: true } };

  const { mutateAsync: createNewDesign } = useCreateDesign(requestOptions);
  const { mutateAsync: deleteDesign } = useDeleteDesign(requestOptions);
  const { mutateAsync: updateDesign } = useUpdateDesign(requestOptions);
  const { mutateAsync: bulkUpdateDesign } = useBulkUpdateDesignByStyleId(requestOptions);

  const {
    data: coloredStyles = null,
  } = useGetColoredStylesForStyleBySku(debouncedSelectedSku, {
    query: {
      enabled: debouncedSelectedSku !== null && debouncedSelectedSku !== '',
      onSuccess: (data) => {
        setSelectedStyleId(data[0].style_id);
      },
    },
  });

  const {
    data: design,
    refetch: refetchDesignForColoredStyle,
    isLoading: isDesignLoading,
  } = useGetDesignByColoredStyle(currentColoredStyle?.id, {
    query: {
      queryKey: `currentColoredStyle-${currentColoredStyle?.id}`,
      enabled: currentColoredStyle !== null,
      onError: (err) => err.statusCode === 404 ? queryClient.setQueryData(`currentColoredStyle-${currentColoredStyle?.id}`, null) : null,
    },
  });

  const {
    data: designsForCurrentStyle,
    refetch: refetchDesignsForCurrentStyle,
  } = useGetDesignBySku(debouncedSelectedSku, { query: { enabled: debouncedSelectedSku !== null } });
  // End React Query

  const handleAutoCompleteGetOptionLabel = useCallback((option) => option.label ?? '', []);

  const handleAutoCompleteOnChange = useCallback((event, value) => {
    if (value?.value) {
      setLogo(value?.value);
    }
  }, []);

  const handleToolTipClick = useCallback((event) => {
    setToolTipAnchorEl(event.currentTarget);
  }, []);

  const handleFormKeydown = useCallback((event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  }, []);

  const handleToolTipClose = useCallback(() => {
    setToolTipAnchorEl(null);
  }, []);

  const handleSnackBarClosed = useCallback((event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackBarOpened(false);
  }, []);

  useEffect(() => {
    setCurrentColoredStyle(null);
    setLpGrid(null);
    setFrontImage(null);
  }, [debouncedSelectedSku]);

  // Handle reset of form when new style is selected
  useEffect(() => {
    if (design?.locations && design.locations.length > 0) {
      reset({
        locationX: design.locations[0].location_x,
        locationY: design.locations[0].location_y,
        rotation: design.locations[0].rotation,
        baseWidth: design.locations[0].base_width,
        position: design.locations[0].measure_from,
      });
    }
  }, [design, reset]);

  const handleStyleSelected = useCallback((sku) => {
    setSelectedSku(sku);

    reset(defaultValues);

    setCurrentColoredStyle(null);
    setFrontImage(null);
    setLpGrid(null);

    refetchDesignsForCurrentStyle();
  }, [refetchDesignsForCurrentStyle, reset]);

  const handleTypedStyleIdChange = useCallback((event) => {
    setSelectedSku(event.target.value);
  }, []);

  const refetchAll = useCallback(async () => {
    await refetchDesignForColoredStyle();
    await refetchDesignsForCurrentStyle();
    await tableRef.current?.refetchTableData();
  }, [refetchDesignForColoredStyle, refetchDesignsForCurrentStyle]);

  const showSnackBar = useCallback(async (message) => {
    setSnackBarOpened(true);
    setSnackBarMessage(message);
    await refetchAll();
  }, [refetchAll]);

  async function handleCreate(data) {
    const {
      id: coloredStyleId,
      color_group: colorGroup,
      style: {
        name: coloredStyleName,
      },
    } = currentColoredStyle;

    const payload = {
      name: `${coloredStyleName ?? currentColoredStyle.code ?? currentColoredStyle.id} ${colorGroup}`,
      coloredStyleId: +coloredStyleId,
      designLocations: [
        {
          locationX: data.locationX,
          locationY: data.locationY,
          baseWidth: data.baseWidth,
          rotation: data.rotation,
          measureFrom: data.position,
        },
      ],
    };

    const res = await createNewDesign({ data: payload });
    if (res.statusCode === 200) {
      await showSnackBar(res.message);
    }
  }

  const handleDelete = useCallback(async () => {
    const res = await deleteDesign({ designId: design.id });
    if (res.statusCode === 200) {
      handleStyleSelected(selectedSku);
      await showSnackBar(res.message);
    }
  }, [deleteDesign, design?.id, handleStyleSelected, selectedSku, showSnackBar]);

  async function handleUpdate(data) {
    const locationData = {
      locationX: data.locationX,
      locationY: data.locationY,
      baseWidth: data.baseWidth,
      rotation: data.rotation,
      measureFrom: data.position,
    };
    const locationId = design?.locations[0]?.id;
    const parameters = {
      designLocationId: locationId,
      data: locationData,
    };

    const res = await updateDesign(parameters);
    if (res.statusCode === 200) {
      await showSnackBar(res.message);
    }
  }

  async function handleBulkUpdate(data) {
    const locationData = {
      locationX: data.locationX,
      locationY: data.locationY,
      baseWidth: data.baseWidth,
      rotation: data.rotation,
      measureFrom: data.position,
    };

    const parameters = {
      styleId: selectedStyleId,
      data: locationData,
    };

    const res = await bulkUpdateDesign(parameters);
    if (res.statusCode === 200) {
      await showSnackBar(res.message);
    }
  }

  const handleImageError = useCallback((error) => {
    if (error.target.id === 'squimgur-image') { error.target.src = noImage; }
    if (error.target.id === 'lp-image') { setLiquidPixelsError(true); }
  }, []);

  const handleWheelActions = useCallback((event) => {
    if (keyPressed === 'r') {
      if (event.deltaY > 0) {
        setValue('rotation', rotation - 1);
      }
      if (event.deltaY < 0) {
        setValue('rotation', rotation + 1);
      }
    } else if (keyPressed === 't') {
      if (event.deltaY > 0) {
        setValue('baseWidth', baseWidth - 1);
      }
      if (event.deltaY < 0) {
        setValue('baseWidth', baseWidth + 1);
      }
    }
  }, [setValue, rotation, baseWidth, keyPressed]);

  const handleSquimgurLoaded = useCallback(() => {
    setSquimgurLoading(false);
  }, []);

  const handleLPLoaded = useCallback(() => {
    setLpLoading(false);
  }, []);

  const handleLogoUpdateFromTextfield = useCallback((event) => {
    setLogo(event.target.value);
  }, []);

  const handleMicroMove = useCallback((event) => {
    if (event.key === 'w') {
      setValue('locationY', locationY - 2);
    }
    if (event.key === 's') {
      setValue('locationY', locationY + 2);
    }
    if (event.key === 'a') {
      setValue('locationX', locationX - 2);
    }
    if (event.key === 'd') {
      setValue('locationX', locationX + 2);
    }
  }, [locationX, locationY, setValue]);

  const getImageFrontUrl = useCallback((imageFront) => (
    `${lockerMgrS3Products}/${imageFront}.png`
  ), []);

  const handleColoredStyleChange = useCallback((event) => {
    setLpLoading(true);
    setSquimgurLoading(true);

    const { id } = event.target;
    const cs = coloredStyles.find((c) => c.id === parseInt(id));
    if (cs) {
      setCurrentColoredStyle(cs);
      setFrontImage(`${lockerMgrS3Products}/${cs.image_front}.png`);
      setLpGrid(`${lockerMgrS3GridFiles}/${cs.image_grid}`);
    }

    setLpLoading(false);
    setSquimgurLoading(false);
  }, [coloredStyles]);

  const liquidPixels = useMemo(() =>
    `${process.env.PUBLIC_LP_URL}?set=gridPath[${lpGrid}],`
    + `productPath[${frontImage}],`
    + 'alignLogo[Center],scaleLogo[100%],'
    + `logoPath[${logo}]&`
    + 'call=url[file:previewTextRasterArtworkTextNoDL.chain]&sink=format[webp]', [lpGrid, frontImage, logo]);

  const squimgur = useMemo(() => {
    let imgStr = `${process.env.PUBLIC_SQUIMGUR_URL}/image/front?set=image[${frontImage}],`;
    imgStr = logo !== '' ? imgStr + `logo[${logo}],` : imgStr;
    imgStr = locationX !== '' ? imgStr + `location_x[${locationX}],` : imgStr;
    imgStr = locationY !== '' ? imgStr + `location_y[${locationY}],` : imgStr;
    imgStr = rotation !== '' ? imgStr + `rotation[${rotation}],` : imgStr;
    imgStr = baseWidth !== '' ? imgStr + `base_width[${baseWidth}],` : imgStr;
    imgStr = position !== '' ? imgStr + `pos[${position}],` : imgStr;
    imgStr += 'name[test]';

    return imgStr;
  }, [
    frontImage,
    logo,
    locationX,
    locationY,
    rotation,
    baseWidth,
    position,
  ]);

  const autoCompleteRenderInput = useCallback((params) => (
    <TextField
      onChange={handleLogoUpdateFromTextfield}
      variant='standard'
      size='small'
      {...params}
      label='Logo Url'
      margin='normal'
    />
  ), [handleLogoUpdateFromTextfield]);

  return (
    <ThemeProvider theme={muiTheme}>
      <Box className={classes.mainContainer}>
        <form
          noValidate={true}
          id='design-configuration-form'
          onKeyDown={handleFormKeydown}
        >
          <Grid container={true} spacing={1}>
            <Grid container={true} item={true} xs={12} spacing={1}>
              <Grid item={true} xs={2}>
                <Autocomplete
                  freeSolo={true}
                  id='logo'
                  getOptionLabel={handleAutoCompleteGetOptionLabel}
                  defaultValue={logoOptions[0]}
                  options={logoOptions}
                  onChange={handleAutoCompleteOnChange}
                  renderInput={autoCompleteRenderInput}
                />
              </Grid>
              <Grid item={true} xs={1} className={classes.formGridItem}>
                <HookformInput
                  name='locationX'
                  control={control}
                  placeholder='X-Coord of logo'
                  error={errors.locationX?.message}
                  required={true}
                  type='number'
                  autoFocus={true}
                  label='Location X'
                  className={classes.textField}
                />
              </Grid>
              <Grid item={true} xs={1} className={classes.formGridItem}>
                <HookformInput
                  name='locationY'
                  control={control}
                  placeholder='Y-Coord of logo'
                  error={errors.locationY?.message}
                  required={true}
                  type='number'
                  autoFocus={true}
                  label='Location Y'
                  className={classes.textField}
                />
              </Grid>
              <Grid item={true} xs={1} className={classes.formGridItem}>
                <HookformInput
                  name='rotation'
                  control={control}
                  placeholder='Rotation of logo'
                  error={errors.rotation?.message}
                  required={true}
                  type='number'
                  autoFocus={true}
                  label='Rotation'
                  className={classes.textField}
                />
              </Grid>
              <Grid item={true} xs={1} className={classes.formGridItem}>
                <HookformInput
                  name='baseWidth'
                  control={control}
                  placeholder='Width of logo'
                  error={errors.baseWidth?.message}
                  required={true}
                  type='number'
                  autoFocus={true}
                  label='Logo Width'
                  className={classes.textField}
                />
              </Grid>
              <Grid item={true} xs={2} className={classes.formGridItem}>
                <NewHookformSelect
                  id='position'
                  name='position'
                  placeholder='Select where to measure from'
                  label='Measure From'
                  error={errors?.position?.message}
                  control={control}
                  options={designLocationMeasureFromOptionsRaw}
                  inputClassName={`${classes.textField} ${classes.selectInput}`}
                />
              </Grid>
              <Grid item={true} xs={2} className={classes.formGridItem}>
                <Snackbar
                  open={snackBarOpened}
                  autoHideDuration={6000}
                  onClose={handleSnackBarClosed}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                >
                  <Alert onClose={handleSnackBarClosed} severity='success'>
                    {snackBarMessage}
                  </Alert>
                </Snackbar>
                {!isDesignLoading && currentColoredStyle
                  ? (
                    <ButtonGroup variant='contained' color='primary'>
                      {!design && <Button size='small' id={'create'} type='submit' onClick={handleSubmit(handleCreate)}>Create</Button>}
                      {design && <Button size='small' id={'update'} type='submit' onClick={handleSubmit(handleUpdate)}>Update</Button>}
                      {design && <Button size='small' id={'bulk-update'} type='submit' onClick={handleSubmit(handleBulkUpdate)}>Update All</Button>}
                      {design && <Button size='small' id={'delete'} onClick={handleDelete}>Delete</Button>}
                    </ButtonGroup>
                  )
                  : null}
              </Grid>
              <Grid item={true} xs={2} className={`${classes.formGridItem}`}>
                <TextField
                  type='text'
                  label='Parent SKU'
                  placeholder='Enter Sku to Search'
                  className={classes.styleInput}
                  onChange={handleTypedStyleIdChange}
                  value={selectedSku ?? ''}
                />
              </Grid>
            </Grid>
            <Grid container={true} item={true} xs={12}>
              <Button variant='contained' color='primary' size='small' onClick={handleToolTipClick}>
                Shortcut Help
              </Button>
              <Popover
                open={toolTipAnchorEl !== null}
                anchorEl={toolTipAnchorEl}
                onClose={handleToolTipClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <Typography
                  className={classes.typography}
                  style={{ padding: '10px' }}
                >
                  Hover over logo and....<br />
                  Hold R and scroll to rotate<br />
                  Hold T and scroll to change width<br />
                  Click A, S, W, D to micro move
                </Typography>
              </Popover>
            </Grid>
            <Grid container={true} item={true} xs={12} spacing={3}>
              <Grid item={true} xs={5} className={classes.imageGridItem}>
                <Typography>Squimgur {squimgurLoading && 'Loading...'}</Typography>
                <Paper className={classes.imagePaper}>
                  {frontImage
                    ? (
                      <img
                        {...selectedBind}
                        tabIndex={'-1'}
                        onKeyUp={handleMicroMove}
                        onWheel={handleWheelActions}
                        id='squimgur-image'
                        src={squimgur}
                        className={squimgurLoading ? classes.loading : classes.image}
                        onLoad={handleSquimgurLoaded}
                        onError={handleImageError}
                      />
                    )
                    : <img src={noImage} className={classes.image} />}
                </Paper>
                <a href={squimgur} target='_blank' rel='noreferrer'>View Image</a>
              </Grid>
              <Grid item={true} xs={5} className={classes.imageGridItem}>
                <Typography>LiquidPixels {lpLoading && 'Loading...'}</Typography>
                <Paper className={classes.imagePaper}>
                  {!!lpGrid && !liquidPixelsError
                    ? (
                      <img
                        id='lp-image'
                        {...selectedBind}
                        src={liquidPixels}
                        className={lpLoading ? classes.loading : classes.image}
                        onLoad={handleLPLoaded}
                        onError={handleImageError}
                      />
                    )
                    : <img src={noImage} className={classes.image} />}
                </Paper>
                <a href={liquidPixels} target='_blank' rel='noreferrer'>View Image</a>
              </Grid>
              <Grid item={true} xs={2} className={classes.coloredStyleGridItem}>
                <Typography>Available Colored Styles</Typography>
                <Typography variant='h6'>{coloredStyles && coloredStyles?.length > 0 && coloredStyles[0]?.style?.name}</Typography>
                <Paper className={classes.availableColoredStylesPaper}>
                  <Grid container={true} spacing={3}>
                    {coloredStyles !== null && coloredStyles !== undefined
                      ? coloredStyles?.map((c) => {
                        const hasDesign = designsForCurrentStyle?.find((d) => d.coloredStyleId === c.id);
                        const selected = currentColoredStyle?.id === c.id;

                        return (
                          <Grid
                            key={c.id}
                            item={true}
                            xs={3}
                            className={classes.csImageGridItem}
                            style={{
                              backgroundColor: hasDesign ? 'rgba(0, 128, 0, 0.5)' : undefined,
                              border: selected ? '2px dashed #008800' : undefined,
                              margin: selected ? '0 2px' : '2px',
                            }}
                          >
                            {c.image_front
                              && (
                                <img
                                  id={c.id}
                                  className={classes.smallImage}
                                  src={getImageFrontUrl(c.image_front)}
                                  onClick={handleColoredStyleChange}
                                />
                              )}
                          </Grid>
                        );
                      })
                      : null}
                  </Grid>
                  {(!coloredStyles || coloredStyles?.length === 0)
                    && <Typography variant={'caption'}>{initialText}</Typography>}
                </Paper>
              </Grid>
            </Grid>
            <Grid item={true} xs={12}>
              <StylesMissingDesignsTable
                onRowClick={handleStyleSelected}
                ref={tableRef}
              />
            </Grid>
          </Grid>
        </form>
      </Box>
    </ThemeProvider>
  );
};

export default DesignConfiguration;
