/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Financials Homefield API
 * OpenAPI spec version: v1
 */

export interface VoucherCollectionItemDto {
  id?: number;
  uuid?: string | null;
  created?: string | null;
  updated?: string | null;
  deleted?: string | null;
  voucherCollectionId?: number;
  quantity?: number;
  unitPrice?: number;
  extendedPrice?: number;
  collectionItemIds?: number[] | null;
  lockerItemId?: number | null;
  coloredStyleSelectionId?: number | null;
  coloredStyleId?: number;
  coloredStyleCode?: string | null;
  styleId?: number;
}
