import React from 'react';
import PropTypes from 'prop-types';
import InfoField from '@sharedComponents/Display/InfoField';
import { parseDateTimeNumeric } from '@util/dateHandler';

const ApplicationSetting = React.memo(({
  setting,
  label,
}) => (
  <div className='sheet__item'>
    <ul className='sheet__title flex'>
      <li>
        <span>{label}</span>
      </li>
    </ul>
    <div className='sheet__content min-h__fit'>
      <div className='sheet__thumbnails'>
        <InfoField
          label={'Code'}
          value={setting?.code}
        />
        <InfoField
          label={'Value'}
          value={setting?.value}
        />
      </div>
      <div className='sheet__thumbnails ml-20'>
        <InfoField
          label={'Date Created'}
          value={setting?.dateCreatedUtc && parseDateTimeNumeric(setting.dateCreatedUtc)}
        />
        <InfoField
          label={'Last Edit'}
          value={setting?.dateEditedUtc && parseDateTimeNumeric(setting.dateEditedUtc)}
        />
      </div>
    </div>
  </div>
));

ApplicationSetting.propTypes = {
  label: PropTypes.string.isRequired,
  setting: PropTypes.shape({
    code: PropTypes.string,
    value: PropTypes.string,
    dateCreatedUtc: PropTypes.string,
    dateEditedUtc: PropTypes.string,
  }).isRequired,
};

export default ApplicationSetting;
