import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import iconEye from '@assets/icon_hidden.svg';
import ImagePreviewModal from '@sharedComponents/Modal/ImagePreviewModal';
import MaterialTooltip from '@sharedComponents/Tooltips/MaterialTooltip';
import Tag from '@sharedComponents/Display/Tag';
import Icon from '@sharedComponents/Icons/Icon';
import Image from '@sharedComponents/Display/Image';

class ProductCatalogColoredStyleItem extends PureComponent {
  state = {
    imageModalIsOpen: false,
    image: '',
  };

  openModalWithImage = (image) => {
    this.setState(() => ({
      imageModalIsOpen: true,
      image,
    }));
  };

  closeImageModal = () => {
    this.setState(() => ({
      imageModalIsOpen: false,
      image: '',
    }));
  };

  render() {
    const {
      code,
      colorGroup,
      colorA,
      colorB,
      floodColorId,
      imageUrl,
      disabled,
      discontinued,
      colorsDictionary,
    } = this.props;

    const {
      imageModalIsOpen,
      image,
    } = this.state;

    const floodColor = colorsDictionary?.[floodColorId];

    return (
      <div className='order__item'>
        <ImagePreviewModal
          modalIsOpen={imageModalIsOpen}
          closeModal={this.closeImageModal}
          imageUrl={image}
        />
        <div className='order__item-column'>
          <Image
            url={imageUrl}
            openInModal={this.openModalWithImage}
          />
        </div>
        <div className='order__item-column'>
          <span className='order__item--sku-info'>{code}</span>
          <span className='order__item--style-name flex'>
            {colorGroup}
            <div className='product-catalog__style-icons'>
              {
                disabled &&
                <MaterialTooltip
                  tooltipText={<div className='pre-line'>Colored style is disabled</div>}
                  placement={'top'}
                  contentClasses={'align__center'}
                >
                  <i
                    className='product-catalog__hidden'
                    style={{ backgroundImage: `url("${iconEye}")` }}
                  />
                </MaterialTooltip>
              }
              {
                discontinued &&
                <MaterialTooltip
                  tooltipText={<div className='pre-line'>Colored style is discontinued</div>}
                  placement={'top'}
                  contentClasses={'align__center'}
                >
                  <Icon materialIcon={'block'} />
                </MaterialTooltip>
              }
            </div>
          </span>
          <ul className='order__item-tags'>
            <li>
              <Tag
                label={'Color A'}
                value={(colorA && colorA !== '' && colorA !== 'Null') ? colorA : null}
              />
            </li>
            <li>
              <Tag
                label={'Color B'}
                value={(colorB && colorB !== '' && colorB !== 'Null') ? colorB : null}
              />
            </li>
            <li>
              <Tag
                label={'Flood Color'}
                value={floodColor?.code}
              />
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

ProductCatalogColoredStyleItem.propTypes = {
  code: PropTypes.string.isRequired,
  colorGroup: PropTypes.string.isRequired,
  colorA: PropTypes.string,
  colorB: PropTypes.string,
  floodColorId: PropTypes.number,
  imageUrl: PropTypes.string,
  disabled: PropTypes.bool,
  discontinued: PropTypes.bool,
  colorsDictionary: PropTypes.objectOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    dtgCode: PropTypes.string,
    cmykValue: PropTypes.string.isRequired,
    dtgCmykValue: PropTypes.string.isRequired,
    hexValue: PropTypes.string,
    threadValue: PropTypes.string,
    brightness: PropTypes.string.isRequired,
  })).isRequired,
};

const mapStateToProps = ({ productCatalog }) => ({
  colorsDictionary: productCatalog.colorsDictionary,
});

export default connect(mapStateToProps)(ProductCatalogColoredStyleItem);
