/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */

export interface ArtworkTaskCountByStatusDto {
  unassigned?: number;
  assigned?: number;
  inQC?: number;
  rework?: number;
  completed?: number;
  productionRework?: number;
  priorityRush?: number;
  priority?: number;
}
