import React, {
  useCallback,
  useEffect,
} from 'react';
import { useForm } from 'react-hook-form';
import { emailMaxLength } from '@constants/values';
import { yupResolver } from '@hookform/resolvers/yup';
import { sendRefundConfirmationForm } from '@constants/reduxForms';
import Modal from '@sharedComponents/Modal/Modal';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import FormInput from '@sharedComponents/Form/FormInput';
import yup from '@util/yupValidationHelper';
import { SendRefundConfirmationFormData } from '@models/forms/SendRefundConfirmationFormData';

interface OwnProps {
  isOpen: boolean;
  closeModal: () => void;
  onSubmit: (form: SendRefundConfirmationFormData) => Promise<void>;
  defaultEmail?: string;
}

type Props = OwnProps;

const SendRefundConfirmationModal = React.memo<Props>(({
  isOpen,
  onSubmit,
  closeModal,
  defaultEmail,
}) => {
  const {
    formState: { errors },
    handleSubmit,
    register,
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(yup.object({
      recipientEmail: yup.string()
        .email('Email is not in correct format')
        .required('Email is required')
        .max(emailMaxLength, `Email should not be greater than ${emailMaxLength} characters.`),
    })),
    defaultValues: { recipientEmail: defaultEmail || '' },
  });

  const handleCloseModal = useCallback(() => {
    reset();
    closeModal();
  }, [
    reset,
    closeModal,
  ]);

  useEffect(() => {
    setValue('recipientEmail', defaultEmail || '');
  }, [
    defaultEmail,
    setValue,
  ]);

  return (
    <Modal
      isOpen={isOpen}
      closeModal={handleCloseModal}
      modalHeight={'l'}
      modalWidth={'s'}
      title={'Send Refund Receipt'}
      buttons={(
        <ModalButtons
          confirmBtnText={'Send Receipt'}
          cancelBtnText={'Cancel'}
          onClose={handleCloseModal}
          formSubmission={true}
          isDangerousAction={false}
          formId={sendRefundConfirmationForm}
        />
      )}
    >
      <>
        {
          isOpen &&
          <div className='mt-20'>
            <form
              id={sendRefundConfirmationForm}
              className='refund__color-river-bed filter-group w-100 pr-10'
              onSubmit={handleSubmit(onSubmit)}

            >
              <FormInput
                id={'recipientEmail'}
                placeholder={'Email address'}
                type={'email'}
                error={errors.recipientEmail}
                register={register}
                {...register('recipientEmail')}
              />
            </form>
          </div>
        }
      </>
    </Modal>
  );
});

export default SendRefundConfirmationModal;
