/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */

export type ArtworkTaskSortByEnum = 'ShipByDate' | 'DecorationMethod' | 'Status';


export const ArtworkTaskSortByEnum = {
  ShipByDate: 'ShipByDate' as ArtworkTaskSortByEnum,
  DecorationMethod: 'DecorationMethod' as ArtworkTaskSortByEnum,
  Status: 'Status' as ArtworkTaskSortByEnum,
};
