import { FormErrors } from 'redux-form';
import { featureFlags } from '@constants/common';
import { ScanBarcodesFormData } from '@models/forms/ScanBarcodesFormData';

export const validateProductionAssemblyFlagItem = (
  values: any
): { [key: string]: string[] | undefined; } => {
  const errors: { [key: string]: string[] | undefined; } = {};

  if (!values.reason) {
    errors.reason = ['Reason is required.'];
  }

  if (!values.note) {
    errors.note = ['Note is required.'];
  }

  return errors;
};

export const validateProductionAssemblyPrintQueueSendToPrint = (
  values: any
): FormErrors<any, string[]> => {
  const errors: FormErrors<any, string[]> = {};

  if (!values.stationIdentifier) {
    errors.stationIdentifier = ['Printer is required.'];
  }

  return errors;
};

export const validateOrderItemsScanBarcodesForm = (
  values: ScanBarcodesFormData
): { [key in keyof ScanBarcodesFormData]?: { barcode?: string[]; }[] } => {
  const errors: { [key in keyof ScanBarcodesFormData]?: { barcode?: string[]; }[]; } = {};
  const itemBarcodes: {
    orderItemId: number;
    barcode: string | null;
  }[] = values.itemBarcodes || [];

  const itemBarcodesArrayErrors = itemBarcodes.map((itemBarcode) => {
    if (!itemBarcode || !itemBarcode.barcode) {
      return { barcode: ['Item barcode is required'] };
    }

    if (itemBarcode?.barcode && itemBarcode.barcode.length !== featureFlags.itemBarcodeLength) {
      return { barcode: ['Item barcode length should be 8 characters'] };
    }

    return {};
  });

  if (itemBarcodesArrayErrors.length) {
    errors.itemBarcodes = itemBarcodesArrayErrors;
  }

  return errors;
};
