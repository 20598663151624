/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */

export type PaymentTypeEnum = 'Undefined' | 'OrderInitialCharge' | 'OrderItemInitialCharge' | 'OrderRefund' | 'OrderItemRefund' | 'OrderAdditionalCharge' | 'OrderItemAdditionalCharge' | 'DollarAmountCouponApplied' | 'PercentageCouponApplied' | 'PersonalizationCharge' | 'PersonalizationRefund' | 'ReplaceItemRefund' | 'ReplaceItemCharge';


export const PaymentTypeEnum = {
  Undefined: 'Undefined' as PaymentTypeEnum,
  OrderInitialCharge: 'OrderInitialCharge' as PaymentTypeEnum,
  OrderItemInitialCharge: 'OrderItemInitialCharge' as PaymentTypeEnum,
  OrderRefund: 'OrderRefund' as PaymentTypeEnum,
  OrderItemRefund: 'OrderItemRefund' as PaymentTypeEnum,
  OrderAdditionalCharge: 'OrderAdditionalCharge' as PaymentTypeEnum,
  OrderItemAdditionalCharge: 'OrderItemAdditionalCharge' as PaymentTypeEnum,
  DollarAmountCouponApplied: 'DollarAmountCouponApplied' as PaymentTypeEnum,
  PercentageCouponApplied: 'PercentageCouponApplied' as PaymentTypeEnum,
  PersonalizationCharge: 'PersonalizationCharge' as PaymentTypeEnum,
  PersonalizationRefund: 'PersonalizationRefund' as PaymentTypeEnum,
  ReplaceItemRefund: 'ReplaceItemRefund' as PaymentTypeEnum,
  ReplaceItemCharge: 'ReplaceItemCharge' as PaymentTypeEnum,
};
