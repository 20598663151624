import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { formatDollarAmount } from '@util/numberHelpers';
import { parseDateNumeric } from '@util/dateHandler';
import { fundraisingPayoutStatusEnum } from '@constants/enums/fundraisingPayoutsEnums';
import FundraisingPayoutsRecipientDetails from './FundraisingPayoutsQuickViewTabs/FundraisingPayoutsRecipientDetails';
import FundraisingPayoutsLockers from './FundraisingPayoutsQuickViewTabs/FundraisingPayoutsLockers';
import FundraisingPayoutsDocumentNumber from './FundraisingPayoutsDocumentNumber';
import InfoField from '@sharedComponents/Display/InfoField';
import Tabs from '@sharedComponents/Tabs/Tabs';

const fundraisingQuickViewTabEnum = {
  Lockers: 'Lockers',
  Recipient: 'Recipient',
};

class FundraisingPayoutsQuickViewInfo extends PureComponent {
  state = {
    selectedTab: fundraisingQuickViewTabEnum.Recipient,
  };

  selectTab = (selectedTab) => {
    this.setState({ selectedTab });
  };

  getTabs = () => {
    const {
      payout,
      lockers,
    } = this.props;

    const tabs = [
      {
        title: 'Recipient Details',
        name: fundraisingQuickViewTabEnum.Recipient,
        content: (
          <FundraisingPayoutsRecipientDetails recipient={payout.payoutRecipient} />
        ),
      },
      {
        title: `Stores (${lockers.length})`,
        name: fundraisingQuickViewTabEnum.Lockers,
        content: (
          <FundraisingPayoutsLockers lockers={lockers} />
        ),
      },
    ];

    return tabs;
  };

  render() {
    const { payout } = this.props;
    const { selectedTab } = this.state;

    const admin = payout?.payoutRecipient?.lockerManager;
    const payoutStatus = payout?.status?.status;

    let statusInfo = '';
    switch (payoutStatus) {
      case fundraisingPayoutStatusEnum.Completed:
        statusInfo = <span className='status-label green'>{payoutStatus}</span>;
        break;
      case fundraisingPayoutStatusEnum.Canceled:
        statusInfo = <span className='status-label red'>{payoutStatus}</span>;
        break;
      default:
        statusInfo = payoutStatus;
        break;
    }

    return (
      <div>
        <div className='tableView__details--content-container'>
          <div className='flex'>
            <InfoField
              label={'Payout Amount'}
              value={formatDollarAmount(payout.amount)}
              fieldClass={'size-45'}
            />
            <InfoField
              label={'Payout Method'}
              value={payout.method}
              fieldClass={'ml-15'}
            />
          </div>
          <div className='flex'>
            <div className='info__field size-45'>
              <FundraisingPayoutsDocumentNumber payout={payout} />
            </div>
            <InfoField
              label={'Status'}
              value={statusInfo}
              fieldClass={'ml-15'}
            />
          </div>
          <div className='flex'>
            <InfoField
              label={'Admin Name'}
              value={admin && `${admin.firstName || ''} ${admin.lastName || ''}`}
              fieldClass={'size-45'}
            />
            <InfoField
              label={'Admin E-mail'}
              value={admin?.email}
              fieldClass={'size-50 ml-15'}
            />
          </div>
          {
            (payout.checkNumber || payout.checkDate) &&
            <div>
              <hr />
              <div className='flex'>
                <InfoField
                  label={'Check Number'}
                  value={payout.checkNumber}
                  fieldClass={'size-40'}
                />
                <InfoField
                  label={'Check Date'}
                  value={payout.checkDate && parseDateNumeric(payout.checkDate)}
                  fieldClass={'size-40 ml-15 mr-15'}
                />
                <InfoField
                  label={'Check Amount'}
                  value={payout.checkAmount && formatDollarAmount(payout.checkAmount)}
                  fieldClass={'size-40'}
                />
              </div>
            </div>
          }
        </div>

        <Tabs
          tabs={this.getTabs()}
          selectedTab={selectedTab}
          selectTab={this.selectTab}
        />
      </div>
    );
  }
}

FundraisingPayoutsQuickViewInfo.propTypes = {
  payout: PropTypes.object.isRequired,
  lockers: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default FundraisingPayoutsQuickViewInfo;
