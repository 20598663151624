import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Radio from '@material-ui/core/Radio';
import { discountTypeEnum } from '@constants/enums/couponEnums';
import Modal from '@sharedComponents/Modal/Modal';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';

class EditStyleDiscountModal extends PureComponent {
  state = {
    discountInput: null,
    selectedDiscountType: discountTypeEnum.DollarAmount,
  };

  handleCloseModal = () => {
    const { closeModal } = this.props;

    this.initializeState();
    closeModal();
  };

  handleDiscountTypeChange = (e) => {
    e.preventDefault();
    const discountType = e.target.value ? Number(e.target.value) : discountTypeEnum.DollarAmount;

    this.setState(() => ({ selectedDiscountType: discountType }));
  };

  setDiscountValue = () => {
    const { setDiscount } = this.props;
    const {
      discountInput,
      selectedDiscountType,
    } = this.state;

    this.initializeState();
    setDiscount(discountInput, selectedDiscountType);
  };

  onInputChange = () => {
    const newDiscountInput = this.discountInput.value ? this.discountInput.value.trim() : null;
    this.setState(() => ({ discountInput: newDiscountInput }));
  };

  initializeState = () => {
    this.setState(() => ({
      discountInput: null,
      selectedDiscountType: discountTypeEnum.DollarAmount,
    }));
  };

  setDiscountInputRef = (r) => {
    this.discountInput = r;
  };

  render() {
    const { isOpen } = this.props;
    const {
      selectedDiscountType,
      discountInput,
    } = this.state;

    return (
      <Modal
        title={'Bulk Set Styles Discount'}
        modalHeight={'l'}
        modalWidth={'m'}
        isOpen={isOpen}
        closeModal={this.handleCloseModal}
        buttons={(
          <ModalButtons
            confirmBtnText={'Save'}
            cancelBtnText={'Cancel'}
            onConfirm={this.setDiscountValue}
            onClose={this.handleCloseModal}
            confirmBtnDisabled={discountInput === null}
          />
        )}
      >
        <div>
          <label className='redux-form__label'>
            Discount type
          </label>
          <div className='flex mb-10'>
            <div className='radio__group--item'>
              <Radio
                checked={selectedDiscountType === discountTypeEnum.DollarAmount}
                onChange={this.handleDiscountTypeChange}
                value={discountTypeEnum.DollarAmount}
                classes={{ checked: 'radio__green' }}
              />
              Dollar Amount
            </div>
            <div className='radio__group--item'>
              <Radio
                checked={selectedDiscountType === discountTypeEnum.Percentage}
                onChange={this.handleDiscountTypeChange}
                value={discountTypeEnum.Percentage}
                classes={{ checked: 'radio__green' }}
              />
              Percentage
            </div>
          </div>
        </div>
        <div className='w-240'>
          <label className='redux-form__label'>
            Discount amount
            <span className='required'>*</span>
          </label>
          <div className='text-field redux-form__input-wrapper'>
            {selectedDiscountType === discountTypeEnum.DollarAmount && <span className='icon-left'>$</span>}
            <input
              type='number'
              step='0.01'
              min='0'
              placeholder={`${selectedDiscountType === discountTypeEnum.DollarAmount ? 'Enter dollar amount' : 'Enter percentage'}`}
              onChange={this.onInputChange}
              ref={this.setDiscountInputRef}
              className={
                `w-100 ${selectedDiscountType === discountTypeEnum.DollarAmount ? 'has-icon' : ''} ${selectedDiscountType === discountTypeEnum.Percentage ? 'has-icon--right' : ''}`
              }
            />
            {selectedDiscountType === discountTypeEnum.Percentage && <span className='icon-right'>%</span>}
          </div>
        </div>
      </Modal>
    );
  }
}

EditStyleDiscountModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  setDiscount: PropTypes.func.isRequired,
};

export default EditStyleDiscountModal;
