import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { keyNameEnum } from '@constants/enums/commonEnums';
import {
  artworkFileTasksSortOptions,
  decorationMethodsOptions,
} from '@constants/options/optionsValues';
import { dropdownSizeL } from '@constants/values';
import { lockerDetailsUrl } from '@constants/clientUrls/clientUrls';
import {
  fetchArtworkFileTasks,
  clearArtworkFileTasks,
  fetchArtworkFileTaskFailReasons,
  lookupLockerByLockerId,
  updateArtworkFileTaskDecorations,
  updateArtworkFileTaskSort,
  getDecorationMethods,
} from '@redux/artworkFileTasks/actions';
import { fetchFulfillmentLockerInfo } from '@redux/lockerManager/actions';
import { fetchAssignees } from '@redux/artwork/actions';
import { getColors } from '@redux/productCatalog/actions';
import { updateMessages } from '@redux/alert/actions';
import { extractParameterFromPath } from '@util/stringHelpers';
import ButtonLink from '@sharedComponents/Navigation/ButtonLink';
import Button from '@sharedComponents/Buttons/Button';
import Dropdown from '@sharedComponents/Inputs/Dropdowns/Dropdown/Dropdown';
import Icon from '@sharedComponents/Icons/Icon';
import Alerts from '@sharedComponents/Alerts';
import MultiSelectDropdown from '@sharedComponents/Inputs/Dropdowns/MultiSelectDropdown/MultiSelectDropdown';
import ArtworkFileTasksBack from '../Header/ArtworkFileTasksHeader/ArtworkFileTasksBack';
import ArtworkTaskItemsContainer from './ArtworkTaskItems/ArtworkTaskItemsContainer';
import ArtworkTaskDetails from './ArtworkTaskDetails/ArtworkTaskDetails';
import StickyHeader from './StickyHeader';
import ArtworkTaskOrdersModal from './ArtworkTaskDetails/ArtworkTaskOrdersModal';

export class ArtworkFileTasks extends PureComponent {
  state = {
    searchInput: '',
    ordersModalIsOpen: false,
    decorations: [],
    sortBy: '',
  };

  componentDidMount() {
    const { dispatch } = this.props;
    const lockerId = extractParameterFromPath(this.props, 'lockerId', 'number');
    const {
      decorations,
      sortBy,
    } = this.state;

    dispatch(fetchArtworkFileTasks(lockerId));
    dispatch(fetchFulfillmentLockerInfo(lockerId));
    dispatch(fetchArtworkFileTaskFailReasons());
    dispatch(fetchAssignees());
    dispatch(getColors());
    dispatch(updateArtworkFileTaskDecorations(decorations));
    dispatch(updateArtworkFileTaskSort(sortBy));
  }

  componentDidUpdate(prevProps) {
    const { dispatch } = this.props;

    const lockerId = extractParameterFromPath(this.props, 'lockerId', 'number');
    const oldLockerId = extractParameterFromPath(prevProps, 'lockerId', 'number');

    if (lockerId !== oldLockerId) {
      dispatch(fetchArtworkFileTasks(lockerId));
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(clearArtworkFileTasks());
  }

  _handleClearMessages = () => {
    const { dispatch } = this.props;
    dispatch(updateMessages([]));
  };

  handleSearchInputChange = (e) => {
    const searchInput = e.target.value;
    this.setState(() => ({ searchInput }));
  };

  runSearch = (e) => {
    if (e.key && e.key !== keyNameEnum.Enter) {
      if (e.key === keyNameEnum.ArrowLeft) {
        if (this.searchInputElement.selectionStart > 0) {
          if (this.searchInputElement.selectionStart !== this.searchInputElement.selectionEnd) {
            this.searchInputElement.selectionEnd = this.searchInputElement.selectionStart;
          } else {
            this.searchInputElement.selectionStart -= 1;
            this.searchInputElement.selectionEnd = this.searchInputElement.selectionStart;
          }
        }
        e.preventDefault();
        e.nativeEvent.stopImmediatePropagation();
      } else if (e.key === keyNameEnum.ArrowRight) {
        if (this.searchInputElement.selectionEnd < this.searchInputElement.value.length) {
          if (this.searchInputElement.selectionStart !== this.searchInputElement.selectionEnd) {
            this.searchInputElement.selectionStart = this.searchInputElement.selectionEnd;
          } else {
            this.searchInputElement.selectionStart += 1;
            this.searchInputElement.selectionEnd = this.searchInputElement.selectionStart;
          }
        }
        e.preventDefault();
        e.nativeEvent.stopImmediatePropagation();
      }

      return;
    }

    e.preventDefault();
    e.stopPropagation();

    lookupLockerByLockerId(this.state.searchInput);
  };

  openOrdersModal = () => {
    this.setState(() => ({ ordersModalIsOpen: true }));
  };

  closeOrdersModal = () => {
    this.setState(() => ({ ordersModalIsOpen: false }));
  };

  decorationChange = (decorations) => {
    const { dispatch } = this.props;
    if (!decorations) {
      dispatch(getDecorationMethods(decorations));

      return;
    }

    dispatch(getDecorationMethods(decorations));
    this.search();
  };

  sortByChange = (sortBy) => {
    const { dispatch } = this.props;
    if (!sortBy) {
      dispatch(updateArtworkFileTaskSort(''));

      return;
    }

    dispatch(updateArtworkFileTaskSort(sortBy));
    this.search();
  };

  search = () => {
    const {
      dispatch,
      decorations,
      sortBy,
    } = this.props;

    const lockerId = extractParameterFromPath(this.props, 'lockerId', 'number');
    dispatch(fetchArtworkFileTasks(lockerId, null, null, sortBy, decorations));
  };

  render() {
    const {
      tasks,
      taskDetails,
      lockerInfo,
    } = this.props;

    const {
      ordersModalIsOpen,
      searchInput,
    } = this.state;

    const ordersCount = (taskDetails?.orders) ? taskDetails.orders.length : 0;

    const status = extractParameterFromPath(this.props, 'status');
    const lockerId = extractParameterFromPath(this.props, 'lockerId', 'number');

    const itemsExist = tasks.items.length !== 0;

    return (
      <>
        <ArtworkTaskOrdersModal
          isOpen={ordersModalIsOpen}
          closeModal={this.closeOrdersModal}
          ordersCount={ordersCount}
          taskDetails={taskDetails}
        />
        <StickyHeader stickyAfter={400} />
        <div className='container'>
          <Alerts onClose={this._handleClearMessages} />
          <div className='flex mb-10 filter-groups'>
            <ArtworkFileTasksBack />
            <div className='flex'>
              <MultiSelectDropdown
                objects={decorationMethodsOptions}
                itemText={'decorations'}
                updateCallback={this.decorationChange}
                textKey={'name'}
                valueKey={'value'}
                classNames={'margin-left'}
              />
              <div className='filter-group m-l-12 mar'>
                <Dropdown
                  options={artworkFileTasksSortOptions}
                  onChange={this.sortByChange}
                  size={dropdownSizeL}
                />
              </div>
              <div className='text-field'>
                <input
                  type='text'
                  className='has-icon--right'
                  placeholder='Locker ID'
                  onKeyDown={this.runSearch}
                  onChange={this.handleSearchInputChange}
                  value={searchInput}
                />
                <Icon
                  materialIcon={'arrow_forward'}
                  classes={'text-field__icon--right'}
                />
              </div>
              <Button
                type={'secondary'}
                classes={'ml-20'}
                text={`See All Orders (${ordersCount})`}
                onClick={this.openOrdersModal}
                disabled={ordersCount === 0}
              />
              <ButtonLink
                type={'secondary'}
                text={'Go To Locker'}
                linkTo={lockerDetailsUrl(lockerId)}
                classes={'ml-10'}
              />
            </div>
          </div>
          {
            !itemsExist
              ? (
                <div>No items found</div>
              )
              : (
                <div>
                  <ArtworkTaskItemsContainer
                    status={status}
                    tasks={tasks.items}
                  />
                  {
                    taskDetails?.id
                      ? (
                        <ArtworkTaskDetails
                          task={taskDetails}
                          lockerInfo={lockerInfo}
                        />
                      )
                      : <div />
                  }
                </div>
              )
          }
        </div>
      </>
    );
  }
}

ArtworkFileTasks.propTypes = {
  tasks: PropTypes.object.isRequired,
  taskDetails: PropTypes.object.isRequired,
  messages: PropTypes.array,
  lockerInfo: PropTypes.object,
  sortBy: PropTypes.string,
  decorations: PropTypes.array,
};

const mapStateToProps = ({
  artworkFileTasks,
  alert,
  lockerManager,
}) => ({
  tasks: artworkFileTasks.artworkFileTasks,
  taskDetails: artworkFileTasks.artworkFileTaskDetails,
  messages: alert.messages,
  lockerInfo: lockerManager.lockerInfo,
  sortBy: artworkFileTasks.artworkFileTasks.sortBy,
  decorations: artworkFileTasks.artworkFileTasks.decorations,
});

export default connect(mapStateToProps)(ArtworkFileTasks);
