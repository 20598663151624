/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Accounts Homefield API
 * OpenAPI spec version: 2.0
 */

export interface ShippingOptionDto {
  id: number;
  name: string;
  price: number;
  minimumEstimatedDeliveryBusinessDays: number;
  maximumEstimatedDeliveryBusinessDays: number;
  expedited: boolean;
  order: number;
  disabledForPOBox: boolean;
  isActive: boolean;
}
