/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Financials Homefield API
 * OpenAPI spec version: v1
 */
import type { CollectionPreview } from './collectionPreview';

export interface VoucherOrderPreviewDto {
  organizationId?: number | null;
  lockerId?: number | null;
  discountAmount?: number;
  discountPercent?: number;
  totalAmount?: number;
  collections?: CollectionPreview[] | null;
}
