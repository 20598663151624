/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Financials Homefield API
 * OpenAPI spec version: v1
 */
import type { PreviewVoucherCollectionDto } from './previewVoucherCollectionDto';

export interface PreviewVoucherOrderCommand {
  organizationId?: number | null;
  lockerId?: number | null;
  voucherOrderDiscountPercent?: number;
  collections?: PreviewVoucherCollectionDto[] | null;
}
