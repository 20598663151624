/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */
import type { OrderInventoryStatusEnumStatusModel } from './orderInventoryStatusEnumStatusModel';
import type { OrderShippingInfoDto } from './orderShippingInfoDto';
import type { OrderStatusEnumStatusModel } from './orderStatusEnumStatusModel';
import type { LockerInfoDto } from './lockerInfoDto';

export interface BackorderDto {
  orderId?: number;
  orderNumber?: number;
  shipDate?: string;
  inventoryStatus?: OrderInventoryStatusEnumStatusModel;
  shippingInfo?: OrderShippingInfoDto;
  status?: OrderStatusEnumStatusModel;
  lockerId?: number | null;
  readonly lockerNumber?: number | null;
  lockerTeamName?: string | null;
  organizationId?: number | null;
  organizationName?: string | null;
  backorderedItemIds?: number[] | null;
  totalItems?: number;
  lockers?: LockerInfoDto[] | null;
}
