import { fulfillmentApi } from '../../sqdApis';
import * as actionTypes from './types';
import { artworkTaskCardPageSize } from '@constants/values';
import {
  imagePreviewStatusEnum,
  artworkTaskStatusEnum,
  artworkFileTaskSortOptionsEnum,
} from '@constants/enums/artworkEnums';
import * as taskUrls from '@constants/sqdApiUrls/taskUrls';
import * as supportUrls from '@constants/sqdApiUrls/supportUrls';
import * as logoUrls from '@constants/sqdApiUrls/logoUrls';
import {
  makeApiCallWithErrorModal,
  makeApiCall,
} from '@util/apiHelper';
import {
  swalAlert,
  parseLockerIdSearch,
} from '@util/componentHelper';
import {
  archiveTasksBulk,
  unarchiveTasksBulk,
} from '@APICalls/artwork/actions';
import * as store from 'store';

export const updateArtworkFileTasks = (tasks) => ({
  type: actionTypes.UPDATE_ARTWORK_FILE_TASKS,
  payload: tasks,
});

export const updateArtworkFileTaskDetails = (task) => ({
  type: actionTypes.UPDATE_ARTWORK_FILE_TASK_DETAILS,
  payload: task,
});

export const deleteArtworkFileTask = (taskId) => ({
  type: actionTypes.DELETE_ARTWORK_FILE_TASK,
  taskId,
});

export const clearArtworkFileTasks = () => ({
  type: actionTypes.CLEAR_ARTWORK_FILE_TASKS,
});

export const updateArtworkFileTaskFailReasons = (reasons) => ({
  type: actionTypes.UPDATE_ARTWORK_FILE_TASK_FAIL_REASONS,
  payload: reasons,
});

export const updateArtworkFileTaskNotes = (notes) => ({
  type: actionTypes.UPDATE_ARTWORK_FILE_TASK_NOTES,
  payload: notes,
});

export const updatePreview = (result) => ({
  type: actionTypes.UPDATE_ARTWORK_FILE_TASK_PREVIEW_GENERATION,
  payload: result,
});

export const clearPreview = () => ({
  type: actionTypes.CLEAR_ARTWORK_FILE_TASK_PREVIEW_GENERATION,
});

export const updateArtworkFileTaskDecorations = (decorations) => ({
  type: actionTypes.UPDATE_ARTWORK_FILE_TASK_DECORATIONS,
  payload: decorations,
});

export const updateArtworkFileTaskSort = (sortBy) => ({
  type: actionTypes.UPDATE_ARTWORK_FILE_TASK_SORT,
  payload: sortBy,
});

const handleTasks = (tasks) => {
  const artworkFileTasksObject = {};

  tasks.forEach((task, i) => {
    if (task.id && task.id !== 0) {
      task.isExistingTask = true;
    } else {
      task.isExistingTask = false;
      task.key = i;
    }

    if (task.locker?.id) {
      task.lockerId = task.locker.id;
    }
  });

  artworkFileTasksObject.items = tasks;
  artworkFileTasksObject.totalCount = tasks.length;
  artworkFileTasksObject.pagesCount = Math.ceil(tasks.length / artworkTaskCardPageSize);

  return artworkFileTasksObject;
};

export const fetchArtworkFileTasks = (
  lockerId,
  logoId,
  callback,
  sortBy = null,
  decorationMethod = ''
) => async (dispatch) => {
  // Get info about a locker
  const call = fulfillmentApi.get(
    logoUrls.logoLockerTasks(lockerId, logoId, sortBy ?? artworkFileTaskSortOptionsEnum.Status, decorationMethod),
    { handleBlockingLoading: false }
  );
  const res = await makeApiCall(call);
  if (res) {
    const artworkFileTasksObject = handleTasks(res);
    if (typeof callback === 'function') {
      callback(artworkFileTasksObject);
    }

    return dispatch(updateArtworkFileTasks(artworkFileTasksObject));
  }
};

export const getDecorationMethods = (
  decorations
) => async (dispatch) => dispatch(updateArtworkFileTaskDecorations(decorations));

export const triageArtworkTasks = (
  items,
  callback
) => async (dispatch) => {
  const call = fulfillmentApi.post(taskUrls.taskTriage, {
    body: JSON.stringify({
      items,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  });

  const res = await makeApiCall(call);
  if (res?.success) {
    const artworkFileTasksObject = handleTasks(res.result);
    if (typeof callback === 'function') {
      callback(artworkFileTasksObject);
    }

    dispatch(updateArtworkFileTasks(artworkFileTasksObject));
  }

  return res;
};

export const fetchArtworkFileTaskFailReasons = () => async (dispatch) => {
  dispatch(updateArtworkFileTaskFailReasons(null));

  const call = fulfillmentApi.get(supportUrls.failReasons, { handleBlockingLoading: false });
  const res = await makeApiCall(call);
  if (res) {
    dispatch(updateArtworkFileTaskFailReasons(res));
  } else {
    dispatch(updateArtworkFileTaskFailReasons(false));
  }
};

export const getLastUploadedFilesPerType = (task, types) => {
  const sort = (items, filters) => {
    const regexp = '\\.(' + filters.join('|') + ')$';
    const temp = [];
    items.forEach((item) => {
      const match = item.fileName.toLowerCase().match(regexp);
      if (!match || item.deleted || item.taskId !== task.id) {
        return;
      }
      item.fileExtension = match[1];
      temp.push(item);
    });
    temp.sort(function(a, b) {
      const keyA = new Date(a.created),
        keyB = new Date(b.created);
      if (keyA < keyB) return 1;
      if (keyA > keyB) return -1;

      return 0;
    });

    // Only one file for each type
    const uniqueItems = [];
    filters.forEach((extension) => {
      const i = temp.find((item) => item.fileExtension === extension);
      uniqueItems.push(i);
    });

    return uniqueItems;
  };
  const filters = types ? types : [
    'eps',
    'dst',
    'emb',
    'pdf',
  ];

  return sort(task.storage, filters);
};

export const dispatchAfterSubmit = (lockerId, taskId) => (dispatch) => {
  const {
    decorations,
    sortBy,
  } = store.default.getState().artworkFileTasks.artworkFileTasks;
  dispatch(fetchArtworkFileTasks(lockerId, null, (tasks) => {
    const currentTask = tasks.items.find((item) => item.id === taskId);

    return dispatch(updateArtworkFileTaskDetails(currentTask));
  }, sortBy, decorations));
};

export const submitTaskForQc = (lockerId, taskId, callback) => async (dispatch) => {
  const call = fulfillmentApi.put(taskUrls.taskStatus, {
    body: JSON.stringify({
      taskId,
      status: artworkTaskStatusEnum.InQC,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  });

  const res = await makeApiCallWithErrorModal(call);
  if (res) {
    if (typeof callback === 'function') {
      callback(res);
    }

    return dispatch(dispatchAfterSubmit(lockerId, taskId));
  }
};

export const submitTaskToRework = (lockerId, taskId, failReason, notes, file) => async (dispatch) => {
  const formData = new FormData();
  formData.append('taskId', taskId);
  formData.append('notes', notes);
  formData.append('reason', failReason);

  if (file) {
    for (const f of file) {
      formData.append('file[]', f, f.name);
    }
  }

  const call = fulfillmentApi.put(taskUrls.taskRework, {
    body: formData,
  });

  const res = await makeApiCallWithErrorModal(call);
  dispatch(dispatchAfterSubmit(lockerId, taskId));

  return res;
};

export const submitTaskToPrint = (lockerId, taskId) => async (dispatch) => {
  const call = fulfillmentApi.put(taskUrls.taskStatus, {
    body: JSON.stringify({
      taskId,
      status: artworkTaskStatusEnum.Completed,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  });

  const res = await makeApiCallWithErrorModal(call);
  dispatch(dispatchAfterSubmit(lockerId, taskId));

  return res;
};

export const submitTaskNotes = (lockerId, taskId, note) => async (dispatch) => {
  const formData = new FormData();
  formData.append('content', note.text);
  if (note.file) {
    for (const f of note.file) {
      formData.append('file[]', f, f.name);
    }
  }

  const call = fulfillmentApi.post(taskUrls.notesForTask(taskId), {
    body: formData,
  });

  const res = await makeApiCallWithErrorModal(call);

  if (res?.success) {
    return dispatch(dispatchAfterSubmit(lockerId, taskId));
  }
};

export const archiveTask = (lockerId, taskId, note, file) => async (dispatch) => {
  const result = await archiveTasksBulk([taskId], note, file);

  if (result) {
    return dispatch(dispatchAfterSubmit(lockerId, taskId));
  }
};

export const archiveTasks = (tasks) => async (dispatch) => {
  const result = await archiveTasksBulk(tasks.map((t) => t.id));

  if (result) {
    tasks.forEach((task) => {
      dispatch(dispatchAfterSubmit(task.locker.id, task.id));
    });
  }

  return result;
};

export const unarchiveTask = (lockerId, taskId) => async (dispatch) => {
  const result = await unarchiveTasksBulk([taskId]);

  if (result) {
    dispatch(dispatchAfterSubmit(lockerId, taskId));
  }
};

export const unarchiveTasks = (lockerId, taskIds) => async (dispatch) => {
  const result = await unarchiveTasksBulk(taskIds);

  if (result) {
    taskIds.forEach((taskId) => {
      dispatch(dispatchAfterSubmit(lockerId, taskId));
    });
  }

  return result;
};

export const deleteNote = (lockerId, taskId, nodeId) => async (dispatch) => {
  const call = fulfillmentApi.delete(taskUrls.note(taskId, nodeId));
  const res = await makeApiCallWithErrorModal(call);
  if (res?.success) {
    dispatch(dispatchAfterSubmit(lockerId, taskId));
  }
};

export const lookupLockerByLockerId = async (search) => {
  const lockerId = parseLockerIdSearch(search);
  const call = fulfillmentApi.get(taskUrls.lockerTasksByLockerId(lockerId), {
    handleBlockingLoading: false,
  });
  const res = await makeApiCallWithErrorModal(call);
  if (res?.length > 0) {
    window.location = `/artwork-file-tasks/${lockerId}`;
  } else if (res?.length === 0) {
    swalAlert(`Locker ${search} not found.`);
  }
};

export const assignTask = (data, lockerId, taskId) => async (dispatch) => {
  const {
    resource,
    taskIds,
  } = data;

  const req = {
    resourceId: resource.id,
    taskIds,
  };

  const call = fulfillmentApi.put(taskUrls.taskAssign, {
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(req),
  });

  const response = await makeApiCallWithErrorModal(call);
  await dispatch(dispatchAfterSubmit(lockerId, taskId));

  return response;
};

export const fetchPreviewUrl = (taskId) => (dispatch) => {
  const timeout = 3000;
  const state = {
    delayedFetch: null,
  };
  const _fetchPreview = async (callbackForFetch) => {
    try {
      const response = await fulfillmentApi.get(taskUrls.previewUrl(taskId), { handleBlockingLoading: false });
      if (response.success) {
        if (response.result.status === imagePreviewStatusEnum.Processing) {
          const { result } = response;
          result.message = response.message;

          return callbackForFetch({
            previewUrl: '',
            processing: true,
            failed: false,
            message: response.message,
          });
        }
        const { result } = response;
        result.message = response.message;
        callbackForFetch(result);
        clearInterval(state.delayedFetch);

        return;
      }
      clearInterval(state.delayedFetch);

      return callbackForFetch({
        previewUrl: '',
        processing: false,
        failed: false,
        message: response.message || '',
      });
    } catch (err) {
      clearInterval(state.delayedFetch);

      return callbackForFetch({
        previewUrl: '',
        processing: false,
        failed: !err.success,
        message: err.message,
      });
    }
  };
  _fetchPreview((response) => dispatch(updatePreview(response)));
  state.delayedFetch = setInterval(() => _fetchPreview((response) => dispatch(updatePreview(response))), timeout);

  return state;
};

export const changeFileCutInfo = (lockerId, taskId, cutFileColor, isCutFile) => async (dispatch) => {
  const body = JSON.stringify({
    taskId,
    isCutFile,
    cutFileColor,
  });

  const call = fulfillmentApi.put(taskUrls.taskCutFile, {
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  });

  await makeApiCallWithErrorModal(call);
  await dispatch(dispatchAfterSubmit(lockerId, taskId));
};
