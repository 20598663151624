import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import { reset } from 'redux-form';
import {
  connect,
  ConnectedProps,
} from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import {
  useGetHomefieldApiOrdermanagementOrders,
  useGetHomefieldApiOrdermanagementOrdersOrderNumber,
  useGetHomefieldApiOrdermanagementOrdersOrderNumberShipments,
  usePostHomefieldApiOrdermanagementOrdersvoucherapply,
} from '@api/fulfillment/order-management';
import {
  useGetHomefieldApiOrdermanagementOrdersgetdetails, useGetHomefieldApiOrdermanagementOrdersgetorderpricingdetails,
} from '@api/orders/orders';
import {
  ApiResponse,
  OrderDetailsDto,
  OrderShipmentDto,
  SubmitStatusEnum,
} from '@api/fulfillment/models';
import {
  pauseOrder,
  unpauseOrder,
  rushOrder,
  refundOrderWithCancel,
  sendRefundConfirmation,
} from '@APICalls/orderManagement/actions';
import rushIcon from '@assets/icon_rush-green.svg';
import { OrderRefundFormData } from '@models/forms/OrderManagement/OrderRefundFormData';
import { SendRefundConfirmationFormData } from '@models/forms/SendRefundConfirmationFormData';
import * as orderManagementActions from '@redux/orderManagement/actions';
import { RootState } from '@redux/index/reducers';
import { roleMapping } from '@util/roleCheck';
import { refundOrderForm } from '@constants/reduxForms';
import {
  keyNameEnum,
  rolesEnum,
} from '@constants/enums/commonEnums';
import { orderDetailsUrl } from '@constants/clientUrls/clientUrls';
import { refundReasonEnum } from '@constants/enums/refundEnums';
import PermissionsEnum from '@constants/enums/permissionsEnum';
import featureFlags from '@constants/featureFlags';
import {
  orderStatusValueEnum,
  accountingActionTypeEnum,
} from '@constants/enums/orderEnums';
import {
  materialSwal, navigateToPage,
} from '@util/componentHelper';
import { getOrderActionType } from '@util/paymentHelper';
import Icon from '@sharedComponents/Icons/Icon';
import SendRefundConfirmationModal from '@sharedComponents/Modal/SendRefundConfirmationModal';
import ActionButton from '@sharedComponents/Buttons/ActionButton';
import OrderManagementBack from './OrderManagementBack';
import OrderManagementDetails from './OrderManagementDetails/OrderManagementDetails';
import OrderManagementDetailsRushModal from './OrderManagementDetails/OrderManagementDetailsModals/OrderManagementDetailsRushModal';
import OrderManagementOrderRefundModal from './OrderManagementRefund/OrderManagementOrderRefundModal';
import OrderManagementShipmentHeaders from './OrderManagementShipmentHeaders';
import OrderManagementSidebar from './OrderManagementSidebar/OrderManagementSidebar';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { OrderVoucherFormData } from '@models/forms/OrderManagement/OrderVoucherFormData';
import OrderManagementOrderVoucherModal from './OrderManagementRefund/OrderManagementOrderVoucherModal';

interface OwnProps {
  order: OrderDetailsDto;
  shipments: Array<OrderShipmentDto>;
  roles: Array<string>;
}

interface RouteProps {
  orderNumber: string;
}

const mapStateToProps = ({ oidc }: RootState) => ({
  roles: roleMapping(oidc),
});

const mapDispatchToProps = {
  fetchOrderNotes: orderManagementActions.fetchOrderNotes,
  reset,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = OwnProps & ConnectedProps<typeof connector> & RouteComponentProps<RouteProps>;

const OrderManagement = React.memo<Props>(({
  roles,
  match: { params: { orderNumber } },
  fetchOrderNotes,
}) => {
  const { hfDisableOrderActions } = useFlags();

  const [
    orderRefundModalIsOpen,
    setOrderRefundModalIsOpen,
  ] = useState<boolean>(false);
  const [
    orderVoucherModalIsOpen,
    setOrderVoucherModalIsOpen,
  ] = useState<boolean>(false);
  const [
    sendRefundConfirmationModalIsOpen,
    setSendRefundConfirmationModalIsOpen,
  ] = useState<boolean>(false);
  const [
    rushModalIsOpen,
    setRushModalIsOpen,
  ] = useState<boolean>(false);
  const [
    isCancelAction,
    setIsCancelAction,
  ] = useState<boolean>(false);
  const [
    orderExistsOnCore,
    setOrderExistsOnCore,
  ] = useState<boolean>(false);
  const [
    jumpToOrderInputValue,
    setJumpToOrderInputValue,
  ] = useState<Nullable<string>>(null);

  const {
    data: shipments,
    refetch: getOrderShipments,
  } = useGetHomefieldApiOrdermanagementOrdersOrderNumberShipments(orderNumber as unknown as number);

  const {
    data: coreOrderPricingDetailsResponse,
    refetch: fetchCoreOrderPricingDetails,
  } = useGetHomefieldApiOrdermanagementOrdersgetorderpricingdetails(
    { id: orderNumber as unknown as number }
  );

  const {
    data: ordersPagedList,
    refetch: getOrdersPagedListQuery,
    isError: isGetOrdersPagedListQueryError,
  } = useGetHomefieldApiOrdermanagementOrders(
    { filter: jumpToOrderInputValue! }
  );

  useEffect(() => {
    if (!jumpToOrderInputValue) return;
    getOrdersPagedListQuery();
  }, [
    getOrdersPagedListQuery,
    jumpToOrderInputValue,
  ]);

  const {
    data: order,
    refetch: fetchOrder,
  } = useGetHomefieldApiOrdermanagementOrdersOrderNumber(orderNumber as unknown as number, {
    query: {
      queryKey: 'ffOrder',
    },
  });

  const {
    mutateAsync: applyVoucher,
  } = usePostHomefieldApiOrdermanagementOrdersvoucherapply();

  const {
    data: coreOrder,
    refetch: fetchCoreOrder,
  } = useGetHomefieldApiOrdermanagementOrdersgetdetails(
    {
      id: order?.orderNumber,
    }
  );

  const resetPageInfo = useCallback(() => {
    fetchOrder();
    if (orderExistsOnCore) {
      fetchCoreOrderPricingDetails();
      fetchCoreOrder();
    }
  }, [
    fetchCoreOrder,
    fetchCoreOrderPricingDetails,
    fetchOrder,
    orderExistsOnCore,
  ]);

  useEffect(() => {
    if (order?.orderNumber && orderExistsOnCore) {
      fetchCoreOrder();
    }
  }, [
    fetchCoreOrder,
    order?.orderNumber,
    orderExistsOnCore,
  ]);

  useEffect(() => {
    if (isGetOrdersPagedListQueryError) {
      materialSwal('Error', 'Jump to order request failed!', 'error');
    } else if (ordersPagedList?.totalCount === 0) {
      materialSwal('Error', 'Order not found!', 'error');
    } else if (ordersPagedList?.totalCount === 1) {
      const number = ordersPagedList.items?.[0]?.orderNumber as number;
      navigateToPage(orderDetailsUrl(number));
    } else if (ordersPagedList?.totalCount && ordersPagedList?.totalCount > 1) {
      const jumpToOrderInputValueTrimmed = jumpToOrderInputValue?.trim();
      const sopNumberOrders = ordersPagedList?.items?.filter((i) => i?.gpDocumentId === jumpToOrderInputValueTrimmed);

      if (sopNumberOrders && sopNumberOrders?.length === 1) {
        const number = ordersPagedList.items?.[0]?.orderNumber as number;
        navigateToPage(orderDetailsUrl(number));
      } else {
        materialSwal('Error', 'Multiple orders found!', 'error');
      }
    }
  }, [
    isGetOrdersPagedListQueryError,
    jumpToOrderInputValue,
    ordersPagedList,
  ]);

  useEffect(() => {
    if (order?.isCoreOrder) {
      setOrderExistsOnCore(true);
    } else {
      setOrderExistsOnCore(false);
    }
  }, [order]);

  // Initial setup, replaces componentDidMount
  useEffect(() => {
    if (!orderNumber) return;
    fetchOrder();
    getOrderShipments();
    if (orderExistsOnCore) {
      fetchCoreOrderPricingDetails();
    }
  }, [
    fetchCoreOrderPricingDetails,
    fetchOrder,
    getOrderShipments,
    orderNumber,
    orderExistsOnCore,
  ]);

  const openCancelWithRefundModal = useCallback(() => {
    setOrderRefundModalIsOpen(true);
    setIsCancelAction(true);
  }, []);

  const closeCancelWithRefundModal = useCallback(() => {
    setOrderRefundModalIsOpen(false);
    setIsCancelAction(false);
  }, []);

  const openOrderRefundModal = useCallback(() => {
    setOrderRefundModalIsOpen(true);
    setIsCancelAction(false);
  }, []);

  const closeOrderRefundModal = useCallback(() => {
    reset(refundOrderForm);
    setOrderRefundModalIsOpen(false);
  }, []);

  const openOrderVoucherModal = useCallback(() => {
    setOrderVoucherModalIsOpen(true);
    setIsCancelAction(false);
  }, []);

  const closeOrderVoucherModal = useCallback(() => {
    setOrderVoucherModalIsOpen(false);
  }, []);

  const openSendRefundConfirmationModal = useCallback(() => {
    setSendRefundConfirmationModalIsOpen(true);
    setIsCancelAction(false);
  }, []);

  const closeSendRefundConfirmationModal = useCallback(() => {
    setSendRefundConfirmationModalIsOpen(false);
  }, []);

  const openRushModal = useCallback(() => {
    setRushModalIsOpen(true);
  }, []);

  const closeRushModal = useCallback(() => {
    setRushModalIsOpen(false);
  }, []);

  const getShipmentNumbersForItems = () => {
    const itemShipments = {} as Record<number, number>;

    if (shipments) {
      for (const shipment of shipments!) {
        const itemIds = shipment.orderItemIds;

        for (const id of itemIds!) {
          itemShipments[id] = shipment.shipmentNumber!;
        }
      }
    }

    return itemShipments;
  };

  const getItemsLeftToShipCount = (itemShipments: Record<number, number>) => {
    let counter = 0;

    for (const item of order!.items!) {
      for (const itemId of item!.ids!) {
        const shipmentNumber = itemShipments[itemId];
        if (!shipmentNumber && !item.canceled) {
          counter++;
          break; // Count item groups
        }
      }
    }

    return counter;
  };

  const lookupOrderByOrderNumber = useCallback(async (e) => {
    if (!e.key || e.key !== keyNameEnum.Enter) {
      return;
    }

    setJumpToOrderInputValue(e.target.value);
  }, []);

  /** Order actions */

  const pauseOnClick = useCallback(async () => {
    const res = await pauseOrder(order?.orderNumber);

    if (res?.success) {
      fetchOrder();
    }
  }, [
    fetchOrder,
    order,
  ]);

  const unpauseOnClick = useCallback(async () => {
    const res = await unpauseOrder(order!.orderNumber);

    if (res?.success) {
      fetchOrder();
    }
  }, [
    fetchOrder,
    order,
  ]);

  const orderRush = useCallback(async (reason, note, shipDate) => {
    const res = await rushOrder(order!.orderNumber, reason, note, shipDate);
    if (res?.success) {
      materialSwal('Success', res.message, 'success');
      fetchOrder();
      closeRushModal();
    }
  }, [
    closeRushModal,
    fetchOrder,
    order,
  ]);

  const refundOrderModalCallback = useCallback(async (form: OrderRefundFormData) => {
    const {
      reason,
      actionType,
      reasonOther,
    } = form;

    const otherReasonSelected = reason === refundReasonEnum.Other;

    const refundAmount = (actionType === accountingActionTypeEnum.OrderFullRefund) ? 0 : form.amount;
    const result = await refundOrderWithCancel(
      order!.orderNumber,
      otherReasonSelected ? reasonOther : reason,
      actionType,
      refundAmount,
      isCancelAction,
      orderExistsOnCore
    );

    if (result?.success) {
      materialSwal('Success', result.message, 'success');
      fetchOrder();
      if (orderExistsOnCore) {
        fetchCoreOrderPricingDetails();
      }
      closeCancelWithRefundModal();
    }
  }, [
    closeCancelWithRefundModal,
    fetchCoreOrderPricingDetails,
    fetchOrder,
    isCancelAction,
    order,
    orderExistsOnCore,
  ]);

  const voucherOrderModalCallback = useCallback(async (formData: OrderVoucherFormData) => {
    const response = await applyVoucher({
      data: {
        orderNumber: order?.orderNumber,
        voucherCode: formData.voucherCode,
        voucherOrderId: parseInt(formData.voucherOrderNumber, 10),
      },
    });

    if (response.success) {
      materialSwal('Success', response.message, 'success');
      fetchOrder();
      closeOrderVoucherModal();
    }
  }, [order, applyVoucher, fetchOrder, closeOrderVoucherModal]);

  const sendRefundOrderConfirmationModalCallback = useCallback(async (form: SendRefundConfirmationFormData | undefined) => {
    if (!form) {
      console.error('Send refund confirmation form is undefined');
      return;
    }
    
    const result = await sendRefundConfirmation(order!.orderNumber, form.recipientEmail);

    if ((result as ApiResponse)?.success) {
      materialSwal('Success', (result as ApiResponse).message, 'success');
      fetchOrderNotes(order!.orderNumber!);
      closeSendRefundConfirmationModal();
    }
  }, [
    closeSendRefundConfirmationModal,
    fetchOrderNotes,
    order,
  ]);

  const hasShipments = () => shipments && shipments.length > 0;
  const getIsOrderPartiallyShipped = () => getItemsLeftToShipCount(getShipmentNumbersForItems()) > 0;

  const showOrderCancelOption = () => (order!.status && order!.status!.value! < orderStatusValueEnum.InPicking)
    || (roles?.includes(rolesEnum.SLAccountingAccess));

  const isOrderShipped = () => order?.status?.value === orderStatusValueEnum.Shipped;

  const orderAccountingActionsDisabled =
    (hfDisableOrderActions && order?.netSuiteAccountingSubmitStatus !== SubmitStatusEnum.Done);

  return (
    <div className='container'>
      {
        !order?.id &&
        <div>
          No order found
        </div>
      }
      {
        order?.id &&
        <div>
          {
            hasShipments() &&
            <OrderManagementShipmentHeaders
              order={order!}
              isOrderPartiallyShipped={getIsOrderPartiallyShipped()}
              itemsLeftToShipCount={getItemsLeftToShipCount(getShipmentNumbersForItems())}
              shipments={shipments!}
            />
          }

          <OrderManagementDetailsRushModal
            order={order}
            isOpen={rushModalIsOpen}
            rushOrder={orderRush}
            closeModal={closeRushModal}
          />

          <OrderManagementOrderRefundModal
            order={order!}
            isOpen={orderRefundModalIsOpen}
            isCancelAction={isCancelAction}
            onSubmit={refundOrderModalCallback}
            closeModal={closeOrderRefundModal}
            initialValues={{ actionType: getOrderActionType(order!.isCompleteRefund!) }}
            coreOrderPricing={coreOrderPricingDetailsResponse?.result ?? undefined}
          />

          <OrderManagementOrderVoucherModal
            order={order!}
            isOpen={orderVoucherModalIsOpen}
            onSubmit={voucherOrderModalCallback}
            closeModal={closeOrderVoucherModal}
          />

          <SendRefundConfirmationModal
            defaultEmail={order!.shippingInfo!.email}
            isOpen={sendRefundConfirmationModalIsOpen}
            onSubmit={sendRefundOrderConfirmationModalCallback}
            closeModal={closeSendRefundConfirmationModal}
          />

          <div className='order'>
            <div className='order__side-wrapper'>
              <div className='navigation mb-10'>
                <OrderManagementBack />
                <div className='order__search-items ml-40'>
                  <div className='text-field filter-group'>
                    <input
                      type='text'
                      placeholder='Jump to Order'
                      className='has-icon--right'
                      onKeyDown={lookupOrderByOrderNumber}
                    />
                    <Icon
                      materialIcon={'arrow_forward'}
                      classes={'text-field__icon--right'}
                    />
                  </div>
                </div>
              </div>
              <OrderManagementSidebar
                order={order!}
                fetchOrder={fetchOrder}
                coreOrderPricingDetails={coreOrderPricingDetailsResponse?.result ?? null}
                orderExistsOnCore={orderExistsOnCore}
                fetchCoreOrderPricingDetails={fetchCoreOrderPricingDetails}
                coreOrder={coreOrder!}
              />
            </div>

            <div className='order__main-wrapper'>
              <div className='align__right mb-10'>
                <ActionButton
                  text={'Order Actions'}
                  classes={'order__action-button'}
                  disabled={orderAccountingActionsDisabled}
                  tooltip={orderAccountingActionsDisabled ? 'Order actions are disabled until the order is submitted to NetSuite.' : ''}
                  actions={[
                    {
                      text: order!.paused ? 'Unpause' : 'Pause',
                      icon: 'pause',
                      action: order!.paused ? unpauseOnClick : pauseOnClick,
                      permissions: [PermissionsEnum.OrderManagementEdit],
                      isVisible: !(order!.canceled && order!.isCompleteRefund)
                        || (order!.canceled === null && !isOrderShipped()),
                      hasBottomBorder: false,
                    },
                    {
                      text: 'Rush',
                      icon: rushIcon,
                      isCustomIcon: true,
                      iconWidth: 18,
                      iconHeight: 22,
                      action: openRushModal,
                      permissions: [PermissionsEnum.OrderManagementEditRushOrder],
                      isVisible: !order!.rush && order!.canceled === null && !isOrderShipped(),
                      hasBottomBorder: false,
                    },
                    {
                      text: 'Refund',
                      icon: 'attach_money',
                      action: openOrderRefundModal,
                      permissions: [PermissionsEnum.OrderManagementEditRefundOrder],
                      isVisible:
                        (!order!.isCompleteRefund && order!.pricePaid! >= 0) && featureFlags.orderRefundEnabled,
                    },
                    {
                      text: 'Apply Voucher',
                      icon: 'confirmation_number',
                      action: openOrderVoucherModal,
                      permissions: [PermissionsEnum.OrderManagementEditRefundOrder],
                      isVisible:
                        (!order!.items?.some((x) => x.voucherCode)) && featureFlags.orderRefundEnabled,
                    },
                    {
                      text: 'Send Refund Receipt',
                      icon: 'email',
                      action: openSendRefundConfirmationModal,
                      isVisible: order!.refunded !== null && featureFlags.orderRefundEnabled,
                    },
                    {
                      text: 'Cancel',
                      icon: 'block',
                      action: openCancelWithRefundModal,
                      permissions: [PermissionsEnum.OrderManagementEditCancelOrder],
                      isVisible: (showOrderCancelOption() && order!.canceled === null),
                      isDangerous: true,
                      hasBottomBorder: false,
                    },
                  ]}
                />
              </div>
              <OrderManagementDetails
                order={order!}
                shipmentNumbersForItems={getShipmentNumbersForItems()}
                isOrderPartiallyShipped={getIsOrderPartiallyShipped()}
                fetchOrder={fetchOrder}
                fetchCoreOrderPricingDetails={fetchCoreOrderPricingDetails}
                coreOrderPricingDetails={coreOrderPricingDetailsResponse?.result}
                orderExistsOnCore={orderExistsOnCore}
                shipments={shipments ?? null}
                coreOrder={coreOrder!}
                resetPageInfo={resetPageInfo}
              />
            </div>
          </div>
        </div>
      }
    </div>
  );
});

export default connector(OrderManagement);
