/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */

export type GetDtgStationsOrderEnum = 'Default' | 'StationDisplayName' | 'StationIdentifier';


export const GetDtgStationsOrderEnum = {
  Default: 'Default' as GetDtgStationsOrderEnum,
  StationDisplayName: 'StationDisplayName' as GetDtgStationsOrderEnum,
  StationIdentifier: 'StationIdentifier' as GetDtgStationsOrderEnum,
};
