/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */

export interface QueriesLockerDto {
  id?: number;
  readonly lockerNumber?: number;
  gpCustomerId?: string | null;
  teamName?: string | null;
  totalOrders?: number;
  totalSales?: number | null;
  sales3Month?: number | null;
  totalFundraising?: number | null;
  productionNote?: string | null;
}
