/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */
import type { ApplyVoucherToOrderPriceCalculationDto } from './applyVoucherToOrderPriceCalculationDto';

export interface ApplyVoucherToOrderPriceCalculationDtoApiResponse {
  success?: boolean;
  message?: string | null;
  result?: ApplyVoucherToOrderPriceCalculationDto;
}
