import React, { useCallback, useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import adminsUrls from '@constants/sqdApiUrls/adminsUrls';
import { makeStyles, ThemeProvider } from '@material-ui/core';
import useDebounce from '@hooks/useDebounce';
import { slServicesApi } from 'sqdApis';
import MaterialTooltip from '@components/shared/Tooltips/MaterialTooltip';
import { muiTheme } from '@constants/values';

const useStyles = makeStyles({
  autoComplete: {
    width: 500,
    marginBottom: 10,
  },
  inputRoot: {
    padding: '0!important',
  },
  progress: {
    position: 'absolute',
    right: 50,
  },
});

export default function LockerManagerAutocomplete({ defaultValue, onManagerSelected }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const debouncedSearchValue = useDebounce(searchValue, 500);

  const handleInputChange = useCallback((e, val, type) => {
    if (type === 'input') {
      setSearchValue(val);
    } else {
      setSearchValue('');
      setOptions([]);
    }
  }, []);

  const handleSelected = useCallback((event, newValue) => {
    onManagerSelected(newValue);
  }, [onManagerSelected]);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleOptionSelected = useCallback((option, value) => {
    return option.name === value.name;
  }, []);

  const handleGetOptionLabel = useCallback((option) => {
    if (option?.firstName && option?.lastName) {
      return `${option.firstName} ${option.lastName} (${option.email})`;
    }

    if (option?.firstName) {
      return `${option.firstName} (${option.email})`;
    }

    return `${option.email}`;
  }, []);

  const renderTextfield = useCallback((params) => {
    return (
      <MaterialTooltip tooltipText='Type to search for a locker owner' placement='right'>
        <TextField
          {...params}
          placeholder="Locker Manager's Email or Name"
          variant='standard'
          color='primary'
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color='primary' size={20} className={classes.progress} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      </MaterialTooltip>
    );
  }, [classes.progress, loading]);

  useEffect(() => {
    (async () => {
      if (debouncedSearchValue) {
        setLoading(true);
        slServicesApi.get(
          adminsUrls.adminSuggestions(debouncedSearchValue, 15),
          { handleBlockingLoading: false },
        ).then((lockerManagers) => {
          setOptions(lockerManagers);
          setLoading(false);
        });
      }
    })();
  }, [debouncedSearchValue]);

  return (
    <ThemeProvider theme={muiTheme}>
      <Autocomplete
        className={classes.autoComplete}
        id='locker-manager-select'
        open={open}
        defaultValue={defaultValue}
        classes={{ inputRoot: classes.inputRoot }}
        openOnFocus={false}
        onOpen={handleOpen}
        onClose={handleClose}
        getOptionSelected={handleOptionSelected}
        getOptionLabel={handleGetOptionLabel}
        options={options}
        loading={loading}
        onInputChange={handleInputChange}
        onChange={handleSelected}
        renderInput={renderTextfield}
      />
    </ThemeProvider>
  );
}
