/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */
import type { HagTransmissionStatusEnum } from './hagTransmissionStatusEnum';
import type { HagTransmissionTypeEnum } from './hagTransmissionTypeEnum';
import type { IHagTransmissionImageDto } from './iHagTransmissionImageDto';

export interface IHagTransmissionDto {
  readonly id?: number;
  readonly uuid?: string | null;
  readonly created?: string | null;
  readonly updated?: string | null;
  readonly deleted?: string | null;
  status?: HagTransmissionStatusEnum;
  type?: HagTransmissionTypeEnum;
  readonly station?: string | null;
  readonly message?: string | null;
  readonly hagTransmissionImages?: IHagTransmissionImageDto[] | null;
}
