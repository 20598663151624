/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */
import type { OrderItemStatusEnum } from './orderItemStatusEnum';
import type { DecorationMethodEnum } from './decorationMethodEnum';

export interface WarehouseOrderItemDto {
  id?: number;
  sku?: string | null;
  styleName?: string | null;
  basePrice?: number | null;
  pricePaid?: number | null;
  brandName?: string | null;
  size?: string | null;
  colorGroup?: string | null;
  status?: OrderItemStatusEnum;
  requiredItem?: boolean;
  imageUrl?: string | null;
  isInventoryAvailable?: boolean;
  promisedDate?: string | null;
  decorationMethod?: DecorationMethodEnum;
}
