/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Financials Homefield API
 * OpenAPI spec version: v1
 */
import type { VoucherInstanceStatusEnum } from './voucherInstanceStatusEnum';
import type { VoucherOrderPaymentStatusEnum } from './voucherOrderPaymentStatusEnum';
import type { VoucherCollectionItemDto } from './voucherCollectionItemDto';

export interface VoucherInstanceDetailsDto {
  id?: number;
  created?: string | null;
  updated?: string | null;
  deleted?: string | null;
  status?: VoucherInstanceStatusEnum;
  paymentStatus?: VoucherOrderPaymentStatusEnum;
  voucherOrderId?: number;
  lockerId?: number;
  collectionId?: number;
  rosterTeamId?: number;
  rosterTeamMemberId?: number;
  cancellationId?: number | null;
  code?: string | null;
  voucherCollectionItems?: VoucherCollectionItemDto[] | null;
}
