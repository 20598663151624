/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Product Catalog API
 * OpenAPI spec version: v1
 */
import type { DecorationSizeEnum } from './decorationSizeEnum';

export interface ProductFulfillmentInfoDto {
  sku?: string | null;
  dropshipVFIs?: boolean;
  waterproofed?: boolean;
  vendorId?: string | null;
  decorationSize?: DecorationSizeEnum;
  defaultNonCustomPersonalizationColorId?: number | null;
  defaultCustomPersonalizationColorId?: number | null;
  fallbackPersonalizationColorId?: number;
  imageUrlFront?: string | null;
  imageUrlGrid?: string | null;
}
