import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reset } from 'redux-form';
import {
  addDecorationLocationArtworkSize,
  editDecorationLocationArtworkSize,
  deleteDecorationLocationArtworkSize,
} from '@APICalls/decorationLocationArtworkSize/actions';
import {
  decorationMethodEnum,
  decorationSizeEnum,
} from '@constants/enums/decorationEnums';
import { keyNameEnum } from '@constants/enums/commonEnums';
import { decorationLocationArtworkSizeForm } from '@constants/reduxForms';
import {
  getDecorationLocationArtworkSizes,
  getDecorationLocations,
} from '@redux/productCatalog/actions';
import { parseDateTimeNumeric } from '@util/dateHandler';
import { materialSwal } from '@util/componentHelper';
import Button from '@sharedComponents/Buttons/Button';
import Table from '@sharedComponents/Table/Table';
import SearchFilter from '@sharedComponents/Inputs/SearchFilter';
import CheckCircleIcon from '@sharedComponents/Icons/CheckCircleIcon';
import DecorationLocationArtworkSizesActionsColumn from './DecorationLocationArtworkSizesActionsColumn';
import DecorationLocationArtworkSizeAddModal from './DecorationLocationArtworkSizeModals/DecorationLocationArtworkSizeAddModal';
import DecorationLocationArtworkSizeEditModal from './DecorationLocationArtworkSizeModals/DecorationLocationArtworkSizeEditModal';
import DecorationLocationArtworkSizeDeleteModal from './DecorationLocationArtworkSizeModals/DecorationLocationArtworkSizeDeleteModal';

const DecorationLocationArtworkSizesTable = Table();

class DecorationLocationArtworkSizes extends Component {
  constructor(props) {
    super(props);

    const data = this.joinArtworkSizesWithLocations();

    this.state = {
      addDecorationLocationArtworkSizeModalIsOpened: false,
      editDecorationLocationArtworkSizeModalIsOpened: false,
      deleteDecorationLocationArtworkSizeModalIsOpened: false,
      selectedDecorationLocationArtworkSize: null,
      data,
      filteredData: data,
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(getDecorationLocations());
    this.fetchDecorationLocationArtworkSizes();
  }

  componentDidUpdate(oldProps) {
    const {
      artworkSizes,
      decorationLocations,
    } = this.props;

    if (oldProps.artworkSizes !== artworkSizes
      || oldProps.decorationLocations !== decorationLocations) {
      const data = [...this.joinArtworkSizesWithLocations()];

      this.setState({
        data,
        filteredData: this.filterData(data),
      });
    }
  }

  getColumns = () => [
    {
      Header: 'Location',
      accessor: 'decorationLocationCode',
      minWidth: 80,
      sortable: true,
      Cell: (cellProps) => cellProps.value && <span>{cellProps.value}</span>,
    },
    {
      Header: 'Method',
      accessor: 'decorationMethod',
      minWidth: 45,
      Cell: (cellProps) => cellProps.value && <span>{cellProps.value}</span>,
    },
    {
      Header: 'Decoration Size',
      accessor: 'decorationSize',
      minWidth: 80,
      Cell: (cellProps) => cellProps.value && <span>{cellProps.value === decorationSizeEnum.Undefined ? 'Default' : cellProps.value}</span>,
    },
    {
      Header: 'Artwork Size',
      accessor: '',
      minWidth: 100,
      Cell: (cellProps) => cellProps.value && <span>{`${cellProps.value.width}''W x ${cellProps.value.height}''H`}</span>,
    },
    {
      Header: 'Scaling Size',
      accessor: 'scalingSize',
      minWidth: 80,
      Cell: (cellProps) => <div className='product-catalog__icon-center'>{cellProps.value ? <CheckCircleIcon /> : '-'}</div>,
    },
    {
      Header: 'Created',
      accessor: 'created',
      minWidth: 100,
      Cell: (cellProps) => cellProps.value && <div>{parseDateTimeNumeric(cellProps.value)}</div>,
    },
    {
      Header: 'Updated',
      accessor: 'updated',
      minWidth: 100,
      Cell: (cellProps) => cellProps.value && <div>{parseDateTimeNumeric(cellProps.value)}</div>,
    },
    {
      Header: '',
      accessor: '',
      resizable: false,
      minWidth: 40,
      style: { overflow: 'visible' },
      Cell: (cellProps) => (
        <DecorationLocationArtworkSizesActionsColumn
          artworkSize={cellProps.value}
          onArtworkSizeEdit={this.openEditDecorationLocationArtworkSizeModal}
          onArtworkSizeDelete={this.openDeleteDecorationLocationArtworkSizeModal}
        />
      ),
    },
  ];

  joinArtworkSizesWithLocations = () => {
    const {
      artworkSizes,
      decorationLocations,
    } = this.props;

    artworkSizes.forEach((artworkSize) => {
      const decorationLocation = decorationLocations.find(
        (location) => location.id === artworkSize.decorationLocationId
      );
      artworkSize.decorationLocationCode = decorationLocation ? decorationLocation.code : '';
    });

    return artworkSizes;
  };

  filterData = (data, searchInput) => {
    const search = (searchInput || '').toLowerCase();

    if (search === '') {
      return data;
    }

    return data.filter((artworkSize) => artworkSize.decorationLocationCode.toLowerCase().includes(search)
      || artworkSize.decorationMethod.toLowerCase().includes(search)
      || artworkSize.decorationSize.toLowerCase().includes(search));
  };

  clearSearch = () => {
    const { data } = this.state;

    this.setState({ filteredData: this.filterData(data, '') });
  };

  onSearch = (e) => {
    if (e.key && e.key !== keyNameEnum.Enter) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();

    const { data } = this.state;

    const searchInput = e.target.value;

    this.setState(() => ({ filteredData: this.filterData(data, searchInput) }));
  };

  fetchDecorationLocationArtworkSizes = () => {
    const { dispatch } = this.props;

    dispatch(getDecorationLocationArtworkSizes());
  };

  addDecorationLocationArtworkSize = async (form) => {
    const result = await addDecorationLocationArtworkSize(form);

    if (result?.success) {
      materialSwal('Success', result.message, 'success');
      this.closeAddDecorationLocationArtworkSizeModal();
      this.fetchDecorationLocationArtworkSizes();
    }
  };

  editDecorationLocationArtworkSize = async (form) => {
    const result = await editDecorationLocationArtworkSize(form);

    if (result?.success) {
      materialSwal('Success', result.message, 'success');
      this.closeEditDecorationLocationArtworkSizeModal();
      this.fetchDecorationLocationArtworkSizes();
    }
  };

  deleteDecorationLocationArtworkSize = async (decorationLocationArtworkSizeId) => {
    const result = await deleteDecorationLocationArtworkSize(decorationLocationArtworkSizeId);

    if (result?.success) {
      materialSwal('Success', result.message, 'success');
      this.closeDeleteDecorationLocationArtworkSizeModal();
      this.fetchDecorationLocationArtworkSizes();
    }
  };

  openAddDecorationLocationArtworkSizeModal = () => {
    this.setState({ addDecorationLocationArtworkSizeModalIsOpened: true });
  };

  closeAddDecorationLocationArtworkSizeModal = () => {
    const { dispatch } = this.props;

    dispatch(reset(decorationLocationArtworkSizeForm));
    this.setState({ addDecorationLocationArtworkSizeModalIsOpened: false });
  };

  openEditDecorationLocationArtworkSizeModal = (artworkSize) => {
    this.setState({
      selectedDecorationLocationArtworkSize: artworkSize,
      editDecorationLocationArtworkSizeModalIsOpened: true,
    });
  };

  closeEditDecorationLocationArtworkSizeModal = () => {
    const { dispatch } = this.props;

    dispatch(reset(decorationLocationArtworkSizeForm));
    this.setState({
      editDecorationLocationArtworkSizeModalIsOpened: false,
      selectedDecorationLocationArtworkSize: null,
    });
  };

  openDeleteDecorationLocationArtworkSizeModal = (artworkSize) => {
    this.setState({
      selectedDecorationLocationArtworkSize: artworkSize,
      deleteDecorationLocationArtworkSizeModalIsOpened: true,
    });
  };

  closeDeleteDecorationLocationArtworkSizeModal = () => {
    this.setState({
      deleteDecorationLocationArtworkSizeModalIsOpened: false,
      selectedDecorationLocationArtworkSize: null,
    });
  };

  render() {
    const {
      addDecorationLocationArtworkSizeModalIsOpened,
      editDecorationLocationArtworkSizeModalIsOpened,
      deleteDecorationLocationArtworkSizeModalIsOpened,
      selectedDecorationLocationArtworkSize,
      filteredData,
    } = this.state;

    const { decorationLocations } = this.props;

    const initialValues = !selectedDecorationLocationArtworkSize
      ? {
        decorationLocationId: decorationLocations.length ? decorationLocations[0].id : 0,
        decorationMethod: decorationMethodEnum.HAG,
        decorationSize: decorationSizeEnum.Undefined,
        scalingSize: false,
      }
      : selectedDecorationLocationArtworkSize;

    return (
      <div className='container'>
        <DecorationLocationArtworkSizeAddModal
          isOpen={addDecorationLocationArtworkSizeModalIsOpened}
          closeModal={this.closeAddDecorationLocationArtworkSizeModal}
          addDecorationLocationArtworkSize={this.addDecorationLocationArtworkSize}
          initialValues={initialValues}
          decorationLocations={decorationLocations}
        />

        <DecorationLocationArtworkSizeEditModal
          isOpen={editDecorationLocationArtworkSizeModalIsOpened}
          closeModal={this.closeEditDecorationLocationArtworkSizeModal}
          editDecorationLocationArtworkSize={this.editDecorationLocationArtworkSize}
          initialValues={initialValues}
          decorationLocations={decorationLocations}
        />

        <DecorationLocationArtworkSizeDeleteModal
          isOpen={deleteDecorationLocationArtworkSizeModalIsOpened}
          closeModal={this.closeDeleteDecorationLocationArtworkSizeModal}
          deleteDecorationLocationArtworkSize={this.deleteDecorationLocationArtworkSize}
          decorationLocationArtworkSize={initialValues}
        />

        <div className='table-options w-100'>
          <div className='flex'>
            <SearchFilter
              search={this.onSearch}
              clearSearch={this.clearSearch}
            />
          </div>
          <div className='flex'>
            <Button
              type={'primary'}
              text={'Add Artwork Size'}
              onClick={this.openAddDecorationLocationArtworkSizeModal}
              classes={'colors__add-btn'}
            />
          </div>
        </div>
        <div className='sheet'>
          <DecorationLocationArtworkSizesTable
            data={filteredData}
            columns={this.getColumns()}
            showPagination={false}
          />
        </div>
      </div>
    );
  }
}

DecorationLocationArtworkSizes.propTypes = {
  artworkSizes: PropTypes.arrayOf(PropTypes.object).isRequired,
  decorationLocations: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const mapStateToProps = ({ productCatalog }) => ({
  artworkSizes: productCatalog.decorationLocationArtworkSizesList,
  decorationLocations: productCatalog.decorationLocations,
});

export default connect(mapStateToProps)(DecorationLocationArtworkSizes);
