import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  DragDropContext,
  Droppable,
  Draggable,
} from 'react-beautiful-dnd';
import Modal from '@sharedComponents/Modal/Modal';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import Icon from '@sharedComponents/Icons/Icon';

// const numberOfBoxesPerRow = 3;
// const rowHeight = 55;

class CategoriesReorderModal extends Component {
  state = {
    categories: this.props.categories,
  };

  componentDidUpdate(prevProps) {
    const {
      categories,
      isOpen,
    } = this.props;

    if (!prevProps.isOpen && isOpen) {
      this.setState({ categories });
    }
  }

  onDragEnd = (result) => {
    if (!result.destination || !result.source) return;
    if (result.destination.index === result.source.index) return;

    const categories = [...this.state.categories];
    const sourceIndex = result.source.index;
    const targetIndex = result.destination.index;

    const [removedCategory] = categories.splice(sourceIndex, 1);
    categories.splice(targetIndex, 0, removedCategory);

    this.setState({ categories });
    console.info(result);
  };

  renderDraggableCategory = (category) => (
    <div className='categories__draggable-item mr-20'>
      <Icon
        materialIcon={'drag_indicator'}
        classes={'mr-20'}
      />
      <span className='categories__draggable-item--info'>
        <b className='mr-5'>{category.sortOrder}</b>
        -
        <span className='ml-5'>{category.name}</span>
      </span>
    </div>
  );

  render() {
    const {
      isOpen,
      closeModal,
      save,
    } = this.props;
    const { categories } = this.state;

    console.info('categories', categories);

    return (
      <Modal
        title={'Edit Sort Order'}
        isOpen={isOpen}
        modalSize={'l'}
        closeModal={closeModal}
        buttons={(
          <ModalButtons
            confirmBtnText={'Save'}
            cancelBtnText={'Cancel'}
            onConfirm={save.bind(null, categories)}
            onClose={closeModal}
            isDangerousAction={true}
          />
        )}
      >
        <div className='mb-10'>Sort order</div>
        <DragDropContext onDragEnd={this.onDragEnd}>
          <Droppable
            droppableId='droppable'
            direction='vertical'
          >
              {(provided) => (
              <div
                ref={provided.innerRef}
                className=''
                {...provided.droppableProps}
              >
                {
                  categories.map((category, index) => (
                    <Draggable
                      key={(category?.id) || `e${index}`}
                      draggableId={(category?.id?.toString()) || `e${index}`}
                      index={index}
                      isDragDisabled={!category || (category && (!category.id || category.id <= 0))}
                    >
                      {
                        (draggableProvided) => (
                          <div
                            ref={draggableProvided.innerRef}
                            {...draggableProvided.draggableProps}
                            {...draggableProvided.dragHandleProps}
                            className='organization__featured-lockers__card-wrapper'
                          >
                            {
                              category?.id
                                ? (
                                  <>
                                    {this.renderDraggableCategory(category)}
                                  </>
                                )
                                : category
                            }
                          </div>
                        )
                      }
                    </Draggable>
                  ))
                }
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Modal>
    );
  }
}

CategoriesReorderModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  categories: PropTypes.arrayOf(PropTypes.object).isRequired,
  closeModal: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
};

export default CategoriesReorderModal;
