import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import yup from '@util/yupValidationHelper';
import FormInput from '@components/shared/Form/FormInput';
import { usePrevious } from '@hooks/helpers';

export interface QualityAssuranceCodeFormModel {
  code: string;
  name: string;
  owner: string;
  scenarios?: string | null;
}

interface OwnProps {
  formId: string;
  defaultValues?: QualityAssuranceCodeFormModel;
  needsReset?: boolean;
  onSubmit: (model: QualityAssuranceCodeFormModel) => Promise<unknown>;
}

type Props = OwnProps;

const schema = yup.object({
  code: yup.string().required('Code is required')
    .matches(/^[A-Z]{1,11}\d{1,9}$/, 'Must be of capital letters then numbers format')
    .max(20),
  name: yup.string().required('Name is required')
    .max(50),
  owner: yup.string().required('Owner is required')
    .max(50),
  scenarios: yup.string()
    .max(4000),
});

const QualityAssuranceCodeForm = React.memo(({
  onSubmit,
  formId,
  needsReset,
  defaultValues = {
    code: '',
    name: '',
    owner: '',
    scenarios: '',
  },
}: Props) => {
  const methods = useForm({
    // @ts-expect-error - resolver is weird
    resolver: yupResolver(schema),
    defaultValues,
  });

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = methods;

  const previousNeedsReset = usePrevious(needsReset);
  useEffect(() => {
    if (needsReset === true && previousNeedsReset === false) {
      reset();
    }
  }, [
    previousNeedsReset,
    needsReset,
    reset,
  ]);

  return (
    <form
      method='post'
      id={formId}
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className='flex mb-20'>
        <div className='redux-form__column--size-m ml-20'>
          <label className='redux-form__label'>
            Code
            <span className='required'>*</span>
          </label>
          <div data-test='collection-status-select'>
            <FormInput
              type='text'
              required={true}
              register={register}
              error={errors.code}
              {...register('code')}
            />
          </div>
        </div>
        <div className='redux-form__column--size-m ml-20'>
          <label className='redux-form__label'>
              Name
            <span className='required'>*</span>
          </label>
          <div data-test='collection-status-select'>
            <FormInput
              type='text'
              required={true}
              register={register}
              error={errors.name}
              {...register('name')}
            />
          </div>
        </div>
        <div className='redux-form__column--size-m ml-20'>
          <label className='redux-form__label'>
              Owner
            <span className='required'>*</span>
          </label>
          <div data-test='collection-status-select'>
            <FormInput
              type='text'
              required={true}
              register={register}
              error={errors.owner}
              {...register('owner')}
            />
          </div>
        </div>
      </div>
      <div className='flex mb-20'>
        <div className='redux-form__column--size-l ml-20'>
          <label className='redux-form__label'>
              Scenario
          </label>
          <div data-test='collection-status-select'>
            <FormInput
              type='textarea'
              required={false}
              register={register}
              error={errors.scenarios}
              {...register('scenarios')}
            />
          </div>
        </div>
      </div>
    </form>
  );
});

export default QualityAssuranceCodeForm;
