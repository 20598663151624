import ModalButtons from '@components/shared/Modal/ModalButtons';
import { accountingActionTypeEnum } from '@constants/enums/orderEnums';
import {
  formatDollarAmount, formatNumber,
} from '@util/numberHelpers';
import React from 'react';
import Modal from '@sharedComponents/Modal/Modal';
import OrderManagementExpandablePriceTab from './OrderManagementExpandablePriceTab';
import {
  OrderDetailsDto,
  OrderItemDetailsDto,
} from '@api/fulfillment/models';
import {
  OrderItemPricingDetails,
  OrderPricingDetailsDto,
} from '@api/orders/models';

interface OwnProps {
    isOpen: boolean;
    closeModal: () => void;
    orderItemReplacePriceCalculation: OrderItemPricingDetails;
    replace: () => void;
    orderItem: OrderItemDetailsDto;
    order: OrderDetailsDto;
    quantity: number;
    coreOrderPricingDetails: Nullable<OrderPricingDetailsDto>;
  }

type Props = OwnProps;

const OrderManagementDetailsReplaceBySkuConfirmationNewModal = React.memo<Props>(({
  isOpen,
  closeModal,
  orderItemReplacePriceCalculation,
  replace,
  orderItem,
  order,
  quantity,
  coreOrderPricingDetails,
}) => {
  if (!isOpen) return null;

  const itemPricingDetails = coreOrderPricingDetails?.orderItemsPricingDetails?.[orderItem?.coreId ?? 0] ?? orderItem;

  const newBasePrice = orderItemReplacePriceCalculation?.basePrice ?? 0;
  const oldBasePrice = itemPricingDetails?.basePrice ?? 0;

  
  const discountAmountToSubtract = (itemPricingDetails as OrderItemPricingDetails).salesChannelDiscountAmount 
  ?? (itemPricingDetails as OrderItemDetailsDto)!.lockerDiscountAmount ?? 0;

  const pretaxAmountToChange = orderItemReplacePriceCalculation
    ? (newBasePrice - oldBasePrice
      - (parseFloat(formatNumber(orderItemReplacePriceCalculation.styleDiscountAmount!))
      - itemPricingDetails!.styleDiscountAmount!)
      - (parseFloat(formatNumber(orderItemReplacePriceCalculation.salesChannelDiscountAmount))
        - discountAmountToSubtract)
      + (parseFloat(formatNumber(orderItemReplacePriceCalculation.fundraisingBaseAmount!))
        - itemPricingDetails!.fundraisingBaseAmount!)
      - (parseFloat(formatNumber(orderItemReplacePriceCalculation.fundraisingCouponDiscount!))
      - itemPricingDetails!.fundraisingCouponDiscount!)
      + (parseFloat(formatNumber(orderItemReplacePriceCalculation.fundraisingAmount!))
      - itemPricingDetails!.fundraisingAmount!) * quantity)
    : 0;
  const paymentType = pretaxAmountToChange! < 0
    ? accountingActionTypeEnum.Refund
    : accountingActionTypeEnum.Charge;

  const deets = itemPricingDetails ? itemPricingDetails as OrderItemDetailsDto : {} as OrderItemDetailsDto;

const taxAmountToChange = (orderItemReplacePriceCalculation?.unitTaxAmount ?? 0) * quantity
- (coreOrderPricingDetails
  ? ((itemPricingDetails as OrderItemPricingDetails)?.unitTaxAmount ?? 0) * quantity
  // eslint-disable-next-line no-constant-binary-expression
  : ((deets?.taxAmount ?? 0 / (deets?.quantity ?? 1)) ?? 0) * quantity);

  const oldTax = order.taxAmount!;
  const newTax = oldTax + taxAmountToChange;

  return (
    <Modal
      isOpen={isOpen}
      closeModal={closeModal}
      modalHeight={'l'}
      modalWidth={'m'}
      isPadded={true}
      title={'Replace by SKU - Price Confirmation'}
      buttons={(
        <ModalButtons
          confirmBtnText={'Replace'}
          cancelBtnText={'Cancel'}
          onConfirm={replace}
          onClose={closeModal}
          isDangerousAction={true}
          confirmBtnDisabled={!orderItemReplacePriceCalculation}
        />
      )}
    >
      <div>
        {
          isOpen &&
          <div>
            <OrderManagementExpandablePriceTab
              label={'Order item'}
              subLabel={'(before tax)'}
              disableExpand={true}
              content={(
                <div>
                  <div className='order-financial-details__content'>
                    <div className='justify__space-between'>
                      <span>Base price</span>
                      <div>
                        <span className='current-amount'>{formatDollarAmount(orderItemReplacePriceCalculation?.basePrice)}</span>
                        <span className='line-through'>({formatDollarAmount(itemPricingDetails!.basePrice)})</span>
                      </div>
                    </div>
                    <div className='justify__space-between mt-15'>
                      <span>Style discount</span>
                      <div>
                        <span className='negative-amount mr-5'>- {formatDollarAmount(orderItemReplacePriceCalculation?.styleDiscountAmount)}</span>
                        <span className='line-through'>({formatDollarAmount(itemPricingDetails!.styleDiscountAmount)})</span>
                      </div>
                    </div>
                    <div className='justify__space-between mt-15'>
                      <span>Locker discount</span>
                      <div>
                        <span className='negative-amount mr-5'>- {formatDollarAmount(orderItemReplacePriceCalculation?.salesChannelDiscountAmount)}</span>
                        <span className='line-through'>({formatDollarAmount((itemPricingDetails as OrderItemPricingDetails)!.salesChannelDiscountAmount!
                          ?? (itemPricingDetails as OrderItemDetailsDto)!.lockerDiscountAmount)})
                        </span>
                      </div>
                    </div>
                    <div className='justify__space-between mt-15'>
                      <span>Fundraising base amount</span>
                      <div>
                        <span className='current-amount'>{formatDollarAmount(orderItemReplacePriceCalculation?.fundraisingBaseAmount)}</span>
                        <span className='line-through'>({formatDollarAmount(itemPricingDetails!.fundraisingBaseAmount)})</span>
                      </div>
                    </div>
                    <div className='justify__space-between mt-15'>
                      <span>Fundraising coupon discount</span>
                      <div>
                        <span className='negative-amount mr-5'>- {formatDollarAmount(orderItemReplacePriceCalculation?.fundraisingCouponDiscount)}</span>
                        <span className='line-through'>({formatDollarAmount(itemPricingDetails!.fundraisingCouponDiscount)})</span>
                      </div>
                    </div>
                    <div className='justify__space-between mt-15'>
                      <span>Fundraising amount</span>
                      <div>
                        <span className='current-amount'>{formatDollarAmount(orderItemReplacePriceCalculation?.fundraisingAmount)}</span>
                        <span className='line-through'>({formatDollarAmount(itemPricingDetails!.fundraisingAmount)})</span>
                      </div>
                    </div>
                  </div>
                  <div className='order-financial-details__subtotal'>
                    <div>
                      <div className='order-financial-details__subtotal--explanation'>(quantity: {quantity})</div>
                      <span>{paymentType} amount</span>
                    </div>
                    <span className='align__flex-end'>{
                      formatDollarAmount(Math.abs(pretaxAmountToChange!) * quantity)}
                    </span>
                  </div>
                </div>
              )}
            />

            <OrderManagementExpandablePriceTab
              label={'Tax'}
              disableExpand={true}
              classes={'mt-30'}
              content={(
                <div>
                  <div className='order-financial-details__content'>
                    <div className='justify__space-between'>
                      <span>Tax</span>
                      <div>
                        <span className='current-amount'>{formatDollarAmount(newTax)}</span>
                        <span className='line-through'>({formatDollarAmount(oldTax)})</span>
                      </div>
                    </div>
                  </div>
                  <div className='order-financial-details__subtotal'>
                    <div>
                      <div className='order-financial-details__subtotal--explanation'>(quantity: {quantity})</div>
                      <span>{paymentType} tax amount</span>
                    </div>
                    <span className='align__flex-end'>{
                      formatDollarAmount(Math.abs(taxAmountToChange))}
                    </span>
                  </div>
                </div>
              )}
            />

            <div className='mt-30'>
              <div className='order-financial-details__total--explanation'>(quantity: {quantity})</div>
              <div className='order-financial-details__total'>
                <span>{paymentType} Total Amount</span>
                <span>
                  {formatDollarAmount(Math.abs(pretaxAmountToChange!) * quantity
                     + Math.abs(taxAmountToChange))}
                </span>
              </div>
            </div>
          </div>
        }
      </div>
    </Modal>
  );
});

export default OrderManagementDetailsReplaceBySkuConfirmationNewModal;
