import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { parseDateTimeNumeric } from '@util/dateHandler';
import {
  itemStatusEnum,
  lineItemStatusEnum,
  flagStatusEnum,
} from '@constants/enums/orderEnums';

class ProductionAssemblyReviewItemHistory extends PureComponent {
  render() {
    const { item } = this.props;
    let itemHistory = null;

    if (item?.statusHistory) {
      const filteredStatuses = item.statusHistory
        .filter((el, i, arr) => i === arr.findIndex((a) => a.status === el.status && a.created === el.created));

      const sortedStatuses = filteredStatuses.sort((a, b) => moment(b.created).diff(moment(a.created)));

      itemHistory = sortedStatuses.map((s) => {
        let labelColor = 'green';
        let showCheck = true;
        let label = item.ids.includes(s.id) ? itemStatusEnum[s.status] : lineItemStatusEnum[s.status];

        if ([
          flagStatusEnum.Unflagged,
          flagStatusEnum.Flagged,
        ].includes(s.status)) {
          labelColor = 'red';
          showCheck = false;
          label = s.label;
        }

        return (
          <div
            key={s.id}
            className='history__item'
          >
            <div className={`history__item--action ${labelColor}`}>
              <span>{label}</span>
              {showCheck && <i className='material-icons'>check</i>}
            </div>
            <div className='history__item--data'>
              <span>{s.user}</span>
              <span>{parseDateTimeNumeric(s.created)}</span>
            </div>
          </div>
        );
      });
    }

    return (
      <div className='order-assembly__history'>
        <div className='order-assembly__history-title'>
          <span>Item History</span>
        </div>
        <div className='order-assembly__history-items custom-scrollbar--horizontal'>
          {itemHistory}
        </div>
      </div>
    );
  }
}

ProductionAssemblyReviewItemHistory.propTypes = {
  item: PropTypes.object.isRequired,
};

export default ProductionAssemblyReviewItemHistory;
