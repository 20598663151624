import { createUserManager } from 'redux-oidc';
import {
  WebStorageStateStore,
  InMemoryWebStorage,
} from 'oidc-client';
import { identityRoot } from '@constants/urls/configUrls';

interface UserManagerConfig {
  client_id: string;
  redirect_uri: string;
  response_type: string;
  scope: string;
  authority: string;
  silent_redirect_uri: string;
  post_logout_redirect_uri: string;
  automaticSilentRenew: boolean;
  filterProtocolClaims: boolean;
  loadUserInfo: boolean;
  stateStore?: WebStorageStateStore;
  userStore?: WebStorageStateStore;
}

const userManagerConfig: UserManagerConfig = {
  client_id: 'homefield-client-implicit',
  redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/callback`,
  response_type: 'token id_token',
  scope: 'openid homefield homefield_profile',
  authority: identityRoot!,
  silent_redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/silent_renew`,
  post_logout_redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/login`,
  automaticSilentRenew: true,
  filterProtocolClaims: true,
  loadUserInfo: true,
};

if (process.env.NODE_ENV === 'test') {
  const stateStoreStorage = new InMemoryWebStorage();
  const userStoreStorage = new InMemoryWebStorage();

  userManagerConfig.stateStore = new WebStorageStateStore({
    store: stateStoreStorage,
  });
  userManagerConfig.userStore = new WebStorageStateStore({
    store: userStoreStorage,
  });
}

const userManager = createUserManager(userManagerConfig);

export default userManager;
