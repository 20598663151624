/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Financials Homefield API
 * OpenAPI spec version: v1
 */

export interface LockerStatusDto {
  id?: number;
  uuid?: string | null;
  created?: string | null;
  updated?: string | null;
  deleted?: string | null;
  teamName?: string | null;
  fundraisingAmountTotal?: number;
  fundraisingAmountAvailable?: number;
  fundraisingAmountProcessing?: number;
  payoutAmountRequested?: number;
  payoutAmountCompleted?: number;
  payoutAmountCanceled?: number;
}
