/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Accounts Homefield API
 * OpenAPI spec version: 1.0
 */
import type { StagingJobOutput } from './stagingJobOutput';

export interface StagingJobOutputPagedList {
  pageNumber: number;
  pageSize: number;
  totalCount: number;
  items?: StagingJobOutput[] | null;
  readonly totalPages: number;
  readonly hasPreviousPage: boolean;
  readonly hasNextPage: boolean;
}
