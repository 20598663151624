/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */

export type ArtworkFileTypeEnum = 'Undefined' | 'HagEps' | 'EmbPdf' | 'EmbDst' | 'EmbEmb' | 'Png' | 'HagStrippedEps';


export const ArtworkFileTypeEnum = {
  Undefined: 'Undefined' as ArtworkFileTypeEnum,
  HagEps: 'HagEps' as ArtworkFileTypeEnum,
  EmbPdf: 'EmbPdf' as ArtworkFileTypeEnum,
  EmbDst: 'EmbDst' as ArtworkFileTypeEnum,
  EmbEmb: 'EmbEmb' as ArtworkFileTypeEnum,
  Png: 'Png' as ArtworkFileTypeEnum,
  HagStrippedEps: 'HagStrippedEps' as ArtworkFileTypeEnum,
};
