/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Catalog Homefield API
 * OpenAPI spec version: v1
 */
import type { CategoryDto } from './categoryDto';
import type { LogoLayoutItemDto } from './logoLayoutItemDto';
import type { PersonalizationLayoutItemDto } from './personalizationLayoutItemDto';

export interface LayoutDetailDto {
  id?: number;
  uuid?: string | null;
  created?: string | null;
  updated?: string | null;
  deleted?: string | null;
  name?: string | null;
  categoryId?: number | null;
  category?: CategoryDto;
  imageUrlFront?: string | null;
  imageUrlBack?: string | null;
  imageUrlRight?: string | null;
  imageUrlLeft?: string | null;
  logos?: LogoLayoutItemDto[] | null;
  personalizations?: PersonalizationLayoutItemDto[] | null;
}
