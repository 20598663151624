/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */

export interface LockerLogoDto {
  id?: number;
  image?: string | null;
  original_Image?: string | null;
  help_Text?: string | null;
  decoration_Methods?: string[] | null;
}
