import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { submitTaskNotes } from '@redux/artworkFileTasks/actions';
import { roleMapping } from '@util/roleCheck';
import Button from '@sharedComponents/Buttons/Button';
import ArtworkTaskNoteUploader from './ArtworkTaskNoteUploader';

const defaultNotePlaceholder = 'Write a note...';

class ArtworkTaskNewNote extends Component {
  state = {
    attachedFile: [],
  };

  componentDidUpdate(oldProps) {
    if (oldProps.taskId !== this.props.taskId) {
      this.noteField.value = '';
      this.clearFileFromState();
    }
  }

  clearFileFromState = () => {
    this.setState({ attachedFile: null });
  };

  addFileToState = (file) => {
    this.setState({ attachedFile: file });
  };

  onClear = () => {
    this.setState({
      message: defaultNotePlaceholder,
      files: [],
    });
  };

  addNewNote = () => {
    const { attachedFile } = this.state;
    this.noteField.disabled = true;

    if (attachedFile) {
      this.submitNote(this.noteField.value, attachedFile);
    } else {
      this.submitNote(this.noteField.value);
    }
  };

  submitNote = async (text, file = null) => {
    const {
      dispatch,
      lockerId,
      taskId,
    } = this.props;

    const note = {
      text,
      file,
    };

    await dispatch(submitTaskNotes(lockerId, taskId, note));

    this.noteField.disabled = false;
    this.noteField.value = '';
    this.clearFileFromState();
  };

  setNoteFieldRef = (r) => {
    this.noteField = r;
  };

  render() {
    const { roles } = this.props;
    const { attachedFile } = this.state;

    return (
      <div>
        <div className='text-field'>
          <textarea
            ref={this.setNoteFieldRef}
            className='w-100'
            rows='2'
            id='regular-note-field'
            disabled={roles.includes('Finalizer')}
            placeholder={defaultNotePlaceholder}
          />
        </div>
        <div className='upload-field--horizontal m-t-16'>
          <ArtworkTaskNoteUploader
            filesAreAttached={!!attachedFile}
            userRoles={roles}
            attachFile={this.addFileToState}
            removeAttachedFile={this.clearFileFromState}
          />
        </div>
        <div>
          <Button
            type={'primary'}
            text={'Add Note'}
            onClick={this.addNewNote}
          />
        </div>
      </div>
    );
  }
}

ArtworkTaskNewNote.propTypes = {
  taskId: PropTypes.number.isRequired,
  lockerId: PropTypes.number.isRequired,
  roles: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const mapStateToProps = ({ oidc }) => ({
  roles: roleMapping(oidc),
});

export default connect(mapStateToProps)(ArtworkTaskNewNote);
