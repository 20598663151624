/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Financials Homefield API
 * OpenAPI spec version: v1
 */
import type { SalesChannelTypeEnum } from './salesChannelTypeEnum';
import type { IContactDto } from './iContactDto';

export interface IContactDtoISalesChannelDetailDto {
  readonly id?: number;
  readonly uuid?: string | null;
  readonly created?: string | null;
  readonly updated?: string | null;
  readonly deleted?: string | null;
  readonly organizationId?: number | null;
  readonly name?: string | null;
  readonly slug?: string | null;
  type?: SalesChannelTypeEnum;
  readonly teamType?: string | null;
  readonly logoUrl?: string | null;
  readonly partnerAccountingId?: string | null;
  readonly partnerCommission?: string | null;
  readonly discountPercent?: number;
  readonly fundraisingPercent?: number;
  readonly hasRequiredItem?: boolean;
  readonly accountingId?: string | null;
  owner?: IContactDto;
}
