/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Catalog Homefield API
 * OpenAPI spec version: v1
 */

export type QueriesSortByEnum = 'Brand';


export const QueriesSortByEnum = {
  Brand: 'Brand' as QueriesSortByEnum,
};
