import React, {
  useCallback,
  useMemo,
  useState,
} from 'react';
import Dropdown from '@sharedComponents/Inputs/Dropdowns/Dropdown';
import { HagReworkLogoItems } from './LogoRejectionItem';
import { createOptionsList } from '@util/optionsMap';

interface OwnProps {
  item: HagReworkLogoItems;
  onFailNotesChange: (artworkTaskId: number, value: string) => void;
  onFailReasonChange: (artworkTaskId: number, value: string) => void;
  failNote?: string;
  failReason?: string;
  isSelected: (key: any) => boolean;
}

type Props = OwnProps;

const PrintRoomOrderRejectNoteCell = React.memo<Props>(({
  item,
  onFailNotesChange,
  onFailReasonChange,
  isSelected,
}) => {
  const [
    reason,
    setReason,
  ] = useState(undefined);

  const [
    notes,
    setNotes,
  ] = useState(undefined);

  const updateFailReason = useCallback((e) => {
    setReason(e.target.value);
    if (item.artworkTaskId) {
      onFailReasonChange(item.artworkTaskId, e.target.value);
    }
  }, [
    onFailReasonChange,
    item,
  ]);

  const updateFailNote = useCallback((e) => {
    setNotes(e.target.value);
    if (item.artworkTaskId) {
      onFailNotesChange(item.artworkTaskId, e.target.value);
    }
  }, [
    onFailNotesChange,
    item,
  ]);

  const hagReworkReasonOptions = useMemo(() => {
    const hagReworkReasons = [
      {
        id: 1,
        reason: 'Color variance',
      },
      {
        id: 2,
        reason: 'Incorrect stitch count',
      },
      {
        id: 3,
        reason: 'Missing logo elements',
      },
      {
        id: 4,
        reason: 'Poor vectorization',
      },
      {
        id: 5,
        reason: 'Poor digitization',
      },
      {
        id: 6,
        reason: 'Misc',
      },
      {
        id: 7,
        reason: 'Incorrect sizing',
      },
    ];

    return createOptionsList({
      // @ts-expect-error - TS doesn't like the type of hagReworkReasons
      list: hagReworkReasons,
      key: 'id',
      value: 'reason',
      name: 'reason',
      emptyOption: {
        disabled: false,
        name: 'Select fail reason',
      },
    });
  }, []);

  return (
    <div className='note-cell'>
      {
        !isSelected(item?.artworkTaskId) &&
        <div className='note-cell-input'>
          <Dropdown
            options={hagReworkReasonOptions}
            defaultValue={reason}
            disabled={false}
            classes={'mb-5'}
            onChange={updateFailReason}
          />
          <div className='text-field no-padding'>
            <input
              className='w-100'
              value={notes}
              onChange={updateFailNote}
              placeholder='Add a note'
            />
          </div>
        </div>
      }
    </div>
  );
});

export default PrintRoomOrderRejectNoteCell;
