/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Financials Homefield API
 * OpenAPI spec version: v1
 */
import type { VoucherInstanceStatusEnum } from './voucherInstanceStatusEnum';

export interface VoucherInstanceDto {
  id?: number;
  uuid?: string | null;
  created?: string | null;
  updated?: string | null;
  deleted?: string | null;
  voucherCollectionId?: number;
  status?: VoucherInstanceStatusEnum;
  amount?: number;
  amountLeftForRefund?: number;
  pricePaid?: number;
  code?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  rosterTeamMemberId?: number;
  cancellationId?: number | null;
}
