/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */
import type { ArtworkFileTypeEnum } from './artworkFileTypeEnum';

export interface UploadArtworkTaskFileCommand {
  taskId?: number;
  filePaths?: string[] | null;
  fileType?: ArtworkFileTypeEnum;
}
