/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Catalog Homefield API
 * OpenAPI spec version: v1
 */
import type { ProductGenderEnum } from './productGenderEnum';
import type { ProductAgeEnum } from './productAgeEnum';
import type { DecorationMethodEnum } from './decorationMethodEnum';
import type { StyleDecorationLocationDto } from './styleDecorationLocationDto';

export interface UpdateStyleCommand {
  id?: number;
  code?: string | null;
  name?: string | null;
  description?: string | null;
  gender?: ProductGenderEnum;
  age?: ProductAgeEnum;
  categoryId?: number | null;
  brandId?: number | null;
  price?: number;
  discount?: number;
  recommended?: boolean;
  availableForNewStores?: boolean;
  alwaysInclude?: boolean;
  neutral?: boolean;
  prdOnly?: boolean;
  customizable?: boolean;
  vendorFulfilledItem?: boolean;
  classId?: string | null;
  qtyVendorMinimum?: number | null;
  alwaysAvailableVendorInventory?: boolean;
  disabled?: boolean;
  discontinued?: boolean;
  sizeChartUrl?: string | null;
  decorationMethod?: DecorationMethodEnum;
  taxCode?: string | null;
  waterproof?: boolean;
  decorationLocations?: StyleDecorationLocationDto[] | null;
}
