import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Button from '@components/shared/Buttons/Button';
import AutocompleteInput from '@components/shared/Inputs/AutocompleteInput';
import { bulkStyleCreationForm } from '@constants/reduxForms';
import { BulkStyleCreationFormData } from '@models/forms/BulkActions/BulkStyleCreationFormData';
import { QueryObserverResult, RefetchOptions } from 'react-query';
import { ApiInternalServerError, StylesSearchViewModel } from '@api/squadlockerServices/models';
import { useForm, UseFormReturn } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useGetStylesWhereCodeContains } from '@api/squadlockerServices/locker-manager';
import yup from '@util/yupValidationHelper';
import { minInputLengthForSkuSuggestions } from '@constants/values';

const schema = yup.object({
  code: yup.string()
    .min(minInputLengthForSkuSuggestions, 'SKU is too short')
    .required('SKU is required'),
  id: yup.number().required('ID is required'),
});

interface OwnProps {
  onSubmit: (data: BulkStyleCreationFormData) => Promise<void>;
  effectedLockersCount: number | undefined;
  fetchingEffectedLockersCount: boolean;
  setSelectedStyle: (cs: Nullable<StylesSearchViewModel>) => void;
  fetchEffectedLockersCount: (options?: RefetchOptions | undefined) => Promise<QueryObserverResult<number, ApiInternalServerError>>;
  selectedStyle: Nullable<StylesSearchViewModel>;
}

type Props = OwnProps;

const displayStyle = (s: StylesSearchViewModel) => s ? `${s.code}` : '';
const displayStyleSuggestion = (s: StylesSearchViewModel) => `${s.code} ${s.name} ID: ${s.id}`;

const BulkStyleCreationForm = React.memo<Props>(({
  onSubmit,
  effectedLockersCount,
  fetchingEffectedLockersCount,
  setSelectedStyle,
  fetchEffectedLockersCount,
  selectedStyle,
}) => {
  const formMethods: UseFormReturn<BulkStyleCreationFormData> = useForm<BulkStyleCreationFormData>({
    resolver: yupResolver(schema),
  });

  const {
    handleSubmit,
    setValue,
  } = formMethods;

  const bulkStyleCreate = useCallback(async () => {
    handleSubmit(onSubmit)();
  }, [
    handleSubmit,
    onSubmit,
  ]);

  const [
    fetchSuggestionsCallback,
    setFetchSuggestionsCallback,
  ] = useState<boolean>(false);

  const [
    effectedLockersCountRequestSent,
    setEffectedLockersCountRequestSent,
  ] = useState<boolean>(false);

  const [
    styleSearchInput,
    setStyleSearchInput,
  ] = useState<string>('');

  const {
    data: styleSuggestions,
    refetch: fetchStyleSuggestions,
  } = useGetStylesWhereCodeContains({ styleCodeContains: styleSearchInput });

  const suggestions = useMemo(
    () => styleSuggestions?.slice(0, 10) ?? [],
    [styleSuggestions],
  );

  const handleButtonClick = useCallback(() => {
    fetchEffectedLockersCount();
    setEffectedLockersCountRequestSent(true);
  }, [fetchEffectedLockersCount]);

  const handleSkuChange = useCallback((input: string) => {
    setStyleSearchInput(input);
  }, []);

  const fetchSuggestions = useCallback((input: string) => {
    handleSkuChange(input);
    if (input?.length < minInputLengthForSkuSuggestions) { return null; }

    setFetchSuggestionsCallback(true);
  }, [handleSkuChange]);

  const handleStyleChange = useCallback((style: StylesSearchViewModel) => {
    setSelectedStyle(style);
    setValue('code', style.code);
    setValue('id', style.id);
  }, [setSelectedStyle, setValue]);

  useEffect(() => {
    if (fetchSuggestionsCallback) {
      fetchStyleSuggestions();
      setFetchSuggestionsCallback(false);
    }
  }, [fetchStyleSuggestions, fetchSuggestionsCallback]);

  useEffect(() => {
    if (
      effectedLockersCount !== undefined
      && effectedLockersCountRequestSent
      && !fetchingEffectedLockersCount
    ) {
      setEffectedLockersCountRequestSent(false);
      bulkStyleCreate();
    }
  }, [bulkStyleCreate, effectedLockersCount, effectedLockersCountRequestSent, fetchingEffectedLockersCount]);

  return (
    <div className='container master-detail'>
      <form
        className='redux-form'
        id={bulkStyleCreationForm}
      >
        <div className='redux-form__column'>
          <div className='redux-form__row'>
            <div className='redux-form__section__medium'>
              <label className='redux-form__label'>
                Add style to all stores
                <span className='required'>*</span>
              </label>
              <AutocompleteInput
                suggestions={suggestions}
                fetchSuggestions={fetchSuggestions}
                displayItem={displayStyle}
                displaySuggestionText={displayStyleSuggestion}
                selectedItem={selectedStyle}
                selectItem={handleStyleChange}
                placeholder={'Parent SKU'}
                hasSearchIcon={true}
              />
              <div>
                {selectedStyle?.name}
              </div>
            </div>
          </div>
          <Button
            text='Add Style'
            disabled={!selectedStyle}
            onClick={handleButtonClick}
            type={'primary'}
          />
        </div>
      </form>
    </div>
  );
});

export default BulkStyleCreationForm;
