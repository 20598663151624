import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import ButtonLink from '@sharedComponents/Navigation/ButtonLink';
import { LinearProgress } from '@material-ui/core';
import { llSchedulingQueueDetailsUrl } from '@constants/clientUrls/clientUrls';
import { getColorClass } from '@util/progressBarHelper';

const SingleQueueGridCard = React.memo(({ queue }) => {
  const percent = queue.filledCapacity / queue.capacity * 100;
  const colorClass = queue && getColorClass(percent);

  return (
    <Grid item={true}>
      <div className='ll-scheduling__title text-uppercase'>
        {queue && (queue.decorationMethod !== null ? queue.decorationMethod : 'no decoration method')} scheduling
      </div>
      <Grid
        container={true}
        direction='row'
        alignItems='center'
      >
        <div className='ll-scheduling__card-hag-emb'>
          <div className='flex align__center justify__space-between'>
            {
              queue?.decorationMethod
                ? (
                  <>
                    <Grid
                      item={true}
                      lg={4}
                      className='flex'
                    >
                      <div className='info__field info__field--non-margin'>
                        <div className='info__label'>Daily capacity</div>
                        <div className='info__value--withProgressBar'>
                          <span className='info__value'>
                            {queue.filledCapacity}/{queue.capacity}
                          </span>
                          <span className='progress-bar--large'>
                            <LinearProgress
                              variant='determinate'
                              value={percent}
                              classes={{ root: `scheduling-progress-bar scheduling-progress-bar--${colorClass} scheduling-progress-bar--grey` }}
                            />
                          </span>
                        </div>
                      </div>
                    </Grid>
                    <Grid
                      item={true}
                      lg={4}
                    >
                      <div className='info__field info__field--non-margin-and-padding'>
                        <div className='info__label'>Awaiting dispatch</div>
                        <div className='info__value'>{queue.awaitingDispatch}</div>
                      </div>
                    </Grid>
                  </>
                )
                : (
                  <Grid
                    item={true}
                    lg={4}
                  >
                    <div className='info__field info__field--non-margin'>
                      <div className='info__label'>Awaiting dispatch</div>
                      <div className='info__value'>{queue.awaitingDispatch}</div>
                    </div>
                  </Grid>
                )
            }
            <Grid
              item={true}
              lg={4}
            >
              <div className='ll-scheduling__bottom-other'>
                <ButtonLink
                  type={'primary'}
                  text={'Open queue'}
                  classes={'w-100'}
                  linkClasses={'w-100'}
                  linkTo={llSchedulingQueueDetailsUrl(queue.id)}
                />
              </div>
            </Grid>
          </div>
        </div>
      </Grid>
    </Grid>
  );
});

SingleQueueGridCard.propTypes = {
  queue: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    filledCapacity: PropTypes.number,
    capacity: PropTypes.number,
    awaitingDispatch: PropTypes.number.isRequired,
    decorationMethod: PropTypes.string,
  }).isRequired,
};
export default SingleQueueGridCard;
