/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Product Catalog API
 * OpenAPI spec version: v1
 */

export interface SyncEventsCommand {
  syncBrands?: boolean;
  brandIds?: number[] | null;
  syncParentCategories?: boolean;
  parentCategoryIds?: number[] | null;
  syncCategories?: boolean;
  categoryIds?: number[] | null;
  syncSports?: boolean;
  sportIds?: number[] | null;
  syncColors?: boolean;
  colorIds?: number[] | null;
  syncVendors?: boolean;
  vendorCodes?: string[] | null;
  syncStyles?: boolean;
  syncStyleSports?: boolean;
  styleIds?: number[] | null;
  syncColoredStyles?: boolean;
  coloredStyleIds?: number[] | null;
  syncProducts?: boolean;
  syncColoredStyleProducts?: boolean;
  skus?: string[] | null;
}
