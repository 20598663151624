/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Orders Homefield API
 * OpenAPI spec version: v1
 */
import type { Customer } from './customer';

export interface StripeChargeInfoDto {
  chargeId?: string | null;
  description?: string | null;
  url?: string | null;
  customer?: Customer;
}
