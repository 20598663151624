/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Accounts Homefield API
 * OpenAPI spec version: 1.0
 */
import type { BulkCreateJobStatusEnum } from './bulkCreateJobStatusEnum';
import type { StagingOrganizationErrorDetailsDto } from './stagingOrganizationErrorDetailsDto';

export interface StagingJobErrorDetailsDto {
  sourceFileUrl?: string | null;
  status: BulkCreateJobStatusEnum;
  stagingOrganizations?: StagingOrganizationErrorDetailsDto[] | null;
}
