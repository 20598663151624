/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Accounts Homefield API
 * OpenAPI spec version: 1.0
 */
import type { BulkCreateJobStatusEnum } from './bulkCreateJobStatusEnum';
import type { IdentityUserDto } from './identityUserDto';

export interface StagingJobOutput {
  id: number;
  sourceFileUrl?: string | null;
  created: string;
  status: BulkCreateJobStatusEnum;
  user?: IdentityUserDto;
  organizationCount: number;
  storeCount: number;
  hasErrors: boolean;
}
