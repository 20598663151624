/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */
import type { DecorationFlagTypeEnum } from './decorationFlagTypeEnum';

export interface DecorationFlagDto {
  userId?: string | null;
  reason?: string | null;
  note?: string | null;
  attachmentUrl?: string | null;
  flagType?: DecorationFlagTypeEnum;
  isUnflagged?: boolean | null;
}
