/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */

export type VendorOrderStatusEnum = 'Initial' | 'SubmitReady' | 'Received' | 'InProduction' | 'Shipped' | 'Cancelled';


export const VendorOrderStatusEnum = {
  Initial: 'Initial' as VendorOrderStatusEnum,
  SubmitReady: 'SubmitReady' as VendorOrderStatusEnum,
  Received: 'Received' as VendorOrderStatusEnum,
  InProduction: 'InProduction' as VendorOrderStatusEnum,
  Shipped: 'Shipped' as VendorOrderStatusEnum,
  Cancelled: 'Cancelled' as VendorOrderStatusEnum,
};
