/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */

export type DecorationLocationEnum = 'Undefined' | 'BG' | 'BN' | 'FF' | 'HD' | 'LC' | 'LL' | 'RC' | 'RL' | 'CC' | 'SL' | 'VS' | 'FB' | 'SS_FF' | 'BL_LC' | 'BL_FF' | 'LB' | 'BGSM' | 'UPBCK' | 'LWRBCK' | 'UF' | 'RSLEEVE' | 'LSLEEVE' | 'SPINEBCK' | 'SPINEFRONT' | 'LRIB' | 'RRIB' | 'BCKNECK' | 'MIDBCK' | 'FRONT' | 'FRONTHD' | 'BCKHD' | 'RHIP' | 'TOP' | 'BOTTOM' | 'SIDE' | 'POCKET' | 'SLEEVE' | 'LHIP' | 'MIDHD' | 'MIDFRONT' | 'DOWN_RSLEEVE' | 'DOWN_LSLEEVE' | 'DOWN_RLEG' | 'DOWN_LLEG' | 'MIDHD_LEFT' | 'MIDHD_RIGHT' | 'FB_REV' | 'FF_REV' | 'LC_REV' | 'UPBCK_REV' | 'LWRBCK_REV' | 'UF_REV' | 'MIDBCK_REV' | 'SPINEBCK_REV' | 'SPINEFRONT_REV' | 'MIDFRONT_REV' | 'LL_REV' | 'RL_REV' | 'LRIB_REV' | 'RLABOVEP' | 'RLBELOWL';


export const DecorationLocationEnum = {
  Undefined: 'Undefined' as DecorationLocationEnum,
  BG: 'BG' as DecorationLocationEnum,
  BN: 'BN' as DecorationLocationEnum,
  FF: 'FF' as DecorationLocationEnum,
  HD: 'HD' as DecorationLocationEnum,
  LC: 'LC' as DecorationLocationEnum,
  LL: 'LL' as DecorationLocationEnum,
  RC: 'RC' as DecorationLocationEnum,
  RL: 'RL' as DecorationLocationEnum,
  CC: 'CC' as DecorationLocationEnum,
  SL: 'SL' as DecorationLocationEnum,
  VS: 'VS' as DecorationLocationEnum,
  FB: 'FB' as DecorationLocationEnum,
  SS_FF: 'SS_FF' as DecorationLocationEnum,
  BL_LC: 'BL_LC' as DecorationLocationEnum,
  BL_FF: 'BL_FF' as DecorationLocationEnum,
  LB: 'LB' as DecorationLocationEnum,
  BGSM: 'BGSM' as DecorationLocationEnum,
  UPBCK: 'UPBCK' as DecorationLocationEnum,
  LWRBCK: 'LWRBCK' as DecorationLocationEnum,
  UF: 'UF' as DecorationLocationEnum,
  RSLEEVE: 'RSLEEVE' as DecorationLocationEnum,
  LSLEEVE: 'LSLEEVE' as DecorationLocationEnum,
  SPINEBCK: 'SPINEBCK' as DecorationLocationEnum,
  SPINEFRONT: 'SPINEFRONT' as DecorationLocationEnum,
  LRIB: 'LRIB' as DecorationLocationEnum,
  RRIB: 'RRIB' as DecorationLocationEnum,
  BCKNECK: 'BCKNECK' as DecorationLocationEnum,
  MIDBCK: 'MIDBCK' as DecorationLocationEnum,
  FRONT: 'FRONT' as DecorationLocationEnum,
  FRONTHD: 'FRONTHD' as DecorationLocationEnum,
  BCKHD: 'BCKHD' as DecorationLocationEnum,
  RHIP: 'RHIP' as DecorationLocationEnum,
  TOP: 'TOP' as DecorationLocationEnum,
  BOTTOM: 'BOTTOM' as DecorationLocationEnum,
  SIDE: 'SIDE' as DecorationLocationEnum,
  POCKET: 'POCKET' as DecorationLocationEnum,
  SLEEVE: 'SLEEVE' as DecorationLocationEnum,
  LHIP: 'LHIP' as DecorationLocationEnum,
  MIDHD: 'MIDHD' as DecorationLocationEnum,
  MIDFRONT: 'MIDFRONT' as DecorationLocationEnum,
  DOWN_RSLEEVE: 'DOWN_RSLEEVE' as DecorationLocationEnum,
  DOWN_LSLEEVE: 'DOWN_LSLEEVE' as DecorationLocationEnum,
  DOWN_RLEG: 'DOWN_RLEG' as DecorationLocationEnum,
  DOWN_LLEG: 'DOWN_LLEG' as DecorationLocationEnum,
  MIDHD_LEFT: 'MIDHD_LEFT' as DecorationLocationEnum,
  MIDHD_RIGHT: 'MIDHD_RIGHT' as DecorationLocationEnum,
  FB_REV: 'FB_REV' as DecorationLocationEnum,
  FF_REV: 'FF_REV' as DecorationLocationEnum,
  LC_REV: 'LC_REV' as DecorationLocationEnum,
  UPBCK_REV: 'UPBCK_REV' as DecorationLocationEnum,
  LWRBCK_REV: 'LWRBCK_REV' as DecorationLocationEnum,
  UF_REV: 'UF_REV' as DecorationLocationEnum,
  MIDBCK_REV: 'MIDBCK_REV' as DecorationLocationEnum,
  SPINEBCK_REV: 'SPINEBCK_REV' as DecorationLocationEnum,
  SPINEFRONT_REV: 'SPINEFRONT_REV' as DecorationLocationEnum,
  MIDFRONT_REV: 'MIDFRONT_REV' as DecorationLocationEnum,
  LL_REV: 'LL_REV' as DecorationLocationEnum,
  RL_REV: 'RL_REV' as DecorationLocationEnum,
  LRIB_REV: 'LRIB_REV' as DecorationLocationEnum,
  RLABOVEP: 'RLABOVEP' as DecorationLocationEnum,
  RLBELOWL: 'RLBELOWL' as DecorationLocationEnum,
};
