/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */
import type { OrderPaymentGiftCardDto } from './orderPaymentGiftCardDto';
import type { PaymentTypeEnum } from './paymentTypeEnum';

export interface OrderPaymentHistory {
  lastUpdatedAt?: string | null;
  totalAmount?: number | null;
  shippingChargeAmount?: number | null;
  subtotal?: number | null;
  stripeChargeId?: string | null;
  giftCards?: OrderPaymentGiftCardDto[] | null;
  customerDepositId?: number | null;
  type?: PaymentTypeEnum;
}
