/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Orders Homefield API
 * OpenAPI spec version: v1
 */
import type { CouponTypeEnum } from './couponTypeEnum';

export interface ApplyCouponCommand {
  orderId?: number;
  couponCode?: string | null;
  couponType?: CouponTypeEnum;
  couponValue?: number;
  couponAppliesToShipping?: boolean;
}
