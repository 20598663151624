import React, { PureComponent } from 'react';
import { Helmet } from 'react-helmet';
import {
  generateNewTrainingSet,
  startTraining,
} from '@APICalls/support/actions';
import { materialSwal } from '@util/componentHelper';
import DateInput from '@sharedComponents/Inputs/DateInput';
import Modal from '@sharedComponents/Modal/Modal';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import Button from '@sharedComponents/Buttons/Button';

class ShippingPredictionManager extends PureComponent {
  state = {
    datasetModalIsOpen: false,
    startDate: null,
    pageTitle: 'Shipping Prediction',
  };

  openGenerateDatasetModal = () => {
    this.setState({ datasetModalIsOpen: true });
  };

  closeGenerateDatasetModal = () => {
    this.setState({
      datasetModalIsOpen: false,
      startDate: null,
    });
  };

  handleDateChange = (date) => {
    this.setState({ startDate: date });
  };

  generateDataset = async () => {
    const { startDate } = this.state;
    const res = await generateNewTrainingSet(startDate);
    if (res) {
      materialSwal('Success', 'Successfully generated a new training set', 'success');
    }
  };

  initiateTraining = async () => {
    const res = await startTraining();
    if (res) {
      materialSwal('Success', 'Successfully initiated training', 'success');
    }
  };

  render() {
    const {
      datasetModalIsOpen,
      startDate,
      pageTitle,
    } = this.state;

    return (
      <div className='sheet'>
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
        <Modal
          isOpen={datasetModalIsOpen}
          closeModal={this.closeGenerateDatasetModal}
          modalSize={'m'}
          title={'Generate Dataset'}
          buttons={(
            <ModalButtons
              onClose={this.closeGenerateDatasetModal}
              onConfirm={this.generateDataset}
              confirmBtnText={'Generate'}
              confirmBtnDisabled={startDate === null}
            />
          )}
        >
          <div className='text-field w-100 redux-form__date-picker'>
            <DateInput
              placeholder='Start date of dataset'
              value={startDate}
              onChange={this.handleDateChange}
              onError={this.handleDateChange}
            />
          </div>
        </Modal>
        <div className='sheet__list'>
          <div className='shipping-prediction'>
            <div className='lockerManagerEdit__details-header--info'>
              {pageTitle}
            </div>
            <div className='shipping-prediction__actions'>
              <div className='shipping-prediction__action'>
                Generate a new dataset:
                <Button
                  type={'primary'}
                  text={'Start'}
                  onClick={this.openGenerateDatasetModal}
                />
              </div>
              <div className='shipping-prediction__action'>
                Initiate training:
                <Button
                  type={'primary'}
                  text={'Start'}
                  onClick={this.initiateTraining}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ShippingPredictionManager;
