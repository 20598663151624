/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Financials Homefield API
 * OpenAPI spec version: v1
 */
import type { VoucherNotificationLinkDestinationEnum } from './voucherNotificationLinkDestinationEnum';
import type { VoucherNotificationSenderNameEnum } from './voucherNotificationSenderNameEnum';

export interface NotificationSettingsDto {
  sendDate?: string;
  linkDestination?: VoucherNotificationLinkDestinationEnum;
  senderName?: VoucherNotificationSenderNameEnum;
  customSenderName?: string | null;
}
