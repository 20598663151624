import React, { useCallback } from 'react';
import { Controller } from 'react-hook-form';
import FormError from './FormError';
import { TextField } from '@material-ui/core';

const HookformInput = ({ id, name, control, placeholder, error, required, type, className = '', autoFocus = false, label = '' }) => {
  const renderForInput = useCallback(({ field: { ref, value, ...rest } }) => (
    <div>
      <TextField
        style={{ width: '100%' }}
        id={id}
        label={label}
        placeholder={placeholder}
        ref={ref}
        required={required}
        autoFocus={autoFocus}
        type={type}
        value={value ?? ''}
        className={`${className} ${error ? 'error' : ''}`}
        {...rest}
        variant='standard'
      />
      {error
        && (
          <FormError
            error={error}
            fieldError={true}
          />
        )}
    </div>
  ), [autoFocus, className, error, id, label, placeholder, required, type]);

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: required ? 'This field is required' : false,
        valueAsNumber: type === 'number',
      }}
      render={renderForInput}
    />
  );
};
export default HookformInput;
