/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */
import type { DecorationMethodEnum } from './decorationMethodEnum';
import type { OrderInventoryStatusEnumStatusModel } from './orderInventoryStatusEnumStatusModel';
import type { OrderStatusEnumStatusModel } from './orderStatusEnumStatusModel';

export interface OrderPagedListDto {
  orderId?: number;
  orderNumber?: number;
  shipDate?: string;
  decorationMethod?: DecorationMethodEnum;
  inventoryStatus?: OrderInventoryStatusEnumStatusModel;
  status?: OrderStatusEnumStatusModel;
  gpDocumentId?: string | null;
  qtyAvailable?: number;
  qtyTotal?: number;
  lockerId?: number | null;
  readonly lockerNumber?: number | null;
  organizationId?: number | null;
  gpCustomerId?: string | null;
  hasRequiredItems?: boolean;
}
