/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */
import type { OrderStatusEnum } from './orderStatusEnum';
import type { OrderInventoryStatusEnum } from './orderInventoryStatusEnum';

export interface BatchWarehouseOrderDto {
  orderId?: number;
  orderNumber?: number;
  salesOrderId?: number | null;
  salesOrderNumber?: string | null;
  shipDate?: string;
  datePlaced?: string;
  orderStatus?: OrderStatusEnum;
  inventoryStatus?: OrderInventoryStatusEnum;
  organizationId?: number | null;
  lockerId?: number | null;
  readyItemCount?: number;
  notReadyItemCount?: number;
  postDispatchItemCount?: number;
  allocatedItemCount?: number;
  totalItemCount?: number;
  hagDecorationCount?: number;
  embDecorationCount?: number;
  nonDecorationItemCount?: number;
  partnerName?: string | null;
  customerName?: string | null;
}
