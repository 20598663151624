/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */
import type { DecorationLocationEnum } from './decorationLocationEnum';
import type { DecorationMethodEnum } from './decorationMethodEnum';
import type { AccountingActionTypeEnum } from './accountingActionTypeEnum';

export interface AddOrderItemCommand {
  orderNumber?: number;
  lockerId?: number | null;
  sku?: string | null;
  logoId?: number | null;
  quantity?: number;
  decorationLocation?: DecorationLocationEnum;
  decorationMethod?: DecorationMethodEnum;
  actionType?: AccountingActionTypeEnum;
  chargeAmount?: number;
}
