/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */

export type BackordersSortByEnum = 'ShipDate' | 'OrderNumber' | 'LockerNumber';


export const BackordersSortByEnum = {
  ShipDate: 'ShipDate' as BackordersSortByEnum,
  OrderNumber: 'OrderNumber' as BackordersSortByEnum,
  LockerNumber: 'LockerNumber' as BackordersSortByEnum,
};
