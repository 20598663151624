/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Orders Homefield API
 * OpenAPI spec version: v1
 */
import type { OrderItemPricingDetails } from './orderItemPricingDetails';
import type { Personalization } from './personalization';

export interface Item {
  id?: number;
  uuid?: string | null;
  accountingSequenceNumber?: number;
  purchaseOrderAccountingId?: string | null;
  purchaseOrderItemAccountingId?: number;
  rosterMemberId?: number | null;
  collectionId?: number | null;
  variationId?: number;
  salesChannelId?: number;
  sku?: string | null;
  voucherCode?: string | null;
  voucherId?: number | null;
  pricing?: OrderItemPricingDetails;
  quantity?: number;
  quantityShipped?: number;
  quantityCanceled?: number;
  personalizations?: Personalization[] | null;
}
