import React, {
  PureComponent,
  Fragment,
} from 'react';
import { dropdownSizeM } from '@constants/values';
import { Option } from '@models/common/Option';
import OutsideClickWrapper from '@sharedComponents/OutsideClickWrapper';
import DropdownListItem from './DropdownListItem';
import DropdownGroupNameItem from './DropdownGroupNameItem';
import { groupBy } from '@util/arrayHelper';

interface OwnProps<T extends string | number | boolean | null = string | number | boolean> {
  defaultValue?: T;
  onChange: (value: T) => void;
  readonly options: readonly Option<T>[];
  size?: string;
  classes: string;
  dataTest?: string;
  optionsClasses?: string;
  listItemClasses?: string;
  fixed?: boolean;
  group?: boolean;
}

type Props<T extends string | number | boolean | null = string | number | boolean> = OwnProps<T>;

interface State<T extends string | number | boolean | null = string | number | boolean> {
  selectedValue?: T;
  dropIsActive: boolean;
  throttledCall: Nullable<ReturnType<typeof setTimeout>>;
}

class DropdownList<T extends string | number | boolean | null = string | number | boolean>
  extends PureComponent<Props<T>, State<T>> {
  state: State<T> = {
    selectedValue: this.props.defaultValue,
    dropIsActive: false,
    throttledCall: null,
  };

  componentDidUpdate(prevProps: Props<T>): void {
    const { defaultValue } = this.props;

    if (prevProps.defaultValue !== defaultValue) {
      this.setState({ selectedValue: defaultValue });
    }
  }

  itemOnClick = (value: T): void => {
    const { onChange } = this.props;

    this.setState(() => ({
      selectedValue: value,
    }), () => {
      onChange(value);
      this.closeDrop();
    });
  };

  dropChange = (): void => {
    this.setState((prevState) => ({
      dropIsActive: !prevState.dropIsActive,
    }));
  };

  closeDrop = (): void => {
    this.setState(() => ({ dropIsActive: false }));
  };

  render(): JSX.Element | null {
    const {
      options,
      size,
      classes,
      dataTest,
      optionsClasses,
      listItemClasses,
      fixed,
      group,
    } = this.props;

    const {
      dropIsActive,
      selectedValue,
    } = this.state;

    if (!options || options.length === 0) {
      return null;
    }

    const selectedOption = selectedValue || typeof selectedValue === 'boolean'
      ? options.find((option) => option.value === selectedValue)
      : options[0];

    const nameText = selectedOption ? selectedOption.name : '';
    const groupOption = groupBy(options, 'groupName');

    return (
      <OutsideClickWrapper onClick={this.closeDrop}>
        <div
          style={{ minWidth: size ?? dropdownSizeM }}
          className={`custom-dropdown ${classes} ${fixed ? 'position-fixed' : ''}`}
          data-test={dataTest}
        >
          <div
            style={{ minWidth: size ?? dropdownSizeM }}
            onClick={this.dropChange}
            className='custom-dropdown__select'
          >
            {nameText}
            <div className='custom-dropdown__arrow' />
          </div>
          <div
            style={{ minWidth: size ?? dropdownSizeM }}
            className={`custom-dropdown__options ${optionsClasses} ${dropIsActive ? 'is-active' : ''}`}
          >
            <ul className='custom-dropdown__options--list custom-scrollbar'>
              {
                group
                  ? Object.keys(groupOption).map((key) => (
                    <Fragment key={key}>
                      <DropdownGroupNameItem name={key} />
                      {
                        groupOption[key].map((item) => (
                          <DropdownListItem<T>
                            key={item.key}
                            value={item.value}
                            name={item.name}
                            disabled={item.disabled}
                            onClick={this.itemOnClick}
                            listItemClasses={listItemClasses}
                          />
                        ))
                      }
                    </Fragment>
                  ))
                  : options.map((item) => (
                    <DropdownListItem<T>
                      key={item.key}
                      value={item.value}
                      name={item.name}
                      disabled={item.disabled}
                      onClick={this.itemOnClick}
                      listItemClasses={listItemClasses}
                    />
                  ))
              }
            </ul>
          </div>
        </div>
      </OutsideClickWrapper>
    );
  }
}

export default DropdownList;
