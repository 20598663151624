import React, { forwardRef, useCallback, useImperativeHandle, useRef, useState } from 'react';
import ArtworkColors from './ArtworkColors';
import { Box, Button, Grid, makeStyles } from '@material-ui/core';
import ApprovedColors from './ApprovedColors';
import DecorationMethods from './DecorationMethods';
import LogoImage from './LogoImage';

const useStyles = makeStyles(() => ({
  mainContainer: {
    position: 'relative',
    width: '100%',
    margin: 16,
  },
  uploadLogoWrapper: {
    width: '100%',
  },
  textField: {
    minWidth: 'initial !important',
  },
  approvedColors: { marginBottom: '165px' },
  actionButton: { margin: '0px 10px 0px 0px' },
  logoApproval: {
    margin: '20px 0px',
    padding: '20px',
    backgroundColor: '#ffffff',
  },
  logoApprovalEven: { backgroundColor: '#e2e2e2' },
  logoApprovalOdd: { backgroundColor: '#f1e7dd' },
  copiedIndex: { color: 'darkred' },
}));

const LogoApproval = forwardRef(({ logo, colors, artworkColors, allDarkColors, allLightColors, hasCopiedConfiguration = false, index, handleStorageUpdate, copiedIndex }, ref) => {
  const classes = useStyles();
  const decoMethodRef = useRef();
  const approvedColorRef = useRef();
  const artworkColorsRef = useRef();

  const [hoveredColor, setHoveredColor] = useState('');

  const handleHoveredColor = useCallback((color) => {
    setHoveredColor(color);
  }, []);

  const getData = () => {
    const decoData = decoMethodRef.current?.getData();
    const approvedColorsData = approvedColorRef.current?.getData();
    const artworkColorsData = artworkColorsRef.current?.getData();

    return {
      id: logo.id,
      decoData,
      approvedColorsData,
      artworkColorsData,
    };
  };

  const setSessionData = () => {
    const decoData = decoMethodRef.current?.getData();
    const approvedColorsData = approvedColorRef.current?.getData();
    const artworkColorsData = artworkColorsRef.current?.getData();

    sessionStorage.setItem('logoApproval_decoData', JSON.stringify(decoData));
    sessionStorage.setItem('logoApproval_approvedColorsData', JSON.stringify(approvedColorsData));
    sessionStorage.setItem('logoApproval_artworkColorsData', JSON.stringify(artworkColorsData));
  };

  const handleSetAsConfiguration = useCallback(() => {
    handleStorageUpdate(index);
    setSessionData();
  }, [handleStorageUpdate, index]);

  useImperativeHandle(ref, () => ({
    getData,
  }));

  const handleApply = useCallback(() => {
    const decoData = JSON.parse(sessionStorage.getItem('logoApproval_decoData'));
    const approvedColorsData = JSON.parse(sessionStorage.getItem('logoApproval_approvedColorsData'));
    const artworkColorsData = JSON.parse(sessionStorage.getItem('logoApproval_artworkColorsData'));

    decoMethodRef.current?.setData(decoData);
    approvedColorRef.current?.setData(approvedColorsData);
    artworkColorsRef.current?.setData(artworkColorsData);
  }, []);

  const handleApplyToAll = useCallback(() => {
    const doThis = confirm('Are you sure you want to apply this configuration to all logos?');
    if (!doThis) {
      return;
    }

    setSessionData();
    document.querySelectorAll('.hdn-btn-handle-apply').forEach((btn) => {
      btn.click();
    });
  }, []);

  return (
    <div className={`${classes.logoApproval} ${index % 2 === 0 ? classes.logoApprovalEven : classes.logoApprovalOdd}`}>
      <i>{logo?.image}</i>

      <Grid container={true}>
        <Grid xs={3} item={true}>
          <LogoImage logo={logo} hoveredColor={hoveredColor} />
        </Grid>
        <Grid xs={3} item={true}>
          <ArtworkColors
            colors={colors}
            artworkColors={artworkColors}
            logo={logo}
            ref={artworkColorsRef}
          />
        </Grid>
        <Grid xs={5} item={true}>
          <Box className={classes.approvedColors}>
            <ApprovedColors ref={approvedColorRef} allDarkColors={allDarkColors} allLightColors={allLightColors} logo={logo} setHoveredColor={handleHoveredColor} />
          </Box>
          <DecorationMethods ref={decoMethodRef} />
        </Grid>
        <Grid xs={12} item={true} style={{ marginTop: '10px' }}>
          <Button variant='contained' color='primary' className={classes.actionButton} onClick={handleSetAsConfiguration}>Copy Configuration</Button>
          <Button variant='contained' disabled={!hasCopiedConfiguration} color='primary' className={classes.actionButton} onClick={handleApply}>Paste Configuration</Button>
          <Button variant='contained' color='primary' className={classes.actionButton} onClick={handleApplyToAll}>Apply this configuration to ALL logos</Button>
          <Button variant='contained' style={{ visibility: 'hidden' }} color='primary' className={`hdn-btn-handle-apply ${classes.actionButton}`} onClick={handleApply}>
            Hidden Button To Apply All
          </Button>
          {copiedIndex !== null && copiedIndex === index && <span className={classes.copiedIndex}>Configuration Copied</span>}
        </Grid>
      </Grid>
    </div>
  );
});

export default LogoApproval;
