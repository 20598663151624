/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Orders Homefield API
 * OpenAPI spec version: v1
 */
import type { ShippingInfo } from './shippingInfo';
import type { CouponTypeEnum } from './couponTypeEnum';
import type { QueriesItem } from './queriesItem';

export interface CalculateOrderPricingDetailsQuery {
  organizationId?: number | null;
  salesChannelId?: number | null;
  prdOrder?: boolean;
  shippingInfo?: ShippingInfo;
  couponCode?: string | null;
  couponType?: CouponTypeEnum;
  couponValue?: number;
  couponAppliesToShipping?: boolean;
  shippingBasePrice?: number;
  items?: QueriesItem[] | null;
}
