/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */

export type ArtworkTaskPreviewStatusEnum = 'Undefined' | 'Processing' | 'Failed' | 'Done';


export const ArtworkTaskPreviewStatusEnum = {
  Undefined: 'Undefined' as ArtworkTaskPreviewStatusEnum,
  Processing: 'Processing' as ArtworkTaskPreviewStatusEnum,
  Failed: 'Failed' as ArtworkTaskPreviewStatusEnum,
  Done: 'Done' as ArtworkTaskPreviewStatusEnum,
};
