/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Accounts Homefield API
 * OpenAPI spec version: 1.0
 */
import type { UploadResult } from './uploadResult';

export interface UploadResultApiResponse {
  success: boolean;
  message?: string | null;
  result?: UploadResult;
}
