/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */

export type PrintQueueFilterEnum = 'PrintReady' | 'Printing' | 'Done';


export const PrintQueueFilterEnum = {
  PrintReady: 'PrintReady' as PrintQueueFilterEnum,
  Printing: 'Printing' as PrintQueueFilterEnum,
  Done: 'Done' as PrintQueueFilterEnum,
};
