import { sampleImageColorsEnum } from '@constants/enums/commonEnums';
import { decorationTypeEnum } from '@constants/enums/decorationEnums';

export const mapStyleErrors = (error, validationErrors) => ({
  _error: error.message,
  brand: {
    id: [validationErrors.BrandId],
  },
  category: {
    id: [validationErrors.CategoryId],
  },
  age: validationErrors.Age,
  availableForNewStores: validationErrors.AvailableForNewStores,
  classId: validationErrors.ClassId,
  code: validationErrors.Code,
  decorationMethod: validationErrors.DecorationMethod,
  description: validationErrors.Description,
  discount: validationErrors.Discount,
  gender: validationErrors.Gender,
  name: validationErrors.Name,
  recommended: validationErrors.Recommended,
  qtyVendorMinimum: validationErrors.QtyVendorMinimum,
  disabled: validationErrors.Disabled,
  sizeChartUrl: validationErrors.SizeChartUrl,
  customizable: validationErrors.Customizable,
  taxCode: validationErrors.TaxCode,
});

export const mapColoredStyleErrors = (error, validationErrors) => ({
  _error: error.message,
  code: validationErrors.Code,
  colorGroup: validationErrors.ColorGroup,
  colorA: validationErrors.ColorA,
  colorB: validationErrors.ColorB,
  floodColorId: validationErrors.FloodColorId,
  disabled: validationErrors.Disabled,
  personalizationColors: validationErrors.PersonalizationColors,
  qtyVendorMinimum: validationErrors.QtyVendorMinimum,
});

export const mapProductErrors = (error, validationErrors) => ({
  _error: error.message,
  sku: validationErrors.Sku,
  size: validationErrors.Size,
  vendorId: validationErrors.VendorId,
  description: validationErrors.Description,
  basePrice: validationErrors.BasePrice,
  standardCost: validationErrors.StandardCost,
  currentCost: validationErrors.CurrentCost,
  weight: validationErrors.Weight,
  decorationSize: validationErrors.DecorationSize,
  discontinued: validationErrors.Discontinued,
  disabled: validationErrors.Disabled,
  qtyVendorMinimum: validationErrors.QtyVendorMinimum,
});

export const mapLayoutErrors = (error, validationErrors) => {
  const layoutErrors = Object.entries(validationErrors)
    .filter((entry) => entry[0].toLowerCase().startsWith('layouts'))
    .map((entry) => entry[1]);

  return {
    _error: error.message,
    layoutsInputList: {
      _error: layoutErrors.flat(),
    },
  };
};

export const getSampleImage = (coloredStyles) => {
  let sampleImage = '';

  if (coloredStyles && coloredStyles.length > 0) {
    const blackColoredStyle = coloredStyles
      .find((cs) => cs.colorGroup.toUpperCase()
        .indexOf(sampleImageColorsEnum.Black) !== -1 && cs.imageUrlFront);

    if (blackColoredStyle) {
      sampleImage = blackColoredStyle.imageUrlFront;
    } else {
      const whiteColoredStyle = coloredStyles
        .find((cs) => cs.colorGroup.toUpperCase()
          .indexOf(sampleImageColorsEnum.White) !== -1 && cs.imageUrlFront);

      if (whiteColoredStyle) {
        sampleImage = whiteColoredStyle.imageUrlFront;
      } else {
        const firstWithImage = coloredStyles.find((cs) => cs.imageUrlFront);
        sampleImage = firstWithImage?.imageUrlFront;
      }
    }
  }

  return sampleImage;
};

export const mapStyleDecorations = (data) => {
  data.logoLocations = [];
  data.personalizationLocations = [];

  data.customOnlyLogos = [];
  data.customOnlyPersonalizations = [];

  data.defaultPersonalizations = [];
  data.defaultLogoLocation = null;

  if (data?.decorationLocations) {
    data.logoLocations = data.decorationLocations
      .filter((x) => x.type === decorationTypeEnum.Logo);

    data.personalizationLocations = data.decorationLocations
      .filter((x) => x.type === decorationTypeEnum.Personalization);

    data.customOnlyLogos = data.decorationLocations
      .filter((x) => x.type === decorationTypeEnum.Logo && x.custom);

    data.customOnlyPersonalizations = data.decorationLocations
      .filter((x) => x.type === decorationTypeEnum.Personalization && x.custom);

    data.defaultPersonalizations = data.decorationLocations
      .filter((x) => x.type === decorationTypeEnum.Personalization && !x.custom);

    const defaultLogoLocation = data.decorationLocations
      .find((x) => x.type === decorationTypeEnum.Logo && !x.custom);

    if (defaultLogoLocation) {
      data.defaultLogoLocation = defaultLogoLocation;
    }
  }

  return data;
};

export const mapStyleDecorationLocations = (
  defaultLogoLocation,
  customOnlyLogos,
  defaultPersonalizations,
  customOnlyPersonalizations
) => {
  const decorationsMapped = [];

  if (defaultLogoLocation) {
    decorationsMapped.push({
      ...defaultLogoLocation,
      custom: false,
    });
  }

  if (customOnlyLogos) {
    for (const logo of customOnlyLogos) {
      decorationsMapped.push({
        ...logo,
        custom: true,
      });
    }
  }

  if (defaultPersonalizations) {
    for (const personalization of defaultPersonalizations) {
      decorationsMapped.push({
        ...personalization,
        custom: false,
      });
    }
  }

  if (customOnlyPersonalizations) {
    for (const personalization of customOnlyPersonalizations) {
      decorationsMapped.push({
        ...personalization,
        custom: true,
      });
    }
  }

  return decorationsMapped;
};

export const mapColoredStyleColors = (coloredStyle) => {
  coloredStyle.personalizationColorIds = [];
  coloredStyle.defaultPersonalizationColor = null;
  coloredStyle.customOnlyPersonalizationColors = [];

  if (coloredStyle.personalizationColors) {
    coloredStyle.personalizationColorIds = coloredStyle.personalizationColors.map((x) => x.floodColorId);

    coloredStyle.customOnlyPersonalizationColors = coloredStyle.personalizationColors
      .filter((x) => x.custom)
      .map((x) => x.floodColorId);

    const defaultPersonalizationColor = coloredStyle.personalizationColors.find((x) => !x.custom);
    if (defaultPersonalizationColor) {
      coloredStyle.defaultPersonalizationColor = defaultPersonalizationColor.floodColorId;
    }
  }

  return coloredStyle;
};
