/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Financials Homefield API
 * OpenAPI spec version: v1
 */

export type SalesChannelTypeEnum = 'Undefined' | 'Temporary' | 'Persistent' | 'AutoLaunched' | 'TemporaryOptIn' | 'PersistentOptIn' | 'Promotional';


export const SalesChannelTypeEnum = {
  Undefined: 'Undefined' as SalesChannelTypeEnum,
  Temporary: 'Temporary' as SalesChannelTypeEnum,
  Persistent: 'Persistent' as SalesChannelTypeEnum,
  AutoLaunched: 'AutoLaunched' as SalesChannelTypeEnum,
  TemporaryOptIn: 'TemporaryOptIn' as SalesChannelTypeEnum,
  PersistentOptIn: 'PersistentOptIn' as SalesChannelTypeEnum,
  Promotional: 'Promotional' as SalesChannelTypeEnum,
};
