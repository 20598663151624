/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */
import type { OrderItemFileJobItem } from './orderItemFileJobItem';

export interface OrderItemFileJobDto {
  id?: number;
  orderId?: number;
  orderNumber?: number;
  jobType?: string | null;
  isCompleted?: boolean;
  shipDate?: string;
  datePlaced?: string;
  jobItems?: OrderItemFileJobItem[] | null;
}
