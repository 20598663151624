import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {
  Field,
  reduxForm,
  FieldArray,
  formValueSelector,
} from 'redux-form';
import PropTypes from 'prop-types';
import { parseRosterFromCSV } from '@APICalls/rosters/actions';
import { keyNameEnum } from '@constants/enums/commonEnums';
import { rostersForm } from '@constants/reduxForms';
import { dropdownSizeXs } from '@constants/values';
import { getRosterStatusOptions } from '@constants/options/options';
import { fetchLockerSuggestions } from '@redux/organizations/actions';
import { validateRoster } from '@redux/rosters/validations';
import Input from '@sharedComponents/Form/Input';
import FormDropdown from '@sharedComponents/Form/FormDropdown';
import FormError from '@sharedComponents/Form/FormError';
import AutocompleteInput from '@sharedComponents/Inputs/AutocompleteInput';
import Icon from '@sharedComponents/Icons/Icon';
import MaterialCheckbox from '@sharedComponents/Inputs/MaterialCheckbox';
import DatePicker from '@sharedComponents/Form/DatePicker';
import RosterCSVUploader from './RosterCSVUploader';

const rosterStatusOptions = getRosterStatusOptions(false, 'Choose Status');

class RosterForm extends PureComponent {
  state = {
    lockersByIdDict: {},
    attachToOneLockerChecked: false,
    assignOneOrderingDeadlineChecked: false,
  };

  onKeyPress = (e) => {
    if (e.key === keyNameEnum.Enter) {
      e.preventDefault();
    }
  };

  displayLocker = (locker) => locker.id ? (`L${locker.id} - ${locker.teamName}`) : '';

  selectLocker = async (propertyName, locker) => {
    const { change } = this.props;

    const { attachToOneLockerChecked } = this.state;

    if (locker) {
      this.cacheLockerDetails(locker);
    }

    await change(propertyName, locker?.id);

    if (attachToOneLockerChecked && propertyName.includes('rosters[0]')) {
      this.attachToOneLockerRostersUpdate(attachToOneLockerChecked);
    }
  };

  cacheLockerDetails = (selectedLocker) => {
    const { lockersByIdDict } = this.state;

    if (!lockersByIdDict[selectedLocker.id]) {
      const updatedLockersByIdDict = {
        ...lockersByIdDict,
        [selectedLocker.id]: selectedLocker,
      };

      this.setState(() => ({ lockersByIdDict: updatedLockersByIdDict }));
    }
  };

  fetchLockerSuggestions = (searchInput) => {
    const { dispatch } = this.props;
    dispatch(fetchLockerSuggestions(searchInput));
  };

  addRoster = async (fields, file) => {
    const {
      attachToOneLockerChecked,
      assignOneOrderingDeadlineChecked,
    } = this.state;

    const res = await parseRosterFromCSV(file);

    if (res?.success && res.result) {
      const rosters = res.result;

      for (const roster of rosters) {
        if (!attachToOneLockerChecked || (fields.length === 0 && attachToOneLockerChecked)) {
          this.cacheLockerDetails({
            id: roster.lockerId,
            teamName: roster.lockerTeamName,
          });
        }

        let attachedLockerId = roster.lockerId;
        let assignedOrderingDeadline = roster.orderingDeadline;

        if (fields.length > 0 && attachToOneLockerChecked) {
          const firstRoster = fields.get(0);
          attachedLockerId = firstRoster.lockerId;
          assignedOrderingDeadline = firstRoster.orderingDeadline;
        }

        fields.push({
          teamName: roster.teamName,
          lockerId: attachedLockerId,
          orderingDeadline: assignedOrderingDeadline,
          disabled: roster.disabled,
          members: roster.members,
        });

        this.attachToOneLockerRostersUpdate(attachToOneLockerChecked);
        this.assignOneOrderingDeadlineRostersUpdate(assignOneOrderingDeadlineChecked);
      }
    }
  };

  removeRoster = (fields, index) => {
    fields.remove(index);
  };

  attachToOneLockerChange = () => {
    const { attachToOneLockerChecked } = this.state;

    this.attachToOneLockerRostersUpdate(!attachToOneLockerChecked);

    this.setState((prevState) => ({
      attachToOneLockerChecked: !prevState.attachToOneLockerChecked,
    }));
  };

  assignOneOrderingDeadlineChange = () => {
    const { assignOneOrderingDeadlineChecked } = this.state;

    this.assignOneOrderingDeadlineRostersUpdate(!assignOneOrderingDeadlineChecked);

    this.setState((prevState) => ({
      assignOneOrderingDeadlineChecked: !prevState.assignOneOrderingDeadlineChecked,
    }));
  };

  attachToOneLockerRostersUpdate = (attachToOneLockerChecked) => {
    const {
      change,
      rostersArray,
    } = this.props;

    if (attachToOneLockerChecked && rostersArray.length > 0) {
      const updatedRosters = rostersArray.map((roster) => ({
        ...roster,
        lockerId: rostersArray[0].lockerId,
      }));

      change('rosters', updatedRosters);
    }
  };

  assignOneOrderingDeadlineRostersUpdate = (assignOneOrderingDeadlineChecked) => {
    const {
      change,
      rostersArray,
    } = this.props;

    if (assignOneOrderingDeadlineChecked && rostersArray.length > 0) {
      const updatedRosters = rostersArray.map((roster) => ({
        ...roster,
        orderingDeadline: rostersArray[0].orderingDeadline,
      }));

      change('rosters', updatedRosters);
    }
  };

  renderRoster = (member, index, fields) => {
    const { lockersByIdDict } = this.state;

    const {
      lockers,
      formErrors,
      disabled = {},
      change,
    } = this.props;

    const {
      attachToOneLockerChecked,
      assignOneOrderingDeadlineChecked,
    } = this.state;

    const rosterTeam = fields.get(index);
    const { lockerId } = rosterTeam;

    const selectedLocker = lockerId && lockersByIdDict[lockerId];

    return (
      <div className='flex w-100 mb-20'>
        <div className='redux-form__column--size-m flex'>
          <div className='redux-form__column--size-m'>
            <Field
              name={`${member}.teamName`}
              placeholder={'Enter Roster Team Name'}
              component={Input}
              type={'text'}
            />
          </div>

          <div className='redux-form__column--size-m ml-20'>
            <div className='select-field'>
              <AutocompleteInput
                suggestions={lockers}
                fetchSuggestions={this.fetchLockerSuggestions}
                displayItem={this.displayLocker}
                displaySuggestionText={this.displayLocker}
                selectedItem={selectedLocker || {}}
                selectItem={this.selectLocker.bind(null, `${member}.lockerId`)}
                placeholder={'Enter locker'}
                error={formErrors[`${member}.lockerId`]}
                disabled={index !== 0 && attachToOneLockerChecked}
                keyValue={selectedLocker ? selectedLocker.id : 0}
                size={'xl'}
                isDropdownFixed={true}
              />
            </div>
          </div>
        </div>

        <div className='redux-form__column--size-m flex ml-30 mr-10'>
          <div className='redux-form__column--size-m'>
            <Field
              name={`${member}.orderingDeadline`}
              placeholder={'Choose a deadline'}
              component={DatePicker}
              minDate={new Date()}
              classes={'rosters__form__ordering-deadline'}
              disabled={disabled.orderingDeadline || (index !== 0 && assignOneOrderingDeadlineChecked)}
            />
          </div>
          <div className='ml-30'>
            <Field
              name={`${member}.disabled`}
              disabled={disabled.disabled}
              component={FormDropdown}
              initialValue={true}
              change={change}
              options={rosterStatusOptions}
              size={dropdownSizeXs}
            />
          </div>
        </div>

        <Icon
          materialIcon={'cancel'}
          onClick={this.removeRoster.bind(null, fields, index)}
          classes={'catalog-details__remove align__center mb-20 ml-10'}
        />
      </div>
    );
  };

  renderRosters = ({
    fields,
    meta: { error },
  }) => (
    <div className='mb-30'>
      <div>
        {(fields.length > 0) && this.renderRostersFieldArrayHeaders()}
        {fields.map(this.renderRoster)}
      </div>

      <div className='mt-20'>
        <FormError
          error={error}
          enableMultiple={true}
        />
      </div>

      <div className='redux-form__row'>
        <RosterCSVUploader addNewRoster={this.addRoster.bind(null, fields)} />
      </div>
    </div>
  );

  renderRostersFieldArrayHeaders = () => (
    <div className='flex w-100'>
      <div className='redux-form__column--size-m flex'>
        <div className='redux-form__column--size-m'>
          <label className='redux-form__label'>
            Roster Name
          </label>
        </div>

        <div className='redux-form__column--size-m ml-40'>
          <label className='redux-form__label'>
            Associated Locker
          </label>
        </div>
      </div>

      <div className='redux-form__column--size-m ml-30 flex'>
        <div className='redux-form__column--size-m ml-10'>
          <label className='redux-form__label'>
            Ordering Deadline
          </label>
        </div>

        <div className='redux-form__column--size-s'>
          <label className='redux-form__label'>
            Status
          </label>
        </div>
      </div>
    </div>
  );

  render() {
    const {
      handleSubmit,
      error,
    } = this.props;

    const {
      attachToOneLockerChecked,
      assignOneOrderingDeadlineChecked,
    } = this.state;

    return (
      <form
        className='redux-form'
        onSubmit={handleSubmit}
        onKeyPress={this.onKeyPress}
        id={rostersForm}
      >
        <div>
          <div className='lockerManager__details-header--info align__left mb-20'>
            <div>
              Attach Member File
            </div>
            <div className='align__center'>
              <MaterialCheckbox
                checked={attachToOneLockerChecked}
                onClick={this.attachToOneLockerChange}
                text={'Attach to one locker'}
              />
              <MaterialCheckbox
                checked={assignOneOrderingDeadlineChecked}
                onClick={this.assignOneOrderingDeadlineChange}
                text={'Assign one ordering deadline'}
              />
            </div>
          </div>

          <div>
            <FieldArray
              name={'rosters'}
              component={this.renderRosters}
              rerenderOnEveryChange={true}
              attachToOneLockerChecked={attachToOneLockerChecked}
              assignOneOrderingDeadlineChecked={assignOneOrderingDeadlineChecked}
            />
          </div>

          <FormError error={error} />
        </div>
      </form>
    );
  }
}

RosterForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  error: PropTypes.string,
  disabled: PropTypes.object,
  change: PropTypes.func,
  lockers: PropTypes.arrayOf(PropTypes.object).isRequired,
  formErrors: PropTypes.object.isRequired,
  rostersArray: PropTypes.arrayOf(PropTypes.object),
};

const selector = formValueSelector(rostersForm);
const mapStateToProps = (state) => ({
  formErrors: (state?.form[rostersForm]?.submitErrors) || {},
  lockers: state.organizations.lockerSuggestions,
  rostersArray: selector(state, 'rosters') || [],
});

export default connect(mapStateToProps)(reduxForm({
  form: rostersForm,
  enableReinitialize: true,
  validate: validateRoster,
})(RosterForm));
