/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */
import type { OrderShippingInfoDto } from './orderShippingInfoDto';
import type { LockerInfoDto } from './lockerInfoDto';

export interface SharedOrderDto {
  id?: number;
  orderNumber?: number;
  datePlaced?: string;
  itemsNumber?: number;
  readonly lockerNumber?: number | null;
  lockerId?: number | null;
  teamName?: string | null;
  organizationId?: number | null;
  organizationName?: string | null;
  gpDocumentId?: string | null;
  isCanceled?: boolean;
  hasRequiredItems?: boolean;
  shippingInfo?: OrderShippingInfoDto;
  shipDate?: string;
  pricePaid?: number;
  shippingPricePaid?: number | null;
  couponCode?: string | null;
  singleLockerSubtotal?: number | null;
  stripePaymentMethod?: string | null;
  isCoreOrder?: boolean;
  lockers?: LockerInfoDto[] | null;
}
