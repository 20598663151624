import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  formatDollarAmount,
  formatPercentageAmount,
} from '@util/numberHelpers';
import Label from '@sharedComponents/Display/Label';
import Tag from '@sharedComponents/Display/Tag';
import Modal from '@sharedComponents/Modal/Modal';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import ExpandablePriceTab from '@sharedComponents/Display/ExpandablePriceTab';

class OrderManagementOrderItemFinancialsModal extends PureComponent {
  onClose = () => {
    const { closeModal } = this.props;

    this.setState({
      itemSummaryTabIsActive: false,
      fundraisingTabIsActive: false,
      personalizationTabIsActive: false,
    });

    closeModal();
  };

  render() {
    const {
      isOpen,
      orderItem,
      product,
      orderItemPricingDetails,
      getPricingFromCore,
    } = this.props;

    if (!orderItem) return null;

    const { styleName, voucherAmount } = orderItem;

    let {
      basePrice,
      styleDiscountAmount,
      lockerDiscountAmount,
      couponDiscountPercent,
      subtotalCouponDiscount,
      fundraisingAmount,
      fundraisingBaseAmount,
      fundraisingCouponDiscount,
      fundraisingPercent,
      personalizationPrice,
      personalizationBasePrice,
      personalizationCouponDiscount,
      refundAmount,
      pricePaid,
      taxableAmount,
      taxAmount,
    } = orderItem;

    if (getPricingFromCore && orderItemPricingDetails) {
      basePrice = orderItemPricingDetails.basePrice;
      styleDiscountAmount = orderItemPricingDetails.styleDiscountAmount;
      lockerDiscountAmount = orderItemPricingDetails.salesChannelDiscountAmount;
      couponDiscountPercent = orderItemPricingDetails.couponDiscountPercent;
      subtotalCouponDiscount = orderItemPricingDetails.subtotalCouponDiscount;
      fundraisingAmount = orderItemPricingDetails.fundraisingAmount;
      fundraisingBaseAmount = orderItemPricingDetails.fundraisingBaseAmount;
      fundraisingCouponDiscount = orderItemPricingDetails.fundraisingCouponDiscount;
      fundraisingPercent = orderItemPricingDetails.salesChannelFundraisingPercent;
      personalizationPrice = orderItemPricingDetails.personalizationsPrice;
      personalizationBasePrice = orderItemPricingDetails.personalizationsBasePrice;
      personalizationCouponDiscount = orderItemPricingDetails.personalizationsCouponDiscount;
      refundAmount = orderItemPricingDetails.refundAmount;
      pricePaid = orderItemPricingDetails.pricePaid;
      taxableAmount = orderItemPricingDetails.taxableAmount;
      taxAmount = orderItemPricingDetails.taxAmount;
    }

    const fundraisingWithoutTax = orderItemPricingDetails?.fundraisingPricePaid
      - orderItemPricingDetails?.fundraisingTax;

    const taxCode = product?.coloredStyle?.style?.taxCode;

    return (
      <Modal
        isOpen={isOpen}
        closeModal={this.onClose}
        modalHeight={'l'}
        modalWidth={'m'}
        isPadded={true}
        modalHeaderClass={'modal__title-with-subtitle'}
        title={(
          <div className='order-financial-details__with-subtitle'>
            <div className='mb-20'>Order Item Financials</div>
            <div className='order-financial-details__subtitle pt-20'>{styleName}</div>
          </div>
        )}
        buttons={(
          <ModalButtons
            onClose={this.onClose}
            displayConfirmBtn={false}
            cancelBtnText={'Close'}
          />
        )}
      >
        <div className='order-financial-details'>
          <ExpandablePriceTab
            label={'Item summary'}
            subLabel={'(before tax)'}
            price={basePrice - styleDiscountAmount - lockerDiscountAmount - subtotalCouponDiscount - voucherAmount}
            content={(
              <div className='order-financial-details__content'>
                <div className='justify__space-between'>
                  <span>Base price</span>
                  <span className='positive-amount'>{formatDollarAmount(basePrice)}</span>
                </div>
                <div className='justify__space-between mt-15'>
                  <span>Style discount</span>
                  <span className='negative-amount'>- {formatDollarAmount(styleDiscountAmount)}</span>
                </div>
                <div className='justify__space-between mt-15'>
                  <span>Locker discount</span>
                  <span className='negative-amount'>- {formatDollarAmount(lockerDiscountAmount)}</span>
                </div>
                <div className='justify__space-between mt-15'>
                  <span>Coupon discount ({formatPercentageAmount(couponDiscountPercent)})</span>
                  <span className='negative-amount'>- {formatDollarAmount(subtotalCouponDiscount)}</span>
                </div>
                <div className='justify__space-between mt-15'>
                  <span>Voucher amount</span>
                  <span className='negative-amount'>- {formatDollarAmount(voucherAmount)}</span>
                </div>
              </div>
            )}
          />

          <ExpandablePriceTab
            label={'Fundraising amount'}
            price={getPricingFromCore
              ? fundraisingWithoutTax
              : fundraisingAmount}
            classes={'mt-20'}
            content={(
              <div className='order-financial-details__content'>
                <div className='justify__space-between'>
                  <span>Base amount ({formatPercentageAmount(fundraisingPercent)})</span>
                  <span className='positive-amount'>{formatDollarAmount(fundraisingBaseAmount)}</span>
                </div>
                <div className='justify__space-between mt-15'>
                  <span>Coupon discount ({formatPercentageAmount(couponDiscountPercent)})</span>
                  <span className='negative-amount'>- {formatDollarAmount(fundraisingCouponDiscount)}</span>
                </div>
              </div>
            )}
          />

          <ExpandablePriceTab
            label={'Personalization amount'}
            price={personalizationPrice}
            classes={'mt-20'}
            content={(
              <div className='order-financial-details__content'>
                <div className='justify__space-between'>
                  <span>Personalization base price</span>
                  <span className='positive-amount'>{formatDollarAmount(personalizationBasePrice)}</span>
                </div>
                <div className='justify__space-between mt-15'>
                  <span>Coupon discount ({formatPercentageAmount(couponDiscountPercent)})</span>
                  <span className='negative-amount'>- {formatDollarAmount(personalizationCouponDiscount)}</span>
                </div>
              </div>
            )}
          />

          <div className='order-financial-details__tab mt-20'>
            <div className='flex'>
              <div className='order-financial-details__tab--text text-uppercase'>Refund Amount</div>
            </div>
            <div className='align__center'>
              <Label
                text={formatDollarAmount(refundAmount)}
                type={'lynch'}
              />
            </div>
          </div>

          <div className='order-financial-details__tab mt-20'>
            <div className='flex'>
              <div className='order-financial-details__tab--text text-uppercase'>Tax</div>
            </div>
            <div className='align__center'>
              <Tag
                label={'Taxable Amount'}
                value={formatDollarAmount(taxableAmount)}
              />
              <Tag
                label={'Code'}
                value={taxCode}
                classes={'ml-5'}
              />
              <Label
                text={formatDollarAmount(taxAmount)}
                type={'lynch'}
              />
            </div>
          </div>
        </div>

        <div className='mt-20'>
          <div className='order-financial-details__total--explanation'>(Item + Fundraising + Personalization + Tax)</div>
          <div className='order-financial-details__total'>
            <span>Price paid</span>
            <span>{formatDollarAmount(pricePaid)}</span>
          </div>
        </div>
      </Modal>
    );
  }
}

OrderManagementOrderItemFinancialsModal.propTypes = {
  orderItem: PropTypes.object,
  product: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  orderItemPricingDetails: PropTypes.object,
  getPricingFromCore: PropTypes.bool,
};

export default OrderManagementOrderItemFinancialsModal;
