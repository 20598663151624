/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */
import type { OrderStatusEnum } from './orderStatusEnum';
import type { OrderInventoryStatusEnum } from './orderInventoryStatusEnum';
import type { DecorationMethodEnum } from './decorationMethodEnum';
import type { QueriesSortByEnum } from './queriesSortByEnum';
import type { SortDirectionEnum } from './sortDirectionEnum';

export type GetHomefieldApiSchedulingOrdersParams = { status?: OrderStatusEnum; pageNumber?: number; pageSize?: number; inventoryStatus?: OrderInventoryStatusEnum; decorationMethod?: DecorationMethodEnum[]; minShipDate?: string; maxShipDate?: string; filter?: string; sortBy?: QueriesSortByEnum; sortDirection?: SortDirectionEnum };
