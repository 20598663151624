import React, { forwardRef, Ref, useCallback, useImperativeHandle, useMemo, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { DataGrid, GridCellParams, GridColDef, GridValueFormatterParams } from '@material-ui/data-grid';
import { TableRefObject } from './BulkBuild';
import { useGetHomefieldApiV1BulkstoresStagingjobs } from '@api/accountsv1/bulk-stores';
import moment from 'moment';
import { BulkCreateJobStatusEnum, IdentityUserDto, StagingJobOutput } from '@api/accountsv1/models';
import { getBulkCreateJobStatusEnumDisplayName } from './helpers';
import MaterialTooltip from '@components/shared/Tooltips/MaterialTooltip';
import { Link } from 'react-router-dom';
import { lighten, makeStyles } from '@material-ui/core';
import { bulkBuildJobDetailsUrl } from '@constants/clientUrls/clientUrls';
import BulkBuildTableMenu from './BulkBuildTableMenu';

const useStyles = makeStyles((theme) => ({
  row: {
    cursor: 'pointer',
  },
  errorButton: {
    color: theme.palette.error.main,
  },
  rowClass: {
    backgroundColor: lighten(theme.palette.primary.dark, 0.95),
    '&.Mui-even': {
      backgroundColor: lighten(theme.palette.primary.dark, 0.92),
    },
  },
  rowClassError: {
    backgroundColor: lighten(theme.palette.error.main, 0.9),
    '&.Mui-even, &.Mui-odd': {
      backgroundColor: lighten(theme.palette.error.main, 0.9),
    },
  },
}));

const BulkBuildJobTable = forwardRef((_, ref: Ref<TableRefObject>) => {
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const classes = useStyles();

  const handleSetPageNumber = useCallback((newPageNumber: number) => {
    setPageNumber(newPageNumber + 1);
  }, []);

  const rowClassName = useCallback((params) => {
    return params.row.hasErrors ? `${classes.rowClass} ${classes.rowClassError}` : classes.rowClass;
  }, [classes]);

  const { data, isLoading, refetch: getStagingJobs } = useGetHomefieldApiV1BulkstoresStagingjobs({
    pageNumber: pageNumber,
    pageSize: pageSize,
  }, {
    query: {
      enabled: true,
      refetchInterval: 5000,
    },
    request: {
      showErrorModal: false,
    },
  });

  const columns: GridColDef[] = useMemo(() => [
    {
      field: 'id',
      headerName: 'Job ID',
      description: 'ID',
      type: 'number',
      align: 'left',
      minWidth: 140,
      headerAlign: 'center',
      sortable: false,
      renderCell: (params: GridCellParams) => {
        const id = params.value as number;

        if ((params.row as StagingJobOutput).storeCount < 1000) {
          return (
            <Link to={bulkBuildJobDetailsUrl(id)}>
              {id}
            </Link>
          );
        } else {
          return id;
        }
      },
    },
    {
      field: 'sourceFileUrl',
      headerName: 'File Name',
      description: 'File Name',
      flex: 1,
      sortable: false,
    },
    {
      field: 'created',
      headerName: 'Uploaded At',
      description: 'Uploaded At',
      flex: 1,
      sortable: false,
      valueFormatter: (params: GridValueFormatterParams) => moment(params.value as string).format('M/D/YYYY h:mm A'),
    },
    {
      field: 'status',
      headerName: 'Status',
      description: 'Status',
      flex: 1,
      sortable: false,
      valueFormatter: (params: GridValueFormatterParams) => {
        const status = params.value as BulkCreateJobStatusEnum;
        const withErrors = params.row.hasErrors as boolean ? ' (with errors)' : '';
        return `${getBulkCreateJobStatusEnumDisplayName(status)} ${withErrors}`;
      },
    },
    {
      field: 'user',
      headerName: 'Uploaded By',
      description: 'Uploaded By',
      flex: 1,
      sortable: false,
      renderCell: (params: GridCellParams) => {
        const user = params.value as IdentityUserDto;
        return user
          ? (
            <MaterialTooltip tooltipText={<div>{user.email ?? ''}</div>}>
              <span>{`${user.firstName} ${user.lastName}`}</span>
            </MaterialTooltip>
          )
          : '-';
      },
    },
    {
      field: 'organizationCount',
      headerName: 'Organization Count',
      description: 'Organization Count',
      flex: 1,
      sortable: false,
    },
    {
      field: 'storeCount',
      headerName: 'Store Count',
      description: 'Store Count',
      flex: 1,
      sortable: false,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      description: 'Actions',
      flex: 1,
      sortable: false,
      align: 'right',
      renderCell: (params: GridCellParams) => {
        const row = params.row as StagingJobOutput;
        return <BulkBuildTableMenu row={row} refetchJobs={getStagingJobs} />;
      },
    },
  ], [getStagingJobs]);

  useImperativeHandle(ref, () => ({
    getStagingJobs,
  }));

  return (
    <Grid container={true} item={true} spacing={2}>
      <Grid item={true} xs={12}>
        <DataGrid
          classes={{ row: classes.row }}
          columns={columns}
          rows={data?.result?.items ?? []}
          rowsPerPageOptions={[10, 50]}
          rowCount={data?.result?.totalCount ?? 0}
          autoHeight={true}
          pageSize={data?.result?.pageSize ?? 25}
          page={data?.result?.pageNumber ? data.result.pageNumber - 1 : 0}
          pagination={true}
          loading={isLoading}
          disableColumnFilter={true}
          disableColumnMenu={true}
          disableSelectionOnClick={true}
          paginationMode='server'
          onPageSizeChange={setPageSize}
          onPageChange={handleSetPageNumber}
          getRowClassName={rowClassName}
        />
      </Grid>
    </Grid>
  );
});

export default BulkBuildJobTable;
