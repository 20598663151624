/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */
import type { OrderPrintStatusEnumStatusModel } from './orderPrintStatusEnumStatusModel';

export interface OrderPrintAssemblyDetailsDto {
  id?: number;
  orderNumber?: number;
  lockerId?: number | null;
  readonly lockerNumber?: number | null;
  lockerTeamName?: string | null;
  organizationId?: number | null;
  organizationName?: string | null;
  shipDate?: string;
  inventoryLocation?: string | null;
  status?: OrderPrintStatusEnumStatusModel;
}
