import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Column } from 'react-table-6';
import { connect, ConnectedProps } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { PrintQueueDto, PriorityQueueDto } from '@api/fulfillment/models';
import { ColorDto } from '@api/productCatalog/models';
import { markPriorityItemInPrint, markSheetStatus, unflagQueueItem } from '@APICalls/printFiles/actions';
import { TableEnum } from '@constants/enums/tableEnums';
import { sortDirectionEnum } from '@constants/enums/commonEnums';
import { defaultPageSizeSmallTable, pageSizeOptionsSmallTable } from '@constants/values';
import { printStatusEnum } from '@constants/enums/printRoomEnums';
import { SortDirectionLong } from '@customTypes/table';
import { RootState } from '@redux/index/reducers';
import * as printFilesActions from '@redux/printFiles/actions';
import { materialSwal } from '@util/componentHelper';
import { parseDateNumeric, parseDateTimeNumeric } from '@util/dateHandler';
import { getPagingParamsFromTable, getSortParamsFromTable } from '@util/tableHelpers';
import HeaderCell from '@sharedComponents/Table/TableCells/HeaderCell';
import Table from '@sharedComponents/Table/Table';
import TableBulkActions from '@sharedComponents/Table/TableComponents/TableBulkActions';
import Button from '@sharedComponents/Buttons/Button';
import AttachedFiles from './PrintRoomTableContent/AttachedFiles';
import ParsedNameFiles from './PrintRoomTableContent/ParsedNameFiles';
import UpdatePrintFileStatus from './PrintRoomTableContent/UpdatePrintFileStatus';
import UpdatePriorityItemStatus from './PrintRoomTableContent/UpdatePriorityItemStatus';
import IdWithIconsCell from './PrintRoomTableContent/IdWithIconsCell';
import LogoOrTextCell from './PrintRoomTableContent/TableCells/LogoOrTextCell';
import ViewPriorityTaskModalButton from './PrintRoomTableContent/ViewPriorityTaskModalButton';
import PrintRoomTableTabs from './PrintRoomTableContent/Tabs/PrintRoomTableTabs';
import DtfPrinterButton from './PrintRoomTableContent/DtfPrinterButton';
import {
  usePostHomefieldApiHagTransmissionssendprepressjobitem as useHagSendPrepressJobItemPOST,
  usePostHomefieldApiHagTransmissionssendprintqueue as useHagSendPrintQueuePOST,
} from '@api/fulfillment/hag';
import DtfPrinterModal from './PrintRoomTableContent/DtfPrinterModal';

const PrintFilesTable = Table<PrintQueueDto | PriorityQueueDto>();

interface OwnProps {
  selectTab: (newSelectedTab: (typeof printStatusEnum)[keyof typeof printStatusEnum]) => void;
  selectedTab: (typeof printStatusEnum)[keyof typeof printStatusEnum];
  claimedByFilter: string;
  expressProductionFilter: Nullable<boolean>;
  openPriorityLogoModal: (imgUrl: string) => void;
  openPriorityDetailsModal: (task: PriorityQueueDto) => void;
  colorsDictionary: Record<string, ColorDto>;
}

const mapStateToProps = ({
  printFiles,
  tableManager,
}: RootState) => ({
  queue: printFiles.currentQueue.queue as PrintQueueDto[] | PriorityQueueDto[],
  totalPages: printFiles.currentQueue.totalPages,
  hasNextPage: printFiles.currentQueue.hasNextPage,
  hasPreviousPage: printFiles.currentQueue.hasPreviousPage,
  totalCount: printFiles.currentQueue.totalCount,
  initialPageNumber: tableManager.printRoom.pageNumber,
  initialPageSize: tableManager.printRoom.pageSize,
  initialSortColumn: tableManager.printRoom.sortColumn,
  initialSortDirection: tableManager.printRoom.sortDirection,
});

const mapDispatchToProps = {
  fetchPrintData: printFilesActions.fetchPrintData,
  getLogoCounts: printFilesActions.getLogoCounts,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = OwnProps & ConnectedProps<typeof connector>;

const PrintRoomTable = React.memo<Props>(({
  selectTab,
  selectedTab,
  claimedByFilter,
  expressProductionFilter,
  queue,
  totalPages,
  totalCount,
  hasNextPage,
  hasPreviousPage,
  fetchPrintData,
  getLogoCounts,
  openPriorityLogoModal,
  openPriorityDetailsModal,
  colorsDictionary,
  initialPageNumber,
  initialPageSize,
  initialSortDirection,
  initialSortColumn,
}) => {
  const [
    selectedItems,
    setSelectedItems,
  ] = useState<PrintQueueDto[] | PriorityQueueDto[]>([]);
  const [
    isPageSelected,
    setIsPageSelected,
  ] = useState<boolean>(false);
  const [
    selectedLogosCount,
    setSelectedLogosCount,
  ] = useState<number>(0);

  const { printRoomEnableDtf } = useFlags();

  const [
    pageNumber,
    setPageNumber,
  ] = useState<number>(initialPageNumber);
  const [
    pageSize,
    setPageSize,
  ] = useState<number>(initialPageSize);
  const [
    sortDirection,
    setSortDirection,
  ] = useState<SortDirectionLong>(initialSortDirection as SortDirectionLong);
  const [
    sortColumn,
    setSortColumn,
  ] = useState<string>(initialSortColumn);

  const resetFilters = useCallback(() => {
    setPageNumber(1);
    setPageSize(defaultPageSizeSmallTable);
    setSortColumn('');
    setSortDirection('');
  }, []);

  const handleSelectTab = useCallback((newSelectedTab: (typeof printStatusEnum)[keyof typeof printStatusEnum]) => {
    if (newSelectedTab !== selectedTab) {
      resetFilters();
    }
    selectTab(newSelectedTab);
  }, [
    selectTab,
    selectedTab,
    resetFilters,
  ]);

  const updateSelection = useCallback((newSelectedItems: PrintQueueDto[], newIsPageSelected: boolean) => {
    setSelectedItems(newSelectedItems);
    setIsPageSelected(newIsPageSelected);

    let newSelectedLogosCount = 0;
    for (const item of newSelectedItems) {
      newSelectedLogosCount += item.logoCount ?? 0;
    }

    setSelectedLogosCount(newSelectedLogosCount);
  }, []);

  const clearSelection = useCallback(() => {
    updateSelection([], false);
  }, [updateSelection]);

  useEffect(() => {
    clearSelection();

    return () => {
      clearSelection();
    };
  }, [clearSelection]);

  const fetchData = useCallback((state: any, instance: any) => {
    const {
      page: newPageNumber,
      pageSize: newPageSize,
    } = getPagingParamsFromTable(instance);

    const {
      sortColumn: newSortColumn,
      sortDirection: newSortDirection,
    } = getSortParamsFromTable(instance, sortDirectionEnum, sortColumn, sortDirection);

    setPageNumber(newPageNumber + 1);
    setPageSize(newPageSize);
    setSortColumn(newSortColumn);
    setSortDirection(newSortDirection);
  }, [
    sortColumn,
    sortDirection,
  ]);

  const refreshData = useCallback(() => {
    if (selectedTab) {
      fetchPrintData(
        selectedTab,
        pageNumber,
        pageSize,
        sortColumn,
        sortDirection,
        claimedByFilter,
        expressProductionFilter as any,
      );
    } else {
      fetchPrintData(printStatusEnum.PrintReady);
    }
  }, [
    fetchPrintData,
    selectedTab,
    pageNumber,
    pageSize,
    sortColumn,
    sortDirection,
    claimedByFilter,
    expressProductionFilter,
  ]);

  useEffect(() => {
    refreshData();
  }, [refreshData]);

  /*
   * Priority items are unique because rather than representing a sheet,
   * they represent a single item that has been sent back to be reprinted.
   * they need to be handled from a different URL, with different params.
   */
  const handleMarkPriorityItemDone = useCallback(async (itemId, isBulk = false) => {
    const res = await unflagQueueItem(itemId);
    if (res?.success && !isBulk) {
      materialSwal('Success', res.message, 'success');
      getLogoCounts();

      return fetchPrintData(selectedTab, pageNumber, pageSize, sortColumn, sortDirection, claimedByFilter);
    }
  }, [
    fetchPrintData,
    getLogoCounts,
    selectedTab,
    pageNumber,
    pageSize,
    sortColumn,
    sortDirection,
    claimedByFilter,
  ]);

  const handleMarkPriorityItemInPrint = useCallback(async (itemId, isBulk = false) => {
    const res = await markPriorityItemInPrint(itemId);
    if (res?.success && !isBulk) {
      getLogoCounts();
      materialSwal('Success', res.message, 'success');

      return fetchPrintData(selectedTab, pageNumber, pageSize, sortColumn, sortDirection, claimedByFilter);
    }
  }, [
    fetchPrintData,
    getLogoCounts,
    selectedTab,
    pageNumber,
    pageSize,
    sortColumn,
    sortDirection,
    claimedByFilter,
  ]);

  const updateStatus = useCallback(async (id: number, nextStatus: string, isBulkUpdate: boolean = false) => {
    const res = await markSheetStatus(nextStatus, id);
    if (res) {
      if (!isBulkUpdate) {
        materialSwal('Success', `${id} has been sent to ${res}`, 'success');

        return fetchPrintData(selectedTab, pageNumber, pageSize, sortColumn, sortDirection, claimedByFilter);
      }
    }
  }, [
    fetchPrintData,
    selectedTab,
    pageNumber,
    pageSize,
    sortColumn,
    sortDirection,
    claimedByFilter,
  ]);

  const handleStatusUpdate = useCallback(async (id: number, nextStatus: string) => {
    await updateStatus(id, nextStatus);
    getLogoCounts();
  }, [
    getLogoCounts,
    updateStatus,
  ]);

  const { mutateAsync: dtfSendPrintQueue } = useHagSendPrintQueuePOST();

  const { mutateAsync: dtfSendSingleJobItem } = useHagSendPrepressJobItemPOST();

  const [
    dftSheetId,
    setDtfSheetId,
  ] = useState<number>(0);
  const openDftSheetModel = useCallback((id: number) => setDtfSheetId(id), [setDtfSheetId]);
  const closeDftSheetModel = useCallback(() => setDtfSheetId(0), [setDtfSheetId]);
  const handleDtfPrintByPrintSheet = useCallback(async (printQueueId: number, printerNumber: number) => {
    const res = await dtfSendPrintQueue({
      data: {
        printQueueId,
        printerNumber,
      },
    });
    if (res?.success) {
      materialSwal('Success', `Print Sheet ${printQueueId} sent to Printer ${printerNumber}. ${res.message}`, 'success');
    } else {
      materialSwal('Failure', `Print Sheet ${printQueueId} failed to send. ${res?.message}`, 'warning');
    }
  }, [dtfSendPrintQueue]);

  const [
    dftPriorityId,
    setDtfPriorityId,
  ] = useState<number>(0);
  const openDftPriorityModel = useCallback((id: number) => setDtfPriorityId(id), [setDtfPriorityId]);
  const closeDftPriorityModel = useCallback(() => setDtfPriorityId(0), [setDtfPriorityId]);
  const handleDtfPrintByPriorityItem = useCallback(async (itemId: number, printerNumber: number) => {
    const res = await dtfSendSingleJobItem({
      data: {
        prepressJobItemId: itemId,
        printerNumber,
      },
    });
    if (res?.success) {
      materialSwal('Success', `Priority Item ${itemId} sent to Printer ${printerNumber}. ${res.message}`, 'success');
    } else {
      materialSwal('Failure', `Priority Item ${itemId} failed to send. ${res?.message}`, 'warning');
    }
  }, [dtfSendSingleJobItem]);

  const getPriorityItemStatus = useCallback((id: number): string | undefined => {
    const priorityItem = (queue as PriorityQueueDto[]).find((item) => item.id === id);
    if (priorityItem?.status) {
      return priorityItem.status.status;
    }
  }, [queue]);

  const handleBulkStatusUpdate = useCallback(async (nextStatus: string) => {
    const statusUpdateCalls = [];
    for (const selectedItem of selectedItems) {
      statusUpdateCalls.push(updateStatus(selectedItem.id!, nextStatus, true));
    }

    let swalMsg;
    if (nextStatus === printStatusEnum.PrintReady) { swalMsg = 'Print Queue'; }
    if (nextStatus === printStatusEnum.Printing) { swalMsg = 'In Print'; }
    if (nextStatus === printStatusEnum.Done) { swalMsg = 'Printed'; }

    await Promise.all(statusUpdateCalls);

    const selectedItemIds: number[] = selectedItems.map((selectedItem) => selectedItem.id!);

    materialSwal('Success', `${selectedItemIds.join(', ')} have been sent to ${swalMsg}`, 'success');
    getLogoCounts();
    fetchPrintData(selectedTab, pageNumber, pageSize, sortColumn, sortDirection, claimedByFilter);
  }, [
    selectedTab,
    selectedItems,
    getLogoCounts,
    fetchPrintData,
    updateStatus,
    pageNumber,
    pageSize,
    sortColumn,
    sortDirection,
    claimedByFilter,
  ]);

  const handleBulkPriorityStatusUpdate = useCallback(async (
    nextSelectedTab: (typeof printStatusEnum)[keyof typeof printStatusEnum],
  ) => {
    const statusUpdateCalls: Promise<any>[] = [];
    const updatedIds: number[] = [];

    for (const selectedItem of selectedItems) {
      if (
        getPriorityItemStatus(selectedItem.id!) === printStatusEnum.PrepressReady
        && nextSelectedTab === printStatusEnum.Printing
      ) {
        statusUpdateCalls.push(handleMarkPriorityItemInPrint(selectedItem.id, true));
        updatedIds.push(selectedItem.id!);
      } else if (
        getPriorityItemStatus(selectedItem.id!) === printStatusEnum.Printing
        && nextSelectedTab === printStatusEnum.Done
      ) {
        statusUpdateCalls.push(handleMarkPriorityItemDone(selectedItem.id, true));
        updatedIds.push(selectedItem.id!);
      }
    }

    await Promise.all(statusUpdateCalls);
    if (updatedIds.length > 0 && nextSelectedTab === printStatusEnum.Printing) {
      materialSwal('Success', `${updatedIds.join(', ')} have been sent to In Print`, 'success');
    } else if (updatedIds.length > 0 && nextSelectedTab === printStatusEnum.Done) {
      materialSwal('Success', `${updatedIds.join(', ')} have been un-flagged`, 'success');
    }

    getLogoCounts();
    fetchPrintData(selectedTab, pageNumber, pageSize, sortColumn, sortDirection, claimedByFilter);
  }, [
    selectedTab,
    selectedItems,
    pageNumber,
    pageSize,
    sortColumn,
    sortDirection,
    claimedByFilter,
    getLogoCounts,
    fetchPrintData,
    getPriorityItemStatus,
    handleMarkPriorityItemInPrint,
    handleMarkPriorityItemDone,
  ]);

  const printReadyColumns: Array<Column<any>> = useMemo(() => [
    {
      Header: <HeaderCell text={'Sheet'} />,
      minWidth: 50,
      sortable: true,
      accessor: '',
      id: 'sheetId',
      Cell: (cellProps) => <IdWithIconsCell item={cellProps.value} />,
    },
    {
      Header: 'Logos',
      accessor: 'logoCount',
      minWidth: 50,
    },
    {
      Header: <HeaderCell text={'Ship Date'} />,
      accessor: 'shipDate',
      sortable: true,
      Cell: (cellProps) => <span>{parseDateNumeric(cellProps.value)}</span>,
    },
    {
      Header: 'Print File(s)',
      accessor: 'logoPrintFiles',
      Cell: (cellProps) => <AttachedFiles files={cellProps.value} />,
    },
    {
      Header: 'Personalization File(s)',
      accessor: 'personalizationCutFiles',
      Cell: (cellProps) => <ParsedNameFiles files={cellProps.value} />,
    },
    {
      Header: 'Logo Cut File(s)',
      accessor: 'logoCutFiles',
      Cell: (cellProps) => <ParsedNameFiles files={cellProps.value} />,
    },
    {
      Header: <HeaderCell text={'Received On'} />,
      accessor: 'receivedOn',
      sortable: true,
      Cell: (cellProps) => <span>{parseDateTimeNumeric(cellProps.value)}</span>,
    },
    {
      Header: 'Mark Printing',
      Cell: (cellProps) => {
        const { id } = cellProps.original;

        return (
          <UpdatePrintFileStatus
            id={id}
            linkText={'Mark in Print'}
            nextStatus={printStatusEnum.Printing}
            handleStatusUpdate={handleStatusUpdate}
          />
        );
      },
    },
  ], [handleStatusUpdate]);

  const printingColumns: Array<Column<any>> = useMemo(() => [
    {
      Header: <HeaderCell text={'Sheet'} />,
      minWidth: 50,
      sortable: true,
      accessor: '',
      id: 'sheetId',
      Cell: (cellProps) => <IdWithIconsCell item={cellProps.value} />,
    },
    {
      Header: 'Logos',
      accessor: 'logoCount',
      minWidth: 50,
    },
    {
      Header: <HeaderCell text={'Ship Date'} />,
      accessor: 'shipDate',
      sortable: true,
      Cell: (cellProps) => <span>{parseDateNumeric(cellProps.value)}</span>,
    },
    {
      Header: 'Print File(s)',
      accessor: 'logoPrintFiles',
      Cell: (cellProps) => <AttachedFiles files={cellProps.value} />,
    },
    {
      Header: 'Personalization File(s)',
      accessor: 'personalizationCutFiles',
      Cell: (cellProps) => <ParsedNameFiles files={cellProps.value} />,
    },
    {
      Header: 'Logo Cut File(s)',
      accessor: 'logoCutFiles',
      Cell: (cellProps) => <ParsedNameFiles files={cellProps.value} />,
    },
    {
      Header: 'Claimed On',
      accessor: 'claimedOn',
      Cell: (cellProps) => <span>{parseDateTimeNumeric(cellProps.value)}</span>,
    },
    {
      Header: 'Claimed By',
      accessor: 'claimedBy',
    },
    {
      Header: 'DTF',
      show: !!printRoomEnableDtf,
      Cell: (cellProps) => {
        const { id } = cellProps.original;

        return (
          <DtfPrinterButton
            id={id}
            onClick={openDftSheetModel}
            enabled={printRoomEnableDtf}
          />
        );
      },
    },
    {
      Header: 'Send To Print Queue',
      Cell: (cellProps) => {
        const { id } = cellProps.original;

        return (
          <UpdatePrintFileStatus
            id={id}
            linkText={'Send To Print Queue'}
            nextStatus={printStatusEnum.PrintReady}
            handleStatusUpdate={handleStatusUpdate}
          />
        );
      },
    },
    {
      Header: 'Mark as Done',
      Cell: (cellProps) => {
        const { id } = cellProps.original;

        return (
          <UpdatePrintFileStatus
            id={id}
            linkText={'Mark Done'}
            nextStatus={printStatusEnum.Done}
            handleStatusUpdate={handleStatusUpdate}
          />
        );
      },
    },
  ], [printRoomEnableDtf, openDftSheetModel, handleStatusUpdate]);

  const doneColumns: Array<Column<any>> = useMemo(() => [
    {
      Header: <HeaderCell text={'Sheet'} />,
      minWidth: 50,
      sortable: true,
      accessor: '',
      id: 'sheetId',
      Cell: (cellProps) => <IdWithIconsCell item={cellProps.value} />,
    },
    {
      Header: 'Logos',
      accessor: 'logoCount',
      minWidth: 50,
    },
    {
      Header: <HeaderCell text={'Ship Date'} />,
      accessor: 'shipDate',
      sortable: true,
      Cell: (cellProps) => <span>{parseDateNumeric(cellProps.value)}</span>,
    },
    {
      Header: 'Print File(s)',
      accessor: 'logoPrintFiles',
      Cell: (cellProps) => <AttachedFiles files={cellProps.value} />,
    },
    {
      Header: 'Personalization File(s)',
      accessor: 'personalizationCutFiles',
      Cell: (cellProps) => <ParsedNameFiles files={cellProps.value} />,
    },
    {
      Header: 'Logo Cut File(s)',
      accessor: 'logoCutFiles',
      Cell: (cellProps) => <ParsedNameFiles files={cellProps.value} />,
    },
    {
      Header: 'Completed On',
      accessor: 'doneOn',
      Cell: (cellProps) => <span>{parseDateTimeNumeric(cellProps.value)}</span>,
    },
    {
      Header: 'Completed By',
      accessor: 'doneBy',
    },
  ], []);

  const priorityColumns: Array<Column<any>> = useMemo(() => [
    {
      Header: 'Artwork / Personalization',
      accessor: '',
      Cell: (cellProps) => (
        <LogoOrTextCell
          item={cellProps.value}
          openModal={openPriorityLogoModal}
          colorsDictionary={colorsDictionary}
        />
      ),
    },
    {
      Header: 'Barcode',
      minWidth: 100,
      accessor: 'barcode',
    },
    {
      Header: 'Order ID',
      maxWidth: 100,
      accessor: 'orderNumber',
    },
    {
      Header: 'File(s)',
      accessor: 'files',
      maxWidth: 140,
      Cell: (cellProps) => <AttachedFiles files={cellProps.value} />,
    },
    {
      Header: <HeaderCell text={'Ship Date'} />,
      accessor: 'shipDate',
      maxWidth: 130,
      sortable: true,
      Cell: (cellProps) => <span>{parseDateNumeric(cellProps.value)}</span>,
    },
    {
      Header: 'Reason for Priority',
      maxWidth: 150,
      accessor: '',
      Cell: (cellProps) => <span>{cellProps?.value?.flag?.reason}</span>,
    },
    {
      Header: 'DTF',
      show: !!printRoomEnableDtf,
      Cell: (cellProps) => {
        const { id } = cellProps.original;

        return (
          <DtfPrinterButton
            id={id}
            onClick={openDftPriorityModel}
            enabled={printRoomEnableDtf}
          />
        );
      },
    },
    {
      Header: 'Notes',
      maxWidth: 150,
      accessor: '',
      Cell: (cellProps) => (
        <ViewPriorityTaskModalButton
          openModal={openPriorityDetailsModal}
          taskDetails={cellProps.value}
        />
      ),
    },
    {
      Header: 'Current Status',
      maxWidth: 150,
      Cell: (cellProps) => {
        const { id } = cellProps.original;

        return (
          <UpdatePriorityItemStatus
            id={id}
            status={cellProps.original.status}
            markPriorityItemInPrint={handleMarkPriorityItemInPrint}
            markPriorityItemDone={handleMarkPriorityItemDone}
          />
        );
      },
    },
  ], [printRoomEnableDtf, openPriorityLogoModal, colorsDictionary, openDftPriorityModel, openPriorityDetailsModal, handleMarkPriorityItemInPrint, handleMarkPriorityItemDone]);

  const getColumns = useCallback((): Array<Column<any>> => {
    if (selectedTab === printStatusEnum.PrintReady) {
      return printReadyColumns;
    }
    if (selectedTab === printStatusEnum.Printing) {
      return printingColumns;
    }
    if (selectedTab === printStatusEnum.Done) {
      return doneColumns;
    }
    if (selectedTab === printStatusEnum.Priority) {
      return priorityColumns;
    }

    return [];
  }, [
    selectedTab,
    printReadyColumns,
    printingColumns,
    doneColumns,
    priorityColumns,
  ]);

  const getLogoCountMessage = useCallback((): Nullable<string> => {
    if (selectedTab === printStatusEnum.Priority) {
      return null;
    }

    return ` (${selectedLogosCount} logos)`;
  }, [
    selectedTab,
    selectedLogosCount,
  ]);

  const tableActionsHeader = useMemo(() => {
    const handleClickOnBulkAction = (printStatus: (typeof printStatusEnum)[keyof typeof printStatusEnum]) => (
      () => handleBulkStatusUpdate(printStatus)
    );

    let bulkAction = null;
    if (selectedTab === printStatusEnum.PrintReady) {
      bulkAction = (
        <Button
          type={'secondary'}
          text={'Bulk Mark Printing'}
          onClick={handleClickOnBulkAction(printStatusEnum.Printing)}
          size={'s'}
          classes={'actions-row__action--button'}
        />
      );
    } else if (selectedTab === printStatusEnum.Printing) {
      bulkAction = (
        <div className='actions-row__action'>
          <Button
            type={'secondary'}
            text={'Bulk Move to Print Queue'}
            onClick={handleBulkStatusUpdate.bind(null, printStatusEnum.PrintReady)}
            size={'s'}
            classes={'actions-row__action--button'}
          />
          <Button
            type={'secondary'}
            text={'Bulk Mark Printed'}
            onClick={handleBulkStatusUpdate.bind(null, printStatusEnum.Done)}
            size={'s'}
            classes={'actions-row__action--button ml-20'}
          />
        </div>
      );
    } else if (selectedTab === printStatusEnum.Priority) {
      bulkAction = (
        <div className='actions-row__action'>
          <Button
            type={'secondary'}
            text={'Bulk Mark In Print'}
            onClick={handleBulkPriorityStatusUpdate.bind(null, printStatusEnum.Printing)}
            size={'s'}
            classes={'actions-row__action--button'}
          />
          <Button
            type={'secondary'}
            text={'Bulk Mark Done'}
            onClick={handleBulkPriorityStatusUpdate.bind(null, printStatusEnum.Done)}
            size={'s'}
            classes={'actions-row__action--button ml-20'}
          />
        </div>
      );
    }

    return (
      <TableBulkActions<PrintQueueDto | PriorityQueueDto>
        selectedItems={selectedItems}
        additionalInfo={getLogoCountMessage}
        bulkActions={bulkAction}
        clearAll={clearSelection}
      />
    );
  }, [
    selectedTab,
    selectedItems,
    clearSelection,
    getLogoCountMessage,
    handleBulkStatusUpdate,
    handleBulkPriorityStatusUpdate,
  ]);

  const wrappedColumns = useMemo(() => [
    {
      Header: tableActionsHeader,
      columns: getColumns(),
    },
  ], [
    getColumns,
    tableActionsHeader,
  ]);

  const filtersToPreserve = useMemo(() => ({
    selectedTab,
    claimedByFilter,
    expressProductionFilter,
  }), [
    selectedTab,
    claimedByFilter,
    expressProductionFilter,
  ]);

  return (
    <div className='sheet__list'>
      <div>
        <PrintRoomTableTabs
          selectTab={handleSelectTab}
          selectedTab={selectedTab}
        />
        <div>
          <div className='sheet'>
            <div className='sheet__list'>
              <div className='w-100'>
                <PrintFilesTable
                  data={queue}
                  columns={wrappedColumns}
                  defaultPageSize={defaultPageSizeSmallTable}
                  pageSizeOptions={pageSizeOptionsSmallTable}
                  onFetchData={fetchData}
                  totalPages={totalPages}
                  hasNextPage={hasNextPage}
                  hasPreviousPage={hasPreviousPage}
                  selectable={true}
                  selectPredicateOrKey={'id'}
                  updateSelection={updateSelection}
                  selectedData={selectedItems}
                  isPageSelected={isPageSelected}
                  totalCount={totalCount}
                  isBulkActionsMode={selectedItems?.length > 0}
                  tableId={TableEnum.printRoom}
                  preserveState={true}
                  sortDirEnum={sortDirectionEnum}
                  initialPageNumber={initialPageNumber}
                  initialPageSize={initialPageSize}
                  initialSortColumn={initialSortColumn}
                  initialSortDirection={initialSortDirection}
                  filtersToPreserve={filtersToPreserve}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <DtfPrinterModal
        title={`DTF Print Sheet #${dftSheetId}`}
        formId='dtf-printer-modal-form_sheet'
        jobId={dftSheetId}
        isOpen={dftSheetId > 0}
        onClose={closeDftSheetModel}
        onPrint={handleDtfPrintByPrintSheet}
      />
      <DtfPrinterModal
        title={`DTF Print Priotity Item #${dftPriorityId}`}
        formId='dtf-printer-modal-form_priority'
        jobId={dftPriorityId}
        isOpen={dftPriorityId > 0}
        onClose={closeDftPriorityModel}
        onPrint={handleDtfPrintByPriorityItem}
      />
    </div>
  );
});

export default connector(PrintRoomTable);
