/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Financials Homefield API
 * OpenAPI spec version: v1
 */
import type { InstancePreview } from './instancePreview';
import type { ItemPreview } from './itemPreview';
import type { IContactDtoISalesChannelDetailDto } from './iContactDtoISalesChannelDetailDto';

export interface CollectionPreview {
  discountAmount?: number;
  totalAmount?: number;
  rosterTeamName?: string | null;
  collectionName?: string | null;
  lockerId?: number;
  collectionId?: number;
  rosterTeamId?: number;
  totalInstanceCount?: number;
  instances?: InstancePreview[] | null;
  items?: ItemPreview[] | null;
  locker?: IContactDtoISalesChannelDetailDto;
}
