/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */

export type PrintAssemblyStatusEnum = 'Initial' | 'Done';


export const PrintAssemblyStatusEnum = {
  Initial: 'Initial' as PrintAssemblyStatusEnum,
  Done: 'Done' as PrintAssemblyStatusEnum,
};
