/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Accounts Homefield API
 * OpenAPI spec version: 1.0
 */
import type { StagingJobOutputPagedList } from './stagingJobOutputPagedList';

export interface StagingJobOutputPagedListApiResponse {
  success: boolean;
  message?: string | null;
  result?: StagingJobOutputPagedList;
}
