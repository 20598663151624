/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Catalog Homefield API
 * OpenAPI spec version: v1
 */
import type { StyleDto } from './styleDto';
import type { LayoutDetailDto } from './layoutDetailDto';

export interface ValidateStyleLayoutCommand {
  style?: StyleDto;
  layout?: LayoutDetailDto;
}
