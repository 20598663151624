import React from 'react';
import MultiSelectDropdown from '@sharedComponents/Inputs/Dropdowns/MultiSelectDropdown/MultiSelectDropdown';
import { ObjectType } from '@customTypes/option';
import { DisableableItem } from '@models/common/DisableableItem';

interface Locker extends ObjectType, DisableableItem {
  id: number;
}

interface OwnProps {
  lockerIds: Locker[];
  lockersChange: (lockerIds: number[]) => void;
}

type Props = OwnProps;

const LockersMultiSelect = React.memo(({
  lockerIds,
  lockersChange,
}: Props) => (
  <MultiSelectDropdown<Locker, 'id'>
    objects={lockerIds}
    itemText={'lockers'}
    updateCallback={lockersChange}
    classNames={'margin-left'}
    textKey={'id'}
    valueKey={'id'}
  />
));

export default LockersMultiSelect;
