/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */
import type { PrintRoomTabEnum } from './printRoomTabEnum';
import type { QueriesSortByEnum } from './queriesSortByEnum';
import type { SortDirectionEnum } from './sortDirectionEnum';
import type { HagPrintRoomJobTypeEnum } from './hagPrintRoomJobTypeEnum';
import type { HagPrintRoomJobStatusEnum } from './hagPrintRoomJobStatusEnum';
import type { OrderInventoryStatusEnum } from './orderInventoryStatusEnum';

export type GetHomefieldApiHagPrintroomParams = { printRoomTab?: PrintRoomTabEnum; pageNumber?: number; pageSize?: number; sortBy?: QueriesSortByEnum; sortDirection?: SortDirectionEnum; jobType?: HagPrintRoomJobTypeEnum; jobStatus?: HagPrintRoomJobStatusEnum; jobStatuses?: HagPrintRoomJobStatusEnum[]; isRush?: boolean; isExpressProduction?: boolean; isStandardProduction?: boolean; inventoryStatus?: OrderInventoryStatusEnum; orderNumber?: number; lockerId?: number; organizationId?: number; shipFrom?: string; shipTo?: string; claimedBy?: string; printerNumber?: number; returnAllJobs?: boolean };
