/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */

export interface NoteDto {
  id?: number;
  created?: string | null;
  createdBy?: string | null;
  createdByName?: string | null;
  attachmentUrl?: string | null;
  readonly fileName?: string | null;
  content?: string | null;
}
