/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Financials Homefield API
 * OpenAPI spec version: v1
 */
import type { PayoutStatusEnum } from './payoutStatusEnum';

export interface PayoutStatusEnumStatusModel {
  created?: string | null;
  updated?: string | null;
  deleted?: string | null;
  status?: PayoutStatusEnum;
  readonly value?: number | null;
  readonly label?: string | null;
  user?: string | null;
}
