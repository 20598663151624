import React from 'react';
import {
  useForm,
  UseFormReturn,
} from 'react-hook-form';
import Grid from '@material-ui/core/Grid';
import { editVoucherOrderForm } from '@constants/reduxForms';
import { maxCustomSenderNameLength } from '@constants/values';
import { yupResolver } from '@hookform/resolvers/yup';
import yup from '@util/yupValidationHelper';
import NotificationSettings from './VoucherFormsContent/NotificationSettings';
import { VoucherNotificationSettingsFormData } from '@models/forms/Vouchers/VoucherNotificationSettingsFormData';

const schema = yup.object().shape({
  notificationSettings: yup.object().shape({
    sendDate: yup.string().required('Send date is required'),
    linkDestination: yup.string().required('Link destination is required'),
    senderName: yup.string().required('Sender name is required'),
    customSenderName: yup.string()
      .max(maxCustomSenderNameLength, `Custom sender name exceeds the character limit of ${maxCustomSenderNameLength}`)
      .nullable(),
  })
    .required(),
});

interface OwnProps {
  onSubmit: (voucherOrder: VoucherNotificationSettingsFormData) => Promise<void>;
  initialValues?: Nullable<VoucherNotificationSettingsFormData>;
  organizationId?: Nullable<number>;
}

type Props = OwnProps;

const VoucherNotificationSettingsForm = React.memo<Props>(({
  initialValues = null,
  onSubmit,
  organizationId,
}) => {
  const formMethods: UseFormReturn<VoucherNotificationSettingsFormData> = useForm<VoucherNotificationSettingsFormData>({
    // @ts-expect-error - resolver is weird
    resolver: yupResolver(schema),
  });

  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
    watch,
  } = formMethods;

  const senderName = watch('notificationSettings.senderName');

  return (
    <form
      id={editVoucherOrderForm}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Grid
        container={true}
        item={true}
        xs={12}
        className={'voucherOrders__edit-form__section'}
      >
        <NotificationSettings
          initialValues={initialValues}
          register={register}
          setValue={setValue}
          errors={errors}
          senderName={senderName}
          organizationId={organizationId}
        />
      </Grid>
    </form>
  );
});

export default VoucherNotificationSettingsForm;
