/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */

export interface SimpleOrderItemDto {
  barcode?: string | null;
  styleName?: string | null;
}
