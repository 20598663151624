/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */
import type { AccountingLineItemDto } from './accountingLineItemDto';

export interface InventoryItemDto {
  sku?: string | null;
  lineItem?: AccountingLineItemDto;
  quantity?: number;
  atyAllocated?: number;
  qtyToBackorder?: number;
  qtyOnPo?: number;
  qtyCanceled?: number;
  qtyRemaining?: number;
  qtyPrevInvoiced?: number;
  fulfillmentDate?: string | null;
  actualShipDate?: string | null;
  poNumber?: string | null;
  promisedDate?: string | null;
}
