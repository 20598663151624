import React, { useCallback, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
  useGetHomefieldApiVouchersOrdersId as useGetVoucherOrder,
  useGetHomefieldApiVouchersOrdersIdChangelog as useGetVoucherOrderChangelog,
  useGetHomefieldApiVouchersOrdersVoucherOrderIdCollections as useGetVoucherOrderCollections,
  usePostHomefieldApiVouchersOrdersrefund as usePostVoucherOrderRefund,
  usePostHomefieldApiVouchersOrderssendemailnotifications as useResendVoucherOrderEmailNotifications,
  usePostHomefieldApiVouchersOrderssendinvoice as useSendVoucherOrderInvoice,
  usePostHomefieldApiVouchersOrderssendrefundreceipt as useSendVoucherOrderRefundReceipt,
  usePutHomefieldApiVouchersInstancescancel,
  usePutHomefieldApiVouchersOrderscancel,
  usePutHomefieldApiVouchersOrdersVoucherOrderId as useUpdateVoucherOrder,
} from '@api/financialServices/voucher-homefield';
import { ApiOk } from '@api/squadlockerServices/models';
import { VoucherInstanceDto, VoucherInstanceStatusEnum, VoucherNotificationLinkDestinationEnum, VoucherNotificationSenderNameEnum, VoucherOrderPaymentStatusEnum } from '@api/financialServices/models';
import { roundFloat } from '@util/numberHelpers';
import { voucherOrdersUrl } from '@constants/clientUrls/clientUrls';
import { materialSwal } from '@util/componentHelper';
import EditVoucherOrderModal from '@components/VoucherOrders/Modals/EditVoucherOrderModal';
import { VoucherOrderFormData } from '@components/VoucherOrders/Forms/VoucherOrderForm';
import TableFilters from '@sharedComponents/Display/TableFilters/TableFilters';
import BackLink from '@sharedComponents/Navigation/BackLink';
import ActionButton from '@sharedComponents/Buttons/ActionButton';
import VoucherDetailsList from './VoucherDetailsContent/VoucherDetailsList';
import VoucherDetailsSidebar from './VoucherDetailsContent/VoucherDetailsSidebar/VoucherDetailsSidebar';
import VoucherOrderCancelWithRefundModal, { VoucherOrderCancelWithRefundForm } from '../Modals/VoucherOrderCancelWithRefundModal';
import VouchersSelectForCancellationModal from '../Modals/VouchersSelectForCancellationModal';
import SendRefundConfirmationModal from '@components/shared/Modal/SendRefundConfirmationModal';
import { SendRefundConfirmationFormData } from '@models/forms/SendRefundConfirmationFormData';
import { useSimpleConfirmationModal } from '@hooks/shared/Modal/useSimpleConfirmationModal';
import VoucherFinancialsModal from '../Modals/VoucherFinancialsModal';
import VoucherOrderRefundModal, { VoucherOrderRefundForm } from '../Modals/VoucherOrderRefundModal';
import { VoucherRefundTypeEnum } from '@constants/enums/voucherEnums';
import VoucherNotificationSettingsModal from '../Modals/VoucherNotificationSettingsModal';
import { VoucherNotificationSettingsFormData } from '@models/forms/Vouchers/VoucherNotificationSettingsFormData';

interface RouteProps {
  voucherOrderId?: string;
}

type Props = RouteComponentProps<RouteProps>;

const VoucherDetails = React.memo<Props>(({ match: { params: { voucherOrderId: voucherOrderIdParam } } }) => {
  const [
    editVoucherOrderModalIsOpen,
    setEditVoucherOrderModalIsOpen,
  ] = useState<boolean>(false);
  const [
    sendVoucherOrderRefundReceiptModalIsOpen,
    setSendVoucherOrderRefundReceiptModalIsOpen,
  ] = useState<boolean>(false);
  const [
    resendVoucherNotificationsModalIsOpen,
    setResendVoucherNotificationsModalIsOpen,
  ] = useState<boolean>(false);
  const [
    cancelVoucherOrderModalIsOpen,
    setCancelVoucherOrderModalIsOpen,
  ] = useState<boolean>(false);
  const [
    refundVoucherOrderModalIsOpen,
    setRefundVoucherOrderModalIsOpen,
  ] = useState<boolean>(false);
  const [
    vouchersSelectForCancellationModal,
    setVoucherSelectForCancellationModal,
  ] = useState<boolean>(false);
  const [
    voucherFinancialsModalIsOpen,
    setVoucherFinancialsModalIsOpen,
  ] = useState<boolean>(false);
  const [
    vouchersToCancel,
    setVouchersToCancel,
  ] = useState<VoucherInstanceDto[] | null>(null);
  const [
    cancelAll,
    setCancelAll,
  ] = useState<boolean>(false);

  const closeVoucherOrderEditModal = useCallback(() => {
    setEditVoucherOrderModalIsOpen(false);
  }, []);

  const closeCancelVoucherOrderModal = useCallback(() => {
    setCancelVoucherOrderModalIsOpen(false);
  }, []);

  const closeResendVoucherOrderNotificationsModal = useCallback(() => {
    setResendVoucherNotificationsModalIsOpen(false);
  }, []);

  const closeRefundVoucherOrderModal = useCallback(() => {
    setRefundVoucherOrderModalIsOpen(false);
  }, []);

  const {
    data: voucherOrder,
    refetch: fetchVoucherOrder,
  } = useGetVoucherOrder(parseInt(voucherOrderIdParam!));

  const {
    data: voucherOrderChangeLog,
    refetch: fetchVoucherOrderChangeLog,
  } = useGetVoucherOrderChangelog(parseInt(voucherOrderIdParam!));

  const {
    data: voucherOrderCollections,
    refetch: fetchVoucherOrderCollections,
  } = useGetVoucherOrderCollections(voucherOrder?.id ?? 0);

  const onSendVoucherOrderInvoice = (response: ApiOk) => {
    materialSwal('Success', response.message, 'success');
  };

  const onSendVoucherOrderRefundReceipt = (response: ApiOk) => {
    materialSwal('Success', response.message, 'success');
  };

  const onUpdateVoucherOrder = (response: ApiOk) => {
    materialSwal('Success', response.message, 'success');
    fetchVoucherOrder();
    closeVoucherOrderEditModal();
  };

  const { mutateAsync: sendVoucherOrderInvoice } = useSendVoucherOrderInvoice({
    mutation: {
      onSuccess: onSendVoucherOrderInvoice,
    },
  });

  const { mutateAsync: sendVoucherOrderRefundReceipt } = useSendVoucherOrderRefundReceipt({
    mutation: {
      onSuccess: onSendVoucherOrderRefundReceipt,
    },
  });

  const { mutateAsync: updateVoucherOrder } = useUpdateVoucherOrder({
    mutation: {
      onSuccess: onUpdateVoucherOrder,
    },
  });

  const onVoucherOrderCanceled = (response: ApiOk) => {
    materialSwal('Success', response.message, 'success');
    closeCancelVoucherOrderModal();
    setVouchersToCancel(null);
    fetchVoucherOrder();
  };

  const onVoucherNotificationSent = (response: ApiOk) => {
    materialSwal('Success', response.message, 'success');
    closeResendVoucherOrderNotificationsModal();
  };

  const { mutateAsync: cancelVoucherOrder } = usePutHomefieldApiVouchersOrderscancel({
    mutation: {
      onSuccess: onVoucherOrderCanceled,
    },
  });

  const { mutateAsync: cancelVouchers } = usePutHomefieldApiVouchersInstancescancel({
    mutation: {
      onSuccess: onVoucherOrderCanceled,
    },
  });

  const onVoucherOrderRefunded = (response: ApiOk) => {
    materialSwal('Success', response.message, 'success');
    closeRefundVoucherOrderModal();
    fetchVoucherOrder();
  };

  const { mutateAsync: refundVoucher } = usePostVoucherOrderRefund({
    mutation: {
      onSuccess: onVoucherOrderRefunded,
    },
  });

  const { mutateAsync: resendVoucherNotifications } = useResendVoucherOrderEmailNotifications({
    mutation: {
      onSuccess: onVoucherNotificationSent,
    },
  });

  useEffect(() => {
    fetchVoucherOrder();
  }, [
    fetchVoucherOrder,
    voucherOrderIdParam,
  ]);

  useEffect(() => {
    if (voucherOrder) {
      fetchVoucherOrderCollections();
    }
  }, [
    fetchVoucherOrderCollections,
    voucherOrder,
  ]);

  useEffect(() => {
    if (voucherOrder) {
      fetchVoucherOrderChangeLog();
    }
  }, [
    fetchVoucherOrderChangeLog,
    voucherOrder,
  ]);

  const {
    openModal: openSendVoucherOrderInvoiceModal,
    closeModal: closeSendVoucherOrderInvoiceModal,
    isOpen: sendVoucherOrderInvoiceModalIsOpen,
    ConfirmationModal: VoucherOrderInvoiceConfirmationModal,
  } = useSimpleConfirmationModal();

  const refreshVoucherOrder = useCallback(() => {
    fetchVoucherOrder();
  }, [fetchVoucherOrder]);

  const openSendVoucherOrderRefundReceiptModal = useCallback(() => {
    setSendVoucherOrderRefundReceiptModalIsOpen(true);
  }, []);

  const closeSendVoucherOrderRefundReceiptModal = useCallback(() => {
    setSendVoucherOrderRefundReceiptModalIsOpen(false);
  }, []);

  const openResendVoucherOrderNotificationsModal = useCallback(() => {
    setResendVoucherNotificationsModalIsOpen(true);
  }, []);

  const openCancelVoucherOrderModal = useCallback(() => {
    setCancelVoucherOrderModalIsOpen(true);
  }, []);

  const openRefundVoucherOrderModal = useCallback(() => {
    setRefundVoucherOrderModalIsOpen(true);
  }, []);

  const closeVoucherFinancialsModal = useCallback(() => {
    setVoucherFinancialsModalIsOpen(false);
  }, []);

  const openVoucherFinancialsModal = useCallback(() => {
    setVoucherFinancialsModalIsOpen(true);
  }, []);

  const onCancelVoucherOrder = useCallback(() => {
    setCancelAll(true);
    openCancelVoucherOrderModal();
  }, [openCancelVoucherOrderModal]);

  const onRefundVoucherOrder = useCallback(() => {
    openRefundVoucherOrderModal();
  }, [openRefundVoucherOrderModal]);

  const sendVoucherOrderInvoiceEmail = useCallback(() => {
    sendVoucherOrderInvoice({
      data: { voucherOrderId: voucherOrder!.id! },
    });
    closeSendVoucherOrderInvoiceModal();
  }, [
    sendVoucherOrderInvoice,
    voucherOrder,
    closeSendVoucherOrderInvoiceModal,
  ]);

  const sendVoucherOrderRefundReceiptEmail = useCallback(async (form: SendRefundConfirmationFormData | undefined) => {
    if (!form) {
      console.error('Form is undefined');
      return;
    }

    await sendVoucherOrderRefundReceipt({
      data: {
        voucherOrderId: voucherOrder!.id!,
        email: form.recipientEmail,
      },
    });
    closeSendVoucherOrderRefundReceiptModal();
  }, [
    sendVoucherOrderRefundReceipt,
    voucherOrder,
    closeSendVoucherOrderRefundReceiptModal,
  ]);

  const resendVoucherOrderEmailNotifications = useCallback(async (form: VoucherNotificationSettingsFormData) => {
    await resendVoucherNotifications({
      data: {
        voucherOrderId: voucherOrder!.id!,
        customSenderName: form.notificationSettings.customSenderName,
        linkDestination: form.notificationSettings.linkDestination as VoucherNotificationLinkDestinationEnum,
        sendDate: form.notificationSettings.sendDate ?? undefined,
        senderName: form.notificationSettings.senderName ?? undefined,
      },
    });
    closeResendVoucherOrderNotificationsModal();
  }, [
    resendVoucherNotifications,
    voucherOrder,
    closeResendVoucherOrderNotificationsModal,
  ]);

  const onVoucherOrderCancel = useCallback(async (form: VoucherOrderCancelWithRefundForm) => {
    if (cancelAll) {
      await cancelVoucherOrder({
        data: {
          id: voucherOrder!.id,
          amount: roundFloat(form.amount),
        },
      });
    } else {
      await cancelVouchers({
        data: {
          voucherOrderId: voucherOrder!.id,
          voucherInstanceIds: vouchersToCancel!.map((x) => x.id!),
          amount: roundFloat(form.amount),
        },
      });
    }
  }, [
    voucherOrder,
    cancelAll,
    vouchersToCancel,
    cancelVoucherOrder,
    cancelVouchers,
  ]);

  const onVoucherOrderRefund = useCallback(async (form: VoucherOrderRefundForm) => {
    const amount = form.refundType === VoucherRefundTypeEnum.FullRefund ? form.fullAmount : form.partialAmount;

    await refundVoucher({
      data: {
        id: voucherOrder!.id,
        amount: roundFloat(amount),
      },
    });
  }, [
    refundVoucher,
    voucherOrder,
  ]);

  const openVoucherOrderEditModal = useCallback(() => {
    setEditVoucherOrderModalIsOpen(true);
  }, []);

  const openVouchersSelectForCancellationModal = useCallback(() => {
    setVoucherSelectForCancellationModal(true);
  }, [setVoucherSelectForCancellationModal]);

  const closeVouchersSelectForCancellationModal = useCallback(() => {
    setVoucherSelectForCancellationModal(false);
  }, [setVoucherSelectForCancellationModal]);

  const onVouchersSelectedForCancellation = useCallback((ids: number[]) => {
    let vouchers: VoucherInstanceDto[] = [];
    voucherOrderCollections!.forEach((collection) => {
      vouchers = [
        ...vouchers,
        ...collection.voucherInstances!
          .filter((x) =>
            x.status !== VoucherInstanceStatusEnum.Redeemed
            && !x.cancellationId
            && ids.includes(x.id!)
          ),
      ];
    });

    setVouchersToCancel(vouchers);
    setCancelAll(false);
    closeVouchersSelectForCancellationModal();
    openCancelVoucherOrderModal();
  }, [
    voucherOrderCollections,
    setVouchersToCancel,
    closeVouchersSelectForCancellationModal,
    openCancelVoucherOrderModal,
  ]);

  const editVoucherOrder = useCallback(async (voucherOrderForm: VoucherOrderFormData) => {
    await updateVoucherOrder({
      voucherOrderId: voucherOrderForm.voucherOrderId,
      data: {
        voucherOrderId: voucherOrderForm.voucherOrderId,
        startDate: voucherOrderForm.startDate,
        endDate: voucherOrderForm.endDate,
        lockerManagerId: voucherOrderForm.lockerManagerId,
        notificationSettings: {
          sendDate: voucherOrderForm.notificationSettings!.sendDate!,
          linkDestination: voucherOrderForm.notificationSettings!.linkDestination! as VoucherNotificationLinkDestinationEnum,
          senderName: voucherOrderForm.notificationSettings!.senderName! as VoucherNotificationSenderNameEnum,
          customSenderName: voucherOrderForm.notificationSettings!.customSenderName!,
        },
      },
    });
  }, [updateVoucherOrder]);

  if (!voucherOrder) {
    return null;
  }

  return (
    <div className='container'>
      <VoucherOrderInvoiceConfirmationModal
        isOpen={sendVoucherOrderInvoiceModalIsOpen}
        closeModal={closeSendVoucherOrderInvoiceModal}
        title={'Confirmation'}
        cancelBtnText={'Cancel'}
        confirm={sendVoucherOrderInvoiceEmail}
        confirmationBody={(
          <span>
            Confirming this action will result in a voucher payment link being sent to the selected admin &nbsp;<b>{voucherOrder.lockerManagerFirstName} {voucherOrder.lockerManagerLastName}</b>
            &nbsp;for <b>{voucherOrder.organizationName ?? voucherOrder.lockerName}</b>.
          </span>
        )}
      />
      <SendRefundConfirmationModal
        defaultEmail={voucherOrder.lockerManagerEmail ?? undefined}
        isOpen={sendVoucherOrderRefundReceiptModalIsOpen}
        onSubmit={sendVoucherOrderRefundReceiptEmail}
        closeModal={closeSendVoucherOrderRefundReceiptModal}
      />
      <VoucherOrderCancelWithRefundModal
        isOpen={cancelVoucherOrderModalIsOpen}
        cancelAll={cancelAll}
        voucherOrder={voucherOrder}
        vouchersToCancel={vouchersToCancel}
        closeModal={closeCancelVoucherOrderModal}
        onSubmit={onVoucherOrderCancel}
      />

      <VoucherOrderRefundModal
        isOpen={refundVoucherOrderModalIsOpen}
        voucherOrder={voucherOrder}
        closeModal={closeRefundVoucherOrderModal}
        onSubmit={onVoucherOrderRefund}
      />

      <VouchersSelectForCancellationModal
        isOpen={vouchersSelectForCancellationModal}
        voucherOrderCollections={voucherOrderCollections}
        closeModal={closeVouchersSelectForCancellationModal}
        onSubmit={onVouchersSelectedForCancellation}
      />

      <EditVoucherOrderModal
        isOpen={editVoucherOrderModalIsOpen}
        closeModal={closeVoucherOrderEditModal}
        editVoucherOrder={editVoucherOrder}
        voucherOrder={voucherOrder}
      />

      <VoucherFinancialsModal
        isOpen={voucherFinancialsModalIsOpen}
        closeModal={closeVoucherFinancialsModal}
        voucherOrder={voucherOrder}
      />

      <VoucherNotificationSettingsModal
        isOpen={resendVoucherNotificationsModalIsOpen}
        closeModal={closeResendVoucherOrderNotificationsModal}
        voucherOrder={voucherOrder}
        addVoucherNotificationSettings={resendVoucherOrderEmailNotifications}
      />

      <TableFilters
        leftGroup={(
          <BackLink
            url={voucherOrdersUrl}
            text={'Voucher Orders'}
          />
        )}
        rightGroup={(
          <ActionButton
            text={'Order Actions'}
            classes={'btn-primary'}
            disabled={!!voucherOrder.cancellationId}
            dangerous={false}
            actions={[
              {
                text: 'Cancel Voucher Order',
                action: onCancelVoucherOrder,
                isDangerous: false,
              },
              {
                text: 'Cancel Individual Voucher(s)',
                action: openVouchersSelectForCancellationModal,
                isDangerous: false,
              },
              {
                text: 'Refund Voucher Order',
                action: onRefundVoucherOrder,
                isDangerous: false,
                isVisible: voucherOrder.totalAmountLeftForRefund! > 0,
              },
              {
                text: 'Send Refund Receipt',
                icon: 'email',
                action: openSendVoucherOrderRefundReceiptModal,
                isVisible: voucherOrder.refundAmount! > 0 || voucherOrder.voucherInstancesTotalRefundAmount! > 0,
              },
              {
                text: 'Resend Voucher Notifications',
                icon: 'email',
                action: openResendVoucherOrderNotificationsModal,
                isVisible: voucherOrder.paymentStatus! === VoucherOrderPaymentStatusEnum.Paid,
              },
            ]}
          />
        )}
        classes={'mb-20'}
      />
      <div className='flex'>
        <VoucherDetailsSidebar
          voucherOrder={voucherOrder}
          openVoucherOrderEditModal={openVoucherOrderEditModal}
          resendInvoice={openSendVoucherOrderInvoiceModal}
          refreshVoucherOrder={refreshVoucherOrder}
          voucherOrderChangeLog={voucherOrderChangeLog}
        />
        {voucherOrderCollections
          && (
            <VoucherDetailsList
              voucherOrder={voucherOrder}
              voucherOrderCollections={voucherOrderCollections}
              classes={'ml-10'}
              openVoucherFinancialsModal={openVoucherFinancialsModal}
            />
          )}
      </div>
    </div>
  );
});

export default withRouter(VoucherDetails);
