/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */

export type PrintQueueStatusEnum = 'Initial' | 'PrintReady' | 'Printing' | 'Printed' | 'PrintAssembly' | 'Done';


export const PrintQueueStatusEnum = {
  Initial: 'Initial' as PrintQueueStatusEnum,
  PrintReady: 'PrintReady' as PrintQueueStatusEnum,
  Printing: 'Printing' as PrintQueueStatusEnum,
  Printed: 'Printed' as PrintQueueStatusEnum,
  PrintAssembly: 'PrintAssembly' as PrintQueueStatusEnum,
  Done: 'Done' as PrintQueueStatusEnum,
};
