import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import Modal from '@sharedComponents/Modal/Modal';

class DeleteCollectionModal extends PureComponent {
  render() {
    const {
      collection,
      deleteCollection,
      closeModal,
      isOpen,
    } = this.props;

    return (
      <Modal
        title={'Remove Collection'}
        modalHeight={'l'}
        modalWidth={'s'}
        modalClass={'lockerManager__delete-modal'}
        isOpen={isOpen}
        closeModal={closeModal}
        buttons={(
          <ModalButtons
            confirmBtnText={'Yes, Remove'}
            cancelBtnText={'Cancel'}
            onConfirm={deleteCollection.bind(null, collection)}
            onClose={closeModal}
            isDangerousAction={true}
          />
        )}
      >
        <div>
          <h3>
            Are you sure you want to remove &nbsp;
            <b>
              {collection?.name}&nbsp;
            </b>
            from the collections?
          </h3>
        </div>
      </Modal>
    );
  }
}

DeleteCollectionModal.propTypes = {
  collection: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }),
  deleteCollection: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default DeleteCollectionModal;
