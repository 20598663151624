/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */
import type { PrintAssemblyStatusEnum } from './printAssemblyStatusEnum';

export interface UpdatePrintAssemblyQueueStatusByBarcodeCommand {
  barcode?: string | null;
  status?: PrintAssemblyStatusEnum;
}
