/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */
import type { NotScheduledOrdersSortByEnum } from './notScheduledOrdersSortByEnum';
import type { SortDirectionEnum } from './sortDirectionEnum';

export type HomefieldApiVendorOrdersNotScheduledGETParams = { pageNumber?: number; pageSize?: number; search?: string; vendorId?: string[]; sortBy?: NotScheduledOrdersSortByEnum; sortDirection?: SortDirectionEnum };
