/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Catalog Homefield API
 * OpenAPI spec version: v1
 */
import type { ProductAgeEnum } from './productAgeEnum';
import type { ProductGenderEnum } from './productGenderEnum';

export interface OnHandInventoryDto {
  imageFront?: string | null;
  sku?: string | null;
  brandName?: string | null;
  categoryName?: string | null;
  name?: string | null;
  color?: string | null;
  size?: string | null;
  age?: ProductAgeEnum;
  gender?: ProductGenderEnum;
  availableOnHand?: number;
}
