/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Orders Homefield API
 * OpenAPI spec version: v1
 */

export type CouponTypeEnum = 'Amount' | 'Percent';


export const CouponTypeEnum = {
  Amount: 'Amount' as CouponTypeEnum,
  Percent: 'Percent' as CouponTypeEnum,
};
