import React, {
  useMemo,
  useState,
  useCallback,
} from 'react';
import {
  VoucherCollectionDetailDto,
  VoucherOrderDetailDto,
} from '@api/financialServices/models';
import { tabSizesEnum } from '@constants/enums/commonEnums';
import Tabs from '@sharedComponents/Tabs/Tabs';
import SectionContainer from '@sharedComponents/Display/Containers/SectionContainer';
import VoucherDetailsListFooter from './VoucherDetailsListContent/VoucherDetailsListFooter';
import VoucherDetailsListHeader from './VoucherDetailsListContent/VoucherDetailsListHeader';
import CollectionsTab from './VoucherDetailsListContent/Tabs/CollectionsTab';
import VouchersTab from './VoucherDetailsListContent/Tabs/VouchersTab';

export enum VoucherDetailsListTabEnum {
  Collections = 'Collections',
  Vouchers = 'Vouchers',
}

interface OwnProps {
  voucherOrder: VoucherOrderDetailDto;
  voucherOrderCollections: VoucherCollectionDetailDto[];
  classes?: string;
  openVoucherFinancialsModal: () => void;
}

type Props = OwnProps;

const VoucherDetailsList = React.memo<Props>(({
  voucherOrder,
  voucherOrderCollections,
  classes,
  openVoucherFinancialsModal,
}) => {
  const [
    selectedTab,
    setSelectedTab,
  ] = useState<VoucherDetailsListTabEnum>(VoucherDetailsListTabEnum.Collections);
  const [
    collapseAll,
    setCollapseAll,
  ] = useState<boolean>(false);
  const [
    filteredItems,
    setFilteredItems,
  ] = useState<VoucherCollectionDetailDto[]>([]);
  const [
    isFilteringItems,
    setIsFilteringItems,
  ] = useState<boolean>(false);
  const [
    resetSearchOrderItemsInput,
    setResetSearchOrderItemsInput,
  ] = useState<boolean>(false);

  const collapseOrExpandItems = useCallback(() => {
    setCollapseAll(!collapseAll);
  }, [collapseAll]);

  const onResetSearchOrderItemsInput = useCallback(() => {
    setResetSearchOrderItemsInput(false);
  }, []);

  const searchItemsInVoucherOrder = useCallback((searchInputValue) => {
    let newIsFilteringItems: boolean;
    let newFilteredItems: VoucherCollectionDetailDto[];

    if (searchInputValue === '') {
      newIsFilteringItems = false;
      newFilteredItems = voucherOrderCollections!;
    } else {
      newIsFilteringItems = true;
      newFilteredItems = voucherOrderCollections!.filter((i) => (
        (i.collectionName ?? '').toLowerCase().includes(searchInputValue.toLowerCase())
        || (i.lockerName ?? '').toLowerCase().includes(searchInputValue.toLowerCase())
        || (i.rosterTeamName ?? '').toLowerCase().includes(searchInputValue.toLowerCase())
        || (i.lockerId?.toString() ?? '').toLowerCase().includes(searchInputValue.toLowerCase())
      ));
    }

    setFilteredItems(newFilteredItems);
    setIsFilteringItems(newIsFilteringItems);
  }, [voucherOrderCollections]);

  const onSearchInputChange = useCallback((e) => {
    const searchInputValue = e.target.value.toLowerCase().trim();
    searchItemsInVoucherOrder(searchInputValue);
  }, [searchItemsInVoucherOrder]);

  const clearSearch = useCallback(() => {
    searchItemsInVoucherOrder('');
    setResetSearchOrderItemsInput(true);
  }, [searchItemsInVoucherOrder]);

  const selectTab = useCallback((newSelectedTab: string) => {
    clearSearch();
    setCollapseAll(false);
    setSelectedTab(newSelectedTab as VoucherDetailsListTabEnum);
  }, [clearSearch]);

  const tabs = useMemo(() => ([
    {
      title: 'Collections',
      name: VoucherDetailsListTabEnum.Collections,
      content: (
        <CollectionsTab
          voucherOrderCollections={(isFilteringItems ? filteredItems : voucherOrderCollections) ?? []}
          collapseAll={collapseAll}
        />
      ),
    },
    {
      title: 'Vouchers',
      name: VoucherDetailsListTabEnum.Vouchers,
      content: (
        <VouchersTab
          voucherOrderCollections={(isFilteringItems ? filteredItems : voucherOrderCollections) ?? []}
          collapseAll={collapseAll}
        />
      ),
    },
  ]), [
    voucherOrderCollections,
    filteredItems,
    isFilteringItems,
    collapseAll,
  ]);

  return (
    <SectionContainer
      noPadding={true}
      classes={`voucherOrders__details__list ${classes}`}
    >
      <>
        <VoucherDetailsListHeader
          collapseAll={collapseAll}
          collapseOrExpandItems={collapseOrExpandItems}
          isFilteringItems={isFilteringItems}
          filteredItemsQuantity={filteredItems?.length ?? 0}
          resetSearchOrderItemsInput={resetSearchOrderItemsInput}
          onResetSearchOrderItemsInput={onResetSearchOrderItemsInput}
          onSearchInputChange={onSearchInputChange}
          clearSearch={clearSearch}
        />
        <div>
          <Tabs
            tabs={tabs}
            selectedTab={selectedTab}
            selectTab={selectTab}
            size={tabSizesEnum.Medium}
            noMargin={true}
          />
        </div>
        <VoucherDetailsListFooter
          voucherOrder={voucherOrder}
          openVoucherFinancialsModal={openVoucherFinancialsModal}
        />
      </>
    </SectionContainer>
  );
});

export default VoucherDetailsList;
