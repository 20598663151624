import Color from 'color';
import { selectionTypeEnum } from '@constants/enums/decorationEnums';

const distanceBetweenColors = (color1, color2) => {
  const rgbColorArray1 = color1.rgb().array();

  const rgbColorArray2 = color2.rgb().array();

  const rgbDiff = Math.pow(rgbColorArray1[0] - rgbColorArray2[0], 2)
    + Math.pow(rgbColorArray1[1] - rgbColorArray2[1], 2)
    + Math.pow(rgbColorArray1[2] - rgbColorArray2[2], 2);

  return rgbDiff;
};

const findHexColorIndex = (rgbColor, hexColorsMapped) => {
  let minDf = -1;
  let minDfIndex = -1;

  for (let i = 0; i < hexColorsMapped.length; i++) {
    const hexColorMapped = hexColorsMapped[i];
    const diff = distanceBetweenColors(rgbColor, hexColorMapped);
    if (minDf < 0 || minDf > diff) {
      minDf = diff;
      minDfIndex = i;
    }
  }

  if (minDfIndex < 0) {
    console.error(`Could not find color for ${rgbColor.rgb().string()}`);
  }

  return minDfIndex;
};

export const findHexColors = (rgbColors, hexColors) => {
  if (!rgbColors || !hexColors) {
    return [];
  }

  const hexColorsMapped = hexColors.map((hexColor) => Color.rgb(hexColor.hexValue));

  const foundColors = new Set();
  for (const rgb of rgbColors) {
    const rgbColor = Color.rgb(rgb.rgb);

    const foundColorIndex = findHexColorIndex(rgbColor, hexColorsMapped);
    if (foundColorIndex >= 0) {
      foundColors.add(hexColors[foundColorIndex]);
    }
  }

  return Array.from(foundColors);
};

export const filterAdminColors = (colors) => (
  colors.filter((color) => (color.selectionType !== selectionTypeEnum.InternalSelection
    && color.selectionType !== selectionTypeEnum.SystemOnly)
  )
);

export const filterArtTeamColors = (colors) => (
  colors.filter((color) => (color.selectionType !== selectionTypeEnum.SystemOnly
    && color.selectionType !== selectionTypeEnum.InternalDeselection)
  )
);

export const filterInternalSelectionColors = (colors) => (
  colors.filter((color) => (color.selectionType === selectionTypeEnum.InternalSelection))
);

export const getColorNames = (colorsDictionary, colorIds, searchKey = 'code') => {
  const colors = [];
  if (colorIds && colorIds.length > 0) {
    for (const colorId of colorIds) {
      const color = colorsDictionary[colorId];
      if (color) {
        colors.push(color[searchKey]);
      }
    }
  }

  return colors;
};
