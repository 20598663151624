/* eslint-disable @typescript-eslint/no-this-alias */
/*
 * Copy pasted implementation of `memoize-one`
 * NPM: https://www.npmjs.com/package/memoize-one
 * GitHub: https://github.com/alexreardon/memoize-one)
 */
function areInputsEqual(
  newInputs: readonly unknown[],
  lastInputs: readonly unknown[]
): boolean {
  // No checks needed if the inputs length has changed
  if (newInputs.length !== lastInputs.length) {
    return false;
  }
  /*
   * Using for loop for speed. It generally performs better than array.every
   * https://github.com/alexreardon/memoize-one/pull/59
   */
  for (let i = 0; i < newInputs.length; i++) {
    // Using shallow equality check
    if (newInputs[i] !== lastInputs[i]) {
      return false;
    }
  }

  return true;
}

type EqualityFn = (newArgs: any[], lastArgs: any[]) => boolean;
/** @see https://github.com/alexreardon/memoize-one */
export default function memoizeOne<
  ResultFn extends(this: any, ...newArgs: any[]) => ReturnType<ResultFn>
>(resultFn: ResultFn, isEqual: EqualityFn = areInputsEqual): ResultFn {
  let lastThis: unknown;
  let lastArgs: unknown[] = [];
  let lastResult: ReturnType<ResultFn>;
  let calledOnce: boolean = false;
  // Breaking cache when context (this) or arguments change
  function memoized(this: unknown, ...newArgs: unknown[]): ReturnType<ResultFn> {
    if (calledOnce && lastThis === this && isEqual(newArgs, lastArgs)) {
      return lastResult;
    }
    /*
     * Throwing during an assignment aborts the assignment: https://codepen.io/alexreardon/pen/RYKoaz
     * Doing the lastResult assignment first so that if it throws
     * nothing will be overwritten
     */
    lastResult = resultFn.apply(this, newArgs);
    calledOnce = true;
    lastThis = this;
    lastArgs = newArgs;

    return lastResult;
  }

  return memoized as ResultFn;
}
