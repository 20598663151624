/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Orders Homefield API
 * OpenAPI spec version: v1
 */

export interface SalesChannelDto {
  id?: number;
  uuid?: string | null;
  created?: string | null;
  updated?: string | null;
  deleted?: string | null;
  organizationId?: number | null;
  name?: string | null;
  slug?: string | null;
  readonly logoUrl?: string | null;
}
