import React from 'react';
import PropTypes from 'prop-types';
import { featureFlags } from '@constants/common';
import ShowMoreActionsButton from '@sharedComponents/Buttons/ShowMoreActionsButton';

const RostersActionsColumn = React.memo(({
  roster,
  onRosterEdit,
  onRosterToggleDisabled,
  onRosterSendNotifications,
  onRosterDelete,
}) => {
  const actions = [
    {
      text: 'Edit',
      action: onRosterEdit.bind(null, roster),
    },
    {
      text: roster.disabled ? 'Activate' : 'Disable',
      action: onRosterToggleDisabled.bind(null, roster),
    },
  ];

  if (onRosterSendNotifications) {
    actions.push({
      text: 'Send Notifications',
      action: onRosterSendNotifications.bind(null, roster),
      isDisabled: roster.disabled,
      isVisible: featureFlags.rosterNotificationsEnabled,
    });
  }

  actions.push({
    text: 'Delete',
    action: onRosterDelete.bind(null, roster),
    isDangerous: true,
  });

  return (
    <ShowMoreActionsButton
      actions={actions}
    />
  );
});

RostersActionsColumn.propTypes = {
  roster: PropTypes.object.isRequired,
  onRosterEdit: PropTypes.func.isRequired,
  onRosterToggleDisabled: PropTypes.func.isRequired,
  onRosterSendNotifications: PropTypes.func.isRequired,
  onRosterDelete: PropTypes.func.isRequired,
};

export default RostersActionsColumn;
