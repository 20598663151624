/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Financials Homefield API
 * OpenAPI spec version: v1
 */
import type { IDto } from './iDto';

export interface IDtoApiResponse {
  success?: boolean;
  message?: string | null;
  result?: IDto;
}
