import { colorBrightnessEnum } from '@constants/enums/decorationEnums';
import * as actionTypes from './types';

const initialState = {
  slServicesVersion: '',
  fulfillmentVersion: '',
  catalogVersion: '',
  financialVersion: '',
  ordersVersion: '',
  accountsVersion: '',
  emailVersion: '',
  shippingOptions: [],
  teamColors: [],
  teamColorsByCode: {},
  colors: [],
  artworkColors: [],
  floodColors: [],
  lightFloodColors: [],
  darkFloodColors: [],
  lightTeamColors: [],
  darkTeamColors: [],
  lightColors: [],
  darkColors: [],
  settings: [],
  coloredStylesDecorationMethods: {},
  applicationSettings: {
    workerCount: {
      id: null,
      code: null,
      value: null,
      dateCreatedUtc: null,
      dateEditedUtc: null,
    },
    artworkTerminalId: {
      id: null,
      code: null,
      value: null,
      dateCreatedUtc: null,
      dateEditedUtc: null,
    },
    schedulingTerminalId: {
      id: null,
      code: null,
      value: null,
      dateCreatedUtc: null,
      dateEditedUtc: null,
    },
  },
  dtgStations: [],
};

export default function(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SL_SERVICES_VERSION:
      return {
        ...state,
        slServicesVersion: action.payload,
      };
    case actionTypes.FULFILLMENT_VERSION:
      return {
        ...state,
        fulfillmentVersion: action.payload,
      };
    case actionTypes.ACCOUNTS_VERSION:
      return {
        ...state,
        accountsVersion: action.payload,
      };
    case actionTypes.EMAIL_VERSION:
      return {
        ...state,
        emailVersion: action.payload,
      };
    case actionTypes.CATALOG_VERSION:
      return {
        ...state,
        catalogVersion: action.payload,
      };
    case actionTypes.FINANCIAL_VERSION:
      return {
        ...state,
        financialVersion: action.payload,
      };
    case actionTypes.ORDERS_VERSION:
      return {
        ...state,
        ordersVersion: action.payload,
      };
    case actionTypes.SHIPPING_OPTIONS:
      return {
        ...state,
        shippingOptions: action.payload,
      };
    case actionTypes.TEAM_COLORS:
      const dict = {};
      for (const color of action.payload) {
        dict[color.code] = color;
      }

      return {
        ...state,
        teamColors: action.payload,
        teamColorsByCode: dict,
        lightTeamColors: action.payload.filter((c) => c.brightness === colorBrightnessEnum.Light),
        darkTeamColors: action.payload.filter((c) => c.brightness === colorBrightnessEnum.Dark),
      };
    case actionTypes.COLORS:
      return {
        ...state,
        colors: action.payload,
        artworkColors: action.payload.filter((c) => c.isArtwork),
        floodColors: action.payload.filter((c) => c.isFlood),
        lightFloodColors: action.payload.filter((c) => c.isFlood && c.brightness === colorBrightnessEnum.Light),
        darkFloodColors: action.payload.filter((c) => c.isFlood && c.brightness === colorBrightnessEnum.Dark),
        lightColors: action.payload.filter((c) => c.brightness === colorBrightnessEnum.Light),
        darkColors: action.payload.filter((c) => c.brightness === colorBrightnessEnum.Dark),
      };
    case actionTypes.APPLICATION_SETTINGS:
      return {
        ...state,
        applicationSettings: action.payload,
      };
    case actionTypes.SETTINGS:
      return {
        ...state,
        settings: action.payload,
      };
    case actionTypes.UPDATE_DTG_STATIONS:
      return {
        ...state,
        dtgStations: action.data,
      };
    case actionTypes.UPDATE_COLORED_STYLES_DECORATION_METHODS:
      return {
        ...state,
        coloredStylesDecorationMethods: action.payload,
      };
    default:
      return state;
  }
}
