/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Catalog Homefield API
 * OpenAPI spec version: v1
 */

export type ColorBrightnessEnum = 'Dark' | 'Light';


export const ColorBrightnessEnum = {
  Dark: 'Dark' as ColorBrightnessEnum,
  Light: 'Light' as ColorBrightnessEnum,
};
