/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Catalog Homefield API
 * OpenAPI spec version: v1
 */
import type { QueriesSortByEnum } from './queriesSortByEnum';
import type { SortDirectionEnum } from './sortDirectionEnum';
import type { ProductAgeEnum } from './productAgeEnum';
import type { ProductGenderEnum } from './productGenderEnum';
import type { DecorationMethodEnum } from './decorationMethodEnum';
import type { SetupStatusEnum } from './setupStatusEnum';
import type { DeletedFilterEnum } from './deletedFilterEnum';

export type GetHomefieldApiStylesParams = { pageNumber?: number; pageSize?: number; sortBy?: QueriesSortByEnum; sortDirection?: SortDirectionEnum; search?: string; brandId?: number[]; categoryId?: number[]; sportId?: number[]; age?: ProductAgeEnum[]; gender?: ProductGenderEnum[]; decorationMethod?: DecorationMethodEnum[]; color?: string[]; size?: string[]; coloredStyleStatus?: SetupStatusEnum; priceMin?: number; priceMax?: number; recommended?: boolean; availableForNewStores?: boolean; customizable?: boolean; websiteVisibility?: boolean; prdOnly?: boolean; vendorQuantityMin?: number; vendorId?: number[]; vendorCode?: string[]; deleted?: DeletedFilterEnum };
