/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */

export type ApplicationVendorOrdersQueriesSortByEnum = 'Id' | 'VendorOrderId' | 'VendorId' | 'Created';


export const ApplicationVendorOrdersQueriesSortByEnum = {
  Id: 'Id' as ApplicationVendorOrdersQueriesSortByEnum,
  VendorOrderId: 'VendorOrderId' as ApplicationVendorOrdersQueriesSortByEnum,
  VendorId: 'VendorId' as ApplicationVendorOrdersQueriesSortByEnum,
  Created: 'Created' as ApplicationVendorOrdersQueriesSortByEnum,
};
