/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */
import type { AccountingActionTypeEnum } from './accountingActionTypeEnum';

export interface UpdateOrderShippingInfoCommand {
  orderNumber?: number;
  name?: string | null;
  email?: string | null;
  addressLine1?: string | null;
  addressLine2?: string | null;
  city?: string | null;
  state?: string | null;
  zip?: string | null;
  country?: string | null;
  method?: string | null;
  phoneNumber?: string | null;
  poBox?: boolean;
  reason?: string | null;
  actionType?: AccountingActionTypeEnum;
  amount?: number;
}
