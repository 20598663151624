/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Catalog Homefield API
 * OpenAPI spec version: v1
 */

export type DeletedFilterEnum = 'ActiveOnly' | 'DeletedOnly' | 'All';


export const DeletedFilterEnum = {
  ActiveOnly: 'ActiveOnly' as DeletedFilterEnum,
  DeletedOnly: 'DeletedOnly' as DeletedFilterEnum,
  All: 'All' as DeletedFilterEnum,
};
