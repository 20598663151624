import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { lockerMgrS3Logos } from '@constants/common';
import Icon from '@sharedComponents/Icons/Icon';

class OrderManagementItemLogos extends Component {
  state = {
    leftItemExists: null,
    rightItemExists: null,
  };

  componentDidMount() {
    this.checkBorderItems();
  }

  componentDidUpdate(prevProps) {
    const {
      logos,
      selectedLogo,
      replaceOnCoreFlag,
    } = this.props;

    const logo = selectedLogo ? selectedLogo : logos[0];
    const prevLogo = prevProps.selectedLogo ? prevProps.selectedLogo : prevProps.logos[0];

    const logoId = (replaceOnCoreFlag && selectedLogo)
      ? logo?.logoId
      : logo?.id;
    const prevLogoId = (replaceOnCoreFlag && selectedLogo)
      ? prevLogo?.logoId
      : prevLogo?.id;
    const currentLogoIndex = (replaceOnCoreFlag && selectedLogo)
      ? logo && logos.findIndex((x) => x.id === logo.logoId)
      : logo && logos.findIndex((x) => x.id === logo.id);
    const previousLogoIndex = (replaceOnCoreFlag && selectedLogo)
      ? prevLogo && prevProps.logos.findIndex((x) => x.id === prevLogo.logoId)
      : prevLogo && prevProps.logos.findIndex((x) => x.id === prevLogo.id);

    if (!(logoId && (logoId !== prevLogoId || currentLogoIndex !== previousLogoIndex))) return;

    this.checkBorderItems();
  }

  getLeftItemIndex = () => {
    const {
      logos,
      selectedLogo,
      replaceOnCoreFlag,
    } = this.props;

    const logo = selectedLogo ? selectedLogo : logos[0];

    const index = replaceOnCoreFlag
      ? logos.findIndex((l) => logo.logoId === l.id) - 1
      : logos.findIndex((l) => logo.id === l.id) - 1;

    return index;
  };

  getRightItemIndex = () => {
    const {
      logos,
      selectedLogo,
      replaceOnCoreFlag,
    } = this.props;

    const logo = selectedLogo ? selectedLogo : logos[0];

    const index = replaceOnCoreFlag
      ? logos.findIndex((l) => logo.logoId === l.id) + 1
      : logos.findIndex((l) => logo.id === l.id) + 1;

    return index;
  };

  checkBorderItems = () => {
    const { logos } = this.props;
    const leftItemIndex = this.getLeftItemIndex();
    const rightItemIndex = this.getRightItemIndex();

    const leftItemExists = leftItemIndex >= 0;
    const rightItemExists = rightItemIndex > 0 && rightItemIndex < logos.length;

    this.setState({
      leftItemExists,
      rightItemExists,
    });
  };

  handleLeftClick = () => {
    const {
      logos,
      selectLogo,
      selectedItem,
    } = this.props;

    const index = this.getLeftItemIndex();

    if (index < 0) return;

    selectLogo(logos[index], selectedItem);
  };

  handleRightClick = () => {
    const {
      logos,
      selectLogo,
      selectedItem,
    } = this.props;

    const index = this.getRightItemIndex();

    if (index < 0 || index >= logos.length) return;

    selectLogo(logos[index], selectedItem);
  };

  render() {
    const {
      logos,
      selectedLogo,
      selectedItem,
      itemId,
      replaceOnCoreFlag,
    } = this.props;

    const {
      leftItemExists,
      rightItemExists,
    } = this.state;

    const logo = logos[0];
    let currentLogoPosition = logos.findIndex((l) => l.id === logo.id) + 1 || 0;
    let logoImage = logo?.image;

    if (selectedItem && selectedItem.id === itemId && selectedLogo) {
      logoImage = selectedLogo.image;
      currentLogoPosition = replaceOnCoreFlag
        ? logos.findIndex((l) => l.id === selectedLogo.logoId) + 1
        : logos.findIndex((l) => l.id === selectedLogo.id) + 1;
    }

    const isCurrentItemSelected = selectedItem && (selectedItem.id === itemId);

    return (
      <div className='order-item__logo-container'>
        <div className='order-item__logos'>
          <div className={`sticky-header__control ${!leftItemExists ? 'sticky-header__control--hidden' : (!isCurrentItemSelected ? 'sticky-header__control--disabled' : '')}`}>
            <Icon
              materialIcon={'chevron_left'}
              onClick={this.handleLeftClick}
            />
          </div>
          <div
            className='order-item__logos--logo'
            style={{ backgroundImage: `url("${lockerMgrS3Logos}/${logoImage}")` }}
          />
          <div className={`sticky-header__control ${!rightItemExists ? 'sticky-header__control--hidden' : (!isCurrentItemSelected ? 'sticky-header__control--disabled' : '')}`}>
            <Icon
              materialIcon={'chevron_right'}
              onClick={this.handleRightClick}
            />
          </div>
        </div>
        <div className='order-item__logo-total'>
          <span>{`${currentLogoPosition} / ${logos.length || 0}`}</span>
        </div>
      </div>
    );
  }
}

OrderManagementItemLogos.propTypes = {
  logos: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectLogo: PropTypes.func.isRequired,
  selectedLogo: PropTypes.object,
  selectedItem: PropTypes.object,
  itemId: PropTypes.number.isRequired,
};

export default OrderManagementItemLogos;
