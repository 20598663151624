/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Financials Homefield API
 * OpenAPI spec version: v1
 */

export type GetVoucherOrderPagedListQuerySortByEnum = 'Id' | 'Created' | 'Updated' | 'PaymentStatus' | 'StartDate' | 'EndDate';


export const GetVoucherOrderPagedListQuerySortByEnum = {
  Id: 'Id' as GetVoucherOrderPagedListQuerySortByEnum,
  Created: 'Created' as GetVoucherOrderPagedListQuerySortByEnum,
  Updated: 'Updated' as GetVoucherOrderPagedListQuerySortByEnum,
  PaymentStatus: 'PaymentStatus' as GetVoucherOrderPagedListQuerySortByEnum,
  StartDate: 'StartDate' as GetVoucherOrderPagedListQuerySortByEnum,
  EndDate: 'EndDate' as GetVoucherOrderPagedListQuerySortByEnum,
};
