/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Catalog Homefield API
 * OpenAPI spec version: v1
 */
import type { DecorationSizeEnum } from './decorationSizeEnum';

export interface DtgConfigurationDecorationLocationArtworkSizeDto {
  decorationLocationId?: number;
  decorationSize?: DecorationSizeEnum;
  rotation?: number;
  posX?: number;
  posY?: number;
  id?: number;
  uuid?: string | null;
  created?: string | null;
  updated?: string | null;
  deleted?: string | null;
  decorationLocationArtworkSizeId?: number;
  printerOverrideWidth?: number | null;
  printerOverrideHeight?: number | null;
  decorationLocationCode?: string | null;
  defaultWidth?: number | null;
  defaultHeight?: number | null;
}
