import React, { useCallback } from 'react';
import { Box, Grid, Menu, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@material-ui/core';
import { lockerMgrS3Logos } from '@constants/common';
import grid from '@assets/transparent.svg';

const ChooseCustomItemLogoLocationsTab = ({
  selectedLogos,
  selectedItems,
  handleNewLogoSelected,
  selectedNewLogos,
  activeLogoSelectionId,
  setActiveLogoSelectionId,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = useCallback((activeLogoSelectionId) => (event) => {
    setAnchorEl(event.currentTarget);
    setActiveLogoSelectionId(activeLogoSelectionId);
  }, [setActiveLogoSelectionId]);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
    setActiveLogoSelectionId(null);
  }, [setActiveLogoSelectionId]);

  const handleLogoSelect = useCallback((currentLogoId, selectedLogo) => () => {
    handleNewLogoSelected(currentLogoId, selectedLogo);
    handleClose();
  }, [handleClose, handleNewLogoSelected]);

  if (selectedItems.length === 0 || selectedLogos.length === 0) {
    return (
      <Box display='flex' mt={30} mb={30} justifyContent='center' alignItems='center' height='100%'>
        <Typography variant='h6'>No logos or items selected, please select logos and items.</Typography>
      </Box>
    );
  }

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Item</TableCell>
            <TableCell>Logos To Assign</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {selectedItems.map((item) => (
            <TableRow key={`${item.id}`}>
              <TableCell>
                <Box display='flex'>
                  <img src={item.imageFront} height={150} width={150} style={{ marginRight: 8 }} />
                  <Box ml={2}>
                    {item.customName && (
                      <Box>
                        <Typography variant='h6'>{item.customName}</Typography>
                      </Box>
                    )}
                    <Box>
                      <Typography variant='body2' color='textSecondary'>{item.coloredStyle.code}</Typography>
                    </Box>
                  </Box>
                </Box>
              </TableCell>
              {item.logos?.map((ll) => (
                <TableCell key={ll.id}>
                  <Grid container={true} spacing={2}>
                    <Grid item={true} xs={6}>
                      <Box display='flex' alignItems='center' flexDirection='column'>
                        <Box>
                          <Typography variant='h6'>{ll.decorationLocation}</Typography>
                        </Box>
                        <Box style={{ backgroundImage: `url(${grid})` }}>
                          <Tooltip title={ll?.logo?.image}>
                            <img
                              src={`${lockerMgrS3Logos}/${ll?.logo?.image}`}
                              height={150}
                              width={150}
                            />
                          </Tooltip>
                        </Box>

                        <Box>
                          <Typography variant='h6'>Current Logo</Typography>
                        </Box>
                      </Box>
                    </Grid>

                    <Grid item={true} xs={6} style={{ display: 'flex', alignItems: 'stretch' }}>
                      <Box display='flex' alignItems='center' flexDirection='column' justifyContent='space-between'>
                        <Box>
                          <Typography variant='h6'>{ll.decorationLocation}</Typography>
                        </Box>
                        <Box
                          onClick={handleClick(ll.id)}
                          style={{
                            width: '150px',
                            minHeight: '150px',
                            border: '2px dashed #ccc',
                            cursor: 'pointer',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          {selectedNewLogos[ll.id]
                            ? (
                              <Box
                                display='flex'
                                alignItems='center'
                                justifyContent='center'
                                style={{
                                  backgroundImage: `url(${grid})`,
                                }}
                              >
                                <Tooltip title={selectedNewLogos[ll.id].image}>
                                  <img
                                    src={`${lockerMgrS3Logos}/${selectedNewLogos[ll.id].image}`}
                                    width={150}
                                  />
                                </Tooltip>
                              </Box>
                            )
                            : (
                              <Box
                                display='flex'
                                alignItems='stretch'
                                justifyContent='stretch'
                              >
                                <Typography variant='body2' align='center' color='textSecondary'>Select New Logo</Typography>
                              </Box>
                            )}
                        </Box>

                        <Box>
                          <Typography variant='h6'>New Logo</Typography>
                        </Box>
                      </Box>
                      <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl) && activeLogoSelectionId === ll.id}
                        onClose={handleClose}
                      >
                        {selectedLogos.map((logo) => (
                          <MenuItem key={logo.id} onClick={handleLogoSelect(ll.id, logo)}>
                            <Box
                              display='flex'
                              alignItems='center'
                              style={{
                                backgroundImage: `url(${grid})`,
                              }}
                            >
                              <Tooltip title={logo.image}>
                                <img
                                  src={`${lockerMgrS3Logos}/${logo.image}`}
                                  width={150}
                                  style={{ marginRight: 8 }}
                                />
                              </Tooltip>
                            </Box>
                          </MenuItem>
                        ))}
                      </Menu>
                    </Grid>
                  </Grid>
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ChooseCustomItemLogoLocationsTab;
