/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */

export type DtgTransmissionStatusEnum = 'Undefined' | 'Sending' | 'Riping' | 'Done' | 'Canceled' | 'Failed';


export const DtgTransmissionStatusEnum = {
  Undefined: 'Undefined' as DtgTransmissionStatusEnum,
  Sending: 'Sending' as DtgTransmissionStatusEnum,
  Riping: 'Riping' as DtgTransmissionStatusEnum,
  Done: 'Done' as DtgTransmissionStatusEnum,
  Canceled: 'Canceled' as DtgTransmissionStatusEnum,
  Failed: 'Failed' as DtgTransmissionStatusEnum,
};
