/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */
import type { LockerDto } from './lockerDto';
import type { ArtworkTaskStatusEnumStatusModel } from './artworkTaskStatusEnumStatusModel';
import type { ArtworkTaskArchivationDto } from './artworkTaskArchivationDto';
import type { ArtworkFileDto } from './artworkFileDto';
import type { OrderDto } from './orderDto';
import type { NoteDto } from './noteDto';
import type { ChangeLogItemDto } from './changeLogItemDto';

export interface ArtworkTaskItemDetailDto {
  id?: number | null;
  created?: string | null;
  locker?: LockerDto;
  status?: ArtworkTaskStatusEnumStatusModel;
  logoId?: number;
  logoUrl?: string | null;
  previewUrl?: string | null;
  strippedPreviewUrl?: string | null;
  readyForQC?: boolean;
  decorationSizeHeight?: number;
  decorationSizeWidth?: number;
  decorationMethod?: string | null;
  assignedOn?: string | null;
  assignedBy?: string | null;
  assignedTo?: string | null;
  assignedToName?: string | null;
  artworkTaskArchivation?: ArtworkTaskArchivationDto;
  productionReadyOn?: string | null;
  productionReadyBy?: string | null;
  shipDate?: string | null;
  isCutFile?: boolean;
  cutFileColor?: string | null;
  fileHeight?: number | null;
  fileWidth?: number | null;
  fileSwatchColors?: string[] | null;
  strippedFileHeight?: number | null;
  strippedFileWidth?: number | null;
  strippedFileSwatchColors?: string[] | null;
  stitchCount?: number | null;
  artworkFiles?: ArtworkFileDto[] | null;
  isCanceled?: boolean;
  orders?: OrderDto[] | null;
  notes?: NoteDto[] | null;
  changeLogs?: ChangeLogItemDto[] | null;
  hasRequiredItems?: boolean;
}
