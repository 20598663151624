/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */

export interface RushModel {
  created?: string;
  shipDate?: string;
  rushShipDate?: string;
  note?: string | null;
  reason?: string | null;
  user?: string | null;
}
