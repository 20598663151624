/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Accounts Homefield API
 * OpenAPI spec version: 1.0
 */
import type { StagingJobErrorDetailsDto } from './stagingJobErrorDetailsDto';

export interface StagingJobErrorDetailsDtoApiResponse {
  success: boolean;
  message?: string | null;
  result?: StagingJobErrorDetailsDto;
}
