/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Accounts Homefield API
 * OpenAPI spec version: 2.0
 */
import type { PlanogramType } from './planogramType';

export type PostHomefieldApiV2MerchandisingBody = {
  name?: string;
  startDate?: string;
  endDate?: string;
  type?: PlanogramType;
  sourceId?: number;
  createdBy?: string;
  updatedBy?: string;
  targetsCsv?: Blob;
};
