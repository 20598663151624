import React from 'react';
import PropTypes from 'prop-types';
import PrintSheetItemPersonalization from './PrintSheetItemPersonalization';
import PrintSheetItemPersonalizationIndicator from './PrintSheetItemPersonalizationIndicator';

const PrintSheetItemPersonalizations = React.memo(({
  personalizations,
  orderDeleted,
  colorsDictionary,
}) => {
  const pers = {};
  const groups = {};

  const compare = (a, b) => {
    if (a.decorationLocation < b.decorationLocation) {
      return -1;
    }
    if (a.decorationLocation > b.decorationLocation) {
      return 1;
    }
    if (a.personalizationColorId < b.personalizationColorId) {
      return -1;
    }
    if (a.personalizationColorId > b.personalizationColorId) {
      return 1;
    }
    if (a.outlineColorId < b.outlineColorId) {
      return -1;
    }
    if (a.outlineColorId > b.outlineColorId) {
      return 1;
    }
    if (a.label < b.label) {
      return -1;
    }
    if (a.label > b.label) {
      return 1;
    }

    return 0;
  };

  personalizations.sort(compare);

  for (const p of personalizations) {
    const key = JSON.stringify(
      {
        colorId: p.colorId,
        outlineColorId: p.outlineColorId,
        location: p.decorationLocation,
      }
    );

    if (!pers[key]) {
      pers[key] = [];
    }

    pers[key].push(p);
  }

  const enums = Object.keys(pers);

  for (const e of enums) {
    const enumPers = pers[e];
    groups[e] = [];
    for (let j = 0; j < enumPers.length; j += 3) {
      groups[e].push(enumPers.slice(j, j + 3));
    }
  }

  const items = {};

  for (const e of enums) {
    items[e] = groups[e].map((item, key) => (
      <PrintSheetItemPersonalization
        orderDeleted={orderDeleted}
        keyIndex={key}
        key={key}
        personalizations={item}
      />
    ));
  }

  const components = {};

  for (let i = 0; i < enums.length; i += 1) {
    const key = JSON.parse(enums[i]);
    const color = colorsDictionary[key.colorId];
    const outlineColor = colorsDictionary[key.outlineColorId];

    components[enums[i]] = (
      <div
        key={i}
        className='sheet__description'
      >
        <div className='flex__row'>
          <h3>{key.location}</h3>
          <PrintSheetItemPersonalizationIndicator
            outlineColor={outlineColor}
            color={color}
          />
        </div>
        <ul>
          {items[enums[i]]}
        </ul>
      </div>
    );
  }

  return (
    <div className='sheet__description'>
      {Object.values(components)}
    </div>
  );
});

PrintSheetItemPersonalizations.propTypes = {
  personalizations: PropTypes.array,
  orderDeleted: PropTypes.bool,
  colorsDictionary: PropTypes.objectOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    dtgCode: PropTypes.string,
    hexValue: PropTypes.string,
    cmykValue: PropTypes.string.isRequired,
    dtgCmykValue: PropTypes.string.isRequired,
    brightness: PropTypes.string.isRequired,
    threadValue: PropTypes.string,
  })).isRequired,
};

export default PrintSheetItemPersonalizations;
