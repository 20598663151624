import {
  fulfillmentApi,
  slServicesApi,
  ordersApi,
} from '../../sqdApis';
import orderManagementUrls from '@constants/sqdApiUrls/orderManagementUrls';
import {
  postHomefieldApiOrdermanagementOrderssendrefundconfirmation,
  postHomefieldApiOrdermanagementOrderitemsreplace,
  postHomefieldApiOrdermanagementOrderitemscancel as cancelOrderItemCall,
  putHomefieldApiOrdermanagementOrdersaccountingsubmitOrderNumber as submitOrderToAccounting,
} from '@api/fulfillment/order-management';
import {
  postHomefieldApiOrdermanagementOrdersrefundwithcancel as refundOrderWithCancelCall,
  putHomefieldApiOrderitemsreplacepricecalculation,
  postHomefieldApiOrdermanagementOrdersitemsrefund as refundOrderItemCall,
} from '@api/orders/orders';
import { accountingActionTypeEnum } from '@constants/enums/orderEnums';
import {
  makeApiCall, makeApiCallWithErrorModal,
} from '@util/apiHelper';
import couponsUrls from '@constants/sqdApiUrls/couponsUrls';
import { parseDateFromLocalTimezone } from '@util/dateHandler';
import { ServiceTypeEnum } from '@api/fulfillment/models';
import {
  beginBlockingRequest, endBlockingRequest,
} from '@redux/api/actions';
import store from 'store';

export const submitOrder = async (orderNumber, serviceType = ServiceTypeEnum.GreatPlains) => {
  const call = submitOrderToAccounting(orderNumber, { serviceType });

  const res = await makeApiCallWithErrorModal(call);

  return res;
};

export const submitOrderNotes = async (orderNumber, note) => {
  const formData = new FormData();
  formData.append('content', note.text);
  if (note.file) {
    for (const f of note.file) {
      formData.append('file[]', f, f.name);
    }
  }

  const call = fulfillmentApi.post(orderManagementUrls.notes(orderNumber), {
    body: formData,
  });

  const res = await makeApiCallWithErrorModal(call);

  return res;
};

export const sendOrderConfirmationMail = async (orderNumber) => {
  const call = fulfillmentApi.post(orderManagementUrls.orderConfirmation(orderNumber));
  const res = await makeApiCallWithErrorModal(call);

  return res;
};

export const changeArtwork = async (ids, changeModel) => {
  const model = {
    ids,
    decorationLocation: changeModel.decorationLocation,
    logoId: changeModel.logoId,
  };

  const call = fulfillmentApi.put(orderManagementUrls.changeArtwork, {
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(model),
  });

  const res = await makeApiCallWithErrorModal(call);

  return res;
};

export const createPersonalization = async (
  orderNumber,
  personalization,
  orderItemIds
) => {
  const model = {
    orderNumber,
    text: personalization.text,
    number: personalization.number,
    decorationLocation: personalization.decorationLocation,
    colorId: personalization.colorId,
    personalizationBasePrice: personalization.personalizationBasePrice,
    orderItemIds,
  };

  const call = fulfillmentApi.post(orderManagementUrls.personalizationAdd, {
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(model),
  });

  const res = await makeApiCallWithErrorModal(call);

  return res;
};

export const changeDecorationMethod = async (ids, decorationMethod) => {
  const model = {
    orderItemIds: ids,
    decorationMethod,
  };

  const call = fulfillmentApi.post(orderManagementUrls.changeDecorationMethod, {
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(model),
  });

  const res = await makeApiCallWithErrorModal(call);

  return res;
};

export const calculateCreatePersonalizationPrice = async (
  orderNumber,
  personalization,
  orderItemIds
) => {
  const model = {
    orderNumber,
    text: personalization.text,
    number: personalization.number,
    decorationLocation: personalization.decorationLocation,
    colorId: personalization.colorId,
    personalizationBasePrice: personalization.personalizationBasePrice,
    orderItemIds,
  };

  const call = fulfillmentApi.post(orderManagementUrls.personalizationAddPriceCalculation, {
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(model),
  });

  const res = await makeApiCallWithErrorModal(call);

  if (res?.result) {
    return res.result;
  }

  return res;
};

export const updatePersonalization = async (
  orderNumber,
  personalization,
  personalizationIds
) => {
  const model = {
    orderNumber,
    text: personalization.text,
    number: personalization.number,
    decorationLocation: personalization.decorationLocation,
    colorId: personalization.colorId,
    personalizationBasePrice: personalization.personalizationBasePrice,
    personalizationIds,
  };

  const call = fulfillmentApi.put(orderManagementUrls.personalizationEdit, {
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(model),
  });

  const res = await makeApiCallWithErrorModal(call);

  return res;
};

export const calculateEditPersonalizationPrice = async (
  orderNumber,
  personalization,
  personalizationIds
) => {
  const model = {
    orderNumber,
    text: personalization.text,
    number: personalization.number,
    decorationLocation: personalization.decorationLocation,
    colorId: personalization.colorId,
    personalizationBasePrice: personalization.personalizationBasePrice,
    personalizationIds,
  };

  const call = fulfillmentApi.post(orderManagementUrls.personalizationEditPriceCalculation, {
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(model),
  });

  const res = await makeApiCallWithErrorModal(call);

  if (res?.result) {
    return res.result;
  }

  return res;
};

export const removePersonalization = async (
  ids,
  orderNumber,
  paymentType,
  refundAmount
) => {
  const model = {
    orderNumber,
    reason: '',
    ids,
    refundAmount: paymentType === accountingActionTypeEnum.PersonalizationRefund ? refundAmount : null,
  };

  const call = fulfillmentApi.delete(orderManagementUrls.personalizationRemove, {
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(model),
  });

  const res = await makeApiCallWithErrorModal(call);

  return res;
};

export const calculateRemovePersonalizationPrice = async (
  ids,
  orderNumber,
  paymentType,
  refundAmount
) => {
  const model = {
    orderNumber,
    reason: '',
    ids,
    refundAmount: paymentType === accountingActionTypeEnum.PersonalizationRefund ? refundAmount : null,
  };

  const call = fulfillmentApi.post(orderManagementUrls.personalizationRemovePriceCalculation, {
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(model),
  });

  const res = await makeApiCallWithErrorModal(call);

  if (res?.result) {
    return res.result;
  }

  return res;
};

export const refundOrderWithCancel = async (
  orderNumber,
  reason,
  actionType,
  amount,
  isCancel,
  orderExistsOnCore
) => {
  const model = orderExistsOnCore
    ? {
      reason: reason || null,
      orderId: orderNumber,
      amount: amount && parseFloat(amount),
      actionType,
      isCancel,
    } : {
      reason: reason || null,
      orderNumber,
      amount: amount && parseFloat(amount),
      actionType,
      isCancel,
    };

  let call;

  if (orderExistsOnCore) {
    call = refundOrderWithCancelCall(model);
  } else {
    call = fulfillmentApi.post(orderManagementUrls.refundOrderWithCancel, {
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(model),
    });
  }

  const res = await makeApiCallWithErrorModal(call);

  return res;
};

export const refundOrderItemWithCancel = async (
  orderNumber,
  orderItemIds,
  reason,
  actionType,
  amount,
  isCancel
) => {
  const model = {
    reason: reason || null,
    orderNumber,
    amount: amount && parseFloat(amount),
    actionType,
    orderItemIds,
    isCancel,
  };
  const call = fulfillmentApi.post(orderManagementUrls.refundOrderItemWithCancel,
    {
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(model),
    });

  const res = await makeApiCallWithErrorModal(call);

  return res;
};

export const refundOrderItem = async (
  orderNumber,
  orderItemCoreId,
  reason,
  actionType,
  amount
) => {
  const model = {
    reason: reason || null,
    orderId: orderNumber,
    amount: amount && parseFloat(amount),
    actionType,
    orderItemId: orderItemCoreId,
  };

  const call = refundOrderItemCall(model);

  const res = await makeApiCall(call);

  return res;
};

export const cancelOrderItem = async (
  orderNumber,
  orderItemIds,
  reason,
  actionType
) => {
  const model = {
    reason: reason || null,
    orderNumber,
    actionType,
    orderItemIds,
  };
  const call = cancelOrderItemCall(model);

  const res = await makeApiCall(call);

  return res;
};

export const reserveCoupon = async (
  couponCode
) => {
  const call = slServicesApi.put(couponsUrls.redeemCoupon(couponCode), {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  const res = await makeApiCallWithErrorModal(call);

  return res;
};

export const removeCouponReservation = async (
  couponCode
) => {
  const call = slServicesApi.put(couponsUrls.unredeemCoupon(couponCode), {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  const res = await makeApiCallWithErrorModal(call);

  return res;
};

export const applyCoupon = async (
  orderNumber,
  couponCode,
  couponType,
  couponValue,
  couponAppliesToShipping
) => {
  const model = {
    reason: 'Apply coupon code',
    orderNumber,
    couponCode,
    couponType,
    couponValue,
    couponAppliesToShipping,
  };

  const call = fulfillmentApi.post(orderManagementUrls.applyCouponToOrder, {
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(model),
  });

  const res = await makeApiCallWithErrorModal(call);

  return res;
};

export const rushOrder = async (orderNumber, reason, note, shipDate) => {
  const localShipDate = parseDateFromLocalTimezone(shipDate);

  const model = {
    orderNumber,
    reason,
    note: note || null,
    shipDate: localShipDate,
  };

  const call = fulfillmentApi.put(orderManagementUrls.rushOrder, {
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(model),
  });
  const res = await makeApiCallWithErrorModal(call);

  return res;
};

export const pauseOrder = async (orderNumber) => {
  const model = {
    reason: '',
  };

  const call = fulfillmentApi.put(orderManagementUrls.pauseOrder(orderNumber), {
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(model),
  });

  const res = await makeApiCallWithErrorModal(call);

  return res;
};

export const unpauseOrder = async (orderNumber) => {
  const model = {
    reason: '',
  };

  const call = fulfillmentApi.put(orderManagementUrls.unpauseOrder(orderNumber), {
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(model),
  });

  const res = await makeApiCallWithErrorModal(call);

  return res;
};

export const calculateApplyCouponPrice = async (
  orderNumber,
  couponCode,
  couponType,
  couponValue,
  couponAppliesToShipping
) => {
  const model = {
    reason: 'Apply coupon code',
    orderNumber,
    couponCode,
    couponType,
    couponValue,
    couponAppliesToShipping,
  };

  const call = fulfillmentApi.post(orderManagementUrls.applyCouponToOrderPriceCalculation, {
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(model),
  });

  const res = await makeApiCallWithErrorModal(call);

  if (res?.result) {
    return res.result;
  }

  return res;
};

export const replaceOrderItem = async (
  orderNumber,
  orderLineItemIds,
  coreId,
  logo,
  form,
  replaceOnCoreFlag,
  quantity,
  variationId
) => {
  const model = !replaceOnCoreFlag
    ? {
      orderNumber,
      sku: form.sku,
      ids: orderLineItemIds,
      logoStorefrontId: logo?.id,
      basePrice: form.basePrice,
    } : {
      orderNumber,
      itemToReplaceId: coreId,
      quantity: form.quantity,
      sku: form.sku,
      logoStorefrontId: logo?.id,
      basePrice: form.basePrice,
      variationId,
    };

  const call = replaceOnCoreFlag
    ? postHomefieldApiOrdermanagementOrderitemsreplace(JSON.stringify(model))
    : fulfillmentApi.put(orderManagementUrls.orderItemReplace, {
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(model),
    });

  const res = await makeApiCallWithErrorModal(call);

  return res;
};

export const calculateReplaceItemPrice = async (
  orderNumber,
  orderLineItemIds,
  coreId,
  logo,
  form,
  replaceOnCoreFlag,
  variationId
) => {
  const model = !replaceOnCoreFlag
    ? {
      orderNumber,
      sku: form.sku,
      ids: orderLineItemIds,
      logoStorefrontId: logo?.id,
      basePrice: form.basePrice,
    } : {
      orderNumber,
      itemToReplaceId: coreId,
      quantity: form.quantity,
      sku: form.sku,
      logoStorefrontId: logo?.id,
      basePrice: form.basePrice,
      variationId,
    };

  const call = replaceOnCoreFlag
    ? putHomefieldApiOrderitemsreplacepricecalculation(JSON.stringify(model))
    : fulfillmentApi.post(orderManagementUrls.orderItemReplacePriceCalculation, {
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(model),
    });

  const res = await makeApiCallWithErrorModal(call);

  if (res?.result) {
    return res.result;
  }

  return res;
};

export const updateShippingInfo = async (orderNumber, shippingInfo, updateShippingOnCore) => {
  const bodyCore = JSON.stringify({
    orderNumber,
    shippingInfo: {
      name: shippingInfo.name,
      addressLine1: shippingInfo.addressLine1,
      addressLine2: shippingInfo.addressLine2,
      email: shippingInfo.email,
      city: shippingInfo.city,
      state: shippingInfo.state,
      zip: shippingInfo.zip,
      poBox: shippingInfo.poBox,
      country: shippingInfo.country,
      phoneNumber: shippingInfo.phoneNumber?.replaceAll(/[\D]+/g, ''),
      method: shippingInfo.method,
    },
    reason: 'Shipping info update',
    actionType: shippingInfo.actionType,
    amount: shippingInfo.actionType === accountingActionTypeEnum.ShippingRefund
      ? shippingInfo.refundAmount
      : shippingInfo.actionType === accountingActionTypeEnum.ShippingCharge
        ? shippingInfo.chargeAmount
        : 0,
  });

  const bodyFF = JSON.stringify({
    orderNumber,
    name: shippingInfo.name,
    addressLine1: shippingInfo.addressLine1,
    addressLine2: shippingInfo.addressLine2,
    email: shippingInfo.email,
    city: shippingInfo.city,
    state: shippingInfo.state,
    zip: shippingInfo.zip,
    poBox: shippingInfo.poBox,
    country: shippingInfo.country,
    phoneNumber: shippingInfo.phoneNumber,
    method: shippingInfo.method,
    reason: 'Shipping info update',
    actionType: shippingInfo.actionType,
    amount: shippingInfo.actionType === accountingActionTypeEnum.ShippingRefund
      ? shippingInfo.refundAmount
      : shippingInfo.actionType === accountingActionTypeEnum.ShippingCharge
        ? shippingInfo.chargeAmount
        : 0,
  });

  const call = updateShippingOnCore
    ? ordersApi.put(orderManagementUrls.shippingInfo, {
      headers: {
        'Content-Type': 'application/json',
      },
      body: bodyCore,
    }) : fulfillmentApi.put(orderManagementUrls.shippingInfo, {
      headers: {
        'Content-Type': 'application/json',
      },
      body: bodyFF,
    });

  // We dispatch blocking request here since PUT requests do not seem to trigger it
  store.dispatch(beginBlockingRequest());
  const res = await makeApiCallWithErrorModal(call);
  store.dispatch(endBlockingRequest());

  return res;
};

export const calculateUpdateShippingInfoPrice = async (orderNumber, shippingInfo, updateShippingOnCore) => {
  let amount = 0;
  switch (shippingInfo.actionType) {
    case accountingActionTypeEnum.ShippingRefund:
      amount = shippingInfo.refundAmount;
      break;
    case accountingActionTypeEnum.ShippingCharge:
      amount = shippingInfo.chargeAmount;
      break;
    case accountingActionTypeEnum.ShippingFullRefund:
      amount = shippingInfo.pricePaid;
      break;
  }

  const bodyCore = JSON.stringify({
    orderNumber,
    shippingInfo: {
      name: shippingInfo.name,
      addressLine1: shippingInfo.addressLine1,
      addressLine2: shippingInfo.addressLine2,
      email: shippingInfo.email,
      city: shippingInfo.city,
      state: shippingInfo.state,
      zip: shippingInfo.zip,
      poBox: shippingInfo.poBox,
      country: shippingInfo.country,
      phoneNumber: shippingInfo.phoneNumber,
      method: shippingInfo.method,
    },
    reason: 'Shipping info update',
    actionType: shippingInfo.actionType,
    amount,
  });

  const bodyFF = JSON.stringify({
    orderNumber,
    name: shippingInfo.name,
    addressLine1: shippingInfo.addressLine1,
    addressLine2: shippingInfo.addressLine2,
    email: shippingInfo.email,
    city: shippingInfo.city,
    state: shippingInfo.state,
    zip: shippingInfo.zip,
    poBox: shippingInfo.poBox,
    country: shippingInfo.country,
    phoneNumber: shippingInfo.phoneNumber,
    method: shippingInfo.method,
    reason: 'Shipping info update',
    actionType: shippingInfo.actionType,
    amount,
  });

  const call = updateShippingOnCore
    ? ordersApi.put(
      orderManagementUrls.shippingInfoPriceCalculation, {
        headers: {
          'Content-Type': 'application/json',
        },
        body: bodyCore,
      }
    ) : fulfillmentApi.post(orderManagementUrls.shippingInfoPriceCalculation, {
      headers: {
        'Content-Type': 'application/json',
      },
      body: bodyFF,
    });

  const res = await makeApiCallWithErrorModal(call);

  if (res?.result) {
    return res.result;
  }

  return res;
};

export const sendRefundConfirmation = async (orderNumber, recipientEmail) => {
  const body = JSON.stringify({
    orderNumber,
    recipientEmail,
  });

  const call = postHomefieldApiOrdermanagementOrderssendrefundconfirmation(body);

  const res = await makeApiCallWithErrorModal(call);

  return res;
};
