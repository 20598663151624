/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Catalog Homefield API
 * OpenAPI spec version: v1
 */
import type { VendorTimelineDto } from './vendorTimelineDto';

export interface UpdateVendorTimelinesCommand {
  vendorCode?: string | null;
  vendorTimelines?: VendorTimelineDto[] | null;
}
