/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Financials Homefield API
 * OpenAPI spec version: v1
 */

export interface InstancePreview {
  discountAmount?: number;
  totalAmount?: number;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  rosterTeamMemberId?: number;
}
