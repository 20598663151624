import { useEffect, useMemo, useRef } from 'react';
import { useLocation } from 'react-router-dom';

export const usePrevious = <T>(value: T) => {
  const ref = useRef<T>(value);
  useEffect(() => {
    ref.current = value;
  });

  return ref.current;
};

export const useHasChanged = <T>(val: T) => {
  const prevVal = usePrevious<T>(val);

  return prevVal !== val;
};

export const useLocationUrlSearchParams = () => {
  const { search } = useLocation();
  const params = useMemo(() => {
    const urlparams = new URLSearchParams(search);
    const retval: Record<string, string> = {};
    for (const [key, value] of urlparams) {
      retval[key] = value;
    }

    return retval;
  }, [search]);

  return params as Readonly<Record<string, string>>;
};
