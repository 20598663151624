/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */
import type { DecorationTypeEnum } from './decorationTypeEnum';

export interface DecorationLocationDto {
  schedulingQueueItemId?: number;
  decorationLocationType?: DecorationTypeEnum;
  decorationLocation?: string | null;
  decorationLocationHeight?: number | null;
  decorationLocationWidth?: number | null;
  personalizationColorId?: number | null;
  personalizationOutlineColorId?: number | null;
  logoId?: number | null;
  stitchCount?: number | null;
}
