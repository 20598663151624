/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Catalog Homefield API
 * OpenAPI spec version: v1
 */

export interface ProductVendorMappingDto {
  id?: number;
  uuid?: string | null;
  created?: string | null;
  updated?: string | null;
  deleted?: string | null;
  sku?: string | null;
  readonly vendorId?: string | null;
  vendorCode?: string | null;
  rbiUpc?: string | null;
  esiUpcNumber?: string | null;
  vendorItemNumber?: string | null;
}
