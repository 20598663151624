/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Orders Homefield API
 * OpenAPI spec version: v1
 */

export interface ItemPricingSetup {
  basePrice?: number;
  fundraisingAmount?: number;
  salesChannelFundraisingPercent?: number;
  salesChannelDiscountPercent?: number;
  styleDiscountAmount?: number;
}
