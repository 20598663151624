import React from 'react';
import { Helmet } from 'react-helmet';

interface OwnProps {
  text: string;
  id?: number;
}

type Props = OwnProps;

const TextHeaderTitle = React.memo(({ text, id }: Props) => {
  const title = id ? `${text} - ${id}` : text;

  return (
    <div className='bar__long'>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className='header__bar-content'>
        <h2 className='header__bar-title'>{text}</h2>
      </div>
    </div>
  );
});

export default TextHeaderTitle;
