import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { decorationMethodEnum } from '@constants/enums/decorationEnums';
import { fetchLogo } from '@redux/productionAssembly/actions';
import ProductionAssemblyLogoCell from './ProductionAssemblyLogoCell';
import ProductionAssemblyTextCell from './ProductionAssemblyTextCell';

class ProductionAssemblyLogoOrTextCell extends PureComponent {
  componentDidMount() {
    const {
      item,
      logoColors,
      dispatch,
    } = this.props;

    if (
      item?.logoId
      && item.decorationMethod === decorationMethodEnum.EMB
      && !logoColors[item.logoId]
    ) {
      dispatch(fetchLogo(item.logoId));
    }
  }

  render() {
    const {
      item,
      openModal,
      colorsDictionary,
      colors,
      showThreadColorValue = false,
      logoColors,
      logoCellSize,
    } = this.props;
    let selectedColors = [];

    if (
      item.logoId
      && item.decorationMethod === decorationMethodEnum.EMB
      && logoColors[item.logoId]
    ) {
      selectedColors = logoColors[item.logoId].selectedColors;
    }
    if (item.logoUrl) {
      return (
        <ProductionAssemblyLogoCell
          openModal={openModal}
          logoUrl={item.previewUrl || item.logoUrl}
          logoColors={selectedColors}
          imageSize={logoCellSize}
          colors={colors}
        />
      );
    }

    return (
      <ProductionAssemblyTextCell
        personalizationColor={colorsDictionary[item.colorId]}
        personalizationText={item.text}
        personalizationNumber={item.number}
        personalizationOutlineColor={colorsDictionary[item.outlineColorId]}
        showThreadColorValue={showThreadColorValue}
        orderItemDecorationId={item.id}
        decorationMethod={item.decorationMethod}
        files={item.files}
      />
    );
  }
}

ProductionAssemblyLogoOrTextCell.propTypes = {
  item: PropTypes.object.isRequired,
  openModal: PropTypes.func.isRequired,
  showThreadColorValue: PropTypes.bool,
  logoColors: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string,
    selectionType: PropTypes.number,
    hexValue: PropTypes.string,
  })).isRequired,
  colorsDictionary: PropTypes.objectOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    dtgCode: PropTypes.string,
    cmykValue: PropTypes.string.isRequired,
    dtgCmykValue: PropTypes.string.isRequired,
    hexValue: PropTypes.string,
    threadValue: PropTypes.string,
    brightness: PropTypes.string.isRequired,
  })).isRequired,
  colors: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    dtgCode: PropTypes.string,
    cmykValue: PropTypes.string.isRequired,
    dtgCmykValue: PropTypes.string.isRequired,
    hexValue: PropTypes.string,
    threadValue: PropTypes.string,
    brightness: PropTypes.string.isRequired,
    isArtwork: PropTypes.bool,
    isFlood: PropTypes.bool,
  })).isRequired,
  logoCellSize: PropTypes.string,
};

const mapStateToProps = ({
  productionAssembly,
  productCatalog,
}) => ({
  logoColors: productionAssembly.logoColors,
  colorsDictionary: productCatalog.colorsDictionary,
  colors: productCatalog.colors,
});

export default connect(mapStateToProps)(ProductionAssemblyLogoOrTextCell);
