import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { regenerateImage } from '@APICalls/dtg/actions';
import { decorationMethodEnum } from '@constants/enums/decorationEnums';
import { materialSwal } from '@util/componentHelper';
import MaterialTooltip from '@sharedComponents/Tooltips/MaterialTooltip';
import SimpleConfirmationModal from '@sharedComponents/Modal/SimpleConfirmationModal';
import RegenerateDtgImageButton from '@sharedComponents/Buttons/RegenerateDtgImageButton';

class ProductionAssemblyTextCell extends PureComponent {
  state = {
    isOpen: false,
  };

  regenerateImage = async () => {
    const { orderItemDecorationId } = this.props;

    const result = await regenerateImage({ orderItemDecorationId });

    if (result?.success) {
      materialSwal('Success', result.message, 'success');

      this.closeRegenerateImageModal();
    }
  };

  openRegenerateImageModal = () => {
    this.setState(() => ({
      isOpen: true,
    }));
  };

  closeRegenerateImageModal = () => {
    this.setState(() => ({
      isOpen: false,
    }));
  };

  render() {
    const {
      personalizationText,
      personalizationNumber,
      personalizationColor,
      personalizationOutlineColor,
      showThreadColorValue = false,
      orderItemDecorationId,
      decorationMethod,
      files,
    } = this.props;

    const { isOpen } = this.state;

    let colorField = '';
    if (showThreadColorValue) {
      colorField = (
        <div className='thread-color'>
          <div className='color-swatch-item size--xs'>
            <div
              className='color-swatch-item__color size--xs'
              style={personalizationColor ? { backgroundColor: personalizationColor?.hexValue } : {}}
            />
          </div>
          <div className='text'>{personalizationColor?.threadValue}</div>
        </div>
      );
    } else {
      let outlineElement = '';
      const tooltipText = (
        <div className='flex__column'>
          <span>Fill: {personalizationColor?.code || '-'}</span>
          {personalizationOutlineColor && <span>Outline: {personalizationOutlineColor.code || '-'}</span>}
        </div>
      );

      if (personalizationOutlineColor?.hexValue) {
        outlineElement = (
          <span
            className='color--outline'
            style={{ borderColor: personalizationOutlineColor?.hexValue }}
          />
        );
      }

      colorField = (
        <MaterialTooltip
          tooltipText={tooltipText}
          placement={'right'}
          contentClasses={'color'}
        >
          <div
            className='w-100 h-100'
            style={{ backgroundColor: personalizationColor?.hexValue }}
          >
            {outlineElement}
          </div>
        </MaterialTooltip>
      );
    }

    return (
      <span className='sheet__list-table-personalization'>
        <span className='text'>
          {`${personalizationText || ''} ${personalizationNumber || ''}`}
        </span>
        <div className='flex'>
          {colorField}
          <SimpleConfirmationModal
            isOpen={isOpen}
            closeModal={this.closeRegenerateImageModal}
            confirm={this.regenerateImage}
            confirmationBody={'This process can take up to 10 minutes please check back'}
            title={'Confirm Personalization Regeneration'}
          />
          {
            decorationMethod === decorationMethodEnum.DTG &&
            <RegenerateDtgImageButton
              iconButton={true}
              onClick={this.openRegenerateImageModal}
              orderItemDecorationId={orderItemDecorationId}
              files={files}
            />
          }
        </div>
      </span>
    );
  }
}

ProductionAssemblyTextCell.propTypes = {
  personalizationText: PropTypes.string,
  personalizationNumber: PropTypes.string,
  personalizationColor: PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    hexValue: PropTypes.string,
    threadValue: PropTypes.string,
  }),
  personalizationOutlineColor: PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    hexValue: PropTypes.string,
    threadValue: PropTypes.string,
  }),
  showThreadColorValue: PropTypes.bool,
  orderItemDecorationId: PropTypes.number.isRequired,
  files: PropTypes.array,
  decorationMethod: PropTypes.string.isRequired,
};

export default ProductionAssemblyTextCell;
