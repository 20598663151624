import React, { useCallback, useRef, useState } from 'react';
import { Field, FieldArray } from 'redux-form';
import { Box, Grid, Typography } from '@material-ui/core';
import ImageDropzone from '@components/shared/Upload/ImageDropzone';
import ReduxFormCheckbox from '@sharedComponents/Form/ReduxFormCheckbox';
import ThreadColorDropDown from './ThreadColorDropDown';
import InfoField from '@sharedComponents/Display/InfoField';
import Input from '@sharedComponents/Form/Input';
import { servicesApiLockerItemPrintFileTypeEnum } from '@constants/enums/decorationEnums';
import { materialSwal } from '@util/componentHelper';
import UploadImage from '@components/shared/Upload/UploadImage';
import { getLast20Chars } from '@util/stringHelpers';

const acceptedFileTypes = {
  'Print Files': ['.svg', '.ai', '.eps', '.pdf'],
};

const sizeMatches = {
  's': ['s', 'sm'],
  'sm': ['s', 'sm'],
  'm': ['m', 'md'],
  'md': ['m', 'md'],
  'l': ['l', 'lg'],
  'lg': ['l', 'lg'],
};

const minFileSize = 1000;

const invalidFileMessage = 'Invalid file(s) found.  Please review file(s) and retry upload.';

const uploadedFileMatchesGarmentSize = (garmentSize, uploadFileSize) => {
  const sizeMatch = sizeMatches[garmentSize.toLowerCase()];

  if (!sizeMatch) {
    return garmentSize.toLowerCase() === uploadFileSize.toLowerCase();
  }

  return sizeMatch.includes(uploadFileSize.toLowerCase());
}

const CustomItemPrintFilesSection = ({
  change,
  updateThreadColor,
  updateOptionInput,
  updateDesignInput,
}) => {
  const [uploadMessage] = useState({
    type: 'default',
    body: "Drag 'n' Drop",
  });

  const [highlightEmptyBoxes, setHighlightEmptyBoxes] = useState(false);
  const fieldsRef = useRef(null);

  const changeImage = useCallback((field, imageName, url, file, size, fileType) => {
    if (file.size <= minFileSize) {
      materialSwal('Error', invalidFileMessage, 'error');

      return;
    }

    // for some reason files were occassionally failing.  this fixed it, no clue why
    const fileCopy = new File([file], file.name, {
      type: file.type,
      path: file.path,
      size: file.size,
    });

    change(field.field, {
      file: fileCopy,
      size,
      imageName: getLast20Chars(file.name), // do not remove,  this fixes the failing file issue as well
      id: field.printFile.id,
      fileType,
    });
    change('liquidPixelsAutomation', true); // Ensure the field is checked
  }, [change]);

  const removeImage = useCallback((field) => {
    change(field.field, {
      file: null,
      size: field.size || field.printFile.size,
      imageName: null,
      imageUrl: null,
      id: field.printFile.id,
      fileType: field.fileType,
    });
  }, [change]);

  const renderField = useCallback((field) => {
    const imageUrl = field.printFile?.file?.path || field.printFile?.imageName || field.printFile.imageUrl
      || field.printFile.image_url;
    console.info('rendering', imageUrl);

    return (
      <Box>
        <ImageDropzone
          message={uploadMessage}
          label={field.label}
          labelType={field.labelType}
          imageUrl={imageUrl}
          size={field.printFile.size}
          fileType={field.printFile.fileType}
          changeImage={changeImage.bind(null, field)}
          showThumbnail={false}
          acceptedFileTypes={acceptedFileTypes}
          onRemove={removeImage.bind(null, field)}
          highlightEmptyBoxes={highlightEmptyBoxes}
        />
      </Box>
    );
  }, [uploadMessage, changeImage, removeImage, highlightEmptyBoxes]);

  const renderFields = useCallback(({ fields }) => {
    const printFiles = fields.getAll() || [];
    fieldsRef.current = fields;

    return (
      <>
        {fields.map((field, index) => {
          if (index % 2 !== 0) { return null; }
          if (fields.get(index + 1) === undefined) { return null; }

          return (
            <Grid
              key={index}
              item={true}
              xs={3}
              style={{ margin: '2px' }}
            >
              <Box
                display='flex'
                justifyContent='center'
              >
                <Typography variant='p'>{printFiles[index].size || ''}</Typography>
              </Box>

              <Field
                key={field}
                name={`${field}.file`}
                field={field}
                index={index}
                size={field.size}
                label={'Main'}
                printFile={printFiles[index]}
                fileType={servicesApiLockerItemPrintFileTypeEnum.Main}
                component={renderField}
              />

              <Field
                key={fields.get(index + 1)}
                name={`printFiles[${index + 1}].file`}
                field={`printFiles[${index + 1}]`}
                index={index + 1}
                size={fields.get(index + 1).size}
                printFile={printFiles[index + 1]}
                label={'Reverse'}
                labelType={'status-blue'}
                fileType={servicesApiLockerItemPrintFileTypeEnum.Reverse}
                component={renderField}
              />
            </Grid>
          );
        })}
      </>
    );
  }, [renderField]);

  const getSizeAndTypeOfPrintFileUrl = (url) => {
    // FILE FORMAT : FILENAME_[REV|MAIN]_SIZE.AI
    const parts = url.split('_');
    let size = parts[parts.length - 1].split('.')[0];
    let printType = parts.length >= 2 ? parts[parts.length - 2].split('.')[0].toLowerCase() : '';

    switch (printType) {
      case 'rev':
        printType = servicesApiLockerItemPrintFileTypeEnum.Reverse;
        break;
      default:
        printType = servicesApiLockerItemPrintFileTypeEnum.Main;
    }

    return [size.toLowerCase(), printType];
  };

  const onBulkImageChange = useCallback((
    url,
    message,
    file,
  ) => {
    if (file === null) {
      return;
    }

    if (file.size <= minFileSize) {
      materialSwal('Error', invalidFileMessage, 'error');

      return;
    }

    setHighlightEmptyBoxes(true);
    const lastParts = getSizeAndTypeOfPrintFileUrl(file.name);
    let currentFieldIndex = null;
    let currentField;
    fieldsRef.current.getAll().forEach((f, index) => {

      if (!uploadedFileMatchesGarmentSize(f.size, lastParts[0]) || f.fileType !== lastParts[1]) {
        return;
      }

      currentFieldIndex = index;
      currentField = f;
    });

    if (currentFieldIndex === null) {
      return;
    }

    change(`printFiles[${currentFieldIndex}]`, {
      file,
      size: currentField.size,
      imageName: '',
      id: currentField.id,
      fileType: currentField.fileType,
    });

    change('liquidPixelsAutomation', true); // Ensure the field is checked
  }, [change, fieldsRef]);

  return (
    <>
      <Grid
        container={true}
        direction='row'
        justifyContent='space-evenly'
        spacing={3}
      >
        <Grid
          item={true}
          xs={3}
        >
          <ThreadColorDropDown updateThreadColor={updateThreadColor} />
        </Grid>
        <Grid
          item={true}
          xs={3}
        >
          <InfoField
            fieldClass={'mb-0'}
            label={'Design ID'}
            value={(
              <Field
                name={'designId'}
                component={Input}
                type={'text'}
                className={'w-100'}
                onChange={updateDesignInput}
              />
            )}
          />
        </Grid>
        <Grid
          item={true}
          xs={3}
        >
          <InfoField
            fieldClass={'mb-0'}
            label={'Option ID'}
            value={(
              <Field
                name={'optionId'}
                component={Input}
                type={'text'}
                className={'w-100'}
                onChange={updateOptionInput}
              />
            )}
          />
        </Grid>

        <Grid
          item={true}
          xs={3}
        >
          <div className='info__field'>
            <Field
              name={'liquidPixelsAutomation'}
              text={'Automate Personalization'}
              component={ReduxFormCheckbox}
              change={change}
            />
          </div>
        </Grid>
      </Grid>
      <Grid
        container={true}
        direction='row'
        justifyContent='space-evenly'
        spacing={3}
      >
        <div
          style={{
            padding: '20px',
            width: '100%',
          }}
        >
          <UploadImage
            changeImageUrl={onBulkImageChange}
            message={{
              type: 'default',
              body: 'Bulk Upload',
            }}
            acceptedFileTypes={acceptedFileTypes}
            uploadIsActive={true}
            multiple={true}
          />
        </div>
      </Grid>
      <Grid
        container={true}
        direction='row'
        justifyContent='space-evenly'
        spacing={3}
      >
        <FieldArray
          name='printFiles'
          component={renderFields}
          rerenderOnEveryChange={true}
        />
      </Grid>
    </>
  );
};

export default CustomItemPrintFilesSection;
