/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */
import type { OrderItemStatusEnum } from './orderItemStatusEnum';

export interface OverrideOrderItemStatusCommand {
  orderItemIds?: number[] | null;
  orderItemStatus?: OrderItemStatusEnum;
}
