import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { updateArtworkFileTaskDetails } from '@redux/artworkFileTasks/actions';
import {
  parseDateNumeric,
  parseDateTimeNumeric,
} from '@util/dateHandler';
import { formatDecorationLocationSize } from '@util/numberHelpers';
import Icon from '@sharedComponents/Icons/Icon';
import LabelsLegendDisplay from '@sharedComponents/Labels/LabelsLegendDisplay';

class StickyHeader extends Component {
  state = {
    hidden: true,
    leftItemExists: null,
    rightItemExists: null,
  };

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);

    this.checkBorderItems();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  componentDidUpdate(prevProps) {
    const {
      task,
      tasks,
    } = this.props;

    const currentTaskIndex = tasks.findIndex((x) => x.id === task.id);
    const previousTaskIndex = prevProps.tasks.findIndex((x) => x.id === task.id);

    if (!(task.id && (task.id !== prevProps.task.id || currentTaskIndex !== previousTaskIndex))) return;

    this.checkBorderItems();
  }

  getLeftItemIndex = () => {
    const {
      tasks,
      task,
    } = this.props;

    return tasks.findIndex((item) => item.id === task.id) - 1;
  };

  getRightItemIndex = () => {
    const {
      tasks,
      task,
    } = this.props;

    return tasks.findIndex((item) => item.id === task.id) + 1;
  };

  checkBorderItems = () => {
    const { tasks } = this.props;
    const leftItemIndex = this.getLeftItemIndex();
    const rightItemIndex = this.getRightItemIndex();

    const leftItemExists = leftItemIndex >= 0;
    const rightItemExists = rightItemIndex > 0 && rightItemIndex < tasks.length;

    this.setState({
      leftItemExists,
      rightItemExists,
    });
  };

  handleScroll = () => {
    const { stickyAfter } = this.props;
    const currentScroll = window.scrollY;

    this.setState({ hidden: currentScroll < stickyAfter });
  };

  handleLeftClick = () => {
    const {
      tasks,
      showDetails,
    } = this.props;

    const index = this.getLeftItemIndex();

    if (index < 0) return;

    showDetails(tasks[index]);
  };

  handleRightClick = () => {
    const {
      tasks,
      showDetails,
    } = this.props;

    const index = this.getRightItemIndex();

    if (index < 0 || index >= tasks.length) return;

    showDetails(tasks[index]);
  };

  render() {
    const {
      hidden,
      leftItemExists,
      rightItemExists,
    } = this.state;

    const {
      task,
      logo,
    } = this.props;

    const isAssigned = task.assignedOn && task.assignedBy && task.assignedToName;

    let isPaused = false;
    if (task.orders) {
      isPaused = true;
      for (const order of task.orders) {
        if (!order.isPaused) {
          isPaused = false;
          break;
        }
      }
    }

    let isRushed = false;
    if (task.orders) {
      for (const order of task.orders) {
        if (order.isRushed) {
          isRushed = true;
          break;
        }
      }
    }

    let taskMessage, shipDateText, decoration;
    let isUnavailable = false;
    if (task.isCanceled) {
      taskMessage = 'Canceled';
      decoration = 'cancelled';
      isUnavailable = true;
    } else if (isPaused) {
      taskMessage = 'Paused';
      decoration = 'paused';
      isUnavailable = true;
    } else if (isRushed) {
      taskMessage = 'Rushed';
      shipDateText = parseDateNumeric(task.shipDate);
      decoration = 'rushed';
    } else {
      taskMessage = 'Ship date';
      shipDateText = parseDateNumeric(task.shipDate);
      decoration = 'shipping-date';
    }
    let archivationText = '';
    if (task.artworkTaskArchivation) {
      archivationText = parseDateNumeric(task.artworkTaskArchivation.created);
    }

    return (
      <div className={`sticky-header ${hidden ? 'sticky-header--hidden' : ''}`}>
        <div className='container sticky-header__container'>
          <div className={`sticky-header__control ${!leftItemExists ? 'sticky-header__control--hidden' : ''}`}>
            <Icon
              materialIcon={'chevron_left'}
              onClick={this.handleLeftClick}
            />
          </div>

          <div className='sticky-header__item'>
            <div className='item__image'>
              <img src={(logo?.imageUrl) || task.logoUrl} />
            </div>
            <div className='item__status'>
              <div className='flex'>
                <span className='task__id'>F{task.id}</span>
                {
                  task.hasRequiredItems &&
                  <div className='align__center'>
                    <LabelsLegendDisplay
                      labels={[
                        {
                          isActive: task.hasRequiredItems,
                          text: 'Required',
                          shortText: 'R',
                          type: 'electric-indigo',
                        },
                      ]}
                    />
                  </div>
                }
              </div>
              <span className={`task__status--${task.status?.status.toLowerCase()}`}>{task.status?.label}</span>
            </div>

            <div className='item__status'>
              <span>{formatDecorationLocationSize(task.decorationSizeWidth, task.decorationSizeHeight)}</span>
              <span>{task.decorationMethod}</span>
            </div>
            {
              task.isCutFile &&
              <div className='item__status'>
                <Icon materialIcon={'content_cut'} />
                <span>{task.cutFileColor}</span>
              </div>
            }

            <div className={`item__status ${isUnavailable ? 'item__status--unavailable' : ''}`}>
              <span className={`task__message task__message--${decoration}  uppercase p-5`}>{taskMessage}</span>
              <span>{shipDateText ?? ''}</span>
            </div>
            {
              task.artworkTaskArchivation &&
              <div className='item__status'>
                <span className='task__message task__message--archived  uppercase p-5'>Archived</span>
                <span>{archivationText || ''}</span>
              </div>
            }
            {
              isAssigned &&
              <div className='item__status p-l-32'>
                <span><strong>Assigned to:</strong> {task.assignedToName}</span>
                <span>
                  <strong>Assigned by:</strong> {task.assignedBy} ({parseDateTimeNumeric(task.assignedOn)})
                </span>
              </div>
            }

          </div>
          <div className={`sticky-header__control ${!rightItemExists ? 'sticky-header__control--hidden' : ''}`}>
            <Icon
              materialIcon={'chevron_right'}
              onClick={this.handleRightClick}
            />
          </div>
        </div>
      </div>
    );
  }
}

StickyHeader.propTypes = {
  stickyAfter: PropTypes.number.isRequired,
  task: PropTypes.object,
  tasks: PropTypes.array.isRequired,
  showDetails: PropTypes.func.isRequired,
  logo: PropTypes.object,
};

const mapStateToProps = ({
  artworkFileTasks,
  logos,
}) => ({
  tasks: artworkFileTasks.artworkFileTasks.items,
  task: artworkFileTasks.artworkFileTaskDetails,
  logo: logos.logo,
});

function matchDispatchToProps(dispatch) {
  return bindActionCreators({
    showDetails: updateArtworkFileTaskDetails,
  }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(StickyHeader);
