/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */

export type ArtworkTableByStatusEnum = 'Unassigned' | 'Progress' | 'Qc' | 'Rework' | 'Completed' | 'Priority';


export const ArtworkTableByStatusEnum = {
  Unassigned: 'Unassigned' as ArtworkTableByStatusEnum,
  Progress: 'Progress' as ArtworkTableByStatusEnum,
  Qc: 'Qc' as ArtworkTableByStatusEnum,
  Rework: 'Rework' as ArtworkTableByStatusEnum,
  Completed: 'Completed' as ArtworkTableByStatusEnum,
  Priority: 'Priority' as ArtworkTableByStatusEnum,
};
