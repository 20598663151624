/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */
import type { WarehouseOrderItemDto } from './warehouseOrderItemDto';

export interface WarehouseOrderDto {
  id?: number;
  orderNumber?: number;
  gpDocumentId?: string | null;
  lockerId?: number | null;
  organizationId?: number | null;
  gpCustomerId?: string | null;
  items?: WarehouseOrderItemDto[] | null;
}
