import React, { useCallback, useEffect } from 'react';
import { Field, formValueSelector, InjectedFormProps, reduxForm } from 'redux-form';
import { connect, ConnectedProps } from 'react-redux';
import { ColoredStyleDetailDto } from '@api/productCatalog/models';
import { PersonalizationPricesViewModel } from '@api/squadlockerServices/models/personalizationPricesViewModel';
import { personalizationForm } from '@constants/reduxForms';
import { PersonalizationFormData } from '@models/forms/OrderManagement/PersonalizationFormData';
import { OrderManagementTempItem, OrderManagementTempItemPersonalization } from '@redux/orderManagement/models';
import { RootState } from '@redux/index/reducers';
import { validatePersonalization } from '@redux/orderManagement/validations';
import { centsToDollars } from '@util/numberHelpers';
import Input from '@sharedComponents/Form/Input';

interface OwnProps {
  item: OrderManagementTempItem;
  personalization: OrderManagementTempItemPersonalization;
  coloredStyle?: ColoredStyleDetailDto;
  defaultPersonalizationPrices: PersonalizationPricesViewModel | undefined;
}

const selector = formValueSelector(personalizationForm);
const mapStateToProps = (state: RootState) => ({
  name: selector(state, 'name'),
  number: selector(state, 'number'),
  unitPriceTouched: selector(state, 'unitPriceTouched'),
});

const connector = connect(mapStateToProps);

type FormProps = OwnProps & ConnectedProps<typeof connector>;

type Props = FormProps & InjectedFormProps<PersonalizationFormData, FormProps, string[]>;

const PersonalizationForm = React.memo(({
  handleSubmit,
  item,
  personalization,
  defaultPersonalizationPrices,
  change,
  name,
  number,
  unitPriceTouched,
}: Props) => {
  useEffect(() => {
    if (item.custom) {
      change('unitPrice', 0);
    }
  }, [change, item]);

  useEffect(() => {
    if (unitPriceTouched || !defaultPersonalizationPrices || item.custom) { return; }
    if (!!name && !!number) {
      change('unitPrice', centsToDollars(defaultPersonalizationPrices.textAndNumber));
    } else if (!!name || !!number) {
      change('unitPrice', centsToDollars(defaultPersonalizationPrices.textOrNumber));
    }
  }, [defaultPersonalizationPrices, name, number, unitPriceTouched, item, change]);

  const onChangeUnitPrice = useCallback(() => {
    change('unitPriceTouched', true);
  }, [change]);

  return (
    <form
      className='redux-form'
      id={personalizationForm}
      onSubmit={handleSubmit}
    >
      <div className='roster-notifications__form mb-100'>
        <div className='redux-form__row-aligned mt-30 mb-20'>
          <div className='redux-form__column--size-m mr-20'>
            <label className='redux-form__label'>
              Location<span className='required'>*</span>
            </label>
            <div>{personalization?.decorationLocation}</div>
          </div>
        </div>

        <div className='redux-form__row-aligned mb-20'>
          <div className='redux-form__column--size-m mr-20'>
            <label className='redux-form__label'>
              Text
            </label>
            <Field
              name={'name'}
              placeholder={'Enter Name'}
              component={Input}
              type={'text'}
            />
          </div>
          <div className='redux-form__column--size-m'>
            <label className='redux-form__label'>
              Number
            </label>
            <Field
              name={'number'}
              placeholder={'Enter Number'}
              component={Input}
              type={'text'}
            />
          </div>
        </div>
        <div className='redux-form__row-aligned mb-20'>
          <div className='redux-form__column--size-m mr-20'>
            <label className='redux-form__label'>
              Personalization Base Price<span className='required'>*</span>
            </label>
            <Field
              name={'unitPrice'}
              placeholder={'Enter Price'}
              component={Input}
              type={'text'}
              onChange={onChangeUnitPrice}
            />
          </div>
        </div>
      </div>
    </form>
  );
});

export default connector(
  reduxForm<PersonalizationFormData, FormProps, string[]>({
    form: personalizationForm,
    enableReinitialize: true,
    validate: validatePersonalization as any,
  })(PersonalizationForm),
);
