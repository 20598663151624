/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */
import type { PtlWallOrderItemDto } from './ptlWallOrderItemDto';
import type { PtlWallRowPositionStatusEnum } from './ptlWallRowPositionStatusEnum';
import type { PtlWallRowDto } from './ptlWallRowDto';

export interface PtlWallRowPositionDto {
  id?: number;
  uuid?: string | null;
  created?: string | null;
  updated?: string | null;
  deleted?: string | null;
  color?: string | null;
  totalOrderItemCount?: number;
  orderItemCount?: number;
  rowId?: number;
  binId?: string | null;
  ptlWallPositionColorId?: number;
  sortOrder?: number;
  orderItems?: PtlWallOrderItemDto[] | null;
  status?: PtlWallRowPositionStatusEnum;
  row?: PtlWallRowDto;
}
