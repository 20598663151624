/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Orders Homefield API
 * OpenAPI spec version: v1
 */
import type { ShippingInfo } from './shippingInfo';
import type { CouponTypeEnum } from './couponTypeEnum';
import type { CommandsItem } from './commandsItem';

export interface PlaceHomefieldOrderCommand {
  organizationId?: number | null;
  salesChannelId?: number | null;
  prdOrder?: boolean;
  shipDate?: string;
  customerShipDate?: string;
  shippingInfo?: ShippingInfo;
  couponCode?: string | null;
  couponType?: CouponTypeEnum;
  couponValue?: number;
  couponAppliesToShipping?: boolean;
  shippingBasePrice?: number;
  stripeCustomerId?: string | null;
  stripeChargeId?: string | null;
  items?: CommandsItem[] | null;
}
