/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Financials Homefield API
 * OpenAPI spec version: v1
 */

export interface IDto {
  readonly id?: number;
  readonly uuid?: string | null;
  readonly created?: string | null;
  readonly updated?: string | null;
  readonly deleted?: string | null;
  readonly typeName?: string | null;
}
