/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Financials Homefield API
 * OpenAPI spec version: v1
 */

export type PayoutTypeEnum = 'Undefined' | 'Request' | 'ProcessedPayout';


export const PayoutTypeEnum = {
  Undefined: 'Undefined' as PayoutTypeEnum,
  Request: 'Request' as PayoutTypeEnum,
  ProcessedPayout: 'ProcessedPayout' as PayoutTypeEnum,
};
