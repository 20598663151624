import { catalogApi } from '../../sqdApis';
import {
  mapStyleErrors,
  mapColoredStyleErrors,
  mapProductErrors,
  mapLayoutErrors,
  mapStyleDecorationLocations,
} from '@util/componentHelpers/productCatalogHelper';
import {
  makeApiCallWithSubmissionError,
  makeApiCallWithErrorModal,
} from '@util/apiHelper';
import productCatalogUrls from '@constants/sqdApiUrls/productCatalogUrls';

export const updateStyle = async (style) => {
  const decorationsMapped = mapStyleDecorationLocations(
    style.defaultLogoLocation,
    style.customOnlyLogos,
    style.defaultPersonalizations,
    style.customOnlyPersonalizations
  );

  const body = JSON.stringify({
    id: style.id,
    age: style.age,
    availableForNewStores: style.availableForNewStores,
    brandId: style?.brand?.id,
    categoryId: style?.category?.id,
    price: style.price,
    classId: style.classId,
    code: style.code,
    decorationMethod: style.decorationMethod,
    description: style.description,
    discount: style.discount,
    gender: style.gender,
    name: style.name,
    recommended: style.recommended,
    disabled: style.disabled,
    discontinued: style.discontinued,
    decorationLocations: decorationsMapped,
    customizable: style.customizable,
    qtyVendorMinimum: style.qtyVendorMinimum,
    sizeChartUrl: style.sizeChartUrl,
    vendorFulfilledItem: !!style.vendorFulfilledItem,
    taxCode: style.taxCode,
    vendorId: style.vendorId,
    alwaysAvailableVendorInventory: style.alwaysAvailableVendorInventory,
    rowHash: style.rowHash,
    waterproof: style.waterproof,
    prdOnly: style.prdOnly,
  });

  const call = catalogApi.put(productCatalogUrls.style(style.id), {
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  });

  const res = await makeApiCallWithSubmissionError(call, mapStyleErrors);

  return res;
};

export const bulkUpdateStyle = async (
  styleIds,
  toggleWebsiteEnabled,
  toggleNewStoresAvailability,
  toggleRecommended,
  toggleCustomizable,
  discount = null,
  discountPercentage = null
) => {
  const body = JSON.stringify({
    styleIds,
    toggleVisible: toggleWebsiteEnabled,
    toggleAvailableForNewStores: toggleNewStoresAvailability,
    toggleRecommended,
    toggleCustomizable,
    discount,
    discountPercentage,
  });

  const call = catalogApi.put(productCatalogUrls.bulkUpdateStyle, {
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  });

  const res = await makeApiCallWithErrorModal(call);

  return res;
};

export const bulkDeleteStyles = async (styleIds) => {
  const body = JSON.stringify({ styleIds });

  const call = catalogApi.delete(productCatalogUrls.bulkUpdateStyle, {
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  });

  const res = await makeApiCallWithErrorModal(call);

  return res;
};

export const updateStyleSports = async (style) => {
  const body = JSON.stringify({
    sports: style.sportsInputList,
  });

  const call = catalogApi.put(productCatalogUrls.sportsForStyle(style.id), {
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  });

  const res = await makeApiCallWithErrorModal(call);

  return res;
};

export const updateStyleLayouts = async (style) => {
  const body = JSON.stringify({
    layouts: style.layoutsInputList,
  });

  const call = catalogApi.put(productCatalogUrls.layoutsForStyle(style.id), {
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  });

  const res = await makeApiCallWithSubmissionError(call, mapLayoutErrors);

  return res;
};

export const updateStyleVendor = async (style) => {
  const body = JSON.stringify({
    id: style.id,
    vendorCode: style.vendorId,
  });

  const call = catalogApi.put(productCatalogUrls.styleVendor, {
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  });

  const res = await makeApiCallWithErrorModal(call);

  return res;
};

export const deleteStyle = async (styleId) => {
  const call = catalogApi.delete(productCatalogUrls.style(styleId));
  const res = await makeApiCallWithErrorModal(call);

  return res;
};

export const updateColoredStyle = async (coloredStyle) => {
  const personalizationColorsMapped = [];

  if (coloredStyle.defaultPersonalizationColor) {
    personalizationColorsMapped.push({
      floodColorId: coloredStyle.defaultPersonalizationColor,
      custom: false,
    });
  }
  if (coloredStyle.customOnlyPersonalizationColors) {
    for (const colorId of coloredStyle.customOnlyPersonalizationColors) {
      personalizationColorsMapped.push({
        floodColorId: colorId,
        custom: true,
      });
    }
  }

  const body = JSON.stringify({
    id: coloredStyle.id,
    code: coloredStyle.code,
    colorGroup: coloredStyle.colorGroup,
    colorA: coloredStyle.colorA,
    colorB: coloredStyle.colorB,
    floodColorId: coloredStyle.floodColorId,
    disabled: coloredStyle.disabled,
    discontinued: coloredStyle.discontinued,
    personalizationColors: personalizationColorsMapped,
    imageUrlFront: coloredStyle.imageUrlFront,
    imageUrlSide: coloredStyle.imageUrlSide,
    imageUrlBack: coloredStyle.imageUrlBack,
    imageUrlGrid: coloredStyle.imageUrlGrid,
    styleId: coloredStyle.styleId,
    qtyVendorMinimum: coloredStyle.qtyVendorMinimum,
    alwaysAvailableVendorInventory: coloredStyle.alwaysAvailableVendorInventory,
    preferredVendor: coloredStyle.preferredVendor,
    rowHash: coloredStyle.rowHash,
  });

  const call = catalogApi.put(productCatalogUrls.coloredStyle(coloredStyle.id), {
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  });

  const res = await makeApiCallWithSubmissionError(call, mapColoredStyleErrors);

  return res;
};

export const bulkUpdateColoredStyles = async (coloredStyles, propertyName, value) => {
  const promises = [];
  let countSuccess = 0;

  for (const coloredStyle of coloredStyles) {
    coloredStyle[propertyName] = value;
    promises.push(updateColoredStyle(coloredStyle));
  }

  const res = await Promise.all(promises);
  for (const r of res) {
    if (r?.success) {
      countSuccess++;
    }
  }

  return countSuccess === coloredStyles.length;
};

export const deleteColoredStyle = async (coloredStyleId) => {
  const call = catalogApi.delete(productCatalogUrls.coloredStyle(coloredStyleId));
  const res = await makeApiCallWithErrorModal(call);

  return res;
};

export const updateProduct = async (product) => {
  const body = JSON.stringify({
    sku: product.sku,
    size: product.size,
    vendorId: product.vendorId,
    description: product.description,
    standardCost: product.standardCost,
    currentCost: product.currentCost,
    weight: product.weight,
    decorationSize: product.decorationSize,
    discontinued: product.discontinued,
    disabled: product.disabled,
    qtyVendorMinimum: product.qtyVendorMinimum,
    status: product.status,
    coloredStyleId: product.coloredStyleId,
    alwaysAvailableVendorInventory: product.alwaysAvailableVendorInventory,
    rowHash: product.rowHash,
  });

  const call = catalogApi.put(productCatalogUrls.product(product.sku), {
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  });

  const res = await makeApiCallWithSubmissionError(call, mapProductErrors);

  return res;
};

export const deleteProduct = async (sku) => {
  const call = catalogApi.delete(productCatalogUrls.product(sku));
  const res = await makeApiCallWithErrorModal(call);

  return res;
};

export const deleteProductVendor = async (sku, vendorId) => {
  const call = catalogApi.delete(productCatalogUrls.productVendorMappings(sku, vendorId));
  const res = await makeApiCallWithErrorModal(call);

  return res;
};

export const updateProductVendor = async (productVendor) => {
  const body = JSON.stringify({
    sku: productVendor.sku,
    vendorId: productVendor.vendorId,
    esiUpcNumber: productVendor.esiUpcNumber,
    rbiUpc: productVendor.rbiUpc,
    vendorItemNumber: productVendor.vendorItemNumber,
  });

  const call = catalogApi.put(productCatalogUrls.productVendorMappings(), {
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  });

  const res = await makeApiCallWithErrorModal(call);

  return res;
};

export const createProductVendor = async (productVendor) => {
  const body = JSON.stringify({
    sku: productVendor.sku,
    vendorId: productVendor.vendorId,
    esiUpcNumber: productVendor.esiUpcNumber,
    rbiUpc: productVendor.rbiUpc,
    vendorItemNumber: productVendor.vendorItemNumber,
  });

  const call = catalogApi.post(productCatalogUrls.productVendorMappings(), {
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  });

  const res = await makeApiCallWithErrorModal(call);

  return res;
};

export const updateColoredStyleStatus = async (ids, status) => {
  const body = JSON.stringify({
    ids,
    status,
  });

  const call = catalogApi.put(productCatalogUrls.coloredStyleStatus, {
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  });

  const res = await makeApiCallWithErrorModal(call);

  return res;
};

export const updateStyleStatus = async (styleId, status) => {
  const body = JSON.stringify({
    ids: [styleId],
    status,
  });

  const call = catalogApi.put(productCatalogUrls.styleStatus, {
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  });

  const res = await makeApiCallWithErrorModal(call);

  return res;
};

export const updateGridPreview = async (coloredStyleId) => {
  const call = catalogApi.put(productCatalogUrls.updateGridPreview(coloredStyleId));
  const res = await makeApiCallWithErrorModal(call);

  return res;
};

export const syncStyle = async (styleId) => {
  const call = catalogApi.put(productCatalogUrls.syncStyle(styleId));
  const res = await makeApiCallWithErrorModal(call);

  return res;
};

export const syncColoredStyle = async (coloredStyleId) => {
  const call = catalogApi.put(productCatalogUrls.syncColoredStyle(coloredStyleId));
  const res = await makeApiCallWithErrorModal(call);

  return res;
};

export const syncColoredStyleProducts = async (coloredStyleId) => {
  const call = catalogApi.put(productCatalogUrls.syncColoredStyleProducts(coloredStyleId));
  const res = await makeApiCallWithErrorModal(call);

  return res;
};
