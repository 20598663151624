/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */
import type { DecorationSizeEnum } from './decorationSizeEnum';
import type { PrepressJobOrderIndicatorEnum } from './prepressJobOrderIndicatorEnum';
import type { PrintAssemblyStatusEnumStatusModel } from './printAssemblyStatusEnumStatusModel';
import type { FlagDto } from './flagDto';

export interface PrintAssemblyQueueDto {
  id?: number;
  previewUrl?: string | null;
  jobType?: string | null;
  resultUrls?: string[] | null;
  size?: string | null;
  decorationSize?: DecorationSizeEnum;
  orderNumber?: number;
  indicator?: PrepressJobOrderIndicatorEnum;
  barcode?: string | null;
  jobId?: number;
  personalizationColorId?: number | null;
  personalizationOutlineColorId?: number | null;
  personalizationText?: string | null;
  personalizationNumber?: string | null;
  isCutLogoFile?: boolean;
  cutFileColor?: string | null;
  isFlagged?: boolean;
  reprintQuantity?: number | null;
  floodColorId?: number | null;
  status?: PrintAssemblyStatusEnumStatusModel;
  flag?: FlagDto;
}
