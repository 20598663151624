import React, {
  useMemo,
  useCallback,
  useState,
} from 'react';
import { useGetHomefieldApiSupportQualityassurancecodes } from '@api/fulfillment/support';
import Table from '@sharedComponents/Table/Table';
import {
  Column,
  CellInfo,
  getSortParamsFromTable,
} from '@util/tableHelpers';
import {
  QualityAssuranceCodeDto, SortDirectionEnum, SupportQueriesSortByEnum,
} from '@api/fulfillment/models';
import QualityAssuranceCodesActionsColumn from './QualityAssuranceCodesActionsColumn';
import Button from '@components/shared/Buttons/Button';
import {
  keyNameEnum, sortDirectionEnum,
} from '@constants/enums/commonEnums';
import Icon from '@components/shared/Icons/Icon';
import AddQualityAssuranceCodeModal from './AddQualityAssuranceCodeModal';
import HeaderCell from '@components/shared/Table/TableCells/HeaderCell';

const QaCodeTable = Table<QualityAssuranceCodeDto>();

export const QualityAssuranceCodes: React.FC = React.memo(() => {
  const [
    searchString,
    setSearchString,
  ] = useState('');
  const [
    inputValue,
    setInputValue,
  ] = useState('');
  const [
    addModalOpen,
    setAddModalOpen,
  ] = useState(false);
  const [
    sortColumn,
    setSortColumn,
  ] = useState<SupportQueriesSortByEnum>(SupportQueriesSortByEnum.Code);
  const [
    sortDirection,
    setSortDirection,
  ] = useState<SortDirectionEnum>('Ascending');

  const {
    data: qaCodes, refetch,
  } = useGetHomefieldApiSupportQualityassurancecodes({
    search: searchString,
    sortBy: sortColumn,
    sortDirection,
  }, { query: { enabled: true } });

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    e.stopPropagation();

    setInputValue(e.target.value);
  }, []);

  const handleKeyDown = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!e?.key || e.key !== keyNameEnum.Enter) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();

    setSearchString(inputValue);
  }, [inputValue]);

  const handleClearSearch = useCallback(() => {
    setInputValue('');
    setSearchString('');
    refetch();
  }, [refetch]);

  const handleOpenAddModal = useCallback(() => setAddModalOpen(true), []);
  const handleCloseAddModal = useCallback(() => setAddModalOpen(false), []);

  const handleFetch = useCallback((_, instance) => {
    const {
      sortColumn: newSortColumn,
      sortDirection: newSortDirection,
    } = getSortParamsFromTable(instance, sortDirectionEnum);

    switch (newSortColumn.toLowerCase()) {
      case 'code':
        setSortColumn(SupportQueriesSortByEnum.Code);
        break;
      case 'name':
        setSortColumn(SupportQueriesSortByEnum.Name);
        break;
      default:
        break;
    }

    switch (newSortDirection) {
      case 'descending':
        setSortDirection(SortDirectionEnum.Descending);
        break;
      case 'ascending':
        setSortDirection(SortDirectionEnum.Ascending);
        break;
      default:
        break;
    }
  }, []);

  type QaCellInfo<K extends keyof QualityAssuranceCodeDto> =
    CellInfo<QualityAssuranceCodeDto, K>;

  const qaColumns: Array<Column<QualityAssuranceCodeDto>> = useMemo(() => [
    {
      Header: <HeaderCell text={'Code'} />,
      accessor: 'code',
      maxWidth: 200,
      sortable: true,
    },
    {
      Header: <HeaderCell text={'Name'} />,
      accessor: 'name',
      maxWidth: 300,
      sortable: true,
    },
    {
      Header: 'Owner',
      accessor: 'owner',
      maxWidth: 250,
    },
    {
      Header: 'Scenarios',
      accessor: 'scenarios',
      Cell: (cellProps: QaCellInfo<'scenarios'>) => (cellProps.value ? <pre>{cellProps.value}</pre> : <></>),
    },
    {
      Header: '',
      accessor: '',
      maxWidth: 65,
      resizable: false,
      style: { overflow: 'visible' },
      Cell: (cellProps) => (
        <QualityAssuranceCodesActionsColumn
          item={cellProps.value}
          refetch={refetch}
        />
      ),
    },
  ], [refetch]);

  const wrappedColumns = useMemo(() => [
    {
      columns: qaColumns,
    },
  ], [qaColumns]);

  const data = useMemo(() => {
    if (qaCodes) {
      return qaCodes;
    }

    return [];
  }, [qaCodes]);

  return (
    <div className='container'>
      <div className='table-options w-100'>
        <div className='flex'>
          <div className='text-field filter-group'>
            <input
              type='text'
              placeholder='Search'
              className='has-icon search-filter-width'
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              value={inputValue}
            />
            <Icon
              materialIcon={'search'}
              classes={'text-field__icon'}
            />
            {
              inputValue &&
              <Icon
                onClick={handleClearSearch}
                materialIcon={'close'}
                classes={'text-field__icon--right button'}
              />
            }
          </div>
        </div>
        <div className='flex'>
          <Button
            type={'primary'}
            text={'Add QA Code'}
            onClick={handleOpenAddModal}
            classes={'colors__add-btn'}
          />
        </div>
      </div>
      <div className='sheet'>
        <QaCodeTable
          data={data}
          columns={wrappedColumns}
          showPagination={false}
          onFetchData={handleFetch}
          sortDirEnum={sortDirectionEnum}
        />
      </div>
      <AddQualityAssuranceCodeModal
        isOpen={addModalOpen}
        closeModal={handleCloseAddModal}
        onAdd={handleClearSearch}
      />
    </div>
  );
});

export default QualityAssuranceCodes;
