import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import Modal from '@sharedComponents/Modal/Modal';
import ModalButtons from '@components/shared/Modal/ModalButtons';
import { FlaggingFormData } from '@models/forms/Production/FlaggingFormData';
import HookFormRadioGroup from '@components/shared/Form/HookFormRadioGroup';
import { decorationMethodEnum } from '@constants/enums/decorationEnums';
import { getFlagReasonOptions } from '@util/flagHelper';
import {
  DecorationFlagTypeEnum,
  HagPrintRoomOrderAssemblyPagedListDto,
} from '@api/fulfillment/models';
import { ColorDto } from '@api/productCatalog/models';
import yup from '@util/yupValidationHelper';
import { printAssemblyUnflagItemForm } from '@constants/reduxForms';
import HookFormSelect from '@components/shared/Form/HookFormSelect';
import FormInput from '@components/shared/Form/FormInput';
import MarkItemAsFlaggedModalLogoDetails from './MarkItemAsFlaggedModalLogoDetails';
import MarkItemAsFlaggedModalPersonalizationDetails from './MarkItemAsFlaggedModalPersonalizationDetails';
import {
  useForm,
  UseFormReturn,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

interface OwnProps {
  orderNumber: number;
  item: HagPrintRoomOrderAssemblyPagedListDto | undefined;
  isOpen: boolean;
  onCloseModal: () => void;
  onSubmit: (data: FlaggingFormData) => Promise<void>;
  colors: ColorDto[] | undefined;
}

type Props = OwnProps;

const PrintAssemblyUnflaggingModal = React.memo<Props>((
  {
    orderNumber,
    item,
    isOpen,
    onCloseModal,
    onSubmit,
    colors,
  }
) => {
  const [
    flagType,
    setFlagType,
  ] = useState<DecorationFlagTypeEnum>();

  const [
    reasons,
    setReasons,
  ] = useState<JSX.Element[]>([]);

  const schema = yup.object({
    flagType: yup.string().required('Flag type is required.'),
    reason: yup.string().required('Reason is required.'),
    note: yup.string().required('Notes is required'),
  });

  const formMethods: UseFormReturn<FlaggingFormData> = useForm<FlaggingFormData>({
    // @ts-expect-error - resolver is weird
    resolver: yupResolver(schema),
    defaultValues: {
      flagType: undefined as (DecorationFlagTypeEnum | undefined),
      reason: undefined as (string | undefined),
      note: undefined as (string | undefined),
    },
  });

  const {
    handleSubmit,
    register,
    setValue,
    getValues,
    formState: { errors },
  } = formMethods;

  const reset = useCallback(() => {
    formMethods.reset();
    setFlagType(DecorationFlagTypeEnum.Reprint);
  }, [formMethods]);

  const unflagDecoration = useCallback(async () => {
    handleSubmit(onSubmit)();
    reset();
  }, [
    handleSubmit,
    onSubmit,
    reset,
  ]);

  const onClose = useCallback(() => {
    reset();
    onCloseModal();
  }, [
    reset,
    onCloseModal,
  ]);

  const handleFlagChanged = useCallback((changeEvent: string) => {
    switch (changeEvent) {
      case DecorationFlagTypeEnum.Reprint:
        setFlagType(DecorationFlagTypeEnum.Reprint);
        break;
      case DecorationFlagTypeEnum.Rework:
        setFlagType(DecorationFlagTypeEnum.Rework);
        break;
      case DecorationFlagTypeEnum.WrongArtwork:
        setFlagType(DecorationFlagTypeEnum.WrongArtwork);
        break;
      default:
        setFlagType(DecorationFlagTypeEnum.Undefined);
        break;
    }
  }, []);

  useEffect(() => {
    setReasons(getFlagReasonOptions(flagType, decorationMethodEnum.HAG));
  }, [flagType]);

  useEffect(() => {
    if (item?.flag?.flagType) {
      setFlagType(item?.flag?.flagType);
      setValue('flagType', item?.flag?.flagType);
    }
    if (item?.flag?.reason) {
      setValue('reason', item?.flag?.reason);
    }
    if (item?.flag?.note) {
      setValue('note', item?.flag?.note);
    }
  }, [
    item,
    setValue,
  ]);

  return (
    <Modal
      isOpen={isOpen}
      closeModal={onClose}
      modalSize={'l'}
      buttons={(
        <ModalButtons
          onClose={onClose}
          onConfirm={unflagDecoration}
          confirmBtnText={'Unflag'}
          isDangerousAction={true}
          confirmBtnDisabled={false}
        />
      )}
    >
      <form
        className='flagging__form'
        onSubmit={unflagDecoration}
        id={printAssemblyUnflagItemForm}
      >
        <div className='flagging__form-group'>
          <HookFormRadioGroup<FlaggingFormData, FlaggingFormData['flagType']>
            name={'flagType'}
            onClickOnLabel={handleFlagChanged}
            onchange={handleFlagChanged}
            groupClassName={'flagging__form-radio-group'}
            register={register}
            getValue={getValues}
            change={setValue}
            radioItems={[
              {
                value: DecorationFlagTypeEnum.Reprint,
                label: 'Send to Reprint',
                disabled: true,
                className: 'ml-n10',
              },
              {
                value: DecorationFlagTypeEnum.Rework,
                disabled: true,
                label: 'Send to Rework',
              },
              {
                value: DecorationFlagTypeEnum.WrongArtwork,
                label: 'Send to Artwork Change',
                disabled: true,
              },
            ]}
          />
        </div>
        <div className='flagging__form-group'>
          <HookFormSelect<FlaggingFormData, FlaggingFormData['reason']>
            name={'reason'}
            register={register}
            options={reasons}
            change={setValue}
            error={errors.reason}
            disabled={true}
          />
        </div>
        <div className='flagging__form-group'>
          <FormInput
            placeholder={'Notes'}
            type={'string'}
            register={register}
            error={errors.note}
            required={true}
            autoFocus={true}
            disabled={true}
            {...register('note')}
          />
        </div>
        {
          item?.previewUrl
            ? (
              <MarkItemAsFlaggedModalLogoDetails
                previewUrl={item?.previewUrl}
                orderNumber={orderNumber}
                size={item?.decorationSize}
                decorationId={item.decorationId}
              />
            )
            : (
              <MarkItemAsFlaggedModalPersonalizationDetails
                personalizationNumber={item?.number}
                personalizationText={item?.text}
                personalizationColor={colors?.find((x) => x.id === item)}
                orderNumber={orderNumber}
                decorationId={item?.decorationId}
                size={item?.decorationSize}
              />
            )
        }
      </form>
    </Modal>
  );
});

export default PrintAssemblyUnflaggingModal;
