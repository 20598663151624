/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */
import type { AccountingActionTypeEnum } from './accountingActionTypeEnum';

export interface RefundOrderItemCommandObsolete {
  orderNumber?: number;
  amount?: number;
  reason?: string | null;
  actionType?: AccountingActionTypeEnum;
  isCancel?: boolean | null;
  orderItemIds?: number[] | null;
  isAdmin?: boolean;
}
