/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */

export type WarehouseSchedulingQueriesSortByEnum = 'ShipDate' | 'OrderNumber' | 'LockerId' | 'GpCustomerId';


export const WarehouseSchedulingQueriesSortByEnum = {
  ShipDate: 'ShipDate' as WarehouseSchedulingQueriesSortByEnum,
  OrderNumber: 'OrderNumber' as WarehouseSchedulingQueriesSortByEnum,
  LockerId: 'LockerId' as WarehouseSchedulingQueriesSortByEnum,
  GpCustomerId: 'GpCustomerId' as WarehouseSchedulingQueriesSortByEnum,
};
