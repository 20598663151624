import React, { PureComponent } from 'react';
import {
  Link,
  withRouter,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import BarcodeReader from 'react-barcode-reader';
import { reset } from 'redux-form';
import orderBy from 'lodash.orderby';
import {
  searchOrder,
  markOrderShippingReady,
  markOrderItemQualityChecked,
} from '@APICalls/productionAssembly/actions';
import {
  associateItemsToDevice,
  removeItemAssociation,
} from '@APICalls/devices/actions';
import {
  orderItemStatusEnum,
  lineItemStatusEnum,
  flagStatusEnum,
  orderStatusEnum,
} from '@constants/enums/orderEnums';
import { dtgTranmissisonDecorationEnum } from '@constants/enums/dtgEnums';
import {
  decorationMethodEnum,
  decorationTypeEnum,
} from '@constants/enums/decorationEnums';
import { keyNameEnum } from '@constants/enums/commonEnums';
import {
  productionAssemblyDetailsUrl,
  productionAssemblyUrl,
  productionAssemblyDetailsBarcodeUrl,
} from '@constants/clientUrls/clientUrls';
import {
  scanItemsForm,
  scanDeviceCodeForm,
} from '@constants/reduxForms';
import {
  fetchOrder,
  getOrderItemsStatuses,
  getOrderLineItemsStatuses,
  getOrderLineItemsFlags,
  clearLogos,
  getDtgStations,
  getTransmissionsCount,
  getProductBySku,
  getDtgColoredStyleConfigByColoredStyleId,
  getDtgColoredStyleConfigByManyIds,
  updatePrintQueue,
} from '@redux/productionAssembly/actions';
import { fetchShippingOptions } from '@redux/support/actions';
import {
  getDecorationLocationArtworkSizes,
  getDecorationLocations,
  getColors,
  getDecorationLocationPersonalizationSizes,
} from '@redux/productCatalog/actions';
import {
  swalAlert,
  navigateToPage,
  materialSwal,
} from '@util/componentHelper';
import {
  mapItemsToHashDictionary,
  getHashCodeForArtworkItem,
} from '@util/mappingHelper';
import { extractParameterFromPath } from '@util/stringHelpers';
import { arraysAreEqual } from 'util/arrayHelper';
import ImagePreviewModal from '@sharedComponents/Modal/ImagePreviewModal';
import Button from '@sharedComponents/Buttons/Button';
import Icon from '@sharedComponents/Icons/Icon';
import ProductionAssemblyPrintQueueDrawer from './ProductionAssemblyPrintQueueDrawer';
import SimpleWarningModal from '@sharedComponents/Modal/SimpleWarningModal';
import ScanDeviceCodeModal from './ScanDeviceCodeModal';
import ScanItemsModal from './ScanItemsModal';
import ProductionAssemblyOrderHeader from './ProductionAssemblyOrderHeader';
import ProductionAssemblyReviewItem from './ProductionAssemblyReviewItem';

const printQueueMaxLength = 50;

class ProductionAssemblyReview extends PureComponent {
  state = {
    modalIsOpen: false,
    modalImage: null,
    isStatusHistoryShown: false,
    printQueue: this.props.printQueue,
    printQueueWarningModalIsOpen: false,
    scanDeviceModalIsOpen: false,
    scanItemsModalIsOpen: false,
    barcodeSearch: null,
  };
  barcodeSearchItemRef = React.createRef();

  componentDidMount() {
    const {
      dispatch,
      printQueue,
    } = this.props;
    const orderNumber = extractParameterFromPath(this.props, 'orderNumber', 'number');

    const statuses = [
      dtgTranmissisonDecorationEnum.Sending,
      dtgTranmissisonDecorationEnum.Riping,
    ];

    const invalidPrintQueueItems = printQueue.filter((i) => !i.dtgTabletProfile);

    if (invalidPrintQueueItems) {
      invalidPrintQueueItems.forEach((item) => dispatch(getProductBySku(item.sku)));
    }
    dispatch(fetchOrder(orderNumber));
    dispatch(getColors());
    dispatch(fetchShippingOptions());
    dispatch(getDecorationLocationPersonalizationSizes());
    dispatch(getDecorationLocationArtworkSizes());
    dispatch(getDecorationLocations(decorationTypeEnum.Logo));
    dispatch(getDecorationLocations(decorationTypeEnum.Personalization));
    dispatch(getDtgStations());
    dispatch(getTransmissionsCount(statuses));
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      dispatch,
      printQueue,
      productsDict,
      dtgColoredStyleConfigDict,
      order,
    } = this.props;
    const {
      isStatusHistoryShown,
      barcodeSearch,
    } = this.state;

    const orderNumber = extractParameterFromPath(this.props, 'orderNumber', 'number');
    const oldOrderNumber = extractParameterFromPath(prevProps, 'orderNumber', 'number');
    const barcodeSearchParam = extractParameterFromPath(this.props, 'barcode', 'string');

    if (orderNumber && orderNumber !== oldOrderNumber) {
      this.setState(() => ({
        isStatusHistoryShown: false,
      }), () => {
        dispatch(clearLogos());
        dispatch(fetchOrder(orderNumber));
      });
    }

    if (barcodeSearch !== barcodeSearchParam) {
      this.setState(() => ({ barcodeSearch: barcodeSearchParam }));
    }

    if (this.barcodeSearchItemRef?.current) {
      this.barcodeSearchItemRef.current.scrollIntoView({ behavior: 'smooth' });
    }

    if (!prevState.isStatusHistoryShown && isStatusHistoryShown) {
      this.refreshStatuses();
    }

    if (prevProps.printQueue !== printQueue) {
      this.setState(() => ({
        printQueue,
      }));
    }

    if (prevProps.productsDict !== productsDict
      || prevProps.dtgColoredStyleConfigDict !== dtgColoredStyleConfigDict) {
      const validPrintQueueItems = [];

      printQueue.forEach((item) => {
        if (item.dtgTabletProfile) {
          validPrintQueueItems.push(item);

          return;
        }
        const productDetails = productsDict[item.sku];
        const dtgColoredStyleConfigDictDetails = productsDict[item.sku]
          ? dtgColoredStyleConfigDict[productsDict[item.sku].coloredStyleId]
          : null;
        if (productDetails?.coloredStyleId
          && !dtgColoredStyleConfigDictDetails) {
          dispatch(getDtgColoredStyleConfigByColoredStyleId(productDetails.coloredStyleId));
        }

        const itemWithTabletProfile = {
          ...item,
          decorationIds: [...item.decorationIds],
        };
        if (dtgColoredStyleConfigDictDetails?.dtgTabletProfile) {
          itemWithTabletProfile.dtgTabletProfile = dtgColoredStyleConfigDictDetails.dtgTabletProfile;
        }
        validPrintQueueItems.push(itemWithTabletProfile);
      });
      this.updatePrintQueue(validPrintQueueItems);
    }

    const prevSkus = Object.keys(prevProps.productsDict || {}).sort();
    const curSkus = Object.keys(productsDict || {}).sort();
    const colorStyleIds = this.getDtgColorStyleIds(order?.items, productsDict);
    const hasNewCsIds = colorStyleIds.length && !arraysAreEqual(prevSkus, curSkus);
    if (hasNewCsIds) {
      dispatch(getDtgColoredStyleConfigByManyIds(colorStyleIds, productsDict));
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props;

    dispatch(clearLogos());
  }

  refresh = () => {
    const { dispatch } = this.props;
    const { isStatusHistoryShown } = this.state;
    const orderNumber = extractParameterFromPath(this.props, 'orderNumber', 'number');

    dispatch(fetchOrder(orderNumber));
    if (isStatusHistoryShown) {
      this.refreshStatuses();
    }
  };

  refreshStatuses = () => {
    const {
      dispatch,
      order,
    } = this.props;

    dispatch(getOrderItemsStatuses(order.orderNumber));
    dispatch(getOrderLineItemsStatuses(order.orderNumber));
    dispatch(getOrderLineItemsFlags(order.orderNumber));
  };

  runSearch = (e) => {
    if (e.key && e.key !== keyNameEnum.Enter) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();

    this.search(e.target.value);
  };

  openModal = (img) => {
    this.setState(() => ({
      modalIsOpen: true,
      modalImage: img,
    }));
  };

  closeModal = () => {
    this.setState(() => ({
      modalIsOpen: false,
      modalImage: null,
    }));
  };

  toggleStatusHistory = () => {
    this.setState((prevState) => ({
      isStatusHistoryShown: !prevState.isStatusHistoryShown,
    }));
  };

  readyForShippingOnClick = async () => {
    const { order } = this.props;
    await markOrderShippingReady(order.orderNumber);
    this.refresh();
  };

  selectAllButtonOnClick = async () => {
    const { order } = this.props;
    const orderItems = this.getGroupedItems(order.items, order.gpDocumentId);
    const orderItemsIds = [];

    for (const item of orderItems) {
      if (item.status.status === orderItemStatusEnum.ProductionCompleted) {
        orderItemsIds.push(item.ids);
      }
    }

    const ids = [].concat.apply([], orderItemsIds);

    await markOrderItemQualityChecked(order.orderNumber, { ids });
    this.refresh();
  };

  lookupOrder = async (searchInput) => {
    const orderParsed = searchInput.toLowerCase().startsWith('o')
      ? searchInput.substring(1)
      : searchInput;

    const res = await searchOrder(orderParsed);

    if (res) {
      if (res.items.some((i) => i.barcode === searchInput)) {
        navigateToPage(productionAssemblyDetailsBarcodeUrl(res.orderNumber, searchInput));
      } else {
        navigateToPage(productionAssemblyDetailsUrl(res.orderNumber));
      }
    }
  };

  search = (orderNumber) => {
    if (!orderNumber) {
      swalAlert('Please fill in an Order Number first.');
    } else {
      this.lookupOrder(orderNumber);
    }
  };

  sortOrderLineItems = (items) => (
    items.sort((a, b) => {
      if (a.decorationMethod === decorationMethodEnum.HAG && b.decorationMethod === decorationMethodEnum.EMB) {
        return 1;
      }
      if (a.decorationMethod === decorationMethodEnum.EMB && b.decorationMethod === decorationMethodEnum.HAG) {
        return -1;
      }

      return 0;
    })
  );

  sortItemsByDecorationMethod = (items) => {
    for (const item of items) {
      if (item.logos.length > 0) {
        item.logos = this.sortOrderLineItems(item.logos);
      }
      if (item.personalizations.length > 0) {
        item.personalizations = this.sortOrderLineItems(item.personalizations);
      }
    }

    return items.sort((a, b) => {
      if (a.logos.find((ai) => ai.decorationMethod === decorationMethodEnum.EMB)
        && !b.logos.find((ai) => ai.decorationMethod === decorationMethodEnum.EMB)) {
        return -1;
      }
      if (!a.logos.find((ai) => ai.decorationMethod === decorationMethodEnum.EMB)
        && b.logos.find((ai) => ai.decorationMethod === decorationMethodEnum.EMB)) {
        return 1;
      }

      return 0;
    });
  };

  getGroupedItems = (items) => {
    const itemsWithInventoryStatus = items.map((item) => ({ ...item }));

    const sortedItems = this.sortItemsByDecorationMethod(itemsWithInventoryStatus);
    const dictionary = mapItemsToHashDictionary(sortedItems);

    return this.groupItems(dictionary);
  };

  getItemHistory = (itemIds, lineItemsIds) => {
    const {
      orderItemsStatuses,
      orderLineItemsStatuses,
      orderLineItemsFlags,
    } = this.props;

    let itemHistory = [], lineItemHistory = [], lineItemFlags = [];
    if (orderItemsStatuses) {
      itemHistory = orderItemsStatuses.filter((ois) => itemIds.includes(ois.id) &&
        ois.status !== orderItemStatusEnum.Initial);
    }

    if (orderLineItemsStatuses) {
      lineItemHistory = orderLineItemsStatuses.filter((olis) => lineItemsIds.includes(olis.id) &&
        olis.status !== orderItemStatusEnum.Initial);
    }

    if (orderLineItemsFlags) {
      lineItemFlags = orderLineItemsFlags.filter((olif) => lineItemsIds.includes(olif.orderLineItemId)).map((f) => ({
        user: f.user,
        label: f.isUnflagged ? lineItemStatusEnum.Unflagged : lineItemStatusEnum.Flagged,
        status: f.isUnflagged ? flagStatusEnum.Unflagged : flagStatusEnum.Flagged,
        created: f.dateCreated,
      }));
    }

    return itemHistory.concat(lineItemHistory, lineItemFlags);
  };

  groupItems = (dictionary) => {
    const {
      logoDecorationLocations,
      personalizationDecorationLocations,
      decorationLocationArtworkSizesList,
      decorationLocationPersonalizationSizesList,
      productsDict,
    } = this.props;
    let groupedItems = [];
    let groupedItem = [];

    for (const property in dictionary) {
      if (dictionary.hasOwnProperty(property)) {
        const item = dictionary[property][0];
        const product = productsDict[item.sku];
        groupedItem = {
          quantity: 0,
          ids: [],
          itemBarcodes: [],
          custom: item.custom,
          requiredItem: item.requiredItem,
          brandName: item.brandName,
          sku: item.sku,
          color: product?.coloredStyle.colorGroup,
          size: product?.size,
          imageUrlBack: item.imageUrlBack,
          imageUrlFront: item.imageUrlFront,
          imageUrlLeft: item.imageUrlLeft,
          imageUrlRight: item.imageUrlRight,
          groupedLogos: [],
          logos: [],
          personalizations: item.personalizations,
          status: item.status,
          inventoryAvailable: item.inventoryAvailable,
          promisedDate: item.promisedDate,
          lockerItemId: item.lockerItemId,
          statusHistory: [],
          waterproofedStepRequired: item.waterproofedStepRequired,
          cartLocations: [],
        };

        for (const personalization of groupedItem.personalizations) {
          personalization.ids = [personalization.id];

          if (personalizationDecorationLocations.length > 0) {
            const personalizationDecorationLocation = personalizationDecorationLocations
              .find((l) => l.code === personalization.decorationLocation);

            const personalizationDecorationSize = decorationLocationPersonalizationSizesList
              .find((s) => s.decorationLocationId === personalizationDecorationLocation?.id
                && s.decorationMethod === personalization.decorationMethod);

            personalization.nameHeight = personalizationDecorationSize?.nameHeight;
            personalization.numberHeight = personalizationDecorationSize?.numberHeight;
          }
        }

        for (const logo of item.logos) {
          const logoDecorationLocation = logoDecorationLocations.find((l) => l.code === logo.decorationLocation);
          const logoDecorationSize = logoDecorationLocation && decorationLocationArtworkSizesList
            .find((s) => s.decorationLocationId === logoDecorationLocation.id
              && s.decorationMethod === logo.decorationMethod);

          const groupedLogo = {
            barcodes: [],
            barcode: logo.barcode,
            artworkTaskIds: [],
            decorationLocation: logo.decorationLocation,
            decorationMethod: logo.decorationMethod,
            decorationWidth: logo.decorationWidth
              ? logo.decorationWidth
              : logoDecorationSize?.width,
            decorationHeight: logo.decorationHeight
              ? logo.decorationHeight
              : logoDecorationSize?.height,
            files: logo.files,
            ids: [],
            imageUrl: logo.imageUrl,
            logoUrl: logo.logoUrl,
            previewUrl: logo.previewUrl,
            status: logo.status,
            flag: logo.flag,
            logoId: logo.logoId,
            environmentName: logo.environmentName,
          };

          groupedItem.groupedLogos.push(groupedLogo);
        }

        for (const value of dictionary[property]) {
          groupedItem.quantity += 1;
          groupedItem.ids.push(value.id);
          groupedItem.itemBarcodes.push({
            orderItemId: value.id,
            barcode: value.barcode,
          });
          groupedItem.cartLocations.push({
            orderItemId: value.id,
            cartLocation: value.cartLocation,
          });

          for (const logo of value.logos) {
            const groupedLogo = groupedItem.groupedLogos.find(
              (a) => getHashCodeForArtworkItem(a) === getHashCodeForArtworkItem(logo)
            );
            if (logo.barcode) {
              groupedLogo.barcodes.push(logo.barcode);
            }
            logo.ids = [logo.id];
            groupedItem.logos.push(logo);
            groupedLogo.ids.push(logo.id);
            groupedLogo.artworkTaskIds.push(logo.artworkTaskId);
          }
        }

        const logosIds = groupedItem.groupedLogos.map((ai) => ai.ids).flat();
        const personalizationsIds = groupedItem.personalizations.map((pi) => pi.id).flat();
        const orderItemDecorationsIds = logosIds.concat(personalizationsIds);
        const statusHistory = this.getItemHistory(groupedItem.ids, orderItemDecorationsIds);
        groupedItem.statusHistory = statusHistory;

        groupedItems.push(groupedItem);
      }
    }

    groupedItems = orderBy(groupedItems, [
      'color',
      'size',
    ], [
      'asc',
      'desc',
    ]);

    return groupedItems;
  };

  updatePrintQueue = (printQueue) => {
    const { dispatch } = this.props;
    dispatch(updatePrintQueue(printQueue));
  };

  addItemToPrintQueue = (item) => {
    const printQueue = [...this.state.printQueue];

    const existingItem = printQueue.find((i) => i.id === item.id);
    const itemWord = item.quantity === 1 ? 'item' : 'items';

    if (existingItem) {
      existingItem.quantity = Math.min(existingItem.quantity + item.quantity, item.maxQuantity);
      this.runToast(`${existingItem.quantity} ${itemWord} adjusted on the Print Queue`);
    } else {
      printQueue.push(item);
      this.runToast(`${item.quantity} ${itemWord} added to the Print Queue`);
    }

    const totalQuantity = printQueue
      ? printQueue.reduce((total, current) => (total + (current.quantity || 0)), 0)
      : 0;

    if (totalQuantity > printQueueMaxLength) {
      this.openPrintQueueWarningModal();
    }

    this.updatePrintQueue(printQueue);
  };

  clearPrintQueue = () => {
    this.updatePrintQueue([]);
  };

  removeItemFromPrintQueue = (id) => {
    const { printQueue } = this.state;
    const updatedPrintQueue = printQueue.filter((item) => item.id !== id);

    this.updatePrintQueue(updatedPrintQueue);
  };

  removeItemGroupFromPrintQueue = (value) => {
    const { printQueue } = this.state;
    const updatedPrintQueue = printQueue.filter((item) => item.dtgTabletProfile.id !== value);

    this.updatePrintQueue(updatedPrintQueue);
  };

  updatePrintQueueItemQuantity = (id, quantity, error) => {
    const printQueue = [...this.state.printQueue];
    const orderItemDecoration = { ...printQueue.find((item) => item.id === id) };
    const orderItemDecorationIndex = printQueue.findIndex((item) => item.id === id);

    orderItemDecoration.quantity = Number(quantity);
    orderItemDecoration.error = error;
    printQueue[orderItemDecorationIndex] = orderItemDecoration;

    this.updatePrintQueue(printQueue);
  };

  openPrintQueueWarningModal = () => {
    this.setState(() => ({
      printQueueWarningModalIsOpen: true,
    }));
  };

  closePrintQueueWarningModal = () => {
    this.setState(() => ({
      printQueueWarningModalIsOpen: false,
    }));
  };

  runToast = (message) => {
    toast.dark(message, {
      position: 'bottom-left',
      autoClose: 3000,
    });
  };
  openAssociateItemsToDeviceModal = () => {
    this.setState(() => ({
      scanDeviceModalIsOpen: true,
    }));
  };

  closeAssociateItemsToDeviceModal = () => {
    this.setState(() => ({
      scanDeviceModalIsOpen: false,
    }));
  };

  openScanItemsModal = () => {
    this.setState(() => ({
      scanItemsModalIsOpen: true,
    }));
  };

  closeScanItemsModal = () => {
    const { dispatch } = this.props;

    dispatch(reset(scanItemsForm));
    dispatch(reset(scanDeviceCodeForm));
    this.setState(() => ({
      scanItemsModalIsOpen: false,
    }));
  };

  openNextModal = () => {
    this.closeAssociateItemsToDeviceModal();
    this.openScanItemsModal();
  };

  associateItemsToDevice = async (form) => {
    const { dispatch } = this.props;

    const result = await associateItemsToDevice(form);
    if (result?.success) {
      materialSwal('Success', result.message, 'success');
      dispatch(reset(scanItemsForm));
      dispatch(reset(scanDeviceCodeForm));

      this.closeScanItemsModal();
    }
  };

  handleScan = async (data) => {
    const result = await removeItemAssociation(data);

    if (result?.success) {
      materialSwal('Success', result.message, 'success');
    }
  };

  getDtgColorStyleIds = (items, productsDict) => {
    if (!items || !items.length) return [];

    return (
      items
        .filter((item) => productsDict[item.sku]?.coloredStyleId &&
          item.logos.some((logo) => logo.decorationMethod === decorationMethodEnum.DTG))
        .map((item) => productsDict[item.sku].coloredStyleId)
        .filter((value, index, self) => self.indexOf(value) === index)
    );
  };

  hasSelectedBarcode = (item) => {
    const { barcodeSearch } = this.state;

    return item.barcode === barcodeSearch;
  };

  itemWithBarcodeSelected = (item) => (
    item.itemBarcodes.some(this.hasSelectedBarcode)
  );

  render() {
    const {
      order,
      dtgStations,
      dtgTransmissionsCount,
    } = this.props;

    if (!order || !order.orderNumber) {
      return (
        <div
          className='container'
          data-test='production-assembly'
        />
      );
    }

    const {
      isStatusHistoryShown,
      modalIsOpen,
      modalImage,
      printQueueWarningModalIsOpen,
      printQueue,
      scanDeviceModalIsOpen,
      scanItemsModalIsOpen,
      barcodeSearch,
    } = this.state;

    const filteredPrintQueue = printQueue.filter((item) => item.dtgTabletProfile);

    let items = null;
    if ((order.items || []).length > 0) {
      const groupedItems = this.getGroupedItems(order.items);
      items = groupedItems.map((item, key) => {
        const itemSelected = this.itemWithBarcodeSelected(item);

        return (
          <div
            key={key}
            ref={barcodeSearch && itemSelected ? this.barcodeSearchItemRef : null}
          >
            <ProductionAssemblyReviewItem
              item={item}
              orderId={order.id}
              orderNumber={order.orderNumber}
              checksDisabled={order.isAssemblyDisabled}
              showImage={this.openModal}
              selectAllButtonOnClick={this.selectAllButtonOnClick}
              orderItems={groupedItems}
              refresh={this.refresh}
              isStatusHistoryShown={isStatusHistoryShown}
              onAddToPrintQueue={this.addItemToPrintQueue}
              highlighted={barcodeSearch && itemSelected}
            />
          </div>
        );
      });
    }

    let allQualityChecked = true;
    for (const item of order.items) {
      if (item.status && item.status.status !== orderItemStatusEnum.QualityChecked) {
        allQualityChecked = false;
        break;
      }
    }
    const readyForShippingDisabled = !allQualityChecked || order.isAssemblyDisabled;

    return (
      <>
        <div
          className='container'
          data-test='production-assembly'
        >
          <SimpleWarningModal
            isOpen={printQueueWarningModalIsOpen}
            closeModal={this.closePrintQueueWarningModal}
            confirm={this.closePrintQueueWarningModal}
            warningMessage={'You have added over 50 items to the print queue.'}
          />
          <ImagePreviewModal
            modalIsOpen={modalIsOpen}
            closeModal={this.closeModal}
            imageUrl={modalImage}
          />
          <div className='order-assembly__navigation'>
            <Link
              className='finalize__actions-button'
              to={productionAssemblyUrl}
            >
              <Icon materialIcon={'keyboard_arrow_left'} />
              <span>Back</span>
            </Link>
            <div className='flex__row'>
              <h1>DEPRECATED, DO NOT USE!</h1>
              <div className='text-field'>
                <input
                  className={'has-icon--right'}
                  name={'OrderNumber'}
                  type={'text'}
                  placeholder={'Jump to Order'}
                  onKeyDown={this.runSearch}
                />
                <Icon
                  materialIcon={'arrow_forward'}
                  classes={'text-field__icon--right'}
                />
              </div>
              <Button
                type={'primary'}
                text={'Associate Items To Device'}
                onClick={this.openAssociateItemsToDeviceModal}
                classes={'ml-20'}
              />
              <BarcodeReader
                onScan={this.handleScan}
              />
              <ScanDeviceCodeModal
                isOpen={scanDeviceModalIsOpen}
                closeModal={this.closeAssociateItemsToDeviceModal}
                openNextModal={this.openNextModal}
              />
              <ScanItemsModal
                isOpen={scanItemsModalIsOpen}
                closeModal={this.closeScanItemsModal}
                onSubmit={this.associateItemsToDevice}
              />
              {
                order.status &&
                  (order.status.status === orderStatusEnum.Shipped
                    || order.status.status === orderStatusEnum.ShippingReady)
                  ? <span className='m-l-24 header-status'>{order.status.label}</span>
                  : (
                    <Button
                      type={'primary'}
                      text={'Ready for shipping'}
                      onClick={this.readyForShippingOnClick}
                      disabled={readyForShippingDisabled}
                      classes={'ml-20'}
                    />
                  )
              }
            </div>
          </div>
          <ProductionAssemblyOrderHeader
            order={order}
            toggleStatusHistory={this.toggleStatusHistory}
            isStatusHistoryShown={isStatusHistoryShown}
          />
          <div className='order-assembly__items'>
            {items}
          </div>

        </div>
        <ProductionAssemblyPrintQueueDrawer
          printQueue={filteredPrintQueue}
          onClearPrintQueue={this.clearPrintQueue}
          onRemoveItemFromPrintQueue={this.removeItemFromPrintQueue}
          onRemoveItemGroupFromPrintQueue={this.removeItemGroupFromPrintQueue}
          onPrintQueueItemQuantityUpdate={this.updatePrintQueueItemQuantity}
          dtgStations={dtgStations}
          dtgAnyActiveTransmissions={dtgTransmissionsCount > 0}
        />
      </>
    );
  }
}

ProductionAssemblyReview.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      orderNumber: PropTypes.string.isRequired,
    }).isRequired,
  }),
  order: PropTypes.object.isRequired,
  orderItemsStatuses: PropTypes.array.isRequired,
  orderLineItemsStatuses: PropTypes.array.isRequired,
  orderLineItemsFlags: PropTypes.array.isRequired,
  logoDecorationLocations: PropTypes.array.isRequired,
  decorationLocationArtworkSizesList: PropTypes.array.isRequired,
  decorationLocationPersonalizationSizesList: PropTypes.array,
  dtgStations: PropTypes.array.isRequired,
  printQueue: PropTypes.array,
  dtgTransmissionsCount: PropTypes.number.isRequired,
  productsDict: PropTypes.object,
  dtgColoredStyleConfigDict: PropTypes.object,
};

const mapStateToProps = ({
  productionAssembly,
  productCatalog,
}) => ({
  order: productionAssembly.order,
  orderItemsStatuses: productionAssembly.orderItemsStatuses,
  orderLineItemsStatuses: productionAssembly.orderLineItemsStatuses,
  orderLineItemsFlags: productionAssembly.orderLineItemsFlags,
  printQueue: productionAssembly.printQueue,
  dtgTransmissionDecorations: productionAssembly.dtgTransmissionDecorations,
  logoDecorationLocations: productCatalog.logoDecorationLocations,
  personalizationDecorationLocations: productCatalog.personalizationDecorationLocations,
  decorationLocationArtworkSizesList: productCatalog.decorationLocationArtworkSizesList,
  decorationLocationPersonalizationSizesList: productCatalog.decorationLocationPersonalizationSizesList,
  dtgStations: productionAssembly.dtgStations,
  dtgTransmissionsCount: productionAssembly.transmissionsCount,
  productsDict: productionAssembly.productsDict,
  dtgColoredStyleConfigDict: productionAssembly.dtgColoredStyleConfigDict,
});

export default withRouter(connect(mapStateToProps)(ProductionAssemblyReview));
