import React from 'react';
import { featureFlags } from '@constants/common';
import {
  orderItemStatusValueEnum,
  orderStatusEnum,
} from '@constants/enums/orderEnums';
import { flagTypeEnum } from '@constants/enums/commonEnums';
import PermissionsEnum from '@constants/enums/permissionsEnum';
import { decorationMethodEnum } from '@constants/enums/decorationEnums';
import ShowMoreActionsButton from '@sharedComponents/Buttons/ShowMoreActionsButton';
import { SubmitStatusEnum } from '@api/fulfillment/models';
import { useFlags } from 'launchdarkly-react-client-sdk';

const changeArtworkAllowedStatuses = [
  orderStatusEnum.Initial,
  orderStatusEnum.ArtworkTasksProcessing,
  orderStatusEnum.ArtworkTasksCompleted,
];

const OrderManagementDetailsItemActions = React.memo(({
  order,
  item,
  openReplaceItemBySkuModal,
  openReplaceItemModal,
  editPersonalization,
  addPersonalization,
  openRemovePersonalizationModal,
  openChangeDecorationMethodModal,
  refundItem,
  changeArtwork,
  shipmentNumbersList,
  orderFlags,
  isCanceled,
  isOrderCompleteRefund,
  itemVariation,
  orderExistsOnCore,
  itemCorePricingDetails,
}) => {
  const { hfDisableOrderActions } = useFlags();

  const handleOpenReplaceBySkuModal = () => {
    openReplaceItemBySkuModal(item);
  };

  const handleOpenReplaceItemModal = () => {
    openReplaceItemModal(item);
  };

  const handleOpenChangeDecorationModal = () => {
    openChangeDecorationMethodModal(item);
  };

  const cancelOnClickWithRefund = () => {
    refundItem(item, true);
  };

  const refundOnClick = () => {
    refundItem(item, false);
  };

  const handleChangeArtwork = () => {
    changeArtwork(item);
  };

  const hasFlaggedArtwork = () => {
    let hasFlaggedAwk = false;
    const artwork = item.logos.find((art) => !art.isCanceled);
    if (artwork) {
      const ids = artwork.ids.filter(
        (orderItemDecorationId) => orderFlags?.find(
          (flag) => flag.orderItemDecorationId === orderItemDecorationId && flag.type === flagTypeEnum.WrongArtwork
        ) !== undefined
      );
      if (ids && ids.length > 0) {
        hasFlaggedAwk = true;
      }
    }

    return hasFlaggedAwk;
  };

  const handleOpenRemovePersonalizationModal = () => {
    openRemovePersonalizationModal(item);
  };

  const editPersonalizationOnClick = () => {
    editPersonalization(item);
  };

  const canChangeDecorationMethod = () => {
    const hasHagDecorationMethod = item.logos.every((logo) => logo.decorationMethod === decorationMethodEnum.HAG);
    const hasEmbDecorationMethod = item.logos.every((logo) => logo.decorationMethod === decorationMethodEnum.EMB);

    const hagToDtgUpdate = item.styleDecorationMethod === decorationMethodEnum.DTG && hasHagDecorationMethod;
    const embToHagUpdate = item.styleDecorationMethod === decorationMethodEnum.HAG && hasEmbDecorationMethod;

    return hagToDtgUpdate || embToHagUpdate;
  };

  const hasActivePersonalizations = item.personalizations.some((element) => !element.isCanceled);

  const hasMorePossiblePersonalizations
    = (item?.custom || item?.personalizations?.length === 0)
    // If does not exist on core, allow any available personalizations
    && (!orderExistsOnCore || (item?.personalizations?.length < itemVariation?.item?.personalizations?.length));

  let hideItemCancelingOption = false;
  if (item?.status?.value >= orderItemStatusValueEnum.InPicking) {
    hideItemCancelingOption = true;
  }

  const isRefundableItem = orderExistsOnCore
    ? itemCorePricingDetails?.pricePaid !== 0
    : item?.pricePaid !== 0
    && !item?.isCompleteRefund;

  const itemCanBeModified =
    !item.vendorFulfilledItem
    || (item.vendorFulfilledItem &&
      item.vendorOrderId === null);

  const orderAccountingActionsDisabled =
    (hfDisableOrderActions && order?.netSuiteAccountingSubmitStatus !== SubmitStatusEnum.Done);

  return (
    <div>
      <ShowMoreActionsButton
        disabled={orderAccountingActionsDisabled}
        tooltip={orderAccountingActionsDisabled ? 'Order actions are disabled until the order is submitted to NetSuite.' : ''}
        actions={[
          {
            text: 'Replace Item',
            action: handleOpenReplaceItemModal,
            isVisible: !isCanceled
              && itemCanBeModified
              && item.status.value < orderItemStatusValueEnum.InPicking,
          },
          {
            text: 'Replace Item by SKU',
            action: handleOpenReplaceBySkuModal,
            isVisible: !isCanceled
              && itemCanBeModified
              && item.status.value < orderItemStatusValueEnum.InPicking,
          },
          {
            text: 'Change Artwork',
            action: handleChangeArtwork,
            isVisible: !isCanceled
              && !item.custom
              && itemCanBeModified
              && changeArtworkAllowedStatuses.includes(order.status.status),
          },
          {
            text: 'Change Flagged Artwork',
            action: handleChangeArtwork,
            isVisible: hasFlaggedArtwork && !isCanceled,
          },
          {
            text: 'Change Decoration Method',
            action: handleOpenChangeDecorationModal,
            isVisible: canChangeDecorationMethod(),
          },
          {
            text: 'Add Personalization',
            action: addPersonalization,
            isVisible: hasMorePossiblePersonalizations
              && !isCanceled
              && itemCanBeModified
              && item.status.value < orderItemStatusValueEnum.Assembled,
          },
          {
            text: 'Edit Personalization(s)',
            action: editPersonalizationOnClick,
            isVisible: hasActivePersonalizations
              && !isCanceled
              && itemCanBeModified
              && item.status.value < orderItemStatusValueEnum.Assembled,
          },
          {
            text: 'Remove Personalization',
            action: handleOpenRemovePersonalizationModal,
            isVisible: hasActivePersonalizations
              && !isCanceled
              && !item.custom
              && itemCanBeModified
              && item.status.value < orderItemStatusValueEnum.Assembled,
          },
          {
            text: 'Refund',
            action: refundOnClick,
            isVisible: (!shipmentNumbersList || shipmentNumbersList.length === 0)
              && isRefundableItem && featureFlags.orderItemRefundEnabled
              && !isCanceled
              && !isOrderCompleteRefund
              && !item.voucherId,
            permissions: [PermissionsEnum.OrderManagementEditRefundOrder],
          },
          {
            text: 'Cancel Item',
            action: cancelOnClickWithRefund,
            isVisible: (!shipmentNumbersList || shipmentNumbersList.length === 0)
              && !hideItemCancelingOption
              && !isCanceled
              && itemCanBeModified,
            hasBottomBorder: false,
            permissions: [PermissionsEnum.OrderManagementEditCancelOrder],
            isDangerous: true,
          },
        ]}
      />
    </div>
  );
});

export default OrderManagementDetailsItemActions;
