import React from 'react';
import { bulkBuildUrl } from '@constants/clientUrls/clientUrls';
import MenuTile from './MenuTile';

const BulkBuildOrgsAndStoresLink = React.memo(() => (
  <MenuTile
    title={'Bulk Build Organizations and Stores'}
    url={bulkBuildUrl}
    isMaterialIcon={true}
    icon={'store_mall_directory'}
  />
));

export default BulkBuildOrgsAndStoresLink;
