/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */
import type { SimpleOrderItemDto } from './simpleOrderItemDto';

export interface PtlWallOrderItemDto {
  id?: number;
  uuid?: string | null;
  created?: string | null;
  updated?: string | null;
  deleted?: string | null;
  orderItemId?: number;
  orderNumber?: number;
  isScanned?: boolean;
  orderItem?: SimpleOrderItemDto;
  ptlWallRowPositionId?: number;
}
