import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import Label from '@sharedComponents/Display/Label';

const OrderManagementAddNewOrderHeaderTitle = React.memo(({ order }) => {
  if (!order) {
    return <span className='header__bar-content' />;
  }

  const title = "Order";

  return (
    <div className='header__bar-content'>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className='header__bar-title align__center'>
        <span>{title}</span>
        <span className='align__center'>
          {
            order.prd &&
            <Label
              text={'PRD'}
              type={'gossamer'}
            />
          }
          <Label
            text={'DRAFT'}
            type={'lynch'}
          />
        </span>
      </div>
    </div>
  );
});

OrderManagementAddNewOrderHeaderTitle.propTypes = {
  order: PropTypes.object.isRequired,
};

const mapStateToProps = ({ orderManagement }) => ({
  order: orderManagement.tempOrder,
});

export default connect(mapStateToProps)(OrderManagementAddNewOrderHeaderTitle);
