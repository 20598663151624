/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Catalog Homefield API
 * OpenAPI spec version: v1
 */
import type { StyleCrossSellItem } from './styleCrossSellItem';

export interface CreateOrUpdateStyleCrossSellCommand {
  styleCrossSells?: StyleCrossSellItem[] | null;
}
