/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Orders Homefield API
 * OpenAPI spec version: v1
 */

export type NewPaymentTypeEnum = 'Undefined' | 'Charge' | 'Refund';


export const NewPaymentTypeEnum = {
  Undefined: 'Undefined' as NewPaymentTypeEnum,
  Charge: 'Charge' as NewPaymentTypeEnum,
  Refund: 'Refund' as NewPaymentTypeEnum,
};
