/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Catalog Homefield API
 * OpenAPI spec version: v1
 */

export interface BrandDto {
  id: number;
  uuid?: string | null;
  created: string;
  updated: string;
  deleted: string;
  code: string;
  name: string;
  imageUrl?: string | null;
}
