/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */
import type { HagTransmissionStatusEnum } from './hagTransmissionStatusEnum';
import type { SortDirectionEnum } from './sortDirectionEnum';

export type GetHomefieldApiHagTransmissionsParams = { status?: HagTransmissionStatusEnum; hagTransmissionId?: number; sortDirection?: SortDirectionEnum; pageNumber?: number; pageSize?: number };
