/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Financials Homefield API
 * OpenAPI spec version: v1
 */
import type { VoucherOrderPreviewDto } from './voucherOrderPreviewDto';

export interface VoucherOrderPreviewDtoApiResponse {
  success?: boolean;
  message?: string | null;
  result?: VoucherOrderPreviewDto;
}
