/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */
import type { PtlWallBinSizeEnum } from './ptlWallBinSizeEnum';

export interface UpdatePtlWallCommand {
  ptlWallId?: number | null;
  name?: string | null;
  binSize?: PtlWallBinSizeEnum;
  numberOfRows?: number;
  numberOfRowPositions?: number;
  devices?: string[] | null;
  colors?: string[] | null;
}
