import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { decorationMethodsOptions } from '@constants/options/optionsValues';
import { keyNameEnum } from '@constants/enums/commonEnums';
import {
  fetchProductsWithoutLogos,
  fetchAllLockerProducts,
  fetchLockerFloodColors,
} from '@redux/lockerManager/actions';
import ImagePreviewCell from '@sharedComponents/Table/TableCells/ImagePreviewCell';
import TitleDescriptionCell from '@sharedComponents/LockerItems/LockerItemsTableContent/TitleDescriptionCell';
import MultiSelectDropdown from '@sharedComponents/Inputs/Dropdowns/MultiSelectDropdown/MultiSelectDropdown';
import MaterialCheckbox from '@sharedComponents/Inputs/MaterialCheckbox';
import ImageModal from '@sharedComponents/Modal/ImageModal';
import Table from '@sharedComponents/Table/Table';
import SearchFilter from '@sharedComponents/Inputs/SearchFilter';
import SelectAllCell from '@sharedComponents/Table/TableCells/SelectAllCell';
import ItemLocationAndSizeCell from '@sharedComponents/LockerItems/LockerItemsTableContent/ItemLocationAndSizeCell';
import IconCell from '../CellActions/IconCell';
import { fetchColors } from 'redux/support/actions';

const ItemsTable = Table();

class AssignLogosToItemsTable extends Component {
  state = {
    icon: 'check_box_outline_blank',
    imageModalIsOpen: false,
    imageUrl: '',
    sortByState: '',
    sortOrderState: '',
    searchInput: '',
    floodColorFilter: [],
    decorationFilter: [],
    lockerFloodColors: this.props.colors?.filter((c) => (this.props.lockerFloodColorCodes || [])?.includes(c.code)),
  };

  componentDidMount() {
    const {
      dispatch,
      lockerId,
    } = this.props;

    dispatch(fetchLockerFloodColors(lockerId));
    dispatch(fetchColors());
    this.search();
  }

  componentDidUpdate(prevProps) {
    const {
      filterBlankProducts,
      colors,
      lockerFloodColorCodes,
    } = this.props;

    if (prevProps.filterBlankProducts !== filterBlankProducts) {
      this.search();
    }

    if (colors
      && lockerFloodColorCodes
      && (prevProps.lockerFloodColorCodes !== lockerFloodColorCodes
        || prevProps.colors !== colors)) {
      const lockerFloodColors = colors.filter((c) => lockerFloodColorCodes.includes(c.code));
      this.setState(() => ({ lockerFloodColors }));
    }
  }

  openImageModal = (imageUrl) => {
    this.setState({
      imageModalIsOpen: true,
      imageUrl,
    });
  };

  closeImageModal = () => {
    this.setState({
      imageModalIsOpen: false,
      imageUrl: '',
    });
  };

  isSelected = (key) => {
    const { selection } = this.state;

    return selection.includes(key);
  };

  clearSearch = () => {
    this.setState({
      searchInput: '',
    }, this.search);
  };

  filterKey = (e) => {
    if (e.key && e.key !== keyNameEnum.Enter) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();

    const searchInput = e.target.value;

    this.setState(() => ({
      searchInput,
    }), this.search);
  };

  search = () => {
    const {
      dispatch,
      lockerId,
      filterBlankProducts,
    } = this.props;

    const {
      searchInput,
      floodColorFilter,
      decorationFilter,
    } = this.state;

    if (filterBlankProducts) {
      return dispatch(fetchProductsWithoutLogos(
        lockerId,
        decorationFilter,
        searchInput,
        floodColorFilter,
        true
      ));
    }

    return dispatch(fetchAllLockerProducts(
      lockerId,
      [],
      [],
      decorationFilter,
      searchInput,
      floodColorFilter,
      true
    ));
  };

  colorChange = (colors) => {
    if (colors) {
      this.setState({
        floodColorFilter: colors,
      }, this.search);
    }
  };

  decorationChange = (decorationFilter) => {
    if (decorationFilter) {
      this.setState({
        decorationFilter,
      }, this.search);
    }
  };

  getColumns = () => {
    const {
      productsToAddLogo,
      selectProduct,
      selectAllProducts,
      selectAll,
    } = this.props;

    const columns = [
      {
        Header: () => (
          <SelectAllCell
            isSelected={selectAll}
            selectAllItems={selectAllProducts}
          />
        ),
        width: 60,
        accessor: '',
        Cell: (cellProps) => (
          <IconCell
            productsToAddLogo={productsToAddLogo}
            product={cellProps.value}
            selectProduct={selectProduct}
            lockingEnabled={false}
          />
        ),
      },
      {
        Header: 'Image',
        accessor: 'cached_image',
        className: 'no-padding',
        Cell: (cellProps) => (
          <ImagePreviewCell
            imageUrl={cellProps.value}
            openModalWithUrl={this.openImageModal}
          />
        ),
      },
      {
        Header: 'Code & Description',
        accessor: '',
        width: 300,
        Cell: (cellProps) => (
          <div className='logo-bank__assignment--code'>
            <TitleDescriptionCell item={cellProps.value} />
          </div>
        ),
      },
      {
        Header: 'Garment Color',
        accessor: 'color',
      },
      {
        Header: 'Flood Color',
        accessor: 'flood_color',
      },
      {
        Header: 'Decoration',
        accessor: 'decoration_method',
      },
      {
        Header: 'Location & Size',
        accessor: '',
        Cell: (cellProps) => <ItemLocationAndSizeCell item={cellProps.value} />,
      },
    ];

    return columns;
  };

  render() {
    const {
      imageUrl,
      imageModalIsOpen,
      lockerFloodColors,
    } = this.state;

    const {
      queue,
      filterBlankProducts,
      toggleFilterBlankLogos,
      enableMultipleLogosOnProduct,
    } = this.props;

    const columns = this.getColumns();

    return (
      <div className='sheet'>
        <ImageModal
          isOpen={imageModalIsOpen}
          closeModal={this.closeImageModal}
          imageUrl={imageUrl}
        />
        <div className='flex'>
          <MultiSelectDropdown
            objects={lockerFloodColors}
            itemText={'flood colors'}
            updateCallback={this.colorChange}
            textKey={'code'}
            valueKey={'code'}
            classNames={'ml-15'}
            color={true}
          />
          <MultiSelectDropdown
            objects={decorationMethodsOptions}
            itemText={'decorations'}
            updateCallback={this.decorationChange}
            textKey={'name'}
            valueKey={'value'}
            classNames={'ml-15'}
          />
          <SearchFilter
            search={this.filterKey}
            classes={'ml-15'}
            clearSearch={this.clearSearch}
          />
          {
            enableMultipleLogosOnProduct &&
            <MaterialCheckbox
              text={'Filter blank items'}
              checked={filterBlankProducts}
              onClick={toggleFilterBlankLogos}
              classes={'ml-10'}
            />
          }
        </div>
        <ItemsTable
          data={queue}
          columns={columns}
          showPagination={false}
          defaultPageSize={-1}
        />
      </div>
    );
  }
}

AssignLogosToItemsTable.propTypes = {
  queue: PropTypes.arrayOf(PropTypes.object).isRequired,
  lockerId: PropTypes.number.isRequired,
  lockerFloodColorCodes: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectProduct: PropTypes.func.isRequired,
  selectAllProducts: PropTypes.func.isRequired,
  productsToAddLogo: PropTypes.array.isRequired,
  selectAll: PropTypes.bool.isRequired,
  filterBlankProducts: PropTypes.bool.isRequired,
  enableMultipleLogosOnProduct: PropTypes.bool.isRequired,
  toggleFilterBlankLogos: PropTypes.func.isRequired,
  colors: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    hexValue: PropTypes.string,
    brightness: PropTypes.string.isRequired,
  })).isRequired,
};

const mapStateToProps = ({
  lockerManager,
  support,
}, ownProps) => ({
  queue: ownProps.filterBlankProducts
    ? lockerManager.blankProducts
    : lockerManager.allLockerProducts,
  lockerFloodColorCodes: lockerManager.lockerFloodColors,
  colors: support.colors,
});

export default connect(mapStateToProps)(AssignLogosToItemsTable);
