/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */
import type { ServiceTypeEnum } from './serviceTypeEnum';

export interface OrderAccountingSubmitResultDto {
  id?: number;
  dateCreatedUtc?: string | null;
  orderId?: number;
  userId?: string | null;
  orderData?: string | null;
  isSuccess?: boolean;
  statusCode?: number;
  elapsedTime?: number;
  message?: string | null;
  result?: string | null;
  serviceType?: ServiceTypeEnum;
}
