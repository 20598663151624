import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { filterArtTeamColors } from '@util/colorHelper';

class ProductionAssemblyLogoCell extends PureComponent {
  handleOpenModal = () => {
    const {
      logoUrl,
      openModal,
    } = this.props;

    openModal(logoUrl);
  };

  render() {
    const {
      logoUrl,
      logoColors,
      imageSize,
      colors,
    } = this.props;

    const colorGroups = [];

    if (
      logoColors
      && logoColors.length > 0
      && Object.keys(colors).length > 0
    ) {
      const logoArtTeamColors = filterArtTeamColors(logoColors);
      const logoArtTeamColorsCodes = logoArtTeamColors.map((c) => c.code);
      const logoArtTeamColorInfo = colors.filter((c) => logoArtTeamColorsCodes.includes(c.code));

      const colorElements = logoArtTeamColorInfo.map((color, index) => (
        <div
          className='color-swatch-item size--xs'
          key={index}
        >
          <div
            className='color-swatch-item__color size--xs'
            style={{ backgroundColor: color.hexValue }}
          />
          <div className='text'>{color.threadValue}</div>
        </div>
      ));

      for (let i = 0; i < colorElements.length; i += 4) {
        colorGroups.push(
          <div
            className='color-swatches--horizontal'
            key={i}
          >
            {colorElements.slice(i, i + 4)}
          </div>
        );
      }
    }

    const imageSizeClass = imageSize ? `image-container--${imageSize}` : '';
    const thumbnailSizeClass = imageSize ? `sheet__list-table-thumbnail--${imageSize}` : '';

    return (
      <div className='flex'>
        <div
          className={`image-container ${imageSizeClass}`}
          onClick={this.handleOpenModal}
        >
          <div
            className={`sheet__list-table-thumbnail cursor-pointer ${thumbnailSizeClass}`}
            style={{ backgroundImage: `url("${logoUrl}")` }}
          >
            <div className='details__item-overlay'>
              <i className='material-icons details__item-preview-icon'>search</i>
            </div>
          </div>
        </div>
        <div className='logo-bank__color-swatches'>
          {colorGroups}
        </div>
      </div>
    );
  }
}

ProductionAssemblyLogoCell.propTypes = {
  logoUrl: PropTypes.string.isRequired,
  openModal: PropTypes.func.isRequired,
  logo: PropTypes.object,
  logoColors: PropTypes.arrayOf(PropTypes.shape({
    code: PropTypes.string,
    selectionType: PropTypes.number,
    id: PropTypes.number.isRequired,
    hexValue: PropTypes.string,
  })).isRequired,
  imageSize: PropTypes.string,
  colors: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    dtgCode: PropTypes.string,
    cmykValue: PropTypes.string.isRequired,
    dtgCmykValue: PropTypes.string.isRequired,
    hexValue: PropTypes.string,
    threadValue: PropTypes.string,
    brightness: PropTypes.string.isRequired,
    isArtwork: PropTypes.bool,
    isFlood: PropTypes.bool,
  })).isRequired,
};

export default ProductionAssemblyLogoCell;
