import React, {
  PureComponent,
  Fragment,
} from 'react';
import {
  Container as GridContainer,
  Row,
  Col,
} from 'react-grid-system';
import ProductionAssemblyPrintQueueInputSpinner from './ProductionAssemblyPrintQueueInputSpinner';
import Button from '@sharedComponents/Buttons/Button';
import Icon from '@sharedComponents/Icons/Icon';
import LogoOrTextCell from './Cells/ProductionAssemblyLogoOrTextCell';
import PropTypes from 'prop-types';
import ImagePreviewModal from '@sharedComponents/Modal/ImagePreviewModal';
import Expander from '@sharedComponents/Buttons/Expander';
import ProductionAssemblyPrintQueueSendToPrintModal from './Modals/ProductionAssemblyPrintQueueSendToPrintModal';

class ProductionAssemblyPrintQueueItem extends PureComponent {
  state = {
    isExpanded: true,
    logoImagePreview: {
      isOpen: false,
      imageUrl: null,
    },
    sendGroupToPrintModalIsOpen: false,
  };

  changeExpand = () => {
    this.setState((prevState) => ({
      isExpanded: !prevState.isExpanded,
    }));
  };

  openLogoPreviewModal = (imageUrl) => {
    this.setState(() => ({
      logoImagePreview: {
        isOpen: true,
        imageUrl,
      },
    }));
  };

  closeLogoPreviewModal = () => {
    this.setState(() => ({
      logoImagePreview: {
        isOpen: false,
        imageUrl: null,
      },
    }));
  };

  formatUnderbaseTimeFromMilliseconds = (milliseconds) => {
    const millisecondsInMin = 60000;
    const millisecondsInSec = 1000;

    const min = Math.floor(milliseconds / millisecondsInMin);
    const sec = Math.floor((milliseconds - min * millisecondsInMin) / millisecondsInSec);
    const ms = milliseconds - min * millisecondsInMin - sec * millisecondsInSec;

    const minText = min ? `${min} min ` : '';
    const secText = sec ? `${sec} sec ` : '';
    const msText = ms ? `${ms} ms ` : ' 0 ms ';

    return `${minText}${secText}${msText}`;
  };

  openSendToPrintModal = () => {
    const { dtgTabletProfile } = this.props;
    dtgTabletProfile.waitUnderbaseMillisecondsFormatted =
      this.formatUnderbaseTimeFromMilliseconds(dtgTabletProfile.waitUnderbaseMilliseconds);
    this.setState(() => ({ sendGroupToPrintModalIsOpen: true }));
  };

  closeSendToPrintModal = () => {
    this.setState(() => ({ sendGroupToPrintModalIsOpen: false }));
  };

  sendToPrint = (formData) => {
    const {
      dtgTabletProfile,
      onSubmit,
      disableSendButtons,
    } = this.props;

    const data = {
      stationIdentifier: formData.stationIdentifier,
      tabletProfileId: dtgTabletProfile.id,
    };

    onSubmit(data);
    this.closeSendToPrintModal();
    disableSendButtons();
  };

  render() {
    const {
      orderItems,
      totalItemsCount,
      dtgTabletProfile,
      onRemoveGroup,
      updatePrintQueueItemQuantity,
      removeItemFromQueue,
      dtgStations,
      dtgAnyActiveTransmissions,
      disabledAfterSendingToPrint,
    } = this.props;

    const {
      isExpanded,
      logoImagePreview,
      sendGroupToPrintModalIsOpen,
    } = this.state;
    const itemText = totalItemsCount > 1 ? 'items' : 'item';
    const headerText = `${dtgTabletProfile.name} (${totalItemsCount} ${itemText})`;

    return (
      <div>
        <ImagePreviewModal
          modalIsOpen={logoImagePreview.isOpen}
          closeModal={this.closeLogoPreviewModal}
          imageUrl={logoImagePreview.imageUrl}
        />
        <ProductionAssemblyPrintQueueSendToPrintModal
          isOpen={sendGroupToPrintModalIsOpen}
          closeModal={this.closeSendToPrintModal}
          onSubmit={this.sendToPrint}
          tabletProfile={dtgTabletProfile}
          itemsCount={totalItemsCount}
          dtgStations={dtgStations}
        />
        <GridContainer className='print-queue'>
          <Row>
            <Col md={8}>
              <div className='print-queue__expander'>
                <Expander
                  isExpanded={isExpanded}
                  toggle={this.changeExpand}
                  collapseText={headerText}
                  expandText={headerText}
                />
              </div>
            </Col>
            <Col md={4}>
              <div className='print-queue__buttons'>
                <Button
                  text={'Remove'}
                  icon={(
                    <Icon
                      materialIcon={'close'}
                    />
                  )}
                  classes={'uppercase btn button--size-m btn-borderless btn-borderless--red'}
                  onClick={onRemoveGroup.bind(null, dtgTabletProfile.id)}
                />
                <Button
                  type={'primary'}
                  text={'Send to print'}
                  classes={'uppercase button--size-m ml-2'}
                  onClick={this.openSendToPrintModal}
                  disabled={dtgAnyActiveTransmissions || disabledAfterSendingToPrint}
                />
              </div>
            </Col>
          </Row>
        </GridContainer>
        {
          isExpanded &&
          <Fragment>
            <div className='order-assembly__print-queue-list-body'>
              <GridContainer>
                {
                  Object.keys(orderItems).map((groupKey, i) => (
                    <Fragment key={i} >
                      <Row
                        className='order-assembly__print-queue-row order-assembly__print-queue-row--gray'
                        align='center'
                      >
                        <Col md={12}>
                          <div className='flex justify__space-between'>
                            <div>
                              <b>
                                {groupKey}
                              </b>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      {
                        orderItems[groupKey].map((item, key) => (
                          <Row
                            key={key}
                            className='order-assembly__print-queue-row'
                            align='center'
                          >
                            <Col md={1}>
                              <LogoOrTextCell
                                item={item}
                                openModal={this.openLogoPreviewModal}
                                logoCellSize={'s'}
                              />
                            </Col>
                            <Col md={1}>{item.sku}</Col>
                            <Col>{item.size}</Col>
                            <Col md={1}>{item.colorGroup}</Col>
                            <Col md={1}>{item.brandName}</Col>
                            <Col md={2}>{item.categoryName}</Col>
                            <Col md={1}>{item.decorationLocation}</Col>
                            <Col md={2}>{item.environmentName}</Col>
                            <Col md={1}>
                              <ProductionAssemblyPrintQueueInputSpinner
                                quantity={item.quantity}
                                maxQuantity={item.maxQuantity}
                                onChange={updatePrintQueueItemQuantity}
                                name={item.id}
                              />
                            </Col>
                            <Col
                              md={1}
                              align={'right'}
                            >
                              <Button
                                type={'secondary'}
                                text={'Remove'}
                                classes={'uppercase button--size-s'}
                                onClick={removeItemFromQueue.bind(null, item)}
                              />
                            </Col>
                          </Row>
                        ))
                      }
                    </Fragment>
                  ))
                }
              </GridContainer>
            </div>
          </Fragment>
        }
      </div>
    );
  }
}

ProductionAssemblyPrintQueueItem.propTypes = {
  dtgTabletProfile: PropTypes.object.isRequired,
  orderItems: PropTypes.object.isRequired,
  totalItemsCount: PropTypes.number.isRequired,
  onRemoveGroup: PropTypes.func.isRequired,
  updatePrintQueueItemQuantity: PropTypes.func.isRequired,
  removeItemFromQueue: PropTypes.func.isRequired,
  dtgStations: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
  dtgAnyActiveTransmissions: PropTypes.bool.isRequired,
  disabledAfterSendingToPrint: PropTypes.bool.isRequired,
  disableSendButtons: PropTypes.func.isRequired,
};

export default ProductionAssemblyPrintQueueItem;
