import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Image from '@sharedComponents/Display/Image';
import Tag from '@sharedComponents/Display/Tag';

class LayoutSuggestion extends PureComponent {
  state = {
    rowHeight: '',
  };

  componentDidMount() {
    const { saveHeight } = this.props;

    const rowHeight = this.getRowHeight();

    this.saveRowHeightToState(rowHeight);

    if (rowHeight) {
      saveHeight(rowHeight);
    }
  }

  saveRowHeightToState = (rowHeight) => {
    this.setState({ rowHeight });
  };

  getRowHeight = () => this.suggestionRow.clientHeight;

  alreadyInList = (layout, layouts) => !!layouts.find((l) => l.id === layout.id);

  onClickOnSuggestion = async (e) => {
    const {
      layout,
      addSuggestion,
      layouts,
    } = this.props;

    e.preventDefault();
    e.stopPropagation();

    if (this.alreadyInList(layout, layouts)) return;

    await addSuggestion(layout);
  };

  setSuggestionRowRef = (elem) => {
    this.suggestionRow = elem;
  };

  render() {
    const {
      layout,
      layouts,
    } = this.props;

    const imageClass = 'layouts-table__image catalog-details__layouts-input__suggestion--image';

    return (
      <div
        className={`suggestions__row catalog-details__layouts-input__suggestion align__center justify__space-between ${this.alreadyInList(layout, layouts) ? 'disabled' : ''}`}
        onClick={this.onClickOnSuggestion}
        ref={this.setSuggestionRowRef}
      >
        <div>
          <div><b>{layout.name}</b></div>
          <Tag value={layout.sport?.name} />
        </div>
        <div className='align__center'>
          {
            layout.imageUrlFront &&
            <Image
              url={layout.imageUrlFront}
              classes={imageClass}
            />
          }
          {
            layout.imageUrlBack &&
            <Image
              url={layout.imageUrlBack}
              classes={imageClass}
            />
          }
          {
            layout.imageUrlLeft &&
            <Image
              url={layout.imageUrlLeft}
              classes={imageClass}
            />
          }
          {
            layout.imageUrlRight &&
            <Image
              url={layout.imageUrlRight}
              classes={imageClass}
            />
          }
        </div>
      </div>
    );
  }
}

LayoutSuggestion.propTypes = {
  layout: PropTypes.object.isRequired,
  layouts: PropTypes.arrayOf(PropTypes.object).isRequired,
  addSuggestion: PropTypes.func.isRequired,
  saveHeight: PropTypes.func,
};

export default LayoutSuggestion;
