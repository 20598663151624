import React, {
  useEffect, useState,
} from 'react';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const ColorCircularProgress = withStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
  },
}))(CircularProgress);

interface OwnProps {
  isBlockingLoadingActive: boolean;
}

type Props = OwnProps;

const CircularProgressBar = React.memo<Props>(({ isBlockingLoadingActive }) => {
  const [
    isLoading,
    setIsLoading,
  ] = useState<boolean>(false);

  useEffect(() => {
    if (isBlockingLoadingActive && !isLoading) {
      setIsLoading(true);
    }
    if (!isBlockingLoadingActive && isLoading) {
      setIsLoading(false);
    }
  }, [isBlockingLoadingActive, isLoading]);

  return (
    <div>
      {
        isLoading &&
        <div className='loading-mask'>
          <div className='loading-elements'>
            <ColorCircularProgress
              size='70px'
              thickness={4.5}
              color='inherit'
              disableShrink={true}
            />
            <div className='message'>
              Loading
            </div>
          </div>
        </div>
      }
    </div>
  );
});

export default CircularProgressBar;
