import * as actionTypes from './types';
import taskUrls from '@constants/sqdApiUrls/taskUrls';
import identityUrls from '@constants/sqdApiUrls/identityUrls';
import { assignmentStatusEnum } from '@constants/enums/artworkEnums';
import { fulfillmentApi, identityApi } from '../../sqdApis';
import { makeApiCall, makeApiCallWithErrorModal } from '@util/apiHelper';

export const updateArtworkDetails = (data) => ({
  type: actionTypes.UPDATE_ARTWORK_DETAILS,
  data,
});

export const updateArtworkSummary = (data) => ({
  type: actionTypes.UPDATE_ARTWORK_SUMMARY,
  data,
});

export const updateAssignees = (assignees) => ({
  type: actionTypes.UPDATE_ASSIGNEES,
  assignees,
});

export const updateSelectedAssignee = (data) => ({
  type: actionTypes.UPDATE_SELECTED_ASSIGNEE,
  data,
});

export const clearSelectedAssignee = () => ({
  type: actionTypes.UPDATE_SELECTED_ASSIGNEE,
  data: null,
});

export const updateArtworkTableState = (data) => ({
  type: actionTypes.UPDATE_ARTWORK_TABLE_STATE,
  data,
});

export const fetchAssignees = () => async (dispatch) => {
  const call = identityApi.get(identityUrls.resources, { handleBlockingLoading: false });
  const res = await makeApiCallWithErrorModal(call);
  if (res) {
    dispatch(updateAssignees(res));
  }
};

export const fetchCounts = () => async (dispatch) => {
  const call = fulfillmentApi.get(taskUrls.tasksCount, { handleBlockingLoading: false });
  const res = await makeApiCall(call);
  if (res) {
    await dispatch(updateArtworkSummary(res));
  }
};

/*
 * Second paramater is a copy of the current tableState merged with whatever pieces we're
 * adding. This way we use the table's current state to keep track of which querystring params
 * we need to continue passing in order to get consistent data.
 * See ArtVectorization.jsx line 70, 81, and 92 to get the idea -- ES6 elipsis notation is encouraged.
 */
export const updateArtworkTable = (status, nextTableState = {}) => async (dispatch) => {
  let statusCode = status;
  if (status === assignmentStatusEnum.Production) {
    statusCode = 'Completed';
  }
  if (status === assignmentStatusEnum.InProgress) {
    statusCode = 'Progress';
  }

  const pageNumber = nextTableState.pageNumber || 1;
  const pageSize = nextTableState.pageSize || 10;
  const sortColumn = nextTableState.sortColumn || 'shipDate';
  const sortDirection = nextTableState.sortDirection || 'ascending';
  const decorationMethod = !nextTableState.decorationMethodFilter
      || nextTableState.decorationMethodFilter === 'All'
    ? null
    : nextTableState.decorationMethodFilter;

  let queryString = `?status=${statusCode}&pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortColumn}&sortDirection=${sortDirection}`;
  if (decorationMethod) {
    queryString += `&decorationMethod=${decorationMethod}`;
  }
  if (nextTableState.resourceIdFilter) {
    queryString += `&resourceId=${nextTableState.resourceIdFilter}`;
  }
  if (nextTableState.partnerNameFilter) {
    queryString += `&lockerPartnerName=${nextTableState.partnerNameFilter}`;
  }
  if (nextTableState.hasParentLockerFilter) {
    queryString += `&parentLockerFilter=${nextTableState.hasParentLockerFilter}`;
  }

  const call = fulfillmentApi.get(`${taskUrls.artworkLockers}/${queryString}`, { handleBlockingLoading: false });
  const data = await makeApiCall(call);

  if (data) {
    await dispatch(updateArtworkDetails(data.items.map((item, i) => ({
      ...item,
      uid: i,
    }))));

    await dispatch(updateArtworkTableState({
      pageNumber: data.pageNumber,
      totalPages: data.totalPages,
      totalCount: data.totalCount,
      hasNextPage: data.hasNextPage,
      hasPreviousPage: data.hasPreviousPage,
      pageSize,
      decorationMethod: decorationMethod || null,
      sortColumn,
      sortDirection,
      resourceId: nextTableState.resourceId || null,
      filterByPartnerName: nextTableState.filterByPartnerName || null,
      filterByHasParentLocker: nextTableState.filterByHasParentLocker || null,
    }));

    return data;
  }
};

export const assignTasks = (data, fetchStatus) => async (dispatch, getState) => {
  const {
    resourceId,
    taskIds,
  } = data;

  const req = {
    resourceId,
    taskIds,
  };

  const { tableState } = getState().artwork;

  const call = fulfillmentApi.put(taskUrls.taskAssign, {
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(req),
  });

  const response = await makeApiCallWithErrorModal(call);
  if (response?.success) {
    dispatch(fetchCounts());
  }

  if (fetchStatus) {
    dispatch(updateArtworkTable(fetchStatus, tableState));
  }

  return response;
};
