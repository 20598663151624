/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */

export type ScheduledOrdersSortByEnum = 'Id' | 'VendorOrderId' | 'VendorId' | 'Created';


export const ScheduledOrdersSortByEnum = {
  Id: 'Id' as ScheduledOrdersSortByEnum,
  VendorOrderId: 'VendorOrderId' as ScheduledOrdersSortByEnum,
  VendorId: 'VendorId' as ScheduledOrdersSortByEnum,
  Created: 'Created' as ScheduledOrdersSortByEnum,
};
