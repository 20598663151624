/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Accounts Homefield API
 * OpenAPI spec version: 1.0
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions
} from 'react-query'
import type {
  UploadResultApiResponse,
  ApiResponse,
  PostHomefieldApiV1BulkstoresStagingjobsUploadBody,
  PostHomefieldApiV1BulkstoresStagingjobsUploadParams,
  PutHomefieldApiV1BulkstoresStagingjobsIdUpdatelogosfolderParams,
  StagingJobOutputPagedListApiResponse,
  GetHomefieldApiV1BulkstoresStagingjobsParams,
  StagingJobDetailsDtoApiResponse,
  JobStatusOutputApiResponse,
  StagingJobErrorDetailsDtoApiResponse,
  ProductCreationStatusOutputApiResponse,
  BooleanApiResponse,
  PatchHomefieldApiV1BulkstoresStagingorgsIdUpdateusercompletedParams,
  PatchHomefieldApiV1BulkstoresStagingstoresIdUpdateusercompletedParams
} from './models'
import { accountsApiv1 } from '../mutator/custom-instance'


type AsyncReturnType<
T extends (...args: any) => Promise<any>,
U = unknown
> = T extends (...args: any) => Promise<infer R> ? (U extends R ? U : R) : any;


type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const postHomefieldApiV1BulkstoresStagingjobsUpload = <TData = UploadResultApiResponse>(
    postHomefieldApiV1BulkstoresStagingjobsUploadBody: PostHomefieldApiV1BulkstoresStagingjobsUploadBody,
    params?: PostHomefieldApiV1BulkstoresStagingjobsUploadParams,
 options?: SecondParameter<typeof accountsApiv1>) => {const formData = new FormData();
if(postHomefieldApiV1BulkstoresStagingjobsUploadBody.file) {
 formData.append('file', postHomefieldApiV1BulkstoresStagingjobsUploadBody.file)
 }

      return accountsApiv1<TData>(
      {url: `/homefield/api/v1/bulk-stores/staging-jobs/upload`, method: 'post',
       data: formData,
        params,
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePostHomefieldApiV1BulkstoresStagingjobsUpload = <TData = AsyncReturnType<typeof postHomefieldApiV1BulkstoresStagingjobsUpload,UploadResultApiResponse>,
    TError = ApiResponse,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: PostHomefieldApiV1BulkstoresStagingjobsUploadBody;params?: PostHomefieldApiV1BulkstoresStagingjobsUploadParams}, TContext>, request?: SecondParameter<typeof accountsApiv1>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {data: PostHomefieldApiV1BulkstoresStagingjobsUploadBody;params?: PostHomefieldApiV1BulkstoresStagingjobsUploadParams}, TContext>((props) => {
        const {data,params} = props || {};

        return  postHomefieldApiV1BulkstoresStagingjobsUpload<TData>(data,params,requestOptions)
      }, mutationOptions)
    }
    export const putHomefieldApiV1BulkstoresStagingjobsIdUpdatelogosfolder = <TData = ApiResponse>(
    id: number,
    params?: PutHomefieldApiV1BulkstoresStagingjobsIdUpdatelogosfolderParams,
 options?: SecondParameter<typeof accountsApiv1>) => {
      return accountsApiv1<TData>(
      {url: `/homefield/api/v1/bulk-stores/staging-jobs/${id}/update.logos-folder`, method: 'put',
      data: undefined,
        params,
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePutHomefieldApiV1BulkstoresStagingjobsIdUpdatelogosfolder = <TData = AsyncReturnType<typeof putHomefieldApiV1BulkstoresStagingjobsIdUpdatelogosfolder,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{id: number;params?: PutHomefieldApiV1BulkstoresStagingjobsIdUpdatelogosfolderParams}, TContext>, request?: SecondParameter<typeof accountsApiv1>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {id: number;params?: PutHomefieldApiV1BulkstoresStagingjobsIdUpdatelogosfolderParams}, TContext>((props) => {
        const {id,params} = props || {};

        return  putHomefieldApiV1BulkstoresStagingjobsIdUpdatelogosfolder<TData>(id,params,requestOptions)
      }, mutationOptions)
    }
    export const getHomefieldApiV1BulkstoresStagingjobs = <TData = StagingJobOutputPagedListApiResponse>(
    params?: GetHomefieldApiV1BulkstoresStagingjobsParams,
 options?: SecondParameter<typeof accountsApiv1>) => {
      return accountsApiv1<TData>(
      {url: `/homefield/api/v1/bulk-stores/staging-jobs`, method: 'get',
        params,
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiV1BulkstoresStagingjobsQueryKey = (params?: GetHomefieldApiV1BulkstoresStagingjobsParams,) => [`/homefield/api/v1/bulk-stores/staging-jobs`, ...(params ? [params]: [])]

    
export const useGetHomefieldApiV1BulkstoresStagingjobs = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiV1BulkstoresStagingjobs, StagingJobOutputPagedListApiResponse>, TError = ApiResponse, TData = TQueryFnData>(
 params?: GetHomefieldApiV1BulkstoresStagingjobsParams, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof accountsApiv1>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiV1BulkstoresStagingjobsQueryKey(params);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiV1BulkstoresStagingjobs<TQueryFnData>(params, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const getHomefieldApiV1BulkstoresStagingjobsId = <TData = StagingJobDetailsDtoApiResponse>(
    id: number,
 options?: SecondParameter<typeof accountsApiv1>) => {
      return accountsApiv1<TData>(
      {url: `/homefield/api/v1/bulk-stores/staging-jobs/${id}`, method: 'get'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiV1BulkstoresStagingjobsIdQueryKey = (id: number,) => [`/homefield/api/v1/bulk-stores/staging-jobs/${id}`]

    
export const useGetHomefieldApiV1BulkstoresStagingjobsId = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiV1BulkstoresStagingjobsId, StagingJobDetailsDtoApiResponse>, TError = ApiResponse, TData = TQueryFnData>(
 id: number, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof accountsApiv1>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiV1BulkstoresStagingjobsIdQueryKey(id);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiV1BulkstoresStagingjobsId<TQueryFnData>(id, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const getHomefieldApiV1BulkstoresStagingjobsIdStatus = <TData = JobStatusOutputApiResponse>(
    id: number,
 options?: SecondParameter<typeof accountsApiv1>) => {
      return accountsApiv1<TData>(
      {url: `/homefield/api/v1/bulk-stores/staging-jobs/${id}/status`, method: 'get'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiV1BulkstoresStagingjobsIdStatusQueryKey = (id: number,) => [`/homefield/api/v1/bulk-stores/staging-jobs/${id}/status`]

    
export const useGetHomefieldApiV1BulkstoresStagingjobsIdStatus = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiV1BulkstoresStagingjobsIdStatus, JobStatusOutputApiResponse>, TError = ApiResponse, TData = TQueryFnData>(
 id: number, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof accountsApiv1>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiV1BulkstoresStagingjobsIdStatusQueryKey(id);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiV1BulkstoresStagingjobsIdStatus<TQueryFnData>(id, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const putHomefieldApiV1BulkstoresStagingjobsIdQueue = <TData = ApiResponse>(
    id: number,
 options?: SecondParameter<typeof accountsApiv1>) => {
      return accountsApiv1<TData>(
      {url: `/homefield/api/v1/bulk-stores/staging-jobs/${id}/queue`, method: 'put',
      data: undefined
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePutHomefieldApiV1BulkstoresStagingjobsIdQueue = <TData = AsyncReturnType<typeof putHomefieldApiV1BulkstoresStagingjobsIdQueue,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{id: number}, TContext>, request?: SecondParameter<typeof accountsApiv1>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {id: number}, TContext>((props) => {
        const {id} = props || {};

        return  putHomefieldApiV1BulkstoresStagingjobsIdQueue<TData>(id,requestOptions)
      }, mutationOptions)
    }
    export const putHomefieldApiV1BulkstoresStagingjobsIdDequeue = <TData = ApiResponse>(
    id: number,
 options?: SecondParameter<typeof accountsApiv1>) => {
      return accountsApiv1<TData>(
      {url: `/homefield/api/v1/bulk-stores/staging-jobs/${id}/dequeue`, method: 'put',
      data: undefined
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePutHomefieldApiV1BulkstoresStagingjobsIdDequeue = <TData = AsyncReturnType<typeof putHomefieldApiV1BulkstoresStagingjobsIdDequeue,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{id: number}, TContext>, request?: SecondParameter<typeof accountsApiv1>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {id: number}, TContext>((props) => {
        const {id} = props || {};

        return  putHomefieldApiV1BulkstoresStagingjobsIdDequeue<TData>(id,requestOptions)
      }, mutationOptions)
    }
    export const getHomefieldApiV1BulkstoresStagingjobsIdOutputfile = <TData = Blob>(
    id: number,
 options?: SecondParameter<typeof accountsApiv1>) => {
      return accountsApiv1<TData>(
      {url: `/homefield/api/v1/bulk-stores/staging-jobs/${id}/output-file`, method: 'get',
        responseType: 'blob',
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiV1BulkstoresStagingjobsIdOutputfileQueryKey = (id: number,) => [`/homefield/api/v1/bulk-stores/staging-jobs/${id}/output-file`]

    
export const useGetHomefieldApiV1BulkstoresStagingjobsIdOutputfile = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiV1BulkstoresStagingjobsIdOutputfile, Blob>, TError = ApiResponse, TData = TQueryFnData>(
 id: number, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof accountsApiv1>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiV1BulkstoresStagingjobsIdOutputfileQueryKey(id);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiV1BulkstoresStagingjobsIdOutputfile<TQueryFnData>(id, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const getHomefieldApiV1BulkstoresStagingjobsIdErrordetails = <TData = StagingJobErrorDetailsDtoApiResponse>(
    id: number,
 options?: SecondParameter<typeof accountsApiv1>) => {
      return accountsApiv1<TData>(
      {url: `/homefield/api/v1/bulk-stores/staging-jobs/${id}/error-details`, method: 'get'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiV1BulkstoresStagingjobsIdErrordetailsQueryKey = (id: number,) => [`/homefield/api/v1/bulk-stores/staging-jobs/${id}/error-details`]

    
export const useGetHomefieldApiV1BulkstoresStagingjobsIdErrordetails = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiV1BulkstoresStagingjobsIdErrordetails, StagingJobErrorDetailsDtoApiResponse>, TError = ApiResponse, TData = TQueryFnData>(
 id: number, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof accountsApiv1>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiV1BulkstoresStagingjobsIdErrordetailsQueryKey(id);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiV1BulkstoresStagingjobsIdErrordetails<TQueryFnData>(id, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const getHomefieldApiV1BulkstoresStagingjobsIdProductcreationstatus = <TData = ProductCreationStatusOutputApiResponse>(
    id: number,
 options?: SecondParameter<typeof accountsApiv1>) => {
      return accountsApiv1<TData>(
      {url: `/homefield/api/v1/bulk-stores/staging-jobs/${id}/product-creation-status`, method: 'get'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiV1BulkstoresStagingjobsIdProductcreationstatusQueryKey = (id: number,) => [`/homefield/api/v1/bulk-stores/staging-jobs/${id}/product-creation-status`]

    
export const useGetHomefieldApiV1BulkstoresStagingjobsIdProductcreationstatus = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiV1BulkstoresStagingjobsIdProductcreationstatus, ProductCreationStatusOutputApiResponse>, TError = ApiResponse, TData = TQueryFnData>(
 id: number, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof accountsApiv1>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiV1BulkstoresStagingjobsIdProductcreationstatusQueryKey(id);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiV1BulkstoresStagingjobsIdProductcreationstatus<TQueryFnData>(id, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const postHomefieldApiV1BulkstoresStagingjobsIdStop = <TData = BooleanApiResponse>(
    id: number,
 options?: SecondParameter<typeof accountsApiv1>) => {
      return accountsApiv1<TData>(
      {url: `/homefield/api/v1/bulk-stores/staging-jobs/${id}/stop`, method: 'post',
      data: undefined
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePostHomefieldApiV1BulkstoresStagingjobsIdStop = <TData = AsyncReturnType<typeof postHomefieldApiV1BulkstoresStagingjobsIdStop,BooleanApiResponse>,
    TError = ApiResponse,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{id: number}, TContext>, request?: SecondParameter<typeof accountsApiv1>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {id: number}, TContext>((props) => {
        const {id} = props || {};

        return  postHomefieldApiV1BulkstoresStagingjobsIdStop<TData>(id,requestOptions)
      }, mutationOptions)
    }
    export const patchHomefieldApiV1BulkstoresStagingorgsIdUpdateusercompleted = <TData = ApiResponse>(
    id: number,
    params?: PatchHomefieldApiV1BulkstoresStagingorgsIdUpdateusercompletedParams,
 options?: SecondParameter<typeof accountsApiv1>) => {
      return accountsApiv1<TData>(
      {url: `/homefield/api/v1/bulk-stores/staging-orgs/${id}/update-user-completed`, method: 'patch',
      data: undefined,
        params,
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePatchHomefieldApiV1BulkstoresStagingorgsIdUpdateusercompleted = <TData = AsyncReturnType<typeof patchHomefieldApiV1BulkstoresStagingorgsIdUpdateusercompleted,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{id: number;params?: PatchHomefieldApiV1BulkstoresStagingorgsIdUpdateusercompletedParams}, TContext>, request?: SecondParameter<typeof accountsApiv1>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {id: number;params?: PatchHomefieldApiV1BulkstoresStagingorgsIdUpdateusercompletedParams}, TContext>((props) => {
        const {id,params} = props || {};

        return  patchHomefieldApiV1BulkstoresStagingorgsIdUpdateusercompleted<TData>(id,params,requestOptions)
      }, mutationOptions)
    }
    export const patchHomefieldApiV1BulkstoresStagingstoresIdUpdateusercompleted = <TData = ApiResponse>(
    id: number,
    params?: PatchHomefieldApiV1BulkstoresStagingstoresIdUpdateusercompletedParams,
 options?: SecondParameter<typeof accountsApiv1>) => {
      return accountsApiv1<TData>(
      {url: `/homefield/api/v1/bulk-stores/staging-stores/${id}/update-user-completed`, method: 'patch',
      data: undefined,
        params,
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePatchHomefieldApiV1BulkstoresStagingstoresIdUpdateusercompleted = <TData = AsyncReturnType<typeof patchHomefieldApiV1BulkstoresStagingstoresIdUpdateusercompleted,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{id: number;params?: PatchHomefieldApiV1BulkstoresStagingstoresIdUpdateusercompletedParams}, TContext>, request?: SecondParameter<typeof accountsApiv1>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {id: number;params?: PatchHomefieldApiV1BulkstoresStagingstoresIdUpdateusercompletedParams}, TContext>((props) => {
        const {id,params} = props || {};

        return  patchHomefieldApiV1BulkstoresStagingstoresIdUpdateusercompleted<TData>(id,params,requestOptions)
      }, mutationOptions)
    }
    