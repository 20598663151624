/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */
import type { PrepressJobTypeEnum } from './prepressJobTypeEnum';

export interface ArtworkItemReworkDto {
  artworkTaskId?: number | null;
  previewUrl?: string | null;
  decorationLocation?: string | null;
  jobType?: PrepressJobTypeEnum;
  orderLineItemIds?: number[] | null;
  orderNumbers?: number[] | null;
  barcodes?: string[] | null;
  readonly quantity?: number;
}
