/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Financials Homefield API
 * OpenAPI spec version: v1
 */
import type { PayoutMethodEnum } from './payoutMethodEnum';
import type { PayoutStatusEnumStatusModel } from './payoutStatusEnumStatusModel';
import type { SubmitStatusEnum } from './submitStatusEnum';
import type { PayoutRecipientDto } from './payoutRecipientDto';

export interface PayoutDto {
  id?: number;
  uuid?: string | null;
  created?: string | null;
  updated?: string | null;
  deleted?: string | null;
  completed?: string | null;
  method?: PayoutMethodEnum;
  status?: PayoutStatusEnumStatusModel;
  accountingSubmitStatus?: SubmitStatusEnum;
  amount?: number;
  checkNumber?: string | null;
  checkDate?: string | null;
  checkAmount?: number | null;
  erpPayoutId?: number | null;
  rowVersion?: string | null;
  payoutRecipient?: PayoutRecipientDto;
}
