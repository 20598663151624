import { createTheme } from '@material-ui/core';
import grid from '@assets/transparent.svg';

export const slidesPerView = 4 as const;

export const modalStyle = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 100,
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    zIndex: 1001,
    bottom: 'unset',
    left: 'unset',
    right: 'unset',
    top: 'unset',
    transform: 'unset',
    padding: 0,
    maxWidth: '90%',
    maxHeight: '90%',
    overflowY: 'hidden',
    overflowX: 'auto',
  },
} as const;

export const modalStyleBig = {
  overlay: { ...modalStyle.overlay },
  content: {
    ...modalStyle.content,
    maxHeight: '95%',
  },
} as const;

export const dragAndDropMessage = (fileType: string | null = null): string => `Drag 'n' Drop ${fileType ? fileType : 'Attachments'}.`;

export const pageSizeOptionsSmallTable = [
  5,
  10,
  25,
  50,
] as const;
export const pageSizeOptionsBigTable = [
  5,
  12,
  25,
  50,
] as const;
export const pageSizeOptionsExtraBigTable = [
  10,
  25,
  50,
  100,
  200,
  500,
] as const;

export const defaultPageSizeSmallTable = 10 as const;
export const defaultPageSizeBigTable = 12 as const;

export const muiTheme = createTheme({
  palette: {
    primary: {
      main: '#029487',
    },
  },
  spacing: 8,
});

export const stickyHeaderInitialHeight = 100 as const;
export const stickyHeaderSpace = stickyHeaderInitialHeight + 20; // With bottom margin

export const scrollDirections = {
  up: 'up',
  down: 'down',
} as const;

export const tooltipEnterDelay = 300 as const;
export const throttleDelay = 300 as const;

export const minInputLengthForSuggestions = 2 as const;
export const minInputLengthForSkuSuggestions = 3 as const;

export const artworkTaskCardPageSize = 4 as const;

export const minOrganizationFeaturedLockers = 2 as const;
export const maxOrganizationFeaturedLockers = 4 as const;

export const changeSkuGetInfoDelay = 1000 as const;
export const minInputLengthForSku = 4 as const;

export const linearProgressBarDelay = 800 as const;
export const circularProgressBarDelay = 100 as const;

export const numberRangeFilterDelay = 1000 as const;
export const multiSelectDropdownDelay = 1000 as const;

export const defaultLockerSuggestionsLimit = 10 as const;

export const fileNameRegex = /([^/]+$)/g;
export const capitalsRegex = /([A-Z]+)/g;
export const onlyNumberRegex = /^[0-9\b]+$/;
export const locationNameRegex = /^[a-zA-Z -]+$/;
export const stringWithoutSpaces = /^\S+$/;
export const phoneNumberInputRegex = /[a-zA-Z\s._-]+/g;
export const usPhoneNumberRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
export const canadianZipRegex = /^(?!.*[DFIOQUdfioqu])[A-VXYa-vxy][0-9][A-Za-z]●?[0-9][A-Za-z][0-9]$/;

export const emailMaxLength = 100 as const;
export const defaultZipCodeLength = 5 as const;
export const defaultCanadianZipCodeLength = 6 as const;
export const defaultPhoneNumberLength = 10 as const;
export const phoneNumberInputMaxLength = 12 as const;
export const personalizationWithSpacesMaxLength = 15 as const;
export const personalizationWithoutSpacesMaxLength = 11 as const;

export const minItemsPerPageInSkuSetup = 5 as const;

export const infoTextTimeout = 1000 as const;
export const highlightTextDuration = 1000 as const;
export const copiedText = 'Copied!' as const;
export const emptyText = '' as const;
export const validationPassedMessage = 'Success' as const;

export const noneBulkAction = {
  key: 0,
  value: '',
  name: 'Bulk Actions',
} as const;

export const suggestionRowCategories = [
  {
    sizeMin: 0,
    sizeMax: 33,
    maxRows: 10,
  },
  {
    sizeMin: 34,
    sizeMax: 68,
    maxRows: 5,
  },
  {
    sizeMin: 69,
    sizeMax: 110,
    maxRows: 3,
  },
  {
    sizeMin: 111,
    sizeMax: 150,
    maxRows: 2,
  },
  {
    sizeMin: 151,
    sizeMax: 1000,
    maxRows: 1,
  },
] as const;

export const dropdownSizeXs = '100px' as const;
export const dropdownSizeS = '160px' as const;
export const dropdownSizeM = '200px' as const;
export const dropdownSizeL = '280px' as const;

export const productionAssemblyPrintQueueStorageKey = 'productionAssemblyPrintQueue' as const;

export const transparentBackgoundOption = {
  code: 'Transparent',
  background: `url("${grid}")`,
  color: 'transparent',
} as const;

export const whiteBackgroundOption = {
  code: 'White',
  background: '#FFFFFF',
  color: '#FFFFFF',
} as const;

export const blackBackgroundOption = {
  code: 'Black',
  background: '#000000',
  color: '#000000',
} as const;

export const imageBackgroundOptions = [
  transparentBackgoundOption,
  whiteBackgroundOption,
  blackBackgroundOption,
] as const;

export const MAX_NUMBER_OF_ROWS = 10 as const;
export const MAX_NUMBER_OF_ROW_POSITIONS = 6 as const;

export const ptlWallRowPositionColors = [
  'r',
  'g',
  'b',
  'rg',
  'rb',
  'bg',
] as const;

export const logoDetailColorNumber = 7 as const;

export const maxCustomSenderNameLength = 100 as const;
