/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Catalog Homefield API
 * OpenAPI spec version: v1
 */
import type { ProductAgeEnum } from './productAgeEnum';
import type { ProductGenderEnum } from './productGenderEnum';
import type { CategoryDto } from './categoryDto';
import type { BrandDto } from './brandDto';
import type { SetupStatusEnum } from './setupStatusEnum';
import type { DecorationMethodEnum } from './decorationMethodEnum';
import type { StyleDecorationLocationDto } from './styleDecorationLocationDto';

export interface StyleDto {
  id?: number;
  uuid?: string | null;
  created: string;
  updated: string;
  deleted?: string | null;
  code: string;
  name: string;
  description: string;
  age?: ProductAgeEnum;
  gender: ProductGenderEnum;
  categoryId?: number | null;
  category?: CategoryDto;
  brandId?: number | null;
  price: number;
  brand: BrandDto;
  readonly vendorId?: string | null;
  vendorCode?: string | null;
  status: SetupStatusEnum;
  discount: number;
  recommended: boolean;
  availableForNewStores: boolean;
  alwaysInclude: boolean;
  neutral: boolean;
  prdOnly: boolean;
  customizable: boolean;
  classId?: string | null;
  sizeChartUrl?: string | null;
  qtyVendorMinimum?: number;
  alwaysAvailableVendorInventory: boolean;
  disabled: boolean;
  discontinued: boolean;
  vendorFulfilledItem?: boolean;
  taxCode?: string | null;
  waterproof: boolean;
  decorationMethod: DecorationMethodEnum;
  decorationLocations?: StyleDecorationLocationDto[] | null;
  rowVersion?: string | null;
}
