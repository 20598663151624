import { decorationTypeEnum } from '@constants/enums/decorationEnums';

const findDecorationIndex = (errorIndex, formDecorations, decorationType) => {
  let count = 0;
  for (let i = 0; i < formDecorations.length; i++) {
    const formDecoration = formDecorations[i];
    if (formDecoration.type === decorationType) {
      if (count === errorIndex) {
        return i;
      }
      count++;
    }
  }
};

export const mapLayoutErrors = (formDecorations, error, validationErrors) => {
  const validationErrorKeys = Object.keys(validationErrors);

  const logosErrorKeys = validationErrorKeys.filter((key) => key.toLowerCase().startsWith('logos'));
  const personalizationsErrorKeys = validationErrorKeys.filter((key) => key.toLowerCase().startsWith('personalizations'));

  const decorations = {};
  for (const key of logosErrorKeys) {
    const decorationError = validationErrors[key];
    const index = +key.substring(key.indexOf('[') + 1, key.indexOf(']'));
    const newIndex = findDecorationIndex(index, formDecorations, decorationTypeEnum.Logo);
    decorations[newIndex] = decorationError;
  }

  for (const key of personalizationsErrorKeys) {
    const decorationError = validationErrors[key];
    const index = +key.substring(key.indexOf('[') + 1, key.indexOf(']'));
    const newIndex = findDecorationIndex(index, formDecorations, decorationTypeEnum.Personalization);
    decorations[newIndex] = decorationError;
  }

  return {
    _error: error.message,
    name: validationErrors.name,
    categoryId: validationErrors.categoryId,
    sportId: validationErrors.sportId,
    imageUrlFront: validationErrors.imageUrlFront,
    imageUrlBack: validationErrors.imageUrlBack,
    imageUrlLeft: validationErrors.imageUrlLeft,
    imageUrlRight: validationErrors.imageUrlRight,
    decorations,
  };
};

export const mapLayoutFormInitialValues = (layout, createMode = false) =>
  layout
    ? ({
      id: createMode ? undefined : layout.id,
      rowHash: createMode ? undefined : layout.rowHash,
      name: layout.name,
      categoryId: layout.categoryId,
      sports: layout.sports,
      imageUrlFront: layout.imageUrlFront,
      imageUrlBack: layout.imageUrlBack,
      imageUrlRight: layout.imageUrlRight,
      imageUrlLeft: layout.imageUrlLeft,
      decorations: [
        ...(layout.logos || []).map((logo) => ({
          ...logo,
          type: decorationTypeEnum.Logo,
        })),
        ...(layout.personalizations || []).map((personalization) => ({
          ...personalization,
          type: decorationTypeEnum.Personalization,
        })),
      ],
    })
    : ({
      name: '',
      categoryId: null,
      sports: [],
      imageUrlFront: null,
      imageUrlBack: null,
      imageUrlRight: null,
      imageUrlLeft: null,
      decorations: [],
    });
