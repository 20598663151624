/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Accounts Homefield API
 * OpenAPI spec version: 1.0
 */
import {
  useQuery,
  UseQueryOptions
} from 'react-query'
import { accountsApiv1 } from '../mutator/custom-instance'


type AsyncReturnType<
T extends (...args: any) => Promise<any>,
U = unknown
> = T extends (...args: any) => Promise<infer R> ? (U extends R ? U : R) : any;


type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const getHomefieldApiV1SupportVersion = <TData = string>(
    
 options?: SecondParameter<typeof accountsApiv1>) => {
      return accountsApiv1<TData>(
      {url: `/homefield/api/v1/support/version`, method: 'get'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiV1SupportVersionQueryKey = () => [`/homefield/api/v1/support/version`]

    
export const useGetHomefieldApiV1SupportVersion = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiV1SupportVersion, string>, TError = unknown, TData = TQueryFnData>(
  options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof accountsApiv1>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiV1SupportVersionQueryKey();

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiV1SupportVersion<TQueryFnData>(requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

