import React, { useCallback } from 'react';
import {
  Page, Text, View, Image, StyleSheet, Rect, Svg,
} from '@react-pdf/renderer';
import JsBarcode from 'jsbarcode';
import {
  HagPrintRoomOrderDetailsDto, PersonalizationItem,
} from '@api/fulfillment/models';
import { parseDateNumeric } from '@util/dateHandler';
import { ColorDto } from '@api/productCatalog/models';

// Create styles
const styles = StyleSheet.create({
  page: {
    padding: 40,
  },
  footer: {
    textAlign: 'right',
    fontSize: 12,
  },
  container: {
    flexGrow: 1,
    flexDirection: 'column',
  },
  section: {
    marginBottom: 0,
    flexGrow: 1,
    flexDirection: 'column',
  },
  notesSection: {
    marginBottom: 20,
  },
  logoSection: {
    flexDirection: 'row',
    fontSize: 14,
    maxHeight: '180px',
    paddingBottom: 0,
    marginBottom: 0,
    marginTop: 0,
    textAlign: 'left',
  },
  header: {
    flexDirection: 'row',
  },
  mainHeader: {
    fontSize: 30,
  },
  logoBox: {
    flexDirection: 'row',
    borderStyle: 'solid',
    borderWidth: '1px',
    height: '180px',
  },
  logo: {
    flexDirection: 'column',
    width: '170px',
    height: 'auto',
  },
  image: {
    height: 'auto',
    width: 'auto',
    maxHeight: '130px',
    maxWidth: '130px',
    margin: 1,
    backgroundColor: '#22FF22',
  },
  prsn: {
    flexDirection: 'column',
    marginTop: 10,
  },
  prsnRow: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    borderBottomStyle: 'solid',
    borderBottomWidth: '1px',
    paddingTop: '5px',
    paddingBottom: '5px',
  },
  prsnRowDarker: {
    backgroundColor: '#EEEEEE',
  },
  prsnRowLighter: {
    backgroundColor: '#FFFFFF',
  },
  prsnHeader: {
    color: '#888888',
    fontSize: 10,
  },
  prsnCell: {
    width: '17%',
    fontSize: 12,
  },
  prsnCellContent: {
    width: '35%',
    fontSize: 12,
  },
  prsnCellQty: {
    width: '14%',
    fontSize: 12,
  },
  prsnCellColor: {
    width: '17%',
    fontSize: 8,
    flexDirection: 'row',
  },
  barcode: {
    maxHeight: 50,
    maxWidth: 150,
    width: 'auto',
    height: 'auto',
  },
});

interface OwnProps {
  order: HagPrintRoomOrderDetailsDto;
  colors?: ColorDto[];
}

type Props = OwnProps;

export const PrintableViewOrderPages: React.FC<Props> = ({
  order, colors,
}) => {
  const barcodeCanvas = document.createElement('canvas');
  JsBarcode(barcodeCanvas, `O${order.orderNumber}`, {
    displayValue: false,
  });
  const barcodeData = barcodeCanvas.toDataURL();

  const ColorView: React.FC<Pick<PersonalizationItem, 'colorId' | 'outlineColorId'>> = useCallback(({
    colorId, outlineColorId,
  }) => {
    const mainColor = colors?.find((color) => colorId && color.id === colorId);
    const outlineColor = colors?.find((color) => outlineColorId && color.id === outlineColorId);
    if (!mainColor || !mainColor.hexValue) {
      return <Text style={styles.prsnCell}>{colorId}</Text>;
    }

    return (
      <View style={styles.prsnCellColor}>
        <Svg
          height={12}
          width={12}
          style={{ marginRight: 2 }}
        >
          <Rect
            height={12}
            width={12}
            x={0}
            y={0}
            fill={mainColor.hexValue}
            stroke={outlineColor?.hexValue ?? 'black'}
            strokeWidth={outlineColor?.hexValue ? 3 : 1}
          />
        </Svg>
        <View style={{ flexDirection: 'column' }}>
          <Text>{mainColor?.code}</Text>
          {!!outlineColor?.code && <Text>{outlineColor?.code}</Text>}
        </View>
      </View>
    );
  }, [colors]);

  const header = (
    <View
      style={styles.header}
      wrap={false}
    >
      <View style={styles.section}>
        <Image
          style={styles.barcode}
          src={barcodeData}
        />
        <Text style={styles.mainHeader}>O{order.orderNumber}</Text>
        {!!order.lockerId && <Text>L{order.lockerId}</Text>}
        {!!order.organizationId && <Text>ORG{order.organizationId}</Text>}
        <Text>Logos: {order.logoCount}</Text>
        <Text>Personalizations: {order.personalizationCount}</Text>
      </View>
      <View style={styles.section}>
        <Text style={styles.mainHeader}>Ship Date: {parseDateNumeric(order.shipDate)}</Text>
        {!!order.isRush && <Text>RUSH</Text>}
        {!!order.isExpressProduction && <Text>EXPRESS PRODUCTION</Text>}
      </View>
    </View>
  );

  const notes = (
    <View style={styles.notesSection}>
      <Text>Notes: {order.notes}</Text>
    </View>
  );

  const logos = order.logos?.map((logo) => (
    <View
      key={`${logo.previewUrl}_${logo.decorationLocation}_${logo.decorationWidth}_${logo.decorationHeight}`}
      style={styles.logoBox}
    >
      <View style={styles.logo}>
        <Image
          src={logo.previewUrl ?? ''}
          style={styles.image}
        />
        <Text>
          {logo.decorationLocation}
          {' '}{logo.decorationWidth ?? 0}"W x {logo.decorationHeight ?? 0}"H
          {' '}QTY:{logo.quantity}
        </Text>
      </View>
    </View>
  )).reduce((acc, cur, idx) => {
    if (idx % 3 === 0) {
      acc.push([cur]);
    } else {
      acc[acc.length - 1].push(cur);
    }

    return acc;
  }, [] as JSX.Element[][]);

  const psrnHeader = (
    <View
      style={[
        styles.prsnRow,
        styles.prsnHeader,
      ]}
      wrap={false}
    >
      {
        order.personalizations?.length
          ? (
            <>
              <Text style={styles.prsnCellQty}>Quantity</Text>
              <Text style={styles.prsnCell}>Location</Text>
              <Text style={styles.prsnCell}>Size</Text>
              <Text style={styles.prsnCell}>Color</Text>
              <Text style={styles.prsnCellContent}>Personalization</Text>
            </>
          )
          : <Text style={styles.prsnCellContent}>No Personalizations</Text>
      }

    </View>
  );

  const prsnRows = order.personalizations?.map((prsn, idx) => (
    <View
      key={`${prsn.decorationLocation}_${prsn.decorationWidth}_${prsn.decorationHeight}_${prsn.text}_${prsn.number}`}
      style={[
        styles.prsnRow,
        idx % 2 === 0 ? styles.prsnRowDarker : styles.prsnRowLighter,
      ]}
      wrap={false}
    >
      <Text style={styles.prsnCellQty}>{prsn.quantity}</Text>
      <Text style={styles.prsnCell}>{prsn.decorationLocation}</Text>
      <Text style={styles.prsnCell}>{prsn.decorationWidth ?? 0}"x{prsn.decorationHeight ?? 0}"</Text>
      <ColorView
        colorId={prsn.colorId}
        outlineColorId={prsn.outlineColorId}
      />
      <Text style={styles.prsnCellContent}>{prsn.text} {prsn.number}</Text>
    </View>
  ));

  return (
    <Page
      size='LETTER'
      style={styles.page}
    >
      <View style={styles.container}>
        {header}
        {notes}
        {logos?.map((logoSet) => (
          <View
            style={[
              styles.section,
              styles.logoSection,
            ]}
            key={logoSet.map((x) => x.key).join('-')}
          >
            {logoSet}
          </View>
        ))}
        <View style={[
          styles.section,
          styles.prsn,
        ]}
        >
          {psrnHeader}
          {prsnRows}
        </View>
      </View>
      <View
        fixed={true}
        style={styles.footer}
      >
        <Text>O{order.orderNumber}</Text>
      </View>
    </Page>
  );
};
