/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */
import type { BackordersSortByEnum } from './backordersSortByEnum';
import type { SortDirectionEnum } from './sortDirectionEnum';

export type GetHomefieldApiBackordermanagementOrdersParams = { pageNumber?: number; pageSize?: number; previouslyNotified?: boolean; discontinued?: boolean; lateOrders?: boolean; filter?: string; sortBy?: BackordersSortByEnum; sortDirection?: SortDirectionEnum };
