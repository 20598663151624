import * as actionTypes from './types';
import organizationsUrls from '@constants/sqdApiUrls/organizationsUrls';
import lockerUrls from '@constants/sqdApiUrls/lockerUrls';
import { makeApiCall, makeApiCallWithErrorModal, makeApiCallWithSubmissionError } from '@util/apiHelper';
import { slServicesApi } from '../../sqdApis';
import { getHomefieldApiLockersOrganizationsOrganizationIdTotals } from '@api/fulfillment/locker';
import { putHomefieldApiV2MerchandisingTargetUpdateoptin } from '@api/accounts/merchandising';

const uploadLogoToOrganization = async (attachedFile) => {
  if (!attachedFile) {
    throw new Error('Missing logo file');
  }

  const formData = new FormData();
  if (attachedFile) {
    for (const f of attachedFile) {
      formData.append('logo', f, f.name);
    }
  }

  const call = slServicesApi.post(organizationsUrls.uploadLogo, {
    body: formData,
  });

  const res = await makeApiCallWithErrorModal(call);

  return res;
};

export const updateOrganizationsTable = (data) => ({
  type: actionTypes.UPDATE_ORGANIZATIONS_TABLE,
  data,
});

export const updateOrganizationDetails = (data) => ({
  type: actionTypes.UPDATE_ORGANIZATION,
  data,
});

export const updateOrganizationSuggestions = (data) => ({
  type: actionTypes.UPDATE_ORGANIZATION_SUGGESTIONS,
  data,
});

export const updateLockerSuggestions = (data) => ({
  type: actionTypes.UPDATE_LOCKER_SUGGESTIONS,
  data,
});

export const updateOrganizationGroups = (data) => ({
  type: actionTypes.UPDATE_ORGANIZATION_GROUPS,
  data,
});

export const updateOrganizationGroupLockers = (data) => ({
  type: actionTypes.UPDATE_ORGANIZATION_GROUP_LOCKERS,
  data,
});

export const updateOrganizationFeaturedLockers = (data) => {
  const availableOrderPlaces = [];

  for (let i = 1; i <= data.length; i++) {
    if (!data.find((locker) => locker.orderPlace === i)) {
      availableOrderPlaces.push(i);
    }
  }

  for (const locker of data) {
    if (!locker.orderPlace) {
      locker.orderPlace = availableOrderPlaces.shift();
    }
  }

  return ({
    type: actionTypes.UPDATE_FEATURED_LOCKERS,
    data,
  });
};

export const updateOrganizationLockers = (data) => ({
  type: actionTypes.UPDATE_ORGANIZATION_LOCKERS,
  data,
});

export const updateOrganizationTotals = (data) => ({
  type: actionTypes.UPDATE_ORGANIZATION_TOTALS,
  data,
});

export const fetchOrganizations = (
  pageNumber = 1,
  pageSize = 10,
  sortBy = '',
  sortOrder = '',
  orgType = '',
  size = '',
  search = '',
  landingPageEnabled = '',
  lockerManagerId = '',
  accountExecutives = [],
) =>
async (dispatch) => {
  const call = slServicesApi.get(
    organizationsUrls.organizations(
      pageNumber,
      pageSize,
      sortBy,
      sortOrder,
      orgType,
      size,
      search,
      landingPageEnabled,
      lockerManagerId,
      accountExecutives,
    ),
    { handleBlockingLoading: false },
  );

  const response = await makeApiCall(call);
  if (response) {
    dispatch(updateOrganizationsTable({
      totalPages: response.totalPages,
      hasPreviousPage: response.hasPreviousPage,
      hasNextPage: response.hasNextPage,
      pageNumber: response.pageNumber,
      pageSize: response.pageSize,
      queue: response.items,
      totalCount: response.totalCount,
    }));
  }
};

export const fetchOrganization = (organizationId) => async (dispatch) => {
  const call = slServicesApi.get(organizationsUrls.organization(organizationId), { handleBlockingLoading: false });
  const response = await makeApiCallWithErrorModal(call);
  if (response) {
    dispatch(updateOrganizationDetails(response));
  }
};

export const createOrganization = (organization) => async () => {
  const body = {
    name: organization.name,
    lockerManagerEmail: organization.lockerManagerEmail,
    orgType: organization.orgType,
    size: organization.size ?? undefined,
    website: organization.website ?? undefined,
    description: organization.description ?? undefined,
    landingPageEnabled: organization.landingPageEnabled ?? undefined,
    logoImage: organization.logoImage ?? undefined,
    colors: organization.colors ?? undefined,
    accountManager: organization.accountManager ?? undefined,
  };

  if (organization.attachedFile) {
    const logoResponse = await uploadLogoToOrganization(organization.attachedFile);
    body.logoImage = logoResponse.result;
  }

  const call = slServicesApi.post(organizationsUrls.addOrganization, {
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });

  const res = await makeApiCallWithSubmissionError(call);

  return res;
};

export const updateOrganization = (organization) => async () => {
  const body = {
    name: organization.name,
    lockerManagerEmail: organization.lockerManagerEmail,
    lockerManagerFirstName: organization.lockerManagerFirstName ?? undefined,
    lockerManagerLastName: organization.lockerManagerLastName ?? undefined,
    orgType: organization.orgType,
    size: organization.size ?? undefined,
    website: organization.website ?? undefined,
    description: organization.description ?? undefined,
    landingPageEnabled: organization.landingPageEnabled ?? undefined,
    logoImage: organization.logoImage ?? undefined,
    colors: organization.colors ?? undefined,
    accountManager: organization.accountManager ?? undefined,
  };

  if (organization.attachedFile) {
    const logoResponse = await uploadLogoToOrganization(organization.attachedFile);
    body.logoImage = logoResponse.result;
  }

  const call = slServicesApi.put(organizationsUrls.organization(organization.id), {
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });

  const res = await makeApiCallWithSubmissionError(call);

  return res;
};

export const deleteOrganization = (organizationId) => async () => {
  const call = slServicesApi.delete(organizationsUrls.organization(organizationId));
  const response = await makeApiCallWithErrorModal(call);

  return response;
};

export const fetchOrganizationSuggestions = (filter = '', limit = 10) => async (dispatch) => {
  const call = slServicesApi.get(
    organizationsUrls.organizationSuggestions(filter, limit),
    { handleBlockingLoading: false },
  );
  const response = await makeApiCall(call);
  if (response) {
    dispatch(updateOrganizationSuggestions(response));
  }
};

export const fetchLockerSuggestions = (filter = '', limit = 10, hideParentLockers = false) => async (dispatch) => {
  const call = slServicesApi.get(
    lockerUrls.lockerSuggestions(filter, limit, hideParentLockers),
    { handleBlockingLoading: false },
  );
  const response = await makeApiCall(call);
  if (response) {
    dispatch(updateLockerSuggestions(response));
  }
};

export const associateLockersWithOrganization = (organizationId, lockers) => async () => {
  const body = JSON.stringify({
    ids: lockers.map((l) => l.id),
  });

  const call = slServicesApi.post(organizationsUrls.organizationLockers(organizationId), {
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  });

  const res = await makeApiCallWithErrorModal(call);

  return res;
};

export const removeLockersFromOrganization = (organizationId, lockers) => async () => {
  const body = JSON.stringify({
    ids: lockers.map((l) => l.id),
  });

  const call = slServicesApi.put(organizationsUrls.removeOrganizationLockers(organizationId), {
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  });

  const res = await makeApiCallWithErrorModal(call);

  return res;
};

export const fetchOrganizationLockers = (organizationId, filter = '', sortColumn = '', sortDirection = '') => async (dispatch) => {
  const call = slServicesApi.get(
    organizationsUrls.organizationLockersSearch(organizationId, filter, sortColumn, sortDirection),
    { handleBlockingLoading: false },
  );

  const res = await makeApiCall(call);
  if (res) {
    dispatch(updateOrganizationLockers(res));
  }
};

export const updateAutoMerchOptIn = (organizationId, ids, optIn) => async (dispatch) => {
  const res = await putHomefieldApiV2MerchandisingTargetUpdateoptin({
    ids,
    optIn,
  });
  if (res?.success) {
    dispatch(fetchOrganizationLockers(organizationId));

    return res;
  }
};

export const fetchOrganizationGroups = (organizationId) => async (dispatch) => {
  const call = slServicesApi.get(organizationsUrls.organizationGroups(organizationId), {
    handleBlockingLoading: false,
  });
  const res = await makeApiCall(call);

  if (res) {
    dispatch(updateOrganizationGroups(res));
  }
};

export const addLockersToGroup = (groupId, lockers) => async () => {
  const body = JSON.stringify({
    ids: lockers.map((l) => l.id),
  });

  const call = slServicesApi.post(organizationsUrls.organizationGroupLockers(groupId), {
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  });

  const res = await makeApiCallWithErrorModal(call);

  return res;
};

export const removeLockersFromGroup = (groupId, lockers) => async () => {
  const body = JSON.stringify({
    ids: lockers.map((l) => l.id),
  });

  const call = slServicesApi.put(organizationsUrls.removeOrganizationGroupLockers(groupId), {
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  });

  const res = await makeApiCallWithErrorModal(call);

  return res;
};

export const createOrganizationGroup = (organizationId, group) => async (dispatch) => {
  const body = {
    name: group.name,
    featured: group.featured,
  };

  const call = slServicesApi.post(organizationsUrls.organizationGroups(organizationId), {
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });

  const response = await makeApiCallWithSubmissionError(call);

  const groupId = response.result;

  const lockersToAdd = [];
  for (const locker of group.newLockerList) {
    const isAddedLocker = !group.lockers.find((l) => l.id === locker.id);
    if (isAddedLocker) {
      lockersToAdd.push(locker);
    }
  }

  const lockersToRemove = [];
  for (const locker of group.lockers) {
    const isRemovedLocker = !group.newLockerList.find((l) => l.id === locker.id);
    if (isRemovedLocker) {
      lockersToRemove.push(locker);
    }
  }

  if (lockersToAdd.length > 0) {
    await dispatch(addLockersToGroup(groupId, lockersToAdd));
  }

  if (lockersToRemove.length > 0) {
    await dispatch(removeLockersFromGroup(groupId, lockersToRemove));
  }

  return response;
};

export const editOrganizationGroup = (group) => async (dispatch) => {
  const body = {
    name: group.name,
    featured: group.featured,
    'organization_id': group.organizationId ?? undefined,
  };

  const call = slServicesApi.put(organizationsUrls.organizationGroup(group.id), {
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });

  const response = await makeApiCallWithSubmissionError(call);

  const lockersToAdd = [];
  for (const locker of group.newLockerList) {
    const isAddedLocker = !group.lockers.find((l) => l.id === locker.id);
    if (isAddedLocker) {
      lockersToAdd.push(locker);
    }
  }

  const lockersToRemove = [];
  for (const locker of group.lockers) {
    const isRemovedLocker = !group.newLockerList.find((l) => l.id === locker.id);
    if (isRemovedLocker) {
      lockersToRemove.push(locker);
    }
  }

  if (lockersToAdd.length > 0) {
    await dispatch(addLockersToGroup(group.id, lockersToAdd));
  }

  if (lockersToRemove.length > 0) {
    await dispatch(removeLockersFromGroup(group.id, lockersToRemove));
  }

  return response;
};

export const fetchOrganizationGroupLockers = (groupId) => async (dispatch) => {
  const call = slServicesApi.get(organizationsUrls.organizationGroupLockers(groupId), { handleBlockingLoading: false });
  const res = await makeApiCall(call);

  if (res) {
    dispatch(updateOrganizationGroupLockers({
      groupId,
      lockers: res,
    }));
  }
};

export const fetchOrganizationFeaturedLockers = (organizationId) => async (dispatch) => {
  const call = slServicesApi.get(organizationsUrls.organizationFeaturedLockers(organizationId), {
    handleBlockingLoading: false,
  });
  const res = await makeApiCall(call);

  if (res) {
    dispatch(updateOrganizationFeaturedLockers(res));
  }
};

export const getOrganizationTotals = (organizationId) => async (dispatch) => {
  const call = getHomefieldApiLockersOrganizationsOrganizationIdTotals(organizationId);
  const response = await makeApiCall(call);

  if (response) {
    dispatch(updateOrganizationTotals(response));
  }
};
