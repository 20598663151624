import React from 'react';
import hash from 'object-hash';
import { decorationMethodEnum, personalizationTypeEnum, servicesApiPersonalizationTypeEnum } from '@constants/enums/decorationEnums';
import { ObjectWithId } from '@models/common/ObjectWithId';
import { Option } from '@models/common/Option';
import { LogoTask, PersonalizationTask } from '@models/Production/ProductionAssembly/Tasks';
import { pascalCaseObjectToCamelCaseObject } from './stringHelpers';
import { logoDetailColorNumber } from '@constants/values';
import { DecorationMethodEnum } from '@api/productCatalog/models';

interface Color {
  hexValue: string;
  displayName: string;
  code: string;
  threadValue?: string;
  dtgCode?: string;
}

interface Item {
  sku: string;
  status: {
    status: string;
  };
  inventoryAvailable: boolean;
  size: string;
  colorGroup: string;
  flag: boolean;
  decorationMethod: string;
  decorationLocation: string;
  imageUrl: string;
  logoUrl: string;
  previewUrl?: string;
  files: any;
  logos: any[];
  personalizations: any[];
}

export type ColorOptions = Record<string, string>;

const voodooColorOptions: ColorOptions = {
  'r': 'Red',
  'g': 'Green',
  'b': 'Blue',
  'rg': 'Yellow',
  'rb': 'Purple',
  'bg': 'Cyan',
} as const;

const voodooOptionsHeaderColors: ColorOptions = {
  'r': 'Red',
  'g': 'Green',
  'b': 'Blue',
  'rg': 'Yellow',
  'rb': 'Purple',
  'bg': 'Cyan',
} as const;

export const getColorLabel = (
  color: Color,
  method: (typeof decorationMethodEnum)[keyof typeof decorationMethodEnum],
  useDtgCode: boolean,
  useDisplayName: boolean,
): string => {
  switch (method) {
    case decorationMethodEnum.HAG:
      return useDisplayName ? color.displayName : color.code;
    case decorationMethodEnum.EMB:
      return color.threadValue ?? color.code;
    case decorationMethodEnum.DTG:
      return useDtgCode ? color.dtgCode ?? color.code : useDisplayName ? color.displayName : color.code;
    default:
      return useDisplayName ? color.displayName : color.code;
  }
};

export const mapToSwatchColorFields = (
  colors: Color[],
  method = decorationMethodEnum.HAG,
  useDtgCode = false,
  useDisplayName = false,
): JSX.Element[] => {
  if (colors?.length) {
    return (
      colors.map((color, index) => (
        <div
          className='color-swatch-item'
          key={index}
        >
          <div
            className='color-swatch-item__color'
            style={{ backgroundColor: color.hexValue }}
          />
          <span className='color-swatch-item__number'>
            {getColorLabel(color, method, useDtgCode, useDisplayName)}
          </span>
        </div>
      ))
    );
  }

  return [];
};

export const mapRowPositionColor = (rowPositionColor: string): string => voodooOptionsHeaderColors[rowPositionColor];

export const getRowPositionColorLabel = (rowPositionColor: string): string => voodooColorOptions[rowPositionColor];

export const mapToSwatchColorIconsSlice = (colors: Color[]): JSX.Element[] => {
  if (colors?.length) {
    return (
      colors.slice(0, logoDetailColorNumber).map((color, index) => (
        <div
          key={index}
          className='tooltip-container'
        >
          <div className='color-swatch-item'>
            <div
              className='color-swatch-item__color'
              style={{ backgroundColor: color.hexValue }}
            />
          </div>
          <div className='tooltip-text'>{color.code}</div>
        </div>
      ))
    );
  }

  return [];
};

export const mapToSwatchColorIcons = (colors: Color[]): JSX.Element[] => {
  if (colors?.length) {
    return (
      colors.map((color, index) => (
        <div
          key={index}
          className='tooltip-container'
        >
          <div className='color-swatch-item'>
            <div
              className='color-swatch-item__color'
              style={{ backgroundColor: color.hexValue }}
            />
          </div>
          <div className='tooltip-text'>{color.code}</div>
        </div>
      ))
    );
  }

  return [];
};

export const mapShippingCodeToName = (
  code: string,
  shippingOptions: {
    code: string;
    ['display_name']?: string;
  }[],
): string => {
  const shippingOption = shippingOptions.find((so) => so.code === code);

  return (code && ((shippingOption?.display_name) || code)) || '-';
};

export const getHashCodeForArtworkItem = (
  item: LogoTask,
  decorationMethod: DecorationMethodEnum,
): string => {
  let flag = null;
  if (item.flag) {
    flag = item.flag;
  }

  return hash({
    decorationMethod,
    decorationLocation: item.decorationLocation,
    imageUrl: item.imageUrl,
    logoUrl: item.logoUrl,
    previewUrl: item.previewUrl,
    files: item.files,
    flag,
  });
};

export const getHashCodeForLogoTask = (item: LogoTask): string => (
  hash({
    decorationLocation: item.decorationLocation,
    imageUrl: item.imageUrl,
    logoUrl: item.logoUrl,
    previewUrl: item.previewUrl,
    files: item.files,
    flag: item.flag,
  })
);

export const getHashCodeForPersonalizationTask = (item: PersonalizationTask): string => (
  hash({
    decorationLocation: item.decorationLocation,
    text: item.text,
    number: item.number,
    colorId: item.colorId,
    outlineColorId: item.outlineColorId,
    decorationWidth: item.decorationWidth,
    decorationHeight: item.decorationHeight,
    flag: item.flag,
  })
);

export const mapItemsToHashDictionary = (items: Item[]): Record<string, Item[]> => {
  const dictionary: Record<string, Item[]> = {};

  for (const item of items) {
    const logos = [];
    for (const logo of item.logos) {
      let flag = null;
      if (logo.flag) {
        flag = logo.flag;
      }

      logos.push({
        decorationMethod: logo.decorationMethod,
        decorationLocation: logo.decorationLocation,
        imageUrl: logo.imageUrl,
        logoUrl: logo.logoUrl,
        previewUrl: logo.previewUrl,
        files: logo.files,
        status: logo?.status?.status,
        flag,
      });
    }

    let personalization = null;
    if (item.personalizations && item.personalizations.length > 0) {
      personalization = item.personalizations;
    }

    const hashCode = hash({
      sku: item.sku,
      colorGroup: item.colorGroup,
      size: item.size,
      status: item?.status?.status,
      inventoryAvailable: item.inventoryAvailable,
      personalizations: personalization,
      logos,
    });

    if (dictionary[hashCode]) {
      dictionary[hashCode].push(item);
    } else {
      dictionary[hashCode] = [item];
    }
  }

  return dictionary;
};

export const mapListIds = (list: ObjectWithId[]): ObjectWithId[] => {
  list.forEach((item, index) => {
    item.id = index + 1;
  });

  return list;
};

export const mapFromServicesApiPersonalizationType = (
  personalizationType: (typeof servicesApiPersonalizationTypeEnum)[keyof typeof servicesApiPersonalizationTypeEnum],
): (typeof personalizationTypeEnum)[keyof typeof personalizationTypeEnum] | null => {
  for (
    const [
      key,
      value,
    ] of Object.entries(servicesApiPersonalizationTypeEnum)
  ) {
    if (personalizationType === value) {
      return personalizationTypeEnum[key as keyof typeof personalizationTypeEnum];
    }
  }

  return null;
};

export const mapToServicesApiPersonalizationType = (
  personalizationType: (typeof personalizationTypeEnum)[keyof typeof personalizationTypeEnum],
): (typeof servicesApiPersonalizationTypeEnum)[keyof typeof servicesApiPersonalizationTypeEnum] | null => {
  for (
    const [
      key,
      value,
    ] of Object.entries(personalizationTypeEnum)
  ) {
    if (personalizationType === value) {
      return servicesApiPersonalizationTypeEnum[key as keyof typeof servicesApiPersonalizationTypeEnum];
    }
  }

  return null;
};

export const mapToOptionsList = <
  T extends string | number | boolean | null = string | number | boolean,
  V extends { [key: string]: any } | (string | number) = { [key: string]: any },
>({
  list = [],
  key = undefined,
  value = undefined,
  name = undefined,
  emptyOption = undefined,
}: {
  list?: V[];
  key?: keyof V;
  value?: keyof V;
  name?: keyof V | ((item: { [key: string]: any }) => string);
  emptyOption?: Partial<Option<T>>;
}): Option<T | ''>[] => {
  let isArrayOfObjects = false;
  if (list && list.length > 0) {
    isArrayOfObjects = typeof list[0] === 'object';
  }

  const options: Option<T | ''>[] = list?.map((item) => {
    if (isArrayOfObjects) {
      const listItem = item as { [key: string]: any };

      return ({
        key: listItem[key as keyof { [key: string]: any }] as (string | number),
        value: (listItem[value as keyof { [key: string]: any }]) as T,
        name: (typeof name === 'function'
          ? name(listItem)
          : listItem[name as keyof { [key: string]: any }]) as string,
        disabled: (listItem.disabled || false) as boolean,
      });
    }

    const listItem = item as unknown as (string | number);

    return ({
      key: listItem!,
      value: listItem as T,
      name: listItem as string,
      disabled: false,
    });
  });

  if (emptyOption?.name) {
    return [
      {
        key: '-1',
        value: '',
        name: emptyOption.name,
        disabled: emptyOption.disabled ?? false,
      },
      ...options,
    ];
  }

  return options;
};

export const mapErrors = (
  error: { message: string },
  validation: { [key: string]: any },
): {
  [key: string]: any;
  _error?: string;
} => {
  const mapping: { [key: string]: any; _error?: string } = pascalCaseObjectToCamelCaseObject(validation);
  mapping._error = error.message;

  return mapping;
};

export const mapToOptionsGroupingList = <
  T extends string | number | boolean | null = string | number | boolean,
  V extends T | { [key: string]: any } = T | { [key: string]: any },
>({
  list = [],
  key = undefined,
  value = undefined,
  name = undefined,
  groupsNameIndicator = undefined,
  emptyOption = undefined,
  entity = '',
}: {
  list: V[];
  key?: keyof V;
  value?: keyof V;
  name?: keyof V | ((item: { [key: string]: any }) => string);
  groupsNameIndicator?: keyof V;
  emptyOption?: Option<T>;
  entity: string;
}): Option<T>[] => {
  let isArrayOfObjects = false;
  if (list && list.length > 0) {
    isArrayOfObjects = typeof list[0] === 'object';
  }

  const options: Option<T>[] = list?.map((item) => {
    if (isArrayOfObjects) {
      const listItem = item as { [key: string]: any };

      return ({
        key: listItem[key as keyof { [key: string]: any }] as (string | number),
        value: listItem[value as keyof { [key: string]: any }] as T,
        name: (listItem[name as keyof { [key: string]: any }] !== 'Default'
          ? listItem[name as keyof { [key: string]: any }]
          : (listItem[groupsNameIndicator as keyof { [key: string]: any }] === null
            ? `${emptyOption} ${entity}`
            : `${listItem[groupsNameIndicator as keyof { [key: string]: any }]} ${entity}`)) as string,
        groupName: (listItem.decorationMethod !== null ? listItem.decorationMethod : 'No decoration') as string,
      });
    }

    const listItem = item as unknown as T;

    return ({
      key: listItem as (string | number),
      value: listItem!,
      name: listItem as string,
      groupName: 'No decoration',
    });
  });

  return options;
};

export const mapQuantityToOptions = (quantity: number) => {
  const options = [];
  for (let i = 1; i <= quantity; i++) {
    const option = {
      key: i,
      value: i,
      name: i,
    };
    options.push(option);
  }

  return options;
};
