/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */

export interface PrintQueueDto {
  id?: number;
  created?: string | null;
  logoPrintFiles?: string[] | null;
  logoCutFiles?: string[] | null;
  personalizationCutFiles?: string[] | null;
  height?: number | null;
  logoCount?: number | null;
  shipDate?: string | null;
  rushShipDate?: string | null;
  expressProduction?: boolean;
  receivedOn?: string | null;
  claimedOn?: string | null;
  claimedBy?: string | null;
  doneOn?: string | null;
  doneBy?: string | null;
  hasWarningMessages?: boolean;
}
