import React, { useCallback } from 'react';
import Table from '@sharedComponents/Table/Table';
import IconCell from '../CellActions/IconCell';
import SelectAllCell from '@components/shared/Table/TableCells/SelectAllCell';
import ImagePreviewCell from '@components/shared/Table/TableCells/ImagePreviewCell';
import ImageModal from '@sharedComponents/Modal/ImageModal';

const CustomItemsTable = Table();

const AssignLogosToCustomItemsTable = ({ selectedItems, selectItem, selectAllItems, allItemsSelected, items }) => {
  const [imageModalIsOpen, setImageModalIsOpen] = React.useState(false);
  const [imageUrl, setImageUrl] = React.useState('');

  const openImageModal = useCallback((imageUrl) => {
    setImageModalIsOpen(true);
    setImageUrl(imageUrl);
  }, []);

  const closeImageModal = useCallback(() => {
    setImageModalIsOpen(false);
    setImageUrl('');
  }, []);

  const getColumns = () => {
    const columns = [
      {
        Header: () => (
          <SelectAllCell
            isSelected={allItemsSelected}
            selectAllItems={selectAllItems}
          />
        ),
        width: 60,
        accessor: '',
        Cell: (cellProps) => (
          <IconCell
            productsToAddLogo={selectedItems}
            product={cellProps.value}
            selectProduct={selectItem}
            lockingEnabled={false}
          />
        ),
      },
      {
        Header: 'Image',
        accessor: 'imageFront',
        className: 'no-padding',
        Cell: (cellProps) => (
          <ImagePreviewCell
            width='200px'
            height='200px'
            imageUrl={cellProps.value}
            openModalWithUrl={openImageModal}
          />
        ),
      },
      {
        Header: 'Custom Name',
        accessor: 'customName',
        width: 200,
      },
      {
        Header: 'Code',
        accessor: 'coloredStyle.code',
        width: 300,
      },
      {
        Header: 'Garment Color',
        accessor: 'coloredStyle.color_group',
      },
      {
        Header: 'Decoration',
        accessor: 'logos[0].decorationMethod',
      },
    ];

    return columns;
  };

  const columns = getColumns();

  return (
    <div className='sheet'>
      <ImageModal
        isOpen={imageModalIsOpen}
        closeModal={closeImageModal}
        imageUrl={imageUrl}
      />
      <CustomItemsTable
        data={items}
        columns={columns}
        showPagination={false}
        defaultPageSize={-1}
      />
    </div>
  );
};

export default AssignLogosToCustomItemsTable;
