import React, { useEffect } from 'react';
import { usePDF } from '@react-pdf/renderer';
import PrintableViewDocument from './PrintableViewDocument';
import Icon from '@components/shared/Icons/Icon';
import { HagPrintRoomOrderDetailsDto } from '@api/fulfillment/models';
import { ColorDto } from '@api/productCatalog/models';
import { useHasChanged } from '@hooks/helpers';

export interface RenderProps {
  url: string | null;
  error: string | null;
  loading: boolean;
  linkElement: JSX.Element;
}

interface OwnProps {
  orders: HagPrintRoomOrderDetailsDto[];
  className?: string;
  text?: string;
  fileName?: string;
  colors?: ColorDto[];
  onClick?: () => unknown;
  onLoading?: () => unknown;
  onLoaded?: () => unknown;
  onError?: (error: string) => unknown;
  render?: React.FC<RenderProps>;
}

type Props = OwnProps;

export const PrintableViewDownloadLink: React.FC<Props> = React.memo(({
  orders,
  className,
  text,
  fileName,
  colors,
  onClick,
  onLoading,
  onLoaded,
  onError,
  render,
}) => {
  const document = (
    <PrintableViewDocument
      orders={orders}
      colors={colors}
    />
  );

  const [
    instance,
    updateInstance,
  ] = usePDF({ document });

  const orderHasChanged = useHasChanged(orders);
  const hasOrders = !!orders.length;

  useEffect(() => {
    if (orderHasChanged) {
      updateInstance(document);
    }
  });

  useEffect(() => {
    if (onLoading && hasOrders && instance.loading) {
      onLoading();
    }
  }, [
    instance.loading,
    hasOrders,
    onLoading,
  ]);

  useEffect(() => {
    if (onLoaded && hasOrders && !instance.loading && instance.url) {
      onLoaded();
    }
  }, [
    instance.loading,
    instance.url,
    hasOrders,
    onLoaded,
  ]);

  useEffect(() => {
    if (onError && orders.length && instance.error) {
      onError(instance.error);
    }
  }, [
    instance.error,
    orders,
    onError,
  ]);

  let linkElement: JSX.Element;

  if (instance.loading) {
    linkElement = (
      <span>Generating Link...</span>
    );
  } else if (instance.error) {
    linkElement = (
      <span>PDF Error: {instance.error}</span>
    );
  } else if (!instance.url) {
    linkElement = (
      <span>Unable to load</span>
    );
  } else {
    linkElement = (
      <a
        href={instance.url}
        download={fileName ?? 'download_printable.pdf'}
        className={className ?? 'button button--link'}
      >
        <span onClick={onClick}>
          <span className='margin margin--right-s'>
            <Icon materialIcon={'print'} />
          </span>
          {text ?? 'Print Version'}
        </span>
      </a>
    );
  }

  if (render) {
    const RenderElement = render;

    return (
      <RenderElement
        url={instance.url}
        error={instance.error}
        loading={instance.loading}
        linkElement={linkElement}
      />
    );
  }

  return linkElement;
});

export default PrintableViewDownloadLink;
