/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Catalog Homefield API
 * OpenAPI spec version: v1
 */
import type { ColorBrightnessEnum } from './colorBrightnessEnum';

export interface UpdateColorCommand {
  id?: number;
  code?: string | null;
  displayName?: string | null;
  cmykValue?: string | null;
  dtgCmykValue?: string | null;
  hexValue?: string | null;
  threadValue?: string | null;
  brightness?: ColorBrightnessEnum;
  dtgCode?: string | null;
  isArtwork?: boolean;
  isFlood?: boolean;
}
