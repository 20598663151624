import React from 'react';
import { useGetHomefieldApiV1BulkstoresStagingjobsId } from '@api/accountsv1/bulk-stores';
import { Backdrop, Box, CircularProgress, Grid, lighten, makeStyles, Paper, Typography } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { getBulkCreateJobStatusEnumDisplayName } from './helpers';
import BackLink from '@components/shared/Navigation/BackLink';
import { bulkBuildUrl } from '@constants/clientUrls/clientUrls';
import { BulkCreateJobStatusEnum } from '@api/accountsv1/models';
import BulkBuildJobSDetailsList from './BulkBuildJobDetailsList';

type DetailsRouteParams = {
  jobId: string;
};

const useStyles = makeStyles((theme) => ({
  listRoot: {
    backgroundColor: lighten(theme.palette.primary.dark, 0.95),
    maxWidth: 600,
    maxHeight: 800,
    overflow: 'auto',
    position: 'relative',
    '& .MuiListItem-container:nth-child(even)': {
      backgroundColor: lighten(theme.palette.primary.dark, 0.92),
    },
  },
  listSubheader: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  listItem: {
    backgroundColor: 'inherit',
  },
  childList: {
    backgroundColor: 'inherit',
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

const BulkBuildJobDetails = () => {
  const classes = useStyles();
  const { jobId } = useParams<DetailsRouteParams>();
  const { data, isLoading, refetch } = useGetHomefieldApiV1BulkstoresStagingjobsId(Number(jobId), {
    query: {
      enabled: !!jobId,
    },
    request: {
      showErrorModal: true,
      isBlockingRequest: true,
    },
  });

  return (
    <>
      <Backdrop open={isLoading}>
        <CircularProgress />
      </Backdrop>
      {!isLoading && (
        <Box flex={1}>
          <BackLink
            text={'Bulk Build Jobs'}
            url={bulkBuildUrl}
          />
          <Box component={Paper} display='flex' flexDirection={'column'} gridRowGap={20} flex={1} p={2} mt={2}>
            <Box>
              <Typography variant='h5'>
                {data?.result?.sourceFileUrl}
              </Typography>
              <Typography variant='body1'>
                <em>{`Uploaded by ${data?.result?.user?.firstName} ${data?.result?.user?.lastName} on ${moment(data?.result?.created).format('M/D/YYYY h:mm A')}`}</em>
              </Typography>
              <Typography variant='body2'>
                <em>{`Status: ${getBulkCreateJobStatusEnumDisplayName(data?.result?.status as BulkCreateJobStatusEnum)}`}</em>
              </Typography>
            </Box>
            <Grid container={true}>
              <Grid item={true} md={6} sm={12}>
                <BulkBuildJobSDetailsList
                  rootClassName={classes.listRoot}
                  subHeaderClassName={classes.listSubheader}
                  listItemClassName={classes.listItem}
                  childListClassName={classes.childList}
                  nestedClassName={classes.nested}
                  job={data?.result}
                  refetchData={refetch}
                />
              </Grid>
              <Grid item={true} md={6} sm={12}>
                <div />
              </Grid>
            </Grid>
          </Box>
        </Box>
      )}
    </>
  );
};

export default BulkBuildJobDetails;
