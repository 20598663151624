import React, { useCallback } from 'react';
import MaterialTooltip from '../Tooltips/MaterialTooltip';

interface OwnProps {
  text: string | JSX.Element;
  onClick: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  classes?: string;
  icon?: Nullable<JSX.Element>;
  disabled?: boolean;
  tooltipText?: string;
}

type Props = OwnProps;

const LinkButton = React.memo(({
  text,
  onClick,
  classes = '',
  icon = null,
  disabled = false,
  tooltipText = '',
}: Props) => {
  const handleClick = useCallback((e: React.MouseEvent<HTMLAnchorElement>) => {
    if (disabled) return;

    onClick(e);
  }, [
    onClick,
    disabled,
  ]);

  return (
    <a
      className={`link ${classes} ${disabled ? 'disabled' : ''}`}
      onClick={handleClick}
    >
      <MaterialTooltip
        tooltipText={tooltipText}
        placement='bottom'
      >
        <>
          {icon} {text}
        </>
      </MaterialTooltip>
    </a>
  );
});

export default LinkButton;
