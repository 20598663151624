import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

class ArtworkFileTasksDetails extends PureComponent {
  render() {
    const { detail } = this.props;
    const { locker } = detail;

    if (locker) {
      const { id: lockerId } = locker;
      const title = (lockerId && locker.teamName)
        ? `Locker L${lockerId} - ${locker.teamName}`
        : '';
      return (
        <>
          <Helmet>
            <title>{title}</title>
          </Helmet>
          <div className='header__bar-content'>
            <h1 className='header__bar-title header__bar-content-block'>
              {
                { title }
              }
            </h1>
          </div>
        </>
      );
    }

    return <div />;
  }
}

ArtworkFileTasksDetails.propTypes = {
  detail: PropTypes.object,
};

const mapStateToProps = ({ artworkFileTasks }) => ({
  detail: artworkFileTasks.artworkFileTaskDetails,
});

export default connect(mapStateToProps)(ArtworkFileTasksDetails);
