/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */
import type { SubmitStatusEnum } from './submitStatusEnum';
import type { ScheduledOrdersSortByEnum } from './scheduledOrdersSortByEnum';
import type { SortDirectionEnum } from './sortDirectionEnum';

export type GetHomefieldApiVendorordersParams = { pageNumber?: number; pageSize?: number; search?: string; vendorId?: string[]; submitStatus?: SubmitStatusEnum; canceled?: boolean; sortBy?: ScheduledOrdersSortByEnum; sortDirection?: SortDirectionEnum };
