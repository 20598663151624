/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */

export type QueriesSortQueueByEnum = 'SheetId' | 'NumberOfLogos' | 'ShipDate' | 'StatusChangeOn';


export const QueriesSortQueueByEnum = {
  SheetId: 'SheetId' as QueriesSortQueueByEnum,
  NumberOfLogos: 'NumberOfLogos' as QueriesSortQueueByEnum,
  ShipDate: 'ShipDate' as QueriesSortQueueByEnum,
  StatusChangeOn: 'StatusChangeOn' as QueriesSortQueueByEnum,
};
