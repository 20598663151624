import React, {
  useState,
  useCallback,
} from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import MomentUtils from '@date-io/moment';
import {
  Path,
  UseFormRegister,
  UseFormSetValue,
  FieldValues
} from 'react-hook-form';
import { muiTheme } from '@constants/values';
import { parseDateFromLocalTimezone } from '@util/dateHandler';
import FormError from './FormError';

interface OwnProps<TFormData extends FieldValues> {
  register: UseFormRegister<TFormData>;
  setValue: UseFormSetValue<TFormData>;

  id?: string;
  name: Path<TFormData>;
  initialValue?: Nullable<Date> | string;
  label?: string;
  placeholder?: string;

  error?: string;
  touched?: any;

  autoFocus?: boolean;
  minDate?: Nullable<Date> | string;
  maxDate?: Nullable<Date> | string;

  onError?: (error: React.ReactNode, value: any) => void;

  width?: number;
  required?: boolean;
  disabled?: boolean;
  className?: string;
}

type Props<TFormData extends FieldValues> = OwnProps<TFormData>;

const FormDateInput = <TFormData extends FieldValues, >({
  register,
  setValue,
  id,
  name,
  initialValue,
  label,
  placeholder,
  error,
  minDate,
  maxDate,
  onError,
  width,
  required,
  disabled,
  className,
}: Props<TFormData>) => {
  const [
    date,
    setDate,
  ] = useState<Nullable<Date> | string>(initialValue ?? null);

  const onChange = useCallback((newDate: MaterialUiPickersDate) => {
    const newDateValue = newDate ? parseDateFromLocalTimezone(newDate?.toDate()) : null;

    setDate(newDateValue);
    setValue(name, newDateValue as any);
  }, [
    name,
    setValue,
  ]);

  return (
    <div>
      <ThemeProvider theme={muiTheme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <KeyboardDatePicker
            id={id}
            label={label}
            placeholder={placeholder}
            format='MM/DD/YYYY'
            variant='dialog'
            style={width ? { width: `${width}%` } : undefined}
            value={date}
            minDate={minDate}
            maxDate={maxDate}
            required={required}
            disabled={disabled}
            className={className}
            error={!!error}
            onFocus={undefined}
            {...register(name)}
            onChange={onChange}
            onError={onError}
          />
        </MuiPickersUtilsProvider>
      </ThemeProvider>
      {
        error &&
        <FormError
          error={error}
          fieldError={true}
        />
      }
    </div>
  );
};

export default React.memo(FormDateInput) as typeof FormDateInput;
