/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */
import type { VendorOrderDto } from './vendorOrderDto';

export interface VendorOrderDtoPagedList {
  pageNumber?: number;
  pageSize?: number;
  totalCount?: number;
  items?: VendorOrderDto[] | null;
  readonly totalPages?: number;
  readonly hasPreviousPage?: boolean;
  readonly hasNextPage?: boolean;
}
