import React, { useCallback } from 'react';
import { Grid } from '@material-ui/core';

import { postHomefieldApiV2MerchandisingAddsource } from '@api/accounts/merchandising';
import { UseFormReturn, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import yup from '@util/yupValidationHelper';
import HookformInput from '@components/shared/Form/HookformInput';
import Modal from '@components/shared/Modal/Modal';
import ModalButtons from '@components/shared/Modal/ModalButtons';
import { MerchandisingEffortDto } from '@api/accounts/models';
import { materialSwal } from '@util/componentHelper';

export interface AddMerchandisingSourceFormData {
  sourceId?: number;
}

interface OwnProps {
  isOpen: boolean;
  closeModal: () => void;
  onSubmit: () => void;
  item?: MerchandisingEffortDto;
}

const AddMerchandisingSourceDialog = ({ isOpen, closeModal, onSubmit, item }: OwnProps) => {
  const schema = yup.object({
    sourceId: yup.number().required('Source Id is required.'),
  });

  const formMethods: UseFormReturn<AddMerchandisingSourceFormData> = useForm<AddMerchandisingSourceFormData>({
    // @ts-expect-error - resolver is weird
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
    defaultValues: {
      sourceId: undefined,
    },
  });

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = formMethods;

  const handleCloseModal = useCallback(() => {
    setValue('sourceId', undefined);
    reset(undefined);
    closeModal();
  }, [closeModal, reset, setValue]);

  const handleFormSubmit = useCallback(() => {
    handleSubmit(async (data) => {
      try {
        const response = await postHomefieldApiV2MerchandisingAddsource({
          effortId: item?.id,
          sourceId: data.sourceId,
        });
        onSubmit();
        handleCloseModal();
        materialSwal('Success', `${response.result} Products from Source Id: ${data.sourceId} were added.`, 'success');
      } catch (error) {
        console.error('error', error);
      }
    })();
  }, [handleCloseModal, handleSubmit, item?.id, onSubmit]);

  return (
    <Modal
      title={`Add Merchandising Source (${item?.name})`}
      modalSize={'l'}
      isOpen={isOpen}
      closeModal={handleCloseModal}
      buttons={(
        <ModalButtons
          confirmBtnText={'Add'}
          cancelBtnText={'Cancel'}
          onClose={handleCloseModal}
          formSubmission={true}
          formId={'add-merchandising-source-form'}
        />
      )}
    >
      <form onSubmit={handleSubmit(handleFormSubmit)} noValidate={true} id='add-merchandising-source-form'>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={11}>
            <HookformInput
              id='sourceid'
              name='sourceId'
              placeholder='Enter the SourceId'
              label='Source Id'
              type='text'
              autoFocus={true}
              error={errors?.sourceId?.message}
              required={true}
              control={control}
            />
          </Grid>
        </Grid>
      </form>
    </Modal>
  );
};

export default AddMerchandisingSourceDialog;
