/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */

export type ParentLockerEnum = 'All' | 'NoParent' | 'WithParent';


export const ParentLockerEnum = {
  All: 'All' as ParentLockerEnum,
  NoParent: 'NoParent' as ParentLockerEnum,
  WithParent: 'WithParent' as ParentLockerEnum,
};
