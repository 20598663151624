/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Financials Homefield API
 * OpenAPI spec version: v1
 */
import type { AccountExecutiveInfo } from './accountExecutiveInfo';

export interface IContactDto {
  readonly id?: number;
  readonly uuid?: string | null;
  readonly created?: string | null;
  readonly updated?: string | null;
  readonly deleted?: string | null;
  readonly email?: string | null;
  readonly firstName?: string | null;
  readonly lastName?: string | null;
  readonly phoneNumber?: string | null;
  readonly organizationType?: string | null;
  readonly identityUserId?: string | null;
  readonly storefrontLockerManagerId?: number | null;
  accountExecutive?: AccountExecutiveInfo;
}
