/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Orders Homefield API
 * OpenAPI spec version: v1
 */
import type { ShippingInfo } from './shippingInfo';
import type { AccountingActionTypeEnum } from './accountingActionTypeEnum';

export interface CalculateUpdateShippingInfoPriceChangeQuery {
  orderNumber?: number;
  shippingInfo?: ShippingInfo;
  actionType?: AccountingActionTypeEnum;
  amount?: number;
  reason?: string | null;
}
