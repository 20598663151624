import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import notificationIcon from '@assets/mail_outline-24px.svg';
import { parseDateNumeric } from '@util/dateHandler';
import RefundPrice from '@sharedComponents/Display/RefundPrice';
import MaterialTooltip from '@sharedComponents/Tooltips/MaterialTooltip';
import SelectIcon from '@sharedComponents/Icons/SelectIcon';
import Image from '@sharedComponents/Display/Image';
import Tag from '@sharedComponents/Display/Tag';
import Icon from '@sharedComponents/Icons/Icon';
import ItemInfoModal from '@sharedComponents/ItemInfo/ItemInfoModal';
import OrderManagementDetailsItemLabels from '@components/OrderManagement/OrderManagementDetailsItemLabels';

class QuickViewOrderItem extends PureComponent {
  state = {
    itemInfoModalIsOpen: false,
  };

  openItemInfoModal = () => {
    this.setState(() => ({ itemInfoModalIsOpen: true }));
  };

  closeItemInfoModal = () => {
    this.setState(() => ({ itemInfoModalIsOpen: false }));
  };

  render() {
    const {
      item,
      selectable = false,
      isSelected,
      selectItem,
    } = this.props;

    const { itemInfoModalIsOpen } = this.state;

    return (
      <div className='order__item'>
        <ItemInfoModal
          isOpen={itemInfoModalIsOpen}
          closeModal={this.closeItemInfoModal}
          sku={item?.sku}
          lockerItemId={item?.lockerItemId}
        />
        <div className='align__center'>
          {
            selectable &&
            <SelectIcon
              select={selectItem}
              item={item}
              isSelected={isSelected}
            />
          }
          <div className='order__item-column align__center ml-10 mr-10'>
            <Image
              url={item.imageUrl}
              openInModal={this.openItemInfoModal}
              showImageInModal={false}
            />
          </div>
        </div>
        <div className='order__item-column'>
          <div className='align__center justify__space-between'>
            <span className='order__item--sku-info'>
              {item.sku || '-'}
              <OrderManagementDetailsItemLabels item={item} />
            </span>
            {
              item.notification &&
              <MaterialTooltip
                tooltipText={(
                  <div>
                    <div>{parseDateNumeric(item.notification.created)}</div>
                    <div>{item.notification.user}</div>
                  </div>
                )}
                placement={'top'}
              >
                <Icon
                  iconUrl={notificationIcon}
                  classes={'fa mr-10 backorder-management__sidebar__tab-content--items__notification'}
                />
              </MaterialTooltip>
            }
          </div>
          <span className='order__item--style-name'>{item.styleName || ''}</span>
          <ul className='order__item-tags'>
            <li>
              <Tag value={`x${item.quantity}`} />
            </li>
            <li>
              <Tag
                label={'Size'}
                value={item.size}
              />
            </li>
            <li>
              <Tag
                label={'Color'}
                value={item.colorGroup}
              />
            </li>
            <li>
              <Tag
                label={'Price'}
                value={(
                  <RefundPrice
                    paid={item.pricePaid || '-'}
                  />
                )}
              />
            </li>
            {
              item.promisedDate &&
              <li>
                <span className='backorder-management__sidebar__ship-date'>
                  <Icon materialIcon={'schedule'} />
                  <span>
                    {parseDateNumeric(item.promisedDate)}
                  </span>
                </span>
              </li>
            }
          </ul>
        </div>
      </div>
    );
  }
}

QuickViewOrderItem.propTypes = {
  item: PropTypes.shape({
    ids: PropTypes.arrayOf(PropTypes.number).isRequired,
    sku: PropTypes.string.isRequired,
    lockerItemId: PropTypes.number,
    imageUrl: PropTypes.string,
    styleName: PropTypes.string,
    quantity: PropTypes.number,
    size: PropTypes.string,
    colorGroup: PropTypes.string,
    pricePaid: PropTypes.number,
    refundAmount: PropTypes.number,
    chargeAmount: PropTypes.number,
    shipDate: PropTypes.string,
    promisedDate: PropTypes.string,
    notification: PropTypes.shape({
      user: PropTypes.string,
      created: PropTypes.string.isRequired,
    }),
  }).isRequired,
  selectable: PropTypes.bool,
  isSelected: PropTypes.bool,
  selectItem: PropTypes.func,
};

export default QuickViewOrderItem;
