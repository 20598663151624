/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Financials Homefield API
 * OpenAPI spec version: v1
 */
import type { OrderItemPayoutStatusEnum } from './orderItemPayoutStatusEnum';
import type { PayoutStatusEnum } from './payoutStatusEnum';

export type GetHomefieldApiOrderitemsstatusParams = { LockerId?: number[]; OrderNumber?: number[]; OrderItemId?: number[]; Status?: OrderItemPayoutStatusEnum[]; PayoutStatus?: PayoutStatusEnum[]; IncludeStyleName?: boolean; IncludeItems?: boolean };
