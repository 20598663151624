import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { lockerMgrS3Logos } from '@constants/common';
import {
  decorationMethodEnum,
  colorBrightnessEnum,
  selectionTypeEnum,
} from '@constants/enums/decorationEnums';
import { removeLogoFromLockerLogos } from '@APICalls/lockerManager/actions';
import { updateLogo } from '@APICalls/logos/actions';
import { fileNameFromUrlWithExtension } from '@util/stringHelpers';
import {
  downloadFile,
  materialSwal,
} from '@util/componentHelper';
import { filterAdminColors } from '@util/colorHelper';
import EditLogoColorsModal from '@sharedComponents/UploadLogoSwatchColors/EditLogoColorsModal';
import LogoBankEditAssignment from '../../LogoBankEditAssignment';
import LogoDetails from './LogoDetails';
import ShowMoreActionsButton from '@sharedComponents/Buttons/ShowMoreActionsButton';

class DetailedLockerLogo extends PureComponent {
  state = {
    openEditAssignment: false,
    editLogoColorsModalIsOpen: false,
    logoColors: filterAdminColors(this.props.logo.colorSelections || []).map((c) => c.code),
  };

  componentDidUpdate(prevProps) {
    const { logo: { colorSelections } } = this.props;

    if (prevProps.logo.colorSelections !== colorSelections) {
      this.setState(() => ({
        logoColors: filterAdminColors(colorSelections || []).map((c) => c.code),
      }));
    }
  }

  openLogoImage = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const {
      openModalWithImage,
      logo,
    } = this.props;

    openModalWithImage(logo.image);
  };

  assignLogo = () => {
    this.setState(() => ({ openEditAssignment: true }));
  };

  toggleLogoApprovedStatus = () => {
    const {
      toggleApprovedStatus,
      logo,
    } = this.props;
    toggleApprovedStatus(logo.id, !logo.isApproved);
  };

  closeEditAssignmentModal = (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    this.setState(() => ({ openEditAssignment: false }));
  };

  openEditLogoColorsModal = () => {
    this.setState(() => ({ editLogoColorsModalIsOpen: true }));
  };

  closeEditLogoColorsModal = (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    this.setState(() => ({ editLogoColorsModalIsOpen: false }));
  };

  openLogoDetailsModal = () => {
    const {
      logo,
      openLogoDetailsModal,
    } = this.props;
    openLogoDetailsModal(logo);
  };

  updateLogo = async (newColors, selectedMethods, floodColorIds) => {
    const {
      logo,
      colors,
      refreshLogos,
      refreshUsedLogos,
    } = this.props;

    const customColors = [];
    const colorIds = [];

    if (newColors && colors && colors.length > 0) {
      for (const newColor of newColors) {
        const selectedColor = colors.find((ac) => ac.code === newColor);
        if (selectedColor) {
          colorIds.push(selectedColor.id);
        } else {
          customColors.push(newColor);
        }
      }

      const res = await updateLogo(
        logo.id,
        selectionTypeEnum.UserOnly,
        colorIds,
        customColors,
        null,
        selectedMethods,
        floodColorIds
      );

      if (res?.success) {
        materialSwal('Success', res.message, 'success');
        this.closeEditLogoColorsModal();
        refreshLogos();
        refreshUsedLogos();
      }
    }
  };

  removeFromLockerLogos = async () => {
    const {
      logo,
      lockerId,
      refreshLogos,
    } = this.props;

    const res = await removeLogoFromLockerLogos(lockerId, logo.id);
    if (res?.success) {
      materialSwal('Success', res.message, 'success');
      refreshLogos();
    }
  };

  requeueForLogoReview = () => {
    const {
      logo,
      requeueForLogoReview,
    } = this.props;

    requeueForLogoReview(logo);
  };

  render() {
    const {
      logo,
      lockerId,
      colors,
      artworkColors,
      allLightColors,
      allDarkColors,
      refreshLogos,
      enableMultipleLogos,
    } = this.props;

    const {
      openEditAssignment,
      editLogoColorsModalIsOpen,
      logoColors,
    } = this.state;

    let decorations = [];

    if (logo.decorationMethods) {
      decorations = logo.decorationMethods.map((method, index) => (
        <div
          key={index}
          className='tooltip-container'
        >
          <span className='logo-bank__used--decoration'>{method}</span>
          <div className='tooltip-text'>
            {method === decorationMethodEnum.HAG && 'Logo can be applied using the HAG decoration method'}
            {method === decorationMethodEnum.EMB && 'Logo can be applied using the EMB decoration method'}
            {method === decorationMethodEnum.DTG && 'Logo can be applied using the DTG decoration method'}
            {method === decorationMethodEnum.DIP && 'Logo can be applied using the DIP decoration method'}
          </div>
        </div>
      ));
    }

    const image = `${lockerMgrS3Logos}/${logo.image}`;
    const approvedLightColors = logo.floodColors.filter((c) => c.brightness === colorBrightnessEnum.Light);
    const approvedDarkColors = logo.floodColors.filter((c) => c.brightness === colorBrightnessEnum.Dark);

    return (
      <div className='logo-bank__used--logo-container'>
        <LogoBankEditAssignment
          logoId={logo.id}
          modalIsOpen={openEditAssignment}
          closeModal={this.closeEditAssignmentModal}
          logoImage={logo.image}
          lockerId={lockerId}
        />
        <EditLogoColorsModal
          isOpen={editLogoColorsModalIsOpen}
          logoColors={logoColors}
          cancel={this.closeEditLogoColorsModal}
          logoId={logo?.id}
          logoUrl={image}
          logoName={logo?.image}
          saveLogoColors={this.updateLogo}
          decorationMethod={logo.decorationMethods}
          colors={colors}
          artworkColors={artworkColors}
          allDecorationsActive={false}
          approvedLightColors={approvedLightColors}
          approvedDarkColors={approvedDarkColors}
          allLightColors={allLightColors}
          allDarkColors={allDarkColors}
          refreshLogos={refreshLogos}
        />
        <div className='logo-bank__used--logo on-hover__container'>
          <div
            onClick={this.openLogoImage}
            type='button'
            className='logo-bank__used--logo--image'
            style={{ backgroundImage: `url("${image}")` }}
          >
            {
              decorations.length > 0 &&
              <div className='logo-bank__used--decorations'>{decorations}</div>
            }

            {
              !logo.isApproved &&
              <div className='logo-bank__used--logo--unapproved-label-container'>
                <span className='logo-bank__used--logo--unapproved-label'>
                  <span className='material-icons logo-bank__used--logo--unapproved-label__icon'>warning</span>Unapproved
                </span>
              </div>
            }

            <ShowMoreActionsButton
              classes={'right--1'}
              actions={[
                {
                  text: 'Assign Logo to Items',
                  action: this.assignLogo,
                  isVisible: enableMultipleLogos,
                },
                {
                  text: logo.isApproved ? 'Unapprove Logo' : 'Approve Logo',
                  action: this.toggleLogoApprovedStatus,
                  isVisible: true,
                },
                {
                  text: 'Remove from Locker Logos',
                  action: this.removeFromLockerLogos,
                  isVisible: true,
                },
                {
                  text: 'Edit Logo',
                  action: this.openEditLogoColorsModal,
                  isVisible: true,
                },
                {
                  text: 'Logo Details',
                  action: this.openLogoDetailsModal,
                  isVisible: true,
                },
                {
                  text: 'Download Logo',
                  action: downloadFile(image, logo.image),
                  isVisible: true,
                },
                {
                  text: 'Requeue for Logo Review',
                  action: this.requeueForLogoReview,
                  isVisible: !logo.needsReview || logo.reviewedAt,
                },
              ]}
            />
          </div>
          <span className='logo-bank__used--title'>{fileNameFromUrlWithExtension(logo.image)}</span>
        </div>
        <LogoDetails
          logo={logo}
          colors={colors}
        />
      </div>
    );
  }
}

DetailedLockerLogo.propTypes = {
  logo: PropTypes.object.isRequired,
  lockerId: PropTypes.number.isRequired,
  openModalWithImage: PropTypes.func.isRequired,
  toggleApprovedStatus: PropTypes.func.isRequired,
  openLogoDetailsModal: PropTypes.func.isRequired,
  colors: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    brightness: PropTypes.string,
    hexValue: PropTypes.string,
    isArtwork: PropTypes.bool,
    isFlood: PropTypes.bool,
  })),
  artworkColors: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    brightness: PropTypes.string,
    hexValue: PropTypes.string,
    isArtwork: PropTypes.bool,
    isFlood: PropTypes.bool,
  })),
  allLightColors: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    brightnes: PropTypes.number,
    hexValue: PropTypes.string,
    isArtwork: PropTypes.bool,
    isFlood: PropTypes.bool,
  })).isRequired,
  allDarkColors: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    brightnes: PropTypes.number,
    hexValue: PropTypes.string,
    isArtwork: PropTypes.bool,
    isFlood: PropTypes.bool,
  })).isRequired,
  refreshLogos: PropTypes.func.isRequired,
  refreshUsedLogos: PropTypes.func.isRequired,
  enableMultipleLogos: PropTypes.bool.isRequired,
  requeueForLogoReview: PropTypes.func.isRequired,
};

const mapStateToProps = ({
  support,
  lockerManager,
}) => ({
  colors: support.colors,
  artworkColors: support.artworkColors,
  allLightColors: support.lightColors,
  allDarkColors: support.darkColors,
  enableMultipleLogos: lockerManager.lockerInfo.enableMultipleLogos,
});

export default connect(mapStateToProps)(DetailedLockerLogo);
