/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Catalog Homefield API
 * OpenAPI spec version: v1
 */
import type { LayoutDto } from './layoutDto';

export interface UpdateStyleLayoutsCommand {
  id?: number;
  layouts?: LayoutDto[] | null;
}
