/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Accounts Homefield API
 * OpenAPI spec version: 2.0
 */
import type { ShippingOptionDto } from './shippingOptionDto';

export interface ShippingOptionDtoApiResponse {
  success?: boolean;
  message?: string | null;
  result?: ShippingOptionDto;
}
