import React, { useCallback, useEffect, useMemo } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { fetchSchedulingQueueTooltip } from '@redux/llScheduling/actions';
import MaterialTooltip from '@sharedComponents/Tooltips/MaterialTooltip';
import IconEye from '@assets/icon_eye.svg';
import { CircularProgress } from '@material-ui/core';
import ColorContainerComponent from './ColorContainerComponent';
import { getColors } from '@redux/productCatalog/actions';
import { fetchLogo } from '@redux/logos/actions';
import { decorationMethodEnum, decorationTypeEnum } from '@constants/enums/decorationEnums';
import { RootState } from '@redux/index/reducers';
import { sortByField } from '@util/sortingHelpers';

interface Row {
  id: number;
  orderNumber: number;
  relatedInProgressCount: number;
  relatedTotalCount: number;
  decorationCount: number;
}

interface OwnProps {
  row: Row;
  schedulingQueue: any;
}

const mapStateToProps = ({
  productCatalog,
  llScheduling,
  logos,
}: RootState) => ({
  colorsDictionary: productCatalog.colorsDictionary,
  schedulingQueueTooltip: llScheduling.schedulingQueueTooltip,
  logo: logos.logo,
});

const mapDispatchToProps = {
  fetchQueue: fetchSchedulingQueueTooltip,
  fetchColors: getColors,
  getLogo: fetchLogo,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = OwnProps & ConnectedProps<typeof connector>;

const RowComponent = React.memo(({
  row,
  schedulingQueueTooltip,
  colorsDictionary,
  logo,
  schedulingQueue,
  fetchQueue,
  fetchColors,
  getLogo,
}: Props) => {
  const onMouseEnter = useCallback(() => {
    if (schedulingQueueTooltip[row.id] === undefined) {
      fetchQueue(row.id);
    }
    if (Object.keys(colorsDictionary).length === 0) {
      fetchColors();
    }
  }, [schedulingQueueTooltip, row.id, colorsDictionary, fetchQueue, fetchColors]);

  const sortData = useMemo(() => {
    if (schedulingQueueTooltip[row.id] === undefined) {
      return undefined;
    }

    return schedulingQueueTooltip[row.id].sort(sortByField('decorationLocationType'));
  }, [
    schedulingQueueTooltip,
    row,
  ]);

  useEffect(() => {
    if (sortData && sortData.length > 0 && sortData[0].decorationLocationType === decorationTypeEnum.Logo) {
      getLogo(sortData[0].logoId);
    }
  }, [
    sortData,
    getLogo,
  ]);

  const showData = useCallback(
    (tooltipRow: any) => (
      <>
        <div
          key={tooltipRow.uuid}
          className='flex'
        >
          <div className='tooltip__width-small'>
            {tooltipRow.decorationLocationType === decorationTypeEnum.Personalization ? 'Pers.' : tooltipRow.decorationLocationType}
          </div>
          <div className='tooltip__width-small'>{tooltipRow.decorationLocation}</div>
          <div className='tooltip__width-large'>
            <span className='row-component__measures'>
              {tooltipRow.decorationLocationType === decorationTypeEnum.Logo
                ? `${tooltipRow.decorationLocationHeight}" x ${tooltipRow.decorationLocationWidth}"`
                : '-'}
            </span>
          </div>
          {schedulingQueue.decorationMethod === decorationMethodEnum.EMB
            && (
              <div className='tooltip__width-large'>
                {tooltipRow.stitchCount ? `${tooltipRow.stitchCount} stitches` : '-'}
              </div>
            )}
          <div className='tooltip__colors'>
            {tooltipRow.decorationLocationType === decorationTypeEnum.Logo && logo
              && logo.selectedColors.map((c: any) => (
                <ColorContainerComponent
                  color={c}
                  key={c.id}
                />
              ))}
            {tooltipRow.personalizationColorId && (
              <ColorContainerComponent
                color={colorsDictionary[tooltipRow.personalizationColorId]}
              />
            )}
            {tooltipRow.personalizationOutlineColorId && (
              <ColorContainerComponent
                color={colorsDictionary[tooltipRow.personalizationOutlineColorId]}
              />
            )}
          </div>
        </div>
      </>
    ),
    [colorsDictionary, logo, schedulingQueue.decorationMethod],
  );

  return (
    <div className='row-component__container'>
      <div>
        {row.decorationCount === 1
          ? `${row.decorationCount} Decoration`
          : `${row.decorationCount} Decorations`}
      </div>
      <div>
        <MaterialTooltip
          tooltipClassName='tooltip-max-width'
          tooltipText={!sortData
            ? (
              <div className='row-component__container__circuit-progress'>
                <CircularProgress size={20} />
              </div>
            )
            : (
              <table className='tooltip-table'>
                <tbody>
                  {sortData.map(showData)}
                </tbody>
              </table>
            )}
          placement={'top'}
          contentClasses={'align__center'}
        >
          <div
            onMouseEnter={onMouseEnter}
            className='ll-scheduling-queue-details__icon-eye'
            style={{
              backgroundImage: `url("${IconEye}")`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              backgroundSize: 'contain',
            }}
          />
        </MaterialTooltip>
      </div>
    </div>
  );
});

export default connector(RowComponent);
