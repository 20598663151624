/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Product Catalog API
 * OpenAPI spec version: v1
 */

export interface SaveProductSkuInfoCommand {
  created?: string;
  sku?: string | null;
  timeStamp?: string | null;
  categoryCode?: string | null;
  brandCode?: string | null;
  description?: string | null;
  decorationMethod?: string | null;
  decorationLocation?: string | null;
  personalizationVariant?: string | null;
  floodColor?: string | null;
  personalizationLocations?: string[] | null;
  basePrice?: number | null;
  standardCost?: number | null;
  currentCost?: number | null;
  weight?: number | null;
  styleCode?: string | null;
  taxScheduleId?: string | null;
  taxOptions?: number | null;
  taxCode?: string | null;
  gender?: string | null;
  age?: string | null;
  size?: string | null;
  classId?: string | null;
  colorA?: string | null;
  colorB?: string | null;
  colorGroup?: string | null;
  imageFileBack?: string | null;
  imageFileFront?: string | null;
  imageFileSide?: string | null;
  vendorCode?: string | null;
  rbiUpc?: string | null;
  neutral?: boolean;
  alwaysInclude?: boolean;
  discontinued?: boolean;
  visible?: boolean;
  sports?: string[] | null;
}
