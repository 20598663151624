import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { minInputLengthForSuggestions } from '@constants/values';
import { fetchLayoutTable } from '@redux/layouts/actions';
import OutsideClickWrapper from '@sharedComponents/OutsideClickWrapper';
import Icon from '@sharedComponents/Icons/Icon';
import Chip from '@sharedComponents/Inputs/Chips/Chip';
import LayoutsDropdown from '@sharedComponents/Layouts/LayoutsDropdown/LayoutsDropdown';

class LayoutsInput extends PureComponent {
  state = {
    isInputActive: false,
    showSuggestions: false,
    searchInput: '',
    throttledCall: null,
  };

  componentWillUnmount() {
    const { throttledCall } = this.state;

    if (throttledCall) {
      clearTimeout(throttledCall);
    }

    this.setState({ throttledCall: null });
  }

  onInputFocus = () => {
    const { searchInput } = this.state;
    this.setState({
      isInputActive: true,
      showSuggestions: searchInput.length > minInputLengthForSuggestions,
    });
  };

  onInputBlur = () => {
    this.setState({
      isInputActive: false,
      showSuggestions: false,
    });
  };

  addLayoutToList = async (layout) => {
    const { fields } = this.props;

    if (fields.getAll().find((l) => l.id === layout.id)) {
      return;
    }

    this.searchInput.value = '';

    fields.push(layout);

    this.setState({
      isInputActive: false,
      showSuggestions: false,
      searchInput: '',
    });
  };

  removeLayoutFromList = (layout, index) => {
    const { fields } = this.props;

    fields.remove(index);
  };

  onInputChange = () => {
    const newSearchInput = this.searchInput.value ? this.searchInput.value.trim() : '';
    this.setState({ searchInput: newSearchInput });

    const { throttledCall } = this.state;
    if (throttledCall) {
      clearTimeout(throttledCall);
    }

    if (newSearchInput.length < minInputLengthForSuggestions) {
      this.setState({
        showSuggestions: false,
        throttledCall: null,
      });

      return;
    }

    const newThrottle = setTimeout(this.updateSuggestions, 300);
    this.setState({
      throttledCall: newThrottle,
      showSuggestions: false,
    });
  };

  updateSuggestions = async () => {
    const { dispatch } = this.props;

    const {
      throttledCall,
      searchInput,
    } = this.state;

    if (throttledCall) {
      clearTimeout(throttledCall);
    }

    await dispatch(fetchLayoutTable(1, 10, searchInput));

    this.setState({ showSuggestions: true });
  };

  renderChip = (layout, index) => (
    <Chip
      key={layout.id}
      text={layout.name}
      onClick={this.removeLayoutFromList.bind(null, layout, index)}
      hasIcon={true}
    />
  );

  setSearchInputRef = (r) => {
    this.searchInput = r;
  };

  render() {
    const {
      fields,
      suggestions,
      isDropUp = false,
    } = this.props;

    const {
      isInputActive,
      showSuggestions,
      searchInput,
    } = this.state;

    const layouts = fields.getAll() || [];

    return (
      <div className='mb-40'>
        <div className='align__center'>
          <div className={`chips-search has-icon w-70 ${isInputActive ? 'active' : ''}`}>
            <div>
              <Icon materialIcon={'search'} />
            </div>
            <div className='chips'>
              {layouts.map((layout, i) => this.renderChip(layout, i))}
              <div className='text-field mb-0'>
                <OutsideClickWrapper onClick={this.onInputBlur}>
                  <>
                    <input
                      ref={this.setSearchInputRef}
                      placeholder={layouts.length === 0 ? 'Select Layouts' : ''}
                      onChange={this.onInputChange}
                      onFocus={this.onInputFocus}
                    />
                    {
                      showSuggestions &&
                      <LayoutsDropdown
                        layouts={layouts}
                        addLayoutToList={this.addLayoutToList}
                        suggestions={suggestions}
                        suggestionsAreShown={showSuggestions}
                        onInputChange={this.onInputChange}
                        onInputFocus={this.onInputFocus}
                        onInputBlur={this.onInputBlur}
                        isDropUp={isDropUp}
                      />
                    }
                  </>
                </OutsideClickWrapper>
              </div>
            </div>
          </div>
        </div>
        {
          searchInput.length > minInputLengthForSuggestions && suggestions && suggestions.length === 0 &&
          <div className='redux-form__error'>Layout not found</div>
        }
      </div>
    );
  }
}

LayoutsInput.propTypes = {
  fields: PropTypes.object,
  isDropUp: PropTypes.bool,
  suggestions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
  })),
};

const mapStateToProps = ({ layouts }) => ({
  suggestions: layouts.layoutsQueue.queue,
});

export default connect(mapStateToProps)(LayoutsInput);
