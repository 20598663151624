/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Orders Homefield API
 * OpenAPI spec version: v1
 */
import type { Logo } from './logo';
import type { QueriesItemDto } from './queriesItemDto';
import type { ColorDto } from './colorDto';
import type { Product } from './product';
import type { ImageDto } from './imageDto';

export interface QueriesVariationDto {
  id?: number;
  uuid?: string | null;
  created?: string | null;
  updated?: string | null;
  deleted?: string | null;
  clcItemLogoId?: number | null;
  clcItemLogo?: Logo;
  itemId?: number;
  item?: QueriesItemDto;
  coloredStyleId?: number;
  color?: ColorDto;
  products?: Product[] | null;
  images?: ImageDto[] | null;
}
