import React from 'react';
import ReactModal from 'react-modal';
import { modalStyle } from '@constants/values';
import Icon from '@sharedComponents/Icons/Icon';

ReactModal.setAppElement('body');

interface OwnProps {
  isOpen: boolean;
  closeModal: () => void;
  title?: string;
  modalStyleProps?: any;
  modalSize?: string;
  modalClass?: string;
  modalHeaderClass?: string;
  modalCloseBtnClass?: string;
  children: Nullable<JSX.Element>;
  buttons?: Nullable<JSX.Element>;
  enableBodyOverflow?: boolean;
  dataTest?: string;
  modalHeight?: string;
  modalWidth?: string;
  isPadded?: boolean;
  customClasses?: string;
  shouldCloseOnOverlayClick?: boolean;
}

type Props = OwnProps;

export type ModalProps = Props;

const Modal = React.memo(({
  isOpen,
  closeModal,
  title = '',
  modalStyleProps,
  modalSize = 'xl',
  modalClass = '',
  modalHeaderClass = '',
  modalCloseBtnClass = '',
  children,
  buttons = null,
  enableBodyOverflow = false,
  dataTest = 'modal',
  modalHeight,
  modalWidth,
  isPadded = false,
  customClasses = '',
  shouldCloseOnOverlayClick = false,
}: Props) => {
  let overflowStyleProps = {};
  if (enableBodyOverflow) {
    overflowStyleProps = {
      content: {
        ...modalStyle.content,
        overflowX: 'visible',
        overflowY: 'visible',
      },
    };
  }

  if (!children) {
    return null;
  }

  return (
    <ReactModal
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={{
        ...modalStyle,
        ...overflowStyleProps,
        ...modalStyleProps,
      }}
    >
      <div
        className={
          modalHeight && modalWidth
            ? `modal__content modal__content--height-${modalHeight} modal__content--width-${modalWidth} ${modalClass} ${customClasses}`
            : `modal__content modal__content--size-${modalSize} ${modalClass} ${customClasses}`
        }
        data-test={dataTest}
      >
        <div className={`modal__header ${modalHeaderClass}`}>
          <header>
            <div className='modal__title modal__title--left mb-10'>{title}</div>
          </header>
          <button
            onClick={closeModal}
            type='button'
            className={`modal__close ${modalCloseBtnClass}`}
          >
            <Icon materialIcon={'close'} />
          </button>
        </div>

        <div className={`custom-scrollbar modal__children ${enableBodyOverflow ? 'modal__body--overflow' : ''}`}>
          <div className={`modal__body ${isPadded ? 'modal__padded-wrapper' : ''}`}>
            {children}
          </div>
        </div>

        {buttons}
      </div>
    </ReactModal>
  );
});

export default Modal;
