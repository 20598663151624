/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */
import type { DecorationMethodEnum } from './decorationMethodEnum';
import type { PrepressJobOrderIndicatorEnum } from './prepressJobOrderIndicatorEnum';
import type { DecorationSizeEnum } from './decorationSizeEnum';
import type { DecorationTypeEnum } from './decorationTypeEnum';

export interface PrepressJobItemDto {
  id?: number;
  orderId?: number | null;
  orderNumber?: number | null;
  orderItemDecorationId?: number | null;
  orderIsRushed?: boolean;
  quantity?: number;
  barcode?: string | null;
  floodColorId?: number | null;
  artworkFileUrl?: string | null;
  decorationLocation?: string | null;
  personalizationText?: string | null;
  personalizationNumber?: string | null;
  personalizationColorId?: number | null;
  personalizationOutlineColorId?: number | null;
  decorationMethod?: DecorationMethodEnum;
  indicator?: PrepressJobOrderIndicatorEnum;
  decorationSize?: DecorationSizeEnum;
  type?: DecorationTypeEnum;
  decorationHeight?: number | null;
  decorationWidth?: number | null;
}
