import React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import PermissionsEnum from '@constants/enums/permissionsEnum';
import PermissionHandler from '@sharedComponents/Authorization/PermissionHandler';
import ArtVectorizationLink from './ArtVectorizationLink';
import LogoReviewLink from './LogoReviewLink';
import LockerManagerLink from './LockerManagerLink';
import OrderManagementLink from './OrderManagementLink';
import OnHandInventoryLink from './OnHandInventoryLink';
import CouponsLink from './CouponsLink';
import OrganizationsLink from './OrganizationsLink';
import AdminsLink from './AdminsLink';
import ProductCatalogLink from './ProductCatalogLink';
import FundraisingPayoutsLink from './FundraisingPayoutsLink';
import AdvancedOptionsLink from './AdvancedOptionsLink';
import SkuSetupLink from './SkuSetupLink';
import LookupEntitiesLink from './LookupEntitiesLink';
import BackorderManagementLink from './BackorderManagementLink';
import RosterManagerLink from './RosterManagerLink';
import VendorsOrdersLink from './VendorOrdersLink';
import ProductionLink from './ProductionLink';
import WarehouseSchedulingLink from './WarehouseSchedulingLink';
import VoucherOrdersLink from './VoucherOrdersLink';
import BulkActionsLink from './BulkActionsLink';
import PrintRoomNewLink from './PrintRoomNewLink';
import DesignConfigurationLink from './DesignConfigurationLink';
import BatchWarehouseSchedulingLink from './BatchWarehouseSchedulingLink';
import MerchandisingLink from './MerchandisingLink';
import ReportsLink from './ReportsLink';
import OrderItemFilesLink from './OrderItemFilesLink';
import CrossSellsLink from './CrossSellsLink';

const MyApps = React.memo(() => {
  const {
    vouchersModule,
  } = useFlags();

  return (
    <div
      className='my-apps-tray'
      data-test='apps-tray'
    >
      <PermissionHandler permissions={PermissionsEnum.AdminsVisit}>
        <AdminsLink />
      </PermissionHandler>

      <PermissionHandler permissions={PermissionsEnum.AdvancedOptionsVisit}>
        <AdvancedOptionsLink />
      </PermissionHandler>

      <PermissionHandler permissions={PermissionsEnum.BackorderManagementVisit}>
        <BackorderManagementLink />
      </PermissionHandler>

      <PermissionHandler permissions={PermissionsEnum.BulkActionsVisit}>
        <BulkActionsLink />
      </PermissionHandler>

      <PermissionHandler permissions={PermissionsEnum.CouponsVisit}>
        <CouponsLink />
      </PermissionHandler>

      <PermissionHandler permissions={PermissionsEnum.ProductCatalogVisit}>
        <CrossSellsLink />
      </PermissionHandler>

      <PermissionHandler permissions={PermissionsEnum.AdminsVisit}>
        <DesignConfigurationLink />
      </PermissionHandler>

      <PermissionHandler permissions={PermissionsEnum.FundraisingPayoutsVisit}>
        <FundraisingPayoutsLink />
      </PermissionHandler>

      <PermissionHandler permissions={PermissionsEnum.LockerManagerVisit}>
        <LockerManagerLink />
      </PermissionHandler>

      <PermissionHandler permissions={PermissionsEnum.LogoReviewVisit}>
        <LogoReviewLink />
      </PermissionHandler>

      <PermissionHandler permissions={PermissionsEnum.LookupEntitiesVisit}>
        <LookupEntitiesLink />
      </PermissionHandler>

      <PermissionHandler permissions={PermissionsEnum.MerchandisingVisit}>
        <MerchandisingLink />
      </PermissionHandler>

      <PermissionHandler permissions={PermissionsEnum.OnHandInventoryVisit}>
        <OnHandInventoryLink />
      </PermissionHandler>

      <PermissionHandler permissions={PermissionsEnum.VendorOrdersVisit}>
        <OrderItemFilesLink />
      </PermissionHandler>

      <PermissionHandler permissions={PermissionsEnum.OrderManagementVisit}>
        <OrderManagementLink />
      </PermissionHandler>

      <PermissionHandler permissions={PermissionsEnum.OrganizationsVisit}>
        <OrganizationsLink />
      </PermissionHandler>

      <PermissionHandler permissions={PermissionsEnum.PrintRoomVisit}>
        <PrintRoomNewLink />
      </PermissionHandler>

      <PermissionHandler permissions={PermissionsEnum.ProductCatalogVisit}>
        <ProductCatalogLink />
      </PermissionHandler>

      <PermissionHandler permissions={PermissionsEnum.ProductionArtworkVisit}>
        <ArtVectorizationLink />
      </PermissionHandler>

      <PermissionHandler permissions={PermissionsEnum.ProductionVisit}>
        <ProductionLink />
      </PermissionHandler>

      <PermissionHandler permissions={PermissionsEnum.ReportsVisit}>
        <ReportsLink />
      </PermissionHandler>

      <PermissionHandler permissions={PermissionsEnum.RosterManagerVisit}>
        <RosterManagerLink />
      </PermissionHandler>

      {/* {
        false && // TURN OFF FOR NOW, 2023-08-02
        featureFlags.lineLevelSchedulingEnabled &&
        <PermissionHandler permissions={PermissionsEnum.SchedulingVisit}>
          <LLSchedulingLink />
        </PermissionHandler>
      } */}
      <PermissionHandler permissions={PermissionsEnum.SchedulingVisit}>
        <WarehouseSchedulingLink />
      </PermissionHandler>

      <PermissionHandler permissions={PermissionsEnum.SchedulingVisit}>
        <BatchWarehouseSchedulingLink />
      </PermissionHandler>

      <PermissionHandler permissions={PermissionsEnum.SkuSetupVisit}>
        <SkuSetupLink />
      </PermissionHandler>

      <PermissionHandler permissions={PermissionsEnum.VendorOrdersVisit}>
        <VendorsOrdersLink />
      </PermissionHandler>

      {
        vouchersModule &&
        <PermissionHandler permissions={PermissionsEnum.VoucherOrdersVisit}>
          <VoucherOrdersLink />
        </PermissionHandler>
      }
    </div>
  );
});

export default MyApps;
