import React, {
  useCallback,
  useEffect, useMemo, useState,
} from 'react';
import { useGetColoredStylesWhereCodeContains } from '@api/squadlockerServices/locker-manager';
import { bulkAddItemForm } from '@constants/reduxForms';
import { BulkAddItemFormData } from '@models/forms/BulkActions/BulkAddItemsFormData';
import AutocompleteInput from '@components/shared/Inputs/AutocompleteInput';
import {
  ApiInternalServerError, ColoredStylesSearchViewModel,
} from '@api/squadlockerServices/models';
import Button from '@components/shared/Buttons/Button';
import { minInputLengthForSkuSuggestions } from '@constants/values';
import yup from '@util/yupValidationHelper';
import {
  FormState,
  useForm,
  UseFormReturn,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  QueryObserverResult, RefetchOptions,
} from 'react-query';

const schema = yup.object({
  newSku: yup.string()
    .min(minInputLengthForSkuSuggestions, 'New item SKU is too short')
    .required('New item SKU is required'),
  oldSku: yup.string()
    .min(minInputLengthForSkuSuggestions, 'Old item SKU is too short')
    .not([yup.ref('newSku')], 'SKUs can not be the same')
    .required('Old item SKU is required'),
  newItemId: yup.number().required(),
  oldItemId: yup.number().required(),
});

interface OwnProps {
  onSubmit: (data: BulkAddItemFormData) => Promise<void>;
  effectedLockersCount: number | undefined;
  fetchingEffectedLockersCount: boolean;
  setOldSelectedColoredStyle: (cs: Nullable<ColoredStylesSearchViewModel>) => void;
  setNewSelectedColoredStyle: (cs: Nullable<ColoredStylesSearchViewModel>) => void;
  fetchEffectedLockersCount: (options?: RefetchOptions | undefined)
    => Promise<QueryObserverResult<number, ApiInternalServerError>>;
  newSelectedColoredStyle: Nullable<ColoredStylesSearchViewModel>;
  oldSelectedColoredStyle: Nullable<ColoredStylesSearchViewModel>;
}

type Props = OwnProps;

const displayColoredStyle = (cs: ColoredStylesSearchViewModel) => cs ? `${cs.code}` : '';
const displayColoredStyleSuggestion = (cs: ColoredStylesSearchViewModel) => `${cs.code} ${cs.name} ID: ${cs.id}`;

const BulkAddItemForm = React.memo<Props>(({
  onSubmit,
  effectedLockersCount,
  fetchingEffectedLockersCount,
  setOldSelectedColoredStyle,
  setNewSelectedColoredStyle,
  fetchEffectedLockersCount,
  newSelectedColoredStyle,
  oldSelectedColoredStyle,
}) => {
  const formMethods: UseFormReturn<BulkAddItemFormData> = useForm<BulkAddItemFormData>({
    resolver: yupResolver(schema),
  });

  const {
    handleSubmit,
    formState: { errors },
    setValue,
  } = formMethods;

  const [
    newColoredStyleSearchInput,
    setNewColoredStyleSearchInput,
  ] = useState<string>('');
  const [
    oldColoredStyleSearchInput,
    setOldColoredStyleSearchInput,
  ] = useState<string>('');
  const [
    fetchNewSuggestionsCallback,
    setFetchNewSuggestionsCallback,
  ] = useState<boolean>(false);
  const [
    fetchOldSuggestionsCallback,
    setFetchOldSuggestionsCallback,
  ] = useState<boolean>(false);

  const [
    effectedLockersCountRequestSent,
    setEffectedLockersCountRequestSent,
  ] = useState<boolean>(false);

  const {
    data: oldColoredStyleSuggestions,
    refetch: fetchOldColorStyleSuggestions,
  } = useGetColoredStylesWhereCodeContains({ coloredStyleCodeContains: oldColoredStyleSearchInput });

  const {
    data: newColoredStyleSuggestions,
    refetch: fetchNewColorStyleSuggestions,
  } = useGetColoredStylesWhereCodeContains({ coloredStyleCodeContains: newColoredStyleSearchInput });

  const handleNewSkuChange = useCallback((input: string) => {
    setNewColoredStyleSearchInput(input);
  }, []);

  const handleOldSkuChange = useCallback((input: string) => {
    setOldColoredStyleSearchInput(input);
  }, []);

  const handleNewColoredStyleChange = useCallback((selectedCs: ColoredStylesSearchViewModel) => {
    setNewSelectedColoredStyle(selectedCs);
    setValue('newSku', selectedCs.code);
    setValue('newItemId', selectedCs.id);
  }, [setNewSelectedColoredStyle, setValue]);

  const handleOldColoredStyleChange = useCallback((selectedCs: ColoredStylesSearchViewModel) => {
    setOldSelectedColoredStyle(selectedCs);
    setValue('oldSku', selectedCs.code);
    setValue('oldItemId', selectedCs.id);
  }, [setOldSelectedColoredStyle, setValue]);

  const fetchNewSuggestions = useCallback((input: string) => {
    handleNewSkuChange(input);
    if (input?.length < minInputLengthForSkuSuggestions) return null;

    setFetchNewSuggestionsCallback(true);
  }, [handleNewSkuChange]);

  const fetchOldSuggestions = useCallback((input: string) => {
    handleOldSkuChange(input);
    if (input?.length < minInputLengthForSkuSuggestions) return null;

    setFetchOldSuggestionsCallback(true);
  }, [handleOldSkuChange]);

  const handleButtonClick = useCallback(() => {
    fetchEffectedLockersCount();
    setEffectedLockersCountRequestSent(true);
  }, [fetchEffectedLockersCount]);

  const addBulkItem = useCallback(async () => {
    handleSubmit(onSubmit)();
  }, [
    handleSubmit,
    onSubmit,
  ]);

  useEffect(() => {
    if (fetchNewSuggestionsCallback) {
      fetchNewColorStyleSuggestions();
      setFetchNewSuggestionsCallback(false);
    }
  }, [fetchNewColorStyleSuggestions, fetchNewSuggestionsCallback]);

  useEffect(() => {
    if (fetchOldSuggestionsCallback) {
      fetchOldColorStyleSuggestions();
      setFetchOldSuggestionsCallback(false);
    }
  }, [fetchOldColorStyleSuggestions, fetchOldSuggestionsCallback]);

  useEffect(() => {
    if (effectedLockersCount !== undefined
      && effectedLockersCountRequestSent
      && !fetchingEffectedLockersCount) {
      setEffectedLockersCountRequestSent(false);
      addBulkItem();
    }
  }, [addBulkItem, effectedLockersCount, effectedLockersCountRequestSent, fetchingEffectedLockersCount]);

  const newSuggestions = useMemo(
    () => newColoredStyleSuggestions?.slice(0, 10) ?? [],
    [newColoredStyleSuggestions]
  );

  const oldSuggestions = useMemo(
    () => oldColoredStyleSuggestions?.slice(0, 10) ?? [],
    [oldColoredStyleSuggestions]
  );

  return (
    <div className='container master-detail'>
      <form
        className='redux-form'
        id={bulkAddItemForm}
      >
        <div className='redux-form__column'>
          <div className='redux-form__row'>
            <div className='redux-form__section__medium'>
              <label className='redux-form__label'>
              Add colored style
                <span className='required'>*</span>
              </label>
              <AutocompleteInput
                suggestions={newSuggestions}
                fetchSuggestions={fetchNewSuggestions}
                displayItem={displayColoredStyle}
                displaySuggestionText={displayColoredStyleSuggestion}
                selectedItem={newSelectedColoredStyle}
                selectItem={handleNewColoredStyleChange}
                placeholder={'Colored Style SKU'}
                hasSearchIcon={true}
              />
              <div className='redux-form__error'>
                {(errors as FormState<BulkAddItemFormData>['errors']).newSku?.message}
              </div>
              <div>
                {newSelectedColoredStyle?.name}
              </div>
            </div>
            <div className='redux-form__section__medium'>
              <label className='redux-form__label'>
                To all stores with this colored style
                <span className='required'>*</span>
              </label>
              <AutocompleteInput
                suggestions={oldSuggestions}
                fetchSuggestions={fetchOldSuggestions}
                displayItem={displayColoredStyle}
                displaySuggestionText={displayColoredStyleSuggestion}
                selectedItem={oldSelectedColoredStyle}
                selectItem={handleOldColoredStyleChange}
                placeholder={'Colored Style SKU'}
                hasSearchIcon={true}
              />
              <div className='redux-form__error'>
                {(errors as FormState<BulkAddItemFormData>['errors']).oldSku?.message}
              </div>
              <div>
                {oldSelectedColoredStyle?.name}
              </div>
            </div>
          </div>
          <Button
            text='Add Replacement Item'
            disabled={!newSelectedColoredStyle || !oldSelectedColoredStyle}
            onClick={handleButtonClick}
            type={'primary'}
          />
        </div>
      </form>
    </div>
  );
});

export default BulkAddItemForm;
