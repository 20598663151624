/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Catalog Homefield API
 * OpenAPI spec version: v1
 */
import type { DecorationMethodEnum } from './decorationMethodEnum';
import type { DecorationSizeEnum } from './decorationSizeEnum';

export interface DecorationLocationPersonalizationSizeDto {
  id?: number;
  uuid?: string | null;
  created?: string | null;
  updated?: string | null;
  deleted?: string | null;
  decorationLocationId?: number;
  decorationMethod?: DecorationMethodEnum;
  decorationSize?: DecorationSizeEnum;
  nameHeight?: number | null;
  nameWidth?: number | null;
  numberHeight?: number | null;
}
