/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Accounts Homefield API
 * OpenAPI spec version: 2.0
 */

export interface Int32ApiResponse {
  success?: boolean;
  message?: string | null;
  result?: number;
}
