import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import OutsideClickWrapper from '@sharedComponents/OutsideClickWrapper';
import Icon from '@sharedComponents/Icons/Icon';
import Chip from '@sharedComponents/Inputs/Chips/Chip';
import EditDecorationLocationsItem from './EditDecorationLocationsItem';

class EditDecorationLocationsInput extends PureComponent {
  state = {
    isInputActive: false,
    showDecorationsList: false,
    searchInput: '',
    filteredDecorations: this.props.allDecorationLocations,
  };

  componentDidUpdate(prevProps, prevState) {
    const { allDecorationLocations } = this.props;
    const { searchInput } = this.state;

    if (searchInput !== prevState.searchInput) {
      if (searchInput.length > 0) {
        this.setState(() => ({
          filteredDecorations: allDecorationLocations.filter(
            (l) => l.code.toLowerCase().includes(searchInput.toLowerCase())
          ),
        }));
      } else {
        this.setState(() => ({ filteredDecorations: allDecorationLocations }));
      }
    }
  }

  onInputFocus = () => {
    this.setState(() => ({
      isInputActive: true,
      showDecorationsList: true,
    }));
  };

  onInputBlur = () => {
    this.setState(() => ({
      isInputActive: false,
      showDecorationsList: false,
    }));
  };

  addDecorationToList = (decorationLocation) => {
    const {
      fields,
      decorationType,
    } = this.props;

    if (fields.getAll().find((l) => l === decorationLocation)) return;

    this.searchInput.value = '';

    const location = {
      type: decorationType,
      locationId: decorationLocation.id,
      location: decorationLocation.code,
    };

    fields.push(location);

    this.setState(() => ({
      isInputActive: false,
      showDecorationsList: false,
      searchInput: '',
    }));
  };

  removeDecorationFromList = (index) => {
    const { fields } = this.props;

    fields.remove(index);
  };

  onInputChange = () => {
    const newSearchInput = this.searchInput.value ? this.searchInput.value.trim() : '';
    this.setState(() => ({
      searchInput: newSearchInput,
      showDecorationsList: true,
    }));
  };

  renderDecorationItem = (decoration, index) => (
    <Chip
      key={index}
      text={decoration.location}
      onClick={this.removeDecorationFromList.bind(null, index)}
      hasIcon={true}
    />
  );

  setSearchInputRef = (r) => {
    this.searchInput = r;
  };

  render() {
    const {
      decorationTypeText,
      fields,
      disabled,
    } = this.props;

    const {
      isInputActive,
      showDecorationsList,
      filteredDecorations,
    } = this.state;

    const initializedFields = fields.getAll() || [];
    const disabledLocationIds = (disabled || []).map((d) => d.locationId);

    return (
      <div className='w-100'>
        <label className='redux-form__label'>
          {`${decorationTypeText} Locations`}
        </label>
        <div className={`chips-search w-70 has-icon ${isInputActive ? 'active' : ''}`}>
          <div>
            <Icon materialIcon={'search'} />
          </div>
          <div className='chips'>
            {initializedFields.map((decoration, index) => this.renderDecorationItem(decoration, index))}
            <div className='text-field mb-0'>
              <OutsideClickWrapper onClick={this.onInputBlur}>
                <>
                  <input
                    type='text'
                    placeholder={initializedFields.length === 0 ? `Enter ${decorationTypeText} Location` : undefined}
                    onChange={this.onInputChange}
                    onFocus={this.onInputFocus}
                    ref={this.setSearchInputRef}
                  />
                  {
                    showDecorationsList &&
                    <div className='suggestions suggestions__dropdown suggestions--size-l custom-scrollbar'>
                      {
                        filteredDecorations.map((decoration) => (
                          <EditDecorationLocationsItem
                            key={decoration.id}
                            decorationLocation={decoration}
                            decorationLocations={initializedFields}
                            isDisabled={disabledLocationIds.includes(decoration.id)}
                            addDecoration={this.addDecorationToList}
                          />
                        ))
                      }
                    </div>
                  }
                </>
              </OutsideClickWrapper>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

EditDecorationLocationsInput.propTypes = {
  allDecorationLocations: PropTypes.arrayOf(PropTypes.object).isRequired,
  decorationTypeText: PropTypes.string.isRequired,
  decorationType: PropTypes.string.isRequired,
  fields: PropTypes.object.isRequired,
  disabled: PropTypes.array,
};

export default EditDecorationLocationsInput;
