/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Catalog Homefield API
 * OpenAPI spec version: v1
 */
import type { DecorationLocationArtworkSizeDto } from './decorationLocationArtworkSizeDto';

export interface DecorationLocationArtworkSizeDtoApiResponse {
  success?: boolean;
  message?: string | null;
  result?: DecorationLocationArtworkSizeDto;
}
