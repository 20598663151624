/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */

export interface SelectedColorModel {
  id?: number;
  name?: string | null;
  selection_type?: number;
}
