import * as actionTypes from './types';

const initialState = {
  artworkDetails: [],
  artworkSummary: {
    unassigned: 0,
    assigned: 0,
    inQC: 0,
    rework: 0,
    completed: 0,
  },
  assignees: [],
  selectedAssignee: null,
  tableState: {
    pageNumber: null,
    totalPages: null,
    totalCount: 0,
    hasNextPage: null,
    hasPreviousPage: null,
    pageSize: null,
    sortColumn: null,
    sortDirection: null,
    decorationMethod: null,
    resourceId: null, // Name of a resource to filter by
    filterByHasParentLocker: null,
    filterByPartnerName: null,
  },
};

export default function artwork(state = initialState, action) {
  switch (action.type) {
    case actionTypes.UPDATE_ARTWORK_DETAILS:
      return {
        ...state,
        artworkDetails: action.data,
      };
    case actionTypes.UPDATE_ARTWORK_SUMMARY:
      return {
        ...state,
        artworkSummary: action.data,
      };
    case actionTypes.UPDATE_ASSIGNEES:
      const mappedAssignees = action.assignees?.map((x) => {
        x.displayName = `${x.firstName || ''} ${x.lastName || ''}`.trim();

        return x;
      });

      return {
        ...state,
        assignees: mappedAssignees,
      };
    case actionTypes.UPDATE_SELECTED_ASSIGNEE:
      return {
        ...state,
        selectedAssignee: action.data,
      };
    case actionTypes.UPDATE_ARTWORK_TABLE_STATE:
      return {
        ...state,
        tableState: {
          ...state.tableState,
          ...action.data,
        },
      };
    default:
      return state;
  }
}
