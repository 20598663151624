/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */

export type HagTransmissionTypeEnum = 'Standard' | 'Priority';


export const HagTransmissionTypeEnum = {
  Standard: 'Standard' as HagTransmissionTypeEnum,
  Priority: 'Priority' as HagTransmissionTypeEnum,
};
