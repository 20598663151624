import React, {
  useState,
  useCallback,
} from 'react';
import { reset } from 'redux-form';
import {
  connect,
  ConnectedProps,
} from 'react-redux';
import { QueryObserverResult } from 'react-query';
import {
  useGetHomefieldApiProductionassemblynewOrdersOrderNumberOrderitemsStatuses,
  useGetHomefieldApiProductionassemblynewOrdersOrderNumberOrderitemdecorationsStatuses,
  useGetHomefieldApiProductionassemblynewOrdersOrderNumberOrderitemdecorationsFlags,
  usePutHomefieldApiProductionassemblynewOrdersOrderNumberOrderitemsmarkcheckedin,
  usePutHomefieldApiProductionassemblynewOrdersOrderNumberOrderitemsBarcodes,
  usePutHomefieldApiProductionassemblynewOrdersOrderNumberOrderitemsmarkonhold,
} from '@api/fulfillment/production-assembly-new';
import {
  ColorDto,
  DecorationLocationArtworkSizeDto,
  DecorationLocationDto,
  DecorationLocationPersonalizationSizeDto,
} from '@api/productCatalog/models';
import {
  FlagDto,
  ProductionAssemblyItemGroupDto,
  ProductionAssemblyOrderHeaderDto,
  OrderItemStatusEnumItemStatusModel,
  DecorationStatusEnumItemStatusModel,
} from '@api/fulfillment/models';
import {
  orderItemStatusEnum,
  lineItemStatusEnum,
  flagStatusEnum,
} from '@constants/enums/orderEnums';
import { scanOrderItemsBarcodesForm } from '@constants/reduxForms';
import { ScanBarcodesFormData } from '@models/forms/ScanBarcodesFormData';
import { StatusHistory } from '@models/Production/ProductionAssembly/StatusHistory';
import AddBarcodesModal from '@sharedComponents/Production/Modals/AddBarcodesModal';
import EditBarcodesModal from '@sharedComponents/Production/Modals/EditBarcodesModal';
import ReviewItem from './ReviewItem';
import BackorderWarningModal from './BackorderWarningModal';
import ImageDetailsModal from '@components/Production/Shared/ImageDetailsModal';

interface OwnProps {
  orderNumber: number | undefined;
  items: ProductionAssemblyItemGroupDto[] | undefined;
  isAssemblyDisabled?: boolean;
  barcodeSearch: Nullable<string>;
  logoDecorationLocations?: DecorationLocationDto[];
  personalizationDecorationLocations?: DecorationLocationDto[];
  decorationLocationArtworkSizesList?: DecorationLocationArtworkSizeDto[];
  decorationLocationPersonalizationSizesList?: DecorationLocationPersonalizationSizeDto[];
  isStatusHistoryShown: boolean;
  refreshOrderItemGroups: () => Promise<QueryObserverResult<ProductionAssemblyItemGroupDto[], unknown>>;
  refreshOrder: () => Promise<QueryObserverResult<ProductionAssemblyOrderHeaderDto, unknown>>;
  barcodeSearchItemRef: React.RefObject<HTMLDivElement>;
  scrollToItem: () => void;
  colorsDictionary: Record<string, ColorDto>;
  colors: ColorDto[] | undefined;
}

const mapDispatchToProps = {
  resetForm: reset,
};

const connector = connect(null, mapDispatchToProps);

type Props = OwnProps & ConnectedProps<typeof connector>;

const ReviewItems = React.memo<Props>(({
  orderNumber,
  items,
  isAssemblyDisabled,
  barcodeSearch,
  logoDecorationLocations,
  personalizationDecorationLocations,
  decorationLocationArtworkSizesList,
  decorationLocationPersonalizationSizesList,
  isStatusHistoryShown,
  refreshOrderItemGroups,
  refreshOrder,
  barcodeSearchItemRef,
  scrollToItem,
  resetForm,
  colorsDictionary,
  colors,
}) => {
  const [
    selectedItem,
    setSelectedItem,
  ] = useState<Nullable<ProductionAssemblyItemGroupDto>>(null);
  const [
    itemInfoModalIsOpen,
    setItemInfoModalIsOpen,
  ] = useState(false);
  const [
    addBarcodesModalIsOpened,
    setAddBarcodesModalIsOpened,
  ] = useState(false);
  const [
    editBarcodesModalIsOpened,
    setEditBarcodesModalIsOpened,
  ] = useState(false);
  const [
    checkInButtonClicked,
    setCheckInButtonClicked,
  ] = useState(false);

  const { mutateAsync: updateOrderItemBarcode }
    = usePutHomefieldApiProductionassemblynewOrdersOrderNumberOrderitemsBarcodes();

  const { data: orderItemsStatuses } = useGetHomefieldApiProductionassemblynewOrdersOrderNumberOrderitemsStatuses(
    Number(orderNumber),
    { query: { enabled: isStatusHistoryShown && !!(orderNumber) } }
  );

  const { data: orderItemDecorationsStatuses }
    = useGetHomefieldApiProductionassemblynewOrdersOrderNumberOrderitemdecorationsStatuses(
      Number(orderNumber),
      { query: { enabled: isStatusHistoryShown && !!(orderNumber) } }
    );

  const { data: orderItemDecorationsFlags }
    = useGetHomefieldApiProductionassemblynewOrdersOrderNumberOrderitemdecorationsFlags(
      Number(orderNumber),
      { query: { enabled: isStatusHistoryShown && !!(orderNumber) } }
    );

  const { mutateAsync: markOrderItemCheckedIn }
    = usePutHomefieldApiProductionassemblynewOrdersOrderNumberOrderitemsmarkcheckedin();

  const { mutateAsync: markOrderOnHold }
    = usePutHomefieldApiProductionassemblynewOrdersOrderNumberOrderitemsmarkonhold();

  const getItemHistory = useCallback((itemIds: number[], lineItemsIds: number[]): StatusHistory => {
    let itemHistory: OrderItemStatusEnumItemStatusModel[] = [];
    let itemDecorationHistory: DecorationStatusEnumItemStatusModel[] = [];
    let itemDecorationlags: FlagDto[] = [];

    if (orderItemsStatuses) {
      itemHistory = orderItemsStatuses.filter((ois) => itemIds.includes(ois.id!) &&
        ois.status !== orderItemStatusEnum.Initial);
    }

    if (orderItemDecorationsStatuses) {
      itemDecorationHistory = orderItemDecorationsStatuses.filter((olis) => lineItemsIds.includes(olis.id!) &&
        olis.status !== orderItemStatusEnum.Initial);
    }

    if (orderItemDecorationsFlags) {
      itemDecorationlags = orderItemDecorationsFlags
        .filter((olif) => lineItemsIds.includes(olif.orderItemDecorationId!))
        .map((f: any) => ({
          user: f.user,
          label: f.isUnflagged ? lineItemStatusEnum.Unflagged : lineItemStatusEnum.Flagged,
          status: f.isUnflagged ? flagStatusEnum.Unflagged : flagStatusEnum.Flagged,
          created: f.dateCreated,
        }));
    }

    const statusHistory: StatusHistory = [
      ...itemHistory,
      ...itemDecorationHistory,
      ...itemDecorationlags,
    ];

    return statusHistory;
  }, [
    orderItemsStatuses,
    orderItemDecorationsStatuses,
    orderItemDecorationsFlags,
  ]);

  const openItemInfoModal = useCallback((
    item: ProductionAssemblyItemGroupDto,
    imageUrl?: string
  ) => {
    setItemInfoModalIsOpen(true);
    setSelectedItem({
      ...item,
      imageUrlFront: imageUrl ?? item.imageUrlFront,
    });
  }, []);

  const closeItemInfoModal = useCallback(() => {
    setItemInfoModalIsOpen(false);
    setSelectedItem(null);
  }, []);

  const openAddBarcodesModal = useCallback((
    item: ProductionAssemblyItemGroupDto,
    checkInButtonIsClicked: boolean = false
  ) => {
    setAddBarcodesModalIsOpened(true);
    setSelectedItem(item);

    if (checkInButtonIsClicked) {
      setCheckInButtonClicked(true);
    }
  }, []);

  const closeAddBarcodesModal = useCallback(() => {
    resetForm(scanOrderItemsBarcodesForm);

    setAddBarcodesModalIsOpened(false);
    setSelectedItem(null);
    setCheckInButtonClicked(false);
  }, [resetForm]);

  const openEditBarcodesModal = useCallback((
    item: ProductionAssemblyItemGroupDto
  ) => {
    setEditBarcodesModalIsOpened(true);
    setSelectedItem(item);
    setCheckInButtonClicked(false);
  }, []);

  const closeEditBarcodesModal = useCallback(() => {
    setEditBarcodesModalIsOpened(false);
    setSelectedItem(null);
  }, []);

  const updateOrderItemsBarcodes = useCallback(async (form: ScanBarcodesFormData) => {
    const promises = [];
    let countSuccess = 0;

    for (const itemBarcodeAssociation of form.itemBarcodes) {
      promises.push(updateOrderItemBarcode(
        {
          orderNumber: orderNumber!,
          data: {
            orderNumber,
            orderItemId: itemBarcodeAssociation.orderItemId,
            barcode: itemBarcodeAssociation.barcode,
          },
        }
      ));
    }

    try {
      const res = await Promise.all(promises);
      for (const r of res) {
        if (r?.success) {
          countSuccess++;
        }
      }

      return countSuccess === promises.length;
    } catch (err) {
      console.error(err);      
      return false;
    }
  }, [
    updateOrderItemBarcode,
    orderNumber,
  ]);

  const updateBarcodes = useCallback(async (form: ScanBarcodesFormData) => {
    const success = await updateOrderItemsBarcodes(form);

    if (success) {
      closeAddBarcodesModal();
      closeEditBarcodesModal();

      if (checkInButtonClicked) {
        const model = {
          orderNumber,
          orderItemIds: selectedItem!.orderItemIds!,
        };

        await markOrderItemCheckedIn({
          orderNumber: orderNumber!,
          data: model,
        });

        setCheckInButtonClicked(false);
      }
    }

    if (success || checkInButtonClicked) {
      refreshOrderItemGroups();
    }
  }, [
    selectedItem,
    orderNumber,
    closeAddBarcodesModal,
    closeEditBarcodesModal,
    refreshOrderItemGroups,
    updateOrderItemsBarcodes,
    checkInButtonClicked,
    markOrderItemCheckedIn,
  ]);

  const getItemBarcodes = (item: ProductionAssemblyItemGroupDto) => {
    const itemBarcodes: {
      orderItemId: number;
      barcode: string | null;
    }[] = [];

    for (const orderItemId of Object.keys(item.orderItemIdsWithBarcode!)) {
      const barcode: string | null = item.orderItemIdsWithBarcode![orderItemId];
      itemBarcodes.push({
        orderItemId: Number(orderItemId),
        barcode,
      });
    }

    return itemBarcodes;
  };

  const hasSelectedBarcode = (
    item: {
      orderItemId: number;
      barcode: string | null;
    }
  ) => item.barcode === barcodeSearch;

  const itemWithBarcodeSelected = (
    item: ProductionAssemblyItemGroupDto
  ) => {
    const itemBarcodes = getItemBarcodes(item);

    return itemBarcodes.some(hasSelectedBarcode);
  };

  const [
    isBackorderWarningOpen,
    setIsWarningBackorderOpen,
  ] = useState(false);
  const openBackorderedModal = useCallback((item: ProductionAssemblyItemGroupDto) => {
    setSelectedItem(item);
    setIsWarningBackorderOpen(true);
  }, []);
  const closeBackorderedModal = useCallback(() => {
    setIsWarningBackorderOpen(false);
  }, []);

  const handleSendToBackorder = useCallback(async (item: ProductionAssemblyItemGroupDto) => {
    setIsWarningBackorderOpen(false);
    await markOrderOnHold({
      orderNumber: orderNumber!,
      data: {
        orderNumber,
        orderItemIds: item.orderItemIds,
      },
    });
    refreshOrderItemGroups();
  }, [
    markOrderOnHold,
    orderNumber,
    refreshOrderItemGroups,
  ]);

  const renderReviewItem = (
    item: ProductionAssemblyItemGroupDto,
    key: number
  ) => (
    <ReviewItem
      key={key}
      item={item}
      itemSelected={itemWithBarcodeSelected(item)}
      barcodeParam={barcodeSearch}
      orderNumber={orderNumber!}
      isAssemblyDisabled={isAssemblyDisabled}
      openItemInfoModal={openItemInfoModal}
      openAddBarcodesModal={openAddBarcodesModal}
      openEditBarcodesModal={openEditBarcodesModal}
      openBackorderedModal={openBackorderedModal}
      isStatusHistoryShown={isStatusHistoryShown}
      getItemHistory={getItemHistory}
      logoDecorationLocations={logoDecorationLocations}
      personalizationDecorationLocations={personalizationDecorationLocations}
      decorationLocationPersonalizationSizesList={decorationLocationPersonalizationSizesList}
      decorationLocationArtworkSizesList={decorationLocationArtworkSizesList}
      colorsDictionary={colorsDictionary}
      colors={colors}
      refreshOrderItemGroups={refreshOrderItemGroups}
      refreshOrder={refreshOrder}
      barcodeSearchItemRef={barcodeSearchItemRef}
      scrollToItem={scrollToItem}
    />
  );

  if (!items || !items.length) {
    return (
      <div className='order-assembly__items--empty mt-20'>No items to show</div>
    );
  }

  const itemBarcodes = selectedItem ? getItemBarcodes(selectedItem) : [];

  return (
    <div className='order-assembly__items'>
      <AddBarcodesModal
        isOpen={addBarcodesModalIsOpened}
        closeModal={closeAddBarcodesModal}
        onSubmit={updateBarcodes}
        itemBarcodes={itemBarcodes}
      />
      <EditBarcodesModal
        isOpen={editBarcodesModalIsOpened}
        closeModal={closeEditBarcodesModal}
        onSubmit={updateBarcodes}
        itemBarcodes={itemBarcodes}
      />
      <ImageDetailsModal
        isOpen={itemInfoModalIsOpen}
        closeModal={closeItemInfoModal}
        selectedItem={selectedItem}
      />
      <BackorderWarningModal
        isOpen={isBackorderWarningOpen}
        closeModal={closeBackorderedModal}
        item={selectedItem}
        onConfirm={handleSendToBackorder}
      />
      {items.map(renderReviewItem)}
    </div>
  );
});

export default connector(ReviewItems);
