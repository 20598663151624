/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */
import type { OrderItemStatusEnumStatusModel } from './orderItemStatusEnumStatusModel';
import type { DecorationMethodEnum } from './decorationMethodEnum';

export interface QueriesOrderItemModel {
  id?: number;
  sku?: string | null;
  styleName?: string | null;
  basePrice?: number | null;
  pricePaid?: number | null;
  brandName?: string | null;
  size?: string | null;
  colorGroup?: string | null;
  status?: OrderItemStatusEnumStatusModel;
  isCanceled?: boolean;
  isBackordered?: boolean;
  requiredItem?: boolean;
  imageUrl?: string | null;
  isInventoryAvailable?: boolean | null;
  promisedDate?: string | null;
  decorationMethod?: DecorationMethodEnum;
}
