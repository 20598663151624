import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  getProductsForColoredStyle,
  getProductInventoryForColoredStyle,
  getColoredStyle,
  getProductVendorQuantitiesForColoredStyle,
} from '@redux/productCatalog/actions';
import iconEye from '@assets/icon_hidden.svg';
import iconInvisible from '@assets/icon_eye_invisible.svg';
import MaterialTooltip from '@sharedComponents/Tooltips/MaterialTooltip';
import Icon from '@sharedComponents/Icons/Icon';
import Table from '@sharedComponents/Table/Table';
import ItemInventoryProductVendorQuantityInfo from '@sharedComponents/ItemInfo/ItemInventoryProductVendorQuantityInfo';

const ItemsTable = Table();

class ItemInventoryStatusInfo extends PureComponent {
  componentDidMount() {
    const {
      hasSku,
      product,
      coloredStyleItem,
      dispatch,
    } = this.props;

    const id = hasSku ? product.coloredStyleId : coloredStyleItem.id;

    dispatch(getProductsForColoredStyle(id));
    dispatch(getProductInventoryForColoredStyle(id));
    dispatch(getColoredStyle(id));
    dispatch(getProductVendorQuantitiesForColoredStyle(id));
  }

  getProductVendorQuantityTooltipInfo = (productVendorQuantity, isPrimary = false) => (
    <ItemInventoryProductVendorQuantityInfo
      productVendorQuantity={productVendorQuantity}
      isPrimary={isPrimary}
    />
  );

  getTrProps = (state, rowInfo) => {
    const {
      hasSku,
      product,
    } = this.props;

    if (!hasSku) {
      return { };
    }

    return {
      className: `${(rowInfo.original.sku === product.sku) && 'is-active'}`,
    };
  };

  getColumns = () => {
    const {
      queueInventoryDictionary,
      coloredStyle,
      productVendorQuantities,
    } = this.props;

    return ([
      {
        Header: 'SKU',
        accessor: 'sku',
        minWidth: 160,
        sortable: true,
        Cell: (cellProps) => cellProps.value && <div><strong>{cellProps.value}</strong></div>,
      },
      {
        Header: 'Size',
        accessor: 'size',
        minWidth: 70,
        Cell: (cellProps) => cellProps.value && <div>{cellProps.value}</div>,
      },
      {
        Header: 'Vendor',
        accessor: 'vendorId',
        minWidth: 100,
        Cell: (cellProps) => cellProps.value && <div>{cellProps.value}</div>,
      },
      {
        Header: 'Vendor Qty',
        accessor: '',
        minWidth: 100,
        Cell: (cellProps) => {
          const productVendorQuantitiesForSku = productVendorQuantities?.[cellProps.original.sku] ?? [];
          const productVendorQuantity = productVendorQuantitiesForSku
            .find((x) => x.vendorId === coloredStyle?.style?.vendorId);
          const productVendorQuantitiesNotPrimary = productVendorQuantitiesForSku
            .filter((x) => x.vendorId !== coloredStyle?.style?.vendorId);

          const quantity = (productVendorQuantity?.quantity) || 0;

          const qtyVendorMinimumColoredStyle = coloredStyle?.qtyVendorMinimum;
          const qtyVendorMinimumStyle = coloredStyle?.style?.qtyVendorMinimum;
          const qtyVendorMinimumProduct = cellProps?.original?.qtyVendorMinimum;

          const qtyVendorMinimum = qtyVendorMinimumProduct
           || qtyVendorMinimumColoredStyle
           || qtyVendorMinimumStyle
           || 0;

          return (
            <MaterialTooltip
              tooltipText={(
                <>
                  <div className='pre-line mb-10'>
                    Minimum Vendor Quantity: {qtyVendorMinimum}
                  </div>
                  {this.getProductVendorQuantityTooltipInfo(productVendorQuantity, true)}
                  {
                    productVendorQuantitiesNotPrimary.map(this.getProductVendorQuantityTooltipInfo)
                  }
                </>
              )}
              placement={'top'}
              contentClasses={`inventory-status__content cursor-pointer ${quantity < qtyVendorMinimum ? 'warning' : ''}`}
            >
              {quantity}
            </MaterialTooltip>

          );
        },
      },
      {
        Header: 'On Hand',
        accessor: '',
        minWidth: 80,
        Cell: (cellProps) => {
          const inventorySku = queueInventoryDictionary[cellProps?.original?.sku];

          return (
            (inventorySku?.qtyOnHand) ? <span>{inventorySku.qtyOnHand}</span> : 0
          );
        },
      },
      {
        Header: 'Allocated',
        accessor: '',
        minWidth: 90,
        Cell: (cellProps) => {
          const inventorySku = queueInventoryDictionary[cellProps?.original?.sku];

          return (
            (inventorySku?.qtyAllocated) ? <span>{inventorySku.qtyAllocated}</span> : 0
          );
        },
      },
      {
        Header: 'Back Ordered',
        accessor: '',
        minWidth: 110,
        Cell: (cellProps) => {
          const inventorySku = queueInventoryDictionary[cellProps?.original?.sku];

          return (
            (inventorySku?.qtyBackOrdered) ? <span>{inventorySku.qtyBackOrdered}</span> : 0
          );
        },
      },
      {
        Header: 'Available On Hand',
        accessor: '',
        minWidth: 140,
        Cell: (cellProps) => {
          const inventorySku = queueInventoryDictionary[cellProps?.original?.sku];

          return (
            (inventorySku?.availableOnHand) ? <span>{inventorySku.availableOnHand}</span> : 0
          );
        },
      },
      {
        Header: 'Free Forward',
        accessor: '',
        minWidth: 110,
        Cell: (cellProps) => {
          const inventorySku = queueInventoryDictionary[cellProps?.original?.sku];
          const freeForward = (inventorySku?.freeForward) || 0;

          return (
            <div className={`inventory-status__content ${freeForward < 1 ? 'warning' : ''}`}>
              <span>
                {freeForward}
              </span>
            </div>
          );
        },
      },
      {
        Header: 'Status',
        accessor: '',
        minWidth: 100,
        Cell: (cellProps) => {
          const disabledColoredStyle = coloredStyle?.disabled;
          const disabledStyle = coloredStyle?.style?.disabled;
          const disabledProduct = cellProps?.original?.disabled;

          const discontinuedColoredStyle = coloredStyle?.discontinued;
          const discontinuedStyle = coloredStyle?.style?.discontinued;
          const discontinuedProduct = cellProps?.original?.discontinued;

          const availableFreeForwardInventory = cellProps?.original?.availableFreeForwardInventory;
          const availableVendorInventory = cellProps?.original?.availableVendorInventory;

          return (
            <div className='inventory-status__row'>
              <div className='inventory-status__column--info s inventory-status__status'>
                {
                  disabledColoredStyle || disabledStyle || disabledProduct
                    ? (
                      <MaterialTooltip
                        tooltipText={(
                          <>
                            {
                              disabledProduct &&
                              <div className='pre-line'>SKU is disabled</div>
                            }
                            {
                              disabledStyle &&
                              <div className='pre-line'>Style is disabled</div>
                            }
                            {
                              disabledColoredStyle &&
                              <div className='pre-line'>Colored style is disabled</div>
                            }
                          </>
                        )}
                        placement={'top'}
                        contentClasses={'align__center'}
                      >
                        <i
                          className='inventory-status__hidden pointer'
                          style={{ backgroundImage: `url("${iconEye}")` }}
                        />
                      </MaterialTooltip>
                    )
                    : (
                      <i
                        className='inventory-status__hidden'
                        style={{ backgroundImage: `url("${iconInvisible}")` }}
                      />
                    )
                }
                {
                  discontinuedColoredStyle || discontinuedStyle || discontinuedProduct
                    ? (
                      <MaterialTooltip
                        tooltipText={(
                          <>
                            {
                              discontinuedProduct &&
                              <div className='pre-line'>SKU is discontinued</div>
                            }
                            {
                              discontinuedStyle &&
                             <div className='pre-line'>Style is discontinued</div>
                            }
                            {
                              discontinuedColoredStyle &&
                              <div className='pre-line'>Colored style is discontinued</div>
                            }
                          </>
                        )}
                        placement={'top'}
                      >
                        <Icon
                          materialIcon={'block'}
                          classes={'active bold'}
                        />
                      </MaterialTooltip>
                    )
                    : (
                      <Icon
                        materialIcon={'block'}
                        classes={'disabled'}
                      />
                    )
                }
                {
                  availableVendorInventory || availableFreeForwardInventory
                    ? (
                      <Icon
                        materialIcon={'inbox'}
                        classes={'disabled'}
                      />
                    )
                    : (
                      <MaterialTooltip
                        tooltipText={'SKU disabled - Low vendor and inventory quantities'}
                        placement={'top'}
                      >
                        <Icon
                          materialIcon={'inbox'}
                          classes={'active'}
                        />
                      </MaterialTooltip>
                    )}
              </div>
            </div>
          );
        },
      },
    ]);
  };

  render() {
    const { queue } = this.props;

    return (
      <div>
        <div className='sheet'>
          <ItemsTable
            data={queue}
            columns={this.getColumns()}
            showPagination={false}
            classNames={'scroll-table-s'}
            getTrProps={this.getTrProps}
          />
        </div>
      </div>
    );
  }
}

ItemInventoryStatusInfo.propTypes = {
  product: PropTypes.object,
  coloredStyleItem: PropTypes.object,
  hasSku: PropTypes.bool.isRequired,
  queue: PropTypes.arrayOf(PropTypes.object).isRequired,
  coloredStyle: PropTypes.object.isRequired,
  queueInventoryDictionary: PropTypes.object.isRequired,
  productVendorQuantities: PropTypes.object.isRequired,
};

const mapStateToProps = ({ productCatalog }) => ({
  queue: productCatalog.coloredStyleProducts,
  coloredStyle: productCatalog.coloredStyleInfo,
  queueInventoryDictionary: productCatalog.productInventoryForColoredStyleDictionary,
  productVendorQuantities: productCatalog.productVendorQuantitiesForColoredStyle.vendorsBySku,
});

export default connect(mapStateToProps)(ItemInventoryStatusInfo);
