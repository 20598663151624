/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */
import type { DecorationStatusEnumStatusModel } from './decorationStatusEnumStatusModel';

export interface PersonalizationItemModel {
  ids?: number[] | null;
  decorationMethod?: string | null;
  decorationLocation?: string | null;
  decorationHeight?: number | null;
  text?: string | null;
  number?: string | null;
  colorId?: number | null;
  outlineColorId?: number | null;
  colorIdIsFloodColor?: boolean | null;
  readonly label?: string | null;
  canceled?: string | null;
  status?: DecorationStatusEnumStatusModel;
  isCustomSize?: boolean;
}
