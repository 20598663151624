import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { emptyImageTextEnum } from '@constants/enums/commonEnums';
import { skuSetupStatusEnum } from '@constants/enums/productCatalogEnums';
import { getColorNames } from '@util/colorHelper';
import {
  validateColoredStyleInQC,
  validateColoredStyleInSetup,
} from '@util/componentHelpers/skuSetupHelper';
import ColorDisplay from '@sharedComponents/Display/ColorDisplay';
import ImagePreviewCell from '@sharedComponents/Table/TableCells/ImagePreviewCell';
import ImagePreviewModal from '@sharedComponents/Modal/ImagePreviewModal';
import TooltipCell from '@sharedComponents/Table/TableCells/TooltipCell';
import Table from '@sharedComponents/Table/Table';
import FileNameLink from '@sharedComponents/Navigation/FileNameLink';
import SkuSetupColoredStyleActions from './SkuSetupColoredStyleActions';
import SkuSetupBulkGridUploadModal from './SkuSetupBulkGridUploadModal';
import SkuSetupStatusChangeModal from './SkuSetupStatusChangeModal';
import SkuSetupBulkStyleChangeModal from './SkuSetupBulkStyleChangeModal';

const ColoredStylesTable = Table();

const bulkActionsEnum = {
  uploadGrid: 'uploadGrid',
  changeStatus: 'changeStatus',
  changeStyle: 'changeStyle',
};

const bulkActions = [
  {
    key: 1,
    value: bulkActionsEnum.uploadGrid,
    name: 'Assign Grid',
  },
  {
    key: 2,
    value: bulkActionsEnum.changeStatus,
    name: 'Change Status',
  },
  {
    key: 3,
    value: bulkActionsEnum.changeStyle,
    name: 'Change Style',
  },
];

class SkuSetupColoredStylesTable extends Component {
  state = {
    imageUrl: '',
    imageModalIsOpen: false,
    areAllSelected: false,
    selectedItems: [],
    gridModalIsOpen: false,
    changeStatusModalIsOpen: false,
    changeStyleModalIsOpen: false,
  };

  componentDidUpdate(prevProps) {
    const { selectedTab } = this.props;

    if (prevProps.selectedTab !== selectedTab) {
      this.updateSelection([], false);
    }
  }

  onSelectBulkAction = (selectedBulkAction) => {
    switch (selectedBulkAction) {
      case bulkActionsEnum.uploadGrid:
        this.openGridFileUploadModal();
        break;
      case bulkActionsEnum.changeStatus:
        this.openChangeStatusModal();
        break;
      case bulkActionsEnum.changeStyle:
        this.openChangeStyleModal();
        break;
    }
  };

  openModalWithImage = (imageUrl) => {
    this.setState({
      imageModalIsOpen: true,
      imageUrl,
    });
  };

  closeImageModal = () => {
    this.setState({
      imageModalIsOpen: false,
      imageUrl: '',
    });
  };

  openGridFileUploadModal = () => {
    this.setState({ gridModalIsOpen: true });
  };

  closeGridFileUploadModal = () => {
    this.setState({ gridModalIsOpen: false });

    this.updateSelection([], false);
  };

  openChangeStatusModal = () => {
    this.setState({ changeStatusModalIsOpen: true });
  };

  closeChangeStatusModal = () => {
    this.setState({ changeStatusModalIsOpen: false });

    this.updateSelection([], false);
  };

  openChangeStyleModal = () => {
    this.setState({ changeStyleModalIsOpen: true });
  };

  closeChangeStyleModal = () => {
    this.setState({ changeStyleModalIsOpen: false });

    this.updateSelection([], false);
  };

  updateSelection = (newSelectedItems, newAreAllSelected) => {
    const {
      updateActiveStyleId,
      activeStyleId,
      style,
    } = this.props;

    if (newSelectedItems.length === 0) {
      updateActiveStyleId(null);
    } else if (activeStyleId !== style.id) {
      updateActiveStyleId(style.id);
    }

    this.setState({
      selectedItems: newSelectedItems,
      areAllSelected: newAreAllSelected,
    });
  };

  isSelectingCellDisabled = () => {
    const {
      activeStyleId,
      style,
    } = this.props;

    return activeStyleId !== null && style.id !== activeStyleId;
  };

  getActiveColoredStyles = () => {
    const {
      nextStatus,
      style,
    } = this.props;
    const { selectedItems } = this.state;

    if (nextStatus === skuSetupStatusEnum.QualityCheck) {
      return selectedItems.filter((item) => !validateColoredStyleInSetup(item, style).length);
    } else if (nextStatus === skuSetupStatusEnum.Completed) {
      return selectedItems.filter((item) => !validateColoredStyleInQC(item, style).length);
    }

    return [];
  };

  getDisabledColoredStyles = () => {
    const {
      nextStatus,
      style,
    } = this.props;
    const { selectedItems } = this.state;

    if (nextStatus === skuSetupStatusEnum.QualityCheck) {
      return selectedItems.filter((item) => validateColoredStyleInSetup(item, style).length);
    } else if (nextStatus === skuSetupStatusEnum.Completed) {
      return selectedItems.filter((item) => validateColoredStyleInQC(item, style).length);
    }

    return [];
  };

  getColumns = () => {
    const {
      style,
      teamColorsByCode,
      changeColoredStyleStatus,
      colorsDictionary,
      openEditColoredStyleModal,
      nextStatus,
    } = this.props;

    return [
      {
        Header: 'Code',
        accessor: 'code',
        width: 150,
        Cell: (cellProps) => cellProps.value ? cellProps.value : '-',
      },
      {
        Header: 'Color Group',
        accessor: 'colorGroup',
        width: 150,
        Cell: (cellProps) => {
          const colorGroupTeamColor = teamColorsByCode[cellProps.value];

          return (
            cellProps.value
              ? (
                <ColorDisplay
                  colorName={cellProps.value}
                  displayName={true}
                  size={'size--small'}
                  hideEmptySwatch={true}
                  hexValue={colorGroupTeamColor?.hexValue}
                />
              )
              : '-'
          );
        },
      },
      {
        Header: 'Color A / B',
        accessor: '',
        width: 150,
        Cell: (cellProps) => {
          const {
            colorA,
            colorB,
          } = cellProps.value;

          if (colorA && colorB) {
            return `${colorA} / ${colorB}`;
          }

          return `${colorA || colorB}`;
        },
      },
      {
        Header: 'Images',
        accessor: '',
        width: 190,
        resizable: false,
        Cell: (cellProps) => (
          <div className='catalog-details__images'>
            <ImagePreviewCell
              imageUrl={cellProps.value.imageUrlFront}
              openModalWithUrl={this.openModalWithImage}
              noImageText={emptyImageTextEnum.Front}
              height={'44px'}
              width={'44px'}
            />
            <ImagePreviewCell
              imageUrl={cellProps.value.imageUrlBack}
              openModalWithUrl={this.openModalWithImage}
              noImageText={emptyImageTextEnum.Back}
              height={'44px'}
              width={'44px'}
            />
            <ImagePreviewCell
              imageUrl={cellProps.value.imageUrlSide}
              openModalWithUrl={this.openModalWithImage}
              noImageText={emptyImageTextEnum.Side}
              height={'44px'}
              width={'44px'}
            />
          </div>
        ),
      },
      {
        Header: 'Grid File',
        accessor: '',
        width: 190,
        Cell: (cellProps) => (
          <div className='flex'>
            <ImagePreviewCell
              imageUrl={cellProps.value.imageUrlGridPreview}
              openModalWithUrl={this.openModalWithImage}
              noImageText={emptyImageTextEnum.Grid}
              height={'44px'}
              width={'44px'}
            />
            {
              cellProps.value.imageUrlGrid &&
              <FileNameLink
                url={cellProps.value.imageUrlGrid}
                iconClasses={'sku-setup__attach-icon'}
              />
            }
          </div>
        ),
      },
      {
        Header: 'Flood Color',
        accessor: 'floodColorId',
        resizable: false,
        width: 160,
        Cell: (cellProps) => {
          const floodColor = colorsDictionary?.[cellProps.value];

          return (
            floodColor
              ? (
                <ColorDisplay
                  colorName={floodColor.code}
                  displayName={true}
                  size={'size--small'}
                  hideEmptySwatch={true}
                  hexValue={floodColor.hexValue}
                />
              )
              : '-'
          );
        },
      },
      {
        Header: 'Pers. Colors',
        accessor: 'personalizationColorIds',
        resizable: false,
        width: 140,
        Cell: (cellProps) => {
          const personalizationColors = getColorNames(colorsDictionary, cellProps.value);

          return (
            personalizationColors.length > 0
              ? (
                <TooltipCell
                  items={personalizationColors}
                  displayName={personalizationColors.length === 1 ? 'Color' : 'Colors'}
                />
              )
              : '-'
          );
        },
      },
      {
        Header: '',
        accessor: '',
        resizable: false,
        className: 'sku-setup__action-cell',
        Cell: (cellProps) => (
          <SkuSetupColoredStyleActions
            coloredStyle={cellProps.value}
            style={style}
            changeColoredStyleStatus={changeColoredStyleStatus}
            openEditColoredStyleModal={openEditColoredStyleModal}
            nextStatus={nextStatus}
          />
        ),
      },
    ];
  };

  render() {
    const {
      coloredStyles,
      style,
      refresh,
      changeColoredStyleStatus,
      nextStatus,
    } = this.props;

    const {
      imageModalIsOpen,
      imageUrl,
      selectedItems,
      gridModalIsOpen,
      changeStatusModalIsOpen,
      changeStyleModalIsOpen,
    } = this.state;

    return (
      <div>
        <ImagePreviewModal
          modalIsOpen={imageModalIsOpen}
          closeModal={this.closeImageModal}
          imageUrl={imageUrl}
        />

        <SkuSetupStatusChangeModal
          isOpen={changeStatusModalIsOpen}
          closeModal={this.closeChangeStatusModal}
          coloredStyles={this.getActiveColoredStyles()}
          disabledColoredStyles={this.getDisabledColoredStyles()}
          styleName={style.name}
          changeStatus={changeColoredStyleStatus}
          status={nextStatus}
        />

        <SkuSetupBulkGridUploadModal
          isOpen={gridModalIsOpen}
          closeModal={this.closeGridFileUploadModal}
          coloredStyles={selectedItems}
          styleName={style.name}
          refresh={refresh}
        />

        <SkuSetupBulkStyleChangeModal
          isOpen={changeStyleModalIsOpen}
          closeModal={this.closeChangeStyleModal}
          coloredStyles={selectedItems}
          style={style}
          refresh={refresh}
        />

        <div className='sheet'>
          <ColoredStylesTable
            columns={this.getColumns()}
            data={coloredStyles}
            showPagination={false}
            defaultPageSize={-1}
            classNames={'sku-setup__colored-styles'}
            stickyHeader={false}

            selectable={true}
            selectedData={selectedItems}
            updateSelection={this.updateSelection}
            isSelectingItemDisabled={this.isSelectingCellDisabled}
            isSelectingPageDisabled={this.isSelectingCellDisabled}
            bulkActionsList={bulkActions}
            onSelectBulkAction={this.onSelectBulkAction}
          />
        </div>
      </div>
    );
  }
}

SkuSetupColoredStylesTable.propTypes = {
  style: PropTypes.object.isRequired,
  coloredStyles: PropTypes.arrayOf(PropTypes.object),
  teamColorsByCode: PropTypes.objectOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    hexValue: PropTypes.string.isRequired,
    brightness: PropTypes.string,
  })).isRequired,
  selectedTab: PropTypes.string.isRequired,
  refresh: PropTypes.func.isRequired,
  changeColoredStyleStatus: PropTypes.func.isRequired,
  colorsDictionary: PropTypes.objectOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    dtgCode: PropTypes.string,
    cmykValue: PropTypes.string.isRequired,
    dtgCmykValue: PropTypes.string.isRequired,
    hexValue: PropTypes.string,
    threadValue: PropTypes.string,
    brightness: PropTypes.string.isRequired,
  })).isRequired,
  updateActiveStyleId: PropTypes.func.isRequired,
  activeStyleId: PropTypes.number,
  openEditColoredStyleModal: PropTypes.func.isRequired,
  nextStatus: PropTypes.string.isRequired,
};

const mapStateToProps = ({
  productCatalog,
  support,
}) => ({
  colorsDictionary: productCatalog.colorsDictionary,
  teamColorsByCode: support.teamColorsByCode,
});

export default connect(mapStateToProps)(SkuSetupColoredStylesTable);
