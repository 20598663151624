import React, {
  useCallback,
  useEffect,
} from 'react';
import {
  connect,
  ConnectedProps,
} from 'react-redux';
import MaterialTooltip from '@sharedComponents/Tooltips/MaterialTooltip';
import { fetchSchedulingQueueInProgressTooltip } from '@redux/llScheduling/actions';
import IconEye from '@assets/icon_eye.svg';
import { CircularProgress } from '@material-ui/core';
import { getProductBySku } from '@redux/productionAssembly/actions';
import {
  StatusComp,
  Status,
} from './StatusComp';
import { RootState } from '@redux/index/reducers';

type RowType = {
  orderNumber: number;
  relatedInProgressCount: number;
  relatedTotalCount: number;
};

type OrderTooltipData = {
  id: number;
  status: keyof typeof Status;
  quantity: number;
  sku: number;
};

interface OwnProps {
  row: RowType;
}

const mapStateToProps = ({
  llScheduling,
  productionAssembly,
}: RootState) => ({
  schedulingQueueInProgressTooltip: llScheduling.schedulingQueueInProgressTooltip,
  productsDict: productionAssembly.productsDict,
});

const mapDispatchToProps = {
  fetchQueue: fetchSchedulingQueueInProgressTooltip,
  getBySku: getProductBySku,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = OwnProps & ConnectedProps<typeof connector>;

const InProgressRowComponent = React.memo(({
  row,
  schedulingQueueInProgressTooltip,
  productsDict,
  fetchQueue,
  getBySku,
}: Props) => {
  const onMouseEnter = useCallback(() => {
    if (schedulingQueueInProgressTooltip[row.orderNumber] === undefined) {
      fetchQueue(row.orderNumber);
    }
  }, [
    row,
    schedulingQueueInProgressTooltip,
    fetchQueue,
  ]);

  useEffect(() => {
    if (schedulingQueueInProgressTooltip[row.orderNumber] !== undefined) {
      schedulingQueueInProgressTooltip[row.orderNumber].forEach((product: any) => {
        getBySku(product.sku);
      });
    }
  }, [
    schedulingQueueInProgressTooltip,
    getBySku,
    row,
  ]);

  const showData = useCallback(
    (orderTooltipData: OrderTooltipData) => (
      <div
        className='tooltip-order'
        key={orderTooltipData.id}
      >
        <div className='tooltip-order__large'>
          <StatusComp status={orderTooltipData.status} />
        </div>

        <div className='tooltip-order__small'>
          x{orderTooltipData.quantity}
        </div>
        {
          !productsDict[orderTooltipData.sku]
            ? (
              <CircularProgress size={12} />
            )
            : (
              <div className='tooltip-order__small tooltip-order__small--right'>
                {productsDict[orderTooltipData.sku]?.size === 'YOUTH' ? 'YTH' : productsDict[orderTooltipData.sku].size}
              </div>
            )
        }

        <div className='tooltip__width__text'>
          {productsDict[orderTooltipData.sku]?.coloredStyle.style.name}
        </div>
      </div>
    ), [productsDict]
  );

  return (
    <div className='row-component__container'>
      <div>
        <div className='scheduling__data-grid__related-column__cell--in-progress'>
          {`${row.relatedInProgressCount} in progress`}
        </div>
        <div className='scheduling__data-grid__related-column__cell--total-items'>
          {`${row.relatedTotalCount} total items`}
        </div>
      </div>
      <MaterialTooltip
        tooltipClassName='tooltip-max-width'
        placement={'top'}
        contentClasses={'align__center'}
        tooltipText={
          !schedulingQueueInProgressTooltip[row.orderNumber]
            ? (
              <CircularProgress size={20} />
            )
            : (
              <div className='tooltip-order__container'>
                {schedulingQueueInProgressTooltip[row.orderNumber].map(showData)}
              </div>
            )}
      >
        <div
          onMouseEnter={onMouseEnter}
          className='ll-scheduling-queue-details__icon-eye'
          style={{
            backgroundImage: `url("${IconEye}")`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'contain',
            marginLeft: '10px',
          }}
        />
      </MaterialTooltip>
    </div>
  );
});

export default connector(InProgressRowComponent);
