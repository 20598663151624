import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  DragDropContext,
  Droppable,
  Draggable,
} from 'react-beautiful-dnd';
import { fetchAllCollectionsForLocker } from '@redux/lockerManager/actions';
import Modal from '@sharedComponents/Modal/Modal';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import Icon from '@sharedComponents/Icons/Icon';

// const numberOfBoxesPerRow = 3;
// const rowHeight = 55;

class ReorderCollectionsModal extends PureComponent {
  state = {
    collections: this.props.collections,
  };

  componentDidUpdate(prevProps) {
    const {
      dispatch,
      isOpen,
      lockerId,
      collections,
    } = this.props;

    if (!prevProps.isOpen && isOpen) {
      dispatch(fetchAllCollectionsForLocker(lockerId));
    }

    if (prevProps.collections !== collections) {
      this.setState({ collections });
    }
  }

  onDragEnd = (result) => {
    if (!result.destination || !result.source) return;
    if (result.destination.index === result.source.index) return;

    const sourceIndex = result.source.index;
    const targetIndex = result.destination.index;
    const collections = [...this.state.collections];

    if (sourceIndex === undefined || targetIndex === undefined) {
      return;
    }

    const [removedCollection] = collections.splice(sourceIndex, 1);
    collections.splice(targetIndex, 0, removedCollection);

    this.setState({ collections });
  };

  renderDraggableCollection = (collection) => (
    <div className='categories__draggable-item mr-20'>
      <Icon
        materialIcon={'drag_indicator'}
        classes={'mr-20'}
      />
      <span className='categories__draggable-item--info'>
        <b className='mr-5'>{collection.orderPlace}</b>
        -
        <span className='ml-5'>{collection.name}</span>
      </span>
    </div>
  );

  render() {
    const {
      isOpen,
      closeModal,
      save,
    } = this.props;

    const { collections } = this.state;
    const oldCollections = this.props.collections;

    return (
      <Modal
        title={'Edit Sort Order'}
        isOpen={isOpen}
        modalSize={'l'}
        closeModal={closeModal}
        buttons={(
          <ModalButtons
            confirmBtnText={'Save'}
            cancelBtnText={'Cancel'}
            onConfirm={save.bind(null, collections, oldCollections)}
            onClose={closeModal}
            isDangerousAction={true}
          />
        )}
      >
        <div className='mb-10'>Sort order</div>
        <DragDropContext onDragEnd={this.onDragEnd}>
          <Droppable
            droppableId='droppable'
            direction='vertical'
          >
              {(provided) => (
              <div
                ref={provided.innerRef}
                className=''
                {...provided.droppableProps}
              >
                {
                  collections.map((collection, index) => (
                    <Draggable
                      key={(collection?.id) || `e${index}`}
                      draggableId={(collection?.id?.toString()) || `e${index}`}
                      index={index}
                      isDragDisabled={!collection || (collection && (!collection.id || collection.id <= 0))}
                    >
                      {
                        (draggableProvided) => (
                          <div
                            ref={draggableProvided.innerRef}
                            {...draggableProvided.draggableProps}
                            {...draggableProvided.dragHandleProps}
                            // className='organization__featured-lockers__card-wrapper'
                          >
                            {
                              collection?.id
                                ? (
                                  <>
                                    {this.renderDraggableCollection(collection)}
                                  </>
                                )
                                : collection
                            }
                          </div>
                        )
                      }
                    </Draggable>
                  ))
                }
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Modal>
    );
  }
}

ReorderCollectionsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  lockerId: PropTypes.number.isRequired,
  collections: PropTypes.arrayOf(PropTypes.object).isRequired,
  closeModal: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
};

const mapStateToProps = ({ lockerManager }) => ({
  collections: lockerManager.lockerCollections,
});

export default connect(mapStateToProps)(ReorderCollectionsModal);
