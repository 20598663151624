/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Financials Homefield API
 * OpenAPI spec version: v1
 */
import type { VoucherOrderPaymentStatusEnum } from './voucherOrderPaymentStatusEnum';
import type { SubmitStatusEnum } from './submitStatusEnum';
import type { VoucherOrderNotificationSettingsDto } from './voucherOrderNotificationSettingsDto';
import type { VoucherCollectionDto } from './voucherCollectionDto';

export interface VoucherOrderDetailDto {
  id?: number;
  uuid?: string | null;
  created?: string | null;
  updated?: string | null;
  deleted?: string | null;
  organizationId?: number | null;
  lockerId?: number | null;
  lockerManagerId?: number;
  paymentStatus?: VoucherOrderPaymentStatusEnum;
  accountingSubmitStatus?: SubmitStatusEnum;
  startDate?: string;
  endDate?: string | null;
  erpId?: number | null;
  erpName?: string | null;
  totalAmount?: number;
  totalAmountLeftForRefund?: number;
  pricePaid?: number;
  discountAmount?: number;
  discountPercent?: number;
  refundAmount?: number;
  collectionCount?: number;
  totalInstanceCount?: number;
  redeemedInstanceCount?: number;
  canceledInstanceCount?: number;
  organizationName?: string | null;
  organizationAccountingId?: string | null;
  lockerName?: string | null;
  lockerAccountingId?: string | null;
  lockerManagerFirstName?: string | null;
  lockerManagerLastName?: string | null;
  lockerManagerEmail?: string | null;
  lockerManagerPhoneNumber?: string | null;
  cancellationId?: number | null;
  canceledOn?: string | null;
  voucherInstancesTotalRefundAmount?: number;
  notificationSettings?: VoucherOrderNotificationSettingsDto;
  voucherCollections?: VoucherCollectionDto[] | null;
}
