import React from 'react';
import PropTypes from 'prop-types';
import Modal from '@sharedComponents/Modal/Modal';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';

const SendNotificationsForItemsModal = React.memo(({
  isOpen,
  closeModal,
  order,
  items,
  sendNotification,
}) => (
  <Modal
    title={'Send Notification for Item(s)'}
    isOpen={isOpen}
    modalSize={'m'}
    closeModal={closeModal}
    buttons={(
      <ModalButtons
        confirmBtnText={'Send'}
        cancelBtnText={'Cancel'}
        onConfirm={sendNotification}
        onClose={closeModal}
      />
    )}
  >
    <h3 className='modal__description'>
      <div className='mb-10'><b>O{order.orderNumber}</b> - {(order.shippingInfo?.email) || ''}</div>
      {
        items.map((item) => (
          <div
            className='ml-10 mb-5'
            key={item.sku}
          >
            <span className='mr-10'>&bull;</span><b>{item.sku}</b> - {item.styleName}
          </div>
        ))
      }
    </h3>
  </Modal>
));

SendNotificationsForItemsModal.propTypes = {
  order: PropTypes.shape({
    orderNumber: PropTypes.number.isRequired,
    shippingInfo: PropTypes.shape({
      name: PropTypes.string,
      email: PropTypes.string,
    }),
  }).isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    sku: PropTypes.string.isRequired,
    styleName: PropTypes.string.isRequired,
  })).isRequired,
  isOpen: PropTypes.bool.isRequired,
  sendNotification: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default SendNotificationsForItemsModal;
