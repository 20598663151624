/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Orders Homefield API
 * OpenAPI spec version: v1
 */
import type { ShippingInfo } from './shippingInfo';
import type { OrderPricingDetails } from './orderPricingDetails';
import type { Coupon } from './coupon';
import type { Item } from './item';

export interface OrderDetailsDto {
  id?: number;
  uuid?: string | null;
  created?: string | null;
  updated?: string | null;
  deleted?: string | null;
  placed?: string | null;
  shipDate?: string | null;
  customerShipDate?: string | null;
  actualShipDate?: string | null;
  shippingInfo?: ShippingInfo;
  prdOrder?: boolean;
  organizationId?: number | null;
  salesChannelId?: number | null;
  accountingPrefix?: string | null;
  accountingId?: string | null;
  accountingAddressCode?: string | null;
  hasStripeConnection?: boolean;
  pricing?: OrderPricingDetails;
  coupon?: Coupon;
  isCanceled?: boolean;
  isPaused?: boolean;
  items?: Item[] | null;
  rowVersion?: string | null;
}
