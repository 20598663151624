/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Financials Homefield API
 * OpenAPI spec version: v1
 */
import type { PayoutDto } from './payoutDto';

export interface PayoutDtoPayoutsPagedListDto {
  pageNumber?: number;
  pageSize?: number;
  totalCount?: number;
  items?: PayoutDto[] | null;
  readonly totalPages?: number;
  readonly hasPreviousPage?: boolean;
  readonly hasNextPage?: boolean;
  totalRequestedPayouts?: number;
  totalProcessedPayouts?: number;
}
