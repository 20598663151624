import React, { useMemo } from 'react';
import Grid from '@material-ui/core/Grid';
import { Backdrop, Box, CircularProgress, makeStyles, Paper, Typography } from '@material-ui/core';
import { useGetHomefieldApiV1BulkstoresStagingjobsIdErrordetails } from '@api/accountsv1/bulk-stores';

type JobErrorProps = {
  jobId: number;
};

const useStyles = makeStyles(() => ({
  errors: {
    color: 'red',
  },
}));

const JobErrors = ({ jobId }: JobErrorProps) => {
  const classes = useStyles();

  const { data, isLoading } = useGetHomefieldApiV1BulkstoresStagingjobsIdErrordetails(jobId, {
    query: {
      enabled: jobId > 0,
    },
    request: {
      showErrorModal: true,
      isBlockingRequest: false,
    },
  });

  const submitResultErrors = useMemo(() => {
    const results = data?.result?.stagingOrganizations?.filter(o => o.ssapiSubmitResult !== undefined && o.ssapiSubmitResult !== null).map((org) => {
      return {
        orgName: org.organizationName!,
        errors: JSON.parse(org.ssapiSubmitResult!)?.message as string,
      };
    }) || [];

    return results;
  }, [data]);

  return (
    <Box component={Paper} p={2} m={2}>
      <Backdrop open={isLoading}>
        <CircularProgress />
      </Backdrop>
      <Grid container={true} spacing={2}>
        <Grid item={true} xs={12}>
          <Typography variant='h5'>Please fix these errors and then requeue the job.</Typography>
        </Grid>
        {submitResultErrors.map((error, index) => (
          <Grid key={index} item={true} xs={12}>
            <Typography variant='body1' color='error'>{error.orgName}</Typography>
            <Typography variant='body2' className={classes.errors}>{error.errors}</Typography>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default JobErrors;
