/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */

export type PrepressJobTypeEnum = 'Undefined' | 'HagPersonalizationCut' | 'HagLogoPrint' | 'HagLogoCut' | 'DtgLogoPrint' | 'DtgPersonalizationPrint' | 'HagLogoStrip';


export const PrepressJobTypeEnum = {
  Undefined: 'Undefined' as PrepressJobTypeEnum,
  HagPersonalizationCut: 'HagPersonalizationCut' as PrepressJobTypeEnum,
  HagLogoPrint: 'HagLogoPrint' as PrepressJobTypeEnum,
  HagLogoCut: 'HagLogoCut' as PrepressJobTypeEnum,
  DtgLogoPrint: 'DtgLogoPrint' as PrepressJobTypeEnum,
  DtgPersonalizationPrint: 'DtgPersonalizationPrint' as PrepressJobTypeEnum,
  HagLogoStrip: 'HagLogoStrip' as PrepressJobTypeEnum,
};
