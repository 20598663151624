/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Orders Homefield API
 * OpenAPI spec version: v1
 */

export interface Address {
  city?: string | null;
  country?: string | null;
  state?: string | null;
  line1?: string | null;
  line2?: string | null;
  postalCode?: string | null;
}
