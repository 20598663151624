import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {
  markOrderLineItemCheckedIn,
  markOrderLineItemDecorated,
  unmarkOrderLineItemCheckedIn,
  unmarkOrderLineItemDecorated,
  flagOrderLineItem,
  unflagOrderLineItem,
} from '@APICalls/productionAssembly/actions';
import { orderItemStatusEnum } from '@constants/enums/orderEnums';
import { decorationMethodEnum } from '@constants/enums/decorationEnums';
import AttachedFiles from '@components/PrintRoom/PrintRoomTableContent/AttachedFiles';
import CheckButton from '@sharedComponents/Inputs/CheckButton';
import FlagCell from '../../WeedingAndMasking/Cells/FlagCell';
import LogoOrTextCell from './Cells/ProductionAssemblyLogoOrTextCell';
import ProductionAssemblyFlaggingModal from './ProductionAssemblyFlaggingModal';
import ProductionAssemblyUnflagItemModal from './ProductionAssemblyUnflagItemModal';
import ProductionAssemblyArtworkItemsExpander from './ProductionAssemblyArtworkItemsExpander';
import ProductionAssemblyQueuePrintButton from './ProductionAssemblyQueuePrintButton';
import ImagePreviewModal from '@sharedComponents/Modal/ImagePreviewModal';
import Table from '@sharedComponents/Table/Table';
import {
  formatDecorationLocationSize,
  formatDecorationLocationHeight,
} from '@util/numberHelpers';
import { materialSwal } from '@util/componentHelper';
import { flagTypeEnum } from '@constants/enums/commonEnums';
import { getFlagReasons } from '@util/flagHelper';
import { reset } from 'redux-form';
import {
  productionAssemblyUnFlagItemForm,
  productionAssemblyFlagItemForm,
} from '@constants/reduxForms';

const TasksTable = Table();

const reprintUnavailableMethodsArr = [
  decorationMethodEnum.EMB,
  decorationMethodEnum.DTG,
];

class ProductionAssemblyReviewItemTasks extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      logoImagePreview: {
        isOpen: false,
        imageUrl: null,
      },
      flaggingModalIsOpen: false,
      unflaggingModalIsOpen: false,
      item: null,
      areArtworkItemsExpanded: false,
      artworkColumns: this.getArtworkItemColumns(false),
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.areArtworkItemsExpanded) {
      this.setState({
        areArtworkItemsExpanded: false,
        artworkColumns: this.getArtworkItemColumns(false),
      });
    }
  }

  openLogoPreviewModal = (imageUrl) => {
    this.setState({
      logoImagePreview: {
        isOpen: true,
        imageUrl,
      },
    });
  };

  closeLogoPreviewModal = () => {
    this.setState({
      logoImagePreview: {
        isOpen: false,
        imageUrl: null,
      },
    });
  };

  openFlaggingModal = (item) => {
    this.setState({
      flaggingModalIsOpen: true,
      item,
    });
  };

  openUnflaggingModal = (item) => {
    this.setState({
      unflaggingModalIsOpen: true,
      item,
    });
  };

  closeFlaggingModal = () => {
    const { dispatch } = this.props;

    dispatch(reset(productionAssemblyFlagItemForm));
    this.setState({
      flaggingModalIsOpen: false,
      item: null,
    });
  };

  closeUnflaggingModal = () => {
    const { dispatch } = this.props;

    dispatch(reset(productionAssemblyUnFlagItemForm));
    this.setState({
      unflaggingModalIsOpen: false,
      item: null,
    });
  };

  expandArtworkItems = () => {
    this.setState((prevState) => ({
      areArtworkItemsExpanded: !prevState.areArtworkItemsExpanded,
      artworkColumns: this.getArtworkItemColumns(!prevState.areArtworkItemsExpanded),
    }));
  };

  orderLineItemCheckedInOnClick = async (task) => {
    const {
      orderNumber,
      refresh,
    } = this.props;

    const taskStatus = task.status.status;
    const model = {
      ids: task.ids,
    };

    if (taskStatus === orderItemStatusEnum.Initial) {
      await markOrderLineItemCheckedIn(orderNumber, model);
      refresh();
    } else if (taskStatus === orderItemStatusEnum.CheckedIn) {
      await unmarkOrderLineItemCheckedIn(orderNumber, model);
      refresh();
    }
  };

  orderLineItemDecoratedOnClick = async (task) => {
    const {
      orderNumber,
      refresh,
    } = this.props;

    const taskStatus = task.status.status;
    const model = {
      ids: task.ids,
    };

    if ((task.decorationMethod === decorationMethodEnum.HAG && taskStatus === orderItemStatusEnum.CheckedIn)
      || (task.decorationMethod === decorationMethodEnum.EMB && taskStatus !== orderItemStatusEnum.Decorated)
      || (task.decorationMethod === decorationMethodEnum.DTG && taskStatus !== orderItemStatusEnum.Decorated)) {
      await markOrderLineItemDecorated(orderNumber, model);
      refresh();
    } else {
      await unmarkOrderLineItemDecorated(orderNumber, model);
      refresh();
    }
  };

  addToPrintQueue = (decoration, quantity) => {
    const {
      orderNumber,
      item,
      onAddToPrintQueue,
    } = this.props;

    const printQueueItem = {
      id: decoration.ids.join('-'),
      orderNumber,
      decorationIds: [decoration.ids],
      quantity,
      maxQuantity: decoration.ids.length,
      sku: item.sku,
      size: formatDecorationLocationSize(decoration.decorationWidth, decoration.decorationHeight),
      colorGroup: item.colorGroup,
      brandName: item.brandName,
      categoryName: item.categoryName,
      decorationLocation: decoration.decorationLocation,
      environmentName: decoration.environmentName,
      logoUrl: decoration.logoUrl,
    };

    onAddToPrintQueue(printQueueItem);
  };

  getGroupedItemColumns = (areArtworkItemsExpanded) => {
    const {
      checksDisabled,
      itemStatus,
    } = this.props;

    // Make sure printing is only available after pretreatment and before QC complete
    const dtgPrintButtonAvailable = [
      orderItemStatusEnum.PreTreated,
      orderItemStatusEnum.ProductionCompleted,
    ].includes(itemStatus);

    return [
      {
        Header: 'Barcode',
        accessor: '',
        minWidth: 200,
        Cell: (cellProps) => (
          <ProductionAssemblyArtworkItemsExpander
            barcodes={cellProps.value.barcodes || [cellProps.value.barcode]}
            areArtworkItemsExpanded={areArtworkItemsExpanded}
            expandArtworkItems={this.expandArtworkItems}
            ids={cellProps.value.ids || [cellProps.value.id]}
          />
        ),
      },
      {
        Header: 'Item',
        accessor: '',
        width: 200,
        Cell: (cellProps) => (
          <span>
            {
              !areArtworkItemsExpanded &&
              <LogoOrTextCell
                item={cellProps.value}
                openModal={this.openLogoPreviewModal}
                showThreadColorValue={cellProps.value.decorationMethod === decorationMethodEnum.EMB}
              />
            }
          </span>
        ),
      },
      {
        Header: 'Location & Size',
        accessor: '',
        width: 120,
        Cell: (cellProps) => (
          <span>
            {
              !areArtworkItemsExpanded &&
              <span>
                <span>{cellProps.value.decorationLocation}</span>
                {
                  cellProps.value.decorationWidth && cellProps.value.decorationHeight &&
                  <span>
                    ({
                      formatDecorationLocationSize(
                        cellProps.value.decorationWidth,
                        cellProps.value.decorationHeight
                      )
                    })
                  </span>
                }
                {
                  <div>
                    {
                      !cellProps.value.decorationHeight && cellProps.value.nameHeight && cellProps.value.text &&
                      <p>Name ({formatDecorationLocationHeight(cellProps.value.nameHeight)})</p>
                    }
                    {
                      !cellProps.value.decorationHeight && cellProps.value.numberHeight && cellProps.value.number &&
                      <p>Num. ({formatDecorationLocationHeight(cellProps.value.numberHeight)})</p>
                    }
                    {
                      cellProps.value.decorationHeight && cellProps.value.text &&
                      <p>Name ({formatDecorationLocationHeight(cellProps.value.decorationHeight)})</p>
                    }
                    {
                      cellProps.value.decorationHeight && cellProps.value.number &&
                      <p>Num. ({formatDecorationLocationHeight(cellProps.value.decorationHeight)})</p>
                    }
                  </div>
                }
              </span>
            }
          </span>
        ),
      },
      {
        Header: 'Decoration',
        accessor: '',
        width: 120,
        Cell: (cellProps) => (
          <span>
            {!areArtworkItemsExpanded && cellProps.value.decorationMethod}
          </span>
        ),
      },
      {
        Header: 'File(s)',
        accessor: 'files',
        width: 200,
        Cell: (cellProps) => (
          <span>
            {!areArtworkItemsExpanded &&
              <AttachedFiles
                files={cellProps.value}
                fileNameDict={this.getFileNameDict()}
              />}
          </span>
        ),
      },
      {
        Header: '',
        accessor: '',
        width: 550,
        Cell: (cellProps) => (
          <div className='flex justify__end'>
            {
              cellProps.value.decorationMethod === decorationMethodEnum.DTG
              && !!cellProps.value.previewUrl // Print Queue available for logos only for now
              && dtgPrintButtonAvailable &&
              <ProductionAssemblyQueuePrintButton
                decoration={cellProps.value}
                onClick={this.addToPrintQueue}
              />
            }
            <div className='flex'>
              <div className='ml-15'>
                {
                  !areArtworkItemsExpanded &&
                  (cellProps.value.decorationMethod !== decorationMethodEnum.EMB
                    && cellProps.value.decorationMethod !== decorationMethodEnum.DTG &&
                    <CheckButton
                      checked={
                        cellProps.value.status
                        && (cellProps.value.status.status === orderItemStatusEnum.CheckedIn
                          || cellProps.value.status.status === orderItemStatusEnum.Decorated)
                      }
                      disabled={
                        checksDisabled
                        || cellProps.value.status
                        && (cellProps.value.status.status !== orderItemStatusEnum.CheckedIn
                          && cellProps.value.status.status !== orderItemStatusEnum.Initial)
                        || itemStatus === orderItemStatusEnum.Initial
                        || itemStatus === orderItemStatusEnum.OnHold
                      }
                      onClick={this.orderLineItemCheckedInOnClick.bind(null, cellProps.value)}
                      text={'Check In'}
                    />
                  )
                }
              </div>
              <div className='ml-15'>
                {
                  !areArtworkItemsExpanded && (
                    <CheckButton
                      checked={cellProps.value.status
                        && cellProps.value.status.status === orderItemStatusEnum.Decorated}
                      text={'Decorated'}
                      disabled={
                        checksDisabled
                        || (cellProps.value.decorationMethod === decorationMethodEnum.HAG
                          && (cellProps.value.status && cellProps.value.status.status === orderItemStatusEnum.Initial))
                        || itemStatus === orderItemStatusEnum.Initial
                        || itemStatus === orderItemStatusEnum.OnHold
                        || itemStatus === orderItemStatusEnum.AwaitingDispatchForPicking
                        || itemStatus === orderItemStatusEnum.InPicking
                        || itemStatus === orderItemStatusEnum.CheckedIn
                      }
                      onClick={this.orderLineItemDecoratedOnClick.bind(null, cellProps.value)}
                    />
                  )
                }
              </div>
              <div className='ml-15'>
                {
                  (cellProps.value.ids && cellProps.value.ids.length === 1
                    && !(reprintUnavailableMethodsArr.includes(cellProps.value.decorationMethod)
                      && (cellProps.value.text || cellProps.value.number))) &&
                  <FlagCell
                    item={cellProps.value}
                    openFlaggingModal={this.openFlaggingModal}
                    openUnflaggingModal={this.openUnflaggingModal}
                    flagInfo={cellProps.value.flag}
                    disabled={checksDisabled
                      || itemStatus === orderItemStatusEnum.QualityChecked
                    }
                  />
                }
              </div>
            </div>
          </div>
        ),
      },
    ];
  };

  getArtworkItemColumns = () => {
    const {
      checksDisabled,
      itemStatus,
    } = this.props;

    return [
      {
        Header: 'Barcode',
        accessor: 'barcode',
        width: 200,
      },
      {
        Header: 'Item',
        accessor: '',
        minWidth: 150,
        Cell: (cellProps) => (
          <LogoOrTextCell
            item={cellProps.value}
            openModal={this.openLogoPreviewModal}
            showThreadColorValue={cellProps.value.decorationMethod === decorationMethodEnum.EMB}
          />
        ),
      },
      {
        Header: 'Location',
        accessor: 'decorationLocation',
        width: 120,
      },
      {
        Header: 'Decoration',
        accessor: 'decorationMethod',
        width: 100,
      },
      {
        Header: 'File(s)',
        accessor: 'files',
        width: 250,
        Cell: (cellProps) => (
          <AttachedFiles
            files={cellProps.value}
            fileNameDict={this.getFileNameDict()}
          />
        ),
      },
      {
        Header: '',
        accessor: '',
        Cell: (cellProps) => (
          cellProps.value.decorationMethod !== decorationMethodEnum.EMB &&
          <CheckButton
            checked={
              cellProps.value.status
              && (cellProps.value.status.status === orderItemStatusEnum.CheckedIn
                || cellProps.value.status.status === orderItemStatusEnum.Decorated)
            }
            disabled={checksDisabled
              || cellProps.value.status
              && (cellProps.value.status.status !== orderItemStatusEnum.CheckedIn
                && cellProps.value.status.status !== orderItemStatusEnum.Initial)
              || itemStatus === orderItemStatusEnum.Initial
              || itemStatus === orderItemStatusEnum.OnHold
            }
            onClick={this.orderLineItemCheckedInOnClick.bind(null, cellProps.value)}
            text={'Check In'}
          />
        ),
      },
      {
        Header: '',
        accessor: '',
        Cell: (cellProps) => (
          <CheckButton
            checked={cellProps.value.status && cellProps.value.status.status === orderItemStatusEnum.Decorated}
            text={'Decorated'}
            disabled={
              checksDisabled
              || (cellProps.value.decorationMethod === decorationMethodEnum.HAG
                && (cellProps.value.status && cellProps.value.status.status === orderItemStatusEnum.Initial))
              || itemStatus === orderItemStatusEnum.Initial
              || itemStatus === orderItemStatusEnum.OnHold
              || itemStatus === orderItemStatusEnum.InPicking
            }
            onClick={this.orderLineItemDecoratedOnClick.bind(null, cellProps.value)}
          />
        ),
      },
      {
        Header: '',
        accessor: '',
        width: 180,
        Cell: (cellProps) => (
          !(
            reprintUnavailableMethodsArr.includes(cellProps.value.decorationMethod)
            && (cellProps.value.text || cellProps.value.number)
          ) &&
          <FlagCell
            item={cellProps.value}
            openFlaggingModal={this.openFlaggingModal}
            openUnflaggingModal={this.openUnflaggingModal}
            flagInfo={cellProps.value.flag}
            disabled={checksDisabled || itemStatus === orderItemStatusEnum.QualityChecked}
          />
        ),
      },
    ];
  };

  getTrProps = (state, rowInfo) => {
    if (!rowInfo) {
      return {};
    }
    const status = rowInfo.original.status?.status;

    return {
      className: `${status === orderItemStatusEnum.Decorated ? 'is-active--light' : ''}`,
    };
  };

  getArtworkItemsTrProps = (state, rowInfo) => {
    if (!rowInfo) {
      return {};
    }
    const status = rowInfo.original.status?.status;

    return {
      className: `${status === orderItemStatusEnum.Decorated ? 'is-active--light' : ''}`,
    };
  };

  flagItem = async (form) => {
    const {
      orderNumber,
      refresh,
    } = this.props;
    const { item: { ids } } = this.state;

    const {
      reason,
      note,
      flagType,
    } = form;

    const model = {
      reason,
      note,
      flagType,
      ids,
    };

    const res = await flagOrderLineItem(orderNumber, model);
    if (res?.success) {
      materialSwal('Success', res.message, 'success');
      refresh();
      this.closeFlaggingModal();
    }
  };

  unFlagItem = async () => {
    const {
      orderNumber,
      refresh,
    } = this.props;
    const { item } = this.state;

    const res = await unflagOrderLineItem(orderNumber, { ids: item.ids });
    if (res?.success) {
      materialSwal('Success', res.message, 'success');
      refresh();
      this.closeUnflaggingModal();
    }
  };

  getFileNameDict = () => {
    const { item } = this.props;
    const fileNameDict = {};
    const dtgFileName = ((item.itemBarcodes?.filter((ib) => !!ib.barcode))?.map((ib) => ib.barcode) || [])?.sort().join('-');

    if (!dtgFileName) return fileNameDict;

    for (const groupedLogo of item.groupedLogos) {
      if (groupedLogo.decorationMethod === decorationMethodEnum.DTG && groupedLogo.files && groupedLogo.files.length) {
        for (const file of groupedLogo.files) {
          fileNameDict[file] = dtgFileName + '.png';
        }
      }
    }

    return fileNameDict;
  };

  render() {
    const {
      tasks,
      orderNumber,
      orderId,
      logos,
      colorsDictionary,
    } = this.props;

    const {
      areArtworkItemsExpanded,
      artworkColumns,
      unflaggingModalIsOpen,
      item,
      logoImagePreview,
      flaggingModalIsOpen,
    } = this.state;

    const flagType = item && reprintUnavailableMethodsArr.includes(item.decorationMethod)
      ? flagTypeEnum.Rework
      : flagTypeEnum.Reprint;

    const reason = item && getFlagReasons(flagType, item.decorationMethod)[0].reason;

    return (
      <div>
        <ImagePreviewModal
          modalIsOpen={logoImagePreview.isOpen}
          closeModal={this.closeLogoPreviewModal}
          imageUrl={logoImagePreview.imageUrl}
        />
        <ProductionAssemblyFlaggingModal
          isOpen={flaggingModalIsOpen}
          closeModal={this.closeFlaggingModal}
          onSubmit={this.flagItem}
          item={item}
          orderNumber={orderNumber}
          orderId={orderId}
          colorsDictionary={colorsDictionary}
          initialValues={{
            flagType,
            reason,
          }}
        />
        <ProductionAssemblyUnflagItemModal
          isOpen={unflaggingModalIsOpen}
          closeModal={this.closeUnflaggingModal}
          onSubmit={this.unFlagItem}
          item={item}
          orderNumber={orderNumber}
          orderId={orderId}
          colorsDictionary={colorsDictionary}
          initialValues={{
            flagType: item?.flag && item.flag.type,
            reason: item?.flag && item.flag.reason,
            note: item?.flag && item.flag.note,
          }}
        />
        <TasksTable
          columns={this.getGroupedItemColumns()}
          data={tasks}
          getTrProps={this.getTrProps}
          pageSize={tasks.length}
          showPagination={false}
          classNames={''}
        />
        {
          areArtworkItemsExpanded &&
          <TasksTable
            columns={artworkColumns}
            classNames={''}
            data={logos}
            pageSize={logos.length}
            showPagination={false}
            getTrProps={this.getArtworkItemsTrProps}
            customProps={{ TheadComponent: () => null }}
          />
        }
      </div>
    );
  }
}

ProductionAssemblyReviewItemTasks.propTypes = {
  item: PropTypes.object.isRequired,
  tasks: PropTypes.array.isRequired,
  orderId: PropTypes.number.isRequired,
  orderNumber: PropTypes.number.isRequired,
  checksDisabled: PropTypes.bool,
  itemStatus: PropTypes.string.isRequired,
  logos: PropTypes.array.isRequired,
  refresh: PropTypes.func.isRequired,
  colorsDictionary: PropTypes.objectOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    dtgCode: PropTypes.string,
    cmykValue: PropTypes.string.isRequired,
    dtgCmykValue: PropTypes.string.isRequired,
    hexValue: PropTypes.string,
    threadValue: PropTypes.string,
    brightness: PropTypes.string.isRequired,
  })).isRequired,
  onAddToPrintQueue: PropTypes.func.isRequired,
};

const mapStateToProps = ({ productCatalog }) => ({
  colorsDictionary: productCatalog.colorsDictionary,
});

export default connect(mapStateToProps)(ProductionAssemblyReviewItemTasks);
