/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Orders Homefield API
 * OpenAPI spec version: v1
 */
import type { AccountExecutiveInfo } from './accountExecutiveInfo';

export interface ContactDto {
  id?: number;
  uuid?: string | null;
  created?: string | null;
  updated?: string | null;
  deleted?: string | null;
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  phoneNumber?: string | null;
  organizationType?: string | null;
  identityUserId?: string | null;
  storefrontLockerManagerId?: number | null;
  accountExecutive?: AccountExecutiveInfo;
}
