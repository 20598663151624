import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Progress from '@components/shared/Display/Progress';

class RedemptionCount extends PureComponent {
  render() {
    const {
      redemptionCount,
      redemptionLimit,
      size = 'medium',
    } = this.props;

    if (!redemptionLimit) {
      return <span className='coupon__info--green'>{redemptionCount || 0}</span>;
    }

    return (
      <>
        <div>
          <span className='coupon__info--green'>{redemptionCount || 0}</span>
          <span>{redemptionLimit ? ` / ${redemptionLimit}` : 0}</span>
        </div>
        <Progress
          color={redemptionLimit === redemptionCount ? 'secondary' : 'primary'}
          value={redemptionCount / redemptionLimit * 100}
          className={`progress-bar-cell__bar progress-bar-cell__bar--${size}`}
        />
      </>
    );
  }
}

RedemptionCount.propTypes = {
  redemptionCount: PropTypes.number,
  redemptionLimit: PropTypes.number,
  size: PropTypes.string,
};

export default RedemptionCount;
