import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  fundraisingPayoutTypeEnum,
  fundraisingPayoutStatusEnum,
} from '@constants/enums/fundraisingPayoutsEnums';
import {
  sortDirectionEnum,
  keyNameEnum,
} from '@constants/enums/commonEnums';
import { getSubmitStatusOptions } from '@constants/options/options';
import PermissionsEnum from '@constants/enums/permissionsEnum';
import { fetchPayoutsTable } from '@redux/fundraisingPayouts/actions';
import { parseDateNumeric } from '@util/dateHandler';
import { formatDollarAmount } from '@util/numberHelpers';
import {
  getPagingParamsFromTable,
  getSortParamsFromTable,
} from '@util/tableHelpers';
import PermissionHandler from '@sharedComponents/Authorization/PermissionHandler';
import SearchFilter from '@sharedComponents/Inputs/SearchFilter';
import MaterialCheckbox from '@sharedComponents/Inputs/MaterialCheckbox';
import HeaderCell from '@sharedComponents/Table/TableCells/HeaderCell';
import Table from '@sharedComponents/Table/Table';
import Dropdown from '@sharedComponents/Inputs/Dropdowns/Dropdown/Dropdown';
import FundraisingPayoutsQuickView from './FundraisingPayoutsQuickView/FundraisingPayoutsQuickView';
import FundraisingPayoutsAdminCell from './FundraisingPayoutsCells/FundraisingPayoutsAdminCell';
import FundraisingPayoutsCompletedCell from './FundraisingPayoutsCells/FundraisingPayoutsCompletedCell';

const FundraisingPayoutsTable = Table();

const accountingSubmitOptions = getSubmitStatusOptions(true, 'All accounting statuses');

class FundraisingPayouts extends Component {
  state = {
    selectedPayout: null,
    sortOrder: this.props.sortOrder,
    sortByState: this.props.sortByState,
    pageNumber: this.props.pageNumber,
    pageSize: this.props.pageSize,
    selectedTab: fundraisingPayoutTypeEnum.Request,
    canceledChecked: false,
    accountingSubmitStatus: null,
  };

  selectPayout = (selectedPayout) => {
    this.setState({ selectedPayout });
  };

  unselectPayout = () => {
    this.setState({ selectedPayout: null });
  };

  selectTab = (selectedTab) => {
    this.unselectPayout();

    this.setState({
      selectedTab,
      status: selectedTab === fundraisingPayoutTypeEnum.Request
        ? fundraisingPayoutTypeEnum.Request
        : fundraisingPayoutTypeEnum.ProcessedPayout,
      canceledChecked: false,
    }, this.refreshData);
  };

  fetchData = (state, instance) => {
    const {
      sortByState,
      sortOrder,
    } = this.props;

    const {
      page,
      pageSize,
    } = getPagingParamsFromTable(instance);

    const {
      sortColumn,
      sortDirection,
    } = getSortParamsFromTable(instance, sortDirectionEnum, sortByState, sortOrder);

    this.setState({
      pageNumber: page + 1,
      pageSize,
      sortByState: sortColumn,
      sortOrder: sortDirection,
    }, this.refreshData);
  };

  refreshData = async () => {
    const { dispatch } = this.props;

    const {
      sortByState,
      sortOrder,
      pageNumber,
      pageSize,
      search,
      selectedTab,
      canceledChecked,
      accountingSubmitStatus,
    } = this.state;

    const status = canceledChecked ? fundraisingPayoutStatusEnum.Canceled : '';

    dispatch(fetchPayoutsTable(
      selectedTab,
      pageNumber,
      pageSize,
      status,
      sortByState,
      sortOrder,
      search,
      accountingSubmitStatus
    ));
  };

  clearSearch = () => {
    this.setState({
      search: '',
    }, this.refreshData);
  };

  filterKey = (e) => {
    if (e.key && e.key !== keyNameEnum.Enter) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();

    const search = e.target.value;

    this.setState(() => ({
      search,
    }), this.refreshData);
  };

  isSelected = (key) => {
    const { selectedPayout } = this.state;

    return selectedPayout && key === selectedPayout;
  };

  canceledChecked = () => {
    this.setState((prevState) => ({
      canceledChecked: !prevState.canceledChecked,
    }), this.refreshData);
  };

  filterByAccountingStatus = (value) => {
    this.setState({
      accountingSubmitStatus: value,
    }, this.refreshData);
  };

  getCommonColumns = () => {
    const { selectedPayout } = this.state;

    let columns = [
      {
        Header: <HeaderCell text={'Payout ID'} />,
        accessor: 'id',
        sortable: true,
        width: 125,
      },
      {
        Header: <HeaderCell text={'Requested on'} />,
        accessor: 'created',
        sortable: true,
        width: 160,
        Cell: (cellProps) => cellProps.value && parseDateNumeric(cellProps.value),
      },
      {
        Header: 'Admin',
        accessor: 'payoutRecipient.lockerManager',
        minWidth: 130,
        Cell: (cellProps) => <FundraisingPayoutsAdminCell admin={cellProps.value} />,
      },
      {
        Header: 'Recipient',
        accessor: 'payoutRecipient.name',
        minWidth: 200,
      },
    ];

    if (!selectedPayout) {
      columns = [
        ...columns,
        {
          Header: 'Method',
          accessor: 'method',
          sortable: false,
          width: 140,
        },
      ];
    }

    columns = [
      ...columns,
      {
        Header: 'Amount',
        accessor: 'amount',
        sortable: false,
        width: 150,
        Cell: (cellProps) => formatDollarAmount(cellProps.value),
      },
    ];

    return columns;
  };

  getProcessedPayoutsColumns = () => {
    const { selectedPayout } = this.state;
    const commonColumns = this.getCommonColumns(selectedPayout);

    if (!selectedPayout) {
      return [
        ...commonColumns,
        {
          Header: 'Date processed',
          accessor: '',
          sortable: false,
          resizable: false,
          width: 160,
          Cell: (cellProps) => (
            <FundraisingPayoutsCompletedCell
              status={cellProps?.value?.status?.status}
              completed={cellProps.value.checkDate}
            />
          ),
        },
      ];
    }

    return commonColumns;
  };

  getTrProps = (state, rowInfo) => {
    const { selectedPayout } = this.state;

    if (!rowInfo) {
      return {};
    }

    return {
      onClick: () => {
        this.selectPayout(rowInfo.original.id);
      },
      className: `text-left cursor-pointer ${selectedPayout && this.isSelected(rowInfo.original.id) && 'is-active'}`,
    };
  };

  render() {
    const {
      queue,
      totalPages,
      search,
      hasNextPage,
      hasPreviousPage,
      totalRequested,
      totalProcessed,
    } = this.props;

    const {
      selectedPayout,
      selectedTab,
      canceledChecked,
    } = this.state;

    const columns = selectedTab === fundraisingPayoutTypeEnum.Request
      ? this.getCommonColumns()
      : this.getProcessedPayoutsColumns();

    return (
      <div className='container'>
        <div className='product-catalog__filters'>
          <div className='flex'>
            <SearchFilter
              initialValue={search || ''}
              search={this.filterKey}
              clearSearch={this.clearSearch}
            />
            <PermissionHandler permissions={PermissionsEnum.FundraisingPayoutsSubmitPayout}>
              <Dropdown
                options={accountingSubmitOptions}
                onChange={this.filterByAccountingStatus}
                classes={'margin-left'}
              />
            </PermissionHandler>
          </div>
          {
            selectedTab === fundraisingPayoutTypeEnum.ProcessedPayout &&
            <MaterialCheckbox
              checked={canceledChecked}
              onClick={this.canceledChecked}
              text={'Show Only Canceled'}
            />
          }
        </div>

        <ul className='tabs tabs--size-l'>
          <li
            onClick={this.selectTab.bind(null, fundraisingPayoutTypeEnum.Request)}
            type='button'
            className={`tab-item ${selectedTab === fundraisingPayoutTypeEnum.Request ? 'is-active' : ''} tab-item`}
          >
            {`Payout Requests (${totalRequested})`}
          </li>
          <li
            onClick={this.selectTab.bind(null, fundraisingPayoutTypeEnum.ProcessedPayout)}
            type='button'
            className={`tab-item ${selectedTab === fundraisingPayoutTypeEnum.ProcessedPayout ? 'is-active' : ''} tab-item`}
          >
            {`Completed Payouts (${totalProcessed})`}
          </li>
        </ul>

        <div className='master-detail'>
          <div className='w-100'>
            <div className='sheet'>
              <FundraisingPayoutsTable
                columns={columns}
                data={queue}
                onFetchData={this.fetchData}
                showPagination={hasNextPage || hasPreviousPage}
                getTrProps={this.getTrProps}
                totalPages={totalPages}
              />
            </div>
          </div>
          {
            selectedPayout &&
            <FundraisingPayoutsQuickView
              closeDetails={this.unselectPayout}
              payoutId={selectedPayout}
              refresh={this.refreshData}
            />
          }
        </div>
      </div>
    );
  }
}

FundraisingPayouts.propTypes = {
  totalPages: PropTypes.number.isRequired,
  hasPreviousPage: PropTypes.bool,
  hasNextPage: PropTypes.bool,
  pageNumber: PropTypes.number,
  pageSize: PropTypes.number,
  queue: PropTypes.arrayOf(PropTypes.object).isRequired,
  search: PropTypes.string,
  sortOrder: PropTypes.string,
  sortByState: PropTypes.string,
  totalRequested: PropTypes.number.isRequired,
  totalProcessed: PropTypes.number.isRequired,
};

const mapStateToProps = ({ fundraisingPayouts }) => ({
  totalPages: fundraisingPayouts.payoutsQueue.totalPages,
  hasPreviousPage: fundraisingPayouts.payoutsQueue.hasPreviousPage,
  hasNextPage: fundraisingPayouts.payoutsQueue.hasNextPage,
  queue: fundraisingPayouts.payoutsQueue.queue,
  pageNumber: fundraisingPayouts.payoutsQueue.pageNumber,
  pageSize: fundraisingPayouts.payoutsQueue.pageSize,
  search: fundraisingPayouts.payoutsQueue.search,
  totalRequested: fundraisingPayouts.payoutsQueue.totalRequestedPayouts,
  totalProcessed: fundraisingPayouts.payoutsQueue.totalProcessedPayouts,
});

export default connect(mapStateToProps)(FundraisingPayouts);
