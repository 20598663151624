/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */

export type HagTransmissionStatusEnum = 'Undefined' | 'Sending' | 'Dequeued' | 'Done' | 'Canceled' | 'Failed';


export const HagTransmissionStatusEnum = {
  Undefined: 'Undefined' as HagTransmissionStatusEnum,
  Sending: 'Sending' as HagTransmissionStatusEnum,
  Dequeued: 'Dequeued' as HagTransmissionStatusEnum,
  Done: 'Done' as HagTransmissionStatusEnum,
  Canceled: 'Canceled' as HagTransmissionStatusEnum,
  Failed: 'Failed' as HagTransmissionStatusEnum,
};
