/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */

export interface IIdentityUserDto {
  readonly id?: string | null;
  readonly email?: string | null;
  readonly firstName?: string | null;
  readonly lastName?: string | null;
  readonly displayName?: string | null;
  readonly gpDocumentId?: string | null;
  readonly commission?: string | null;
  readonly accountUuid?: string | null;
  readonly storefrontId?: number | null;
}
