import React, { useCallback, useState } from 'react';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { StagingJobOutput } from '@api/accountsv1/models';
import { AppBar, Box, Dialog, IconButton, Menu, MenuItem, Toolbar, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import JobErrors from './JobErrors';
import { useHistory } from 'react-router-dom';
import { bulkBuildJobDetailsUrl } from '@constants/clientUrls/clientUrls';
import { usePutHomefieldApiV1BulkstoresStagingjobsIdQueue } from '@api/accountsv1/bulk-stores';

type BulkBuildTableMenuProps = {
  row: StagingJobOutput;
  refetchJobs: () => void;
};

const BulkBuildTableMenu = ({ row, refetchJobs }: BulkBuildTableMenuProps) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [errorModalOpen, setErrorModalOpen] = useState<boolean>(false);

  const { id, status } = row;

  const { mutateAsync: queueJob } = usePutHomefieldApiV1BulkstoresStagingjobsIdQueue({ request: { showErrorModal: true } });

  const history = useHistory();

  const handleQueue = async () => {
    await queueJob({ id });
    refetchJobs();
    setAnchorEl(null);
  };

  const handleShowErrors = () => {
    setErrorModalOpen(true);
    setAnchorEl(null);
  };

  const handleViewDetails = () => {
    history.push(bulkBuildJobDetailsUrl(id));
    setAnchorEl(null);
  };

  const createMenuClickHandler = (menuOption: string) => {
    switch (menuOption) {
      case 'queue':
        return handleQueue;
      case 'errors':
        return handleShowErrors;
      case 'details':
        return handleViewDetails;
      default:
        return () => {};
    }
  };

  const closeModal = useCallback(() => {
    setErrorModalOpen(false);
  }, []);

  const handleMenuClick = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleMenuClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <>
      <Dialog fullScreen={true} open={errorModalOpen} onClose={closeModal}>
        <AppBar style={{ position: 'sticky' }}>
          <Toolbar>
            <Box flex={1} display='flex' justifyContent='space-between' alignItems='center'>
              <Typography variant='h6'>Build Build Upload Error(s)</Typography>
              <IconButton edge='start' color='inherit' onClick={closeModal} aria-label='close'>
                <CloseIcon />
              </IconButton>
            </Box>
          </Toolbar>
        </AppBar>
        <JobErrors jobId={id} />
      </Dialog>
      <IconButton onClick={handleMenuClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        id='simple-menu'
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        {(status === 'NotScheduled' || status === 'Failed') && <MenuItem onClick={createMenuClickHandler('queue')}>{status === 'Failed' && 'Re'}Queue Job</MenuItem>}
        {status === 'Failed' && <MenuItem onClick={createMenuClickHandler('errors')}>View Errors</MenuItem>}
        {row.storeCount < 1000 && <MenuItem onClick={createMenuClickHandler('details')}>View Details</MenuItem>}
      </Menu>
    </>
  );
};

export default BulkBuildTableMenu;
