/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Financials Homefield API
 * OpenAPI spec version: v1
 */

export interface PayoutAccountingSubmitResultDto {
  id?: number;
  uuid?: string | null;
  created?: string | null;
  updated?: string | null;
  deleted?: string | null;
  payoutId?: number;
  userId?: string | null;
  payoutData?: string | null;
  success?: boolean;
  statusCode?: number;
  elapsedTime?: number;
  message?: string | null;
  result?: string | null;
}
