import { Dispatch } from 'redux';
import * as actionTypes from './types';
import { editAdmin } from '@api/squadlockerServices/admins';
import { UpdateAdminRequestModel } from '@api/squadlockerServices/models';
import { sortDirectionShortEnum } from '@constants/enums/commonEnums';
import adminsUrls from '@constants/sqdApiUrls/adminsUrls';
import {
  DateRangeEnum,
  dateRangeEnum,
} from '@constants/enums/dateRangeEnum';
import {
  slServicesApi,
  identityApi,
} from '../../sqdApis';
import identityUrls from '@constants/sqdApiUrls/identityUrls';
import {
  makeApiCall,
  makeApiCallWithErrorModal,
  makeApiCallWithSubmissionError,
} from '@util/apiHelper';
import { materialSwal } from '@util/componentHelper';

export const updateAdminsTable = (data: any) => ({
  type: actionTypes.UPDATE_ADMINS_TABLE,
  data,
});

export const updateAdminDetails = (data: any) => ({
  type: actionTypes.UPDATE_ADMIN,
  data,
});

export const updateAdminSuggestions = (data: any) => ({
  type: actionTypes.UPDATE_ADMIN_SUGGESTIONS,
  data,
});

export const updateAccountExecutives = (data: any) => ({
  type: actionTypes.UPDATE_ACCOUNT_EXECUTIVES,
  data,
});

export const fetchAdmins = (
  pageNumber = 1,
  pageSize = 10,
  sortBy = '',
  sortOrder = '',
  dateRange: keyof DateRangeEnum = dateRangeEnum.all.value,
  search = '',
  organizationId = '',
  lockerId = '',
  accountExecutives = [] as string[]
) => async (dispatch: Dispatch) => {
  const range = dateRangeEnum[dateRange];

  const res = slServicesApi.get(adminsUrls.admins(
    pageNumber,
    pageSize,
    sortBy,
    sortOrder,
    range.minDatePlaced?.toISOString() ?? '',
    range.maxDatePlaced?.toISOString() ?? '',
    search,
    organizationId,
    lockerId,
    accountExecutives
  ), { handleBlockingLoading: false });

  const data = await makeApiCall(res);

  if (data) {
    dispatch(updateAdminsTable({
      totalPages: data.totalPages,
      hasPreviousPage: data.hasPreviousPage,
      hasNextPage: data.hasNextPage,
      pageNumber: data.pageNumber,
      pageSize: data.pageSize,
      queue: data.items,
      totalCount: data.totalCount,
    }));
  }
};

export const fetchAdmin = (adminId: number) => async (dispatch: Dispatch) => {
  const call = slServicesApi.get(adminsUrls.admin(adminId), { handleBlockingLoading: false });
  const response = await makeApiCall(call);
  if (response) {
    dispatch(updateAdminDetails(response));
  }
};

export const updateAdmin = (admin: any) => async () => {
  const body: UpdateAdminRequestModel = {
    email: admin.email,
    firstName: admin.firstName,
    lastName: admin.lastName,
    phoneNumber: admin.phoneNumber,
    role: admin.role,
    notes: admin.notes ?? undefined,
    accountManager: admin.accountManager ?? undefined,
    classification: admin.classification ?? undefined,
    organizationType: admin.organizationType ?? undefined,
  };

  const call = editAdmin(admin.id, body);

  const response = await makeApiCallWithSubmissionError(call);
  if (response?.success) {
    materialSwal('Success', response.message, 'success');
  }
};

export const fetchAdminSuggestions = (filter = '', limit = 10) => async (dispatch: Dispatch) => {
  const call = slServicesApi.get(adminsUrls.adminSuggestions(filter, limit), { handleBlockingLoading: false });
  const response = await makeApiCall(call);
  if (response) {
    dispatch(updateAdminSuggestions(response));
  }
};

export const fetchAccountExecutives = () => async (dispatch: Dispatch) => {
  const call = identityApi.get(identityUrls.accountExecutives, { handleBlockingLoading: false });
  const res = await makeApiCallWithErrorModal(call);
  if (res) {
    dispatch(updateAccountExecutives(res));
  }
};

export const fetchLockerAdmins = (
  lockerId: number,
  pageNumber: number,
  pageSize: number,
  sortColumn: string = 'fullName',
  sortDirection: string = sortDirectionShortEnum.Asc
) => async (dispatch: Dispatch) => {
  const call = slServicesApi.get(
    adminsUrls.adminsByLockerId(lockerId, pageNumber, pageSize, sortColumn, sortDirection),
    {
      handleBlockingLoading: false,
    }
  );

  const response = await makeApiCall(call);
  if (response) {
    dispatch(updateAdminsTable(response));
  }
};
