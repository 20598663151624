/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */
import type { SubmitStatusEnum } from './submitStatusEnum';
import type { SortQueueByEnum } from './sortQueueByEnum';
import type { SortDirectionEnum } from './sortDirectionEnum';

export type GetHomefieldApiOrdermanagementOrdersParams = { pageNumber?: number; pageSize?: number; filter?: string; accountingSubmitStatus?: SubmitStatusEnum; minDatePlaced?: string; maxDatePlaced?: string; sortBy?: SortQueueByEnum; sortDirection?: SortDirectionEnum; lockerIds?: string; userId?: string; partnerName?: string };
