/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Catalog Homefield API
 * OpenAPI spec version: v1
 */

export interface CreateTabletProfileCommand {
  name?: string | null;
  note?: string | null;
  printDirectionUnderbase?: string | null;
  printDirectionColor?: string | null;
  waitUnderbaseMilliseconds?: number;
  passesUnderbase?: number;
  passesColor?: number;
}
