import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import Expander from '@sharedComponents/Buttons/Expander';
import EditButton from '@sharedComponents/Display/EditButton';
import HeaderDetails from './HeaderDetails';

class Header extends PureComponent {
  state = {
    detailsAreExpanded: true,
    pageTitle: '',
  };

  componentDidMount() {
    const { admin } = this.props;
    const title = `${admin.firstName || '-'} ${admin.lastName || '-'}`;
    this.setState({ pageTitle: title });
  }

  componentDidUpdate(prevProps) {
    const { admin } = this.props;
    if (prevProps.admin.firstName !== admin.firstName || prevProps.admin.lastName !== admin.lastName) {
      const title = `${admin.firstName || '-'} ${admin.lastName || '-'}`;
      this.setState({ pageTitle: title });
    }
  }

  changeExpand = () => {
    this.setState((prevState) => ({
      detailsAreExpanded: !prevState.detailsAreExpanded,
    }));
  };

  render() {
    const {
      admin,
      openEditAdminModal,
    } = this.props;

    const { detailsAreExpanded, pageTitle } = this.state;

    return (
      <div className='lockerManagerEdit__header'>
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
        <div className='lockerManagerEdit__details-header'>
          <div className='lockerManagerEdit__details-header--info'>
            {pageTitle}
            <EditButton
              onClick={openEditAdminModal}
            />
          </div>
          <Expander
            isExpanded={detailsAreExpanded}
            toggle={this.changeExpand}
          />
        </div>
        {
          detailsAreExpanded &&
          <HeaderDetails
            admin={admin}
          />
        }
      </div>
    );
  }
}

Header.propTypes = {
  admin: PropTypes.shape({
    id: PropTypes.number,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    phoneNumber: PropTypes.string,
    accountManager: PropTypes.string,
    role: PropTypes.string,
    createdAt: PropTypes.string,
    classification: PropTypes.string,
    notes: PropTypes.string,
  }).isRequired,
  openEditAdminModal: PropTypes.func.isRequired,
};

const mapStateToProps = ({ lockerManager }) => ({
  partners: lockerManager.partners,
});

export default connect(mapStateToProps)(Header);
