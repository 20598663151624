/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Catalog Homefield API
 * OpenAPI spec version: v1
 */
import type { ModeEnum } from './modeEnum';

export interface ChangeStylePrimaryVendorCommand {
  id?: number;
  vendorCode?: string | null;
  mode?: ModeEnum;
}
