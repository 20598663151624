/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */

export type PrepressJobItemReworkStatusEnum = 'Undefined' | 'PrepressPending' | 'PrepressReady' | 'Printing' | 'Done';


export const PrepressJobItemReworkStatusEnum = {
  Undefined: 'Undefined' as PrepressJobItemReworkStatusEnum,
  PrepressPending: 'PrepressPending' as PrepressJobItemReworkStatusEnum,
  PrepressReady: 'PrepressReady' as PrepressJobItemReworkStatusEnum,
  Printing: 'Printing' as PrepressJobItemReworkStatusEnum,
  Done: 'Done' as PrepressJobItemReworkStatusEnum,
};
