import React, { useCallback, useState } from 'react';
import { Box, IconButton } from '@material-ui/core';
import DropZoneWrapper from './DropZoneWrapper';
import Icon from '@sharedComponents/Icons/Icon';

import DeleteIcon from '@material-ui/icons/Delete';
import Label from '../Display/Label';
import { getLast20Chars } from '@util/stringHelpers';

const isValidUrl = (url) => {
  const pattern = new RegExp('^(https?:\\/\\/)?' // protocol
    + '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|' // domain name
    + '((\\d{1,3}\\.){3}\\d{1,3}))' // OR ip (v4) address
    + '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' // port and path
    + '(\\?[;&a-z\\d%_.~+=-]*)?' // query string
    + '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator

  return !!pattern.test(url);
};

const ImageDropzone = ({
  imageUrl, message, changeImage, acceptedFileTypes, size, showThumbnail = true, onRemove, fileType, label, labelType = 'status-green', highlightEmptyBoxes,
}) => {
  const [
    loading,
    setLoading,
  ] = useState(false);

  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    const reader = new FileReader();
    const file = acceptedFiles[0];

    let dropMessage;
    setLoading(true);

    if (rejectedFiles.length > 0) {
      dropMessage = {
        type: 'reject',
        body: `${acceptedFileTypes} files required.`,
      };
      changeImage(null, dropMessage, null);
      setLoading(false);
    } else {
      reader.onloadend = () => {
        const { result } = reader;
        dropMessage = {
          body: `File ${acceptedFiles[0].name} has been attached.`,
          type: 'default',
        };
        changeImage(result, dropMessage, acceptedFiles[0], size, fileType);
        setLoading(false);
      };

      reader.readAsDataURL(file);
    }
  }, [changeImage, acceptedFileTypes, size, fileType]);

  const dropzoneContent = loading
    ? (
      <label className='button'>
        <Icon
          fontAwesomeIcon={'spinner'}
          classes={'fa-spin'}
        />
        Uploading...
      </label>
    )
    : <label className={`button message-${message.type}`}>{message.body}</label>;

  const isUrl = isValidUrl(imageUrl);
  const shortenedText = getLast20Chars(imageUrl);

  const handleOnClick = useCallback(() => {
    if (!onRemove) return;

    onRemove();
  }, [onRemove]);

  return (
    <Box>
      <Box
        border='solid'
        style={{ borderWidth: '1px' }}
        width='200px'

      >
        <Label
          classes={'design-label'}
          text={label}
          tooltipText={label}
          type={labelType}
        />
        {imageUrl &&
          <Box
            display='flex'
            alignItems='center'
            justifyContent='center'
          >
            {showThumbnail &&
              <img
                src={imageUrl}
                style={{
                  width: '200px',
                  height: '200px',
                }}
              />
            }
            {!showThumbnail &&
              <div style={{ 'overflow': 'hidden' }}>
                {isUrl ? <a href={imageUrl} title={imageUrl} target='_blank' rel='noreferrer'>{shortenedText}</a> : shortenedText}
              </div>
            }
            {onRemove
              &&
              <IconButton
                aria-label='remove'
                size='small'
                onClick={handleOnClick}
              >
                <DeleteIcon />
              </IconButton>
            }
          </Box>

        }
        {!imageUrl &&
          <Box
            display='flex'
            height='100%'
            alignItems='center'
            justifyContent='center'
            style={highlightEmptyBoxes ? { border: '3px solid red' } : {}}
          >
            <DropZoneWrapper
              onDrop={onDrop}
              multiple={false}
              className={'block-drop-zone'}
              accept={acceptedFileTypes ?? { 'Custom Files': ['.svg'] }}
            >
              {dropzoneContent}
            </DropZoneWrapper>
          </Box>
        }
      </Box>
    </Box>
  );
};

export default ImageDropzone;
