/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */

export interface HagPrintRoomJobPriorityItemDto {
  orderItemDecorationId?: number;
  previewUrl?: string | null;
  colorId?: number | null;
  outlineColorId?: number | null;
  personalizationText?: string | null;
  personalizationNumber?: string | null;
  reason?: string | null;
  note?: string | null;
  reasonCreated?: string | null;
  userId?: string | null;
}
