/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */
import type { PrintQueueFilterEnum } from './printQueueFilterEnum';
import type { PrintQueueTableSortQueueByEnum } from './printQueueTableSortQueueByEnum';
import type { SortDirectionEnum } from './sortDirectionEnum';

export type HomefieldApiPrintroomPrintQueueGETParams = { pageNumber?: number; pageSize?: number; status?: PrintQueueFilterEnum; claimedBy?: string; expressProduction?: boolean; sortBy?: PrintQueueTableSortQueueByEnum; sortDirection?: SortDirectionEnum };
