import React, { PureComponent } from 'react';
import Icon from '@sharedComponents/Icons/Icon';

interface OwnProps {
  initialValue?: string;
  search?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  placeholder?: string;
  classes?: string;
  inputClasses?: string;
  searchOnKeyDown?: boolean;
  resetValue?: boolean;
  clearSearch?: () => void;
  onResetValue?: () => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

type Props = OwnProps;

interface State {
  value: string;
}

export default class SearchFilter extends PureComponent<Props, State> {
  state: State = {
    value: this.props.initialValue!,
  };

  static defaultProps = {
    initialValue: '',
    placeholder: 'Search',
    classes: '',
    inputClasses: '',
    searchOnKeyDown: false,
    resetValue: false,
  };

  componentDidUpdate(prevProps: Props) {
    const {
      initialValue,
      resetValue,
      onResetValue,
    } = this.props;

    if (prevProps.initialValue !== initialValue) {
      this.setState(() => ({ value: initialValue! }));
    }

    if (resetValue) {
      this.setState(() => ({
        value: initialValue!,
      }), onResetValue);
    }
  }

  updateValue = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const {
      onChange,
      clearSearch,
    } = this.props;

    const searchValue = e.target.value;
    this.setState({ value: searchValue });

    if (onChange) {
      onChange(e);
    } else if (searchValue === '' && clearSearch) {
      clearSearch();
    }
  };

  onClearSearch = () => {
    const { clearSearch } = this.props;

    this.setState({
      value: '',
    }, clearSearch);
  };

  render() {
    const {
      search,
      placeholder,
      classes,
      inputClasses,
      searchOnKeyDown,
      clearSearch,
    } = this.props;

    const { value } = this.state;

    return (
      <div className={`text-field filter-group ${classes}`}>
        <input
          type='text'
          placeholder={placeholder}
          className={`has-icon ${inputClasses ?? 'search-filter-width'}`}
          onChange={this.updateValue}
          onKeyDown={searchOnKeyDown ? undefined : search}
          value={value || ''}
        />
        <Icon
          materialIcon={'search'}
          classes={'text-field__icon'}
        />
        {
          clearSearch && value &&
          <Icon
            onClick={this.onClearSearch}
            materialIcon={'close'}
            classes={'text-field__icon--right button'}
          />
        }
      </div>
    );
  }
}
