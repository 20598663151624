import React, {
  useCallback,
  useState,
} from 'react';
import { LabelModel } from '@models/common/Label';
import MaterialTooltip from '@sharedComponents/Tooltips/MaterialTooltip';
import Label from '@sharedComponents/Display/Label';
import { TooltipPlacement } from '@customTypes/tooltip';

interface OwnProps {
  labels: LabelModel[];
  tooltipPlacement?: TooltipPlacement;
  labelClasses?: string;
}

type Props = OwnProps;

const LabelsLegendDisplay = React.memo(({
  labels,
  tooltipPlacement = 'top',
  labelClasses = '',
}: Props) => {
  const [
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    legendModalIsOpen,
    setLegendModalIsOpen,
  ] = useState(false);

  const openLegendModal = useCallback(() => {
    setLegendModalIsOpen(true);
  }, []);

  const activeLabels = labels.filter((label) => label.isActive);
  const showAdditionalText = activeLabels.some((label) => label.additionalText);

  return (
    <div>
      <MaterialTooltip
        placement={tooltipPlacement}
        tooltipText={(
          <div>
            <div className='label-legend'>
              {
                activeLabels.map((item, index) => (
                  <Label
                    key={index}
                    text={item.text}
                    type={item.type}
                  />
                ))
              }
            </div>
            {
              showAdditionalText &&
              <div>
                {activeLabels.map((item, index) => <span key={index}>{item.additionalText}</span>)}
              </div>
            }
          </div>
        )}
      >
        <div
          className={`flex button ${labelClasses}`}
          onClick={openLegendModal}
        >
          {
            activeLabels.map((item, index) => (
              <Label
                key={index}
                text={item.shortText || item.text}
                type={item.type}
              />
            ))
          }
        </div>
      </MaterialTooltip>
    </div>
  );
});

export default LabelsLegendDisplay;
