import React, {
  useMemo,
  useEffect,
  useCallback,
} from 'react';
import Grid from '@material-ui/core/Grid';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { useGetVoucherCreationAdmins } from '@api/squadlockerServices/vouchers';
import {
  VoucherCreationAdminViewModel as Admin,
  VoucherCreationLockerViewModel as Locker,
} from '@api/squadlockerServices/models';
import { dropdownSizeL } from '@constants/values';
import { mapToOptionsList } from '@util/mappingHelper';
import InfoField from '@sharedComponents/Display/InfoField';
import DateInput from '@sharedComponents/Inputs/DateInput';
import Dropdown from '@sharedComponents/Inputs/Dropdowns/Dropdown/Dropdown';
import FormError from '@sharedComponents/Form/FormError';

interface OwnProps {
  selectedLockers: Locker[];
  validFromDate: Nullable<string>;
  validToDate: Nullable<string>;
  selectedAdminId: Nullable<number>;
  updateValidFromDate: (value: Nullable<string>) => void;
  updateValidToDate: (value: Nullable<string>) => void;
  updateSelectedAdmin: (value: Nullable<Admin>) => void;
  validToDateError: Nullable<string>;
}

type Props = OwnProps;

const VoucherOrderSettings = React.memo<Props>(({
  selectedLockers,
  validFromDate,
  validToDate,
  selectedAdminId,
  updateValidFromDate,
  updateValidToDate,
  updateSelectedAdmin,
  validToDateError,
}) => {
  const selectedLocker = selectedLockers[0] ?? null;
  const organizationId = selectedLocker?.organizationId ?? undefined;

  const lockerId = !organizationId ? selectedLocker?.id : undefined;
  const organizationInfo = organizationId
    ? `ORG${organizationId} - ${selectedLocker?.organizationName}`
    : null;
  const lockerInfo = organizationId
    ? null
    : `L${selectedLocker?.id} - ${selectedLocker?.name}`;

  const {
    data: adminsData,
    refetch: fetchAdmins,
  } = useGetVoucherCreationAdmins({
    organizationId,
    lockerId,
  });

  useEffect(() => {
    fetchAdmins();
  }, [
    selectedLockers,
    fetchAdmins,
  ]);

  const adminsOptions = useMemo(() => (
    mapToOptionsList<number | string, Admin>({
      list: adminsData,
      key: 'id',
      value: 'id',
      name: (admin) => `${admin.firstName} ${admin.lastName}`,
      emptyOption: {
        name: 'Select Admin',
      },
    })
  ), [adminsData]);

  const onValidFromDateChange = useCallback((date: MaterialUiPickersDate) => {
    updateValidFromDate(date?.toISOString() ?? null);
  }, [updateValidFromDate]);

  const onValidToDateChange = useCallback((date: MaterialUiPickersDate) => {
    updateValidToDate(date?.toISOString() ?? null);
  }, [updateValidToDate]);

  const onAdminSelected = useCallback((value: number | string) => {
    if (typeof value === 'number') {
      const admin = adminsData!.find((a) => a.id === value)!;
      updateSelectedAdmin(admin);
    } else {
      updateSelectedAdmin(null);
    }
  }, [
    updateSelectedAdmin,
    adminsData,
  ]);

  return (
    <>
      <Grid
        container={true}
        item={true}
        xs={12}
        className={'voucherOrders__edit-form__section-title pt-0'}
      >
        <span className='card-title'>Voucher Order Settings</span>
      </Grid>
      <Grid
        container={true}
        item={true}
        xs={12}
        spacing={2}
      >
        <Grid
          item={true}
          xs={6}
        >
          <div>
            <InfoField
              label={(
                <div>
                  <span>Valid From</span>
                  <span className='required'>*</span>
                </div>
              )}
              value={(
                <DateInput
                  value={validFromDate}
                  onChange={onValidFromDateChange}
                  placeholder={'Select Valid From'}
                  width={100}
                />
              )}
            />
          </div>
        </Grid>
        <Grid
          item={true}
          xs={6}
        >
          <div>
            <InfoField
              label={(
                <div>
                  <span>Valid To</span>
                </div>
              )}
              value={(
                <DateInput
                  value={validToDate}
                  onChange={onValidToDateChange}
                  placeholder={'Select Valid To'}
                  width={100}
                />
              )}
            />
            {
              validToDateError &&
              <FormError error={validToDateError} />
            }
          </div>
        </Grid>
      </Grid>

      <Grid
        container={true}
        item={true}
        xs={12}
        spacing={2}
      >
        <Grid
          item={true}
          xs={6}
        >
          <div>
            {
              organizationInfo &&
              <InfoField
                label={'Organization'}
                value={organizationInfo}
              />
            }
            {
              lockerInfo &&
              <InfoField
                label={'Locker'}
                value={lockerInfo}
              />
            }
          </div>
        </Grid>
        <Grid
          item={true}
          xs={6}
        >
          <div>
            <InfoField
              label={(
                <div>
                  <span>Admin</span>
                  <span className='required'>*</span>
                </div>
              )}
              value={(
                <Dropdown<number | string>
                  defaultValue={selectedAdminId ?? undefined}
                  options={adminsOptions}
                  onChange={onAdminSelected}
                  size={dropdownSizeL}
                />
              )}
            />
          </div>
        </Grid>
      </Grid>

    </>
  );
});

export default VoucherOrderSettings;
