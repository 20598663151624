/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */
import type { AccountingActionTypeEnum } from './accountingActionTypeEnum';

export interface CancelOrderItemCommand {
  orderNumber?: number;
  reason?: string | null;
  actionType?: AccountingActionTypeEnum;
  orderItemIds?: number[] | null;
}
