/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Financials Homefield API
 * OpenAPI spec version: v1
 */
import type { NotificationSettingsDto } from './notificationSettingsDto';
import type { CollectionDto } from './collectionDto';

export interface CreateVoucherOrderCommand {
  organizationId?: number | null;
  lockerId?: number | null;
  lockerManagerId?: number;
  startDate?: string;
  endDate?: string | null;
  discountPercent?: number;
  notificationSettings?: NotificationSettingsDto;
  collections?: CollectionDto[] | null;
}
