/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */
import type { PrintQueueStatusEnum } from './printQueueStatusEnum';
import type { PrintQueueStatusEnumStatusModel } from './printQueueStatusEnumStatusModel';
import type { SheetItem } from './sheetItem';
import type { SheetJobDetails } from './sheetJobDetails';

export interface SheetDetailsDto {
  id?: number;
  status?: PrintQueueStatusEnum;
  statusHistory?: PrintQueueStatusEnumStatusModel[] | null;
  items?: SheetItem[] | null;
  claimedBy?: string | null;
  claimedOn?: string | null;
  personalizationJobs?: SheetJobDetails[] | null;
  logoPrintJobs?: SheetJobDetails[] | null;
  logoCutJobs?: SheetJobDetails[] | null;
}
