/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Financials Homefield API
 * OpenAPI spec version: v1
 */

export type PayoutStatusEnum = 'Undefined' | 'Requested' | 'Canceled' | 'Completed';


export const PayoutStatusEnum = {
  Undefined: 'Undefined' as PayoutStatusEnum,
  Requested: 'Requested' as PayoutStatusEnum,
  Canceled: 'Canceled' as PayoutStatusEnum,
  Completed: 'Completed' as PayoutStatusEnum,
};
