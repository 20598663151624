/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */

export interface VendorOrderSubmitResultDto {
  id?: number;
  dateCreatedUtc?: string | null;
  vendorOrderId?: number;
  payload?: string | null;
  userId?: string | null;
  isSuccess?: boolean;
  statusCode?: number;
  elapsedTime?: number;
  message?: string | null;
  result?: string | null;
}
