import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ColorDisplay from '@sharedComponents/Display/ColorDisplay';

const SkuSetupColoredStylesList = React.memo(({
  coloredStyles,
  styleName,
  teamColorsByCode,
}) => (
  <div className='sku-setup__modal--list'>
    {
      coloredStyles.length > 0 &&
      coloredStyles.map((coloredStyle) => {
        const colorGroupTeamColor = teamColorsByCode[coloredStyle.colorGroup];
        const colorATeamColor = teamColorsByCode[coloredStyle.colorA];
        const colorBTeamColor = teamColorsByCode[coloredStyle.colorB];

        return (
          <div
            className='sku-setup__modal--row'
            key={coloredStyle.code}
          >
            <ColorDisplay
              colorName={coloredStyle.colorGroup}
              displayName={false}
              className='mr-10'
              size={'size--small'}
              hideEmptySwatch={true}
              hexValue={colorGroupTeamColor?.hexValue}
            />
            <span className='text-bold mr-5'>{`${coloredStyle.code} - `}</span>
            <span>{`${styleName}`}</span>
            {
              coloredStyle.colorA &&
              <div className='ml-15'>
                <ColorDisplay
                  colorName={coloredStyle.colorA}
                  displayName={true}
                  size={'size--small'}
                  hideEmptySwatch={true}
                  hexValue={colorATeamColor?.hexValue}
                />
              </div>
            }
            {
              coloredStyle.colorB &&
              <div className='ml-15'>
                <ColorDisplay
                  colorName={coloredStyle.colorB}
                  displayName={true}
                  className='ml-10'
                  size={'size--small'}
                  hideEmptySwatch={true}
                  hexValue={colorBTeamColor?.hexValue}
                />
              </div>
            }
          </div>
        );
      })
    }
  </div>
));

SkuSetupColoredStylesList.propTypes = {
  coloredStyles: PropTypes.arrayOf(PropTypes.object).isRequired,
  styleName: PropTypes.string.isRequired,
  teamColorsByCode: PropTypes.objectOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    hexValue: PropTypes.string.isRequired,
    brightness: PropTypes.string,
  })).isRequired,
};

const mapStateToProps = ({ support }) => ({
  teamColorsByCode: support.teamColorsByCode,
});

export default connect(mapStateToProps)(SkuSetupColoredStylesList);
