import React from 'react';
import { Helmet } from 'react-helmet';
import Label from '@sharedComponents/Display/Label';
import {
  useGetHomefieldApiOrdermanagementOrdersOrderNumber,
} from '@api/fulfillment/order-management';


interface OwnProps {
  orderNumber?: number;
}

const OrderManagementHeaderTitle = React.memo(({ orderNumber }: OwnProps) => {
  const {
    data: order,
  } = useGetHomefieldApiOrdermanagementOrdersOrderNumber(orderNumber as unknown as number, {
    query: {
      queryKey: 'ffOrder',
    },
  });

  if (!order || !order?.orderNumber) {
    return <span className='header__bar-content' />;
  }

  const allItemsAreVFI = order?.items?.every((i) => i.vendorFulfilledItem);
  const title = `Order O${order?.orderNumber}`;

  let orderStatus: string | JSX.Element = '';
  if (order?.rush !== null) {
    orderStatus = (
      <Label
        text={'Rushed'}
        type={'status-blue'}
      />
    );
  }
  if (order?.paused !== null) {
    orderStatus = (
      <Label
        text={'Paused'}
        type={'status-orange'}
      />
    );
  }
  if (order?.canceled !== null) {
    orderStatus = (
      <Label
        text={'Canceled'}
        type={'status-darkred'}
      />
    );
  }

  return (
    <div className='header__bar-content'>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className='header__bar-title align__center'>
        <span>{title}</span>
        <span>
          {
            allItemsAreVFI &&
            <Label
              text={'VFI'}
              type={'lynch'}
            />
          }
        </span>
        <span className='orderManagement__status'>
          {orderStatus}
        </span>
      </div>
    </div>
  );
});

export default OrderManagementHeaderTitle;
