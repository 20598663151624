import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  DragDropContext,
  Droppable,
  Draggable,
} from 'react-beautiful-dnd';
import CollectionItemCard from './CollectionItemCard';

// const numberOfBoxesPerRow = 4;
// const rowHeight = 214;

class CollectionItems extends Component {
  onDragEnd = (result) => {
    const {
      collectionItems,
      reorderItems,
    } = this.props;

    if (!result.destination || !result.source) return;
    if (result.destination.index === result.source.index) return;

    const sourceIndex = result.source.index;
    const targetIndex = result.destination.index;

    if (sourceIndex === undefined || targetIndex === undefined) {
      return;
    }

    const items = this.sortCollectionItems(collectionItems);

    const [removedCollectionItem] = items.splice(sourceIndex, 1);
    items.splice(targetIndex, 0, removedCollectionItem);

    const reorderedItems = items.map((item, i) => ({
      ...item,
      orderPlace: i + 1,
    }));

    reorderItems(reorderedItems);
  };

  sortCollectionItems = (collectionItems) => {
    const copyArr = [...collectionItems];

    return copyArr.sort((a, b) => {
      if (a.orderPlace < b.orderPlace) {
        return -1;
      }
      if (a.orderPlace > b.orderPlace) {
        return 1;
      }

      // A must be equal to b
      return 0;
    });
  };

  render() {
    const { collectionItems } = this.props;
    // const gridHeight = Math.ceil(collectionItems.length / 4) * rowHeight;
    const sortedCollectionItems = this.sortCollectionItems(collectionItems);

    return (
      <div>
        <DragDropContext onDragEnd={this.onDragEnd}>
          <Droppable
            droppableId='droppable'
            direction='vertical'
          >
              {(provided) => (
              <div
                ref={provided.innerRef}
                className=''
                {...provided.droppableProps}
              >
                {
                  sortedCollectionItems.map((item, index) => (
                    <Draggable
                      key={(item?.id) || `e${index}`}
                      draggableId={(item?.id?.toString()) || `e${index}`}
                      index={index}
                      isDragDisabled={!item || (item && (!item.id || item.id <= 0))}
                    >
                      {
                        (draggableProvided) => (
                          <div
                            ref={draggableProvided.innerRef}
                            {...draggableProvided.draggableProps}
                            {...draggableProvided.dragHandleProps}
                            // className='organization__featured-lockers__card-wrapper'
                          >
                            {
                              item?.id
                                ? (
                                  <CollectionItemCard item={item} />
                                )
                                : item
                            }
                          </div>
                        )
                      }
                    </Draggable>
                  ))
                }
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    );
  }
}

CollectionItems.propTypes = {
  reorderItems: PropTypes.func.isRequired,
  collectionItems: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default CollectionItems;
