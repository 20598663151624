/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Accounts Homefield API
 * OpenAPI spec version: 2.0
 */
import type { PartnerDto } from './partnerDto';

export interface PartnerDtoApiResponse {
  success?: boolean;
  message?: string | null;
  result?: PartnerDto;
}
