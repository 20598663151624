import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Column } from 'react-table-6';
import { connect, ConnectedProps } from 'react-redux';
import { VoucherNotificationLinkDestinationEnum, VoucherNotificationSenderNameEnum, VoucherOrderDetailDto, VoucherOrderPaymentStatusEnum } from '@api/financialServices/models';
import {
  useGetHomefieldApiVouchersOrders as useGetVoucherOrdersPage,
  usePostHomefieldApiVouchersOrderssendinvoice as useSendVoucherOrderInvoice,
  usePutHomefieldApiVouchersOrderscancel,
  usePutHomefieldApiVouchersOrdersVoucherOrderId as useUpdateVoucherOrder,
} from '@api/financialServices/voucher-homefield';
import { voucherOrderStatuses } from '@constants/options/optionsValues';
import { keyNameEnum, sortDirectionEnum } from '@constants/enums/commonEnums';
import { defaultPageSizeSmallTable, pageSizeOptionsSmallTable } from '@constants/values';
import { addVoucherOrderUrl, voucherOrderDetailsUrl } from '@constants/clientUrls/clientUrls';
import { TableEnum } from '@constants/enums/tableEnums';
import { SortDirectionLong } from '@customTypes/table';
import { RootState } from '@redux/index/reducers';
import { parseDateNumeric } from '@util/dateHandler';
import { getSelectableTableRowProps } from '@util/selectionHelpers';
import { materialSwal } from '@util/componentHelper';
import { getPagingParamsFromTable, getSortParamsFromTable } from '@util/tableHelpers';
import { roundFloat } from '@util/numberHelpers';
import EditVoucherOrderModal from '@components/VoucherOrders/Modals/EditVoucherOrderModal';
import { VoucherOrderFormData } from '@components/VoucherOrders/Forms/VoucherOrderForm';
import Table from '@sharedComponents/Table/Table';
import SearchFilter from '@sharedComponents/Inputs/SearchFilter';
import StatusFilter from '@sharedComponents/Inputs/Dropdowns/StatusFilter';
import DateInput from '@sharedComponents/Inputs/DateInput';
import ShowMoreTooltip from '@sharedComponents/Display/ShowMoreTooltip';
import ButtonLink from '@sharedComponents/Navigation/ButtonLink';
import TableFilters from '@sharedComponents/Display/TableFilters/TableFilters';
import Link from '@sharedComponents/Navigation/Link';
import TextButton from '@sharedComponents/Buttons/TextButton';
import MasterDetailContainer from '@sharedComponents/Display/Containers/MasterDetailContainer';
import SimpleConfirmationModal from '@sharedComponents/Modal/SimpleConfirmationModal';
import VoucherOrdersQuickView from './VoucherOrdersQuickView';
import VoucherOrderActions from './VoucherOrderActions';
import VoucherOrderStatusLabel from '@components/VoucherOrders/Shared/VoucherOrderStatusLabel';
import VoucherOrderCancelWithRefundModal, { VoucherOrderCancelWithRefundForm } from './Modals/VoucherOrderCancelWithRefundModal';
import { ApiOk } from '@api/squadlockerServices/models';

const VoucherOrdersTable = Table<VoucherOrderDetailDto>();

const mapStateToProps = ({ tableManager }: RootState) => ({
  initialPageNumber: tableManager.voucherOrders.pageNumber,
  initialPageSize: tableManager.voucherOrders.pageSize,
  initialSortColumn: tableManager.voucherOrders.sortColumn,
  initialSortDirection: tableManager.voucherOrders.sortDirection,
  initialSearchInput: tableManager.voucherOrders.searchInput,
  initialMinValidThrough: tableManager.voucherOrders.minValidThrough,
  initialMaxValidThrough: tableManager.voucherOrders.maxValidThrough,
});

const connector = connect(mapStateToProps);

type Props = ConnectedProps<typeof connector>;

const VoucherOrders = React.memo<Props>(({
  initialPageNumber,
  initialPageSize,
  initialSortColumn,
  initialSortDirection,
  initialSearchInput,
  initialMinValidThrough,
  initialMaxValidThrough,
}) => {
  const [
    selectedVoucherOrder,
    setSelectedVoucherOrder,
  ] = useState<Nullable<VoucherOrderDetailDto>>(null);
  const [
    editVoucherOrderModalIsOpen,
    setEditVoucherOrderModalIsOpen,
  ] = useState<boolean>(false);
  const [
    sendVoucherOrderInvoiceModalIsOpen,
    setSendVoucherOrderInvoiceModalIsOpen,
  ] = useState<boolean>(false);
  const [
    cancelVoucherOrderModalIsOpen,
    setCancelVoucherOrderModalIsOpen,
  ] = useState<boolean>(false);

  const [
    pageNumber,
    setPageNumber,
  ] = useState<number>(initialPageNumber);
  const [
    pageSize,
    setPageSize,
  ] = useState<number>(initialPageSize);
  const [
    sortColumn,
    setSortColumn,
  ] = useState<string>(initialSortColumn);
  const [
    sortDirection,
    setSortDirection,
  ] = useState<SortDirectionLong>(initialSortDirection as SortDirectionLong);
  const [
    searchInput,
    setSearchInput,
  ] = useState<string>(initialSearchInput);
  const [
    minValidThrough,
    setMinValidThrough,
  ] = useState<Nullable<Date>>(initialMinValidThrough);
  const [
    maxValidThrough,
    setMaxValidThrough,
  ] = useState<Nullable<Date>>(initialMaxValidThrough);
  const [
    paymentStatus,
    setPaymentStatus,
  ] = useState<VoucherOrderPaymentStatusEnum[]>([]);

  const {
    data: voucherOrdersData,
    refetch: fetchVoucherOrders,
  } = useGetVoucherOrdersPage({
    PageNumber: pageNumber,
    PageSize: pageSize,
    Search: searchInput,
    PaymentStatus: paymentStatus,
    ValidFrom: minValidThrough?.toISOString(),
    ValidUntil: maxValidThrough?.toISOString(),
  });

  const unselectVoucherOrder = useCallback(() => {
    setSelectedVoucherOrder(null);
  }, []);

  const closeSendVoucherOrderInvoiceModal = useCallback(() => {
    setSendVoucherOrderInvoiceModalIsOpen(false);
    unselectVoucherOrder();
  }, [unselectVoucherOrder]);

  const onSendVoucherOrderInvoice = (response: ApiOk) => {
    materialSwal('Success', response.message, 'success');
    closeSendVoucherOrderInvoiceModal();
  };

  const closeVoucherOrderEditModal = useCallback(() => {
    setEditVoucherOrderModalIsOpen(false);
    unselectVoucherOrder();
  }, [unselectVoucherOrder]);

  const onUpdateVoucherOrder = (response: ApiOk) => {
    materialSwal('Success', response.message, 'success');
    fetchVoucherOrders();
    closeVoucherOrderEditModal();
  };

  const { mutateAsync: sendVoucherOrderInvoice } = useSendVoucherOrderInvoice({
    mutation: {
      onSuccess: onSendVoucherOrderInvoice,
    },
  });

  const { mutateAsync: updateVoucherOrder } = useUpdateVoucherOrder({
    mutation: {
      onSuccess: onUpdateVoucherOrder,
    },
  });

  const onStatusChange = useCallback((statuses) => {
    setPaymentStatus(statuses);
  }, []);

  const fetchData = useCallback((state, instance) => {
    const {
      page: newPageNumber,
      pageSize: newPageSize,
    } = getPagingParamsFromTable(instance);

    const {
      sortColumn: newSortColumn,
      sortDirection: newSortDirection,
    } = getSortParamsFromTable(instance, sortDirectionEnum, sortColumn, sortDirection);

    setPageNumber(newPageNumber + 1);
    setPageSize(newPageSize);
    setSortColumn(newSortColumn);
    setSortDirection(newSortDirection);
  }, [
    sortColumn,
    sortDirection,
  ]);

  useEffect(() => {
    fetchVoucherOrders();
  }, [
    fetchVoucherOrders,
    pageNumber,
    pageSize,
    searchInput,
    paymentStatus,
    minValidThrough,
    maxValidThrough,
  ]);

  const selectVoucherOrder = useCallback((voucherOrder: VoucherOrderDetailDto) => {
    setSelectedVoucherOrder(voucherOrder);
  }, []);

  const getTrProps = useCallback(
    (state, rowInfo) => getSelectableTableRowProps(selectVoucherOrder, rowInfo, selectedVoucherOrder, 'id'),
    [
      selectVoucherOrder,
      selectedVoucherOrder,
    ],
  );

  const search = useCallback((value) => {
    setSearchInput(value);
  }, []);

  const clearSearch = useCallback(() => {
    setSearchInput('');
  }, []);

  const filterKey = useCallback((e) => {
    if (e.key && e.key !== keyNameEnum.Enter) {
      return;
    }
    e.preventDefault();
    e.stopPropagation();

    search(e.target.value);
  }, [search]);

  const handleMinValidThroughFilter = useCallback((date) => {
    if (date === '') {
      console.info('date is empty');
      return;
    }

    setMinValidThrough(date);
  }, []);

  const handleMaxValidThroughFilter = useCallback((date) => {
    if (date === '') {
      console.info('date is empty');
      return;
    }

    setMaxValidThrough(date);
  }, []);

  const clearDateFilter = useCallback(() => {
    setMinValidThrough(null);
    setMaxValidThrough(null);
  }, []);

  const openSendVoucherOrderInvoiceModal = useCallback(() => {
    setSendVoucherOrderInvoiceModalIsOpen(true);
  }, []);

  const openCancelVoucherOrderModal = useCallback(() => {
    setCancelVoucherOrderModalIsOpen(true);
  }, []);

  const closeCancelVoucherOrderModal = useCallback(() => {
    setCancelVoucherOrderModalIsOpen(false);
  }, []);

  const sendVoucherOrderInvoiceEmail = useCallback(() => {
    sendVoucherOrderInvoice({
      data: { voucherOrderId: selectedVoucherOrder!.id! },
    });
    closeSendVoucherOrderInvoiceModal();
  }, [
    sendVoucherOrderInvoice,
    selectedVoucherOrder,
    closeSendVoucherOrderInvoiceModal,
  ]);

  const openVoucherOrderEditModal = useCallback(() => {
    setEditVoucherOrderModalIsOpen(true);
  }, []);

  const editVoucherOrder = useCallback(async (voucherOrderForm: VoucherOrderFormData) => {
    await updateVoucherOrder({
      voucherOrderId: voucherOrderForm.voucherOrderId,
      data: {
        voucherOrderId: voucherOrderForm.voucherOrderId,
        startDate: voucherOrderForm.startDate,
        endDate: voucherOrderForm.endDate,
        lockerManagerId: voucherOrderForm.lockerManagerId,
        notificationSettings: {
          sendDate: voucherOrderForm.notificationSettings.sendDate!,
          linkDestination: voucherOrderForm.notificationSettings!.linkDestination! as VoucherNotificationLinkDestinationEnum,
          senderName: voucherOrderForm.notificationSettings!.senderName! as VoucherNotificationSenderNameEnum,
          customSenderName: voucherOrderForm.notificationSettings!.customSenderName!,
        },
      },
    });
  }, [updateVoucherOrder]);

  const onEditVoucherOrder = useCallback((voucherOrder: VoucherOrderDetailDto) => {
    selectVoucherOrder(voucherOrder);
    openVoucherOrderEditModal();
  }, [
    selectVoucherOrder,
    openVoucherOrderEditModal,
  ]);

  const onResendPaymentNotification = useCallback((voucherOrder: VoucherOrderDetailDto) => {
    selectVoucherOrder(voucherOrder);
    openSendVoucherOrderInvoiceModal();
  }, [
    openSendVoucherOrderInvoiceModal,
    selectVoucherOrder,
  ]);

  const onCancelVoucherOrder = useCallback((voucherOrder: VoucherOrderDetailDto) => {
    selectVoucherOrder(voucherOrder);
    openCancelVoucherOrderModal();
  }, [
    selectVoucherOrder,
    openCancelVoucherOrderModal,
  ]);

  const onVoucherOrderCanceled = (response: ApiOk) => {
    materialSwal('Success', response.message, 'success');
    closeCancelVoucherOrderModal();
    fetchVoucherOrders();
  };

  const { mutateAsync: cancelVoucherOrder } = usePutHomefieldApiVouchersOrderscancel({
    mutation: {
      onSuccess: onVoucherOrderCanceled,
    },
  });

  const onVoucherOrderCancel = useCallback(async (form: VoucherOrderCancelWithRefundForm) => {
    await cancelVoucherOrder({
      data: {
        id: selectedVoucherOrder?.id,
        amount: roundFloat(form.amount),
      },
    });
    setSelectedVoucherOrder(null);
  }, [
    cancelVoucherOrder,
    selectedVoucherOrder,
  ]);

  const renderVoucherCollection = useCallback((vc) => (
    <span
      key={vc.id}
      className='align__center'
    >
      {vc.rosterTeamName} - {vc.collectionName}
    </span>
  ), []);

  const getColumns = useCallback((): Array<Column<VoucherOrderDetailDto>> => {
    const columns: Array<Column<VoucherOrderDetailDto>> = [
      {
        Header: 'ID',
        sortable: true,
        minWidth: 80,
        Cell: (cellProps) => (
          <div className='flex align__center'>
            <Link
              url={voucherOrderDetailsUrl(cellProps.original.id)}
              text={`V${cellProps.original.id}`}
            />
            {cellProps.original.cancellationId
              && <VoucherOrderStatusLabel voucherOrder={cellProps.original} />}
          </div>
        ),
      },
      {
        Header: 'Organization/Locker',
        minWidth: 120,
        sortable: true,
        Cell: (cellProps) => (
          <div>
            <div>
              <div>
                {cellProps.original.organizationName
                  ? `ORG${cellProps.original.organizationId} - ${cellProps.original.organizationName}`
                  : `L${cellProps.original.lockerId} - ${cellProps.original.lockerName}`}
              </div>
            </div>
          </div>
        ),
      },
      {
        Header: 'Collections',
        accessor: 'voucherCollections',
        sortable: true,
        minWidth: 100,
        Cell: (cellProps) => {
          const voucherCollections = cellProps.value as VoucherOrderDetailDto['voucherCollections'];

          return (
            <div>
              {voucherCollections
                && (
                  <div>
                    <div>
                      <span className='align__center'>
                        {voucherCollections.length} Collection
                        <ShowMoreTooltip
                          tooltipText={<>{voucherCollections.map(renderVoucherCollection)}</>}
                        />
                      </span>
                    </div>
                  </div>
                )}
            </div>
          );
        },
      },
      {
        Header: 'Valid From',
        sortable: false,
        minWidth: 70,
        Cell: (cellProps) => (
          <div>
            <span>
              {parseDateNumeric(cellProps.original.startDate)}
            </span>
          </div>
        ),
      },
      {
        Header: 'Valid Until',
        sortable: false,
        minWidth: 70,
        Cell: (cellProps) => (
          <div>
            <span>
              {`${cellProps.original.endDate ? parseDateNumeric(cellProps.original.endDate) : '-'}`}
            </span>
          </div>
        ),
      },
      {
        Header: 'Payment Status',
        accessor: 'paymentStatus',
        sortable: false,
        minWidth: 85,
        Cell: (cellProps) => (
          <div className='uppercase'>
            {cellProps.value === VoucherOrderPaymentStatusEnum.Paid
              ? <span className='status-label green'>Paid</span>
              : <span className='status-label red'>Unpaid</span>}
          </div>
        ),
      },
      {
        Header: 'Redeemed',
        sortable: false,
        minWidth: 60,
        Cell: (cellProps) => (
          <div>
            <div>
              <div>
                <span>{cellProps.original.redeemedInstanceCount}/{cellProps.original.totalInstanceCount}</span>
              </div>
            </div>
          </div>
        ),
      },
      {
        Header: '',
        width: 50,
        resizable: false,
        accessor: '',
        style: { overflow: 'visible' },
        Cell: (cellProps) => (
          <VoucherOrderActions
            order={cellProps.value}
            onEditVoucherOrder={onEditVoucherOrder}
            onResendPaymentNotification={onResendPaymentNotification}
            onCancelVoucherOrder={onCancelVoucherOrder}
          />
        ),
      },
    ];

    return columns;
  }, [
    renderVoucherCollection,
    onEditVoucherOrder,
    onResendPaymentNotification,
    onCancelVoucherOrder,
  ]);

  const columns = useMemo(() => getColumns(), [getColumns]);
  const placeholderDate = parseDateNumeric(new Date());

  const filtersToPreserve = useMemo(() => ({
    searchInput,
    minValidThrough,
    maxValidThrough,
  }), [
    searchInput,
    minValidThrough,
    maxValidThrough,
  ]);

  return (
    <div className='container'>
      <SimpleConfirmationModal
        isOpen={sendVoucherOrderInvoiceModalIsOpen}
        closeModal={closeSendVoucherOrderInvoiceModal}
        title={'Confirmation'}
        cancelBtnText={'Cancel'}
        confirm={sendVoucherOrderInvoiceEmail}
        confirmationBody={(
          <span>
            Confirming this action will result in a voucher payment link being sent to the selected admin &nbsp;
            <b>
              {selectedVoucherOrder?.lockerManagerFirstName} {selectedVoucherOrder?.lockerManagerLastName}
            </b>
            &nbsp;for <b>{selectedVoucherOrder?.organizationName ?? selectedVoucherOrder?.lockerName}</b>.
          </span>
        )}
      />
      <VoucherOrderCancelWithRefundModal
        isOpen={cancelVoucherOrderModalIsOpen}
        cancelAll={true}
        voucherOrder={selectedVoucherOrder}
        vouchersToCancel={null}
        closeModal={closeCancelVoucherOrderModal}
        onSubmit={onVoucherOrderCancel}
      />
      <EditVoucherOrderModal
        isOpen={editVoucherOrderModalIsOpen}
        closeModal={closeVoucherOrderEditModal}
        editVoucherOrder={editVoucherOrder}
        voucherOrder={selectedVoucherOrder}
      />
      <div className='flex'>
        <TableFilters
          leftGroup={(
            <>
              <SearchFilter
                initialValue={searchInput}
                search={filterKey}
                clearSearch={clearSearch}
              />
              <StatusFilter
                options={voucherOrderStatuses}
                filter={onStatusChange}
                selected={paymentStatus}
              />
              <div className='scheduling__datepicker'>
                <DateInput
                  value={minValidThrough}
                  maxDate={maxValidThrough ?? '3000-01-01'}
                  label={'Valid From'}
                  onChange={handleMinValidThroughFilter}
                  onError={handleMinValidThroughFilter}
                  placeholder={placeholderDate}
                />
              </div>
              <div className='scheduling__datepicker'>
                <DateInput
                  value={maxValidThrough}
                  minDate={minValidThrough ?? '1900-01-01'}
                  label={'Valid Until'}
                  onChange={handleMaxValidThroughFilter}
                  onError={handleMaxValidThroughFilter}
                  placeholder={placeholderDate}
                />
              </div>
              {(maxValidThrough || minValidThrough)
                && (
                  <TextButton
                    text='Clear date'
                    type='primary'
                    classes='scheduling__filter--clear'
                    onClick={clearDateFilter}
                  />
                )}
            </>
          )}
          rightGroup={(
            <div className='flex'>
              <ButtonLink
                type={'primary'}
                text={'Add Voucher Order'}
                linkTo={addVoucherOrderUrl}
                classes={'w-100'}
                linkClasses={'w-100 mb-15'}
              />
            </div>
          )}
        />
      </div>

      <MasterDetailContainer
        masterComponent={(
          <div className='sheet'>
            <VoucherOrdersTable
              data={voucherOrdersData?.items ?? []}
              columns={columns}
              onFetchData={fetchData}
              getTrProps={getTrProps}
              totalPages={voucherOrdersData?.totalPages}
              defaultPageSize={defaultPageSizeSmallTable}
              pageSizeOptions={pageSizeOptionsSmallTable}
              hasNextPage={voucherOrdersData?.hasNextPage}
              hasPreviousPage={voucherOrdersData?.hasPreviousPage}
              tableId={TableEnum.voucherOrders}
              preserveState={true}
              sortDirEnum={sortDirectionEnum}
              initialPageNumber={initialPageNumber}
              initialPageSize={initialPageSize}
              initialSortColumn={initialSortColumn}
              initialSortDirection={initialSortDirection}
              filtersToPreserve={filtersToPreserve}
            />
          </div>
        )}
        detailComponent={(
          <VoucherOrdersQuickView
            voucherOrder={selectedVoucherOrder}
            openVoucherOrderEditModal={openVoucherOrderEditModal}
            closeDetails={unselectVoucherOrder}
          />
        )}
        showDetailComponent={!!selectedVoucherOrder}
      />
    </div>
  );
});

export default connector(VoucherOrders);
