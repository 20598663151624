import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { uploadLogoToAdminLogos } from '@APICalls/lockerManager/actions';
import { dragAndDropMessage } from '@constants/values';
import {
  fetchLockerInfo,
  fetchFulfillmentLockerInfo,
} from '@redux/lockerManager/actions';
import { materialSwal } from '@util/componentHelper';
import UploadLogoSwatchColors from '@sharedComponents/UploadLogoSwatchColors/UploadLogoSwatchColors';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import Modal from '@sharedComponents/Modal/Modal';
import Button from '@sharedComponents/Buttons/Button';
import Icon from '@sharedComponents/Icons/Icon';
import DropZoneWrapper from '@sharedComponents/Upload/DropZoneWrapper';
import AdminLogo from './Logos/AdminLogo/AdminLogo';
import AssignLogosToItems from './AssignLogosToItems/AssignLogosToItems';
import Pagination from '@components/shared/Pagination';

const PAGE_SIZE = 25;

class LogoBankAdminLogos extends Component {
  state = {
    isExpanded: true,
    uploadToLogoBankModalIsOpen: false,
    assignLogosToItemsModalIsOpen: false,
    attachedFile: null,
    loading: false,
    uploadLogoResponse: null,
    swatchModalIsOpen: false,
    message: {
      type: 'default',
      body: dragAndDropMessage(),
    },
    logoData: '',
    pageNumber: 1,
  };

  componentDidMount() {
    const {
      lockerId,
      dispatch,
    } = this.props;

    dispatch(fetchLockerInfo(lockerId));
    dispatch(fetchFulfillmentLockerInfo(lockerId));
    this.updateLogos();
  }

  componentDidUpdate(prevProps) {
    const { logos } = this.props;

    if (prevProps.logos !== logos && logos) {
      this.updateLogos();
    }
  }

  changeExpand = () => {
    this.setState((prevState) => ({ isExpanded: !prevState.isExpanded }));
  };

  openUploadToLogoBankModal = () => {
    this.setState({ uploadToLogoBankModalIsOpen: true });
  };

  closeUploadToLogoBankModal = () => {
    this.setState({ uploadToLogoBankModalIsOpen: false });
  };

  openAssignLogosToItemsModal = () => {
    this.setState({ assignLogosToItemsModalIsOpen: true });
  };

  closeAssignLogosToItemsModal = () => {
    this.setState({ assignLogosToItemsModalIsOpen: false });
  };

  closeSwatchModal = () => {
    this.setState({
      swatchModalIsOpen: false,
      uploadLogoResponse: null,
    });
  };

  uploadLogo = async () => {
    const {
      lockerId,
      refreshLogos,
    } = this.props;

    const { attachedFile } = this.state;

    const res = await uploadLogoToAdminLogos(lockerId, attachedFile);
    if (res?.success) {
      materialSwal('Success', 'Logo was uploaded', 'success');
      this.clearFileFromState();
      refreshLogos();
      this.setState({
        uploadToLogoBankModalIsOpen: false,
        uploadLogoResponse: res,
        swatchModalIsOpen: true,
      });
    }
  };

  onDrop = (acceptedFiles, rejectedFiles) => {
    const destroyFiles = () => {
      for (const file of acceptedFiles.concat(rejectedFiles)) {
        window.URL.revokeObjectURL(file.preview);
      }
    };

    this.setState({
      message: {
        body: `File ${acceptedFiles[0].name} has been attached.`,
        type: 'default',
      },
      attachedFile: acceptedFiles,
    }, destroyFiles);
  };

  clearFileFromState = () => {
    this.setState({
      attachedFile: null,
      message: {
        type: 'default',
        body: dragAndDropMessage(),
      },
    });
  };

  updateLogos = (scrollTop) => {
    const { pageNumber } = this.state;

    const {
      logos,
      lockerId,
      openModalWithImage,
      refreshLogos,
    } = this.props;

    if (!logos) return;

    const start = (pageNumber - 1) * PAGE_SIZE;
    const end = pageNumber * PAGE_SIZE;

    logos.sort((a, b) => {
      if (a < b) {
        return -1;
      }

      if (a > b) {
        return 1;
      }

      return 0;
    });

    const logoData = logos.slice(start, end).map((logo, key) => (
      <AdminLogo
        logo={logo}
        key={key}
        keyProp={key}
        lockerId={lockerId}
        openModalWithImage={openModalWithImage}
        refreshLogos={refreshLogos}
        scrollTop={scrollTop}
      />
    ));

    this.setState({ logoData });
  };

  onManagerLogosScroll = (event) => {
    this.updateLogos(event.target.scrollTop);
  };

  handleChangePage = (pn) => {
    this.setState({ pageNumber: pn }, this.updateLogos);
  };

  render() {
    const {
      logos,
      lockerId,
    } = this.props;

    const {
      uploadToLogoBankModalIsOpen,
      assignLogosToItemsModalIsOpen,
      message,
      loading,
      logoData,
    } = this.state;

    const dropzoneContent = loading
      ? (
        <label className='button'>
          <Icon
            fontAwesomeIcon={'spinner'}
            classes={'fa-spin'}
          />
          Uploading...
        </label>
      )
      : <label className={`button message-${message.type}`}>{message.body}</label>;

    const {
      uploadLogoResponse,
      swatchModalIsOpen,
      pageNumber,
    } = this.state;

    return (
      <div>
        <AssignLogosToItems
          modalIsOpen={assignLogosToItemsModalIsOpen}
          closeModal={this.closeAssignLogosToItemsModal}
          lockerId={lockerId}
          multipleLogos={true}
          logos={logos}
        />

        {
          uploadLogoResponse?.success &&
          <UploadLogoSwatchColors
            logoId={uploadLogoResponse.result}
            cancel={this.closeSwatchModal}
            isModalOpen={swatchModalIsOpen}
          />
        }

        <Modal
          title={'Upload Logo to Your Logo Bank'}
          modalSize={'xl'}
          isOpen={uploadToLogoBankModalIsOpen}
          closeModal={this.closeUploadToLogoBankModal}
          buttons={(
            <ModalButtons
              confirmBtnText={'Upload logo'}
              cancelBtnText={'Cancel'}
              onConfirm={this.uploadLogo}
              onClose={this.closeUploadToLogoBankModal}
            />
          )}
        >
          <div className='logo-bank__container'>
            <div className='upload-field'>
              <DropZoneWrapper
                onDrop={this.onDrop}
                className={'block-drop-zone'}
                multiple={false}
              >
                {dropzoneContent}
              </DropZoneWrapper>
            </div>
          </div>
        </Modal>

        <div className='logo-bank__container logo-bank__container-full'>
          <div className='header'>
            <span>
              Admin Logos
            </span>
            <div>
              <Button
                type={'secondary'}
                text={'Assign Logos to Items'}
                onClick={this.openAssignLogosToItemsModal}
              />
              <Button
                type={'secondary'}
                text={'Upload to Admin Logo Bank'}
                onClick={this.openUploadToLogoBankModal}
                classes={'ml-10'}
              />
            </div>
          </div>
          <div
            className='logo-bank__manager--logos'
            onScroll={this.onManagerLogosScroll}
          >
            {logoData}
          </div>
          <div className='pagination'>
            <Pagination
              totalPages={Math.ceil(logos.length / PAGE_SIZE)}
              pageNumber={pageNumber}
              selectPage={this.handleChangePage}
              range={5}
            />
          </div>
        </div>
      </div>
    );
  }
}

LogoBankAdminLogos.propTypes = {
  logos: PropTypes.array,
  lockerId: PropTypes.number,
  openModalWithImage: PropTypes.func,
  refreshLogos: PropTypes.func.isRequired,
};

export default connect()(LogoBankAdminLogos);
