/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */

export type OrderPrintStatusEnum = 'Undefined' | 'PrepressPending' | 'PrepressReady' | 'PrintReady' | 'Printing' | 'Printed' | 'PrintAssembly' | 'Done';


export const OrderPrintStatusEnum = {
  Undefined: 'Undefined' as OrderPrintStatusEnum,
  PrepressPending: 'PrepressPending' as OrderPrintStatusEnum,
  PrepressReady: 'PrepressReady' as OrderPrintStatusEnum,
  PrintReady: 'PrintReady' as OrderPrintStatusEnum,
  Printing: 'Printing' as OrderPrintStatusEnum,
  Printed: 'Printed' as OrderPrintStatusEnum,
  PrintAssembly: 'PrintAssembly' as OrderPrintStatusEnum,
  Done: 'Done' as OrderPrintStatusEnum,
};
