/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */

export type PtlWallRowPositionStatusEnum = 'Empty' | 'Full';


export const PtlWallRowPositionStatusEnum = {
  Empty: 'Empty' as PtlWallRowPositionStatusEnum,
  Full: 'Full' as PtlWallRowPositionStatusEnum,
};
