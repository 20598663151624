/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */
import type { PrintAssemblyQueueDto } from './printAssemblyQueueDto';

export interface PrintAssemblyQueueDtoPagedList {
  pageNumber?: number;
  pageSize?: number;
  totalCount?: number;
  items?: PrintAssemblyQueueDto[] | null;
  readonly totalPages?: number;
  readonly hasPreviousPage?: boolean;
  readonly hasNextPage?: boolean;
}
