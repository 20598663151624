import { fulfillmentApi } from '../../sqdApis';
import weedingAndMaskingUrls from '@constants/sqdApiUrls/weedingAndMaskingUrls';
import { weedingAndMaskingEnum } from '@constants/enums/artworkEnums';
import * as actionTypes from './types';
import { makeApiCall } from '@util/apiHelper';

export const updateSheetAndOrder = (sheetId, orderNumber) => ({
  type: actionTypes.UPDATE_SHEET_AND_ORDER,
  payload: {
    sheetId,
    orderNumber,
  },
});

export const updateCurrentQueue = (data) => ({
  type: actionTypes.UPDATE_CURRENT_QUEUE,
  payload: data,
});

export const clearQueue = () => ({
  type: actionTypes.CLEAR_QUEUE,
});

export const updateOrdersList = (data) => ({
  type: actionTypes.UPDATE_ORDERS_LIST,
  payload: data,
});

export const updateCheckInventory = (data) => ({
  type: actionTypes.CHECK_INVENTORY,
  payload: data,
});

export const updateCounts = (data) => ({
  type: actionTypes.UPDATE_COUNTS,
  payload: data,
});

export const clearOrdersList = () => ({
  type: actionTypes.CLEAR_ORDERS_LIST,
});

export const fetchQueue = (sheetId, status, pageNumber = 1, pageSize = 10, filterOrderId = '', sortBy = '', sortDirection = 'Ascending') => async (dispatch) => {
  const call = fulfillmentApi.get(weedingAndMaskingUrls.weedingAndMaskingGetData(
    sheetId,
    status,
    pageNumber,
    pageSize,
    sortBy,
    sortDirection,
    filterOrderId
  ), { handleBlockingLoading: false });

  const data = await makeApiCall(call);

  data.sortBy = sortBy;
  data.sortDirection = sortDirection;
  await dispatch(updateCurrentQueue(data));

  return data;
};

export const getTabCounts = (sheetId, orderNumber) => async (dispatch) => {
  const promises = [];
  const counts = {};

  promises.push(fulfillmentApi.get(weedingAndMaskingUrls.weedingAndMaskingGetData(
    sheetId,
    weedingAndMaskingEnum.Initial,
    1,
    1,
    '',
    '',
    orderNumber
  ), { handleBlockingLoading: false }).then((data) => {
    if (data?.totalCount) {
      counts.initial = data.totalCount;
    } else {
      counts.initial = 0;
    }

    return data;
  }));

  promises.push(fulfillmentApi.get(weedingAndMaskingUrls.weedingAndMaskingGetData(
    sheetId,
    weedingAndMaskingEnum.Done,
    1,
    1,
    '',
    '',
    orderNumber
  ), { handleBlockingLoading: false })
    .then((data) => {
      if (data?.totalCount) {
        counts.done = data.totalCount;
      } else {
        counts.done = 0;
      }

      return data;
    }));

  try {
    await Promise.all(promises);
    await dispatch(updateCounts(counts));
  } catch (err) {
    console.error(err);
  }
};

export const checkInventory = (orderNumber, sheetId) => async (dispatch) => {
  const call = fulfillmentApi.get(weedingAndMaskingUrls.checkInventory(orderNumber, sheetId), {
    handleBlockingLoading: false,
  });
  const res = await makeApiCall(call);
  if (res) {
    dispatch(updateCheckInventory(res));
  }
};

export const fetchOrdersList = (sheetId) => async (dispatch) => {
  const call = fulfillmentApi.get(weedingAndMaskingUrls.weedingAndMaskingOrdersList(sheetId), {
    handleBlockingLoading: false,
  });
  const res = await makeApiCall(call);
  if (res) {
    dispatch(updateOrdersList(res));
  }
};
