import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  editProductEssential,
  deleteProducts,
  importItemsToLocker,
  undecorateProduct,
} from '@APICalls/lockerManager/actions';
import {
  addCollection,
  addItemsToCollection,
  removeItemsFromCollection,
} from '@APICalls/collections/actions';
import { refreshCachedImage } from '@APICalls/cachedImages/actions';
import { decorationTypeEnum } from '@constants/enums/decorationEnums';
import { lockerMgrS3Logos } from '@constants/common';
import { decorationMethodsOptions } from '@constants/options/optionsValues';
import {
  customItemStatusEnum,
  productTypeEnum,
} from '@constants/enums/lockerEnums';
import {
  sortDirectionShortEnum,
  keyNameEnum,
} from '@constants/enums/commonEnums';
import { lockerItemBulkActionsEnum } from '@constants/enums/bulkActionsEnum';
import * as lockerManagerActions from '@redux/lockerManager/actions';
import * as artworkFileTasksActions from '@redux/artworkFileTasks/actions';
import * as productCatalogActions from '@redux/productCatalog/actions';
import * as supportActions from '@redux/support/actions';
import {
  getPagingParamsFromTable,
  getSortParamsFromTable,
} from '@util/tableHelpers';
import {
  isSelectedItem,
  selectAllItems,
  selectItem,
} from '@util/selectionHelpers';
import { materialSwal } from '@util/componentHelper';
import { formatPrice } from '@util/numberHelpers';
import { parseDateTimeNumeric } from '@util/dateHandler';
import AddCollectionModal from '@components/Collections/CollectionModals/AddCollectionModal';
import AddItemsToExistingCollectionModal from '@components/Collections/CollectionModals/AddItemsToExistingCollectionModal';
import MaterialCheckbox from '@sharedComponents/Inputs/MaterialCheckbox';
import MultiSelectDropdown from '@sharedComponents/Inputs/Dropdowns/MultiSelectDropdown/MultiSelectDropdown';
import TitleDescriptionCell from '@sharedComponents/LockerItems/LockerItemsTableContent/TitleDescriptionCell';
import HeaderCell from '@sharedComponents/Table/TableCells/HeaderCell';
import Table from '@sharedComponents/Table/Table';
import Icon from '@sharedComponents/Icons/Icon';
import SearchFilter from '@sharedComponents/Inputs/SearchFilter';
import LogoDropdown from '@sharedComponents/LogoDisplay/LogoDropdown/LogoDropdown';
import AddCustomItemModal from '@sharedComponents/CustomItems/Modals/AddCustomItemModal';
import EditCustomItemModal from '@sharedComponents/CustomItems/Modals/EditCustomItemModal';
import LockerManagerDetailsTableLogos from '@sharedComponents/LockerItems/LockerItemsTableContent/TableLogos';
import SimpleConfirmationModal from '@sharedComponents/Modal/SimpleConfirmationModal';
import ImportItemModal from '../../LockerManagerModals/ImportItemModal/ImportItemModal';
import DeleteProductsModal from '../../LockerManagerModals/DeleteProductsModal';
import LockerManagerAddProductsModal from '../../LockerManagerModals/LockerManagerAddProducts/LockerManagerAddProductsModal';
import AddColorsToProductsModal from '../../LockerManagerModals/AddColorsToProductsModal/AddColorsToProductsModal';
import LockerManagerCreateArtworkTasksModal from '../../LockerManagerModals/LockerManagerCreateArtworkTasksModal';
import ItemDecorations from './LockerItemsTableContent/ItemDecorations';
import ItemImage from './LockerItemsTableContent/ItemImage';
import ItemLocationAndSizeCell from './LockerItemsTableContent/ItemLocationAndSizeCell';
import ItemPersonalizationCell from './LockerItemsTableContent/ItemPersonalizationCell';
import ItemActionColumn from './LockerItemsTableContent/ItemActionColumn';
import RemoveItemsFromCollection from '../../Collections/CollectionModals/RemoveItemsFromCollection';
import LockerItemsActionButton from './LockerItemsActionButton';

const ItemsTable = Table();

const mapStateToProps = ({
  lockerManager,
  productCatalog,
  support,
}) => ({
  queue: lockerManager.currentProductQueue.queue,
  pageNumber: lockerManager.currentProductQueue.pageNumber,
  pageSize: lockerManager.currentProductQueue.pageSize,
  sortOrder: lockerManager.currentQueue.sortOrder,
  sortByState: lockerManager.currentQueue.sortByState,
  totalPages: lockerManager.currentProductQueue.totalPages,
  hasPreviousPage: lockerManager.currentProductQueue.hasPreviousPage,
  hasNextPage: lockerManager.currentProductQueue.hasNextPage,
  lockerLogos: lockerManager.lockerLogos,
  lockerColorCodes: lockerManager.lockerColors,
  allDecorationLocations: productCatalog.decorationLocations,
  coloredStyleProductsDictionary: productCatalog.coloredStyleProductsDictionary,
  teamColors: support.teamColors,
});

const mapDispatchToProps = {
  fetchLockerManagerProducts: lockerManagerActions.fetchLockerManagerProducts,
  fetchLockerColors: lockerManagerActions.fetchLockerColors,
  fetchLockerLogos: lockerManagerActions.fetchLockerLogos,
  triageArtworkTasks: artworkFileTasksActions.triageArtworkTasks,
  getDecorationLocations: productCatalogActions.getDecorationLocations,
  getDecorationLocationArtworkSizes: productCatalogActions.getDecorationLocationArtworkSizes,
  getProductsForColoredStylesDictionary: productCatalogActions.getProductsForColoredStylesDictionary,
  fetchColors: supportActions.fetchColors,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

class LockerItemsTable extends PureComponent {
  state = {
    isPageSelected: false,
    selectedItem: null,
    selectedItems: [],
    pageNumber: this.props.pageNumber,
    pageSize: this.props.pageSize,
    sortByState: '',
    sortOrderState: '',
    selectedLogos: [],
    colorFilter: [],
    decorationFilter: '',
    searchInput: '',
    refreshingImages: false,
    imagesResolved: {},
    productsRefreshing: [],
    showOnlyCustomItems: false,
    showOnlyMerchandisedItems: false,
    lockerColors: this.props.teamColors?.filter((c) => (this.props.lockerColorCodes || []).includes(c.code)),

    // Modals
    addProductModalIsOpen: false,
    addCustomItemModalIsOpen: false,
    editCustomItemModalIsOpen: false,
    importItemModalIsOpen: false,
    deleteModalIsOpen: false,
    addColorsToProductsModalIsOpen: false,
    addCollectionModalIsOpen: false,
    addItemsToExistingCollectionModalIsOpen: false,
    removeItemsFromCollectionModalIsOpen: false,
    artworkTasksCreationModalIsOpen: false,
    refreshTableConfirmationModalIsOpen: false,
  };

  componentDidMount() {
    const {
      lockerId,
      fetchLockerColors,
      fetchLockerLogos,
      getDecorationLocations,
      getDecorationLocationArtworkSizes,
      fetchColors,
    } = this.props;

    fetchLockerColors(lockerId);
    fetchLockerLogos(lockerId);
    getDecorationLocations();
    getDecorationLocationArtworkSizes();
    fetchColors();

    this.getProductsForItems();
  }

  componentDidUpdate(prevProps) {
    const {
      collectionId,
      teamColors,
      lockerColorCodes,
      queue,
    } = this.props;
    if (collectionId !== prevProps.collectionId) {
      this.search();
    }

    if (teamColors
      && lockerColorCodes
      && (prevProps.lockerColorCodes !== lockerColorCodes
        || prevProps.teamColors !== teamColors)) {
      const lockerColors = teamColors.filter((c) => lockerColorCodes.includes(c.code));
      this.setState(() => ({ lockerColors }));
    }

    if (prevProps.queue !== queue) {
      this.getProductsForItems();
    }
  }

  getProductsForItems = () => {
    const {
      queue,
      coloredStyleProductsDictionary,
      getProductsForColoredStylesDictionary,
    } = this.props;

    const existingColoredStyleIds = coloredStyleProductsDictionary ? Object.keys(coloredStyleProductsDictionary) : [];

    const coloredStyleIds = [];
    for (const item of queue) {
      const { coloredStyleId } = item;

      if (existingColoredStyleIds.includes(coloredStyleId)) continue;
      if (coloredStyleIds.includes(coloredStyleId)) continue;

      coloredStyleIds.push(coloredStyleId);
    }

    if (coloredStyleIds.length) {
      getProductsForColoredStylesDictionary(coloredStyleIds);
    }
  };

  fetchData = (state, instance) => {
    const {
      sortByState,
      sortOrderState,
    } = this.state;

    const {
      page,
      pageSize,
    } = getPagingParamsFromTable(instance);

    const {
      sortColumn,
      sortDirection,
    } = getSortParamsFromTable(instance, sortDirectionShortEnum, sortByState, sortOrderState);

    this.setState(() => ({
      pageNumber: page + 1,
      pageSize,
      sortByState: sortColumn,
      sortOrderState: sortDirection,
    }), this.search);
  };

  search = () => {
    const {
      lockerId,
      collectionId,
      fetchLockerManagerProducts,
    } = this.props;

    const {
      colorFilter,
      decorationFilter,
      selectedLogos,
      pageNumber,
      pageSize,
      sortByState,
      sortOrderState,
      showOnlyCustomItems,
      showOnlyMerchandisedItems,
      searchInput,
    } = this.state;

    const productType = showOnlyCustomItems ? productTypeEnum.Custom : productTypeEnum.All;

    return fetchLockerManagerProducts(
      pageNumber,
      pageSize,
      lockerId,
      sortByState,
      sortOrderState,
      colorFilter,
      decorationFilter,
      searchInput,
      selectedLogos,
      productType,
      collectionId,
      false,
      showOnlyMerchandisedItems
    );
  };

  clearSearch = () => {
    this.setState(() => ({
      searchInput: '',
    }), this.search);
  };

  filterKey = (e) => {
    if (e.key && e.key !== keyNameEnum.Enter) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();

    const searchInput = e.target.value;

    this.setState(() => ({
      searchInput,
    }), this.search);
  };

  colorChange = (colors) => {
    if (colors) {
      this.setState(() => ({
        colorFilter: colors,
      }), this.search);
    }
  };

  logosSelectOnChange = (logos) => {
    this.setState(() => ({
      selectedLogos: logos,
    }), this.search);
  };

  decorationChange = (decorationFilter) => {
    if (!decorationFilter) {
      return;
    }

    this.setState(() => ({
      decorationFilter,
    }), this.search);
  };

  toggleShowOnlyCustomItems = () => {
    this.setState((prevState) => ({
      showOnlyCustomItems: !prevState.showOnlyCustomItems,
    }), this.search);
  };

  toggleShowOnlyMerchandisedItems = () => {
    this.setState((prevState) => ({
      showOnlyMerchandisedItems: !prevState.showOnlyMerchandisedItems,
    }), this.search);
  };

  openDeleteProductsModal = () => {
    this.setState(() => ({ deleteModalIsOpen: true }));
  };

  confirmDeleteSingleItem = (product) => {
    this.setState(() => ({
      selectedItems: [product],
      deleteModalIsOpen: true,
    }));
  };

  closeDeleteProductsModal = () => {
    this.setState(() => ({ deleteModalIsOpen: false }));
  };

  openAddProductModal = () => {
    this.setState(() => ({ addProductModalIsOpen: true }));
  };

  closeAddProductModal = () => {
    this.setState(() => ({ addProductModalIsOpen: false }));
  };

  openAddCustomItemModal = () => {
    this.setState(() => ({ addCustomItemModalIsOpen: true }));
  };

  closeAddCustomItemModal = () => {
    this.setState(() => ({ addCustomItemModalIsOpen: false }));
  };

  openImportItemModal = () => {
    this.setState(() => ({ importItemModalIsOpen: true }));
  };

  closeImportItemModal = () => {
    this.setState(() => ({ importItemModalIsOpen: false }));
  };

  openAddColorsToProductsModal = () => {
    this.setState(() => ({ addColorsToProductsModalIsOpen: true }));
  };

  closeAddColorsToProductsModal = () => {
    this.setState(() => ({
      selectedItems: [],
      addColorsToProductsModalIsOpen: false,
    }));
  };

  openAddCollectionModal = () => {
    this.setState(() => ({ addCollectionModalIsOpen: true }));
  };

  closeAddCollectionModal = () => {
    this.setState(() => ({ addCollectionModalIsOpen: false }));
  };

  openAddItemsToExistingCollectionModal = () => {
    this.setState(() => ({ addItemsToExistingCollectionModalIsOpen: true }));
  };

  closeAddItemsToExistingCollectionModal = () => {
    this.setState(() => ({ addItemsToExistingCollectionModalIsOpen: false }));
  };

  openRemoveItemsFromCollectionModal = () => {
    this.setState(() => ({ removeItemsFromCollectionModalIsOpen: true }));
  };

  closeRemoveItemsFromCollectionModal = () => {
    this.setState(() => ({ removeItemsFromCollectionModalIsOpen: false }));
  };

  openEditCustomItemModal = (selectedItem) => {
    this.setState(() => ({
      editCustomItemModalIsOpen: true,
      selectedItem,
    }));
  };

  closeEditCustomItemModal = () => {
    this.setState(() => ({
      editCustomItemModalIsOpen: false,
      selectedItem: null,
    }));
  };

  openArtworkTasksCreationModal = async (selectedItem) => {
    const {
      lockerId,
      triageArtworkTasks,
      coloredStyleProductsDictionary,
    } = this.props;

    let models = [];

    const products = coloredStyleProductsDictionary[selectedItem.coloredStyleId];
    const sku = products.length ? products[0].sku : undefined;

    if (selectedItem.logos.length > 0) {
      models = selectedItem.logos.map((l) => ({
        sku,
        logoUrl: `${lockerMgrS3Logos}/${l.image}`,
        logoId: l.id,
        lockerId,
        decorationLocation: selectedItem.decoration_location,
        decorationMethod: selectedItem.decorationMethod,
        decorationSizeHeight: (selectedItem.isLockerItem && l.decorationHeight) ? l.decorationHeight : null,
        decorationSizeWidth: (selectedItem.isLockerItem && l.decorationWidth) ? l.decorationWidth : null,
      }));
    } else if (selectedItem.decorations.length > 0) {
      const logoDecorations = selectedItem.decorations.filter((l) => l.type === decorationTypeEnum.Logo);

      models = logoDecorations.map((l) => ({
        sku,
        logoUrl: `${lockerMgrS3Logos}/${l.logoImage}`,
        logoId: l.logoId,
        lockerId,
        decorationLocation: l.decorationLocation,
        decorationMethod: selectedItem.decorationMethod,
        decorationSizeHeight: (selectedItem.isLockerItem && l.decorationHeight) ? l.decorationHeight : null,
        decorationSizeWidth: (selectedItem.isLockerItem && l.decorationWidth) ? l.decorationWidth : null,
      }));
    }

    await triageArtworkTasks(models, null);

    this.setState(() => ({
      artworkTasksCreationModalIsOpen: true,
      selectedItem,
    }));
  };

  closeArtworkTasksCreationModal = () => {
    this.setState(() => ({
      artworkTasksCreationModalIsOpen: false,
      selectedItem: null,
    }));
  };

  openRefreshTableConfirmationModal = () => {
    this.setState(() => ({ refreshTableConfirmationModalIsOpen: true }));
  };

  closeRefreshTableConfirmationModal = () => {
    this.setState(() => ({ refreshTableConfirmationModalIsOpen: false }));
  };

  handleRefreshTableModalConfirm = () => {
    this.search();
    materialSwal('Success', 'Table refreshed.', 'success');

    this.closeRefreshTableConfirmationModal();
  };

  importItems = async (selectedItems, importLogos) => {
    const { lockerId } = this.props;
    const res = await importItemsToLocker(lockerId, selectedItems, importLogos);
    if (res?.success) {
      this.search();
      materialSwal('Success', res.message, 'success');

      return res;
    }
  };

  deleteProducts = async () => {
    const { selectedItems } = this.state;
    const { lockerId } = this.props;

    const res = await deleteProducts(selectedItems, lockerId);
    if (res?.success) {
      this.search();
      this.closeDeleteProductsModal();
      this.clearSelection();
      materialSwal('Success', res.message, 'success');
    }
  };

  changeCustomItemStatus = (customItem, hide = false) => {
    const { lockerId } = this.props;

    customItem.customItemIsHidden = hide;

    return lockerManagerActions.updateCustomItem(customItem, lockerId, customItem.coloredStyleId);
  };

  hideCustomItem = (customItem) => {
    const { lockerId } = this.props;

    customItem.customItemIsHidden = customItem.status !== customItemStatusEnum.Hidden;

    return lockerManagerActions.updateCustomItem(customItem, lockerId, customItem.coloredStyleId);
  };

  undecorateProduct = async (product) => {
    try {
      const res = await undecorateProduct(product);

      if (res?.success) {
        this.search();
        materialSwal('Success', res.message, 'success');
      }
    } catch (err) { console.error(err); }
  };

  refreshCachedImages = async (products) => {
    const refreshingTasks = [];
    for (const product of products) {
      if (!product.isLockerItem) {
        const { productsRefreshing } = this.state;

        productsRefreshing.push(product.id);
        this.setState(() => ({ productsRefreshing }));
        // eslint-disable-next-line
        const call = new Promise(async (resolve, reject) => {
          const imageUrl = await refreshCachedImage(product.id);
          if (imageUrl) {
            const { imagesResolved } = this.state;

            imagesResolved[product.id] = imageUrl;
            this.setState(() => ({ imagesResolved }));
          }
          resolve(imageUrl);
        });
        refreshingTasks.push(call);
      }
    }
    await Promise.all(refreshingTasks);

    this.setState(() => ({
      refreshingImages: false,
      productsRefreshing: [],
      imagesResolved: [],
    }));

    this.openRefreshTableConfirmationModal();
  };

  addCollection = async (addCollectionForm) => {
    const { onCollectionsChange } = this.props;

    const res = await addCollection(addCollectionForm);

    if (res?.success) {
      this.clearSelection();
      this.search();

      if (onCollectionsChange) {
        onCollectionsChange();
      }

      materialSwal('Success', res?.message, 'success');
      this.closeAddCollectionModal();
    }
  };

  addItemsToCollection = async (addItemsToCollectionForm) => {
    const res = await addItemsToCollection(addItemsToCollectionForm);

    if (res?.success) {
      this.clearSelection();
      this.search();

      materialSwal('Success', res.message, 'success');
      this.closeAddItemsToExistingCollectionModal();
    }
  };

  removeItemsFromCollection = async (collectionId, items) => {
    const res = await removeItemsFromCollection(collectionId, items);

    if (res?.success) {
      this.clearSelection();
      this.search();

      materialSwal('Success', res.message, 'success');
      this.closeRemoveItemsFromCollectionModal();
    }
  };

  onSelectBulkAction = async (selectedBulkAction) => {
    switch (selectedBulkAction) {
      case lockerItemBulkActionsEnum.deleteProducts:
        this.onBulkDeleteProducts();
        break;
      case lockerItemBulkActionsEnum.changeEssential:
        await this.onBulkChangeEssential();
        break;
      case lockerItemBulkActionsEnum.refreshCachedImage:
        this.onBulkRefreshCachedImages();
        break;
      case lockerItemBulkActionsEnum.hideCustomItems:
        await this.onBulkHideCustomItems();
        break;
      case lockerItemBulkActionsEnum.unhideCustomItems:
        await this.onBulkUnhideCustomItems();
        break;
      case lockerItemBulkActionsEnum.addColorsToProducts:
        this.onBulkAddColorsToProducts();
        break;
      case lockerItemBulkActionsEnum.newCollection:
        this.onBulkNewCollection();
        break;
      case lockerItemBulkActionsEnum.addToExistingCollection:
        this.onBulkAddItemsToExistingCollection();
        break;
      case lockerItemBulkActionsEnum.removeItemsFromCollection:
        this.onBulkRemoveItemsFromCollection();
        break;
    }
  };

  onBulkDeleteProducts = () => {
    this.setState(() => ({ deleteModalIsOpen: true }));
  };

  onBulkRefreshCachedImages = () => {
    const { selectedItems } = this.state;

    this.setState(() => ({
      refreshingImages: true,
    }), () => this.refreshCachedImages(selectedItems));

    this.clearSelection();
  };

  onBulkChangeEssential = async () => {
    const { selectedItems } = this.state;

    const updateTasks = [];
    for (const item of selectedItems) {
      updateTasks.push(editProductEssential(item));
    }
    await Promise.all(updateTasks);

    this.clearSelection();
    this.search();
  };

  onBulkHideCustomItems = async () => {
    await this.onBulkChangeCustomItemStatus(true);
  };

  onBulkUnhideCustomItems = async () => {
    await this.onBulkChangeCustomItemStatus(false);
  };

  onBulkChangeCustomItemStatus = async (hide = false) => {
    const { selectedItems } = this.state;

    const updateTasks = [];
    for (const item of selectedItems) {
      updateTasks.push(this.changeCustomItemStatus(item, hide));
    }
    await Promise.all(updateTasks);

    this.clearSelection();
    this.search();
  };

  onBulkAddColorsToProducts = () => {
    this.openAddColorsToProductsModal();
  };

  onBulkNewCollection = () => {
    this.openAddCollectionModal();
  };

  onBulkAddItemsToExistingCollection = () => {
    this.openAddItemsToExistingCollectionModal();
  };

  onBulkRemoveItemsFromCollection = () => {
    this.openRemoveItemsFromCollectionModal();
  };

  getBulkActions = () => {
    const {
      selectedItems,
      refreshingImages,
    } = this.state;

    const { getBulkActions } = this.props;

    return getBulkActions(selectedItems, !refreshingImages);
  };

  getColumns = () => {
    const {
      productsRefreshing,
      imagesResolved,
    } = this.state;

    const { collectionId } = this.props;

    const columns = [
      {
        Header: 'Image',
        accessor: 'cached_image',
        className: 'no-padding',
        minWidth: 50,
        Cell: (cellProps) => (
          <ItemImage
            item={cellProps.original}
            resolvedUrl={imagesResolved[cellProps.original.id]}
            productsRefreshing={productsRefreshing}
          />
        ),
      },
      {
        Header: <HeaderCell text={'Code & Description'} />,
        accessor: '',
        key: 'image_front',
        sortable: true,
        minWidth: 120,
        Cell: (cellProps) => (
          <TitleDescriptionCell
            item={cellProps.value}
            showLabels={true}
            showCopyUuidButton={true}
          />
        ),
      },
      ...(collectionId
        ? [
          {
            Header: 'Quantity',
            accessor: '',
            width: 95,
            Cell: (cellProps) => {
              const currentCollection = cellProps.original.collectionsList
                ?.find((collection) => collection.id === collectionId);

              return currentCollection?.itemQuantity ? currentCollection.itemQuantity : '-';
            },
          },
        ]
        : []
      ),
      {
        Header: 'Color',
        accessor: 'color',
        minWidth: 65,
      },
      {
        Header: 'Method',
        accessor: 'decoration_method',
        width: 90,
      },
      {
        Header: 'Location & Size',
        accessor: '',
        minWidth: 70,
        Cell: (cellProps) => <ItemLocationAndSizeCell item={cellProps.value} />,
      },
      {
        Header: 'Personalization',
        accessor: '',
        minWidth: 80,
        Cell: (cellProps) => <ItemPersonalizationCell item={cellProps.value} />,
      },
      {
        Header: 'Price',
        accessor: 'price',
        width: 85,
        Cell: (cellProps) => cellProps.value ? `${formatPrice(cellProps.value)}` : '-',
      },
      {
        Header: <HeaderCell text={'Orders'} />,
        accessor: 'times_ordered',
        minWidth: 40,
        sortable: true,
        Cell: (cellProps) => cellProps.value ? cellProps.value : '-',
      },
      {
        Header: <HeaderCell text={'Last Update'} />,
        accessor: 'updated_at',
        minWidth: 60,
        sortable: true,
        Cell: (cellProps) => cellProps.value ? parseDateTimeNumeric(cellProps.value) : '-',
      },
      {
        Header: '',
        width: 30,
        resizable: false,
        accessor: '',
        style: { overflow: 'visible' },
        Cell: (cellProps) => (
          <ItemActionColumn
            product={cellProps.value}
            openDeleteModal={this.confirmDeleteSingleItem}
            openEditCustomItemModal={this.openEditCustomItemModal}
            openArtworkTasksCreationModal={this.openArtworkTasksCreationModal}
            refreshAction={this.search}
            hideCustomItem={this.hideCustomItem}
            undecorateProduct={this.undecorateProduct}
          />
        ),
      },
      {
        expander: true,
        Header: '',
        width: 65,
        Expander: ({ isExpanded }) => (
          <div>
            {
              isExpanded
                ? (
                  <Icon
                    materialIcon={'arrow_drop_up'}
                    classes={'button'}
                  />
                )
                : (
                  <Icon
                    materialIcon={'arrow_drop_down'}
                    classes={'button'}
                  />
                )
            }
          </div>
        ),
      },
    ];

    return columns;
  };

  getSubComponent = (cell) => {
    const {
      allDecorationLocations,
      lockerId,
    } = this.props;

    return (
      cell.original.isLockerItem
        ? (
          <ItemDecorations
            item={cell.original}
            allDecorationLocations={allDecorationLocations}
          />
        )
        : (
          <LockerManagerDetailsTableLogos
            item={cell.original}
            lockerId={lockerId}
            search={this.search}
            allDecorationLocations={allDecorationLocations}
          />
        )
    );
  };

  areEqualItems = (item1, item2) => item1.id === item2.id && item1.isLockerItem === item2.isLockerItem;

  isSelectedItem = (item) => {
    const { selectedItems } = this.state;

    return isSelectedItem(selectedItems, item, this.areEqualItems);
  };

  selectItem = (item, isSelected) => {
    const {
      selectedItems,
      isPageSelected,
    } = this.state;

    const { queue } = this.props;

    const {
      newSelectedItems,
      newSelectAll,
    } = selectItem(queue, selectedItems, isPageSelected, item, isSelected, this.areEqualItems);

    this.setState(() => ({
      selectedItems: newSelectedItems,
      isPageSelected: newSelectAll,
    }));
  };

  selectAllItems = () => {
    const { queue } = this.props;
    const {
      isPageSelected,
      selectedItems,
    } = this.state;

    const {
      newSelectedItems,
      newSelectAll,
    } = selectAllItems(queue, selectedItems, isPageSelected, this.areEqualItems);

    this.setState(() => ({
      selectedItems: newSelectedItems,
      isPageSelected: newSelectAll,
    }));
  };

  clearSelection = () => {
    this.updateSelection([], false);
  };

  updateSelection = (newSelectedItems, newIsPageSelected) => {
    this.setState(() => ({
      selectedItems: newSelectedItems,
      isPageSelected: newIsPageSelected,
    }));
  };

  render() {
    const {
      queue,
      totalPages,
      lockerId,
      collectionId,
      lockerLogos,
      hasNextPage,
      hasPreviousPage,
      displayLockerItemsActionBtn,
    } = this.props;

    const {
      selectedItem,
      selectedItems,
      isPageSelected,
      deleteModalIsOpen,
      addProductModalIsOpen,
      addCustomItemModalIsOpen,
      editCustomItemModalIsOpen,
      addColorsToProductsModalIsOpen,
      showOnlyCustomItems,
      showOnlyMerchandisedItems,
      importItemModalIsOpen,
      addCollectionModalIsOpen,
      addItemsToExistingCollectionModalIsOpen,
      removeItemsFromCollectionModalIsOpen,
      artworkTasksCreationModalIsOpen,
      refreshTableConfirmationModalIsOpen,
      lockerColors,
    } = this.state;

    const bulkActions = this.getBulkActions();
    const columns = this.getColumns();

    return (
      <div>
        <DeleteProductsModal
          isOpen={deleteModalIsOpen}
          closeModal={this.closeDeleteProductsModal}
          products={selectedItems}
          deleteProducts={this.deleteProducts}
        />
        <LockerManagerAddProductsModal
          lockerId={lockerId}
          isOpen={addProductModalIsOpen}
          closeModal={this.closeAddProductModal}
          refresh={this.search}
        />
        <AddCustomItemModal
          isOpen={addCustomItemModalIsOpen}
          lockerId={lockerId}
          closeModal={this.closeAddCustomItemModal}
          refresh={this.search}
        />
        <EditCustomItemModal
          isOpen={editCustomItemModalIsOpen}
          lockerId={lockerId}
          closeModal={this.closeEditCustomItemModal}
          customItem={selectedItem}
          refresh={this.search}
        />
        <ImportItemModal
          isOpen={importItemModalIsOpen}
          closeModal={this.closeImportItemModal}
          lockerId={parseInt(lockerId)}
          importItems={this.importItems}
        />
        <AddColorsToProductsModal
          isOpen={addColorsToProductsModalIsOpen}
          closeModal={this.closeAddColorsToProductsModal}
          selectedItems={selectedItems}
          lockerId={parseInt(lockerId)}
          refresh={this.search}
        />
        <AddCollectionModal
          isOpen={addCollectionModalIsOpen}
          addCollection={this.addCollection}
          closeModal={this.closeAddCollectionModal}
          lockerId={parseInt(lockerId)}
          items={selectedItems}
        />
        <AddItemsToExistingCollectionModal
          isOpen={addItemsToExistingCollectionModalIsOpen}
          closeModal={this.closeAddItemsToExistingCollectionModal}
          addItems={this.addItemsToCollection}
          items={selectedItems}
          lockerId={parseInt(lockerId)}
        />
        <SimpleConfirmationModal
          isOpen={refreshTableConfirmationModalIsOpen}
          confirm={this.handleRefreshTableModalConfirm}
          closeModal={this.closeRefreshTableConfirmationModal}
          title={'Refresh Table'}
          confirmationBody={'Images refresh call ended, would you like to refresh the table?'}
        />
        <RemoveItemsFromCollection
          isOpen={removeItemsFromCollectionModalIsOpen}
          closeModal={this.closeRemoveItemsFromCollectionModal}
          removeItems={this.removeItemsFromCollection}
          collectionId={collectionId}
          items={selectedItems}
        />
        <LockerManagerCreateArtworkTasksModal
          lockerId={lockerId}
          isOpen={artworkTasksCreationModalIsOpen}
          closeModal={this.closeArtworkTasksCreationModal}
          refresh={this.openArtworkTasksCreationModal}
          product={selectedItem}
        />

        <div className='lockerManagerEdit__filters'>
          <div className='lockerManagerEdit__filters-fields'>
            <SearchFilter
              search={this.filterKey}
              clearSearch={this.clearSearch}
            />
            <LogoDropdown
              logos={lockerLogos}
              updateCallback={this.logosSelectOnChange}
              className={'margin-left'}
            />
            <MultiSelectDropdown
              objects={lockerColors}
              itemText={'colors'}
              updateCallback={this.colorChange}
              textKey={'code'}
              valueKey={'code'}
              classNames={'margin-left'}
              color={true}
            />
            <MultiSelectDropdown
              objects={decorationMethodsOptions}
              itemText={'decorations'}
              updateCallback={this.decorationChange}
              textKey={'name'}
              valueKey={'value'}
              classNames={'margin-left'}
            />
            <MaterialCheckbox
              disabled={false}
              text={'Only Custom Items'}
              checked={showOnlyCustomItems}
              onClick={this.toggleShowOnlyCustomItems}
            />
            <MaterialCheckbox
              disabled={false}
              text={'Only SquadLocker\'s Picks'}
              checked={showOnlyMerchandisedItems}
              onClick={this.toggleShowOnlyMerchandisedItems}
            />
          </div>

          {
            displayLockerItemsActionBtn &&
            <LockerItemsActionButton
              lockerId={parseInt(lockerId)}
              openAddProductModal={this.openAddProductModal}
              openAddCustomItemModal={this.openAddCustomItemModal}
              openImportItemModal={this.openImportItemModal}
            />
          }
        </div>

        <div className='sheet'>
          <ItemsTable
            data={queue}
            columns={columns}
            showPagination={hasNextPage || hasPreviousPage}
            totalPages={totalPages}
            onFetchData={this.fetchData}
            isBulkActionMode={selectedItems && selectedItems.length > 0}
            subComponent={this.getSubComponent}

            selectable={true}
            selectPredicateOrKey={this.areEqualItems}
            updateSelection={this.updateSelection}
            selectedData={selectedItems}
            isPageSelected={isPageSelected}
            bulkActionsList={bulkActions}
            onSelectBulkAction={this.onSelectBulkAction}
          />
        </div>
      </div>
    );
  }
}

LockerItemsTable.propTypes = {
  totalPages: PropTypes.number.isRequired,
  hasPreviousPage: PropTypes.bool,
  hasNextPage: PropTypes.bool,
  pageNumber: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  queue: PropTypes.arrayOf(PropTypes.object).isRequired,
  sortOrder: PropTypes.string,
  sortByState: PropTypes.string,
  lockerId: PropTypes.number.isRequired,
  collectionId: PropTypes.number,
  lockerLogos: PropTypes.array.isRequired,
  lockerColorCodes: PropTypes.arrayOf(PropTypes.string).isRequired,
  allDecorationLocations: PropTypes.array.isRequired,
  onCollectionsChange: PropTypes.func,
  getBulkActions: PropTypes.func.isRequired,
  displayLockerItemsActionBtn: PropTypes.bool,
  teamColors: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    hexValue: PropTypes.string,
    brightness: PropTypes.string.isRequired,
  })).isRequired,
};

LockerItemsTable.defaultProps = {
  displayLockerItemsActionBtn: false,
};

export default connector(LockerItemsTable);
