import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bulkUpdateColoredStyles } from '@APICalls/productCatalog/actions';
import { uploadFile } from '@APICalls/support/actions';
import { uploadFile as uploadFileUrl } from '@constants/sqdApiUrls/productCatalogUtilityUrls';
import { catalogApi } from '../../sqdApis';
import {
  modalStyleBig,
  dragAndDropMessage,
} from '@constants/values';
import { materialSwal } from '@util/componentHelper';
import Modal from '@sharedComponents/Modal/Modal';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import Icon from '@sharedComponents/Icons/Icon';
import DropZoneWrapper from '@sharedComponents/Upload/DropZoneWrapper';
import SkuSetupColoredStylesList from './SkuSetupColoredStylesList';

const defaultMessage = dragAndDropMessage('grid file');
const acceptedFileType = { 'Custom Files': ['.lgrd'] };

class SkuSetupBulkGridUploadModal extends Component {
  state = {
    imageUrlGrid: null,
    loadingGridFile: false,
    messageGridFile: {
      type: 'default',
      body: defaultMessage,
    },
  };

  onCloseModal = () => {
    const { closeModal } = this.props;

    this.setState({
      imageUrlGrid: null,
      loadingGridFile: false,
      messageGridFile: {
        type: 'default',
        body: defaultMessage,
      },
    });

    closeModal();
  };

  onDropGridImg = async (acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length > 0) {
      this.setState({
        messageGridFile: {
          type: 'reject',
          body: `${acceptedFileType} files required.`,
        },
      });
    } else {
      this.setState({
        messageGridFile: {
          body: `File ${acceptedFiles[0].name} has been attached.`,
          type: 'default',
        },
        loadingGridFile: true,
      }, async () => {
        const res = await uploadFile(acceptedFiles[0], catalogApi, uploadFileUrl);
        if (res) {
          this.setState({ imageUrlGrid: res });
        }
        this.setState({ loadingGridFile: false });
      });
    }
  };

  removeGridFile = () => {
    this.setState({
      imageUrlGrid: null,
      messageGridFile: {
        type: 'default',
        body: `${defaultMessage}`,
      },
    });
  };

  assignGridFile = async () => {
    const {
      coloredStyles,
      closeModal,
      refresh,
    } = this.props;
    const { imageUrlGrid } = this.state;

    const res = await bulkUpdateColoredStyles(coloredStyles, 'imageUrlGrid', imageUrlGrid);

    if (res) {
      materialSwal('Success', 'Grid file successfully assigned to selected colored styles', 'success');
    } else {
      materialSwal('Error', 'Something went wrong. Please try again.', 'error');
    }

    this.setState({
      imageUrlGrid: null,
      loadingGridFile: false,
      messageGridFile: {
        type: 'default',
        body: defaultMessage,
      },
    });

    refresh();
    closeModal();
  };

  render() {
    const {
      coloredStyles,
      isOpen,
      styleName,
    } = this.props;

    const {
      imageUrlGrid,
      loadingGridFile,
      messageGridFile,
    } = this.state;

    const dropzoneContentGridFile = loadingGridFile
      ? (
        <label className='button'>
          <Icon
            fontAwesomeIcon={'spinner'}
            classes={'fa-spin'}
          />
          Uploading...
        </label>
      )
      : <label className={`button message-${messageGridFile.type}`}>{messageGridFile.body}</label>;

    return (
      <Modal
        title={'Assign Grid File'}
        modalSize={'l'}
        isOpen={isOpen}
        closeModal={this.onCloseModal}
        modalStyleProps={modalStyleBig}
        buttons={(
          <ModalButtons
            confirmBtnText={'Assign'}
            cancelBtnText={'Cancel'}
            onConfirm={this.assignGridFile}
            confirmBtnDisabled={!imageUrlGrid}
            onClose={this.onCloseModal}
          />
        )}
      >
        <div>
          <div className='sku-setup__modal--text'>
            {`Assign a grid file to the selected colored ${coloredStyles.length === 1 ? 'style' : 'styles'}:`}
          </div>
          <SkuSetupColoredStylesList
            coloredStyles={coloredStyles}
            styleName={styleName}
          />
          <div>
            <div className='catalog-detail__modal--container mb-30'>
              <label className='redux-form__label'>
                Product Grid File
              </label>
              {
                imageUrlGrid
                  ? (
                    <div className='flex-center'>
                      <Icon materialIcon={'attach_file'} />
                      <a
                        href={imageUrlGrid}
                        className='catalog-details__link'
                      >
                        {imageUrlGrid}
                      </a>
                      <Icon
                        materialIcon={'cancel'}
                        onClick={this.removeGridFile}
                        classes={'catalog-details__remove'}
                      />
                    </div>
                  )
                  : (
                    <div className='redux-form__row'>
                      <div className='upload-field--horizontal m-t-16 w-100'>
                        <div className='upload-field'>
                          <DropZoneWrapper
                            onDrop={this.onDropGridImg}
                            className={'block-drop-zone'}
                            multiple={false}
                            accept={acceptedFileType}
                          >
                            {dropzoneContentGridFile}
                          </DropZoneWrapper>
                        </div>
                      </div>
                    </div>
                  )
              }
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

SkuSetupBulkGridUploadModal.propTypes = {
  coloredStyles: PropTypes.arrayOf(PropTypes.object).isRequired,
  styleName: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  refresh: PropTypes.func.isRequired,
};

export default SkuSetupBulkGridUploadModal;
