/* eslint-disable no-use-before-define */
import React, { PureComponent } from 'react';
import DataGridRow from './DataGridRow';
import Dropdown from '@sharedComponents/Inputs/Dropdowns/Dropdown/Dropdown';
import MaterialCheckbox from '@sharedComponents/Inputs/MaterialCheckbox';
import Button from '@sharedComponents/Buttons/Button';
import LinkButton from '@sharedComponents/Buttons/LinkButton';
import { sortDirectionEnum } from '@constants/enums/commonEnums';
import Icon from '@sharedComponents/Icons/Icon';
import MaterialTooltip from '@sharedComponents/Tooltips/MaterialTooltip';
import { deepMerge } from '@util/deepMerge';
import Spinner from '@sharedComponents/Display/Spinner';
import { arraysAreEqual } from 'util/arrayHelper';

export interface DataGridColumn<T extends { [key in K]: number }, K extends keyof T> {
  name: string;
  key: keyof T | K;
  className?: string;
  width?: number;
  template?: (row: T) => void;
  selectionColumn: boolean;
  visible: boolean;
  // Not able to use JSX.Element because JSON.stringify and parse kill $$typeof
  groupTitleTemplate?: (row: T) => string;
  groupAdditionalInfoTemplate?: (row: T) => string;
  groupByDisabled: boolean;
  sortable: boolean;
}

export interface DataGridGroupLayerProps {
  number: number;
  alwaysExpanded?: boolean;
  rowClasses?: string;
  height?: number;
  showCount?: boolean;
}

interface DataGridSort<T, K> {
  key: keyof T | K;
  direction: string;
}

export interface GroupRow<T extends { [key in K]: number }, K extends keyof T> {
  count: number;
  value: number;
  isGroup: boolean;
  column: string;
  expanded: boolean;
  groupKey: string;
  depth: number;
  rows: (T | GroupRow<T, K>)[];
  displayValue: string;
  additionalDisplayValue: string;
}

interface OwnProps<T extends { [key in K]: number }, K extends keyof T> {
  rows: T[] | undefined;
  columns: DataGridColumn<T, K>[];

  groupLayersCount: number;
  groupLayersProperties: DataGridGroupLayerProps[];
  groupByColumns: (keyof T)[];
  defaultGroupingOnly: boolean;

  selectable: boolean;
  selectionKey: K;
  onSelectionChange: (ids: (number)[]) => void;
  selectedIds: number[];

  action?: () => void;
  actionText?: string;

  disabledActionButtonTooltipText?: string;
  hasDisabledActionButtonTooltip?: boolean;
  actionButtonDisabled?: boolean;

  footer?: JSX.Element;
}

interface OwnState<T extends { [key in K]: number }, K extends keyof T> {
  groupByColumns: (keyof T)[];
  gridColumns: DataGridColumn<T, K>[];
  gridRows: (T | GroupRow<T, K>)[] | undefined;
  selectedIds: Set<number | string>;
  selectedIdsNoGroupKey: number[];
  allSelected: boolean;
  sort: Nullable<DataGridSort<T, K>>;
  widthPercentage: number;
  numberOfColumnsWithWidth: number;
  expandedGroupIds: string[];
}

type Props<T extends { [key in K]: number }, K extends keyof T> = OwnProps<T, K>;
type State<T extends { [key in K]: number }, K extends keyof T> = OwnState<T, K>;

// TODO - think about moving these to props, but set default values
const groupKeyDelimiter = '**';
const groupKeyPrefix = '==';
export const selectionColumnWidthLg = 5;

class DataGrid<T extends { [key in K]: number }, K extends keyof T> extends PureComponent<Props<T, K>, State<T, K>> {
  constructor(props: Props<T, K>) {
    super(props);

    const newColumns = this.calculateGridColumns(this.props.groupByColumns);
    const gridRows = this.props.rows ? this.groupBy(this.props.rows, this.props.groupByColumns) : undefined;
    const selectedIds: Set<number | string> = gridRows
      ? this.restoreGridSelection(new Set<string | number>([...this.props.selectedIds]), gridRows)
      : new Set();
    this.state = {
      groupByColumns: this.props.groupByColumns,
      gridColumns: newColumns,
      gridRows,

      selectedIds,
      selectedIdsNoGroupKey: this.props.selectedIds,
      allSelected: this.props.selectedIds.length === this.props.rows?.length,

      sort: null,
      widthPercentage: this.calculateWidthPercentage(newColumns),
      numberOfColumnsWithWidth: this.calculateNumberOfColumnsWithWidth(newColumns),
      expandedGroupIds: [],
    };
  }

  componentDidUpdate(prevProps: Props<T, K>): void {
    const {
      rows,
      columns,
      groupByColumns,
      selectedIds,
    } = this.props;

    const { selectedIdsNoGroupKey } = this.state;

    // Rows updated - restore selected items
    if (rows !== prevProps.rows) {
      const gridRows = this.props.rows ? this.groupBy(this.props.rows, this.props.groupByColumns) : undefined;
      const selectedIdsNew: Set<string | number> = gridRows
        ? this.restoreGridSelection(new Set<string | number>([...this.props.selectedIds]), gridRows)
        : new Set();

      this.setState(() => ({
        gridRows,
        selectedIds: selectedIdsNew,
        selectedIdsNoGroupKey: this.props.selectedIds,
        allSelected: this.props.selectedIds.length === this.props.rows?.length,
      }));
    }

    // Rows not updated, grid selection updated from outside the component - restore selection
    if (
      !arraysAreEqual(selectedIds, selectedIdsNoGroupKey)
      && rows === prevProps.rows
    ) {
      const gridRows = this.props.rows ? this.groupBy(this.props.rows, this.props.groupByColumns) : undefined;
      const selectedIdsNew: Set<string | number> = gridRows
        ? this.restoreGridSelection(new Set<string | number>([...this.props.selectedIds]), gridRows)
        : new Set();

      this.setState(() => ({
        selectedIds: selectedIdsNew,
        selectedIdsNoGroupKey: this.props.selectedIds,
        allSelected: this.props.selectedIds.length === this.props.rows?.length,
      }));
    }

    // Group by changed - reset selection and re-calculate grid columns
    if (groupByColumns !== prevProps.groupByColumns) {
      const newColumns = this.calculateGridColumns(groupByColumns);
      this.setState(() => ({
        groupByColumns,
        gridColumns: newColumns,
        widthPercentage: this.calculateWidthPercentage(newColumns),
        numberOfColumnsWithWidth: this.calculateNumberOfColumnsWithWidth(newColumns),
        gridRows: rows ? this.groupBy(rows, groupByColumns) : undefined,
        selectedIds: new Set(),
        allSelected: false,
        selectedIdsNoGroupKey: [],
        expandedGroupIds: [],
      }));
    }

    // Grid columns changes - recalculate grid columns & regroup rows
    if (columns !== prevProps.columns && groupByColumns === prevProps.groupByColumns) {
      const newColumns = this.calculateGridColumns(groupByColumns);
      this.setState(() => ({
        gridColumns: newColumns,
        widthPercentage: this.calculateWidthPercentage(newColumns),
        numberOfColumnsWithWidth: this.calculateNumberOfColumnsWithWidth(newColumns),
        gridRows: rows ? this.groupBy(rows, groupByColumns) : undefined,
      }));
    }
  }

  groupBy = (
    rows: T[],
    groupByColumns: (keyof T)[],
    parentGroupKey: string = '',
    depth: number = 0,
  ): any[] => {
    const { columns } = this.props;
    if (!groupByColumns.length) {
      return rows;
    }

    const { groupLayersProperties } = this.props;
    const expandedGroupIds = this.state?.expandedGroupIds || [];
    const groupLayerProperties = groupLayersProperties[depth];

    const groupByColumn = groupByColumns[0];
    const dataGridColumn = columns.find((c) => c.key === groupByColumn);

    const groups = Object.values(rows.reduce((result: any, current: T) => {
      const currentValue = current[groupByColumn];

      if (!result[currentValue]) {
        const groupKey = `${parentGroupKey}${parentGroupKey ? groupKeyDelimiter : ''}${groupKeyPrefix}${currentValue}`;
        result[currentValue] = {
          value: currentValue,
          column: groupByColumn,
          rows: [],
          isGroup: true,
          expanded: groupLayerProperties.alwaysExpanded ? true : expandedGroupIds.includes(groupKey) ? true : false,
          depth,
          groupKey,
          displayValue: dataGridColumn?.groupTitleTemplate ? dataGridColumn.groupTitleTemplate(current) : currentValue,
          additionalDisplayValue: dataGridColumn?.groupAdditionalInfoTemplate
            ? dataGridColumn.groupAdditionalInfoTemplate(current)
            : null,
        };
      }
      result[currentValue].rows.push(current);

      return result;
    }, {}));

    groupByColumns = groupByColumns.slice(1);
    if (groupByColumns.length) {
      groups.forEach((g: any) => {
        g.count = g.rows.length;
        g.rows = this.groupBy(g.rows, groupByColumns, g.groupKey, depth + 1);
      });
    }

    return groups;
  };

  onGroupByChange = (): void => {
    const { groupByColumns } = this.state;
    const { rows } = this.props;

    const gridRows = rows ? this.groupBy(rows, groupByColumns) : undefined;
    this.setState(() => ({ gridRows }));
  };

  toggleExpanded = (groupKey: string): void => {
    const {
      gridRows,
      expandedGroupIds,
    } = this.state;
    const keys = groupKey.split(groupKeyDelimiter);
    let parentGroup = gridRows;
    let currentRow: any;
    let expandedGroupIdsNew: string[] = [];
    keys.forEach((key, index) => {
      currentRow = parentGroup!.find((g: any) => g.isGroup && g.groupKey.split(groupKeyDelimiter)[index] === key);

      if (index !== keys.length - 1) {
        parentGroup = currentRow.rows;
      }
    });

    if (!currentRow) { return; }

    if (currentRow.expanded) {
      expandedGroupIdsNew = expandedGroupIds.filter((x) => x !== currentRow.groupKey);
    } else {
      expandedGroupIdsNew = [
        ...expandedGroupIds,
        currentRow.groupKey,
      ];
    }
    currentRow.expanded = !currentRow.expanded;

    const newGridRows = gridRows!.map((r) => deepMerge({}, r));

    this.setState(() => ({
      gridRows: newGridRows,
      expandedGroupIds: expandedGroupIdsNew,
    }));
  };

  calculateGridColumns = (groupByColumns: (keyof T)[]): DataGridColumn<T, K>[] => {
    const {
      selectable,
      columns,
      selectionKey,
    } = this.props;

    const filteredColumns = columns.filter((c) => !groupByColumns.includes(c.key));
    if (selectable) {
      filteredColumns.unshift({
        name: '',
        key: selectionKey,
        className: '',
        width: selectionColumnWidthLg,
        selectionColumn: true,
      } as DataGridColumn<T, K>);
    }

    return filteredColumns;
  };

  calculateWidthPercentage = (columns: DataGridColumn<T, K>[]): number => {
    const addedColumnsPercentages = columns.reduce((a, b) => a + (b.width ?? 0), 0);

    return 100 - addedColumnsPercentages;
  };

  calculateNumberOfColumnsWithWidth = (
    columns: DataGridColumn<T, K>[],
  ): number => columns.filter((c) => !!c.width).length;

  toggleGroup = (index: number, groupByValue: string): void => {
    const { groupByColumns } = this.state;

    const newGrupByColumns = [...groupByColumns];
    newGrupByColumns[index] = groupByValue as keyof T;
    const newGridColumns = this.calculateGridColumns(newGrupByColumns);

    this.setState(() => ({
      groupByColumns: newGrupByColumns,
      gridColumns: newGridColumns,
    }), this.onGroupByChange);
  };

  calculateGroupSelection = (
    selectedIds: Set<number | string>,
    rows: any[],
    keys: string[],
    depth: number,
  ): Set<number | string> => {
    const { selectionKey } = this.props;
    if (keys.length === depth) {
      return new Set(selectedIds);
    }

    const newGroup = rows.find((r) => r.groupKey === keys.slice(0, depth + 1).join(groupKeyDelimiter));
    const newSelectedIds = this.calculateGroupSelection(selectedIds, newGroup.rows, keys, depth + 1);
    const newGroupRowIds = newGroup.rows.map((r: any) => r.groupKey || r[selectionKey]);
    if (newGroupRowIds.every((k: any) => newSelectedIds.has(k))) {
      newSelectedIds.add(newGroup.groupKey);
    } else {
      newSelectedIds.delete(newGroup.groupKey);
    }

    return newSelectedIds;
  };

  getSubGroupKeysForSelection = (group: GroupRow<T, K>, selectedIds: Set<number | string>) => {
    const { selectionKey } = this.props;

    if (
      ('isGroup' in group)
      && group.rows.find((x) => !('isGroup' in x) && selectedIds.has(x[selectionKey]))
    ) {
      return [group.groupKey];
    }

    let keys: string[] = [];
    group.rows.forEach((g) => {
      if ('isGroup' in g) {
        keys = [
          ...keys,
          ...this.getSubGroupKeysForSelection(g, selectedIds),
        ];
      }
    });

    return keys;
  };

  restoreGridSelection = (
    selectedIds: Set<number | string>,
    gridRows: (T | GroupRow<T, K>)[],
  ): Set<number | string> => {
    let selectedIdsAndGroupIds: Set<number | string> = new Set<number | string>();

    let groupKeys: string[] = [];
    gridRows.forEach((group) => {
      if ('isGroup' in group) {
        groupKeys = [
          ...groupKeys,
          ...this.getSubGroupKeysForSelection(group, selectedIds),
        ];
      }
    });

    groupKeys.forEach((groupKey) => {
      const groupSelection = this.calculateGroupSelection(selectedIds, gridRows, groupKey.split(groupKeyDelimiter), 0);
      selectedIdsAndGroupIds = new Set([
        ...selectedIdsAndGroupIds,
        ...groupSelection,
      ]);
    });

    return selectedIdsAndGroupIds;
  };

  toggleSelection = (
    set: Set<number | string>,
    rows: (T | GroupRow<T, K>)[],
    action: (key: number) => void,
  ): void => {
    const { selectionKey } = this.props;
    rows.forEach((row: any) => {
      if (row.isGroup) {
        action(row.groupKey);
        this.toggleSelection(set, row.rows, action.bind(set));
      } else {
        action(row[selectionKey]);
      }
    });
  };

  onGridSelectionChange = (row?: T | GroupRow<T, K>, parentGroupKey?: string): void => {
    const {
      selectedIds,
      gridRows,
      allSelected,
    } = this.state;

    let newSelectedIds = new Set(selectedIds);
    const {
      selectionKey,
      onSelectionChange,
      rows,
    } = this.props;

    if (row && 'isGroup' in row) {
      if (newSelectedIds.has(row.groupKey)) {
        newSelectedIds.delete(row.groupKey);
        this.toggleSelection(newSelectedIds, row.rows, newSelectedIds.delete.bind(newSelectedIds));
      } else {
        newSelectedIds.add(row.groupKey);
        this.toggleSelection(newSelectedIds, row.rows, newSelectedIds.add.bind(newSelectedIds));
      }
    } else if (row && selectionKey in row) {
      if (newSelectedIds.has(row[selectionKey])) {
        newSelectedIds.delete(row[selectionKey]);
      } else {
        newSelectedIds.add(row[selectionKey]);
      }
    } else if (allSelected) {
      newSelectedIds = new Set();
    } else {
      newSelectedIds = new Set();
      this.toggleSelection(newSelectedIds, gridRows!, newSelectedIds.add.bind(newSelectedIds));
    }

    if (parentGroupKey) {
      const keys = parentGroupKey.split(groupKeyDelimiter);
      newSelectedIds = this.calculateGroupSelection(newSelectedIds, gridRows!, keys, 0);
    }

    const selectedIdsNoGroupKey = [...newSelectedIds]
      .filter((id) => !id.toString().includes(groupKeyPrefix)) as (number)[];

    this.setState(() => ({
      selectedIds: newSelectedIds,
      selectedIdsNoGroupKey,
      allSelected: selectedIdsNoGroupKey.length === rows?.length,
    }));
    onSelectionChange(selectedIdsNoGroupKey);
  };

  onAllSelected = (): void => {
    this.onGridSelectionChange();
  };

  clearSelection = (): void => {
    const { onSelectionChange } = this.props;
    const newSelectedIds = new Set<string | number>();
    this.setState(() => ({
      selectedIds: newSelectedIds,
      allSelected: false,
      selectedIdsNoGroupKey: [],
    }));
    onSelectionChange([]);
  };

  sortGroups = (rows: any[], sort: DataGridSort<T, K>): void => {
    rows.forEach((row) => {
      if (row.isGroup && row.rows.length && !row.rows[0].isGroup) {
        row.rows = row.rows.sort((a: any, b: any) => {
          if (a[sort.key] < b[sort.key]) {
            if (sort.direction === sortDirectionEnum.Asc) {
              return -1;
            }

            return 1;
          } else if (a[sort.key] === b[sort.key]) {
            return 0;
          }

          if (sort.direction === sortDirectionEnum.Asc) {
            return 1;
          }

          return -1;
        });
      } else {
        this.sortGroups(row.rows, sort);
      }
    });
  };

  toggleSort = (key: keyof T | K): void => {
    const {
      sort,
      gridRows,
    } = this.state;
    let direction: string = sortDirectionEnum.Asc;

    if (sort && sort.key === key && sort.direction === sortDirectionEnum.Asc) {
      direction = sortDirectionEnum.Desc;
    }

    const newSort = {
      key,
      direction,
    };
    this.sortGroups(gridRows!, newSort);

    const newGridRows = gridRows!.map((r) => deepMerge({}, r));

    this.setState(() => ({
      sort: newSort,
      gridRows: newGridRows,
    }));
  };

  dispatchAction = (): void => {
    const { action } = this.props;
    if (!action) { return; }

    this.setState(() => ({
      selectedIds: new Set(),
      selectedIdsNoGroupKey: [],
      allSelected: false,

      sort: null,
    }));
    action();
  };

  render() {
    const {
      groupLayersProperties,
      columns,
      selectionKey,
      actionText,
      hasDisabledActionButtonTooltip,
      disabledActionButtonTooltipText,
      actionButtonDisabled,
      selectable,
      action,
      defaultGroupingOnly,
      footer,
    } = this.props;
    const {
      gridColumns,
      gridRows,
      groupByColumns,
      selectedIds,
      allSelected,
      selectedIdsNoGroupKey,
      sort,
      widthPercentage,
      numberOfColumnsWithWidth,
    } = this.state;

    if (!gridRows) {
      return (
        <div className='flex align__center justify__center'>
          <Spinner />
        </div>
      );
    }

    if (!gridRows.length) {
      return (
        <div className='flex align__center justify__center'>
          No rows found
        </div>
      );
    }

    const dropdownOptions = columns.filter((c) => !c.groupByDisabled).map((c) => ({
      key: c.key.toString(),
      value: c.key.toString(),
      name: c.name,
      disabled: groupByColumns.includes(c.key),
    }));

    const calculatedWidth = widthPercentage / (gridColumns.length - numberOfColumnsWithWidth);
    const hasVisibleColumns = gridColumns.some((x) => x.visible);

    return (
      <div className='sheet w-100'>
        {groupLayersProperties.length && !defaultGroupingOnly
          && (
            <div className='filter-groups'>
              {groupLayersProperties.map((c, index) => (
                <Dropdown<string>
                  key={c.number}
                  options={dropdownOptions}
                  onChange={this.toggleGroup.bind(this, index)}
                  defaultValue={groupByColumns[index].toString()}
                  classes={'mr-30'}
                />
              ))}
            </div>
          )}

        <div className='sheet__list'>
          <table className='data-grid w-100'>
            <tbody>
              {selectedIdsNoGroupKey.length > 0 && action && actionText
                && (
                  <tr className='data-grid__actions-row'>
                    <td>
                      {hasDisabledActionButtonTooltip && actionButtonDisabled
                        && (
                          <MaterialTooltip
                            tooltipText={disabledActionButtonTooltipText}
                            placement={'top'}
                          >
                            <Button
                              type={'primary'}
                              text={actionText}
                              onClick={this.dispatchAction.bind(null)}
                              classes={'data-grid__actions-row__action-btn--disabled'}
                            />
                          </MaterialTooltip>
                        )}
                      {!hasDisabledActionButtonTooltip
                        || hasDisabledActionButtonTooltip && !actionButtonDisabled
                          && (
                            <Button
                              type={'primary'}
                              text={actionText}
                              onClick={this.dispatchAction.bind(null)}
                              classes={'data-grid__actions-row__action-btn'}
                            />
                          )}
                    </td>
                    <td>
                      <span className='data-grid__actions-row__info mr-10'>{selectedIdsNoGroupKey.length} selected</span>
                      <LinkButton
                        text={'Deselect all'}
                        onClick={this.clearSelection}
                        classes={'m-l-12 data-grid__actions-row__deselect-btn'}
                      />
                    </td>
                  </tr>
                )}
              {hasVisibleColumns
                && (
                  <tr>
                    {gridColumns.map((c, index) => (
                      <th
                        key={index}
                        className={c.className}
                        style={{ width: `${c.width ? c.width : calculatedWidth}%` }}
                      >
                        {c.selectionColumn
                          ? (
                            <MaterialCheckbox
                              checked={allSelected}
                              onClick={this.onAllSelected}
                              text={''}
                            />
                          )
                          : c.visible
                          ? (
                            c.sortable
                              ? (
                                <div
                                  onClick={this.toggleSort.bind(null, c.key)}
                                  className={'flex align__center cursor-pointer'}
                                >
                                  <div className='mr-20'>{c.name}</div>
                                  {sort && sort.key === c.key
                                    && (
                                      <div>
                                        {sort.direction === sortDirectionEnum.Asc
                                          ? (
                                            <Icon
                                              materialIcon={'arrow_upward'}
                                              classes={'sort'}
                                            />
                                          )
                                          : (
                                            <Icon
                                              materialIcon={'arrow_downward'}
                                              classes={'sort'}
                                            />
                                          )}
                                      </div>
                                    )}
                                </div>
                              )
                              : c.name
                          )
                          : <div />}
                      </th>
                    ))}
                  </tr>
                )}
              {gridRows.map((row: any) => (
                <DataGridRow<T, K>
                  row={row}
                  columns={gridColumns}
                  key={row.isGroup ? row.value : row[selectionKey]}
                  toggleExpanded={this.toggleExpanded}
                  groupLayersProperties={groupLayersProperties}
                  onGridSelectionChange={this.onGridSelectionChange}
                  selectionKey={selectionKey}
                  selectedIds={selectedIds}
                  defaultWidth={calculatedWidth}
                  selectable={selectable}
                />
              ))}
              {footer
                && (
                  <tr>
                    <td className='h-100 w-100'>
                      {footer}
                    </td>
                  </tr>
                )}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default DataGrid;
