import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  uploadLogoToLockerLogos,
  requeueLogoForReview,
} from '@APICalls/lockerManager/actions';
import { updateLogo } from '@APICalls/logos/actions';
import { selectionTypeEnum } from '@constants/enums/decorationEnums';
import { dragAndDropMessage } from '@constants/values';
import { changeLogoApprovedStatus } from '@redux/lockerManager/actions';
import { materialSwal } from '@util/componentHelper';
import UploadLogoSwatchColors from '@sharedComponents/UploadLogoSwatchColors/UploadLogoSwatchColors';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import Modal from '@sharedComponents/Modal/Modal';
import DropZoneWrapper from '@sharedComponents/Upload/DropZoneWrapper';
import Button from '@sharedComponents/Buttons/Button';
import Icon from '@sharedComponents/Icons/Icon';
import DetailedLockerLogo from './Logos/DetailedLogo/DetailedLockerLogo';
import AssignLogosToItems from './AssignLogosToItems/AssignLogosToItems';
import AssignLogosToCustomItemsModal from './AssignLogosToCustomItems/AssignLogosToCustomItemsModal';
import LogoDetailsModal from './Logos/DetailedLogo/LogoDetailsModal';
import ButtonLink from '@components/shared/Navigation/ButtonLink';
import { lockerDetailBulkUploadImage } from '@constants/clientUrls/clientUrls';

class LogoBankLockerLogos extends PureComponent {
  state = {
    isExpanded: true,
    uploadToLogoBankModalIsOpen: false,
    assignLogosToItemsModalIsOpen: false,
    assignLogosToCustomItemsModalIsOpen: false,
    attachedFile: null,
    loading: false,
    selectedLogo: null,
    selectedLogoIndex: null,
    uploadLogoResponse: null,
    swatchModalIsOpen: false,
    logoDetailsModalIsOpen: false,
    message: {
      type: 'default',
      body: dragAndDropMessage(),
    },
    logoData: '',
  };

  componentDidMount() {
    this.updateLogos();
  }

  componentDidUpdate(prevProps) {
    const { logos } = this.props;

    if (prevProps.logos !== logos && logos) {
      this.updateLogos();
    }
  }

  openLogoDetailsModal = (logo) => {
    const { logos } = this.props;
    this.setState(() => ({
      logoDetailsModalIsOpen: true,
      selectedLogo: logo,
      selectedLogoIndex: logos.findIndex((l) => l.id === logo.id),
    }));
  };

  closeLogoDetailsModal = () => {
    this.setState(() => ({
      logoDetailsModalIsOpen: false,
      selectedLogo: null,
      selectedLogoIndex: null,
    }));
  };

  slideToPrevious = () => {
    const { selectedLogoIndex } = this.state;
    const { logos } = this.props;

    if (selectedLogoIndex > 0) {
      const newIndex = selectedLogoIndex - 1;
      this.setState(() => ({
        selectedLogo: logos[newIndex],
        selectedLogoIndex: newIndex,
      }));
    }
  };

  slideToNext = () => {
    const { selectedLogoIndex } = this.state;
    const { logos } = this.props;

    if (selectedLogoIndex > -1 && selectedLogoIndex < (logos.length - 1)) {
      const newIndex = selectedLogoIndex + 1;
      this.setState(() => ({
        selectedLogo: logos[newIndex],
        selectedLogoIndex: newIndex,
      }));
    }
  };

  hasPrevious = (logo) => {
    const { logos } = this.props;

    const index = logos.findIndex((l) => l.id === logo.id);

    return index > 0;
  };

  hasNext = (logo) => {
    const { logos } = this.props;

    const index = logos.findIndex((l) => l.id === logo.id);

    return index > -1 && index < (logos.length - 1);
  };

  changeExpand = () => {
    this.setState((prevState) => ({ isExpanded: !prevState.isExpanded }));
  };

  openUploadToLogoBankModal = () => {
    this.setState(() => ({ uploadToLogoBankModalIsOpen: true }));
  };

  closeUploadToLogoBankModal = () => {
    this.setState(() => ({ uploadToLogoBankModalIsOpen: false }));
  };

  openAssignLogosToItemsModal = () => {
    this.setState(() => ({ assignLogosToItemsModalIsOpen: true }));
  };

  closeAssignLogosToItemsModal = () => {
    this.setState(() => ({ assignLogosToItemsModalIsOpen: false }));
  };

  openAssignLogosToCustomItemsModal = () => {
    this.setState(() => ({ assignLogosToCustomItemsModalIsOpen: true }));
  };

  closeAssignLogosToCustomItemsModal = () => {
    this.setState(() => ({ assignLogosToCustomItemsModalIsOpen: false }));
  };

  closeSwatchModal = () => {
    this.setState(() => ({
      swatchModalIsOpen: false,
      uploadLogoResponse: null,
    }));
  };

  uploadLogo = async () => {
    const {
      lockerId, refreshLogos,
    } = this.props;
    const { attachedFile } = this.state;

    const res = await uploadLogoToLockerLogos(lockerId, attachedFile);
    if (res?.success) {
      materialSwal('Success', res.message, 'success');
      this.clearFileFromState();
      refreshLogos();
      this.setState(() => ({
        uploadToLogoBankModalIsOpen: false,
        uploadLogoResponse: res,
        swatchModalIsOpen: true,
      }));
    }
  };

  onDrop = (acceptedFiles, rejectedFiles) => {
    const destroyFiles = () => {
      for (const file of acceptedFiles.concat(rejectedFiles)) {
        window.URL.revokeObjectURL(file.preview);
      }
    };

    this.setState(() => ({
      message: {
        body: `File ${acceptedFiles[0].name} has been attached.`,
        type: 'default',
      },
      attachedFile: acceptedFiles,
    }), destroyFiles);
  };

  clearFileFromState = () => {
    this.setState(() => ({
      attachedFile: null,
      message: {
        type: 'default',
        body: dragAndDropMessage(),
      },
    }));
  };

  toggleLogoApprovedStatus = async (logoId, isApproved) => {
    const { dispatch } = this.props;

    const res = await updateLogo(logoId, selectionTypeEnum.UserOnly, null, null, isApproved);
    if (res?.success) {
      await dispatch(changeLogoApprovedStatus({
        logoId,
        isApproved,
      }));
    }
  };

  requeueForLogoReview = async (logo) => {
    const { refreshLogos } = this.props;

    const res = await requeueLogoForReview(logo.id);
    if (res?.success) {
      materialSwal('Success', res.message, 'success');
      refreshLogos();
    }
  };

  updateLogos = () => {
    const {
      logos,
      lockerId,
      openModalWithImage,
      refreshLogos,
      refreshUsedLogos,
    } = this.props;

    if (!logos) return;

    const logoData = logos.map((logo, key) => (
      <DetailedLockerLogo
        logo={logo}
        key={key}
        keyProp={key}
        lockerId={lockerId}
        openModalWithImage={openModalWithImage}
        openLogoDetailsModal={this.openLogoDetailsModal}
        toggleApprovedStatus={this.toggleLogoApprovedStatus}
        refreshLogos={refreshLogos}
        refreshUsedLogos={refreshUsedLogos}
        requeueForLogoReview={this.requeueForLogoReview}
      />
    ));

    this.setState(() => ({ logoData }));
  };

  render() {
    const {
      logos,
      lockerId,
      refreshLogos,
    } = this.props;

    const {
      uploadToLogoBankModalIsOpen,
      assignLogosToItemsModalIsOpen,
      assignLogosToCustomItemsModalIsOpen,
      message,
      loading,
      logoData,
      logoDetailsModalIsOpen,
      selectedLogo,
      uploadLogoResponse,
      swatchModalIsOpen,
    } = this.state;

    const dropzoneContent = loading
      ? (
        <label className='button'>
          <Icon
            fontAwesomeIcon={'spinner'}
            classes={'fa-spin'}
          />
          Uploading...
        </label>
      )
      : <label className={`button message-${message.type}`}>{message.body}</label>;

    return (
      <div>
        <LogoDetailsModal
          isOpen={logoDetailsModalIsOpen}
          closeModal={this.closeLogoDetailsModal}
          logo={selectedLogo}
          hasPrevious={this.hasPrevious}
          hasNext={this.hasNext}
          slideToPrevious={this.slideToPrevious}
          slideToNext={this.slideToNext}
        />

        <AssignLogosToItems
          modalIsOpen={assignLogosToItemsModalIsOpen}
          closeModal={this.closeAssignLogosToItemsModal}
          lockerId={lockerId}
          multipleLogos={true}
          logos={logos}
        />

        <AssignLogosToCustomItemsModal
          isOpen={assignLogosToCustomItemsModalIsOpen}
          closeModal={this.closeAssignLogosToCustomItemsModal}
          logos={logos}
          lockerId={lockerId}
        />

        {
          uploadLogoResponse?.success &&
          <UploadLogoSwatchColors
            logoId={uploadLogoResponse.result}
            cancel={this.closeSwatchModal}
            isModalOpen={swatchModalIsOpen}
            refreshLogos={refreshLogos}
          />
        }

        <Modal
          title={'Upload Logo to Locker Logo Bank'}
          modalSize={'xl'}
          isOpen={uploadToLogoBankModalIsOpen}
          closeModal={this.closeUploadToLogoBankModal}
          buttons={(
            <ModalButtons
              confirmBtnText={'Upload logo'}
              cancelBtnText={'Cancel'}
              onConfirm={this.uploadLogo}
              onClose={this.closeUploadToLogoBankModal}
            />
          )}
        >
          <div className='logo-bank__container'>
            <div className='upload-field '>
              <DropZoneWrapper
                onDrop={this.onDrop}
                multiple={false}
                className={'block-drop-zone'}
              >
                {dropzoneContent}
              </DropZoneWrapper>
            </div>
          </div>
        </Modal>

        <div className='logo-bank__container logo-bank__container-full'>
          <div className='header'>
            <span>
              Locker Logos
            </span>
            <div>
              <Button
                type={'secondary'}
                text={'Assign Logos to Items'}
                onClick={this.openAssignLogosToItemsModal}
              />
              <Button
                type={'secondary'}
                text={'Assign Logos to Custom Items'}
                onClick={this.openAssignLogosToCustomItemsModal}
                classes={'ml-10'}
              />
              <Button
                type={'secondary'}
                text={'Upload to Locker Logo Bank'}
                onClick={this.openUploadToLogoBankModal}
                classes={'ml-10'}
              />
              <ButtonLink
                type={'secondary'}
                text={'Bulk Upload to Locker Logo Bank'}
                classes={'ml-10'}
                linkTo={lockerDetailBulkUploadImage(lockerId)}
              />
            </div>
          </div>
          <div className='logo-bank__manager--logos'>
            {logoData}
          </div>
        </div>
      </div>
    );
  }
}

LogoBankLockerLogos.propTypes = {
  logos: PropTypes.array,
  lockerId: PropTypes.number,
  openModalWithImage: PropTypes.func,
  refreshLogos: PropTypes.func.isRequired,
  refreshUsedLogos: PropTypes.func.isRequired,
};

const mapStateToProps = ({ lockerManager }) => ({
  logoBankLockerLogos: lockerManager.logoBankLockerLogos,
});

export default connect(mapStateToProps)(LogoBankLockerLogos);
