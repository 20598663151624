/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Catalog Homefield API
 * OpenAPI spec version: v1
 */
import type { StyleDto } from './styleDto';

export interface StyleCrossSellDto {
  id?: number;
  sourceStyleId?: number | null;
  targetStyleId?: number;
  sourceStyle?: StyleDto;
  targetStyle?: StyleDto;
}
