import SqdApi from 'sqd-fetch';
import {
  fulfillmentRoot,
  productCatalogRoot,
  slServicesRoot,
  storefrontRoot,
  identityRoot,
  financialServicesRoot,
  ordersApiRoot,
} from '@constants/urls/configUrls';
import {
  beginRequest,
  endRequest,
  beginBlockingRequest,
  endBlockingRequest,
} from '@redux/api/actions';
import store from './store';

const getToken = () => {
  const oidcUser = store.getState().oidc.user;
  if (oidcUser?.access_token) {
    return oidcUser.access_token;
  }

  return '';
};

const nonBlockingProvider = {
  onBegin: (options) => {
    if (options) {
      if (!options.hasOwnProperty('handleLoading') || options.handleLoading) {
        store.dispatch(beginRequest(options));
      }
    }
  },
  onResolve: (options, response) => {
    if (options) {
      if (!options.hasOwnProperty('handleLoading') || options.handleLoading) {
        store.dispatch(endRequest(options, response));
      }
    }
  },
  onFail: (options, response) => {
    if (options) {
      if (!options.hasOwnProperty('handleLoading') || options.handleLoading) {
        store.dispatch(endRequest(options, response));
      }
    }
  },
};

const blockingProvider = {
  onBegin: (options) => {
    if (options) {
      if (!options.hasOwnProperty('handleBlockingLoading') || options.handleBlockingLoading) {
        store.dispatch(beginBlockingRequest(options));
      }
    }
  },
  onResolve: (options, response) => {
    if (options) {
      if (!options.hasOwnProperty('handleBlockingLoading') || options.handleBlockingLoading) {
        store.dispatch(endBlockingRequest(options, response));
      }
    }
  },
  onFail: (options, response) => {
    if (options) {
      if (!options.hasOwnProperty('handleBlockingLoading') || options.handleBlockingLoading) {
        store.dispatch(endBlockingRequest(options, response));
      }
    }
  },
};

const fulfillmentApi = new SqdApi(fulfillmentRoot, true, { getToken });
fulfillmentApi
  .addSqdProvider(nonBlockingProvider)
  .addSqdProvider(blockingProvider);

const catalogApi = new SqdApi(productCatalogRoot, true, { getToken });
catalogApi
  .addSqdProvider(nonBlockingProvider)
  .addSqdProvider(blockingProvider);

const slServicesApi = new SqdApi(slServicesRoot, true, { getToken });
slServicesApi
  .addSqdProvider(nonBlockingProvider)
  .addSqdProvider(blockingProvider);

const identityApi = new SqdApi(identityRoot, true, { getToken });
identityApi
  .addSqdProvider(nonBlockingProvider)
  .addSqdProvider(blockingProvider);

const financialApi = new SqdApi(financialServicesRoot, true, { getToken });
financialApi
  .addSqdProvider(nonBlockingProvider)
  .addSqdProvider(blockingProvider);

const ordersApi = new SqdApi(ordersApiRoot, true, { getToken });
fulfillmentApi
  .addSqdProvider(nonBlockingProvider)
  .addSqdProvider(blockingProvider);

export const slRailsapi = new SqdApi(storefrontRoot, false);

export {
  fulfillmentApi,
  catalogApi,
  slServicesApi,
  identityApi,
  financialApi,
  ordersApi,
};
