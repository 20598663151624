import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Table from '@sharedComponents/Table/Table';
import { formatDecorationLocationSize } from '@util/numberHelpers';
import {
  parseDateNumeric,
  parseTime,
} from '@util/dateHandler';
import ImagePreviewModal from '@sharedComponents/Modal/ImagePreviewModal';
import LogoOrTextCell from './Cells/ProductionAssemblyLogoOrTextCell';
import Button from '@sharedComponents/Buttons/Button';
import { getPagingParamsFromTable } from '@util/tableHelpers';
import { dtgTranmissisonDecorationEnum } from '@constants/enums/dtgEnums';
import {
  cancelDtgTransmissionDecorations,
  archiveDtgTransmissionDecorations,
} from '@APICalls/dtg/actions';
import { materialSwal } from '@util/componentHelper';

const DtgTransmissionDecorationsTable = Table();

class ProductionAssemblyInProgressTab extends PureComponent {
  state = {
    logoImagePreview: {
      isOpen: false,
      imageUrl: null,
    },
  };

  cancelDtgTransmissionDecoration = async (id) => {
    const { fetchData } = this.props;
    const result = await cancelDtgTransmissionDecorations([id]);

    if (result?.success) {
      materialSwal('Success', result.message, 'success');
      fetchData();
    }
  };

  clearHistory = async () => {
    const { fetchData } = this.props;
    const result = await archiveDtgTransmissionDecorations();

    if (result?.success) {
      materialSwal('Success', result.message, 'success');
      fetchData();
    }
  };

  getColumns = () => (
    [
      {
        Header: 'Item',
        accessor: '',
        width: 100,
        Cell: (cellProps) => (
          cellProps.value &&
          <LogoOrTextCell
            item={{ logoUrl: cellProps.value.logoUrl }}
            openModal={this.openLogoPreviewModal}
            logoCellSize={'s'}
          />
        ),
      },
      {
        Header: 'SKU',
        accessor: 'sku',
        minWidth: 70,
        Cell: (cellProps) => <span>{cellProps.value}</span>,
      },
      {
        Header: 'Size',
        accessor: '',
        minWidth: 50,
        Cell: (cellProps) => (
          cellProps.value &&
          <span>
            {cellProps.value && formatDecorationLocationSize(cellProps.value.width, cellProps.value.height)}
          </span>
        ),
      },
      {
        Header: 'Color',
        accessor: 'colorGroup',
        minWidth: 70,
        Cell: (cellProps) => <span>{cellProps.value}</span>,
      },
      {
        Header: 'Brand',
        accessor: 'brandName',
        minWidth: 80,
        Cell: (cellProps) => <span>{cellProps.value}</span>,
      },
      {
        Header: 'Location',
        accessor: 'decorationLocation',
        minWidth: 50,
        Cell: (cellProps) => <span>{cellProps.value}</span>,
      },
      {
        Header: 'Sent to Print',
        accessor: 'created',
        minWidth: 50,
        Cell: (cellProps) => (
          <div>
            <div>{parseDateNumeric(cellProps.value)}</div>
            <div>{parseTime(cellProps.value)}</div>
          </div>
        ),
      },
      {
        Header: 'Printer Name',
        accessor: 'dtgStationName',
        minWidth: 90,
        Cell: (cellProps) => <span>{cellProps.value}</span>,
      },
      {
        Header: 'Status',
        accessor: 'decorationStatus',
        minWidth: 50,
        Cell: (cellProps) => <span className={`decoration-status--${this.getStatusColorName(cellProps.value)}`}>{cellProps.value}</span>,
      },
      {
        Header: 'Action',
        accessor: '',
        minWidth: 50,
        Cell: (cellProps) => (
          cellProps.value && (
            cellProps.value.decorationStatus === dtgTranmissisonDecorationEnum.Sending
            || cellProps.value.decorationStatus === dtgTranmissisonDecorationEnum.Riping
          ) &&
          <Button
            type={'secondary'}
            text={'Cancel'}
            classes={'uppercase button--size-s'}
            onClick={this.cancelDtgTransmissionDecoration.bind(null, cellProps.value.id)}
          />
        ),
      },
    ]
  );

  getStatusColorName = (status) => {
    if (status === dtgTranmissisonDecorationEnum.Done) {
      return 'green';
    } else if (status === dtgTranmissisonDecorationEnum.Failed) {
      return 'red';
    } else if (status === dtgTranmissisonDecorationEnum.Sending
      || status === dtgTranmissisonDecorationEnum.Riping) {
      return 'orange';
    }
  };

  openLogoPreviewModal = (imageUrl) => {
    this.setState(() => ({
      logoImagePreview: {
        isOpen: true,
        imageUrl,
      },
    }));
  };

  closeLogoPreviewModal = () => {
    this.setState(() => ({
      logoImagePreview: {
        isOpen: false,
        imageUrl: null,
      },
    }));
  };

  fetchData = (state, instance) => {
    const { fetchData } = this.props;

    const {
      page,
      pageSize,
    } = getPagingParamsFromTable(instance);

    fetchData(page + 1, pageSize);
  };

  render() {
    const {
      hasNextPage,
      hasPreviousPage,
      totalPages,
      queue,
    } = this.props;

    const { logoImagePreview } = this.state;

    return (
      <>
        <div className='container mb-50 order-assembly__print-queue-in-progress-container'>
          <ImagePreviewModal
            modalIsOpen={logoImagePreview.isOpen}
            closeModal={this.closeLogoPreviewModal}
            imageUrl={logoImagePreview.imageUrl}
          />
          <DtgTransmissionDecorationsTable
            data={queue}
            columns={this.getColumns()}
            onFetchData={this.fetchData}
            totalPages={totalPages}
            hasNextPage={hasNextPage}
            hasPreviousPage={hasPreviousPage}
            tableName={'DTG Transmission Decorations'}
          />
        </div>
        <div className='order-assembly__print-queue-footer'>
          <div className='container'>
            <div className='flex h-100 align__center justify__space-between'>
              <Button
                type={'secondary'}
                text={'Clear History'}
                onClick={this.clearHistory}
                classes={'flex uppercase button--size-l'}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

ProductionAssemblyInProgressTab.propTypes = {
  queue: PropTypes.arrayOf(PropTypes.object).isRequired,
  totalPages: PropTypes.number.isRequired,
  hasPreviousPage: PropTypes.bool,
  hasNextPage: PropTypes.bool,
  pageNumber: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  fetchData: PropTypes.func.isRequired,
};

export default ProductionAssemblyInProgressTab;
