/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Accounts Homefield API
 * OpenAPI spec version: 1.0
 */
import type { StagingStoreDetailsDto } from './stagingStoreDetailsDto';

export interface StagingOrganizationDetailsDto {
  id: number;
  organizationName?: string | null;
  existingOrganizationId?: number | null;
  homefieldUrl?: string | null;
  userCompleted: boolean;
  stagingStores?: StagingStoreDetailsDto[] | null;
}
