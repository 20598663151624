import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '@sharedComponents/Buttons/Button';
import Table from '@sharedComponents/Table/Table';
import { materialSwal } from '@util/componentHelper';
import {
  getDtgArtworkSettings,
  getDecorationLocations,
} from '@redux/productCatalog/actions';
import {
  editDtgArtworkSettings,
  deleteDtgArtworkSettings,
} from '@APICalls/dtg/actions';
import { isDefined } from '@util/valueHelpers';
import DtgArtworkSettingsEditModal from './DtgArtworkSettingsModals/DtgArtworkSettingsEditModal';
import DtgArtworkSettingsAddModal from './DtgArtworkSettingsModals/DtgArtworkSettingsAddModal';
import DtgArtworkSettingsDeleteModal from './DtgArtworkSettingsModals/DtgArtworkSettingsDeleteModal';
import ShowMoreActionsButton from '@sharedComponents/Buttons/ShowMoreActionsButton';

const DtgArtworkSettingsTable = Table();

class DtgArtworkSettings extends PureComponent {
  state = {
    editDtgArtworkSettingModalIsOpen: false,
    addDtgArtworkSettingModalIsOpen: false,
    deleteDtgArtworkSettingModalIsOpen: false,
    selectedSetting: {},
  };

  componentDidMount() {
    this.fetchDtgArtworkSettings();
  }

  onEditArtworkSettings = async (data) => {
    const result = await editDtgArtworkSettings(data);
    if (result?.success) {
      materialSwal('Success', result.message, 'success');
      this.closeModal();
      this.fetchDtgArtworkSettings();
    }
  };

  onDeleteArtworkSettings = async (id) => {
    const result = await deleteDtgArtworkSettings(id);
    if (result?.success) {
      materialSwal('Success', result.message, 'success');
      this.closeModal();
      this.fetchDtgArtworkSettings();
    }
  };

  fetchDtgArtworkSettings = () => {
    const { dispatch } = this.props;
    dispatch(getDecorationLocations());
    dispatch(getDtgArtworkSettings());
  };

  closeModal = () => {
    this.setState({
      editDtgArtworkSettingModalIsOpen: false,
      addDtgArtworkSettingModalIsOpen: false,
      deleteDtgArtworkSettingModalIsOpen: false,
      selectedSetting: {},
    });
  };

  openEditModal = (selectedSetting) => {
    this.setState({
      editDtgArtworkSettingModalIsOpen: true,
      selectedSetting,
    });
  };

  openAddModal = () => {
    this.setState({
      addDtgArtworkSettingModalIsOpen: true,
      selectedSetting: {},
    });
  };

  openDeleteModal = (selectedSetting) => {
    this.setState({
      deleteDtgArtworkSettingModalIsOpen: true,
      selectedSetting,
    });
  };

  getColumns = () => [
    {
      Header: 'Decoration Location',
      accessor: 'decorationLocationCode',
      minWidth: 70,
      sortable: true,
      Cell: (cellProps) => cellProps.value && <span>{cellProps.value}</span>,
    },
    {
      Header: 'Decoration Size',
      accessor: 'decorationSize',
      minWidth: 70,
      Cell: (cellProps) => cellProps.value && <span>{cellProps.value}</span>,
    },
    {
      Header: 'Rotation',
      accessor: 'rotation',
      minWidth: 70,
      Cell: (cellProps) => cellProps.value && <span>{cellProps.value}</span>,
    },
    {
      Header: 'Width',
      accessor: '',
      minWidth: 70,
      Cell: ({ value }) => {
        const {
          printerOverrideWidth,
          defaultWidth,
        } = value;
        if (isDefined(printerOverrideWidth)) {
          return <span>{printerOverrideWidth} (Override)</span>;
        }
        if (isDefined(defaultWidth)) {
          return <span>{defaultWidth} (Default)</span>;
        }

        return <i>N/A</i>;
      },
    },
    {
      Header: 'Height',
      accessor: '',
      minWidth: 70,
      Cell: ({ value }) => {
        const {
          printerOverrideHeight,
          defaultHeight,
        } = value;
        if (isDefined(printerOverrideHeight)) {
          return <span>{printerOverrideHeight} (Override)</span>;
        }
        if (isDefined(defaultHeight)) {
          return <span>{defaultHeight} (Default)</span>;
        }

        return <span>N/A</span>;
      },
    },
    {
      Header: 'Actions',
      accessor: '',
      resizable: false,
      minWidth: 40,
      style: { overflow: 'visible' },
      Cell: (cellProps) => (
        <ShowMoreActionsButton
          actions={[
            {
              text: 'Edit',
              action: this.openEditModal.bind(null, cellProps.value),
              isVisible: true,
            },
            {
              text: 'Delete',
              action: this.openDeleteModal.bind(null, cellProps.value),
              isDangerous: true,
              isVisible: true,
            },
          ]}
        />
      ),
    },
  ];

  render() {
    const {
      decorationLocations,
      dtgArtworkSettings,
    } = this.props;
    const {
      editDtgArtworkSettingModalIsOpen,
      addDtgArtworkSettingModalIsOpen,
      deleteDtgArtworkSettingModalIsOpen,
      selectedSetting,
    } = this.state;

    return (
      <div className='container'>
        <DtgArtworkSettingsAddModal
          isOpen={addDtgArtworkSettingModalIsOpen}
          closeModal={this.closeModal}
          onSubmit={this.onEditArtworkSettings}
          decorationLocations={decorationLocations}
        />
        <DtgArtworkSettingsEditModal
          isOpen={editDtgArtworkSettingModalIsOpen}
          closeModal={this.closeModal}
          onSubmit={this.onEditArtworkSettings}
          initialValues={selectedSetting}
          decorationLocations={decorationLocations}
        />
        <DtgArtworkSettingsDeleteModal
          isOpen={deleteDtgArtworkSettingModalIsOpen}
          closeModal={this.closeModal}
          selectedSetting={selectedSetting}
          onDelete={this.onDeleteArtworkSettings}
        />
        <div className='table-options w-100'>
          <div className='flex' />
          <div className='flex'>
            <Button
              type={'primary'}
              text={'Add New Setting'}
              onClick={this.openAddModal}
              classes={'colors__add-btn'}
            />
          </div>
        </div>
        <br />
        <div className='sheet'>
          <DtgArtworkSettingsTable
            data={dtgArtworkSettings}
            columns={this.getColumns()}
            showPagination={false}
          />
        </div>
      </div>
    );
  }
}

DtgArtworkSettings.propTypes = {
  decorationLocations: PropTypes.array.isRequired,
  dtgArtworkSettings: PropTypes.array.isRequired,
};

const mapStateToProps = ({ productCatalog }) => ({
  decorationLocations: productCatalog.decorationLocations,
  dtgArtworkSettings: productCatalog.dtgArtworkSettings,
});

export default connect(mapStateToProps)(DtgArtworkSettings);
