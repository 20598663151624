import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '@redux/index/reducers';
import * as productCatalogActions from '@redux/productCatalog/actions';
import * as orderManagementActions from '@redux/orderManagement/actions';
import * as supportActions from '@redux/support/actions';
import {
  applyCoupon,
  calculateApplyCouponPrice,
  calculateCreatePersonalizationPrice,
  calculateEditPersonalizationPrice,
  calculateRemovePersonalizationPrice,
  calculateReplaceItemPrice,
  cancelOrderItem,
  changeArtwork,
  changeDecorationMethod,
  createPersonalization,
  refundOrderItem,
  refundOrderItemWithCancel,
  removeCouponReservation,
  removePersonalization,
  replaceOrderItem,
  reserveCoupon,
  updatePersonalization,
} from '@APICalls/orderManagement/actions';
import { materialSwal, swalAlert, toggleArrayMember } from '@util/componentHelper';
import { reset } from 'redux-form';
import { orderArtworkForm, orderItemRemovePersonalizationForm, refundOrderItemForm, replaceItemBySkuForm } from '@constants/reduxForms';
import { accountingActionTypeEnum, couponTypeEnum } from '@constants/enums/orderEnums';
import { refundReasonEnum } from '@constants/enums/refundEnums';
import { flagTypeEnum } from '@constants/enums/commonEnums';
import ImageModal from '@components/shared/Modal/ImageModal';
import Expander from '@components/shared/Buttons/Expander';
import SearchFilter from '@components/shared/Inputs/SearchFilter';
import ItemInfoModal from '@components/shared/ItemInfo/ItemInfoModal';
import { getOrderItemActionType, getOrderSubtotalRefundAmountLeft } from '@util/paymentHelper';
import OrderManagementChoosePersonalizationToEditModal from '../OrderManagementPersonalization/OrderManagementChoosePersonalizationToEditModal';
import OrderManagementAddPersonalizationModal from '../OrderManagementPersonalization/OrderManagementAddPersonalizationModal';
import OrderManagementEditPersonalizationModal from '../OrderManagementPersonalization/OrderManagementEditPersonalizationModal';
import OrderManagementRemovePersonalizationPriceConfirmationModal from '../OrderManagementPersonalization/OrderManagementRemovePersonalizationPriceConfirmationModal';
import OrderManagementDetailsItems from './OrderManagementDetailsItems';
import OrderManagementAddCouponModal from './OrderManagementDetailsModals/OrderManagementAddCouponModal';
import OrderManagementApplyCouponConfirmationModal from './OrderManagementDetailsModals/OrderManagementApplyCouponConfirmationModal';
import OrderManagementChangeDecorationMethodModal from './OrderManagementDetailsModals/OrderManagementChangeDecorationMethodModal';
import OrderManagementCouponDetailsModal from './OrderManagementDetailsModals/OrderManagementCouponDetailsModal';
import OrderManagementDetailsReplaceBySkuConfirmationNewModal from './OrderManagementDetailsModals/OrderManagementDetailsReplaceBySkuConfirmationNewModal';
import OrderManagementDetailsReplaceBySkuModalNew from './OrderManagementDetailsModals/OrderManagementDetailsReplaceBySkuModalNew';
import OrderManagementDetailsReplaceItemModalNew from './OrderManagementDetailsModals/OrderManagementDetailsReplaceItemModalNew';
import OrderManagementOrderFinancialsModal from './OrderManagementDetailsModals/OrderManagementOrderFinancialsModal';
import OrderManagementOrderItemFinancialsModal from './OrderManagementDetailsModals/OrderManagementOrderItemFinancialsModal';
import OrderManagementDetailsOrderSummary from './OrderManagementDetailsOrderSummary';
import OrderManagementDetailsReplaceBySkuConfirmationModalOld from './OrderManagementDetailsModals/OrderManagementDetailsReplaceBySkuConfirmationModalOld';
import OrderManagementChangeArtworkModal from '../OrderManagementChangeArtwork/OrderManagementChangeArtworkModal';
import OrderManagementRemovePersonalizationModal from '../OrderManagementPersonalization/OrderManagementRemovePersonalizationModal';
import OrderManagementAddPersonalizationPriceConfirmationModal from '../OrderManagementPersonalization/OrderManagementAddPersonalizationPriceConfirmationModal';
import OrderManagementEditPersonalizationPriceConfirmationModal from '../OrderManagementPersonalization/OrderManagementEditPersonalizationPriceConfirmationModal';
import OrderManagementOrderItemRefundModalNew from '../OrderManagementRefund/OrderManagementOrderItemRefundModal';
import {
  AccountingActionTypeEnum,
  AddPersonalizationCommand,
  DecorationMethodEnum,
  EditPersonalizationCommand,
  OrderDetailsDto,
  OrderItemDetailsDto,
  OrderShipmentDto,
  PersonalizationItemModel,
  RemovePersonalizationCommand,
  UpdateOrderItemPriceCalculationDto,
  UpdateOrderItemPriceCalculationDto as FFReplaceItemPriceCalculation,
} from '@api/fulfillment/models';
import { ReplaceItemBySkuFormData } from '@models/forms/OrderManagement/ReplaceItemBySkuFormData';
import { LockerProductsCssLogoViewModel } from '@api/squadlockerServices/models';
import {
  ApplyCouponCommand,
  CalculateAddPersonalizationPriceQuery,
  CalculateApplyCouponPriceChangeQuery,
  CalculateEditPersonalizationPriceQuery,
  Logo as LogoDto,
  OrderDetailsDto as CoreOrderDetails,
  OrderItemPricingDetails,
  OrderPricingDetails,
  OrderPricingDetailsDto,
} from '@api/orders/models';
import { OrderItemRemovePersonalizationFormData } from '@models/forms/OrderManagement/OrderItemRemovePersonalizationFormData';
import { OrderArtworkFormData } from '@models/forms/OrderManagement/OrderArtworkFormData';
import { RefundOrderItemFormData } from '@models/forms/OrderManagement/RefundOrderItemFormData';
import { PersonalizationFormData } from '@models/forms/OrderManagement/PersonalizationFormData';
import { DecorationLocationDto } from '@api/productCatalog/models';
import { centsToDollars } from '@util/numberHelpers';
import {
  usePostHomefieldApiOrdermanagementOrderscouponapply,
  usePutHomefieldApiOrderitemscalculateaddpersonalization,
  usePutHomefieldApiOrderitemscalculateeditpersonalization,
  usePutHomefieldApiOrderitemscalculateremovepersonalization,
  usePutHomefieldApiOrdermanagementOrderscouponapplypricecalculation,
} from '@api/orders/orders';
import {
  usePostHomefieldApiOrdermanagementOrderspersonalizationadd,
  usePostHomefieldApiOrdermanagementOrderspersonalizationremove,
  usePutHomefieldApiOrdermanagementOrderspersonalizationedit,
} from '@api/fulfillment/order-management';
import OrderManagementChoosePersonalizationToRemoveModal from '../OrderManagementPersonalization/OrderManagementChoosePersonalizationToRemoveModal';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { OrderPaymentMethod } from '../OrderPaymentMethod';
interface OwnProps {
  order: OrderDetailsDto;
  decorationLocations: Array<DecorationLocationDto>;
  shipmentNumbersForItems: any;
  isOrderPartiallyShipped: boolean;
  fetchOrder: () => void;
  fetchCoreOrderPricingDetails: () => void;
  coreOrderPricingDetails: OrderPricingDetailsDto | undefined;
  orderExistsOnCore: boolean;
  shipments: Nullable<Array<OrderShipmentDto>>;
  coreOrder: CoreOrderDetails;
  resetPageInfo: () => void;
}

const mapStateToProps = ({
  productCatalog,
  orderManagement,
  support,
}: RootState) => ({
  settings: support.settings,
  orderItemProduct: productCatalog.orderItemProduct,
  decorationLocations: productCatalog.decorationLocations,
  colors: productCatalog.colors,
  colorsDictionary: productCatalog.colorsDictionary,
  orderFlags: orderManagement.orderFlags,
  lockerArtworks: orderManagement.lockerArtworks,
});

const mapDispatchToProps = {
  getColors: productCatalogActions.getColors,
  getDecorationLocations: productCatalogActions.getDecorationLocations,
  getOrderItemProductBySku: productCatalogActions.getOrderItemProductBySku,
  fetchOrderFlags: orderManagementActions.fetchOrderFlags,
  getArtworksForLocker: orderManagementActions.getArtworksForLocker,
  fetchSettings: supportActions.fetchSettings,
  reset,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = OwnProps & ConnectedProps<typeof connector>;

const OrderManagementDetails = React.memo<Props>(({
  order,
  orderItemProduct,
  decorationLocations,
  shipmentNumbersForItems,
  isOrderPartiallyShipped,
  shipments,
  orderFlags,
  lockerArtworks,
  colors,
  colorsDictionary,
  coreOrderPricingDetails,
  settings,
  getColors,
  getDecorationLocations,
  getOrderItemProductBySku,
  fetchOrderFlags,
  getArtworksForLocker,
  fetchSettings,
  fetchOrder,
  orderExistsOnCore,
  coreOrder,
  resetPageInfo,
}) => {
  const [
    addPersonalizationModalIsOpen,
    setAddPersonalizationModalIsOpen,
  ] = useState<boolean>(false);
  const [
    addPersonalizationPriceConfirmationModalIsOpen,
    setAddPersonalizationPriceConfirmationModalIsOpen,
  ] = useState<boolean>(false);
  const [
    applyCouponModalIsOpen,
    setApplyCouponModalIsOpen,
  ] = useState<boolean>(false);
  const [
    applyCouponConfirmationModalIsOpen,
    setApplyCouponConfirmationModalIsOpen,
  ] = useState<boolean>(false);
  const [
    changeArtworkModalIsOpen,
    setChangeArtworkModalIsOpen,
  ] = useState<boolean>(false);
  const [
    choosePersonalizationToEditModalIsOpen,
    setChoosePersonalizationToEditModalIsOpen,
  ] = useState<boolean>(false);
  const [
    choosePersonalizationToRemoveModalIsOpen,
    setChoosePersonalizationToRemoveModalIsOpen,
  ] = useState<boolean>(false);
  const [
    couponDetailsModalIsOpen,
    setCouponDetailsModalIsOpen,
  ] = useState<boolean>(false);
  const [
    editPersonalizationModalIsOpen,
    setEditPersonalizationModalIsOpen,
  ] = useState<boolean>(false);
  const [
    editPersonalizationPriceConfirmationModalIsOpen,
    setEditPersonalizationPriceConfirmationModalIsOpen,
  ] = useState<boolean>(false);
  const [
    orderItemFinancialsModalIsOpen,
    setOrderItemFinancialsModalIsOpen,
  ] = useState<boolean>(false);
  const [
    itemInfoModalIsOpen,
    setItemInfoModalIsOpen,
  ] = useState<boolean>(false);
  const [
    orderFinancialsModalIsOpen,
    setOrderFinancialsModalIsOpen,
  ] = useState<boolean>(false);
  const [
    previewModalIsOpen,
    setPreviewModalIsOpen,
  ] = useState<boolean>(false);
  const [
    refundOrderItemModalIsOpen,
    setRefundOrderItemModalIsOpen,
  ] = useState<boolean>(false);
  const [
    removePersonalizationModalIsOpen,
    setRemovePersonalizationModalIsOpen,
  ] = useState<boolean>(false);
  const [
    removePersonalizationPriceConfirmationModalIsOpen,
    setRemovePersonalizationPriceConfirmationModalIsOpen,
  ] = useState<boolean>(false);
  const [
    replaceBySkuModalIsOpen,
    setReplaceBySkuModalIsOpen,
  ] = useState<boolean>(false);
  const [
    replaceBySkuConfirmationModalIsOpen,
    setReplaceBySkuConfirmationModalIsOpen,
  ] = useState<boolean>(false);
  const [
    replaceItemModalIsOpen,
    setReplaceItemModalIsOpen,
  ] = useState<boolean>(false);
  const [
    replaceBySkuForm,
    setReplaceBySkuForm,
  ] = useState<Nullable<ReplaceItemBySkuFormData>>(null);
  const [
    orderItemReplacePriceCalculation,
    setOrderItemReplacePriceCalculation,
  ] = useState<Nullable<OrderItemPricingDetails | FFReplaceItemPriceCalculation>>(null);
  const [
    personalizationPriceCalculation,
    setPersonalizationPriceCalculation,
  ] = useState<Nullable<FFReplaceItemPriceCalculation | OrderItemPricingDetails>>(null);
  const [
    applyCouponPriceCalculation,
    setApplyCouponPriceCalculation,
  ] = useState<Nullable<UpdateOrderItemPriceCalculationDto | OrderPricingDetails>>(null);
  const [
    changeDecorationMethodModalIsOpen,
    setChangeDecorationMethodModalIsOpen,
  ] = useState<boolean>(false);
  const [
    quantityToReplace,
    setQuantityToReplace,
  ] = useState<number>(0);
  const [
    skuInput,
    setSkuInput,
  ] = useState<string>('');
  const [
    imageUrl,
    setImageUrl,
  ] = useState<string | undefined>(undefined);
  const [
    orderItem,
    setOrderItem,
  ] = useState<Nullable<OrderItemDetailsDto>>(null);
  const [
    personalization,
    setPersonalization,
  ] = useState<Nullable<PersonalizationItemModel>>(null);
  const [
    isCancelAction,
    setIsCancelAction,
  ] = useState<boolean>(false);
  const [
    isFlaggedArtworkChange,
    setIsFlaggedArtworkChange,
  ] = useState<boolean>(false);
  const [
    selectedLogo,
    setSelectedLogo,
  ] = useState<Nullable<LogoDto | LockerProductsCssLogoViewModel>>(null);
  const [
    resetSearchOrderItemsInput,
    setResetSearchOrderItemsInput,
  ] = useState<boolean>(false);
  const [
    collapseAll,
    setCollapseAll,
  ] = useState<boolean>(false);
  const [
    filteredItems,
    setFilteredItems,
  ] = useState<Array<OrderItemDetailsDto>>(order?.items ?? []);
  const [
    collapsedItemIds,
    setCollapsedItemIds,
  ] = useState<Array<Array<number>>>([]);
  const [
    isFilteringItems,
    setIsFilteringItems,
  ] = useState<boolean>(false);
  const [
    personalizationForm,
    setPersonalizationForm,
  ] = useState<Nullable<PersonalizationFormData | OrderItemRemovePersonalizationFormData>>(null);
  const [
    couponCode,
    setCouponCode,
  ] = useState<Nullable<string>>(null);
  const [
    couponType,
    setCouponType,
  ] = useState<Nullable<string>>(null);
  const [
    couponValue,
    setCouponValue,
  ] = useState<Nullable<number>>(null);
  const [
    couponAppliesToShipping,
    setCouponAppliesToShipping,
  ] = useState<Nullable<boolean>>(null);
  const [
    coreSynced,
    setCoreSynced,
  ] = useState<boolean>(false);
  const [
    variationId,
    setVariationId,
  ] = useState<Nullable<number>>(null);
  const [
    applyCouponNextAction,
    setApplyCouponNextAction,
  ] = useState<Nullable<{ apply: boolean; couponType: any }>>(null);
  const [
    removePersonalizationCallback,
    setRemovePersonalizationCallback,
  ] = useState<boolean>(false);
  const [
    editPersonalizationCallback,
    setEditPersonalizatonCallback,
  ] = useState<boolean>(false);
  const [
    orderItemPricingDetails,
    setOrderItemPricingDetails,
  ] = useState<Nullable<OrderItemPricingDetails>>(null);
  const [
    replaceBySkuBasePrice,
    setReplaceBySkuBasePrice,
  ] = useState<Nullable<number>>(null);
  const [
    personalizationToEdit,
    setPersonalizationToEdit,
  ] = useState<Nullable<PersonalizationItemModel>>(null);
  const [
    personalizationToRemove,
    setPersonalizationToRemove,
  ] = useState<Nullable<PersonalizationItemModel>>(null);

  const { hfEnableChargeForPRDOrders } = useFlags();

  const {
    data: addPersonalizationCorePriceCalculationResponse,
    mutateAsync: fetchCoreAddPersonalizationPriceCalculation,
  } = usePutHomefieldApiOrderitemscalculateaddpersonalization();

  const {
    data: addPersonalizationResponse,
    mutateAsync: sendAddPersonalizationRequest,
  } = usePostHomefieldApiOrdermanagementOrderspersonalizationadd();

  const {
    data: removePersonalizationCorePriceCalculation,
    mutateAsync: fetchRemovePersonalizationCorePriceCalculation,
  } = usePutHomefieldApiOrderitemscalculateremovepersonalization();

  const {
    data: removePersonalizationResponse,
    mutateAsync: sendRemovePersonalizationRequest,
  } = usePostHomefieldApiOrdermanagementOrderspersonalizationremove();

  const {
    data: editPersonalizationCorePriceCalculationResponse,
    mutateAsync: fetchCoreEditPersonalizationPriceCalculation,
  } = usePutHomefieldApiOrderitemscalculateeditpersonalization();

  const {
    data: editPersonalizationResponse,
    mutateAsync: sendEditPersonalizationRequest,
  } = usePutHomefieldApiOrdermanagementOrderspersonalizationedit();

  const {
    data: calculateApplyCouponPriceResponse,
    mutateAsync: sendApplyCouponCalculatePriceRequest,
  } = usePutHomefieldApiOrdermanagementOrderscouponapplypricecalculation();

  const {
    data: applyCouponResponse,
    mutateAsync: sendApplyCouponRequest,
  } = usePostHomefieldApiOrdermanagementOrderscouponapply();

  const calculateCollapsed = useCallback(() => {
    const filteredItemIds = filteredItems?.map((i) => i.ids);
    if (filteredItemIds!.length === 0) { return; }

    let collapsedCounter = 0;
    for (const item of filteredItemIds!) {
      if (collapsedItemIds.includes(item!)) {
        collapsedCounter++;
      }
    }

    if (collapsedCounter === filteredItemIds!.length && !collapseAll) {
      setCollapseAll(true);
    } else if (collapsedCounter === 0 && collapseAll) {
      setCollapseAll(false);
    }
  }, [
    collapseAll,
    collapsedItemIds,
    filteredItems,
  ]);

  const closeApplyCouponModal = useCallback(() => {
    setApplyCouponModalIsOpen(false);
    setCouponCode(null);
    setCouponType(null);
    setCouponValue(null);
    setCouponAppliesToShipping(null);
  }, []);

  const closeApplyCouponConfirmationModal = useCallback(() => {
    setApplyCouponConfirmationModalIsOpen(false);
    setApplyCouponPriceCalculation(null);
    setCouponCode(null);
    setCouponType(null);
    setCouponValue(null);
    setCouponAppliesToShipping(null);
  }, []);

  const couponApply = useCallback(async () => {
    const reserveRes = await reserveCoupon(couponCode);

    if (reserveRes?.success) {
      if (orderExistsOnCore) {
        const data = {
          data: ({
            orderId: order.orderNumber,
            couponValue,
            couponType,
            couponCode,
            couponAppliesToShipping,
          } as ApplyCouponCommand),
        };
        sendApplyCouponRequest(data);
      } else {
        const res = await applyCoupon(
          order.orderNumber,
          couponCode,
          couponType,
          couponValue,
          couponAppliesToShipping,
        );
        if (res?.success) {
          fetchOrder();
          resetPageInfo();
          materialSwal('Success', res.message, 'success');
          closeApplyCouponModal();
          closeApplyCouponConfirmationModal();
        } else {
          await removeCouponReservation(couponCode);
        }
      }
    }
  }, [
    couponCode,
    order.orderNumber,
    couponType,
    couponValue,
    couponAppliesToShipping,
    orderExistsOnCore,
    sendApplyCouponRequest,
    fetchOrder,
    resetPageInfo,
    closeApplyCouponModal,
    closeApplyCouponConfirmationModal,
  ]);

  const getProductBySku = useCallback(async (sku: string) => {
    await getOrderItemProductBySku(sku);
  }, [getOrderItemProductBySku]);

  const getSettings = useCallback(async () => {
    await fetchSettings();
  }, [fetchSettings]);

  const openOrderItemRefundModal = useCallback((item: OrderItemDetailsDto, isCancel: boolean) => {
    setOrderItem(item);
    setRefundOrderItemModalIsOpen(true);
    setIsCancelAction(isCancel);
  }, []);

  const closeOrderItemRefundModal = useCallback(() => {
    reset(refundOrderItemForm);
    setOrderItem(null);
    setRefundOrderItemModalIsOpen(false);
  }, []);

  const openPreviewModal = useCallback((image: string) => {
    setPreviewModalIsOpen(true);
    setImageUrl(image);
  }, []);

  const closePreviewModal = useCallback(() => {
    setPreviewModalIsOpen(false);
    setImageUrl(undefined);
  }, []);

  const openAddPersonalizationModal = useCallback((item: OrderItemDetailsDto) => {
    getProductBySku(item?.sku ?? '');
    getSettings();

    setAddPersonalizationModalIsOpen(true);
    setOrderItem(item);
  }, [
    getProductBySku,
    getSettings,
  ]);

  const closeAddPersonalizationModal = useCallback(() => {
    setAddPersonalizationModalIsOpen(false);
  }, []);

  const closeChoosePersonalizationToEditModal = useCallback((resetOrderItem = true) => {
    if (resetOrderItem) {
      setChoosePersonalizationToEditModalIsOpen(false);
      setOrderItem(null);
    } else {
      setChoosePersonalizationToEditModalIsOpen(false);
    }
  }, []);

  const closeChoosePersonalizationToRemoveModal = useCallback((resetOrderItem = true) => {
    if (resetOrderItem) {
      setChoosePersonalizationToRemoveModalIsOpen(false);
      setOrderItem(null);
    } else {
      setChoosePersonalizationToRemoveModalIsOpen(false);
    }
  }, []);

  const openEditPersonalizationModal = useCallback((newPersonalization: PersonalizationItemModel) => {
    closeChoosePersonalizationToEditModal(false);
    getSettings();

    setPersonalizationToEdit(newPersonalization);

    setPersonalization(newPersonalization);
    setEditPersonalizationModalIsOpen(true);
  }, [
    closeChoosePersonalizationToEditModal,
    getSettings,
  ]);

  const openChoosePersonalizationToEditModal = useCallback((item: OrderItemDetailsDto) => {
    const activePersonalizations = item?.personalizations?.filter((p) => !p.canceled);

    getProductBySku(item?.sku ?? '');
    if (activePersonalizations && activePersonalizations.length > 1) {
      setChoosePersonalizationToEditModalIsOpen(true);
      setOrderItem(item);
    } else if (activePersonalizations?.length === 1) {
      setOrderItem(item);
      openEditPersonalizationModal(activePersonalizations[0]);
    }
  }, [
    getProductBySku,
    openEditPersonalizationModal,
  ]);

  const openRemovePersonalizationModal = useCallback((newPersonalization: PersonalizationItemModel) => {
    closeChoosePersonalizationToRemoveModal(false);
    getSettings();

    setPersonalizationToRemove(newPersonalization);

    setPersonalization(newPersonalization);
    setRemovePersonalizationModalIsOpen(true);
  }, [
    closeChoosePersonalizationToRemoveModal,
    getSettings,
  ]);

  const openChoosePersonalizationToRemoveModal = useCallback((item: OrderItemDetailsDto) => {
    const activePersonalizations = item?.personalizations?.filter((p) => !p.canceled);

    getProductBySku(item?.sku ?? '');
    if ((activePersonalizations && activePersonalizations.length > 1) || !orderExistsOnCore) {
      setChoosePersonalizationToRemoveModalIsOpen(true);
      setOrderItem(item);
    } else if (activePersonalizations?.length === 1) {
      setOrderItem(item);
      openRemovePersonalizationModal(activePersonalizations[0]);
    }
  }, [
    getProductBySku,
    openRemovePersonalizationModal,
    orderExistsOnCore,
  ]);

  const closeEditPersonalizationModal = useCallback(() => {
    setEditPersonalizationModalIsOpen(false);
  }, []);

  const closeRemovePersonalizationModal = useCallback(() => {
    setRemovePersonalizationModalIsOpen(false);
  }, []);

  const openReplaceItemBySkuModal = useCallback((
    task: OrderItemDetailsDto,
    sku: string,
    logo: Nullable<LogoDto | LockerProductsCssLogoViewModel>,
    synced: boolean,
  ) => {
    setReplaceBySkuModalIsOpen(true);
    if (sku) {
      setSkuInput(sku);
    } else {
      setSkuInput(task?.sku ?? '');
    }
    setOrderItem(task);
    setSelectedLogo(logo);
    setCoreSynced(synced ? synced : false);
  }, []);

  const calculateReplaceItem = useCallback(async (form: ReplaceItemBySkuFormData) => {
    const { quantity } = form;
    setQuantityToReplace(quantity);

    let itemIds = orderItem?.ids;
    const coreId = orderItem?.coreId;

    if (quantity) {
      itemIds = itemIds?.slice(0, quantity);
    }

    if (form.sku === orderItem?.sku) {
      swalAlert('Please choose a different SKU.');

      return;
    }

    const result = await calculateReplaceItemPrice(
      order.orderNumber,
      itemIds,
      coreId,
      selectedLogo,
      form,
      orderExistsOnCore,
      variationId,
    );

    return result;
  }, [
    order.orderNumber,
    orderItem,
    orderExistsOnCore,
    selectedLogo,
    variationId,
  ]);

  const openReplaceBySkuConfirmationModal = useCallback(async (form: ReplaceItemBySkuFormData) => {
    const orderItemReplaceCalculation = await calculateReplaceItem(form);

    if (!orderItemReplaceCalculation) { return; }

    setReplaceBySkuModalIsOpen(false);
    setReplaceBySkuConfirmationModalIsOpen(true);
    setOrderItemReplacePriceCalculation(orderItemReplaceCalculation);
    setReplaceBySkuForm(form);
  }, [calculateReplaceItem]);

  const closeReplaceItemBySkuModal = useCallback(() => {
    setReplaceBySkuModalIsOpen(false);
    setReplaceBySkuBasePrice(null);
  }, []);

  const calculateCreatePersonalization = useCallback(async (form: PersonalizationFormData) => {
    const result = await calculateCreatePersonalizationPrice(
      order.orderNumber,
      form,
      orderItem?.ids?.slice(0, form.quantity),
    );

    return result;
  }, [
    order.orderNumber,
    orderItem?.ids,
  ]);

  const openAddPersonalizationPriceConfirmationModal = useCallback(async (form: PersonalizationFormData) => {
    if (orderExistsOnCore) {
      fetchCoreAddPersonalizationPriceCalculation({
        data: ({
          orderNumber: order.orderNumber,
          orderItemId: orderItem?.coreId,
          name: form.text,
          number: form.number,
          decorationMethod: orderItem?.styleDecorationMethod,
          decorationLocation: form.decorationLocation,
          colorId: form.colorId,
          personalizationBasePrice: form.personalizationBasePrice,
        } as CalculateAddPersonalizationPriceQuery),
      });
    } else {
      const priceCalculation = await calculateCreatePersonalization(form);
      setAddPersonalizationPriceConfirmationModalIsOpen(true);
      setPersonalizationPriceCalculation(priceCalculation);
    }
    closeAddPersonalizationModal();
    setPersonalizationForm(form);
  }, [
    calculateCreatePersonalization,
    closeAddPersonalizationModal,
    fetchCoreAddPersonalizationPriceCalculation,
    order.orderNumber,
    orderExistsOnCore,
    orderItem?.coreId,
    orderItem?.styleDecorationMethod,
  ]);

  const closeAddPersonalizationPriceConfirmationModal = useCallback(() => {
    setAddPersonalizationPriceConfirmationModalIsOpen(false);
    setOrderItem(null);
    setPersonalizationForm(null);
    setPersonalizationPriceCalculation(null);
  }, []);

  const calculateEditPersonalization = useCallback(async (form: PersonalizationFormData) => {
    const result = await calculateEditPersonalizationPrice(
      order.orderNumber,
      form,
      personalization?.ids?.slice(0, form.quantity),
    );

    return result;
  }, [
    order.orderNumber,
    personalization?.ids,
  ]);

  const openEditPersonalizationPriceConfirmationModal = useCallback(async (form: PersonalizationFormData) => {
    if (orderExistsOnCore) {
      fetchCoreEditPersonalizationPriceCalculation({
        data: ({
          orderNumber: order.orderNumber,
          orderItemId: orderItem?.coreId,
          name: form.text,
          number: form.number,
          decorationMethod: orderItem?.styleDecorationMethod,
          oldDecorationLocation: personalizationToEdit?.decorationLocation,
          decorationLocation: form.decorationLocation,
          colorId: form.colorId,
          personalizationBasePrice: form.personalizationBasePrice,
        } as CalculateEditPersonalizationPriceQuery),
      });
    } else {
      const priceCalculation = await calculateEditPersonalization(form);

      setEditPersonalizationPriceConfirmationModalIsOpen(true);
      setPersonalizationPriceCalculation(priceCalculation);
    }
    setPersonalizationForm(form);
    setEditPersonalizationModalIsOpen(false);
  }, [
    calculateEditPersonalization,
    fetchCoreEditPersonalizationPriceCalculation,
    order.orderNumber,
    orderExistsOnCore,
    orderItem,
    personalizationToEdit?.decorationLocation,
  ]);

  const closeEditPersonalizationPriceConfirmationModal = useCallback(() => {
    setEditPersonalizationPriceConfirmationModalIsOpen(false);
    setOrderItem(null);
    setPersonalization(null);
    setPersonalizationForm(null);
    setPersonalizationPriceCalculation(null);
  }, []);

  const calculateRemovePersonalization = useCallback(async (form: OrderItemRemovePersonalizationFormData) => {
    const {
      quantity,
      paymentType,
      refundAmount,
    } = form;

    const itemPersonalization = orderItem?.personalizations?.find((pers) => pers.canceled === null);

    const result = await calculateRemovePersonalizationPrice(
      itemPersonalization?.ids?.slice(0, quantity),
      order.orderNumber,
      paymentType,
      refundAmount,
    );

    return result;
  }, [
    order.orderNumber,
    orderItem?.personalizations,
  ]);

  const openRemovePersonalizationPriceConfirmationModal = useCallback(async (form: OrderItemRemovePersonalizationFormData) => {
    if (orderExistsOnCore) {
      const model = {
        orderNumber: order.orderNumber!,
        orderItemId: orderItem?.coreId ?? 0,
        decorationLocation: personalizationToRemove?.decorationLocation,
        refundAmount: form.paymentType === AccountingActionTypeEnum.NoChange
          ? 0
          : form.refundAmount,
      };

      setPersonalizationForm(form);

      fetchRemovePersonalizationCorePriceCalculation({
        data: model,
      });
    } else {
      const priceCalculation = await calculateRemovePersonalization(form);

      setRemovePersonalizationPriceConfirmationModalIsOpen(true);
      setPersonalizationPriceCalculation(priceCalculation);
      setPersonalizationForm(form);
      setRemovePersonalizationModalIsOpen(false);
    }
  }, [
    calculateRemovePersonalization,
    fetchRemovePersonalizationCorePriceCalculation,
    order.orderNumber,
    orderExistsOnCore,
    orderItem?.coreId,
    personalizationToRemove?.decorationLocation,
  ]);

  const closeRemovePersonalizationPriceConfirmationModal = useCallback(() => {
    reset(orderItemRemovePersonalizationForm);

    setRemovePersonalizationPriceConfirmationModalIsOpen(false);
    setOrderItem(null);
    setPersonalizationPriceCalculation(null);
    setPersonalizationForm(null);
  }, []);

  const editPersonalization = useCallback(async () => {
    if (orderExistsOnCore) {
      sendEditPersonalizationRequest({
        data: ({
          orderNumber: order.orderNumber,
          orderItemId: orderItem?.coreId,
          name: (personalizationForm as PersonalizationFormData)!.text!,
          number: (personalizationForm as PersonalizationFormData)!.number!,
          decorationMethod: orderItem?.styleDecorationMethod,
          oldDecorationLocation: personalizationToEdit?.decorationLocation,
          decorationLocation: (personalizationForm as PersonalizationFormData)!.decorationLocation!,
          colorId: (personalizationForm as PersonalizationFormData)!.colorId!,
          personalizationBasePrice: (personalizationForm as PersonalizationFormData)!.personalizationBasePrice!,
        } as EditPersonalizationCommand),
      });
    } else {
      const res = await updatePersonalization(
        order.orderNumber,
        personalizationForm,
        personalization?.ids?.slice(0, personalizationForm?.quantity),
      );

      if (res?.success) {
        resetPageInfo();
        materialSwal('Success', res.message, 'success');
        closeEditPersonalizationModal();
        closeEditPersonalizationPriceConfirmationModal();
      }
    }
  }, [
    closeEditPersonalizationModal,
    closeEditPersonalizationPriceConfirmationModal,
    order.orderNumber,
    orderExistsOnCore,
    orderItem?.coreId,
    orderItem?.styleDecorationMethod,
    personalization?.ids,
    personalizationForm,
    personalizationToEdit?.decorationLocation,
    resetPageInfo,
    sendEditPersonalizationRequest,
  ]);

  const addPersonalization = useCallback(async () => {
    if (orderExistsOnCore) {
      sendAddPersonalizationRequest({
        data: ({
          orderNumber: order.orderNumber,
          orderItemId: orderItem?.coreId,
          name: (personalizationForm as PersonalizationFormData)!.text!,
          number: (personalizationForm as PersonalizationFormData)!.number!,
          decorationMethod: orderItem?.styleDecorationMethod,
          decorationLocation: (personalizationForm as PersonalizationFormData)!.decorationLocation!,
          colorId: (personalizationForm as PersonalizationFormData)!.colorId!,
          personalizationBasePrice: (personalizationForm as PersonalizationFormData)!.personalizationBasePrice!,
        } as AddPersonalizationCommand),
      });
    } else {
      const res = await createPersonalization(
        order.orderNumber,
        personalizationForm,
        orderItem?.ids?.slice(0, personalizationForm?.quantity),
      );

      if (res?.success) {
        resetPageInfo();
        materialSwal('Success', res.message, 'success');
        closeAddPersonalizationModal();
        closeAddPersonalizationPriceConfirmationModal();
      }
    }
  }, [
    closeAddPersonalizationModal,
    closeAddPersonalizationPriceConfirmationModal,
    order.orderNumber,
    orderExistsOnCore,
    orderItem,
    personalizationForm,
    resetPageInfo,
    sendAddPersonalizationRequest,
  ]);

  const removePersonalizationFromItems = useCallback(async () => {
    if (orderExistsOnCore) {
      const data = {
        data: ({
          orderNumber: order.orderNumber,
          orderItemId: orderItem?.coreId,
          reason: '',
          refundAmount: (personalizationForm as OrderItemRemovePersonalizationFormData)?.paymentType
              === AccountingActionTypeEnum.NoChange
            ? 0
            : (personalizationForm as OrderItemRemovePersonalizationFormData)!.refundAmount! ?? 0,
          decorationLocation: personalizationToRemove?.decorationLocation,
        } as RemovePersonalizationCommand),
      };
      sendRemovePersonalizationRequest(data);
    } else {
      const {
        quantity,
        paymentType,
        refundAmount,
      } = personalizationForm as OrderItemRemovePersonalizationFormData;

      const itemPersonalization = orderItem?.personalizations?.find((pers) => pers.canceled === null);

      const res = await removePersonalization(
        itemPersonalization?.ids?.slice(0, quantity),
        order.orderNumber,
        paymentType,
        refundAmount,
      );

      if (res?.success) {
        resetPageInfo();
        materialSwal('Success', res.message, 'success');
        closeRemovePersonalizationModal();
        closeRemovePersonalizationPriceConfirmationModal();
      }
    }
  }, [
    closeRemovePersonalizationModal,
    closeRemovePersonalizationPriceConfirmationModal,
    order.orderNumber,
    orderExistsOnCore,
    orderItem?.coreId,
    orderItem?.personalizations,
    personalizationForm,
    personalizationToRemove?.decorationLocation,
    resetPageInfo,
    sendRemovePersonalizationRequest,
  ]);

  const closeReplaceBySkuConfirmationModal = useCallback(() => {
    reset(replaceItemBySkuForm);

    setReplaceBySkuConfirmationModalIsOpen(false);
    setSkuInput('');
    setOrderItem(null);
    setOrderItemReplacePriceCalculation(null);
    setCoreSynced(false);
  }, []);

  const refundOrderItemModalCallback = useCallback(async (form: RefundOrderItemFormData) => {
    const {
      reason,
      reasonOther,
      quantity,
      actionType,
    } = form;

    const refundAmount = (actionType !== accountingActionTypeEnum.OrderItemPartialRefund) ? 0 : form.amount;

    let ids = orderItem?.ids;

    if (quantity) {
      ids = ids?.slice(0, quantity);
    }

    let realReason = reason;
    if (reason === refundReasonEnum.Other && reasonOther) {
      realReason = reasonOther;
    }

    if (orderExistsOnCore) {
      let refundResult;
      if (actionType !== accountingActionTypeEnum.NoChange) {
        refundResult = await refundOrderItem(
          order.orderNumber,
          orderItem?.coreId,
          realReason,
          actionType,
          refundAmount,
        );
      }

      let cancelResult;
      if (isCancelAction) {
        cancelResult = await cancelOrderItem(
          order.orderNumber,
          ids,
          realReason,
          actionType,
        );
      }

      /*
       * TODO: Is this the best solution? We should talk with UX
       * if there should be one or many popups and refactor code based on that input
       * or, we could redirect all request to single service that propagates it to other services
       */
      if (actionType !== accountingActionTypeEnum.NoChange && isCancelAction) {
        if (refundResult?.success && cancelResult?.success) {
          materialSwal('Success', refundResult?.message + '\n' + cancelResult?.message, 'success');
        } else if (refundResult?.success && !cancelResult) {
          materialSwal('Error', refundResult?.message + '\n' + 'Order item cancellation failed', 'error');
        } else if (!refundResult && cancelResult?.success) {
          materialSwal('Error', cancelResult?.message + '\n' + 'Order item refund failed', 'error');
        } else {
          materialSwal('Error', 'Order item refund and cancel failed', 'error');
        }
      } else if (actionType !== accountingActionTypeEnum.NoChange && !isCancelAction) {
        if (refundResult?.success) {
          materialSwal('Success', refundResult?.message, 'success');
        } else {
          materialSwal('Error', refundResult?.message, 'error');
        }
      } else if (actionType === accountingActionTypeEnum.NoChange && isCancelAction) {
        if (cancelResult?.success) {
          materialSwal('Success', cancelResult?.message, 'success');
        } else {
          materialSwal('Error', cancelResult?.message, 'error');
        }
      }

      if (cancelResult?.success || refundResult?.success) {
        resetPageInfo();
        closeOrderItemRefundModal();
      }
    } else {
      const result = await refundOrderItemWithCancel(
        order.orderNumber,
        ids,
        realReason,
        actionType,
        refundAmount,
        isCancelAction,
      );

      if (result?.success) {
        materialSwal('Success', result.message, 'success');
        resetPageInfo();
        closeOrderItemRefundModal();
      }
    }
  }, [
    closeOrderItemRefundModal,
    isCancelAction,
    order.orderNumber,
    orderItem,
    orderExistsOnCore,
    resetPageInfo,
  ]);

  const replace = useCallback(async () => {
    const formData = replaceBySkuForm;
    const quantity = formData?.quantity;

    let itemIds = orderItem?.ids;
    const coreId = orderItem?.coreId;

    if (quantity) {
      itemIds = itemIds?.slice(0, quantity);
    }

    if (formData?.sku === orderItem?.sku) {
      swalAlert('Please choose a different SKU.');

      return;
    }

    setCoreSynced(false);

    const result = await replaceOrderItem(
      order.orderNumber,
      itemIds,
      coreId,
      selectedLogo,
      formData,
      orderExistsOnCore,
      quantity,
      variationId,
    );

    if (result?.success) {
      materialSwal('Success', result.message, 'success');
      resetPageInfo();
      closeReplaceBySkuConfirmationModal();
    }
  }, [
    closeReplaceBySkuConfirmationModal,
    order.orderNumber,
    orderItem,
    replaceBySkuForm,
    orderExistsOnCore,
    resetPageInfo,
    selectedLogo,
    variationId,
  ]);

  const handleChangeSkuInput = useCallback((newSku: string) => {
    // Infinite loops occur without this check
    if (newSku !== skuInput) {
      setSkuInput(newSku);
    }
  }, [skuInput]);

  const handleSelectedProductChange = useCallback((newVariationId: number) => {
    if (variationId !== newVariationId) {
      setVariationId(newVariationId);
    }
  }, [variationId]);

  const openReplaceItemModal = useCallback((item: OrderItemDetailsDto) => {
    setReplaceItemModalIsOpen(true);
    setOrderItem(item);
  }, []);

  const closeReplaceItemModal = useCallback(() => {
    setReplaceItemModalIsOpen(false);
    setCoreSynced(false);
  }, []);

  const openCouponDetailsModal = useCallback(() => {
    setCouponDetailsModalIsOpen(true);
  }, []);

  const closeCouponDetailsModal = useCallback(() => {
    setCouponDetailsModalIsOpen(false);
  }, []);

  const openApplyCouponModal = useCallback(() => {
    setApplyCouponModalIsOpen(true);
  }, []);

  const openApplyCouponConfirmationModal = useCallback(async () => {
    if (orderExistsOnCore) {
      const data = {
        data: ({
          orderId: order.orderNumber,
          couponValue,
          couponType,
          couponCode,
          couponAppliesToShipping,
        } as CalculateApplyCouponPriceChangeQuery),
      };
      sendApplyCouponCalculatePriceRequest(data);
    } else {
      const priceCalculation = await calculateApplyCouponPrice(
        order.orderNumber,
        couponCode,
        couponType,
        couponValue,
        couponAppliesToShipping,
      );

      setApplyCouponConfirmationModalIsOpen(true);
      setApplyCouponModalIsOpen(false);
      setApplyCouponPriceCalculation(priceCalculation);
    }
  }, [
    couponAppliesToShipping,
    couponCode,
    couponType,
    couponValue,
    order.orderNumber,
    orderExistsOnCore,
    sendApplyCouponCalculatePriceRequest,
  ]);

  const getApplyCouponNextAction = useCallback((
    newCouponCode: string,
    newCouponType: string,
    newCouponValue: number,
    newCouponAppliesToShipping: boolean,
  ) => {
    setCouponCode(newCouponCode);
    setCouponType(newCouponType);
    setCouponValue(newCouponValue);
    setCouponAppliesToShipping(newCouponAppliesToShipping);
    setApplyCouponNextAction({
      apply: true,
      couponType: newCouponType,
    });
  }, []);

  const openItemInfoModal = useCallback((item: OrderItemDetailsDto) => {
    setItemInfoModalIsOpen(true);
    setOrderItem(item);
  }, []);

  const closeItemInfoModal = useCallback(() => {
    setItemInfoModalIsOpen(false);
    setOrderItem(null);
  }, []);

  const openChangeArtworkModal = useCallback((item: OrderItemDetailsDto, isFlaggedArtwork = false) => {
    getProductBySku(item?.sku ?? '');
    getArtworksForLocker(item?.lockerId ?? 0);

    setOrderItem(item);
    setChangeArtworkModalIsOpen(true);
    setIsFlaggedArtworkChange(isFlaggedArtwork);
  }, [
    getArtworksForLocker,
    getProductBySku,
  ]);

  const closeChangeArtworkModal = useCallback(() => {
    reset(orderArtworkForm);

    setOrderItem(null);
    setChangeArtworkModalIsOpen(false);
    setIsFlaggedArtworkChange(false);
  }, []);

  const getChangeArtworkLogo = useCallback(
    () => orderItem?.logos?.find((logo) => logo.canceled === null),
    [orderItem?.logos],
  );

  const artworkChange = useCallback(async (artworkForm: OrderArtworkFormData) => {
    const changeArtworkLogo = getChangeArtworkLogo();

    const ids = isFlaggedArtworkChange
      ? changeArtworkLogo?.ids?.filter(
        (orderItemId) =>
          orderFlags.find(
            (flag) => flag.orderLineItemId === orderItemId && flag.type === flagTypeEnum.WrongArtwork,
          ) !== undefined,
      )
      : changeArtworkLogo?.ids;

    const result = await changeArtwork(ids, artworkForm);

    if (result?.success) {
      materialSwal('Success', result.message, 'success');
      resetPageInfo();
      closeChangeArtworkModal();
    }
  }, [
    closeChangeArtworkModal,
    getChangeArtworkLogo,
    isFlaggedArtworkChange,
    orderFlags,
    resetPageInfo,
  ]);

  const openOrderFinancialsModal = useCallback(() => {
    setOrderFinancialsModalIsOpen(true);
  }, []);

  const closeOrderFinancialsModal = useCallback(() => {
    setOrderFinancialsModalIsOpen(false);
  }, []);

  const openOrderItemFinancialsModal = useCallback((
    item: OrderItemDetailsDto,
    itemPricingDetails: Nullable<OrderItemPricingDetails>,
  ) => {
    getProductBySku(item?.sku ?? '');

    setOrderItemFinancialsModalIsOpen(true);
    setOrderItem(item);
    setOrderItemPricingDetails(itemPricingDetails);
  }, [getProductBySku]);

  const closeOrderItemFinancialsModal = useCallback(() => {
    setOrderItemFinancialsModalIsOpen(false);
    setOrderItem(null);
    setOrderItemPricingDetails(null);
  }, []);

  const openChangeDecorationMethodModal = useCallback((item: OrderItemDetailsDto) => {
    setChangeDecorationMethodModalIsOpen(true);
    setOrderItem(item);
  }, []);

  const closeChangeDecorationMethodModal = useCallback(() => {
    setChangeDecorationMethodModalIsOpen(false);
    setOrderItem(null);
  }, []);

  const decorationMethodChange = useCallback(async () => {
    const result = await changeDecorationMethod(orderItem?.ids, orderItem?.styleDecorationMethod);

    if (result?.success) {
      materialSwal('Success', result.message, 'success');
      resetPageInfo();
      closeChangeDecorationMethodModal();
    }
  }, [
    closeChangeDecorationMethodModal,
    orderItem?.ids,
    orderItem?.styleDecorationMethod,
    resetPageInfo,
  ]);

  const searchItemsInOrder = useCallback((searchInput: string) => {
    let isFiltering: boolean;
    let filtered: Array<OrderItemDetailsDto>;

    if (searchInput === '') {
      isFiltering = false;
      filtered = order.items!;
    } else {
      isFiltering = true;
      filtered = order?.items
        ? order.items.filter((i) => (
          (i.sku ?? '').toLowerCase().includes(searchInput.toLowerCase())
          || (i.styleName ?? '').toLowerCase().includes(searchInput.toLowerCase())
        ))
        : [];
    }

    setFilteredItems(filtered);
    setIsFilteringItems(isFiltering);
  }, [order.items]);

  const clearSearch = useCallback(() => {
    searchItemsInOrder('');
    setResetSearchOrderItemsInput(true);
  }, [searchItemsInOrder]);

  const onResetSearchOrderItemsInput = useCallback(() => {
    setResetSearchOrderItemsInput(false);
  }, []);

  const handleSearchInputChange = useCallback((e) => {
    const searchInput = e.target.value.toLowerCase().trim();
    searchItemsInOrder(searchInput);
  }, [searchItemsInOrder]);

  const artworksExpandedOrCollapsedCallback = useCallback((ids: Array<number>) => {
    const collapsedItemIdList = toggleArrayMember(collapsedItemIds, ids);
    const filteredItemsIds = filteredItems!.map((i) => i.ids);

    if (filteredItemsIds.length === 0) { return; }

    let collapsedCounter = 0;
    for (const itemId of filteredItemsIds) {
      if (collapsedItemIdList.includes(itemId!)) {
        collapsedCounter++;
      }
    }

    let newCollapseAll = collapseAll;
    if (collapsedCounter === filteredItemsIds.length && !collapseAll) {
      newCollapseAll = true;
    } else if (collapsedCounter === 0 && collapseAll) {
      newCollapseAll = false;
    }

    setCollapseAll(newCollapseAll);
    setCollapsedItemIds(collapsedItemIdList);
  }, [
    collapseAll,
    collapsedItemIds,
    filteredItems,
  ]);

  const collapseOrExpandItems = useCallback(() => {
    setCollapseAll(!collapseAll);
    setCollapsedItemIds(collapseAll ? [] : filteredItems!.map((i) => i.ids!));
  }, [
    collapseAll,
    filteredItems,
  ]);

  const setSynced = useCallback((synch: boolean) => {
    setCoreSynced(synch);
  }, []);

  const doReplace = useCallback(() => {
    replace();
  }, [replace]);

  useEffect(() => {
    if (removePersonalizationCorePriceCalculation?.result) {
      setRemovePersonalizationPriceConfirmationModalIsOpen(true);
    }
  }, [removePersonalizationCorePriceCalculation]);

  useEffect(() => {
    if (addPersonalizationCorePriceCalculationResponse?.result) {
      setAddPersonalizationPriceConfirmationModalIsOpen(true);
      setPersonalizationPriceCalculation(addPersonalizationCorePriceCalculationResponse?.result);
    }
  }, [addPersonalizationCorePriceCalculationResponse]);

  useEffect(() => {
    if (editPersonalizationCorePriceCalculationResponse?.result) {
      setEditPersonalizationPriceConfirmationModalIsOpen(true);
      setPersonalizationPriceCalculation(editPersonalizationCorePriceCalculationResponse?.result);
    }
  }, [editPersonalizationCorePriceCalculationResponse]);

  useEffect(() => {
    if (addPersonalizationResponse?.success) {
      resetPageInfo();
      materialSwal('Success', addPersonalizationResponse?.message, 'success');
      closeAddPersonalizationModal();
      closeAddPersonalizationPriceConfirmationModal();
    }
  }, [
    addPersonalizationResponse,
    closeAddPersonalizationModal,
    closeAddPersonalizationPriceConfirmationModal,
    resetPageInfo,
  ]);

  useEffect(() => {
    if (editPersonalizationResponse?.success) {
      resetPageInfo();
      materialSwal('Success', editPersonalizationResponse?.message, 'success');
      closeEditPersonalizationModal();
      closeEditPersonalizationPriceConfirmationModal();
    }
  }, [
    closeEditPersonalizationModal,
    closeEditPersonalizationPriceConfirmationModal,
    editPersonalizationResponse,
    resetPageInfo,
  ]);

  useEffect(() => {
    if (removePersonalizationResponse?.success) {
      resetPageInfo();
      materialSwal('Success', removePersonalizationResponse?.message, 'success');
      closeRemovePersonalizationModal();
      closeRemovePersonalizationPriceConfirmationModal();
    }
  }, [
    closeRemovePersonalizationModal,
    closeRemovePersonalizationPriceConfirmationModal,
    removePersonalizationResponse,
    resetPageInfo,
  ]);

  useEffect(() => {
    getColors();
    getDecorationLocations();

    if (order?.orderNumber) {
      fetchOrderFlags(order.orderNumber);
    }
  }, [
    fetchOrderFlags,
    getColors,
    getDecorationLocations,
    order?.orderNumber,
  ]);

  const updateReplaceBySkuBasePrice = useCallback((basePrice: Nullable<number>) => {
    setReplaceBySkuBasePrice(basePrice);
  }, []);

  useEffect(() => {
    if (order?.orderNumber) {
      fetchOrderFlags(order.orderNumber);
      getDecorationLocations();
    }
  }, [
    fetchOrderFlags,
    getDecorationLocations,
    order.orderNumber,
  ]);

  useEffect(() => {
    if (order) {
      setFilteredItems(order.items!);
      setIsFilteringItems(false);
    }
  }, [order]);

  useEffect(() => {
    if (filteredItems) {
      calculateCollapsed();
    }
  }, [
    calculateCollapsed,
    filteredItems,
  ]);

  useEffect(() => {
    if (!applyCouponNextAction?.apply) { return; }

    if (!orderExistsOnCore && applyCouponNextAction?.couponType === couponTypeEnum.Amount) {
      couponApply();
    } else {
      openApplyCouponConfirmationModal();
    }

    setApplyCouponNextAction(null);
  }, [
    applyCouponNextAction,
    applyCouponNextAction?.apply,
    applyCouponNextAction?.couponType,
    couponApply,
    openApplyCouponConfirmationModal,
    orderExistsOnCore,
    setApplyCouponNextAction,
  ]);

  useEffect(() => {
    if (
      !!(personalizationForm as OrderItemRemovePersonalizationFormData)?.paymentType
      && removePersonalizationCallback
    ) {
      removePersonalizationFromItems();
      setRemovePersonalizationCallback(false);
    }
  }, [
    personalizationForm,
    removePersonalizationCallback,
    removePersonalizationFromItems,
  ]);

  useEffect(() => {
    if (
      !!(personalizationForm as PersonalizationFormData)?.personalizationBasePrice
      && editPersonalizationCallback
    ) {
      editPersonalization();
      setEditPersonalizatonCallback(false);
    }
  }, [
    editPersonalization,
    editPersonalizationCallback,
    personalizationForm,
  ]);

  useEffect(() => {
    if (
      !!(personalizationForm as OrderItemRemovePersonalizationFormData)?.paymentType
      && removePersonalizationCallback
    ) {
      removePersonalizationFromItems();
      setRemovePersonalizationCallback(false);
    }
  }, [
    personalizationForm,
    removePersonalizationCallback,
    removePersonalizationFromItems,
  ]);

  useEffect(() => {
    if (
      !!(personalizationForm as PersonalizationFormData)?.personalizationBasePrice
      && editPersonalizationCallback
    ) {
      editPersonalization();
      setEditPersonalizatonCallback(false);
    }
  }, [
    editPersonalization,
    editPersonalizationCallback,
    personalizationForm,
  ]);

  useEffect(() => {
    if (calculateApplyCouponPriceResponse?.success) {
      setApplyCouponConfirmationModalIsOpen(true);
      setApplyCouponModalIsOpen(false);
      setApplyCouponPriceCalculation(calculateApplyCouponPriceResponse.result!);
    }
  }, [calculateApplyCouponPriceResponse]);

  useEffect(() => {
    if (applyCouponResponse?.success) {
      fetchOrder();
      resetPageInfo();
      materialSwal('Success', applyCouponResponse.message, 'success');
      closeApplyCouponModal();
      closeApplyCouponConfirmationModal();
    } else if (applyCouponResponse) { // This check prevents this from running on page opening
      removeCouponReservation(couponCode);
    }
  }, [applyCouponResponse, applyCouponResponse?.success, closeApplyCouponConfirmationModal, closeApplyCouponModal, couponCode, fetchOrder, resetPageInfo]);

  const filteredQuantity = filteredItems ? filteredItems.length : 0;

  const changeArtworkLogo = getChangeArtworkLogo();
  const changeArtworkInitialValues = changeArtworkLogo
    ? { decorationLocation: changeArtworkLogo.decorationLocation }
    : {};

  const editPersonalizationInitialValues = () => {
    const orPrice = settings?.defaultPersonalizationPrices?.textOrNumber;
    const andPrice = settings?.defaultPersonalizationPrices?.textAndNumber;
    const personalizationCorePrice = coreOrder?.items?.find(
      (i) => i?.id === orderItem?.coreId,
    )?.personalizations?.find(
      (p) => p?.decorationLocation === personalizationToEdit?.decorationLocation,
    )?.personalizationUnitPrice;

    return personalizationToEdit
      ? {
        decorationLocation: personalizationToEdit.decorationLocation,
        number: personalizationToEdit.number ?? '',
        text: personalizationToEdit.text ?? '',
        colorId: personalizationToEdit.colorId,
        quantity: orderItem?.quantity,
        personalizationBasePrice: orderItem?.voucherId || orderItem?.custom
          ? 0
          : personalizationCorePrice
            ?? (orPrice && andPrice
              ? (!personalizationToEdit?.text || !personalizationToEdit?.number)
                ? centsToDollars(orPrice)
                : centsToDollars(andPrice)
              : 0),
      }
      : {
        quantity: orderItem?.quantity,
        personalizationBasePrice: orderItem?.voucherId ? 0 : undefined,
      };
  };

  const addPersonalizionInitialValues = useMemo(() => ({
    decorationLocation: null,
    number: '',
    text: '',
    colorId: null,
    quantity: orderItem?.quantity,
    personalizationBasePrice: 0,
  }), [orderItem?.quantity]);

  const replaceBySkuInitialValues = useMemo(() => ({
    quantity: orderItem?.quantity ?? 0,
    sku: skuInput,
    basePrice: orderItem?.voucherId
      ? orderItem?.basePrice
      : replaceBySkuBasePrice!,
  }), [
    orderItem?.basePrice,
    orderItem?.quantity,
    orderItem?.voucherId,
    replaceBySkuBasePrice,
    skuInput,
  ]);

  const orderSubtotalAmountLeft = order
    ? getOrderSubtotalRefundAmountLeft(order)
    : 0;
  const canRefundSubtotal = orderSubtotalAmountLeft > 0;

  return (
    <div className='order__main'>
      {order
        && (
          <div>
            <ImageModal
              isOpen={previewModalIsOpen}
              closeModal={closePreviewModal}
              imageUrl={imageUrl}
            />
            <OrderManagementDetailsReplaceBySkuModalNew
              isOpen={replaceBySkuModalIsOpen}
              onSubmit={openReplaceBySkuConfirmationModal}
              closeModal={closeReplaceItemBySkuModal}
              skuInput={skuInput}
              handleChangeSkuInput={handleChangeSkuInput}
              orderItem={orderItem!}
              selectedLogo={selectedLogo! as LockerProductsCssLogoViewModel}
              initialValues={replaceBySkuInitialValues}
              canUpdateBasePrice={!orderItem?.voucherId}
              synced={coreSynced}
              setSynced={setSynced}
              product={orderItemProduct}
              order={order}
              handleSelectedProductChange={handleSelectedProductChange}
              setInitialBasePrice={updateReplaceBySkuBasePrice}
              orderExistsOnCore={orderExistsOnCore}
            />

            {orderExistsOnCore
              && (
                <OrderManagementDetailsReplaceBySkuConfirmationNewModal
                  isOpen={replaceBySkuConfirmationModalIsOpen && orderExistsOnCore}
                  replace={doReplace}
                  closeModal={closeReplaceBySkuConfirmationModal}
                  orderItemReplacePriceCalculation={orderItemReplacePriceCalculation as OrderItemPricingDetails}
                  orderItem={orderItem!}
                  order={order}
                  quantity={quantityToReplace}
                  coreOrderPricingDetails={coreOrderPricingDetails!}
                />
              )}

            {!orderExistsOnCore
              && (
                <OrderManagementDetailsReplaceBySkuConfirmationModalOld
                  isOpen={replaceBySkuConfirmationModalIsOpen && !orderExistsOnCore}
                  replace={doReplace}
                  closeModal={closeReplaceBySkuConfirmationModal}
                  orderItemReplacePriceCalculation={orderItemReplacePriceCalculation! as FFReplaceItemPriceCalculation}
                  order={order}
                />
              )}

            <OrderManagementDetailsReplaceItemModalNew
              isOpen={replaceItemModalIsOpen}
              replace={replace}
              closeModal={closeReplaceItemModal}
              lockerId={orderItem?.lockerId ?? 0}
              openReplaceItemBySkuModal={openReplaceItemBySkuModal}
              orderItem={orderItem!}
              getOrderItemProductBySku={getProductBySku}
              setSynced={setSynced}
              synced={coreSynced}
              setInitialBasePrice={updateReplaceBySkuBasePrice}
              orderExistsOnCore={orderExistsOnCore}
            />

            <OrderManagementChangeArtworkModal
              isOpen={changeArtworkModalIsOpen}
              closeModal={closeChangeArtworkModal}
              product={orderItemProduct}
              decorationLocations={decorationLocations}
              logo={changeArtworkLogo}
              lockerArtworks={lockerArtworks}
              onSubmit={artworkChange}
              initialValues={changeArtworkInitialValues}
            />

            <OrderManagementAddPersonalizationModal
              isOpen={addPersonalizationModalIsOpen}
              closeModal={closeAddPersonalizationModal}
              onSubmit={openAddPersonalizationPriceConfirmationModal}
              orderItem={orderItem!}
              product={orderItemProduct}
              decorationLocations={decorationLocations}
              colors={colors}
              initialValues={addPersonalizionInitialValues}
              showQuantityField={true}
              canUpdatePersonalizationPrice={true}
              variation={null}
              orderExistsOnCore={orderExistsOnCore}
              disableCharge={!hfEnableChargeForPRDOrders && (coreOrder?.prdOrder ?? false)}
            />

            <OrderManagementChoosePersonalizationToEditModal
              isOpen={choosePersonalizationToEditModalIsOpen}
              closeModal={closeChoosePersonalizationToEditModal}
              personalizations={orderItem?.personalizations ?? []}
              colorsDictionary={colorsDictionary}
              personalizationOnClick={openEditPersonalizationModal}
            />

            <OrderManagementChoosePersonalizationToRemoveModal
              isOpen={choosePersonalizationToRemoveModalIsOpen}
              closeModal={closeChoosePersonalizationToRemoveModal}
              personalizations={orderItem?.personalizations ?? []}
              colorsDictionary={colorsDictionary}
              personalizationOnClick={openRemovePersonalizationModal}
            />

            <OrderManagementEditPersonalizationModal
              isOpen={editPersonalizationModalIsOpen}
              closeModal={closeEditPersonalizationModal}
              onSubmit={openEditPersonalizationPriceConfirmationModal}
              orderItem={orderItem!}
              canRefundSubtotal={canRefundSubtotal}
              personalization={personalization}
              product={orderItemProduct}
              decorationLocations={decorationLocations}
              colors={colors}
              initialValues={editPersonalizationInitialValues()}
              showQuantityField={true}
              canUpdatePersonalizationPrice={true}
              orderExistsOnCore={orderExistsOnCore}
              variation={null}
              disableCharge={!hfEnableChargeForPRDOrders && (coreOrder?.prdOrder ?? false)}
            />

            <OrderManagementRemovePersonalizationModal
              orderItem={orderItem!}
              canRefundSubtotal={canRefundSubtotal}
              onSubmit={openRemovePersonalizationPriceConfirmationModal}
              isOpen={removePersonalizationModalIsOpen}
              closeModal={closeRemovePersonalizationModal}
              canUpdatePersonalizationPrice={true}
              colorsDictionary={colorsDictionary}
              orderExistsOnCore={orderExistsOnCore}
            />

            <OrderManagementAddPersonalizationPriceConfirmationModal
              isOpen={addPersonalizationPriceConfirmationModalIsOpen}
              closeModal={closeAddPersonalizationPriceConfirmationModal}
              personalizationPriceCalculation={personalizationPriceCalculation!}
              addPersonalization={addPersonalization}
              orderExistsOnCore={orderExistsOnCore}
              orderItem={orderItem}
              coreOrder={coreOrder}
              order={order}
            />

            <OrderManagementEditPersonalizationPriceConfirmationModal
              isOpen={editPersonalizationPriceConfirmationModalIsOpen}
              closeModal={closeEditPersonalizationPriceConfirmationModal}
              personalizationPriceCalculation={personalizationPriceCalculation!}
              editPersonalization={editPersonalization}
              oldPersonalizationBasePrice={orderItem?.personalizationBasePrice ?? 0}
              newPersonalizationBasePrice={(personalizationForm as PersonalizationFormData)?.personalizationBasePrice
                || 0}
              orderExistsOnCore={orderExistsOnCore}
              orderItem={orderItem}
              coreOrder={coreOrder}
            />

            <OrderManagementRemovePersonalizationPriceConfirmationModal
              isOpen={removePersonalizationPriceConfirmationModalIsOpen}
              closeModal={closeRemovePersonalizationPriceConfirmationModal}
              personalizationPriceCalculation={orderExistsOnCore
                ? removePersonalizationCorePriceCalculation?.result ?? null
                : (personalizationPriceCalculation! as FFReplaceItemPriceCalculation)}
              removePersonalization={removePersonalizationFromItems}
              paymentType={(personalizationForm as OrderItemRemovePersonalizationFormData)?.paymentType
                || AccountingActionTypeEnum.NoChange}
              orderExistsOnCore={orderExistsOnCore}
              orderItem={orderItem}
              coreOrder={coreOrder}
            />

            <OrderManagementOrderItemRefundModalNew
              orderRefundAmountLeft={orderSubtotalAmountLeft}
              orderItem={orderItem}
              isOpen={refundOrderItemModalIsOpen}
              isCancelAction={isCancelAction}
              onSubmit={refundOrderItemModalCallback}
              closeModal={closeOrderItemRefundModal}
              canRefundSubtotal={canRefundSubtotal}
              initialValues={{
                actionType: getOrderItemActionType(
                  !canRefundSubtotal,
                  orderItem?.isCompleteRefund ?? false,
                  !!orderItem?.voucherId,
                ),
                quantity: orderItem?.quantity,
              }}
              quantity={orderItem?.quantity}
              coreOrderPricingDetails={coreOrderPricingDetails}
              orderExistsOnCore={orderExistsOnCore}
            />

            <OrderManagementCouponDetailsModal
              couponCode={coreOrder
                ? coreOrder.coupon?.code
                : order?.couponCode}
              isOpen={couponDetailsModalIsOpen}
              closeModal={closeCouponDetailsModal}
            />

            <OrderManagementAddCouponModal
              isOpen={applyCouponModalIsOpen}
              closeModal={closeApplyCouponModal}
              order={order}
              onConfirm={getApplyCouponNextAction}
            />

            <OrderManagementApplyCouponConfirmationModal
              isOpen={applyCouponConfirmationModalIsOpen}
              closeModal={closeApplyCouponConfirmationModal}
              applyCouponPriceCalculation={applyCouponPriceCalculation!}
              applyCoupon={couponApply}
              orderExistsOnCore={orderExistsOnCore}
              coreOrder={coreOrder!}
              couponType={couponType!}
            />

            <ItemInfoModal
              isOpen={itemInfoModalIsOpen}
              closeModal={closeItemInfoModal}
              sku={orderItem?.sku}
              lockerItemId={orderItem?.lockerItemId}
            />

            <OrderManagementOrderFinancialsModal
              isOpen={orderFinancialsModalIsOpen}
              closeModal={closeOrderFinancialsModal}
              order={order}
              coreOrderPricingDetails={coreOrderPricingDetails}
            />

            <OrderManagementOrderItemFinancialsModal
              isOpen={orderItemFinancialsModalIsOpen}
              closeModal={closeOrderItemFinancialsModal}
              orderItem={orderItem}
              product={orderItemProduct}
              orderItemPricingDetails={orderItemPricingDetails}
              getPricingFromCore={orderExistsOnCore}
            />

            <OrderManagementChangeDecorationMethodModal
              isOpen={changeDecorationMethodModalIsOpen}
              closeModal={closeChangeDecorationMethodModal}
              onSubmit={decorationMethodChange}
              decorationMethod={(orderItem?.styleDecorationMethod as DecorationMethodEnum)
                || DecorationMethodEnum.Undefined}
            />

            <div className='order__search-container'>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <SearchFilter
                  onChange={handleSearchInputChange}
                  placeholder={'Search Items in Order'}
                  resetValue={resetSearchOrderItemsInput}
                  onResetValue={onResetSearchOrderItemsInput}
                />
                <OrderPaymentMethod stripePaymentMethod={order.stripePaymentMethod} />
              </div>
              <Expander
                isExpanded={!collapseAll}
                toggle={collapseOrExpandItems}
                collapseText={'Collapse All'}
                expandText={'Expand All'}
                hideIcon={true}
                classes={'ml-20'}
              />
            </div>
            <div className='order__items-container'>
              <div className='order__expander'>
                {isFilteringItems
                  && (
                    <div className='order__items-results-found'>
                      <div>{filteredQuantity} {filteredQuantity === 1 ? 'result' : 'results'} found.</div>
                      <div
                        className='link'
                        onClick={clearSearch}
                      >
                        Clear search
                      </div>
                    </div>
                  )}
              </div>

              <OrderManagementDetailsItems
                openReplaceItemBySkuModal={openReplaceItemBySkuModal}
                openReplaceItemModal={openReplaceItemModal}
                openPreviewModal={openPreviewModal}
                openItemInfoModal={openItemInfoModal}
                openOrderItemFinancialsModal={openOrderItemFinancialsModal}
                openChangeDecorationMethodModal={openChangeDecorationMethodModal}
                items={filteredItems}
                isFilteringItems={isFilteringItems}
                order={order}
                createPersonalization={openAddPersonalizationModal}
                editPersonalization={openChoosePersonalizationToEditModal}
                openRemovePersonalizationModal={openChoosePersonalizationToRemoveModal}
                collapsedItemIds={collapsedItemIds}
                artworksExpandedOrCollapsedCallback={artworksExpandedOrCollapsedCallback}
                shipmentNumbersForItems={shipmentNumbersForItems}
                isOrderPartiallyShipped={isOrderPartiallyShipped}
                orderShipments={shipments}
                refundItem={openOrderItemRefundModal}
                changeArtwork={openChangeArtworkModal}
                orderFlags={orderFlags}
                coreOrderPricingDetails={coreOrderPricingDetails}
                orderExistsOnCore={orderExistsOnCore}
                coreOrder={coreOrder}
                orderVariations={[]}
              />
            </div>

            <OrderManagementDetailsOrderSummary
              order={order}
              openApplyCouponModal={openApplyCouponModal}
              openOrderFinancialsModal={openOrderFinancialsModal}
              openCouponDetailsModal={openCouponDetailsModal}
              coreOrderPricingDetails={coreOrderPricingDetails}
              orderExistsOnCore={orderExistsOnCore}
              coreOrder={coreOrder!}
            />
          </div>
        )}
      {!order
        && <div className='order__main' />}
    </div>
  );
});

export default connector(OrderManagementDetails);
