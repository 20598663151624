/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Orders Homefield API
 * OpenAPI spec version: v1
 */
import type { PersonalizationTypeEnum } from './personalizationTypeEnum';

export interface ItemsQueriesPersonalization {
  id?: number;
  personalizationType?: PersonalizationTypeEnum;
  decorationMethod?: string | null;
  decorationLocation?: string | null;
  decorationHeight?: number | null;
  colorId?: number | null;
  outlineColorId?: number | null;
  required?: boolean;
  maxPersonalizationNameLength?: number | null;
}
