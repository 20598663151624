/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Catalog Homefield API
 * OpenAPI spec version: v1
 */

export type SortByEnum = 'Brand';


export const SortByEnum = {
  Brand: 'Brand' as SortByEnum,
};
