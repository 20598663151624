/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */
import type { ArtworkTaskPreviewStatusEnum } from './artworkTaskPreviewStatusEnum';

export interface ArtworkTaskPreviewStatusDto {
  taskId?: number;
  fileGuid?: string;
  fileName?: string | null;
  previewUrl?: string | null;
  swatchColors?: string[] | null;
  height?: number | null;
  width?: number | null;
  status?: ArtworkTaskPreviewStatusEnum;
}
