/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Product Catalog API
 * OpenAPI spec version: v1
 */

export type CommandsModeEnum = 'AddNewMapping' | 'OverwriteExistingMapping';


export const CommandsModeEnum = {
  AddNewMapping: 'AddNewMapping' as CommandsModeEnum,
  OverwriteExistingMapping: 'OverwriteExistingMapping' as CommandsModeEnum,
};
