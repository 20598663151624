/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Orders Homefield API
 * OpenAPI spec version: v1
 */

export interface Logo {
  decorationMethod?: string | null;
  decorationLocation?: string | null;
  decorationHeight?: number | null;
  decorationWidth?: number | null;
  logoId?: number;
  logoUrl?: string | null;
}
