/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */
import type { Int32Int32DictionaryApiResponseResult } from './int32Int32DictionaryApiResponseResult';

export interface Int32Int32DictionaryApiResponse {
  success?: boolean;
  message?: string | null;
  result?: Int32Int32DictionaryApiResponseResult;
}
