import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { updateLogo } from '@APICalls/logos/actions';
import { lockerMgrS3Logos } from '@constants/common';
import { fetchLogo } from '@redux/logos/actions';
import { fetchColors } from '@redux/support/actions';
import { materialSwal } from '@util/componentHelper';
import {
  decorationMethodEnum,
  selectionTypeEnum,
} from '@constants/enums/decorationEnums';
import EditLogoColorsModal from './EditLogoColorsModal';

class UploadLogoSwatchColors extends Component {
  state = {
    approvedLightColors: [],
    approvedDarkColors: [],
  };

  componentDidMount() {
    const {
      dispatch,
      logoId,
    } = this.props;

    dispatch(fetchColors());
    if (logoId) {
      dispatch(fetchLogo(logoId));
    }
  }

  componentDidUpdate(oldProps) {
    const { logoId } = this.props;
    if (logoId && logoId !== oldProps.logoId) {
      const { dispatch } = this.props;
      dispatch(fetchLogo(logoId));
    }
  }

  updateLogo = async (newColors, selectedMethods, floodColorIds) => {
    const {
      logoId,
      colors,
      cancel,
      refreshLogos,
    } = this.props;

    const customColors = [];
    const colorIds = [];

    if (newColors && colors && colors.length > 0) {
      for (const newColor of newColors) {
        const selectedColor = colors.find((c) => c.code === newColor);
        if (selectedColor) {
          colorIds.push(selectedColor.id);
        } else {
          customColors.push(newColor);
        }
      }

      const res = await updateLogo(
        logoId,
        selectionTypeEnum.UserOnly,
        colorIds,
        customColors,
        null,
        selectedMethods,
        floodColorIds
      );

      if (res?.success) {
        materialSwal('Success', res.message, 'success');
        if (refreshLogos) {
          refreshLogos();
        }
      }
      cancel();
    }
  };

  render() {
    const {
      logo,
      isModalOpen,
      cancel,
      colors,
      artworkColors,
      allLightColors,
      allDarkColors,
    } = this.props;

    return (
      <EditLogoColorsModal
        isOpen={isModalOpen}
        modalTitle={'Upload to Logo Bank'}
        logoColors={logo?.selectedColors?.map((x) => x.code)}
        cancel={cancel}
        logoId={logo?.id}
        logoUrl={`${lockerMgrS3Logos}/${logo?.image}`}
        logoName={logo?.image}
        saveLogoColors={this.updateLogo}
        decorationMethod={[
          decorationMethodEnum.HAG,
          decorationMethodEnum.EMB,
        ]}
        saveAlwaysEnabled={true}
        colors={colors}
        artworkColors={artworkColors}
        allDecorationsActive={true}
        allLightColors={allLightColors}
        allDarkColors={allDarkColors}
      />
    );
  }
}

UploadLogoSwatchColors.propTypes = {
  logoId: PropTypes.number.isRequired,
  cancel: PropTypes.func.isRequired,
  colors: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    brightness: PropTypes.string,
    hexValue: PropTypes.string,
    isArtwork: PropTypes.bool,
    isFlood: PropTypes.bool,
  })),
  artworkColors: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    brightness: PropTypes.string,
    hexValue: PropTypes.string,
    isArtwork: PropTypes.bool,
    isFlood: PropTypes.bool,
  })),
  logo: PropTypes.shape({
    id: PropTypes.number.isRequired,
    image: PropTypes.string.isRequired,
    imageUrl: PropTypes.string.isRequired,
    selectedColors: PropTypes.arrayOf(PropTypes.shape({
      code: PropTypes.string.isRequired,
      selectionType: PropTypes.number,
      id: PropTypes.number,
      hexValue: PropTypes.string,
    })),
    artworkApproved: PropTypes.bool.isRequired,
    decorationMethods: PropTypes.arrayOf(PropTypes.string).isRequired,
    deletedAt: PropTypes.string,
  }),
  isModalOpen: PropTypes.bool.isRequired,
  allLightColors: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    brightnes: PropTypes.number,
    hexValue: PropTypes.string,
    isArtwork: PropTypes.bool,
    isFlood: PropTypes.bool,
  })),
  allDarkColors: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    brightnes: PropTypes.number,
    hexValue: PropTypes.string,
    isArtwork: PropTypes.bool,
    isFlood: PropTypes.bool,
  })),
  refreshLogos: PropTypes.func,
};

const mapStateToProps = ({
  support,
  logos,
}) => ({
  colors: support.colors,
  artworkColors: support.artworkColors,
  allLightColors: support.lightColors,
  allDarkColors: support.darkColors,
  logo: logos.logo,
});

export default connect(mapStateToProps)(UploadLogoSwatchColors);
