import LogoColorsDropdown from '@components/shared/ColorDetectionEditSwatches/LogoColorsDropdown';
import SelectableSwatchColorField from '@components/shared/ColorDetectionEditSwatches/SelectableSwatchColorField';
import { keyNameEnum } from '@constants/enums/commonEnums';
import { Button } from '@material-ui/core';
import { toggleArrayMember } from '@util/componentHelper';
import React, { forwardRef, useCallback, useImperativeHandle, useState } from 'react';

const ArtworkColors = forwardRef(({ logo, artworkColors = [], colors = [] }, ref) => {
  const logoColors = logo?.selectedColors?.map((x) => x.code);

  const [customColorsInputValue, setCustomColorsInputValue] = useState('');
  const [selectedLogoColors, setSelectedLogoColors] = useState(logoColors || []);
  const [newLogoColors, setNewLogoColors] = useState([]);
  const [deletedLogoColors, setDeletedLogoColors] = useState([]);

  const getData = () => ({
    selectedLogoColors,
    newLogoColors,
    deletedLogoColors,
    customColors: customColorsInputValue,
  });

  const setData = (data) => {
    setSelectedLogoColors(data.selectedLogoColors);
    setNewLogoColors(data.newLogoColors);
    setDeletedLogoColors(data.deletedLogoColors);
  };

  const addCustomColors = useCallback(() => {
    const colorsSplit = customColorsInputValue.split(',');

    const updatedNewLogoColors = [...newLogoColors];
    const updatedSelectedLogoColors = [...selectedLogoColors];

    for (const color of colorsSplit) {
      const colorName = color.trim();
      if (colorName === '') { continue; }

      if (!updatedNewLogoColors.includes(colorName)) {
        updatedSelectedLogoColors.push(colorName);
        updatedNewLogoColors.push(colorName);
      }
    }

    setSelectedLogoColors(updatedSelectedLogoColors);
    setNewLogoColors(updatedNewLogoColors);
    setCustomColorsInputValue('');
  }, [newLogoColors, selectedLogoColors, customColorsInputValue]);

  const handleEnterKeydown = useCallback((e) => {
    if (e.key === keyNameEnum.Enter) {
      addCustomColors();
    }
  }, [addCustomColors]);

  const handleChangeCustomColorsInput = useCallback((e) => {
    setCustomColorsInputValue(e.target.value);
  }, []);

  const addColorSwatches = useCallback((swatchColors) => {
    setSelectedLogoColors(selectedLogoColors.concat(swatchColors));
    setNewLogoColors(newLogoColors.concat(swatchColors));
  }, [selectedLogoColors, newLogoColors]);

  const getUnusedArtworkColors = useCallback(() => artworkColors.filter((c) => !(selectedLogoColors || []).includes(c.code)), [artworkColors, selectedLogoColors]);

  useImperativeHandle(ref, () => ({
    getData,
    setData,
  }));

  const deleteColorSwatch = useCallback((color) => {
    if (newLogoColors.includes(color)) {
      setSelectedLogoColors(toggleArrayMember(selectedLogoColors, color));
      setNewLogoColors(toggleArrayMember(newLogoColors, color));
    } else {
      setDeletedLogoColors(toggleArrayMember(deletedLogoColors, color));
    }
  }, [selectedLogoColors, newLogoColors, deletedLogoColors]);

  return (
    <div style={{ backgroundColor: '#fafafa' }}>
      <div className='logo-bank__upload-modal--title'>Artwork Colors</div>
      {selectedLogoColors?.length > 0
        && (
          <>
            <div className='color-swatches__container custom-scrollbar' style={{ padding: '0px' }}>
              <div>
                {selectedLogoColors.map((c, index) => (
                  <SelectableSwatchColorField
                    key={index}
                    colorCode={c}
                    isDeleted={deletedLogoColors.includes(c)}
                    isAdded={newLogoColors.includes(c)}
                    handleSelect={deleteColorSwatch}
                    colors={colors}
                  />
                ))}
              </div>
            </div>
            <div className='color-swatches__remove-color-info'>Click on an existing color to remove it</div>
          </>
        )}
      <div className='color-swatches__container'>
        <div className='color-swatches__input-container'>
          <div className='select-field'>
            <LogoColorsDropdown
              colors={getUnusedArtworkColors()}
              addColors={addColorSwatches}
            />
          </div>
          <div className='text-field'>
            <input
              type='text'
              className='color-swatches__input'
              placeholder='Add Custom Color (comma separated)'
              onKeyDown={handleEnterKeydown}
              value={customColorsInputValue}
              onChange={handleChangeCustomColorsInput}
            />
          </div>
        </div>
        <div className='color-swatches__add-btn-container'>
          <Button
            variant='contained'
            text={'Add'}
            color='primary'
            onClick={addCustomColors}
            disabled={customColorsInputValue.trim() === ''}
          >
            Add
          </Button>
        </div>
      </div>
    </div>
  );
});

export default ArtworkColors;
