import CheckButton from '@components/shared/Inputs/CheckButton';
import { decorationMethodEnum } from '@constants/enums/decorationEnums';
import { makeStyles } from '@material-ui/styles';
import React, { useState, forwardRef, useImperativeHandle } from 'react';

const useStyles = makeStyles(() => ({
  colorSwatches: {
    padding: '0px',
  },
}));

const DecorationMethods = forwardRef((props, ref) => {
  const classes = useStyles();

  const [isHag, setIsHag] = useState(true);
  const [isEmb, setIsEmb] = useState(true);
  const [isDtg, setIsDtg] = useState(true);
  const [isDip, setIsDip] = useState(true);

  const toggleCheckBox = (setStateFn, value) => {
    setStateFn(!value);
  };

  const getData = () => ({
    isHag,
    isEmb,
    isDtg,
    isDip,
  });

  const setData = (data) => {
    setIsHag(data.isHag);
    setIsEmb(data.isEmb);
    setIsDtg(data.isDtg);
    setIsDip(data.isDip);
  };

  useImperativeHandle(ref, () => ({
    getData,
    setData,
  }));

  return (
    <div>
      <div >Approved Decoration Methods</div>
      <div className={`color-swatches__checkboxes ${classes.colorSwatches} `}>
        <CheckButton
          disabled={false}
          text={decorationMethodEnum.HAG}
          checked={isHag}
          onClick={toggleCheckBox.bind(null, setIsHag, isHag)}
        />
        <CheckButton
          disabled={false}
          text={decorationMethodEnum.EMB}
          checked={isEmb}
          onClick={toggleCheckBox.bind(null, setIsEmb, isEmb)}
        />
        <CheckButton
          disabled={false}
          text={decorationMethodEnum.DTG}
          checked={isDtg}
          onClick={toggleCheckBox.bind(null, setIsDtg, isDtg)}
        />
        <CheckButton
          disabled={false}
          text={decorationMethodEnum.DIP}
          checked={isDip}
          onClick={toggleCheckBox.bind(null, setIsDip, isDip)}
        />
      </div>
    </div>
  );
});

export default DecorationMethods;
