import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { infoTextTimeout } from '@constants/values';
import {
  copyToClipboard,
  copyUrlToClipboard,
} from '@util/componentHelper';
import Tag from '@sharedComponents/Display/Tag';
import MaterialTooltip from '@sharedComponents/Tooltips/MaterialTooltip';

class PONumberTag extends PureComponent {
  state = {
    copyText: '',
  };

  getDataToCopyId = () => {
    const { item } = this.props;

    return `dataToCopy-${item.ids.toString()}-poNumber`;
  };

  onCopyToClipboard = (e) => {
    const {
      vendorsDictionary,
      item,
    } = this.props;

    const vendor = vendorsDictionary[item.vendorId];

    if (vendor?.vendorSystemUrl) {
      copyUrlToClipboard(e, this.getDataToCopyId(), vendor.vendorSystemUrl);
    } else {
      copyToClipboard(e, this.getDataToCopyId());
    }

    this.setState({
      copyText: 'Copied!',
    }, () => setTimeout(() => {
      this.setState(() => ({
        copyText: '',
      }));
    }, infoTextTimeout));
  };

  render() {
    const { item } = this.props;
    const { copyText } = this.state;

    return (
      <MaterialTooltip
        tooltipText={(
          <div
            className='cursor-pointer'
            onClick={this.onCopyToClipboard}
          >
            <div>Purchase Order</div>
            <div>(click to copy to clipboard)</div>
            <div>{copyText}</div>
          </div>
        )}
        placement={'top'}
        interactive={true}
      >
        <Tag
          label={'PO#'}
          value={<span id={this.getDataToCopyId()}>{item.poNumber}</span>}
          classes={'cursor-pointer'}
        />
      </MaterialTooltip>
    );
  }
}

PONumberTag.propTypes = {
  item: PropTypes.shape({
    ids: PropTypes.arrayOf(PropTypes.number).isRequired,
    poNumber: PropTypes.string,
    vendorId: PropTypes.string,
  }),
  vendorsDictionary: PropTypes.object,
};

export default PONumberTag;
