/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */

export interface AddPersonalizationCommand {
  orderNumber?: number;
  orderItemId?: number;
  name?: string | null;
  number?: string | null;
  decorationLocation?: string | null;
  colorId?: number;
  personalizationBasePrice?: number;
}
