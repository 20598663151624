/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */
import type { BatchWarehouseOrderDto } from './batchWarehouseOrderDto';

export interface BatchWarehouseOrderDtoPagedList {
  pageNumber?: number;
  pageSize?: number;
  totalCount?: number;
  items?: BatchWarehouseOrderDto[] | null;
  readonly totalPages?: number;
  readonly hasPreviousPage?: boolean;
  readonly hasNextPage?: boolean;
}
