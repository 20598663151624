import React from 'react';
import { formatDollarAmount } from '@util/numberHelpers';
import ExpandablePriceTab from '@sharedComponents/Display/ExpandablePriceTab';
import {
  OrderItemDetailsDto,
  UpdateOrderItemPriceCalculationDto,
} from '@api/fulfillment/models';
import {
  OrderDetailsDto,
  OrderItemPricingDetails,
} from '@api/orders/models';

interface OwnProps {
  personalizationPriceCalculation: UpdateOrderItemPriceCalculationDto | OrderItemPricingDetails;
  paymentType: string;
  orderExistsOnCore: boolean;
  orderItem: Nullable<OrderItemDetailsDto>;
  coreOrder: OrderDetailsDto | undefined;
  isEdit: boolean;
}

type Props = OwnProps;

const PersonalizationPriceConfirmationContent = React.memo(({
  personalizationPriceCalculation,
  paymentType,
  orderExistsOnCore,
  orderItem,
  coreOrder,
  isEdit,
}: Props) => {
  const coreOrderItem = coreOrder?.items?.find((coi) => coi.id === orderItem?.coreId);

  const updatedBasePrice = orderExistsOnCore
    ? (personalizationPriceCalculation as OrderItemPricingDetails)?.personalizationsBasePrice
    : (personalizationPriceCalculation as UpdateOrderItemPriceCalculationDto)?.
      updatedOrderItems?.[0]?.personalizationBasePrice;

  const currentBasePrice = orderExistsOnCore
    ? coreOrderItem?.pricing?.personalizationsBasePrice
    : (personalizationPriceCalculation as UpdateOrderItemPriceCalculationDto)?.
      orderItems?.[0]?.personalizationBasePrice;

  const updatedCouponDiscount = orderExistsOnCore
    ? (personalizationPriceCalculation as OrderItemPricingDetails)?.personalizationsCouponDiscount
    : (personalizationPriceCalculation as UpdateOrderItemPriceCalculationDto)?.
      updatedOrderItems?.[0]?.personalizationCouponDiscount;

  const currentCouponDiscount = orderExistsOnCore
    ? coreOrderItem?.pricing?.personalizationsCouponDiscount
    : (personalizationPriceCalculation as UpdateOrderItemPriceCalculationDto)?.
      orderItems?.[0]?.personalizationCouponDiscount;

  const updatedPersonalizationPrice = orderExistsOnCore
    ? (personalizationPriceCalculation as OrderItemPricingDetails)?.personalizationsPrice
    : (personalizationPriceCalculation as UpdateOrderItemPriceCalculationDto)?.
      updatedOrderItems?.[0]?.personalizationPrice;

  const currentPersonalizationPrice = orderExistsOnCore
    ? coreOrderItem?.pricing?.personalizationsPrice
    : (personalizationPriceCalculation as UpdateOrderItemPriceCalculationDto)?.
      orderItems?.[0]?.personalizationPrice;

  const quantity = orderExistsOnCore
    ? coreOrderItem?.quantity
    : (personalizationPriceCalculation as UpdateOrderItemPriceCalculationDto)?.
      orderItems?.[0]?.quantity;

  const updatedTaxAmount = orderExistsOnCore
    ? (personalizationPriceCalculation as OrderItemPricingDetails)?.taxAmount
    : (personalizationPriceCalculation as UpdateOrderItemPriceCalculationDto)?.
      updatedOrderItems?.[0]?.taxAmount;

  const currentTaxAmount = orderExistsOnCore
    ? coreOrderItem?.pricing?.taxAmount
    : (personalizationPriceCalculation as UpdateOrderItemPriceCalculationDto)?.
      orderItems?.[0]?.taxAmount;

  const taxUnitAmountToChange = updatedTaxAmount! - currentTaxAmount!;
  const taxAmountToChange = taxUnitAmountToChange * quantity!;

  const pretaxUnitAmountToChange = updatedPersonalizationPrice! - currentPersonalizationPrice!;
  const pretaxAmountToChange = pretaxUnitAmountToChange * quantity!;

  return (
    <div>
      <ExpandablePriceTab
        label={'Personalization'}
        subLabel={'(before tax)'}
        disableExpand={true}
        content={(
          <div>
            <div className='order-financial-details__content'>
              <div className='justify__space-between'>
                <span>Base price</span>
                <div>
                  <span className='current-amount'>{formatDollarAmount(updatedBasePrice)}</span>
                  <span className='line-through'>({formatDollarAmount(currentBasePrice)})</span>
                </div>
              </div>
              <div className='justify__space-between mt-15'>
                <span>Coupon discount</span>
                <div>
                  <span className='negative-amount mr-5'>- {formatDollarAmount(updatedCouponDiscount)}</span>
                  <span className='line-through'>({formatDollarAmount(currentCouponDiscount)})</span>
                </div>
              </div>
              <div className='justify__space-between mt-15'>
                <span>Personalization price</span>
                <div>
                  <span className='current-amount'>{formatDollarAmount(updatedPersonalizationPrice)}</span>
                  <span className='line-through'>({formatDollarAmount(currentPersonalizationPrice)})</span>
                </div>
              </div>
            </div>
            {
              isEdit &&
              <div className='mt-20'>
                <div className='order-financial-details__subtotal'>
                  <span>unit {paymentType} amount</span>
                  <span className='align__flex-end'>{formatDollarAmount(Math.abs(pretaxUnitAmountToChange!))}</span>
                </div>
              </div>
            }
            <div className='order-financial-details__subtotal'>
              <div>
                <div className='order-financial-details__subtotal--explanation'>(quantity: {quantity})</div>
                <span>{paymentType} amount</span>
              </div>
              <span className='align__flex-end'>{formatDollarAmount(Math.abs(pretaxAmountToChange!))}</span>
            </div>
          </div>
        )}
      />

      <ExpandablePriceTab
        label={'Tax'}
        disableExpand={true}
        classes={'mt-30'}
        content={(
          <div>
            <div className='order-financial-details__content'>
              <div className='justify__space-between'>
                <span>Tax</span>
                <div>
                  <span className='current-amount'>{formatDollarAmount(updatedTaxAmount)}</span>
                  <span className='line-through'>({formatDollarAmount(currentTaxAmount)})</span>
                </div>
              </div>
            </div>
            <div className='order-financial-details__subtotal'>
              <div>
                <div className='order-financial-details__subtotal--explanation'>(quantity: {quantity})</div>
                <span>{paymentType} tax amount</span>
              </div>
              <span className='align__flex-end'>{formatDollarAmount(Math.abs(taxAmountToChange!))}</span>
            </div>
          </div>
        )}
      />

      <div className='mt-30'>
        <div className='order-financial-details__total--explanation'>(quantity: {quantity})</div>
        <div className='order-financial-details__total'>
          <span>{paymentType} Total Amount</span>
          <span>
            {formatDollarAmount(Math.abs(pretaxAmountToChange!) + Math.abs(taxAmountToChange!))}
          </span>
        </div>
      </div>

      {
        (!orderExistsOnCore && !(personalizationPriceCalculation as UpdateOrderItemPriceCalculationDto)?.canUpdate) &&
        <div className='redux-form__error'>Warning: Can't update personalization.</div>
      }
    </div>
  );
});

export default PersonalizationPriceConfirmationContent;
