import React from 'react';

interface OwnProps {
  label?: string;
  value?: Nullable<JSX.Element | string | number | boolean>;
  defaultValue?: JSX.Element | string | number | boolean;
  classes?: string;
  onClick?: () => void;
}

type Props = OwnProps;

const Tag = React.memo(({
  label = '',
  value = null,
  defaultValue = '-',
  classes = '',
  onClick = undefined,
}: Props) => (
  label
    ? (
      <span
        className={`order__item-tag ${classes}`}
        onClick={onClick}
      >
        {label}:&nbsp;{value ?? defaultValue}
      </span>
    )
    : (
      <span
        className={`order__item-tag ${classes}`}
        onClick={onClick}
      >
        {value ?? defaultValue}
      </span>
    )
));

export default Tag;
