import { ObjectWithId } from '@models/common/ObjectWithId';

interface RowInfo<T> {
  original: T;
}

type PredicateOrKey<T> = (keyof T) | ((a: T, b: T) => boolean);

const compareItemsByKey = <T = ObjectWithId>(
  key: keyof T = 'id' as (keyof T),
  item1: T,
  item2: T,
) => item1[key] === item2[key];

export const isSelectedItem = <T = ObjectWithId>(
  selectedItems: T[],
  item: T,
  predicateOrKey: PredicateOrKey<T>,
): boolean => {
  const predicate = typeof predicateOrKey === 'function'
    ? predicateOrKey
    : (item1: T, item2: T) => compareItemsByKey(predicateOrKey, item1, item2);

  return selectedItems.find((i) => predicate(i, item)) !== undefined;
};

export const isRowSelected = <T = ObjectWithId>(
  rowItem: T,
  selectedItem: T,
  key: keyof T,
): boolean => (selectedItem[key] === rowItem[key]);

export const toggleItemSelection = <T = ObjectWithId>(
  selectedItems: T[],
  item: T,
  key: keyof T,
): T[] => {
  if (isSelectedItem(selectedItems, item, key)) {
    return selectedItems.filter((i) => i[key] !== item[key]);
  }

  return [
    ...selectedItems,
    item,
  ];
};

export const selectItem = <T>(
  allItems: T[],
  selectedItems: T[],
  isPageSelected: boolean,
  item: T,
  isSelected: boolean,
  predicateOrKey: PredicateOrKey<T>,
): {
  newSelectedItems: T[];
  newSelectAll: boolean;
} => {
  const predicate = typeof predicateOrKey === 'function'
    ? predicateOrKey
    : (item1: T, item2: T) => compareItemsByKey(predicateOrKey, item1, item2);

  if (isSelected) {
    const newSelectedItems = selectedItems.filter((i) => !predicate(i, item));

    return ({
      newSelectedItems,
      newSelectAll: newSelectedItems.length === 0 ? false : isPageSelected,
    });
  }

  const newSelectedItems = [
    ...selectedItems,
    item,
  ];

  return ({
    newSelectedItems,
    newSelectAll: newSelectedItems.length === allItems.length ? true : isPageSelected,
  });
};

export const selectAllItems = <T>(
  allItems: T[],
  selectedItems: T[],
  isPageSelected: boolean,
  predicateOrKey: PredicateOrKey<T>,
): {
  newSelectedItems: T[];
  newSelectAll: boolean;
} => {
  const predicate = typeof predicateOrKey === 'function'
    ? predicateOrKey
    : (item1: T, item2: T) => compareItemsByKey(predicateOrKey, item1, item2);

  if (!isPageSelected) {
    const newSelectedTasks = [...selectedItems];

    for (const item of allItems) {
      if (!selectedItems.find((i) => predicate(i, item))) {
        newSelectedTasks.push(item);
      }
    }

    return ({
      newSelectedItems: newSelectedTasks,
      newSelectAll: true,
    });
  }

  const newSelectedItems = selectedItems.filter((item) => !allItems.find((i) => predicate(i, item)));

  return ({
    newSelectedItems,
    newSelectAll: false,
  });
};

export const getSelectableTableRowProps = <T = ObjectWithId>(
  selectItemAction: (item: T) => void,
  rowInfo: RowInfo<T>,
  selectedItem: Nullable<T>,
  compareByKey: keyof T = 'id' as (keyof T),
): {
  onClick?: any;
  className?: string;
} => {
  if (!rowInfo) {
    return {};
  }

  return ({
    onClick: selectItemAction.bind(null, rowInfo.original),
    className: `text-left cursor-pointer
      ${selectedItem && isRowSelected(rowInfo.original, selectedItem, compareByKey) ? 'is-active' : ''}`,
  });
};
