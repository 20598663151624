import React from 'react';
import FileUploader from '@sharedComponents/Upload/FileUploader';

const allowedCsvExtensions = { 'Custom Files': ['.xlsx'] };

interface OwnProps {
  uploadFile: (file: Blob) => void;
}

type Props = OwnProps;

const TeamOrganizerUploader = React.memo<Props>(({ uploadFile }) => (
  <div className='w-100'>
    <FileUploader
      addFile={uploadFile}
      addBtnText={'Attach File'}
      addBtnType={'secondary'}
      acceptedFileTypes={allowedCsvExtensions}
    />
  </div>
));

export default TeamOrganizerUploader;
