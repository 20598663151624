import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import OutsideClickWrapper from '@sharedComponents/OutsideClickWrapper';
import SearchInputItem from './SearchInputItem';

class SearchInput extends PureComponent {
  state = {
    showList: false,
    searchInput: '',
    isInputActive: false,
    filteredList: this.props.allItems,
  };

  componentDidMount() {
    this.initializeSearchInputValue();
  }

  componentDidUpdate(prevProps, prevState) {
    const { allItems } = this.props;
    const { searchInput } = this.state;

    if (allItems.length > 0 && prevProps.allItems.length === 0) {
      this.initializeSearchInputValue();
      this.updateFilteredList();
    }

    if (searchInput !== prevState.searchInput) {
      this.updateFilteredList();
    }
  }

  initializeSearchInputValue = () => {
    const {
      currentItem,
      allItems,
      searchKey,
      displayKey,
    } = this.props;

    const current = allItems.find((x) => (searchKey === '' ? x : x[searchKey || 'value']) === currentItem);
    this.searchInput.value = current ? (displayKey === '' ? current : current[displayKey || 'name']) : '';
  };

  updateFilteredList = () => {
    const {
      allItems,
      displayKey,
    } = this.props;

    const { searchInput } = this.state;

    if (searchInput.length > 0) {
      this.setState({
        filteredList: allItems.filter((x) => (displayKey === '' ? x : x[displayKey || 'name']).toLowerCase().includes(searchInput.toLowerCase())),
      });
    } else {
      this.setState({ filteredList: allItems });
    }
  };

  addItem = (value) => {
    const {
      allItems,
      searchKey,
      displayKey,
      changeItem,
    } = this.props;

    const current = allItems.find((x) => (searchKey === '' ? x : x[searchKey || 'value']) === value);
    this.searchInput.value = current ? (displayKey === '' ? current : current[displayKey || 'name']) : '';

    this.setState({
      searchInput: '',
      showList: false,
    });

    changeItem(value);
  };

  onInputFocus = () => {
    this.setState({
      isInputActive: true,
      showList: true,
    });
  };

  onInputBlur = () => {
    this.setState({
      isInputActive: false,
      showList: false,
    });
  };

  onInputChange = () => {
    const newSearchInput = this.searchInput.value ? this.searchInput.value.trim() : '';
    this.setState({
      searchInput: newSearchInput,
      showList: true,
    });
    if (newSearchInput === '') {
      this.addItem(null);
    }
  };

  setSearchInputRef = (r) => {
    this.searchInput = r;
  };

  render() {
    const {
      currentItem,
      placeholderText,
      displayKey,
      searchKey,
      disabledItems,
      isFixed = true,
    } = this.props;

    const {
      showList,
      filteredList,
    } = this.state;

    return (
      <div>
        <div className='text-field mb-0'>
          <OutsideClickWrapper onClick={this.onInputBlur}>
            <>
              <input
                type='text'
                placeholder={placeholderText || 'Search'}
                onChange={this.onInputChange}
                onFocus={this.onInputFocus}
                ref={this.setSearchInputRef}
                className='w-100'
              />
              <div className='select-field__arrow' />
              {
                showList &&
                <div className={`suggestions suggestions__dropdown${isFixed ? '-fixed' : ''} custom-scrollbar`}>
                  {
                    filteredList.map((item) => (
                      <SearchInputItem
                        key={searchKey === '' ? item : item[searchKey || 'value']}
                        item={item}
                        displayKey={displayKey}
                        searchKey={searchKey}
                        isDisabled={
                          searchKey === ''
                            ? (disabledItems || []).includes(item)
                            : (disabledItems || []).map((i) => i[searchKey || 'value']).includes(item[searchKey || 'value'])
                        }
                        currentValue={currentItem}
                        addItem={this.addItem}
                      />
                    ))
                  }
                </div>
              }
            </>
          </OutsideClickWrapper>
        </div>
      </div>
    );
  }
}

SearchInput.propTypes = {
  allItems: PropTypes.array.isRequired,
  currentItem: PropTypes.any,
  changeItem: PropTypes.func.isRequired,
  displayKey: PropTypes.string,
  searchKey: PropTypes.string,
  placeholderText: PropTypes.string,
  isFixed: PropTypes.bool,
  disabledItems: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ])),
};

export default SearchInput;
