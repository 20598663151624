/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */
import type { BatchWarehouseGroupingDateTypeEnum } from './batchWarehouseGroupingDateTypeEnum';
import type { BatchWarehouseCategoryEnum } from './batchWarehouseCategoryEnum';
import type { BatchWarehouseListOrderByEnum } from './batchWarehouseListOrderByEnum';
import type { SortDirectionEnum } from './sortDirectionEnum';
import type { OrderInventoryStatusEnum } from './orderInventoryStatusEnum';

export type GetHomefieldApiWarehouseschedulingOrdergroupsListParams = { groupingDateType?: BatchWarehouseGroupingDateTypeEnum; minDate?: string; maxDate?: string; category?: BatchWarehouseCategoryEnum; pageNumber?: number; pageSize?: number; sortBy?: BatchWarehouseListOrderByEnum; sortDirection?: SortDirectionEnum; partners?: string; vendors?: string; inventoryStatus?: OrderInventoryStatusEnum; filter?: string };
