/* eslint-disable @typescript-eslint/no-unused-vars */
import ColorListSelect from '@components/shared/UploadLogoSwatchColors/ColorListSelect';
import React, { forwardRef, useImperativeHandle, useState } from 'react';

const ApprovedColors = forwardRef(({ logo, setHoveredColor, allLightColors, allDarkColors }, ref) => {
  const [approvedLightColors, setApprovedLightColors] = useState(allLightColors || []);
  const [approvedDarkColors, setApprovedDarkColors] = useState(allDarkColors || []);
  const selectAllLightColors = React.useCallback(() => {
    setApprovedLightColors(allLightColors);
  }, [allLightColors]);

  const unselectAllLightColors = React.useCallback(() => {
    setApprovedLightColors([]);
  }, []);

  const selectAllDarkColors = React.useCallback(() => {
    setApprovedDarkColors(allDarkColors);
  }, [allDarkColors]);

  const unselectAllDarkColors = React.useCallback(() => {
    setApprovedDarkColors([]);
  }, []);

  const toggleSelectColor = (stateFn, colorList, color) => {
    const isSelected = colorList.find((c) => c.id === color.id);

    if (isSelected) {
      stateFn(colorList.filter((c) => c.id !== color.id));
    } else {
      stateFn([...colorList, color]);
    }
  };

  const getData = () => ({
    approvedLightColors,
    approvedDarkColors,
  });

  const setData = (data) => {
    setApprovedLightColors(data.approvedLightColors);
    setApprovedDarkColors(data.approvedDarkColors);
  };

  useImperativeHandle(ref, () => ({
    getData,
    setData,
  }));

  return (
    <div className=''>
      <div className='logo-bank__upload-modal--colors mb-20'>
        <div className=''>
          <div className='logo-bank__upload-modal--title'>
            <div>Approved Light Colors</div>
            <div>
              <span
                className='logo-bank__upload-modal--title__btn'
                onClick={selectAllLightColors}
              >
                All
              </span>
              <span
                className='logo-bank__upload-modal--title__btn'
                onClick={unselectAllLightColors}
              >
                None
              </span>
            </div>
          </div>
          <div>
            <ColorListSelect
              allColors={allLightColors}
              selectedColors={approvedLightColors}
              toggleSelectColor={toggleSelectColor.bind(null, setApprovedLightColors, approvedLightColors)}
              setHoveredColor={setHoveredColor}
            />
          </div>
        </div>

        <div>
          <div className='logo-bank__upload-modal--title'>
            <div>Approved Dark Colors</div>
            <div>
              <span
                className='logo-bank__upload-modal--title__btn'
                onClick={selectAllDarkColors}
              >
                All
              </span>
              <span
                className='logo-bank__upload-modal--title__btn'
                onClick={unselectAllDarkColors}
              >
                None
              </span>
            </div>
          </div>
          <div>
            <ColorListSelect
              allColors={allDarkColors}
              selectedColors={approvedDarkColors}
              toggleSelectColor={toggleSelectColor.bind(null, setApprovedDarkColors, approvedDarkColors)}
              setHoveredColor={setHoveredColor}
            />
          </div>
        </div>
      </div>
    </div>
  );
});
export default ApprovedColors;
