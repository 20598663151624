const PermissionsEnum = {
  AnyUserVisit: 'any-user:visit',
  DeveloperOnlyVisit: 'developer-only:visit',
  AdminsVisit: 'admins:visit',
  AdvancedOptionsVisit: 'advanced-options:visit',
  ApplicationSettingsVisit: 'application-settings:visit',
  BackorderManagementVisit: 'backorder-management:visit',
  BulkActionsVisit: 'bulk-actions:visit',
  BulkAddItemVisit: 'bulk-add-item:visit',
  BulkAddStyleVisit: 'bulk-add-style:visit',
  BulkBuildOrgsAndStoresVisit: 'bulk-build-orgs-and-stores:visit',
  CouponsVisit: 'coupons:visit',
  FundraisingPayoutsVisit: 'fundraising-payouts:visit',
  FundraisingPayoutsSubmitPayout: 'fundraising-payouts:submit-payout',
  LockerManagerVisit: 'locker-manager:visit',
  LockerManagerEditDiscount: 'locker-manager:edit:discount',
  LockerManagerEditShipping: 'locker-manager:edit:shipping',
  LockerManagerEditCustomItems: 'locker-manager:edit:custom-items',
  LockerManagerEditPartner: 'locker-manager:edit:partner',
  LogoReviewVisit: 'logo-review:visit',
  LookupEntitiesVisit: 'lookup-entities:visit',
  LookupEntitiesColorsVisit: 'lookup-entities:visit:colors',
  LookupEntitiesDecorationLocationArtworkSizesVisit: 'lookup-entities:visit:decoration-location-artwork-sizes',
  LookupEntitiesDecorationLocationsVisit: 'lookup-entities:visit:decoration-locations',
  LookupEntitiesVendorsVisit: 'lookup-entities:visit:vendors',
  MerchandisingVisit: 'merchandising:visit',
  MerchandisingEdit: 'merchandising:edit',
  MerchandisingOptInOut: 'merchandising:opt-in-out',
  OnHandInventoryVisit: 'on-hand-inventory:visit',
  OrderItemStatusEdit: 'order-item-status:edit',
  OrderManagementEdit: 'order-management:edit',
  OrderManagementEditRushOrder: 'order-management:edit:rush-order',
  OrderManagementEditCancelOrder: 'order-management:edit:cancel-order',
  OrderManagementEditRefundOrder: 'order-management:edit:refund-order',
  OrderManagementEditChargeOrder: 'order-management:edit:charge-order',
  OrderManagementVisit: 'order-management:visit',
  OrderManagementSubmitOrder: 'order-management:submit-order',
  OrganizationsVisit: 'organizations:visit',
  PrintRoomVisit: 'print-room:visit',
  ProductCatalogVisit: 'product-catalog:visit',
  ProductCatalogEdit: 'product-catalog:edit',
  ProductCatalogRestrictedEdit: 'product-catalog:restricted-edit',
  ProductCatalogSyncDataVisit: 'product-catalog:sync-data:visit',
  ProductionArtworkVisit: 'production-artwork:visit',
  ProductionOverviewVisit: 'production-overview:visit',
  ProductionVisit: 'production:visit',
  ProductionAssemblyHagVisit: 'production-assembly-hag:visit',
  ProductionAssemblyEmbVisit: 'production-assembly-emb:visit',
  ProductionAssemblyDtgVisit: 'production-assembly-dtg:visit',
  OrderAssemblyVisit: 'order-assembly:visit',
  QualityCheckVisit: 'quality-check:visit',
  ReportsVisit: 'reports:visit',
  RosterManagerVisit: 'roster-manager:visit',
  SchedulingVisit: 'scheduling:visit',
  ServiceManagerVisit: 'service-manager:visit',
  SkuSetupVisit: 'sku-setup:visit',
  VendorOrdersVisit: 'vendor-orders:visit',
  VoucherOrdersVisit: 'voucher-orders:visit',
  MerchandisingEffortsOptIn: 'merchandising-efforts:opt-in',
} as const;

export default PermissionsEnum;
