/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */

export type PtlWallRowStatusEnum = 'HasVacancies' | 'Full';


export const PtlWallRowStatusEnum = {
  HasVacancies: 'HasVacancies' as PtlWallRowStatusEnum,
  Full: 'Full' as PtlWallRowStatusEnum,
};
