/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Accounts Homefield API
 * OpenAPI spec version: 1.0
 */

export interface ProductCreationStatusOutput {
  pending: number;
  successful: number;
  failed: number;
}
