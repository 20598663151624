/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */
import type { OrderItemPriceDto } from './orderItemPriceDto';

export interface ApplyVoucherToOrderPriceCalculationDto {
  totalAmount?: number;
  items?: OrderItemPriceDto[] | null;
}
