import React, {
  useCallback,
  useState,
} from 'react';
import {
  connect,
  ConnectedProps,
} from 'react-redux';
import {
  Field,
  reduxForm,
  InjectedFormProps,
} from 'redux-form';
import { validateOrderRefund } from '@redux/orderManagement/validations';
import { createOptionsList } from '@util/optionsMap';
import { mapListIds } from '@util/mappingHelper';
import {
  formatDollarAmount,
  parseNumber,
} from '@util/numberHelpers';
import { canRefundOrder } from '@util/paymentHelper';
import {
  refundReasons,
  cancelFullOrderReasons,
} from '@constants/common';
import { refundOrderForm } from '@constants/reduxForms';
import { accountingActionTypeEnum } from '@constants/enums/orderEnums';
import { refundReasonEnum } from '@constants/enums/refundEnums';
import Modal from '@sharedComponents/Modal/Modal';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import RadioGroup from '@sharedComponents/Form/RadioGroup';
import Input from '@sharedComponents/Form/Input';
import Select from '@sharedComponents/Form/Select';
import OrderManagementOrderRefundItems from './OrderManagementOrderRefundItems';
import { OrderRefundFormData } from '@models/forms/OrderManagement/OrderRefundFormData';
import { OrderDetailsDto } from '@api/fulfillment/models';
import { OrderPricingDetailsDto } from '@api/orders/models';

interface OwnProps {
  order: OrderDetailsDto;
  isOpen: boolean;
  closeModal: () => void;
  onSubmit: (form: OrderRefundFormData) => void;
  isCancelAction: boolean;
  coreOrderPricing: OrderPricingDetailsDto | undefined;
}

const refundOrderReasons = mapListIds([...refundReasons]);
const cancelOrderReasons = mapListIds([
  ...refundReasons,
  ...cancelFullOrderReasons,
]);

const connector = connect();

type FormProps = OwnProps & ConnectedProps<typeof connector>;

export type Props = FormProps & InjectedFormProps<OrderRefundFormData, FormProps, string[]>;

const OrderManagementOrderRefundModal = React.memo<Props>(({
  order,
  isOpen,
  closeModal,
  handleSubmit,
  isCancelAction,
  coreOrderPricing,
}) => {
  const [
    otherReasonSelected,
    setOtherReasonSelected,
  ] = useState<boolean>(false);
  const [
    isPartialRefundSelected,
    setIsPartialRefundSelected,
  ] = useState<boolean>(false);

  const handleChangeReason = useCallback((e) => {
    const isOtherReasonSelected = e.target.value === refundReasonEnum.Other;
    setOtherReasonSelected(isOtherReasonSelected);
  }, []);

  const handleActionTypeChange = useCallback((e) => {
    setIsPartialRefundSelected(e.target.value === accountingActionTypeEnum.OrderPartialRefund);
  }, []);

  const allRefundReasons = isCancelAction
    ? cancelOrderReasons
    : refundOrderReasons;

  const label = isCancelAction ? 'Cancel' : 'Refund';

  const refundReasonsOptions = createOptionsList({
    // @ts-expect-error - TS doesn't like the type of allRefundReasons
    list: allRefundReasons,
    key: 'id',
    value: 'reason',
    name: 'reason',
    emptyOption: {
      disabled: true,
      name: 'Choose Reason',
    },
  });

  return (
    <Modal
      isOpen={isOpen}
      closeModal={closeModal}
      modalHeight={'l'}
      modalWidth={'s'}
      title={`${label} Order O${order.orderNumber}?`}
      buttons={(
        <ModalButtons
          confirmBtnText={`${label} Order`}
          cancelBtnText={'Cancel'}
          onClose={closeModal}
          formSubmission={true}
          isDangerousAction={true}
          formId={refundOrderForm}
        />
      )}
    >
      <>
        {
          isOpen &&
          <div className='mt-20'>
            <OrderManagementOrderRefundItems
              items={order.items!}
              coreOrderItemsPricing={coreOrderPricing?.orderItemsPricingDetails ?? undefined}
            />
            <form
              className='refund__color-river-bed filter-group w-100 pr-10'
              id={refundOrderForm}
              onSubmit={handleSubmit}
            >
              <Field
                name={'reason'}
                component={Select}
                onChange={handleChangeReason}
              >
                {refundReasonsOptions}
              </Field>

              {
                otherReasonSelected &&
                <Field
                  name={'reasonOther'}
                  placeholder={'Enter other reason'}
                  component={Input}
                  type={'text'}
                />
              }

              <Field
                name={'actionType'}
                component={RadioGroup}
                groupClassName={'radio__group radio__gray-02 m__w--540'}
                onChange={handleActionTypeChange}
                radioItems={[
                  {
                    value: accountingActionTypeEnum.NoChange,
                    label: 'No Refund',
                    disabled: !isCancelAction,
                  },
                  {
                    value: accountingActionTypeEnum.OrderFullRefund,
                    disabled: !canRefundOrder(order && order.isCompleteRefund!),
                    label: `Full Refund (${formatDollarAmount(coreOrderPricing
                      ? coreOrderPricing.pricePaid
                      : order.pricePaid)})`,
                  },
                  {
                    value: accountingActionTypeEnum.OrderPartialRefund,
                    className: 'with-input mt-20',
                    hasInput: true,
                    rootClassName: 'with-input__radio',
                    disabled: !canRefundOrder(order && order.isCompleteRefund!),
                    inputNode: (
                      <div className='with-input__label'>
                        <div className='text-field'>
                          <Field
                            name={'amount'}
                            component={Input}
                            type={'number'}
                            parse={parseNumber}
                            placeholder={'Partial Refund'}
                            disabled={!isPartialRefundSelected}
                          />
                        </div>
                      </div>
                    ),
                  },
                ] as any[]}
              />
            </form>
          </div>
        }
      </>
    </Modal>
  );
});

export default connector(reduxForm<OrderRefundFormData, FormProps, string[]>({
  form: refundOrderForm,
  validate: validateOrderRefund,
  shouldError: () => true,
  enableReinitialize: true,
})(OrderManagementOrderRefundModal));
