import React from 'react';
import Modal from '@sharedComponents/Modal/Modal';
import CreateCrossSellForm from './CreateCrossSellForm';
import { Box } from '@material-ui/core';

interface Props {
  isOpen: boolean;
  closeModal: () => void;
}

const CreateCrossSellModal = ({ isOpen, closeModal }: Props) => (
  <div>
    <Modal
      isOpen={isOpen}
      title='Create Cross Sell'
      closeModal={closeModal}
    >
      <Box mb={2}>
        <CreateCrossSellForm
          closeModal={closeModal}
        />
      </Box>
    </Modal>
  </div>
);

export default CreateCrossSellModal;
