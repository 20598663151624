import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import {
  getDecorationSizeOptions,
  getTrueOrFalseOptions,
} from '@constants/options/options';
import { editProductForm } from '@constants/reduxForms';
import {
  emptyImageTextEnum,
  keyNameEnum,
} from '@constants/enums/commonEnums';
import { generalOptions } from '@util/optionsMap';
import { parseNumber } from '@util/numberHelpers';
import { parseStringToBoolean } from '@util/valueHelpers';
import ImagePreviewCell from '@sharedComponents/Table/TableCells/ImagePreviewCell';
import Input from '@sharedComponents/Form/Input';
import Textarea from '@sharedComponents/Form/Textarea';
import Select from '@sharedComponents/Form/Select';
import FormError from '@sharedComponents/Form/FormError';
import ProductVendors from './ProductFormContent/ProductVendors';

class ProductForm extends Component {
  state = {
    isActiveProductTab: true,
    isActiveProductVendorsTab: false,
  };

  onKeyPress = (e) => {
    if (e.key === keyNameEnum.Enter) {
      e.preventDefault();
    }
  };

  selectProductTab = () => {
    this.setState(() => ({
      isActiveProductTab: true,
      isActiveProductVendorsTab: false,
    }));
  };

  selectProductVendorsTab = () => {
    this.setState(() => ({
      isActiveProductTab: false,
      isActiveProductVendorsTab: true,
    }));
  };

  render() {
    const {
      onSubmit,
      error,
      change,
      initialValues,
      inventoryAlwaysAvailable,
    } = this.props;

    const {
      isActiveProductTab,
      isActiveProductVendorsTab,
    } = this.state;

    const decorationSizes = generalOptions(getDecorationSizeOptions(false));
    const websiteVisibilityOptions = generalOptions(getTrueOrFalseOptions('Disabled', 'Enabled'));
    const productAvailabilityOptions = generalOptions(getTrueOrFalseOptions('Discontinued', 'Available'));

    let imageFront = '';
    let imageBack = '';
    let imageSide = '';
    let styleName = '';
    let colorGroup = '';

    if (initialValues.coloredStyle) {
      imageFront = initialValues.coloredStyle.imageUrlFront;
      imageBack = initialValues.coloredStyle.imageUrlBack;
      imageSide = initialValues.coloredStyle.imageUrlSide;
      styleName = initialValues.coloredStyle.style.name;
      colorGroup = initialValues.coloredStyle.colorGroup;
    }

    const selectedPrimaryVendor = initialValues?.vendorId && { code: initialValues.vendorId };

    const disabled = {
      ...this.props.disabled,
      productVendors: (initialValues.productVendors || []).map(() => ({ vendorId: true })),
    };

    return (
      <form
        className='redux-form w-100'
        onSubmit={onSubmit}
        onKeyPress={this.onKeyPress}
        id={editProductForm}
      >
        <div>
          <div>
            <div className='tableView__details--header--subtitle mb-20'>
              <span>{styleName} - {colorGroup}</span>
            </div>
            <div className='catalog-details__images-aligned'>
              <ImagePreviewCell
                imageUrl={imageFront}
                noImageText={emptyImageTextEnum.Front}
                height={'120px'}
                width={'120px'}
              />
              <ImagePreviewCell
                imageUrl={imageBack}
                noImageText={emptyImageTextEnum.Back}
                height={'120px'}
                width={'120px'}
              />
              <ImagePreviewCell
                imageUrl={imageSide}
                noImageText={emptyImageTextEnum.Side}
                height={'120px'}
                width={'120px'}
              />
            </div>
          </div>

          <div className='tabs mb-30 pr-5'>
            <div
              className={`tab-item ${isActiveProductTab && 'is-active'}`}
              onClick={this.selectProductTab}
            >
              Product Information
            </div>
            <div
              className={`tab-item ${isActiveProductVendorsTab && 'is-active'}`}
              onClick={this.selectProductVendorsTab}
            >
              Product Vendors
            </div>
          </div>

          {
            isActiveProductTab &&
            <div>
              <div className='redux-form__container'>
                <div className='catalog-details__modal--row'>
                  <div className='redux-form__column--size-xl'>
                    <div className='tableView__details--header--title mb-30'>
                      <span>Basic Information</span>
                    </div>
                    <div className='redux-form__column--size-xl'>
                      <div className='ml-15 mr-30 m-b-30'>
                        <div className='redux-form__row'>
                          <div className='redux-form__column--size-s'>
                            <label className='redux-form__label'>
                              SKU
                              <span className='required'>*</span>
                            </label>
                            <Field
                              name={'sku'}
                              placeholder={'Enter product SKU'}
                              component={Input}
                              type={'text'}
                              disabled={true}
                            />
                          </div>

                          <div className='redux-form__column--size-s ml-20'>
                            <label className='redux-form__label'>
                              Size
                            </label>
                            <Field
                              name={'size'}
                              placeholder={'Enter size'}
                              component={Input}
                              type={'text'}
                              disabled={disabled.size}
                            />
                          </div>

                          <div className='redux-form__column--size-s ml-20'>
                            <label className='redux-form__label'>
                              Decoration Size
                            </label>
                            <Field
                              name={'decorationSize'}
                              component={Select}
                              disabled={disabled.decorationSize}
                            >
                              {decorationSizes}
                            </Field>
                          </div>
                        </div>

                        <div className='redux-form__column--size-xl'>
                          <label className='redux-form__label'>
                            Description
                          </label>
                          <Field
                            name={'description'}
                            placeholder={'Enter product description'}
                            component={Textarea}
                            type={'text'}
                            disabled={disabled.description}
                          />
                        </div>

                        <div className='redux-form__row'>
                          <div className='redux-form__column--size-s'>
                            <label className='redux-form__label'>
                              Weight (lbs)
                            </label>
                            <Field
                              name={'weight'}
                              placeholder={'Enter weight'}
                              component={Input}
                              type={'number'}
                              parse={parseNumber}
                              disabled={disabled.weight}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='redux-form__column--size-l'>
                <div className='tableView__details--header--title mb-30'>
                  <span>Status</span>
                </div>
                <div className='redux-form__column--size-xl'>
                  <div className='ml-15 mr-30 m-b-30'>
                    <div className='redux-form__row'>
                      <div className='redux-form__column--size-m'>
                        <label className='redux-form__label'>
                          Website Visibility
                        </label>
                        <Field
                          name={'disabled'}
                          component={Select}
                          disabled={disabled.disabled}
                          parse={parseStringToBoolean}
                        >
                          {websiteVisibilityOptions}
                        </Field>
                      </div>

                      <div className='redux-form__column--size-m ml-20'>
                        <label className='redux-form__label'>
                          Product Availability
                        </label>
                        <Field
                          name={'discontinued'}
                          component={Select}
                          disabled={disabled.discontinued}
                          parse={parseStringToBoolean}
                        >
                          {productAvailabilityOptions}
                        </Field>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }

          {
            isActiveProductVendorsTab &&
            <ProductVendors
              selectedPrimaryVendor={selectedPrimaryVendor}
              change={change}
              disabled={disabled}
              inventoryAlwaysAvailable={inventoryAlwaysAvailable}
            />
          }

          <FormError error={error} />
        </div>
      </form>
    );
  }
}

ProductForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  error: PropTypes.string,
  disabled: PropTypes.object,
  initialValues: PropTypes.object.isRequired,
  productVendorMappingsForSku: PropTypes.arrayOf(PropTypes.object).isRequired,
  change: PropTypes.func,
  inventoryAlwaysAvailable: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  productVendorMappingsForSku: state.productCatalog.productVendorMappingsForSku,
});

export default connect(mapStateToProps)(ProductForm);
