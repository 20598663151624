import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Icon from '@sharedComponents/Icons/Icon';
import OutsideClickWrapper from '@sharedComponents/OutsideClickWrapper';
import ColorChip from '@sharedComponents/ColorChip';
import SuggestionsDropdown from '@sharedComponents/Inputs/Dropdowns/SuggestionsDropdown';
import ChooseColorsStylesTable from './ChooseColorsStylesTable';

class ChooseColorsTab extends PureComponent {
  state = {
    isInputActive: false,
    showColorsList: false,
    searchInput: '',
    filteredColors: this.props.allColors,
  };

  componentDidUpdate(prevProps, prevState) {
    const { allColors } = this.props;
    const { searchInput } = this.state;

    if (searchInput !== prevState.searchInput) {
      if (searchInput.length > 0) {
        this.setState(() => ({
          filteredColors: allColors.filter((c) => c.name.toLowerCase().includes(searchInput.toLowerCase())),
        }));
      } else {
        this.setState(() => ({
          filteredColors: allColors,
        }));
      }
    }
  }

  onInputFocus = () => {
    this.setState(() => ({
      isInputActive: true,
      showColorsList: true,
    }));
  };

  onInputBlur = () => {
    this.searchInput.value = '';
    this.setState(() => ({
      isInputActive: false,
      showColorsList: false,
      searchInput: '',
    }));
  };

  onInputChange = () => {
    const newSearchInput = this.searchInput.value ? this.searchInput.value.trim() : '';
    this.setState(() => ({
      searchInput: newSearchInput,
      showColorsList: true,
    }));
  };

  setSearchInputRef = (r) => {
    this.searchInput = r;
  };

  render() {
    const {
      selectedColors,
      addColor,
      removeColor,
      styles,
      colorsByStyle,
    } = this.props;

    const {
      isInputActive,
      filteredColors,
      showColorsList,
    } = this.state;

    return (
      <div>
        <div className={`chips-search has-icon w-60 mb-15 ${isInputActive ? 'active' : ''}`}>
          <div>
            <Icon
              materialIcon={'style'}
              classes={'mb-5'}
            />
          </div>
          <div className='chips'>
            <div className='text-field mb-0'>
              <OutsideClickWrapper onClick={this.onInputBlur}>
                <>
                  <input
                    type='text'
                    placeholder={'Add Color Group'}
                    onChange={this.onInputChange}
                    onFocus={this.onInputFocus}
                    ref={this.setSearchInputRef}
                  />

                  {
                    showColorsList &&
                    <SuggestionsDropdown
                      compareBy={'code'}
                      displayName={'name'}
                      suggestions={filteredColors}
                      selectedSuggestions={selectedColors}
                      addToList={addColor}
                      size={'m'}
                    />
                  }
                </>
              </OutsideClickWrapper>
            </div>
          </div>
        </div>

        <div className='flex align__center mb-30'>
          <div className='w-20'>Selected colors:</div>
          <div className='w-80 ml-10'>
            {
              selectedColors.length > 0
                ? selectedColors.map((color) => (
                  <ColorChip
                    key={color.code}
                    color={color}
                    removeColor={removeColor}
                  />
                ))
                : '-'
            }
          </div>
        </div>

        <ChooseColorsStylesTable
          queue={styles}
          colorsByStyle={colorsByStyle}
          selectedColors={selectedColors.map((c) => c.code)}
        />
      </div>
    );
  }
}

ChooseColorsTab.propTypes = {
  allColors: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedColors: PropTypes.arrayOf(PropTypes.object).isRequired,
  addColor: PropTypes.func.isRequired,
  removeColor: PropTypes.func.isRequired,
  styles: PropTypes.arrayOf(PropTypes.object).isRequired,
  colorsByStyle: PropTypes.object.isRequired,
};

export default ChooseColorsTab;
