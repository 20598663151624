/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Catalog Homefield API
 * OpenAPI spec version: v1
 */
import type { PersonalizationColorDto } from './personalizationColorDto';

export interface UpdateColoredStyleCommand {
  id?: number;
  styleId?: number;
  code?: string | null;
  colorGroup?: string | null;
  colorA?: string | null;
  colorB?: string | null;
  floodColorId?: number | null;
  imageUrlFront?: string | null;
  imageUrlBack?: string | null;
  imageUrlSide?: string | null;
  imageUrlGrid?: string | null;
  qtyVendorMinimum?: number | null;
  alwaysAvailableVendorInventory?: boolean;
  disabled?: boolean;
  discontinued?: boolean;
  preferredVendor?: boolean;
  personalizationColors?: PersonalizationColorDto[] | null;
}
