/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */
import type { DecorationLocationEnum } from './decorationLocationEnum';

export interface ArtworkTaskItemTriageDto {
  sku?: string | null;
  logoUrl?: string | null;
  logoId?: number;
  lockerId?: number;
  decorationSizeHeight?: number | null;
  decorationSizeWidth?: number | null;
  decorationMethod?: string | null;
  decorationLocation?: DecorationLocationEnum;
}
