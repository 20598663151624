import * as actionTypes from './types';
import productCatalogUtilityUrls from '@constants/sqdApiUrls/productCatalogUtilityUrls';
import { mapStyleDecorationLocations } from '@util/componentHelpers/productCatalogHelper';
import { makeApiCall, makeApiCallWithSubmissionError } from '@util/apiHelper';
import { catalogApi } from '../../sqdApis';

export const updateLayoutsTable = (data) => ({
  type: actionTypes.UPDATE_LAYOUTS_TABLE,
  data,
});

export const updateLayoutDetails = (data) => ({
  type: actionTypes.UPDATE_LAYOUT_DETAILS,
  data,
});

export const updateLayoutSports = (data) => ({
  type: actionTypes.UPDATE_SPORTS_FOR_LAYOUT,
  data,
});

export const updateSportsForLayoutDictionary = (data) => ({
  type: actionTypes.UPDATE_SPORTS_FOR_LAYOUT_DICTIONARY,
  data,
});

export const updateLayoutValidationErrors = (data) => ({
  type: actionTypes.UPDATE_LAYOUT_VALIDATION_ERRORS,
  data,
});

export const fetchLayoutTable = (
  pageNumber = 1,
  pageSize = 10,
  search = '',
  categories = [],
  sports = [],
  styleId = null,
) =>
async (dispatch) => {
  const call = catalogApi.get(
    productCatalogUtilityUrls.layoutsTable(
      pageNumber,
      pageSize,
      search,
      categories,
      sports,
      styleId,
    ),
    { handleBlockingLoading: false },
  );

  const res = await makeApiCall(call);

  if (res) {
    dispatch(updateLayoutsTable({
      totalPages: res.totalPages,
      hasPreviousPage: res.hasPreviousPage,
      hasNextPage: res.hasNextPage,
      pageNumber: res.pageNumber,
      pageSize: res.pageSize,
      queue: res.items,
      totalCount: res.totalCount,
    }));
  }

  if (res.items) {
    const sportsForLayoutsDictionary = {};

    for (const item of res.items) {
      const sportsCall = catalogApi.get(productCatalogUtilityUrls.sportsForLayout(item.id), {
        handleBlockingLoading: false,
      });

      const sportsData = await makeApiCall(sportsCall);
      sportsForLayoutsDictionary[item.id] = sportsData;
    }

    dispatch(updateSportsForLayoutDictionary(sportsForLayoutsDictionary));
  }
};

export const fetchLayoutDetails = (layoutId) => async (dispatch) => {
  const call = catalogApi.get(productCatalogUtilityUrls.layout(layoutId), { handleBlockingLoading: false });
  const res = await makeApiCall(call);

  if (res) {
    dispatch(updateLayoutDetails(res));
  }
};

export const getSportsForLayout = (layoutId) => async (dispatch) => {
  const call = catalogApi.get(productCatalogUtilityUrls.sportsForLayout(layoutId), { handleBlockingLoading: false });
  const res = await makeApiCall(call);

  if (res) {
    dispatch(updateLayoutSports(res));
  }
};

export const getLayoutDetails = async (layoutId) => {
  const call = catalogApi.get(productCatalogUtilityUrls.layout(layoutId), { handleBlockingLoading: false });
  const res = await makeApiCall(call);

  return res;
};

const validateStyleLayout = async (style, layoutId) => {
  const layoutDetails = await getLayoutDetails(layoutId);
  const body = {
    style,
    layout: layoutDetails,
  };

  const call = catalogApi.put(productCatalogUtilityUrls.validateLayoutStyles, {
    headers: {
      'Content-type': 'application/json',
    },
    body: JSON.stringify(body),
    handleBlockingLoading: false,
  });

  const res = await makeApiCallWithSubmissionError(call);

  if (res?.result) {
    res.result.layout = layoutDetails;
  }

  return res;
};

export const validateLayouts = (
  layoutsInputList,
  currentDefaultLogo,
  logoLocations,
  defaultPersonalizations,
  customOnlyPersonalizations,
) =>
async (dispatch) => {
  const decorationLocations = mapStyleDecorationLocations(
    currentDefaultLogo,
    logoLocations,
    defaultPersonalizations,
    customOnlyPersonalizations,
  );

  const style = { decorationLocations };

  const layoutValidationErrors = {};
  for (const layout of layoutsInputList) {
    const res = await validateStyleLayout(style, layout.id);

    if (res?.result) {
      if (!res.result.isValid) {
        layoutValidationErrors[layout.id] = {
          layoutName: layout.name,
          unsupportedLocations: res.result.unsupportedLocations,
        };
      }
    }
  }

  dispatch(updateLayoutValidationErrors(layoutValidationErrors));
};
