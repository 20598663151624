/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */
import type { UploadOrderItemFileDto } from './uploadOrderItemFileDto';

export interface UploadItemFilesCommand {
  jobId?: number;
  items?: UploadOrderItemFileDto[] | null;
}
