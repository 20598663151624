import React, { useCallback, useEffect, useState } from 'react';
import Modal from '@sharedComponents/Modal/Modal';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import FormInput from '@sharedComponents/Form/FormInput';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import yup from '@util/yupValidationHelper';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface OwnProps {
  title: string;
  jobIds: number[];
  formId: string;
  isOpen: boolean;
  onClose: () => void;
  onPrint: (jobIds: number[], printerNumber: number) => void;
}

type Props = OwnProps;

const DtfPrinterModal = React.memo<Props>(({
  title,
  jobIds,
  formId,
  isOpen,
  onClose,
  onPrint,
}) => {
  const { printRoomAvailablePrinterNumbers } = useFlags();

  const [
    printNumberList,
    setPrintNumberList,
  ] = useState<number[]>([]);

  const schema = yup.object({
    printerNumber: yup.number()
      .required('Printer Number is required')
      .integer('Printer Number must be a number')
      .oneOf(printNumberList, `Printer Number must be one of: ${printNumberList.join(', ')}`),
  });

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      printerNumber: 0,
    },
  });

  const handleCloseModal = useCallback(() => {
    reset();
    onClose();
  }, [
    reset,
    onClose,
  ]);

  useEffect(() => {
    if (typeof printRoomAvailablePrinterNumbers === 'string') {
      const printers: number[] = [];
      const parsedList = printRoomAvailablePrinterNumbers
        .split(',')
        .map((x) => parseInt(x))
        .filter((x) => x > 0);

      for (const item of parsedList) {
        printers.push(item);
      }

      setPrintNumberList(printers);
      reset({ printerNumber: printers[0] });
    }
  }, [reset, printRoomAvailablePrinterNumbers]);

  const onSubmit = useCallback((form: { printerNumber: number | undefined }) => {
    if (form.printerNumber !== undefined) {
      onPrint(jobIds, form.printerNumber);
      handleCloseModal();
    } else {
      console.error('Printer Number is undefined');
    }
  }, [
    handleCloseModal,
    jobIds,
    onPrint,
  ]);

  return (
    <Modal
      modalSize={'m'}
      title={title}
      isOpen={isOpen}
      closeModal={handleCloseModal}
      buttons={(
        <ModalButtons
          confirmBtnText={'Print'}
          cancelBtnText={'Cancel'}
          onClose={handleCloseModal}
          formSubmission={true}
          formId={formId}
        />
      )}
    >
      <form
        id={formId}
        onSubmit={handleSubmit(onSubmit)}
      >
        <label className='redux-form__label'>
          Printer Number
          <span className='required'>*</span>
        </label>
        <div>
          <FormInput
            placeholder={'Printer Number'}
            type={'number'}
            register={register}
            error={errors.printerNumber}
            required={true}
            autoFocus={true}
            {...register('printerNumber')}
          />
        </div>
      </form>
    </Modal>
  );
});

export default DtfPrinterModal;
