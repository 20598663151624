import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  addDtgEnvironment,
  editDtgEnvironment,
  deleteDtgEnvironment,
} from '@APICalls/dtg/actions';
import {
  pageSizeOptionsSmallTable,
  defaultPageSizeSmallTable,
} from '@constants/values';
import { getDtgEnvironments } from '@redux/productCatalog/actions';
import { parseDateTimeNumeric } from '@util/dateHandler';
import { getPagingParamsFromTable } from '@util/tableHelpers';
import { materialSwal } from '@util/componentHelper';
import ShowMoreActionsButton from '@sharedComponents/Buttons/ShowMoreActionsButton';
import Button from '@sharedComponents/Buttons/Button';
import Table from '@sharedComponents/Table/Table';
import DtgEnvironmentViewModal from './DtgEnvironmentModals/DtgEnvironmentViewModal';
import DtgEnvironmentAddModal from './DtgEnvironmentModals/DtgEnvironmentAddModal';
import DtgEnvironmentEditModal from './DtgEnvironmentModals/DtgEnvironmentEditModal';
import DtgEnvironmentDeleteModal from './DtgEnvironmentModals/DtgEnvironmentDeleteModal';

const DtgEnvironmentsTable = Table();

class DtgEnvironments extends PureComponent {
  state = {
    pageNumber: 1,
    pageSize: defaultPageSizeSmallTable,
    search: '',
    viewEnvironmentIsOpen: false,
    addEnvironmentIsOpen: false,
    editEnvironmentIsOpen: false,
    deleteEnvironmentIsOpen: false,
    selectedSetting: {},
  };

  componentDidMount() {
    this.fetchDtgEnvironments();
  }

  addEnvironment = async (data) => {
    const result = await addDtgEnvironment(data);
    if (result?.success) {
      materialSwal('Success', result.message, 'success');
      this.closeModals();
      this.fetchDtgEnvironments();
    }
  };

  editEnvironment = async (data) => {
    const { id } = this.state.selectedSetting;

    if (!id) {
      materialSwal('Error', 'ID is empty', 'error');

      return;
    }
    const result = await editDtgEnvironment(id, data);
    if (result?.success) {
      materialSwal('Success', result.message, 'success');
      this.closeModals();
      this.fetchDtgEnvironments();
    }
  };

  deleteEnvironment = async (id) => {
    if (!id) {
      materialSwal('Error', 'ID is empty', 'error');

      return;
    }
    const result = await deleteDtgEnvironment(id);
    if (result?.success) {
      materialSwal('Success', result.message, 'success');
      this.closeModals();
      this.fetchDtgEnvironments();
    }
  };

  fetchDtgEnvironments = () => {
    const { dispatch } = this.props;
    const {
      pageNumber,
      pageSize,
    } = this.state;

    dispatch(getDtgEnvironments(pageNumber, pageSize));
  };

  fetchData = (state, instance) => {
    const {
      page,
      pageSize,
    } = getPagingParamsFromTable(instance);

    this.setState({
      pageNumber: page + 1,
      pageSize,
    }, this.fetchDtgEnvironments);
  };

  closeModals = () => {
    this.setState({
      viewEnvironmentIsOpen: false,
      addEnvironmentIsOpen: false,
      editEnvironmentIsOpen: false,
      deleteEnvironmentIsOpen: false,
      selectedSetting: {},
    });
  };

  openViewModal = (selectedSetting) => {
    this.setState({
      viewEnvironmentIsOpen: true,
      selectedSetting,
    });
  };

  openAddModal = () => {
    this.setState({
      addEnvironmentIsOpen: true,
      selectedSetting: {},
    });
  };

  openEditModal = (selectedSetting) => {
    this.setState({
      editEnvironmentIsOpen: true,
      selectedSetting,
    });
  };

  openDeleteModal = (selectedSetting) => {
    this.setState({
      deleteEnvironmentIsOpen: true,
      selectedSetting,
    });
  };

  getColumns = () => [
    {
      Header: 'Name',
      accessor: 'environmentName',
      minWidth: 120,
      sortable: true,
      Cell: (cellProps) => cellProps.value && <span>{cellProps.value}</span>,
    },
    {
      Header: 'Note',
      accessor: 'note',
      minWidth: 180,
      Cell: (cellProps) => cellProps.value && <span>{cellProps.value}</span>,
    },
    {
      Header: 'Created',
      accessor: 'created',
      minWidth: 70,
      Cell: (cellProps) => cellProps.value && <div>{parseDateTimeNumeric(cellProps.value)}</div>,
    },
    {
      Header: 'Updated',
      accessor: 'updated',
      minWidth: 70,
      Cell: (cellProps) => cellProps.value && <div>{parseDateTimeNumeric(cellProps.value)}</div>,
    },
    {
      Header: 'Actions',
      accessor: '',
      resizable: false,
      minWidth: 40,
      style: { overflow: 'visible' },
      Cell: (cellProps) => (
        <ShowMoreActionsButton
          actions={[
            {
              text: 'View',
              action: this.openViewModal.bind(null, cellProps.value),
              isVisible: true,
            },
            {
              text: 'Edit',
              action: this.openEditModal.bind(null, cellProps.value),
              isVisible: true,
            },
            {
              text: 'Delete',
              action: this.openDeleteModal.bind(null, cellProps.value),
              isDangerous: true,
              isVisible: true,
            },
          ]}
        />
      ),
    },
  ];

  render() {
    const { dtgEnvironments } = this.props;
    const {
      viewEnvironmentIsOpen,
      addEnvironmentIsOpen,
      editEnvironmentIsOpen,
      deleteEnvironmentIsOpen,
      selectedSetting,
      pageNumber,
    } = this.state;

    return (
      <div className='container'>
        <DtgEnvironmentViewModal
          isOpen={viewEnvironmentIsOpen}
          closeModal={this.closeModals}
          dtgEnvironment={selectedSetting}
        />
        <DtgEnvironmentAddModal
          isOpen={addEnvironmentIsOpen}
          closeModal={this.closeModals}
          onSubmit={this.addEnvironment}
        />
        <DtgEnvironmentEditModal
          isOpen={editEnvironmentIsOpen}
          closeModal={this.closeModals}
          onSubmit={this.editEnvironment}
          initialValues={selectedSetting}
        />
        <DtgEnvironmentDeleteModal
          isOpen={deleteEnvironmentIsOpen}
          closeModal={this.closeModals}
          dtgEnvironment={selectedSetting}
          onDelete={this.deleteEnvironment}
        />
        <div className='table-options w-100'>
          <div className='flex' />
          <div className='flex'>
            <Button
              type={'primary'}
              text={'Add New Environment'}
              onClick={this.openAddModal}
              classes={'colors__add-btn'}
            />
          </div>
        </div>
        <br />
        <div className='master-detail'>
          <div className='lockerManager__master'>
            <div className='sheet'>
              <DtgEnvironmentsTable
                data={dtgEnvironments.items}
                columns={this.getColumns()}
                defaultPageSize={defaultPageSizeSmallTable}
                showPagination={dtgEnvironments.hasNextPage || dtgEnvironments.hasPreviousPage}
                pageSizeOptions={pageSizeOptionsSmallTable}
                totalPages={dtgEnvironments.totalPages}
                hasNextPage={dtgEnvironments.hasNextPage}
                hasPreviousPage={dtgEnvironments.hasPreviousPage}
                onFetchData={this.fetchData}
                defaultPage={pageNumber}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

DtgEnvironments.propTypes = {
  dtgEnvironments: PropTypes.object.isRequired,
};

const mapStateToProps = ({ productCatalog }) => ({
  dtgEnvironments: productCatalog.dtgEnvironments,
});

export default connect(mapStateToProps)(DtgEnvironments);
