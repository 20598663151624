/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */

export type PrintQueueTableSortQueueByEnum = 'SheetId' | 'NumberOfLogos' | 'ShipDate' | 'StatusChangeOn';


export const PrintQueueTableSortQueueByEnum = {
  SheetId: 'SheetId' as PrintQueueTableSortQueueByEnum,
  NumberOfLogos: 'NumberOfLogos' as PrintQueueTableSortQueueByEnum,
  ShipDate: 'ShipDate' as PrintQueueTableSortQueueByEnum,
  StatusChangeOn: 'StatusChangeOn' as PrintQueueTableSortQueueByEnum,
};
