import { accountsApiRoot, emailApiRoot, financialServicesRoot, fulfillmentRoot, ordersApiRoot, productCatalogRoot, slServicesRoot } from '../urls/configUrls';

const utility = 'utility' as const;
const homefield = 'homefield/api' as const;
const service = 'service/api' as const;
const support = `${homefield}/support` as const;
const swagger = 'swagger' as const;
const version = 'version' as const;

export const shippingOptions = `${utility}/shippingOptions` as const;
export const settings = `${utility}/settings` as const;
export const getBrands = `${utility}/brands` as const;
export const teamColors = `${homefield}/team-colors` as const;
export const colors = `${homefield}/colors` as const;
export const uploadImage = `${homefield}/images.upload` as const;

export const serviceVersion = `${service}/${version}` as const;
export const slServicesSwagger = `${slServicesRoot}/${swagger}` as const;
export const fulfillmentSwagger = `${fulfillmentRoot}/${swagger}` as const;
export const catalogSwagger = `${productCatalogRoot}/${swagger}` as const;
export const financialSwagger = `${financialServicesRoot}/${swagger}` as const;
export const ordersSwagger = `${ordersApiRoot}/${swagger}` as const;
export const accountsSwagger = `${accountsApiRoot}/${swagger}` as const;
export const emailSwagger = `${emailApiRoot}/${swagger}` as const;

export const applicationSettings = `${support}/application-settings` as const;

export const shippingPrediction = `${homefield}/shippingprediction` as const;
export const generateShippingData = (date: string): string => `${shippingPrediction}/shipping-prediction.generate-data?ordersAfter=${date}`;
export const startTraining = `${shippingPrediction}/shipping-prediction.start-training` as const;

export const failReasons = `${support}/qafailreasons` as const;

export const dtgStations = `${homefield}/dtg/stations` as const;
export const dtgStation = (id: string): string => `${dtgStations}/${parseInt(id)}`;

export const uploadFile = `${support}/files.upload` as const;

export const coloredStyleDecorationMethod = (id: number): string => `colored-styles/${id}/decorationMethod`;

export default {
  shippingOptions,
  settings,
  serviceVersion,
  fulfillmentSwagger,
  catalogSwagger,
  financialSwagger,
  slServicesSwagger,
  accountsSwagger,
  emailSwagger,
  getBrands,
  teamColors,
  colors,
  uploadImage,
  applicationSettings,
  generateShippingData,
  startTraining,
  failReasons,
  dtgStations,
  dtgStation,
  uploadFile,
  coloredStyleDecorationMethod,
};
