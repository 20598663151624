/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */

export type SchedulingQueriesSortByEnum = 'ShipDate' | 'OrderNumber' | 'LockerNumber' | 'GpCustomerId';


export const SchedulingQueriesSortByEnum = {
  ShipDate: 'ShipDate' as SchedulingQueriesSortByEnum,
  OrderNumber: 'OrderNumber' as SchedulingQueriesSortByEnum,
  LockerNumber: 'LockerNumber' as SchedulingQueriesSortByEnum,
  GpCustomerId: 'GpCustomerId' as SchedulingQueriesSortByEnum,
};
