/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Product Catalog API
 * OpenAPI spec version: v1
 */

export interface SkuInfo {
  sku?: string | null;
  vendorCode?: string | null;
  upc?: string | null;
  vendorItemNumber?: string | null;
}
