import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

class Alerts extends Component {
   
  UNSAFE_componentWillReceiveProps(newProps) {
    const {
      onClose,
      timeout,
      messages,
    } = newProps;

    if (messages.length === 0) return;
    this.timer = setTimeout(onClose, timeout);
  }

  clearMessages = () => {
    clearTimeout(this.timer);
    this.props.onClose();
  };

  alertClass = (type) => {
    const classes = {
      error: 'alert-danger',
      alert: 'alert-warning',
      notice: 'alert-info',
      success: 'alert-success',
    };

    return `alert ${classes[type]} flash`;
  };

  render() {
    const { messages } = this.props;

    return (
      messages.length === 0
        ? <div />
        : (
          <div>
            {
              messages.map((m, i) => (
                <div
                  key={i}
                  className={this.alertClass(m.type)}
                >
                  <button
                    onClick={this.clearMessages}
                    className='close'
                  > &times;
                  </button>
                  <div className='alert-text'>
                    {m.text}
                  </div>
                </div>
              ))
            }
          </div>
        )
    );
  }
}

Alerts.propTypes = {
  onClose: PropTypes.func.isRequired,
  timeout: PropTypes.number,
  messages: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    })
  ).isRequired,
};

Alerts.defaultProps = {
  timeout: 6500,
  messages: [],
};

const mapStateToProps = ({ alert }) => ({
  messages: alert.messages,
});

export default connect(mapStateToProps)(Alerts);
