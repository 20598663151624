/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Financials Homefield API
 * OpenAPI spec version: v1
 */

export interface ItemPreview {
  basePrice?: number;
  styleDiscountAmount?: number | null;
  lockerDiscountAmount?: number | null;
  lockerDiscountPercent?: number | null;
  unitPrice?: number;
  quantity?: number;
  extendedPrice?: number;
  lockerItemId?: number | null;
  coloredStyleSelectionId?: number | null;
  styleId?: number;
  coloredStyleId?: number;
  coloredStyleCode?: string | null;
  collectionItemIds?: number[] | null;
}
