/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Orders Homefield API
 * OpenAPI spec version: v1
 */
import type { ColorDto } from './colorDto';
import type { ImageDto } from './imageDto';
import type { ProductDto } from './productDto';

export interface VariationDto {
  id?: number;
  uuid?: string | null;
  color?: ColorDto;
  images?: ImageDto[] | null;
  products?: ProductDto[] | null;
}
