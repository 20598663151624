import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reset } from 'redux-form';
import {
  discountTypeEnum,
  restrictionTypeValueEnum,
} from '@constants/enums/couponEnums';
import { TableQuickViewSizeEnum } from '@constants/enums/tableEnums';
import {
  organizationDetailsUrl,
  orderManagementWithFilterUrl,
} from '@constants/clientUrls/clientUrls';
import { couponForm } from '@constants/reduxForms';
import {
  fetchCoupons,
  updateCoupon,
  deleteCoupon,
  fetchCouponRestrictions,
} from '@redux/coupons/actions';
import {
  parseDateTimeNumeric,
  daysBetween,
  parseDateDashes,
  getTodaysDate,
} from '@util/dateHandler';
import { materialSwal } from '@util/componentHelper';
import { formatNumber } from '@util/numberHelpers';
import DiscountField from '@sharedComponents/Display/DiscountField';
import Button from '@sharedComponents/Buttons/Button';
import Link from '@sharedComponents/Navigation/Link';
import TableQuickViewHeader from '@sharedComponents/Table/TableQuickView/TableQuickViewHeader';
import InfoField from '@sharedComponents/Display/InfoField';
import SimpleConfirmationModal from '@sharedComponents/Modal/SimpleConfirmationModal';
import OpenNewTabLink from '@sharedComponents/Navigation/OpenNewTabLink';
import TableQuickViewContainer from '@sharedComponents/Table/TableQuickView/TableQuickViewContainer';
import CouponsEditModal from '../CouponsModals/CouponsEditModal';
import RedemptionCount from './RedemptionCount';
import Progress from '@components/shared/Display/Progress';

class CouponsDetails extends PureComponent {
  state = {
    showEditCouponModal: false,
    removeModalIsOpen: false,
  };

  componentDidMount() {
    const {
      dispatch,
      coupon,
    } = this.props;

    if (coupon?.id) {
      dispatch(fetchCouponRestrictions(coupon.id));
    }
  }

  componentDidUpdate(prevProps) {
    const {
      dispatch,
      coupon,
    } = this.props;

    if (coupon?.id && (!prevProps.coupon || prevProps.coupon.id !== coupon.id)) {
      dispatch(fetchCouponRestrictions(coupon.id));
    }
  }

  openEditCouponModal = () => {
    this.setState({ showEditCouponModal: true });
  };

  closeEditCouponModal = () => {
    const { dispatch } = this.props;
    dispatch(reset(couponForm));
    this.setState({ showEditCouponModal: false });
  };

  editCoupon = async (editCouponForm) => {
    const {
      dispatch,
      closeDetails,
      coupon,
    } = this.props;

    const res = await dispatch(updateCoupon(editCouponForm));
    if (res) {
      materialSwal('Success', res.message, 'success');
      dispatch(fetchCoupons());
      dispatch(fetchCouponRestrictions(coupon.id));
      this.closeEditCouponModal();
      closeDetails();
    }
  };

  deleteCoupon = async () => {
    const {
      coupon,
      dispatch,
      closeDetails,
    } = this.props;

    const res = await dispatch(deleteCoupon(coupon.id));
    if (res?.success) {
      materialSwal('Success', res.message, 'success');
      dispatch(fetchCoupons());
      this.closeRemoveModal();
      closeDetails();
    }
  };

  mapRestriction = (restrictions) => {
    const restrictionField = [];
    const lockerRestriction = restrictions.find((f) => f.restrictionType === restrictionTypeValueEnum.Locker);
    if (!lockerRestriction) {
      restrictionField.push({ restrictionType: restrictionTypeValueEnum.Locker });
    } else {
      restrictionField.push(lockerRestriction);
    }
    const partnerRestriction = restrictions.find((f) => f.restrictionType === restrictionTypeValueEnum.Partner);
    if (!partnerRestriction) {
      restrictionField.push({ restrictionType: restrictionTypeValueEnum.Partner });
    } else {
      restrictionField.push(partnerRestriction);
    }
    const amountRestriction = restrictions.find((f) => f.restrictionType === restrictionTypeValueEnum.Amount);
    if (!amountRestriction) {
      restrictionField.push({ restrictionType: restrictionTypeValueEnum.Amount });
    } else {
      const amountRestrictionCopy = {
        restrictionType: amountRestriction.restrictionType,
        value: formatNumber(amountRestriction.value / 100),
      };
      restrictionField.push(amountRestrictionCopy);
    }

    return restrictionField;
  };

  openRemoveModal = () => {
    this.setState({ removeModalIsOpen: true });
  };

  closeRemoveModal = () => {
    this.setState({ removeModalIsOpen: false });
  };

  render() {
    const {
      coupon,
      closeDetails,
      restrictions,
    } = this.props;

    const {
      showEditCouponModal,
      removeModalIsOpen,
    } = this.state;

    const todaysDate = getTodaysDate();
    const restrictionsMapped = this.mapRestriction(restrictions);
    const initialValues = (coupon.discountType === discountTypeEnum.DollarAmount)
      ? {
        ...coupon,
        discountAmount: formatNumber(coupon.discountAmount / 100),
      }
      : coupon;
    initialValues.restrictions = restrictionsMapped;

    return (
      <TableQuickViewContainer
        noPadding={true}
        size={TableQuickViewSizeEnum.Medium}
      >
        <CouponsEditModal
          isOpen={showEditCouponModal}
          closeModal={this.closeEditCouponModal}
          onSubmit={this.editCoupon}
          initialValues={initialValues}
        />
        <SimpleConfirmationModal
          isOpen={removeModalIsOpen}
          confirm={this.deleteCoupon}
          closeModal={this.closeRemoveModal}
          title={'Remove Coupon'}
          confirmationBody={`Are you sure you want to remove the coupon ${coupon.code}?`}
        />
        <TableQuickViewHeader
          title={(
            <>
              <span>{coupon.code}</span>
              {
                coupon.isActive
                  ? <span className='coupon__label--active ml-15'>Active</span>
                  : <span className='coupon__label--expired ml-15'>Expired</span>
              }
            </>
          )}
          onClose={closeDetails}
        />
        <div className='tableView__details--content size--700'>
          <div>
            <div className='tableView__details--content-container'>
              <InfoField
                label={'Description'}
                value={coupon.description}
              />

              <div className='flex'>
                <InfoField
                  label={'Discount'}
                  fieldClass={'tableView__details__column--short'}
                  value={(
                    <DiscountField
                      type={coupon.discountType}
                      amount={coupon.discountAmount}
                      emphasizeUnitOfMeasure={true}
                    />
                  )}
                />
                <InfoField
                  label={'Reason'}
                  value={coupon.reason}
                  fieldClass={'tableView__details__column--long'}
                />
              </div>
            </div>

            <div className='tableView__details--content-container'>
              <div className='flex'>
                <InfoField
                  label={'Valid From'}
                  value={coupon.validFrom && parseDateDashes(coupon.validFrom)}
                  fieldClass={'tableView__details__column--short'}
                />
                <InfoField
                  label={'Valid To'}
                  value={coupon.validTo && parseDateDashes(coupon.validTo)}
                  fieldClass={'tableView__details__column--short ml-20'}
                />
                <InfoField
                  label={'Time Left'}
                  fieldClass={'tableView__details__column--short ml-20'}
                  value={(
                    <>
                      <div>{(coupon.validTo && coupon.isActive) ? `${daysBetween(todaysDate, coupon.validTo)} days` : '-'}</div>
                      {
                        coupon.validTo && coupon.isActive &&
                        <div>
                          <Progress
                            variant='determinate'
                            color='secondary'
                            value={daysBetween(coupon.validFrom, todaysDate)
                              / daysBetween(coupon.validFrom, coupon.validTo) * 100
                            }
                          />
                        </div>
                      }
                    </>
                  )}
                />
              </div>
              <div className='justify__space-between'>
                <InfoField
                  label={'Redemption Count'}
                  value={(
                    <RedemptionCount
                      redemptionLimit={coupon.redemptionLimit}
                      redemptionCount={coupon.redemptionCount}
                    />
                  )}
                />
                {
                  coupon.redemptionCount > 0 &&
                  <OpenNewTabLink
                    url={orderManagementWithFilterUrl(coupon.code)}
                    text={'View Orders'}
                  />
                }
              </div>
              <InfoField
                label={'Created On'}
                value={coupon.createdAt && `${parseDateTimeNumeric(coupon.createdAt)} ${coupon.createdBy ? `(${coupon.createdBy})` : ''}`}
              />
              <InfoField
                label={'Last Updated On'}
                value={coupon.updatedAt && `${parseDateTimeNumeric(coupon.updatedAt)} ${coupon.updatedBy ? `(${coupon.updatedBy})` : ''}`}
              />
            </div>
            <div className='tableView__details--content-container'>
              <div className='tableView__details--header--title mb-15'>
                <span>Restrictions</span>
              </div>
              <div className='justify__space-between'>
                <InfoField
                  label={'Single Locker:'}
                  value={restrictionsMapped[0].value}
                  fieldClass={'tableView__details--row'}
                  isSlim={true}
                />
                <InfoField
                  label={'Redemption Limit:'}
                  value={coupon.redemptionLimit}
                  fieldClass={'tableView__details--row'}
                  isSlim={true}
                />
              </div>
              <div className='justify__space-between'>
                <InfoField
                  label={'Single Partner:'}
                  value={restrictionsMapped[1].value}
                  fieldClass={'tableView__details--row'}
                  isSlim={true}
                />
                <InfoField
                  label={'Style Number:'}
                  value={coupon.styleCode}
                  fieldClass={'tableView__details--row'}
                  isSlim={true}
                />
              </div>
              <div className='justify__space-between'>
                <InfoField
                  label={'Minimum Order Value:'}
                  value={restrictionsMapped[2].value}
                  fieldClass={'tableView__details--row'}
                  isSlim={true}
                />
                <InfoField
                  label={'Organization:'}
                  value={
                    (coupon.organizationId && coupon.organizationName) &&
                    <Link
                      url={organizationDetailsUrl(coupon.organizationId)}
                      text={coupon.organizationName}
                    />
                  }
                  fieldClass={'tableView__details--row'}
                  isSlim={true}
                />
              </div>
            </div>
            <div className='tableView__details--footer'>
              <div className='tableView__details--footer-buttons'>
                <Button
                  type={'danger'}
                  text={'Delete'}
                  onClick={this.openRemoveModal}
                  classes={'coupons__details--footer-btn--delete'}
                />
                <Button
                  type={'primary'}
                  text={'Edit Coupon Code'}
                  onClick={this.openEditCouponModal}
                  classes={'coupons__details--footer-btn--edit'}
                />
              </div>
            </div>
          </div>
        </div>
      </TableQuickViewContainer>
    );
  }
}

CouponsDetails.propTypes = {
  coupon: PropTypes.object.isRequired,
  closeDetails: PropTypes.func.isRequired,
  restrictions: PropTypes.array,
};

const mapStateToProps = ({ coupons }) => ({
  restrictions: coupons.couponRestrictions,
});

export default connect(mapStateToProps)(CouponsDetails);
