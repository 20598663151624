/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Financials Homefield API
 * OpenAPI spec version: v1
 */
import type { ServiceTypeEnum } from './serviceTypeEnum';

export interface SubmitVoucherOrderToAccountingCommand {
  id?: number;
  serviceType?: ServiceTypeEnum;
}
