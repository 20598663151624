import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import LockerStatus from '@components/LockerManagerTable/LockerManagerQuickView/LockerStatus';
import { Helmet } from 'react-helmet';

const LockerManagerDetailsHeader = React.memo(({ lockerInfo }) => {
  if (lockerInfo) {
    const headerTitle = `L${lockerInfo.id} - ${lockerInfo.storeName}`;

    return (
      <>
        <Helmet>
          <title>{headerTitle}</title>
        </Helmet>
        <div className='bar__long'>
          <div className='header__bar-content lockerManagerEdit__main-header'>
            <h2 className='header__bar-title mr-20'>{headerTitle}</h2>
            <div className='flex'>
              {
                lockerInfo.status &&
                <LockerStatus status={lockerInfo.status} />
              }
              <span className={`lockerManagerEdit__locker-option ${!lockerInfo.approved ? 'disabled' : ''}`}>
                Approved
              </span>
              <span className={`lockerManagerEdit__locker-option ${!lockerInfo.searchable ? 'disabled' : ''}`}>
                Searchable
              </span>
              <span className={`lockerManagerEdit__locker-option ${!lockerInfo.disableEmails ? 'disabled' : ''}`}>
                Disable emails
              </span>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <span className='header__bar-content' />
  );
});

LockerManagerDetailsHeader.propTypes = {
  lockerInfo: PropTypes.object.isRequired,
};

const mapStateToProps = ({ lockerManager }) => ({
  lockerInfo: lockerManager.lockerInfo,
});

export default connect(mapStateToProps)(LockerManagerDetailsHeader);
