import React, { useCallback } from 'react';
import { muiTheme } from '@constants/values';
import { Grid, Box, Button } from '@material-ui/core';
import { makeStyles, ThemeProvider } from '@material-ui/styles';
import { yupResolver } from '@hookform/resolvers/yup';
import yup from '@util/yupValidationHelper';
import { useForm } from 'react-hook-form';
import HookformInput from '@components/shared/Form/HookformInput';
import { usePostHomefieldApiStylecrosssells } from '@api/productCatalog/style-cross-sells';
import { CreateStyleCrossSellCommand } from '@api/productCatalog/models';
import { useQueryClient } from 'react-query';

interface Props {
  closeModal: () => void;
}

const useStyles = makeStyles(() => ({
  mainContainer: {
    position: 'relative',
    width: '100%',
    margin: 16,
  },
  textField: {
    minWidth: 'initial!important',
  },
}));

const schema = yup.object().shape({
  targetStyleId: yup.number().required('Required'),
});

const CreateCrossSellForm = ({ closeModal }: Props) => {
  const classes = useStyles();

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const queryClient = useQueryClient();
  const { mutateAsync: createCrossSell } = usePostHomefieldApiStylecrosssells();

  const handleCreateCrossSell = async (data: CreateStyleCrossSellCommand) => {
    try {
      await createCrossSell({ data });
      reset();
      queryClient.invalidateQueries('/homefield/api/style-cross-sells/accessories');
      closeModal();
    } catch (error) {
      console.error(error);
    }
  };

  const handleKeyDown = useCallback((event: React.KeyboardEvent<HTMLFormElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  }, []);

  return (
    <ThemeProvider theme={muiTheme}>
      <Box className={classes.mainContainer}>
        <form
          id='create-cross-sell-form'
          onKeyDown={handleKeyDown}
          onSubmit={handleSubmit(handleCreateCrossSell)}
        >
          <Grid container={true} spacing={1}>
            <Grid item={true} xs={12}>
              <HookformInput
                name='targetStyleId'
                control={control}
                placeholder={'Target Style Id'}
                error={errors.targetStyleId}
                required={true}
                type={'number'}
                label='Target Style Id'
                className={classes.textField}
                id={'target-style-id-input'}
              />
            </Grid>
            <Grid item={true} xs={12}>
              <Button type='submit' variant='contained' color='primary'>
                Create Cross Sell
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </ThemeProvider>
  );
};

export default CreateCrossSellForm;
