import React from 'react';
import LabelsLegendDisplay from '@sharedComponents/Labels/LabelsLegendDisplay';

interface OwnProps {
  item: {
    custom?: boolean;
    vendorFulfilledItem?: boolean;
    vendorId?: string;
    preferredVendor?: boolean;
    requiredItem?: boolean;
  };
}

type Props = OwnProps;

const OrderManagementDetailsItemLabels = React.memo(({ item }: Props) => (
  <LabelsLegendDisplay
    labels={[
      {
        isActive: item.custom,
        text: 'Custom',
        shortText: 'C',
        type: 'status-blue',
      },
      {
        isActive: item.vendorFulfilledItem,
        text: 'Vendor Fulfilled Item',
        shortText: 'VFI',
        type: 'lynch',
        additionalText: (
          <div>Vendor ID: {item.vendorId ?? '-'}</div>
        ),
      },
      {
        isActive: item.requiredItem,
        text: 'Required',
        shortText: 'R',
        type: 'electric-indigo',
      },
      {
        isActive: item.preferredVendor,
        text: 'Return eligible',
        shortText: 'R',
        type: 'status-green',
      },
    ]}
  />
));

export default OrderManagementDetailsItemLabels;
