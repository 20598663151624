import React from 'react';
import PropTypes from 'prop-types';
import OrderManagementDetailsItemExpandPersonalization from './OrderManagementDetailsItemExpandPersonalization';

const OrderManagementDetailsItemExpandPersonalizations = React.memo(({
  personalizations,
  colorsDictionary,
}) => {
  const pers = {};
  const groups = {};
  for (const personalization of personalizations) {
    if (!pers[personalization.colorId]) {
      pers[personalization.colorId] = [];
    }
    pers[personalization.colorId].push(personalization);
  }

  const enums = Object.keys(pers);

  for (const e of enums) {
    const enumPers = pers[e];
    groups[e] = [];
    for (let j = 0; j < enumPers.length; j += 3) {
      groups[e].push(enumPers.slice(j, j + 3));
    }
  }
  const items = {};

  for (const e of enums) {
    items[e] = groups[e].map((item, key) => (
      <OrderManagementDetailsItemExpandPersonalization
        key={key}
        personalizations={item}
        colorsDictionary={colorsDictionary}
      />
    ));
  }
  const components = {};

  for (let i = 0; i < enums.length; i += 1) {
    components[enums[i]] = (
      <div key={i}>
        <ul>
          {items[enums[i]]}
        </ul>
      </div>
    );
  }

  return (
    <div>
      {Object.values(components)}
    </div>
  );
});

OrderManagementDetailsItemExpandPersonalizations.propTypes = {
  personalizations: PropTypes.array,
  colorsDictionary: PropTypes.objectOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    dtgCode: PropTypes.string,
    cmykValue: PropTypes.string.isRequired,
    dtgCmykValue: PropTypes.string.isRequired,
    hexValue: PropTypes.string,
    threadValue: PropTypes.string,
    brightness: PropTypes.string.isRequired,
  })).isRequired,
};

export default OrderManagementDetailsItemExpandPersonalizations;
