/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Orders Homefield API
 * OpenAPI spec version: v1
 */

export interface ImageDto {
  src?: string | null;
  isPrimary?: boolean;
  description?: string | null;
}
