import React from 'react';
import Icon from '@sharedComponents/Icons/Icon';

type Props = {
  title: string;
  isActiveTab: boolean;
  selectTab: () => void;
  iconChecked: boolean;
};

const AssignLogosToItemsTabHeader = React.memo(({
  title,
  isActiveTab,
  selectTab,
  iconChecked,
}: Props) => (
  <div
    className={`tab-item ${isActiveTab ? 'is-active' : ''}`}
    onClick={selectTab}
  >
    {iconChecked
      ? (
        <Icon
          materialIcon={'check_circle'}
          classes={'logo-bank__assignment--check'}
        />
      )
      : (
        <Icon
          materialIcon={'panorama_fish_eye'}
          classes={'logo-bank__assignment--circle'}
        />
      )}
    <span>{title}</span>
  </div>
));

export default AssignLogosToItemsTabHeader;
