import Axios, {
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';
import { stringify } from 'qs';
import {
  beginRequest,
  endRequest,
  beginBlockingRequest,
  endBlockingRequest,
} from '@redux/api/actions';
import {
  handleApiError,
  handleApiSubmissionError,
} from '@util/apiHelper';
import store from '../../store';

interface AxiosInstanceAdditionalOptions {
  isBlockingRequest?: boolean;
  isFormSubmission?: boolean;
  showErrorModal?: boolean;
}

const nonBlockingProvider = {
  onBegin: () => {
    store.dispatch(beginRequest());
  },
  onResolve: () => {
    store.dispatch(endRequest());
  },
  onFail: () => {
    store.dispatch(endRequest());
  },
};

const blockingProvider = {
  onBegin: () => {
    store.dispatch(beginBlockingRequest());
  },
  onResolve: () => {
    store.dispatch(endBlockingRequest());
  },
  onFail: () => {
    store.dispatch(endBlockingRequest());
  },
};

const getToken = () => {
  const oidcUser = store.getState().oidc.user;
  if (oidcUser && oidcUser.access_token) {
    return oidcUser.access_token;
  }

  return '';
};

const appendAuthorizationHeader = (config: any) => {
  const token = getToken();
  config.headers.Authorization = `Bearer ${token}`;

  return config;
};


const commonInstanceOptions = {
  headers: {
    'Content-Type': 'application/json',
    Accept: '*/*'
  },
  paramsSerializer: (params: any) => stringify(params, { arrayFormat: 'repeat' }),
};

const nonBlockingMethods = ['get', 'GET', 'head', 'HEAD', 'options', 'OPTIONS'];
const blockingMethods = ['delete', 'DELETE', 'post', 'POST', 'put', 'PUT', 'patch', 'PATCH'];

export const fulfillmentApiAxiosInstance = Axios.create({
  ...commonInstanceOptions,
  baseURL: process.env.PUBLIC_API_ROOT,
});

export const ordersApiAxiosInstance = Axios.create({
  ...commonInstanceOptions,
  baseURL: process.env.PUBLIC_ORDERS_API,
});

export const accountsApiAxiosInstance = Axios.create({
  ...commonInstanceOptions,
  baseURL: process.env.PUBLIC_ACCOUNTS_API,
});

export const emailApiAxiosInstance = Axios.create({
  ...commonInstanceOptions,
  baseURL: process.env.PUBLIC_EMAIL_SERVICES,
});

export const financialApiAxiosInstance = Axios.create({
  ...commonInstanceOptions,
  baseURL: process.env.PUBLIC_FINANCIAL_SERVICES,
});

export const catalogApiApiAxiosInstance = Axios.create({
  ...commonInstanceOptions,
  baseURL: process.env.PUBLIC_PRODUCT_CATALOG_API,
});

export const slServicesApiAxiosInstance = Axios.create({
  ...commonInstanceOptions,
  baseURL: process.env.PUBLIC_SQUADLOCKER_SERVICES_API,
});

export const identityApiAxiosInstance = Axios.create({
  ...commonInstanceOptions,
  baseURL: process.env.PUBLIC_IDENTITY_ROOT,
});

export const reportEngineApiAxiosInstance = Axios.create({
  ...commonInstanceOptions,
  baseURL: process.env.PUBLIC_REPORT_ENGINE_API,
});

const getProvider = (config: AxiosRequestConfig & AxiosInstanceAdditionalOptions) => {
  if (config.isBlockingRequest === false) {
    return nonBlockingProvider;
  }

  if (config.isBlockingRequest === true) {
    return blockingProvider;
  }

  const nonBlocking: boolean = config.isBlockingRequest === false || nonBlockingMethods.includes(config.method as string);
  return nonBlocking ? nonBlockingProvider : blockingProvider;
}

const setShowErrorModal = (config: AxiosRequestConfig & AxiosInstanceAdditionalOptions) => {
  if (config.showErrorModal !== undefined) {
    return config;
  }

  const blocking: boolean = blockingMethods.includes(config.method as string);

  return ({
    ...config,
    showErrorModal: blocking,
  });
}

const requestInterceptorOnFulfilled = (config: AxiosRequestConfig & AxiosInstanceAdditionalOptions) => {
  // Do something before request is sent
  const provider = getProvider(config);
  provider.onBegin();

  let updatedConfig = appendAuthorizationHeader(config);
  updatedConfig = setShowErrorModal(updatedConfig);

  return updatedConfig;
};

const requestInterceptorOnRejected = (error: any) => {
  // Do something with request error
  const provider = getProvider(error.response.config);

  provider.onFail();
  return Promise.reject(error);
};

const responseInterceptorOnFulfilled = (response: AxiosResponse<any>) => {
  // Do something with response data
  const provider = getProvider(response.config);

  provider.onResolve();
  return response;
};

const responseInterceptorOnRejected = (error: any) => {
  const config = error?.response?.config as (AxiosRequestConfig & AxiosInstanceAdditionalOptions | undefined);

  // react-query cancels requests with a message property in the error
  // assume non-blocking provider for this scenario
  if (!config) {
    const message = (config as any)?.message ?? 'UNKNOWN ERROR';
    nonBlockingProvider.onFail();
    return Promise.reject(message);
  }

  // Do something with response error
  const provider = getProvider(config);

  if (config.isFormSubmission) {
    handleApiSubmissionError(error);
  } else if (config.showErrorModal) {
    handleApiError(error);
  }

  provider.onFail();
  return Promise.reject(error.response.data);
};

ordersApiAxiosInstance.interceptors.request.use(requestInterceptorOnFulfilled, requestInterceptorOnRejected);
ordersApiAxiosInstance.interceptors.response.use(responseInterceptorOnFulfilled, responseInterceptorOnRejected);

accountsApiAxiosInstance.interceptors.request.use(requestInterceptorOnFulfilled, requestInterceptorOnRejected);
accountsApiAxiosInstance.interceptors.response.use(responseInterceptorOnFulfilled, responseInterceptorOnRejected);

emailApiAxiosInstance.interceptors.request.use(requestInterceptorOnFulfilled, requestInterceptorOnRejected);
emailApiAxiosInstance.interceptors.response.use(responseInterceptorOnFulfilled, responseInterceptorOnRejected);

fulfillmentApiAxiosInstance.interceptors.request.use(requestInterceptorOnFulfilled, requestInterceptorOnRejected);
fulfillmentApiAxiosInstance.interceptors.response.use(responseInterceptorOnFulfilled, responseInterceptorOnRejected);

financialApiAxiosInstance.interceptors.request.use(requestInterceptorOnFulfilled, requestInterceptorOnRejected);
financialApiAxiosInstance.interceptors.response.use(responseInterceptorOnFulfilled, responseInterceptorOnRejected);

catalogApiApiAxiosInstance.interceptors.request.use(requestInterceptorOnFulfilled, requestInterceptorOnRejected);
catalogApiApiAxiosInstance.interceptors.response.use(responseInterceptorOnFulfilled, responseInterceptorOnRejected);

slServicesApiAxiosInstance.interceptors.request.use(requestInterceptorOnFulfilled, requestInterceptorOnRejected);
slServicesApiAxiosInstance.interceptors.response.use(responseInterceptorOnFulfilled, responseInterceptorOnRejected);

identityApiAxiosInstance.interceptors.request.use(requestInterceptorOnFulfilled, requestInterceptorOnRejected);
identityApiAxiosInstance.interceptors.response.use(responseInterceptorOnFulfilled, responseInterceptorOnRejected);

reportEngineApiAxiosInstance.interceptors.request.use(requestInterceptorOnFulfilled, requestInterceptorOnRejected);
reportEngineApiAxiosInstance.interceptors.response.use(responseInterceptorOnFulfilled, responseInterceptorOnRejected);

export const fulfillmentApi = <T>(config: AxiosRequestConfig, additionalOptions: AxiosInstanceAdditionalOptions): Promise<T> => {
  const source = Axios.CancelToken.source();
  const promise = fulfillmentApiAxiosInstance({ ...config, cancelToken: source.token, ...additionalOptions }).then(
    ({ data }) => data,
  );

  // @ts-ignore
  promise.cancel = () => {
    source.cancel('Query was cancelled by React Query');
  };

  return promise;
};

export const ordersApi = <T>(config: AxiosRequestConfig, additionalOptions: AxiosInstanceAdditionalOptions): Promise<T> => {
  const source = Axios.CancelToken.source();
  const promise = ordersApiAxiosInstance({ ...config, cancelToken: source.token, ...additionalOptions }).then(
    ({ data }) => data,
  );

  // @ts-ignore
  promise.cancel = () => {
    source.cancel('Query was cancelled by React Query');
  };

  return promise;
};

export const accountsApi = <T>(config: AxiosRequestConfig, additionalOptions: AxiosInstanceAdditionalOptions): Promise<T> => {
  const source = Axios.CancelToken.source();
  const promise = accountsApiAxiosInstance({ ...config, cancelToken: source.token, ...additionalOptions }).then(
    ({ data }) => data,
  );

  // @ts-ignore
  promise.cancel = () => {
    source.cancel('Query was cancelled by React Query');
  };

  return promise;
};

export const accountsApiv1 = <T>(config: AxiosRequestConfig, additionalOptions: AxiosInstanceAdditionalOptions): Promise<T> => {
  const source = Axios.CancelToken.source();
  const promise = accountsApiAxiosInstance({ ...config, cancelToken: source.token, ...additionalOptions }).then(
    ({ data }) => data,
  );

  // @ts-ignore
  promise.cancel = () => {
    source.cancel('Query was cancelled by React Query');
  };

  return promise;
};

export const emailApi = <T>(config: AxiosRequestConfig, additionalOptions: AxiosInstanceAdditionalOptions): Promise<T> => {
  const source = Axios.CancelToken.source();
  const promise = emailApiAxiosInstance({ ...config, cancelToken: source.token, ...additionalOptions }).then(
    ({ data }) => data,
  );

  // @ts-ignore
  promise.cancel = () => {
    source.cancel('Query was cancelled by React Query');
  };

  return promise;
};

export const financialApi = <T>(config: AxiosRequestConfig, additionalOptions: AxiosInstanceAdditionalOptions): Promise<T> => {
  const source = Axios.CancelToken.source();
  const promise = financialApiAxiosInstance({ ...config, cancelToken: source.token, ...additionalOptions }).then(
    ({ data }) => data,
  );

  // @ts-ignore
  promise.cancel = () => {
    source.cancel('Query was cancelled by React Query');
  };

  return promise;
};

export const catalogApi = <T>(config: AxiosRequestConfig, additionalOptions: AxiosInstanceAdditionalOptions): Promise<T> => {
  const source = Axios.CancelToken.source();
  const promise = catalogApiApiAxiosInstance({ ...config, cancelToken: source.token, ...additionalOptions }).then(
    ({ data }) => data,
  );

  // @ts-ignore
  promise.cancel = () => {
    source.cancel('Query was cancelled by React Query');
  };

  return promise;
};

export const slServicesApi = <T>(config: AxiosRequestConfig, additionalOptions: AxiosInstanceAdditionalOptions): Promise<T> => {
  const source = Axios.CancelToken.source();
  const promise = slServicesApiAxiosInstance({ ...config, cancelToken: source.token, ...additionalOptions }).then(
    ({ data }) => data,
  );

  // @ts-ignore
  promise.cancel = () => {
    source.cancel('Query was cancelled by React Query');
  };

  return promise;
};

export const identityApi = <T>(config: AxiosRequestConfig, additionalOptions: AxiosInstanceAdditionalOptions): Promise<T> => {
  const source = Axios.CancelToken.source();
  const promise = identityApiAxiosInstance({ ...config, cancelToken: source.token, ...additionalOptions }).then(
    ({ data }) => data,
  );

  // @ts-ignore
  promise.cancel = () => {
    source.cancel('Query was cancelled by React Query');
  };

  return promise;
};

export const reportEngineApi = <T>(config: AxiosRequestConfig, additionalOptions: AxiosInstanceAdditionalOptions): Promise<T> => {
  const source = Axios.CancelToken.source();
  const promise = reportEngineApiAxiosInstance({ ...config, cancelToken: source.token, ...additionalOptions }).then(
    ({ data }) => data,
  );

  // @ts-ignore
  promise.cancel = () => {
    source.cancel('Query was cancelled by React Query');
  };

  return promise;
};

export default {
  fulfillmentApi,
  financialApi,
  catalogApi,
  slServicesApi,
  identityApi,
  ordersApi,
  accountsApi,
  reportEngineApi,
};
