/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */
import type { VendorOrderStatusEnum } from './vendorOrderStatusEnum';
import type { SubmitStatusEnum } from './submitStatusEnum';

export interface VendorOrderDto {
  id?: number;
  vendorId?: string | null;
  vendorOrderId?: string | null;
  vendorStatus?: string | null;
  created?: string | null;
  updated?: string | null;
  canceled?: string | null;
  shipDate?: string | null;
  status?: VendorOrderStatusEnum;
  submitStatus?: SubmitStatusEnum;
  itemsCount?: number;
  ordersCount?: number;
}
