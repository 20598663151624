/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */
import type { PrintQueueFilterEnum } from './printQueueFilterEnum';

export interface UpdateSheetStatusCommand {
  printQueueId?: number;
  status?: PrintQueueFilterEnum;
}
