import React, { useCallback } from 'react';
import { lockerMgrS3Logos } from '@constants/common';
import ItemSelectionIcon from '@sharedComponents/Icons/ItemSelectionIcon';

interface AssignLogoCardProps {
  logo: any;
  toggleSelectLogo: (logo: any, isSelected: boolean) => void;
  isSelected: boolean;
  isDisabled: boolean;
}

const AssignLogoCard: React.FC<AssignLogoCardProps> = React.memo(({
  logo,
  toggleSelectLogo,
  isSelected,
  isDisabled = false,
}) => {
  const selectOnClick = useCallback(() => {
    if (!isDisabled) {
      toggleSelectLogo(logo, isSelected);
    }
  }, [toggleSelectLogo, logo, isSelected, isDisabled]);

  return (
    <div
      onClick={selectOnClick}
      className={`add-product__available--container ${isSelected && 'isSelected'} logo-bank__assignment--logo-card ${isDisabled ? 'disabled' : ''}`}
    >
      <div className='add-product__available--product'>
        <div
          className='add-product__available--image'
          style={{ backgroundImage: `url("${lockerMgrS3Logos}/${logo.image}")` }}
        />
        <ItemSelectionIcon
          isSelected={isSelected}
          isDisabled={isDisabled}
        />
      </div>
      <span className='add-product__available--title logo-bank__assignment--logo-card--title'>
        {logo.image}
      </span>
    </div>
  );
});

export default AssignLogoCard;
