/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */
import type { SupportQueriesSortByEnum } from './supportQueriesSortByEnum';
import type { SortDirectionEnum } from './sortDirectionEnum';

export type GetHomefieldApiSupportQualityassurancecodesParams = { search?: string; sortBy?: SupportQueriesSortByEnum; sortDirection?: SortDirectionEnum };
