/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */
import type { FlagDto } from './flagDto';

export interface QueriesPersonalizationItemDto {
  id?: number;
  decorationLocation?: string | null;
  decorationWidth?: number | null;
  decorationHeight?: number | null;
  status?: string | null;
  barcode?: string | null;
  flag?: FlagDto;
  files?: string[] | null;
  text?: string | null;
  number?: string | null;
  colorId?: number | null;
  outlineColorId?: number | null;
}
