/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */
import type { OrderItemModel } from './orderItemModel';

export interface UpdateOrderItemPriceCalculationDto {
  orderItems?: OrderItemModel[] | null;
  updatedOrderItems?: OrderItemModel[] | null;
  pretaxAmountToChange?: number;
  taxAmountToChange?: number;
  canUpdate?: boolean;
}
