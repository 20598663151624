import {
  Logo as LogoDto,
  QueriesVariationDto as VariationDto,
} from '@api/orders/models';
import { VariationProductModel } from '@models/Products/VariationProductModel';
import { decorationMethodEnum } from '@constants/enums/decorationEnums';
import { toCents } from './numberHelpers';
import { LockerProductsCssLogoViewModel } from '@api/squadlockerServices/models';

export const variationsToLockerProducts =
(variations: Array<VariationDto>):
  Array<VariationProductModel> => {
  const lockerProducts: Array<VariationProductModel> = variations?.map((variation) => {
    const lockerProduct: VariationProductModel = {
      id: 0,
      uuid: '',
      cached_image: '',
      code: '',
      name: '',
      color: '',
      decoration_method: '',
      decoration_location: '',
      age: '',
      location_dimensions: '',
      times_ordered: 0,
      logos: [],
      essential: false,
      price: 0,
      collections_list: [],
      locker_item_decorations: [],
      is_custom_item: false,
      is_locker_item: false,
      personalization_locations: [],
      colored_style_id: 0,
      colored_style_code: '',
      style_id: 0,
      personalizationPrices: {},
      updated_at: '',
      brand_name: '',
      category_name: '',
      flood_color: '',
      variationId: 0,
      pricing: undefined,
      locker_item_print_files: [],
    };
    lockerProduct.variationId = variation.id!;
    lockerProduct.cached_image = variation.images?.[0]
      ? variation.images?.[0].src ?? ''
      : '';
    lockerProduct.colored_style_id = variation.coloredStyleId!;
    lockerProduct.color = variation.color?.name ?? '';
    lockerProduct.decoration_method = variation.item?.personalizations?.[0]
      ? variation.item?.personalizations?.[0].decorationMethod ?? decorationMethodEnum.NA
      : decorationMethodEnum.NA;
    lockerProduct.decoration_location = variation.item?.logos?.[0]
      ? variation.item?.logos?.[0].decorationLocation ?? ''
      : '';
    lockerProduct.id = variation.itemId!;
    lockerProduct.location_dimensions = '';
    lockerProduct.logos = new Array<LockerProductsCssLogoViewModel>();
    lockerProduct.logos = ((variation.item?.logos ?? []).map(
      (itemLogo: LogoDto) => {
        const newLogo: LockerProductsCssLogoViewModel = {
          id: itemLogo.logoId!,
          image: itemLogo.logoUrl?.split('/')[itemLogo.logoUrl.split('/').length - 2] + '/' + itemLogo.logoUrl?.split('/')[itemLogo.logoUrl.split('/').length - 1],
          artwork_approved: true,
          cached_image: '',
          uuid: '',
        };

        return newLogo;
      }
    ));
    lockerProduct.name = variation.item?.name ?? '';
    lockerProduct.price = toCents(variation.item?.price)!;
    lockerProduct.pricing = variation.item?.pricing;

    return lockerProduct;
  });

  return lockerProducts;
};
