/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */
import type { DecorationSizeEnum } from './decorationSizeEnum';

export interface PrintQueueDetailsLogoItem {
  prepressJobItemId?: number[] | null;
  previewUrl?: string | null;
  quantity?: number;
  decorationLocation?: string | null;
  barcodes?: string[] | null;
  isCanceled?: boolean;
  artworkTaskId?: number | null;
  floodColorId?: number | null;
  cutFileColor?: string | null;
  decorationSize?: DecorationSizeEnum;
  decorationHeight?: number | null;
  decorationWidth?: number | null;
}
