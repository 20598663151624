import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  editLockerData,
  editLockerProductionNote,
  inviteUserByEmail,
  uploadLogoToAdminLogos,
  addLogoToLockerLogos,
} from '@APICalls/lockerManager/actions';
import {
  fetchLockerInfo,
  getLockerChangeLogs,
  fetchPartners,
  fetchLockerManagers,
  fetchLockerLogos,
  fetchFulfillmentLockerInfo,
} from '@redux/lockerManager/actions';
import { fetchLockerAdmins } from '@redux/admins/actions';
import { fetchOrganizationSuggestions } from '@redux/organizations/actions';
import {
  lockerMgrS3Logos,
  emailRegex,
  markupValues,
} from '@constants/common';
import { dateRangeEnum } from '@constants/enums/dateRangeEnum';
import PermissionsEnum from '@constants/enums/permissionsEnum';
import { dragAndDropMessage } from '@constants/values';
import { getTeamGendersOptions } from '@constants/options/options';
import { materialSwal } from '@util/componentHelper';
import {
  generalOptions,
  createOptionsList,
} from '@util/optionsMap';
import LockerManagerLogo from './LockerManagerLogo';
import CheckButton from '@sharedComponents/Inputs/CheckButton';
import UploadLogoSwatchColors from '@sharedComponents/UploadLogoSwatchColors/UploadLogoSwatchColors';
import Modal from '@sharedComponents/Modal/Modal';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import Button from '@sharedComponents/Buttons/Button';
import Icon from '@sharedComponents/Icons/Icon';
import AutocompleteInput from '@sharedComponents/Inputs/AutocompleteInput';
import DropZoneWrapper from '@sharedComponents/Upload/DropZoneWrapper';
import PermissionHandler from '@sharedComponents/Authorization/PermissionHandler';
import Dropdown from '@sharedComponents/Inputs/Dropdowns/Dropdown';
import {
  onLockerEditSuccess,
  onLockerEditError,
} from '@util/componentHelpers/lockerManagerHelper';
import { LockerTypeEnum } from '@constants/enums/lockerManagerEnums';
import enumHelper from '@constants/enums/enumHelper';

const teamGendersOptions = generalOptions(getTeamGendersOptions(true, 'Choose Gender'));

const markupDropdown = createOptionsList({
  list: markupValues,
  key: 'key',
  value: 'value',
  name: 'value',
});

const lockerTypeOptions = createOptionsList({
  list: enumHelper.getSelectOptionsList(LockerTypeEnum),
  key: 'value',
  value: 'value',
  name: 'label',
});

class LockerManagerEditModal extends Component {
  constructor(props) {
    super(props);
    const { locker } = props;

    this.state = {
      teamName: locker.storeName,
      teamUid: locker.teamUID,
      organization: locker.organizationId
        ? {
          id: locker.organizationId,
          name: locker.organizationName,
        }
        : null,
      partnerId: locker.partnerId,
      programLink: locker.programLink,
      programName: locker.programName,
      logoId: locker.logoId,
      genders: locker.genders,
      playerCount: locker.playerCount,
      ages: locker.ages,
      approved: locker.approved,
      disableEmails: locker.disableEmails,
      searchable: locker.searchable,
      sport: locker.sportId,
      note: locker.note,
      discount: locker.discount,
      changeLogoIsActive: false,
      uploadLogoIsActive: false,
      message: {
        type: 'default',
        body: dragAndDropMessage(),
      },
      loading: false,
      attachedFile: null,
      toSelectId: null,
      selectedLogo: null,
      uploadLogoResponse: null,
      swatchModalIsOpen: false,
      fundraisingMarkup: locker.markup || 0,
      productionNote: locker.productionNote,
      type: locker.type,
    };
  }

  componentDidMount() {
    const { locker } = this.props;

    if (locker.id) {
      this.refresh();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      lockerLogos,
      locker,
    } = this.props;

    const {
      logoId,
      toSelectId,
    } = this.state;

    if (toSelectId && logoId !== toSelectId) {
      const foundLogo = lockerLogos.find((logo) => logo.id === toSelectId);
      if (foundLogo) {
        this.setState({
          logoId: toSelectId,
          selectedLogo: foundLogo,
        });
      }
    }

    if (locker.id && locker.id !== prevProps.locker.id) {
      this.refresh();
      this.initializeState(locker);
    }
  }

  handleCloseModal = () => {
    const {
      closeModal,
      locker,
    } = this.props;

    this.initializeState(locker);
    closeModal();
  };

  initializeState = (locker) => {
    this.setState({
      teamName: locker.storeName,
      teamUid: locker.teamUID,
      organization: locker.organizationId
        ? {
          id: locker.organizationId,
          name: locker.organizationName,
        }
        : null,
      partnerId: locker.partnerId,
      programLink: locker.programLink,
      programName: locker.programName,
      logoId: locker.logoId,
      genders: locker.genders,
      playerCount: locker.playerCount,
      ages: locker.ages,
      approved: locker.approved,
      disableEmails: locker.disableEmails,
      searchable: locker.searchable,
      sport: locker.sportId,
      note: locker.note,
      discount: locker.discount,
      changeLogoIsActive: false,
      uploadLogoIsActive: false,
      message: {
        type: 'default',
        body: dragAndDropMessage(),
      },
      loading: false,
      attachedFile: null,
      toSelectId: null,
      selectedLogo: null,
      uploadLogoResponse: null,
      swatchModalIsOpen: false,
      fundraisingMarkup: locker.markup || 0,
      productionNote: locker.productionNote,
      type: locker.type,
    });
  };

  closeSwatchModal = () => {
    this.setState({
      swatchModalIsOpen: false,
      uploadLogoResponse: null,
    });
  };

  changeLogo = (logo) => {
    this.setState({
      logoId: logo.id,
      selectedLogo: logo,
      toSelectId: null,
    });
  };

  update = async () => {
    const {
      locker,
      closeModal,
      dispatch,
    } = this.props;

    const {
      logoId,
      partnerId,
      organization,
      teamUid,
      teamName,
      programLink,
      programName,
      approved,
      disableEmails,
      searchable,
      genders,
      playerCount,
      ages,
      sport,
      note,
      discount,
      fundraisingMarkup,
      productionNote,
      type,
    } = this.state;

    const resEditLockerData = await editLockerData(
      locker.id,
      logoId,
      partnerId,
      teamUid,
      teamName,
      organization ? organization.id : null,
      programLink,
      programName,
      approved,
      disableEmails,
      searchable,
      genders,
      playerCount,
      ages,
      sport,
      note,
      discount === undefined || discount === '' ? 0 : discount,
      fundraisingMarkup,
      type
    );

    if (resEditLockerData?.success) {
      if (locker.totalOrders > 0) {
        const resEditProductionNote = await editLockerProductionNote(locker.id, productionNote);
        if (resEditProductionNote?.success) {
          dispatch(fetchFulfillmentLockerInfo(locker.id));
        } else {
          onLockerEditError(resEditProductionNote);

          return;
        }
      }
      onLockerEditSuccess(resEditLockerData);
      dispatch(fetchLockerInfo(locker.id));
      dispatch(getLockerChangeLogs(locker.id));
      await addLogoToLockerLogos(locker.id, logoId, true);
      closeModal();
      this.refresh();
    } else {
      onLockerEditError(resEditLockerData);
    }
  };

  uploadLogo = async () => {
    const {
      locker,
      dispatch,
    } = this.props;

    const { attachedFile } = this.state;

    const response = await uploadLogoToAdminLogos(locker.id, attachedFile);

    if (response.success) {
      materialSwal('Success', 'Logo was uploaded', 'success');
      this.clearFileFromState();

      await addLogoToLockerLogos(locker.id, response.result);

      this.setState((prevState) => ({
        toSelectId: response.result ? response.result : prevState.logoId,
        swatchModalIsOpen: true,
        uploadLogoResponse: response,
      }), () => {
        dispatch(fetchLockerLogos(locker.id));
        this.changeContainerOnClick();
      });
    }
  };

  inviteUser = async (email) => {
    const { locker } = this.props;

    const response = await inviteUserByEmail(locker.id, email);
    if (response.success) {
      materialSwal('Success', 'Locker Manager invited', 'success');
      this.refresh();
    }
  };

  inviteUserOnClick = async () => {
    if (!this.inviteUserValid) {
      materialSwal('Invalid email', 'Please provide a valid email', 'warning');
    } else {
      await this.inviteUser(this.email.value);
    }
  };

  inviteUserValid = () => {
    if (!this.email || !this.email.value) {
      return false;
    }

    const email = this.email.value;

    return emailRegex.test(String(email).toLowerCase());
  };

  handleInputChange = (e) => {
    const { target } = e;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;

    if (name === 'partnerId') {
      value = +value;
    }

    this.setState({ [name]: value });
  };

  uploadContainerOnClick = () => {
    this.setState({
      changeLogoIsActive: false,
      uploadLogoIsActive: true,
    });
  };

  changeContainerOnClick = () => {
    this.setState({
      changeLogoIsActive: true,
      uploadLogoIsActive: false,
    });
  };

  onDrop = (acceptedFiles, rejectedFiles) => {
    const destroyFiles = () => {
      for (const file of acceptedFiles.concat(rejectedFiles)) {
        window.URL.revokeObjectURL(file.preview);
      }
    };

    this.setState({
      message: {
        body: `Upload ${acceptedFiles[0].name}?`,
        type: 'default',
      },
      attachedFile: acceptedFiles,
    }, destroyFiles);
  };

  clearFileFromState = () => {
    this.setState({
      attachedFile: null,
      message: {
        type: 'default',
        body: dragAndDropMessage(),
      },
    });
  };

  formatLink = () => {
    const { programLink } = this.state;
    if (programLink) {
      return programLink.substring(0, 7) === 'http://' || programLink.substring(0, 8) === 'https://' ? programLink : '//' + programLink;
    }
  };

  refresh = () => {
    const {
      locker,
      dispatch,
    } = this.props;

    dispatch(fetchLockerLogos(locker.id));
    dispatch(fetchLockerManagers(locker.id));
    dispatch(fetchPartners());
    dispatch(fetchLockerAdmins(locker.id, 1, 0, '', '', dateRangeEnum.all.value, '', '', locker.id));
  };

  toggleApproved = () => {
    this.setState({ approved: !this.state.approved });
  };

  toggleDisableEmails = () => {
    this.setState({ disableEmails: !this.state.disableEmails });
  };

  toggleSearchable = () => {
    this.setState({ searchable: !this.state.searchable });
  };

  displayOrganization = (organization) => (`ORG${organization.id} - ${organization.name}`);

  selectOrganization = (organization) => {
    this.setState({ organization });
  };

  fetchOrganizationSuggestions = (searchInput) => {
    const { dispatch } = this.props;
    dispatch(fetchOrganizationSuggestions(searchInput));
  };

  render() {
    const {
      isOpen,
      locker,
      partners,
      lockerLogos,
      sports,
      organizations,
    } = this.props;

    const {
      selectedLogo,
      teamName,
      organization,
      programName,
      sport,
      teamUid,
      partnerId,
      genders,
      playerCount,
      ages,
      discount,
      programLink,
      note,
      changeLogoIsActive,
      uploadLogoIsActive,
      logoId,
      toSelectId,
      uploadLogoResponse,
      swatchModalIsOpen,
      approved,
      disableEmails,
      searchable,
      message,
      loading,
      fundraisingMarkup,
      productionNote,
      type,
    } = this.state;

    const partnerOptions = createOptionsList({
      list: partners,
      key: 'id',
      value: 'id',
      name: 'name',
      emptyOption: {
        name: 'No partner',
      },
    });

    const sportOptions = createOptionsList({
      list: sports,
      key: 'id',
      value: 'id',
      name: 'name',
      emptyOption: {
        name: 'Choose Sport',
      },
    });

    const dropzoneContent = loading
      ? (
        <label className='button'>
          <Icon
            fontAwesomeIcon={'spinner'}
            classes={'fa-spin'}
          />
          Uploading...
        </label>
      )
      : <label className={`button message-${message.type}`}>{message.body}</label>;

    const compareId = toSelectId ? toSelectId : logoId;
    const logoUrl = selectedLogo ? `url("${lockerMgrS3Logos}/${selectedLogo.image}")` : `url("${locker.logoUrl}")`;

    const logos = lockerLogos.map((logo, index) => (
      <LockerManagerLogo
        logo={logo}
        onClick={this.changeLogo}
        key={index}
        isSelected={logo.id === compareId}
      />
    ));

    return (
      <Modal
        title={`Edit Locker L${locker.id} Information`}
        modalSize={'l'}
        isOpen={isOpen}
        closeModal={this.handleCloseModal}
        buttons={(
          <ModalButtons
            dataTest='locker-edit'
            confirmBtnText={'Update'}
            cancelBtnText={'Cancel'}
            onConfirm={this.update}
            onClose={this.handleCloseModal}
          />
        )}
      >
        {
          uploadLogoResponse?.success &&
          <UploadLogoSwatchColors
            logoId={uploadLogoResponse.result}
            cancel={this.closeSwatchModal}
            isModalOpen={swatchModalIsOpen}
          />
        }
        <div className='lockerEdit'>
          <div className='lockerEdit__container'>
            <div className='lockerEdit__information'>
              <div className='lockerEdit__information-input'>
                <div className='lockerEdit__information-title uppercase'>
                  Locker Information
                </div>
                <div className='lockerEdit__information-fields'>
                  <div className='lockerEdit__information-fields--column'>
                    <p>Team Name</p>
                    <div className='text-field'>
                      <input
                        data-test='locker-edit-team-name'
                        className='w-100'
                        onChange={this.handleInputChange}
                        name='teamName'
                        value={teamName || ''}
                        type='text'
                        placeholder='Enter Team Name'
                      />
                    </div>
                    <p>Organization Name</p>
                    <div className='select-field'>
                      <AutocompleteInput
                        suggestions={organizations}
                        fetchSuggestions={this.fetchOrganizationSuggestions}
                        displayItem={this.displayOrganization}
                        displaySuggestionText={this.displayOrganization}
                        selectedItem={organization}
                        selectItem={this.selectOrganization}
                        placeholder={'Enter organization'}
                      />
                    </div>
                  </div>
                  <div className='lockerEdit__information-fields--column m-l-24'>
                    <p>Sport</p>
                    <Dropdown
                      options={sportOptions}
                      selectClasses={'w-100'}
                      name={'sport'}
                      onChange={this.handleInputChange}
                      defaultValue={sport || ''}
                    />
                    <div className='lockerEdit__information-fields'>
                      <div className='lockerEdit__information-fields--column redux-form__column--size-m mr-10'>
                        <p>Team UID</p>
                        <div className='text-field'>
                          <input
                            className='w-100 lockerEdit__text-input'
                            onChange={this.handleInputChange}
                            name='teamUid'
                            value={teamUid || ''}
                            type='text'
                            placeholder='Enter Team UID'
                          />
                        </div>
                      </div>

                      <PermissionHandler permissions={PermissionsEnum.LockerManagerEditPartner}>
                        <div className='lockerEdit__information-fields--column redux-form__column--size-m'>
                          <p>Partner</p>
                          <Dropdown
                            options={partnerOptions}
                            selectClasses={'w-100 lockerEdit__text-input'}
                            name={'partnerId'}
                            onChange={this.handleInputChange}
                            defaultValue={partnerId || ''}
                          />
                        </div>
                      </PermissionHandler>

                    </div>
                  </div>
                </div>
                <div className='redux-form__row'>
                  <div className='lockerEdit__information-fields--column redux-form__column--size-s'>
                    <p>Gender</p>
                    <Dropdown
                      options={teamGendersOptions}
                      selectClasses={'w-100'}
                      name={'genders'}
                      onChange={this.handleInputChange}
                      defaultValue={genders || ''}
                    />
                  </div>
                  <div className='lockerEdit__information-fields--column redux-form__column--size-s'>
                    <p>Players</p>
                    <div className='text-field'>
                      <input
                        className='w-100 lockerEdit__text-input'
                        onChange={this.handleInputChange}
                        name='playerCount'
                        value={playerCount || ''}
                        type='number'
                        placeholder='Enter Player Count'
                      />
                    </div>
                  </div>
                  <div className='lockerEdit__information-fields--column redux-form__column--size-s'>
                    <p>Ages</p>
                    <div className='text-field'>
                      <input
                        className='w-100 lockerEdit__text-input'
                        onChange={this.handleInputChange}
                        name='ages'
                        value={ages || ''}
                        type='text'
                        placeholder='Enter Ages'
                      />
                    </div>
                  </div>
                </div>
                <div className='redux-form__row'>
                  <div className='lockerEdit__information-fields--column redux-form__column--size-m'>
                    <p>Discount (-20% - 20%)</p>
                    <div className='text-field'>
                      <PermissionHandler
                        permissions={PermissionsEnum.LockerManagerEditDiscount}
                        fallback={(
                          <input
                            className='w-100 lockerEdit__text-input'
                            disabled={true}
                            value={discount || ''}
                            placeholder='Enter Locker Discount'
                          />
                        )}
                      >
                        <input
                          className='w-100 lockerEdit__text-input'
                          onChange={this.handleInputChange}
                          name='discount'
                          value={discount || ''}
                          type='number'
                          placeholder='Enter Locker Discount'
                          step='1'
                          min='-20'
                          max='20'
                        />
                      </PermissionHandler>
                    </div>
                  </div>
                  <div className='lockerEdit__information-fields--column redux-form__column--size-m ml-30'>
                    <p>Markup (percentage)</p>
                    <Dropdown
                      options={markupDropdown}
                      defaultValue={fundraisingMarkup}
                      name={'fundraisingMarkup'}
                      classes={'redux-form__select'}
                      onChange={this.handleInputChange}
                    />
                  </div>
                </div>
              </div>
              <div className='lockerEdit__information-logo'>
                <i
                  className='lockerEdit__information-logo--image'
                  style={{ backgroundImage: logoUrl }}
                />
                <div className='lockerEdit__information-logo--actions'>
                  <a
                    className='lockerManagerEdit__details-header--edit'
                    onClick={this.uploadContainerOnClick}
                  >
                    <Icon materialIcon={'add_photo_alternate'} />
                    <span>Upload</span>
                  </a>
                  <a
                    className='lockerManagerEdit__details-header--edit'
                    onClick={this.changeContainerOnClick}
                  >
                    <Icon materialIcon={'swap_horiz'} />
                    <span>Change</span>
                  </a>
                </div>
              </div>
            </div>
            <div className='lockerEdit__information-fields--column w-100'>
              <p>Locker Type</p>
              <Dropdown
                options={lockerTypeOptions}
                defaultValue={type}
                name={'type'}
                classes={'redux-form__select'}
                onChange={this.handleInputChange}
              />
            </div>
            <div className='lockerEdit__information-fields--column w-100'>
              <p>Program Name</p>
              <div className='text-field'>
                <input
                  className='w-100'
                  onChange={this.handleInputChange}
                  name='programName'
                  value={programName || ''}
                  type='text'
                  placeholder='Enter Program Name'
                />
              </div>
            </div>
            <div className='lockerEdit__information-fields--column w-100'>
              <p>Program Link</p>
              <div className='text-field flex'>
                <input
                  className='w-100'
                  onChange={this.handleInputChange}
                  name='programLink'
                  value={programLink || ''}
                  type='text'
                  placeholder='Enter Locker Program Link'
                />
                <a
                  target='_blank'
                  href={this.formatLink()}
                  rel='noreferrer'
                >
                  <Icon
                    materialIcon={'arrow_forward'}
                    classes={'button flex-right lockerEdit__link'}
                  />
                </a>
              </div>
            </div>
            <div className='lockerEdit__information-fields--column w-100'>
              <p>General Note</p>
              <div className='text-field flex'>
                <input
                  className='w-100'
                  onChange={this.handleInputChange}
                  name='note'
                  value={note || ''}
                  type='text'
                  placeholder='Enter Note'
                />
              </div>
            </div>
            <div className='lockerEdit__information-fields--column w-100'>
              <p>Production Note</p>
              <div className='text-field flex'>
                <input
                  className='w-100'
                  onChange={this.handleInputChange}
                  name='productionNote'
                  value={productionNote || ''}
                  type='text'
                  placeholder='Enter Note'
                />
              </div>
            </div>
          </div>
          <div className={`lockerEdit__information-change-logos custom-scrollbar--horizontal ${changeLogoIsActive && 'is-active'}`}>
            {logos}
          </div>
          <div className={`p-l-16 p-r-16 lockerEdit__information-change-logos ${uploadLogoIsActive && 'is-active'}`}>
            <div className='upload-field--horizontal m-t-16 w-100'>
              <div className='upload-field '>
                <DropZoneWrapper
                  onDrop={this.onDrop}
                  className={'block-drop-zone'}
                  multiple={false}
                >
                  {dropzoneContent}
                </DropZoneWrapper>
              </div>
              <Button
                type={'primary'}
                text={'Upload Logo'}
                onClick={this.uploadLogo}
              />
            </div>
          </div>
          <div className='lockerEdit__checkboxes mb-30'>
            <CheckButton
              disabled={false}
              text={'Locker is Approved'}
              checked={approved}
              onClick={this.toggleApproved}
            />
            <CheckButton
              disabled={false}
              text={'Locker is Searchable'}
              checked={searchable}
              onClick={this.toggleSearchable}
            />
            <CheckButton
              disabled={false}
              text={'Disable Emails'}
              checked={disableEmails}
              onClick={this.toggleDisableEmails}
            />
          </div>
        </div>
      </Modal>
    );
  }
}

LockerManagerEditModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  locker: PropTypes.object.isRequired,
  lockerManagers: PropTypes.array.isRequired,
  partners: PropTypes.array.isRequired,
  lockerLogos: PropTypes.array.isRequired,
  organizations: PropTypes.arrayOf(PropTypes.object).isRequired,
  sports: PropTypes.array.isRequired,
};

const mapStateToProps = ({
  lockerManager,
  organizations,
}) => ({
  locker: lockerManager.lockerInfo,
  lockerManagers: lockerManager.lockerManagers,
  partners: lockerManager.partners,
  lockerLogos: lockerManager.lockerLogos,
  organizations: organizations.organizationSuggestions,
  sports: lockerManager.sportOptions,
});

export default connect(mapStateToProps)(LockerManagerEditModal);
