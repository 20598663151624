/* eslint-disable no-use-before-define */
import React, { useCallback } from 'react';
import Expander from '@sharedComponents/Buttons/Expander';
import MaterialCheckbox from '@sharedComponents/Inputs/MaterialCheckbox';
import { DataGridColumn, DataGridGroupLayerProps, GroupRow, selectionColumnWidthLg } from './DataGrid';

interface OwnProps<T extends { [key in K]: number }, K extends keyof T> {
  row: any;
  parentGroupKey?: string;
  columns: DataGridColumn<T, K>[];
  toggleExpanded: (key: string) => void;
  groupLayersProperties: DataGridGroupLayerProps[];
  onGridSelectionChange: (row?: T | GroupRow<T, K>, parentGroupKey?: string) => void;
  selectionKey: K;
  selectedIds: Set<string | number>;
  selectable: boolean;
  defaultWidth: number;
}

type Props<T extends { [key in K]: number }, K extends keyof T> = OwnProps<T, K>;

const DataGridRow = <T extends { [key in K]: number }, K extends keyof T>({
  row,
  columns,
  toggleExpanded,
  groupLayersProperties,
  onGridSelectionChange,
  selectable,
  selectionKey,
  selectedIds,
  parentGroupKey,
  defaultWidth,
}: Props<T, K>): JSX.Element => {
  const groupLayerProperties = groupLayersProperties[row.depth];
  const expandedCollapsedText = `${row.displayValue}
    ${groupLayerProperties?.showCount ? `(${row.rows.length})` : ''}`;

  const toogleExpanded = useCallback(() => {
    if (groupLayerProperties.alwaysExpanded) { return; }

    toggleExpanded(row.groupKey);
  }, [
    groupLayerProperties,
    toggleExpanded,
    row,
  ]);

  const gridSelectionChanged = useCallback(() => {
    onGridSelectionChange(row, parentGroupKey);
  }, [
    row,
    parentGroupKey,
    onGridSelectionChange,
  ]);

  const toRow = (c: DataGridColumn<T, K>, index: number) => (
    <td
      key={index}
      className={c.className}
      style={{ width: `${c.width ? c.width : defaultWidth}%` }}
    >
      {c.selectionColumn
        ? (
          <MaterialCheckbox
            checked={selectedIds.has(row[selectionKey])}
            onClick={gridSelectionChanged}
            text={''}
            classes={c.name}
          />
        )
        : (
          c.template
            ? c.template(row)
            : row[c.key]
        )}
    </td>
  );

  const toGroupRow = (innerRow: T | GroupRow<T, K>) => (
    <DataGridRow
      row={innerRow}
      parentGroupKey={row.groupKey}
      columns={columns}
      key={'isGroup' in innerRow ? innerRow.value : innerRow[selectionKey]}
      toggleExpanded={toggleExpanded}
      groupLayersProperties={groupLayersProperties}
      onGridSelectionChange={onGridSelectionChange}
      selectionKey={selectionKey}
      selectedIds={selectedIds}
      defaultWidth={defaultWidth}
      selectable={selectable}
    />
  );

  if (!row.isGroup) {
    return (
      <tr className='test'>
        {columns.map(toRow)}
      </tr>
    );
  }

  return (
    <>
      <tr
        className={`flex align__center group-header-container ${groupLayerProperties.rowClasses}`}
        style={{ height: `${groupLayerProperties.height ?? 60}px` }}
      >
        {selectable
          && (
            <td style={{ width: `${selectionColumnWidthLg}%` }}>
              <MaterialCheckbox
                checked={selectedIds.has(row.groupKey)}
                onClick={onGridSelectionChange.bind(null, row, parentGroupKey)}
                text={''}
              />
            </td>
          )}
        <td
          className='flex w-100 justify__space-between text-bold group-header'
          style={{ width: `${100 - defaultWidth - selectionColumnWidthLg}%` }}
        >
          {(
            <Expander
              isExpanded={row.expanded}
              toggle={toogleExpanded}
              hideIcon={groupLayerProperties.alwaysExpanded}
              collapseText={expandedCollapsedText}
              expandText={expandedCollapsedText}
              classes={'w-100 h-100 cursor-pointer flex justify__start text-bold'}
            />
          )}
        </td>
        <td style={{ width: `${defaultWidth}%` }}>
          {row.additionalDisplayValue}
        </td>
      </tr>
      {row.expanded && row.rows.length
        && row.rows.map(toGroupRow)}
    </>
  );
};

export default React.memo(DataGridRow) as typeof DataGridRow;
