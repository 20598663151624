/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */

export type ArtworkTaskStatusEnum = 'Unassigned' | 'Assigned' | 'InQC' | 'Rework' | 'Completed' | 'ProductionRework';


export const ArtworkTaskStatusEnum = {
  Unassigned: 'Unassigned' as ArtworkTaskStatusEnum,
  Assigned: 'Assigned' as ArtworkTaskStatusEnum,
  InQC: 'InQC' as ArtworkTaskStatusEnum,
  Rework: 'Rework' as ArtworkTaskStatusEnum,
  Completed: 'Completed' as ArtworkTaskStatusEnum,
  ProductionRework: 'ProductionRework' as ArtworkTaskStatusEnum,
};
