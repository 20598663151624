import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { reset } from 'redux-form';
import {
  addBrand,
  editBrand,
  deleteBrand,
} from '@APICalls/brand/actions';
import { keyNameEnum } from '@constants/enums/commonEnums';
import { brandForm } from '@constants/reduxForms';
import { getBrands } from '@redux/productCatalog/actions';
import { parseDateTimeNumeric } from '@util/dateHandler';
import { materialSwal } from '@util/componentHelper';
import ImagePreviewCell from '@sharedComponents/Table/TableCells/ImagePreviewCell';
import Table from '@sharedComponents/Table/Table';
import Button from '@sharedComponents/Buttons/Button';
import SearchFilter from '@sharedComponents/Inputs/SearchFilter';
import BrandsActionsColumn from './BrandsActionsColumn';
import BrandAddModal from './BrandModals/BrandAddModal';
import BrandEditModal from './BrandModals/BrandEditModal';
import BrandDeleteModal from './BrandModals/BrandDeleteModal';

const BrandsTable = Table();

class Brands extends Component {
  state = {
    addBrandModalIsOpened: false,
    editBrandModalIsOpened: false,
    deleteBrandModalIsOpened: false,
    imageModalIsOpened: false,
    selectedBrand: null,
    filteredData: this.props.queue,
  };

  componentDidMount() {
    this.fetchBrands();
  }

  componentDidUpdate(oldProps) {
    const { queue } = this.props;

    if (oldProps.queue !== queue) {
      this.setState({ filteredData: this.filterData() });
    }
  }

  getColumns = () => [
    {
      Header: 'ID',
      accessor: 'id',
      minWidth: 20,
      sortable: true,
      Cell: (cellProps) => cellProps.value && <span>{cellProps.value}</span>,
    },
    {
      Header: 'Created',
      accessor: 'created',
      minWidth: 100,
      Cell: (cellProps) => cellProps.value && <div>{parseDateTimeNumeric(cellProps.value)}</div>,
    },
    {
      Header: 'Updated',
      accessor: 'updated',
      minWidth: 100,
      Cell: (cellProps) => cellProps.value && <div>{parseDateTimeNumeric(cellProps.value)}</div>,
    },
    {
      Header: 'Code',
      accessor: 'code',
      minWidth: 100,
      Cell: (cellProps) => cellProps.value && <span>{cellProps.value}</span>,
    },
    {
      Header: 'Name',
      accessor: 'name',
      minWidth: 100,
      Cell: (cellProps) => cellProps.value && <span>{cellProps.value}</span>,
    },
    {
      Header: 'Image',
      accessor: 'imageUrl',
      className: 'no-padding',
      width: 200,
      Cell: (cellProps) => (
        <ImagePreviewCell
          imageUrl={cellProps.value}
          transparent={false}
        />
      ),
    },
    {
      Header: '',
      accessor: '',
      minWidth: 40,
      resizable: false,
      style: { overflow: 'visible' },
      Cell: (cellProps) => (
        <BrandsActionsColumn
          brand={cellProps.value}
          onBrandEdit={this.openEditBrandModal}
          onBrandDelete={this.openDeleteBrandModal}
        />
      ),
    },
  ];

  fetchBrands = () => {
    const { dispatch } = this.props;

    dispatch(getBrands());
  };

  filterData = (searchInput) => {
    const search = (searchInput || '').toLowerCase();
    const { queue } = this.props;

    if (search === '') {
      return queue;
    }

    return queue.filter((brand) => brand.name.toLowerCase().includes(search)
      || brand.code.toLowerCase().includes(search));
  };

  clearSearch = () => {
    this.setState({ filteredData: this.filterData('') });
  };

  onSearch = (e) => {
    if (e.key && e.key !== keyNameEnum.Enter) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();

    const searchInput = e.target.value;
    this.setState(() => ({ filteredData: this.filterData(searchInput) }));
  };

  addBrand = async (form) => {
    const result = await addBrand(form);

    if (result?.success) {
      materialSwal('Success', result.message, 'success');
      this.closeAddBrandModal();
      this.fetchBrands();
    }
  };

  editBrand = async (form) => {
    const result = await editBrand(form);

    if (result?.success) {
      materialSwal('Success', result.message, 'success');
      this.closeEditBrandModal();
      this.fetchBrands();
    }
  };

  deleteBrand = async (brandId) => {
    const result = await deleteBrand(brandId);

    if (result?.success) {
      materialSwal('Success', result.message, 'success');
      this.closeDeleteBrandModal();
      this.fetchBrands();
    }
  };

  openAddBrandModal = () => {
    this.setState({ addBrandModalIsOpened: true });
  };

  closeAddBrandModal = () => {
    const { dispatch } = this.props;

    dispatch(reset(brandForm));
    this.setState({ addBrandModalIsOpened: false });
  };

  openEditBrandModal = (brand) => {
    this.setState({
      editBrandModalIsOpened: true,
      selectedBrand: brand,
    });
  };

  closeEditBrandModal = () => {
    const { dispatch } = this.props;

    dispatch(reset(brandForm));
    this.setState({
      editBrandModalIsOpened: false,
      selectedBrand: null,
    });
  };

  openDeleteBrandModal = (brand) => {
    this.setState({
      deleteBrandModalIsOpened: true,
      selectedBrand: brand,
    });
  };

  closeDeleteBrandModal = () => {
    this.setState({
      deleteBrandModalIsOpened: false,
      selectedBrand: null,
    });
  };

  render() {
    const {
      addBrandModalIsOpened,
      editBrandModalIsOpened,
      deleteBrandModalIsOpened,
      selectedBrand,
      filteredData,
    } = this.state;
    const initialValues = !selectedBrand ? {} : selectedBrand;

    return (
      <div className='container'>
        <BrandAddModal
          isOpen={addBrandModalIsOpened}
          closeModal={this.closeAddBrandModal}
          onSubmit={this.addBrand}
        />

        <BrandEditModal
          isOpen={editBrandModalIsOpened}
          closeModal={this.closeEditBrandModal}
          onSubmit={this.editBrand}
          initialValues={initialValues}
        />

        <BrandDeleteModal
          isOpen={deleteBrandModalIsOpened}
          closeModal={this.closeDeleteBrandModal}
          deleteBrand={this.deleteBrand}
          brand={initialValues}
        />

        <div className='table-options w-100'>
          <div className='flex'>
            <SearchFilter
              search={this.onSearch}
              clearSearch={this.clearSearch}
            />
          </div>
          <div className='flex'>
            <Button
              type={'primary'}
              text={'Add Brand'}
              onClick={this.openAddBrandModal}
              classes={'colors__add-btn'}
            />
          </div>
        </div>

        <div className='sheet'>
          <BrandsTable
            data={filteredData}
            columns={this.getColumns()}
            showPagination={false}
          />
        </div>
      </div>
    );
  }
}

Brands.propTypes = {
  queue: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const mapStateToProps = ({ productCatalog }) => ({
  queue: productCatalog.brands,
});

export default connect(mapStateToProps)(Brands);
