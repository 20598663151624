import React from 'react';
import { Helmet } from 'react-helmet';

const OrderManagementTable = React.memo(() => {
  const title = "Order Management";

  return (
    <div className='header__bar-content'>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <h2 className='header__bar-title'>{title}</h2>
    </div>
  );
});

export default OrderManagementTable;
