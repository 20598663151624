/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Orders Homefield API
 * OpenAPI spec version: v1
 */
import type { Address } from './address';

export interface Customer {
  customerId?: string | null;
  name?: string | null;
  email?: string | null;
  phone?: string | null;
  address?: Address;
}
