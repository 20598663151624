import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { rushReasons } from '@constants/common';
import { createOptionsList } from '@util/optionsMap';
import { getTodaysDate } from '@util/dateHandler';
import DateInput from '@sharedComponents/Inputs/DateInput';
import Modal from '@sharedComponents/Modal/Modal';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import TextInput from '@sharedComponents/Inputs/TextInput';
import Dropdown from '@sharedComponents/Inputs/Dropdowns/Dropdown';

const rushReasonsOptions = createOptionsList({
  list: rushReasons,
  key: 'id',
  value: 'reason',
  name: 'reason',
  emptyOption: {
    disabled: true,
    name: 'Choose Reason',
  },
});

class OrderManagementDetailsRushModal extends Component {
  state = {
    rushNote: '',
    rushReason: '',
    startDate: getTodaysDate(),
  };

  rushOrder = () => {
    const { rushOrder } = this.props;
    const {
      rushReason,
      startDate,
      rushNote,
    } = this.state;

    rushOrder(rushReason, rushNote, startDate);
    this.initializeState();
  };

  cancel = () => {
    const { closeModal } = this.props;

    closeModal();
    this.initializeState();
  };

  handleRushNoteChange = (e) => {
    const rushNote = e.target.value;
    this.setState(() => ({ rushNote }));
  };

  handleSelectRushReason = (e) => {
    const rushReason = e.target.value;
    this.setState(() => ({ rushReason }));
  };

  handleDateChange = (date) => {
    this.setState(() => ({ startDate: date }));
  };

  initializeState = () => {
    this.setState({
      rushReason: '',
      startDate: getTodaysDate(),
    });
  };

  render() {
    const {
      order,
      isOpen,
    } = this.props;

    const {
      rushReason,
      startDate,
    } = this.state;

    const minRushDate = getTodaysDate();
    const isRushDisabled = !(rushReason !== '' && startDate && moment(startDate).isAfter(minRushDate));

    return (
      <Modal
        isOpen={isOpen}
        closeModal={this.cancel}
        title={`Rush order O${order.orderNumber}?`}
        modalHeight={'l'}
        modalWidth={'m'}
        buttons={(
          <ModalButtons
            onClose={this.cancel}
            onConfirm={this.rushOrder}
            confirmBtnText={'Rush order'}
            confirmBtnDisabled={isRushDisabled}
          />
        )}
      >
        <div className='order__rush-modal'>
          <div className='order__rush-modal-input'>
            <div className='order__rush-modal-input--label'>New Ship Date</div>
            <div className='order__rush-modal-input--datepicker'>
              <DateInput
                minDate={minRushDate}
                value={startDate}
                maxDate={'2099-12-31'}
                onChange={this.handleDateChange}
              />
            </div>
          </div>
          <div className='order__rush-modal-input'>
            <div className='filter-group w-100 pr-10'>
              <Dropdown
                options={rushReasonsOptions}
                selectClasses={'w-100'}
                defaultValue={rushReason}
                onChange={this.handleSelectRushReason}
              />
            </div>
          </div>
          <div className='order__rush-modal-input'>
            <TextInput
              placeholder={'Note'}
              onChange={this.handleRushNoteChange}
              classes={'w-100 pr-10'}
              inputClasses={'w-100'}
            />
          </div>
        </div>
      </Modal>
    );
  }
}

OrderManagementDetailsRushModal.propTypes = {
  order: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  rushOrder: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default OrderManagementDetailsRushModal;
