/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Financials Homefield API
 * OpenAPI spec version: v1
 */
import type { GetVoucherOrderPagedListQuerySortByEnum } from './getVoucherOrderPagedListQuerySortByEnum';
import type { SortDirectionEnum } from './sortDirectionEnum';
import type { VoucherOrderPaymentStatusEnum } from './voucherOrderPaymentStatusEnum';

export type GetHomefieldApiVouchersOrdersParams = { OrganizationId?: number; LockerId?: number; Search?: string; PageNumber?: number; PageSize?: number; SortBy?: GetVoucherOrderPagedListQuerySortByEnum; SortDirection?: SortDirectionEnum; ValidFrom?: string; ValidUntil?: string; PaymentStatus?: VoucherOrderPaymentStatusEnum[] };
