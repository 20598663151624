import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reset } from 'redux-form';
import {
  addSport,
  editSport,
  deleteSport,
} from '@APICalls/sport/actions';
import { sportForm } from '@constants/reduxForms';
import { keyNameEnum } from '@constants/enums/commonEnums';
import { getSports } from '@redux/productCatalog/actions';
import { parseDateTimeNumeric } from '@util/dateHandler';
import { materialSwal } from '@util/componentHelper';
import Button from '@sharedComponents/Buttons/Button';
import Table from '@sharedComponents/Table/Table';
import SearchFilter from '@sharedComponents/Inputs/SearchFilter';
import SportsActionsColumn from './SportsActionsColumn';
import SportDeleteModal from './SportModals/SportDeleteModal';
import SportAddModal from './SportModals/SportAddModal';
import SportEditModal from './SportModals/SportEditModal';

const SportsTable = Table();

class Sports extends Component {
  state = {
    addSportModalIsOpened: false,
    editSportModalIsOpened: false,
    deleteSportModalIsOpened: false,
    selectedSport: null,
    filteredData: this.props.queue,
  };

  componentDidMount() {
    this.fetchSports();
  }

  componentDidUpdate(oldProps) {
    const { queue } = this.props;

    if (oldProps.queue !== queue) {
      this.setState({ filteredData: this.filterData() });
    }
  }

  getColumns = () => [
    {
      Header: 'ID',
      accessor: 'id',
      minWidth: 30,
      sortable: true,
      Cell: (cellProps) => cellProps.value && <span>{cellProps.value}</span>,
    },
    {
      Header: 'Created',
      accessor: 'created',
      minWidth: 100,
      Cell: (cellProps) => cellProps.value && <div>{parseDateTimeNumeric(cellProps.value)}</div>,
    },
    {
      Header: 'Updated',
      accessor: 'updated',
      minWidth: 100,
      Cell: (cellProps) => cellProps.value && <div>{parseDateTimeNumeric(cellProps.value)}</div>,
    },
    {
      Header: 'Code',
      accessor: 'code',
      minWidth: 100,
      Cell: (cellProps) => cellProps.value && <span>{cellProps.value}</span>,
    },
    {
      Header: 'Name',
      accessor: 'name',
      minWidth: 100,
      Cell: (cellProps) => cellProps.value && <span>{cellProps.value}</span>,
    },
    {
      Header: '',
      accessor: '',
      minWidth: 40,
      resizable: false,
      style: { overflow: 'visible' },
      Cell: (cellProps) => (
        <SportsActionsColumn
          sport={cellProps.value}
          onSportEdit={this.openEditSportModal}
          onSportDelete={this.openDeleteSportModal}
        />
      ),
    },
  ];

  fetchSports = () => {
    const { dispatch } = this.props;

    dispatch(getSports());
  };

  filterData = (searchInput) => {
    const search = (searchInput || '').toLowerCase();
    const { queue } = this.props;

    if (search === '') {
      return queue;
    }

    return queue.filter((sport) => sport.code.toLowerCase().includes(search)
      || sport.name.toLowerCase().includes(search));
  };

  clearSearch = () => {
    this.setState({ filteredData: this.filterData('') });
  };

  onSearch = (e) => {
    if (e.key && e.key !== keyNameEnum.Enter) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();

    const searchInput = e.target.value;

    this.setState({ filteredData: this.filterData(searchInput) });
  };

  addSport = async (form) => {
    const result = await addSport(form);

    if (result?.success) {
      materialSwal('Success', result.message, 'success');
      this.closeAddSportModal();
      this.fetchSports();
    }
  };

  editSport = async (form) => {
    const result = await editSport(form);

    if (result?.success) {
      materialSwal('Success', result.message, 'success');
      this.closeEditSportModal();
      this.fetchSports();
    }
  };

  deleteSport = async (sportId) => {
    const result = await deleteSport(sportId);

    if (result?.success) {
      materialSwal('Success', result.message, 'success');
      this.closeDeleteSportModal();
      this.fetchSports();
    }
  };

  openAddSportModal = () => {
    this.setState({ addSportModalIsOpened: true });
  };

  closeAddSportModal = () => {
    const { dispatch } = this.props;

    dispatch(reset(sportForm));
    this.setState({ addSportModalIsOpened: false });
  };

  openEditSportModal = (sport) => {
    this.setState({
      selectedSport: sport,
      editSportModalIsOpened: true,
    });
  };

  closeEditSportModal = () => {
    const { dispatch } = this.props;

    dispatch(reset(sportForm));
    this.setState({
      editSportModalIsOpened: false,
      selectedSport: null,
    });
  };

  openDeleteSportModal = (sport) => {
    this.setState({
      selectedSport: sport,
      deleteSportModalIsOpened: true,
    });
  };

  closeDeleteSportModal = () => {
    this.setState({
      deleteSportModalIsOpened: false,
      selectedSport: null,
    });
  };

  render() {
    const {
      selectedSport,
      addSportModalIsOpened,
      editSportModalIsOpened,
      deleteSportModalIsOpened,
      filteredData,
    } = this.state;

    const initialValues = !selectedSport ? {} : selectedSport;

    return (
      <div className='container'>
        <SportAddModal
          isOpen={addSportModalIsOpened}
          closeModal={this.closeAddSportModal}
          onSubmit={this.addSport}
        />

        <SportEditModal
          isOpen={editSportModalIsOpened}
          closeModal={this.closeEditSportModal}
          onSubmit={this.editSport}
          initialValues={initialValues}
        />

        <SportDeleteModal
          isOpen={deleteSportModalIsOpened}
          closeModal={this.closeDeleteSportModal}
          deleteSport={this.deleteSport}
          sport={initialValues}
        />

        <div className='table-options w-100'>
          <div className='flex'>
            <SearchFilter
              search={this.onSearch}
              clearSearch={this.clearSearch}
            />
          </div>
          <div className='flex'>
            <Button
              type={'primary'}
              text={'Add Sport'}
              onClick={this.openAddSportModal}
              classes={'colors__add-btn'}
            />
          </div>
        </div>
        <div className='sheet'>
          <SportsTable
            data={filteredData}
            columns={this.getColumns()}
            showPagination={false}
          />
        </div>
      </div>
    );
  }
}

Sports.propTypes = {
  queue: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const mapStateToProps = ({ productCatalog }) => ({
  queue: productCatalog.sports,
});

export default connect(mapStateToProps)(Sports);
