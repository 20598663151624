/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */
import type { PrepressJobOrderIndicatorEnum } from './prepressJobOrderIndicatorEnum';
import type { OrderPrintStatusEnum } from './orderPrintStatusEnum';
import type { PrintQueueDetailsLogoItem } from './printQueueDetailsLogoItem';
import type { PrintQueueDetailsPersonalizationItem } from './printQueueDetailsPersonalizationItem';
import type { PrintAssemblyStats } from './printAssemblyStats';

export interface SheetItem {
  indicator?: PrepressJobOrderIndicatorEnum;
  orderNumber?: number;
  orderId?: number;
  shipDate?: string | null;
  isDeleted?: boolean;
  isRushed?: boolean;
  isExpressProduction?: boolean;
  lockerId?: number | null;
  readonly lockerNumber?: number | null;
  lockerTeamName?: string | null;
  organizationId?: number | null;
  organizationName?: string | null;
  inventoryLocation?: string | null;
  orderPrintStatus?: OrderPrintStatusEnum;
  logoPrintItems?: PrintQueueDetailsLogoItem[] | null;
  logoCutItems?: PrintQueueDetailsLogoItem[] | null;
  personalizations?: PrintQueueDetailsPersonalizationItem[] | null;
  printAssemblyDetails?: PrintAssemblyStats;
}
