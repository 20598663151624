/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Orders Homefield API
 * OpenAPI spec version: v1
 */

export interface AccountExecutiveInfo {
  accountingId?: string | null;
  commission?: string | null;
}
