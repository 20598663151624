/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */

export interface BatchWarehouseOrderGroupItemDto {
  groupDate?: string;
  totalOrders?: number;
  totalUnits?: number;
  totalDecorations?: number;
  readyOrders?: number;
  readyUnits?: number;
  readyDecorations?: number;
  readyHagOnlyOrders?: number;
  readyHagOnlyUnits?: number;
  readyHagOnlyDecorations?: number;
  readyEmbOnlyOrders?: number;
  readyEmbOnlyUnits?: number;
  readyEmbOnlyDecorations?: number;
  readyNoDecOrders?: number;
  readyNoDecUnits?: number;
  readyNoDecDecorations?: number;
  readyAllDecOrders?: number;
  readyAllDecUnits?: number;
  readyAllDecDecorations?: number;
  partialReadyOrders?: number;
  partialReadyUnits?: number;
  partialReadyDecorations?: number;
  backorderedOrders?: number;
  backorderedUnits?: number;
  backorderedDecorations?: number;
  expressProductionOrders?: number;
  expressProductionUnits?: number;
  expressProductionDecorations?: number;
  partnerOrders?: number;
  partnerUnits?: number;
  partnerDecorations?: number;
  dispatchedOrders?: number;
  dispatchedUnits?: number;
  dispatchedDecorations?: number;
  pickedOrders?: number;
  pickedUnits?: number;
  pickedDecorations?: number;
  needPrintOrders?: number;
  needPrintUnits?: number;
  needPrintDecorations?: number;
  checkedInOrders?: number;
  checkedInUnits?: number;
  checkedInDecorations?: number;
  producedOrders?: number;
  producedUnits?: number;
  producedDecorations?: number;
  shippedOrders?: number;
  shippedUnits?: number;
  shippedDecorations?: number;
  lastShipDate?: string | null;
}
