/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Catalog Homefield API
 * OpenAPI spec version: v1
 */

export interface StyleCrossSellItem {
  sourceStyleId?: number | null;
  targetStyleId?: number;
  crossSellCategory?: string | null;
  sourceStyleCode?: string | null;
  targetStyleCode?: string | null;
}
