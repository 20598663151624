/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */
import type { OrderItemStatusEnum } from './orderItemStatusEnum';

export interface OrderItemStatusEnumStatusModel {
  created?: string | null;
  updated?: string | null;
  deleted?: string | null;
  status?: OrderItemStatusEnum;
  readonly value?: number | null;
  readonly label?: string | null;
  user?: string | null;
}
