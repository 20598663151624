/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */
import type { HagPrintRoomJobStatusEnum } from './hagPrintRoomJobStatusEnum';
import type { PersonalizationItem } from './personalizationItem';
import type { LogoItem } from './logoItem';

export interface HagPrintRoomOrderDetailsDto {
  orderNumber?: number;
  isCanceled?: boolean;
  isRush?: boolean;
  isExpressProduction?: boolean;
  lockerId?: number | null;
  organizationId?: number | null;
  shipDate?: string;
  decortationCount?: number;
  hagPrintRoomJobId?: number | null;
  status?: HagPrintRoomJobStatusEnum;
  personalizations?: PersonalizationItem[] | null;
  logos?: LogoItem[] | null;
  logoCount?: number | null;
  personalizationCount?: number | null;
  notes?: string | null;
}
