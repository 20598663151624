import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ArtworkTaskDetailsNoteChangeLog from './ArtworkTaskDetailsNoteChangeLog';
import ArtworkTaskNotes from './ArtworkTaskNotes';
import ArtworkTaskNewNote from './ArtworkTaskNewNote';
import Tabs from '@sharedComponents/Tabs/Tabs';

const artworkTaskNoteLogTabEnum = {
  Notes: 'Notes',
  ChangeLog: 'ChangeLog',
};

class ArtworkTaskDetailsNoteLogContainer extends PureComponent {
  state = {
    selectedTab: artworkTaskNoteLogTabEnum.Notes,
  };

  selectTab = (selectedTab) => {
    this.setState({ selectedTab });
  };

  getTabs = () => {
    const {
      task,
      loggedInUser,
    } = this.props;

    const tabs = [
      {
        title: 'Notes',
        name: artworkTaskNoteLogTabEnum.Notes,
        content: (
          <div>
            <ArtworkTaskNotes
              taskId={task.id}
              loggedInUser={loggedInUser}
              notes={task.notes}
              lockerId={task.locker.id}
            />
            <ArtworkTaskNewNote
              lockerId={task.locker.id}
              taskId={task.id}
            />
          </div>
        ),
      },
      {
        title: 'History',
        name: artworkTaskNoteLogTabEnum.ChangeLog,
        content: <ArtworkTaskDetailsNoteChangeLog log={task.changeLogs} />,
      },
    ];

    return tabs;
  };

  render() {
    const { selectedTab } = this.state;

    return (
      <div className='artwork-details__container'>
        <Tabs
          tabs={this.getTabs()}
          selectedTab={selectedTab}
          selectTab={this.selectTab}
        />
      </div>
    );
  }
}

ArtworkTaskDetailsNoteLogContainer.propTypes = {
  task: PropTypes.object.isRequired,
  loggedInUser: PropTypes.string,
};

const mapStateToProps = ({ oidc }) => ({
  loggedInUser: (oidc?.user?.profile) ? oidc.user.profile.sub : '',
});

export default connect(mapStateToProps)(ArtworkTaskDetailsNoteLogContainer);
