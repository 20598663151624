/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */

export type SortQueueByEnum = 'DatePlaced' | 'OrderNumber' | 'PricePaid';


export const SortQueueByEnum = {
  DatePlaced: 'DatePlaced' as SortQueueByEnum,
  OrderNumber: 'OrderNumber' as SortQueueByEnum,
  PricePaid: 'PricePaid' as SortQueueByEnum,
};
