/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */
import type { DecorationFlagTypeEnum } from './decorationFlagTypeEnum';

export interface FlagOrderItemDecorationCommand {
  orderNumber?: number;
  reason?: string | null;
  note?: string | null;
  ids?: number[] | null;
  flagType?: DecorationFlagTypeEnum;
}
