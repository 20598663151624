/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */
import type { HagTransmissionImageStatusEnum } from './hagTransmissionImageStatusEnum';

export interface IHagTransmissionImageDto {
  readonly id?: number;
  readonly uuid?: string | null;
  readonly created?: string | null;
  readonly updated?: string | null;
  readonly deleted?: string | null;
  status?: HagTransmissionImageStatusEnum;
  readonly message?: string | null;
  readonly fileName?: string | null;
  readonly hagTransmissionId?: number;
  readonly orderId?: number;
  readonly orderItemId?: number;
  readonly orderItemDecorationId?: number;
  readonly orderNumber?: number;
  readonly coloredStyleCode?: string | null;
  readonly styleCode?: string | null;
  readonly sku?: string | null;
  readonly colorGroup?: string | null;
  readonly colorA?: string | null;
  readonly colorB?: string | null;
  readonly floodColorId?: number | null;
  readonly decorationHeight?: number | null;
  readonly decorationWidth?: number | null;
  readonly isCustomSize?: boolean;
  readonly decorationType?: string | null;
  readonly decorationSize?: string | null;
  readonly decorationLocation?: string | null;
  readonly personalizationText?: string | null;
  readonly personalizationNumber?: string | null;
  readonly personalizationColorId?: number | null;
  readonly personalizationOutlineColorId?: number | null;
  readonly personalizationColorCode?: string | null;
  readonly personalizationOutlineColorCode?: string | null;
  readonly personalizationColorCmyk?: string | null;
  readonly personalizationOutlineColorCmyk?: string | null;
  readonly printableImageUrl?: string | null;
  readonly previewImageUrl?: string | null;
}
