/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Catalog Homefield API
 * OpenAPI spec version: v1
 */

export interface UpsertDtgColoredStyleConfigurationCommand {
  coloredStyleId?: number;
  dtgEnvironmentId?: number;
  dtgTabletProfileId?: number;
  dtgDefaultPalletId?: number;
  dtgPretreatmentBatchId?: number;
  pretreatmentPercent?: number;
  pretreatmentPlatenFahrenheit?: number;
  pretreatmentPsi?: number;
  pretreatmentDwellTimeSeconds?: number;
  postprintDryerFahrenheit?: number;
  postprintProcessTimeMinutes?: number;
  postprintAirSpeed?: number;
  postprintPressFahrenheit?: number;
  postprintPsi?: number;
  postprintDwellTimeSeconds?: number;
}
