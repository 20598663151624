/* eslint-disable @typescript-eslint/no-unused-vars */
import * as yup from 'yup';

yup.addMethod(yup.object, 'uniqueProperty', function(propertyName, message) {
  return this.test('unique', message, function(value) {
    if (!value || !value[propertyName]) {
      return true;
    }

    if (
      this.parent
        .filter((v: any) => v !== value)
        .some((v: any) => v[propertyName] === value[propertyName])
    ) {
      throw this.createError({
        path: `${this.path}.${propertyName}`,
      });
    }

    return true;
  });
});

declare module 'yup' {
  interface ObjectSchema<TShape, TContext> {
    uniqueProperty(propertyName: string, message: string): this;
  }
}

export default yup;
