/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */
import type { BatchWarehouseOrderGroupItemDto } from './batchWarehouseOrderGroupItemDto';

export interface BatchWarehouseOrderGroupsDto {
  groups?: BatchWarehouseOrderGroupItemDto[] | null;
  sums?: BatchWarehouseOrderGroupItemDto;
  vendors?: string[] | null;
  partners?: string[] | null;
}
