import React, {
  useCallback,
  useState,
} from 'react';
import RadioGroupItem, { RadioGroupItemModel } from './RadioGroupItem';
import {
  UseFormRegister,
  UseFormSetValue,
  Path,
  PathValue,
  UseFormGetValues,
  FieldValues,
} from 'react-hook-form';

interface OwnProps<TFormData extends FieldValues, T extends string | number | boolean> {
  name: Path<TFormData>;
  register: UseFormRegister<TFormData>;
  change: UseFormSetValue<TFormData>;
  getValue: UseFormGetValues<TFormData>;
  groupClassName?: string;
  errors?: string[];
  onClickOnLabel?: (value: T) => void;
  onchange?: (value: T) => void;
  radioItems: RadioGroupItemModel<T>[];
}

type Props<TFormData extends FieldValues, T extends string | number | boolean> = OwnProps<TFormData, T>;

const HookFormRadioGroup = <TFormData extends FieldValues, T extends string | number | boolean>({
  name,
  radioItems,
  groupClassName = '',
  onClickOnLabel,
  errors = [],
  change,
  register,
  getValue,
  onchange,
}: Props<TFormData, T>) => {
  const [
    selectedValue,
    setSelectedValue,
  ] = useState<T>(getValue(name) as T);

  const handleChangeEvent = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.defaultValue;
    let parsedValue = value;

    if (typeof (selectedValue) === 'number'
      || typeof (selectedValue) === 'boolean') {
      parsedValue = JSON.parse(value);
    }
    change(name, parsedValue as PathValue<TFormData, Path<TFormData>>);
    setSelectedValue(getValue(name) as T);
    onchange?.(parsedValue as T);
  }, [
    name,
    change,
    setSelectedValue,
    getValue,
    selectedValue,
    onchange,
  ]);

  const handleClickOnLabelEvent = useCallback((value: T) => {
    change(name, value as PathValue<TFormData, Path<TFormData>>);
    setSelectedValue(getValue(name) as T);
    onClickOnLabel?.(value);
  }, [
    name,
    change,
    setSelectedValue,
    getValue,
    onClickOnLabel,
  ]);

  const mapToError = (error: string) => (
    <div className='redux-form__error--field'>
      {error}
    </div>
  );

  const renderRadioItem = (item: RadioGroupItemModel<T>, index: number) => (
    <RadioGroupItem<T>
      key={index}
      item={item}
      value={selectedValue}
      onClickOnLabel={handleClickOnLabelEvent}
      {...register(name)}
      onChange={handleChangeEvent}
    />
  );

  return (
    <div className='w-100'>
      <div className={`${groupClassName}`}>
        {radioItems.map(renderRadioItem)}
      </div>
      {
        errors.map(mapToError)
      }
    </div>
  );
};

export default React.memo(HookFormRadioGroup) as typeof HookFormRadioGroup;
