/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */

export interface ReplaceOrderItemCommand {
  orderNumber?: number;
  itemToReplaceId?: number;
  quantity?: number;
  sku?: string | null;
  variationId?: number;
  logoStorefrontId?: number | null;
  basePrice?: number;
}
