/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */

export type DecorationSizeEnum = 'Undefined' | 'Youth' | 'Regular' | 'Large';


export const DecorationSizeEnum = {
  Undefined: 'Undefined' as DecorationSizeEnum,
  Youth: 'Youth' as DecorationSizeEnum,
  Regular: 'Regular' as DecorationSizeEnum,
  Large: 'Large' as DecorationSizeEnum,
};
