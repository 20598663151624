import { editOrderItemStatusForm } from '@constants/reduxForms';
import React, {
  useEffect, useMemo, useState,
} from 'react';
import { EditOrderItemStatusFormData } from '@models/forms/Production/EditOrderItemStatusFormData';
import { ProductionAssemblyItemGroupDto } from '@api/fulfillment/models';
import { ProductDetailDto } from '@api/productCatalog/models';
import { mapToOptionsList } from '@util/mappingHelper';
import yup from '@util/yupValidationHelper';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  useForm,
  UseFormReturn,
} from 'react-hook-form';
import HookFormDropdown from '@components/shared/Form/HookFormDropdown';
import {
  orderItemStatusEnum, orderItemStatusValueEnum,
} from '@constants/enums/orderEnums';

const schema = yup.object({
  status: yup.string().required('New status is required'),
  itemIds: yup.array(yup.number())
    .min(1, 'No items selected')
    .required('Valid From is required'),
});

interface OwnProps {
  item: ProductionAssemblyItemGroupDto;
  product: ProductDetailDto;
  onSubmit: (data: EditOrderItemStatusFormData) => Promise<void>;
}

type Status = {
  status: string;
  value: number;
};

type Props = OwnProps;

const statusOptions: Status[] = [
  {
    status: orderItemStatusEnum.OnHold,
    value: orderItemStatusValueEnum.OnHold,
  }, // Awaiting inventory
  {
    status: orderItemStatusEnum.AwaitingDispatchForPicking,
    value: orderItemStatusValueEnum.AwaitingDispatchForPicking,
  },
  {
    status: orderItemStatusEnum.InPicking,
    value: orderItemStatusValueEnum.InPicking,
  },
  {
    status: orderItemStatusEnum.Picked,
    value: orderItemStatusValueEnum.Picked,
  },
  {
    status: orderItemStatusEnum.Shipped,
    value: orderItemStatusValueEnum.Shipped,
  },
];

const EditOrderItemStatusForm = React.memo<Props>(({
  onSubmit,
  item,
  product,
}) => {
  const formMethods: UseFormReturn<EditOrderItemStatusFormData> = useForm<EditOrderItemStatusFormData>({
    // @ts-expect-error - resolver is weird
    resolver: yupResolver(schema),
  });

  const {
    handleSubmit,
    formState: { errors },
    setValue,
    register,
  } = formMethods;

  const [
    itemStatusOptions,
    setItemStatusOptions,
  ] = useState<Array<Status>>([]);

  useEffect(() => {
    setValue('itemIds', item.orderItemIds ?? []);
  }, [
    item,
    setValue,
  ]);

  useEffect(() => {
    setItemStatusOptions(statusOptions.filter(
      (option) => option.value > (item.status?.value ?? 0)
    ) ?? []);
  }, [item]);

  const optionsList = useMemo(
    () => mapToOptionsList<string, Status>({
      list: itemStatusOptions,
      key: 'value',
      value: 'status',
      name: 'status',
      emptyOption: {
        name: 'Select Item Status',
      },
    }),
    [itemStatusOptions]
  );

  return (
    <form
      className='redux-form'
      id={editOrderItemStatusForm}
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className='roster-notifications__form mb-100'>
        <div className='redux-form__row-aligned mt-30 mb-20'>
          <div className='redux-form__column--size-m mr-20 info__field'>
            <label className='redux-form__label'>
              Item SKU
            </label>
            <div className='info__value'>
              {item?.sku}
            </div>
          </div>
          <div className='redux-form__column--size-m mr-20 info__field'>
            <label className='redux-form__label'>
              Item Name
            </label>
            <div className='info__value'>
              {product?.coloredStyle?.style?.name}
            </div>
          </div>
        </div>
        <div className='redux-form__row-aligned mt-30 mb-20'>
          <div className='redux-form__column--size-m mr-20 info__field'>
            <label className='redux-form__label'>
              Current Order Item Status
            </label>
            <div className='info__value'>
              {item?.status?.status}
            </div>
          </div>
        </div>
        <div className='redux-form__row-aligned mt-30 mb-20'>
          <div className='redux-form__column--size-m mr-20'>
            <label className='redux-form__label'>
              New Status<span className='required'>*</span>
            </label>
            <HookFormDropdown<any, string>
              name={'status'}
              register={register}
              options={optionsList}
              change={setValue}
              classes={
                'roster-notifications__form__templates-dropdown new-order__sizes-dropdown'
              }
              error={errors.status}
            />
          </div>
        </div>
      </div>
    </form>
  );
});

export default EditOrderItemStatusForm;
