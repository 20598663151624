import React from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import MomentUtils from '@date-io/moment';
import { muiTheme } from '@constants/values';

interface OwnProps {
  value: Nullable<Date> | string;
  minDate?: Nullable<Date> | string;
  maxDate?: Nullable<Date> | string;
  label?: string;
  onChange: (date: MaterialUiPickersDate) => void;
  onError?: (date: MaterialUiPickersDate) => void;
  placeholder?: string;
  width?: number;
}

type Props = OwnProps;

const DateInput = React.memo<Props>((props) => (
  <ThemeProvider theme={muiTheme}>
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <KeyboardDatePicker
        format='MM/DD/YYYY'
        variant='dialog'
        style={props.width ? { width: `${props.width}%` } : undefined}
        {...props as any}
      />
    </MuiPickersUtilsProvider>
  </ThemeProvider>
));

export default DateInput;
