/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Orders Homefield API
 * OpenAPI spec version: v1
 */
import type { OrderPricingDetailsDtoOrderItemsPricingDetails } from './orderPricingDetailsDtoOrderItemsPricingDetails';

export interface OrderPricingDetailsDto {
  shippingBasePrice?: number;
  shippingCouponDiscount?: number;
  shippingPretaxPrice?: number;
  shippingPricePaid?: number;
  shippingRefundAmount?: number;
  shippingChargeAmount?: number;
  markdown?: number;
  tradeDiscount?: number;
  subtotalCouponDiscount?: number;
  refundAmount?: number;
  subtotalTaxRefundAmount?: number;
  subtotal?: number;
  pretaxPrice?: number;
  pricePaid?: number;
  shippingTaxableAmount?: number | null;
  shippingTaxAmount?: number | null;
  taxableAmount?: number | null;
  taxAmount?: number | null;
  subtotalChargeAmount?: number;
  subtotalChargeTaxAmount?: number;
  shippingChargeTaxAmount?: number;
  orderTotalDiscount?: number;
  orderItemsPricingDetails?: OrderPricingDetailsDtoOrderItemsPricingDetails;
}
