import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  reduxForm,
  stopSubmit,
  formValueSelector,
} from 'redux-form';
import { editProductForm } from '@constants/reduxForms';
import { validateProduct } from '@redux/productCatalog/validations';
import ProductForm from '../../ProductForm';
import Modal from '@sharedComponents/Modal/Modal';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';

class EditProductModal extends PureComponent {
  handleCloseModal = () => {
    const {
      dispatch,
      closeModal,
    } = this.props;

    closeModal();
    dispatch(stopSubmit(editProductForm, {}));
  };

  render() {
    const {
      modalIsOpen,
      handleSubmit,
      error,
      initialValues,
      deleteProduct,
      inventoryAlwaysAvailable,
      change,
    } = this.props;

    return (
      <Modal
        title={`Edit Product with SKU ${initialValues?.sku}`}
        isOpen={modalIsOpen}
        closeModal={this.handleCloseModal}
        modalHeight={'xl'}
        modalWidht={'l'}
        buttons={(
          <ModalButtons
            formSubmission={true}
            confirmBtnText={'Save'}
            onClose={this.handleCloseModal}
            formId={editProductForm}
          />
        )}
      >
        {
          modalIsOpen &&
          <div className='catalog-details__modal--container'>
            <ProductForm
              onSubmit={handleSubmit}
              error={error}
              deleteProduct={deleteProduct}
              change={change}
              initialValues={initialValues}
              inventoryAlwaysAvailable={inventoryAlwaysAvailable}
            />
          </div>
        }
      </Modal>
    );
  }
}

EditProductModal.propTypes = {
  modalIsOpen: PropTypes.bool,
  closeModal: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  modalStyle: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  error: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  deleteProduct: PropTypes.func.isRequired,
  change: PropTypes.func,
  inventoryAlwaysAvailable: PropTypes.bool,
};

const selector = formValueSelector(editProductForm);
const ConnectedEditProductModal = connect((state) => ({
  inventoryAlwaysAvailable: selector(state, 'alwaysAvailableVendorInventory'),
}))(EditProductModal);

export default connect()(reduxForm({
  form: editProductForm,
  enableReinitialize: true,
  validate: validateProduct,
})(ConnectedEditProductModal));
