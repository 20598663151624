import React, {
  useCallback,
  useState,
  useMemo,
  useEffect,
} from 'react';
import { OrderManagementTempItem } from '@redux/orderManagement/models';
import { mapToOptionsList } from '@util/mappingHelper';
import Modal from '@sharedComponents/Modal/Modal';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import NumberInput from '@sharedComponents/Inputs/NumberInput';
import Dropdown from '@sharedComponents/Inputs/Dropdowns/Dropdown/Dropdown';
import ItemDetails from '../OrderManagementNewOrder/ItemDetails';
import MaterialCheckbox from '../../shared/Inputs/MaterialCheckbox';

interface OwnProps {
  isOpen: boolean;
  item: Nullable<OrderManagementTempItem>;
  duplicateItem: (item: OrderManagementTempItem, quantity: number, size: string) => void;
  closeModal: () => void;
}

type Props = OwnProps;

const DuplicateItemModal = React.memo(({
  isOpen,
  item,
  duplicateItem,
  closeModal,
}: Props) => {
  const [
    quantity,
    setQuantity,
  ] = useState<number | undefined>(item?.quantity);
  const [
    size,
    setSize,
  ] = useState<string | undefined>(item?.size);
  const [
    duplicateDecorations,
    setDuplicateDecorations,
  ] = useState<boolean>(false);

  useEffect(() => {
    setQuantity(item?.quantity);
    setSize(item?.size);
  }, [item]);

  const handleCloseModal = useCallback(async () => {
    closeModal();
  }, [closeModal]);

  const handleDuplicateItem = useCallback(() => {
    duplicateItem(item!, quantity!, size!);
  }, [
    duplicateItem,
    item,
    quantity,
    size,
  ]);

  const handleChangeQuantity = useCallback((newQuantity: number) => {
    setQuantity(newQuantity);
  }, []);

  const handleChangeSize = useCallback((newSize: string) => {
    setSize(newSize);
  }, []);

  const toggleDuplicateDecorations = useCallback(() => {
    setDuplicateDecorations(!duplicateDecorations);
  }, [duplicateDecorations]);

  const options = useMemo(() => (mapToOptionsList<string, string>({
    list: item?.availableSizes ?? [],
    emptyOption: {
      name: 'Choose size',
      disabled: true,
    },
  })), [item]);

  if (!isOpen || !item) {
    return null;
  }

  return (
    <Modal
      title={'Duplicate Item'}
      isOpen={isOpen}
      modalWidth={'s'}
      modalHeight={'xl'}
      closeModal={handleCloseModal}
      buttons={(
        <ModalButtons
          confirmBtnText={'Duplicate'}
          cancelBtnText={'Cancel'}
          onConfirm={handleDuplicateItem}
          onClose={handleCloseModal}
        />
      )}
    >
      <div>
        <ItemDetails item={item} />
        <div className='mt-30'>
          <div className='align__center'>
            <NumberInput
              initialValue={quantity}
              placeholder={'Quantity'}
              onChange={handleChangeQuantity}
              classes={'w-50 pr-20'}
              inputClasses={'w-100'}
            />
            <Dropdown
              options={options}
              defaultValue={size}
              onChange={handleChangeSize}
              classes={'w-50 mb-0'}
              optionsClasses={'new-order__order-items__sizes-dropdown'}
            />
          </div>
          <div className='mt-30 mb-30'>
            <MaterialCheckbox
              text={'Duplicate Decoration(s)'}
              checked={duplicateDecorations}
              onClick={toggleDuplicateDecorations}
              classes={'p-0'}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
});

export default DuplicateItemModal;
