/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */
import type { ArtworkTaskStatusEnum } from './artworkTaskStatusEnum';

export interface ArtworkTableByStatusDto {
  readonly lockerNumber?: number;
  lockerId?: number;
  lockerTeamName?: string | null;
  parentLockerId?: number | null;
  readonly parentLockerNumber?: number | null;
  lockerPartnerName?: string | null;
  decorationMethod?: string | null;
  taskCount?: number | null;
  taskIds?: number[] | null;
  shipDate?: string | null;
  oldShipDate?: string | null;
  assignedTo?: string | null;
  assignedOn?: string | null;
  sentToQcOn?: string | null;
  sentToQcBy?: string | null;
  productionReadyOn?: string | null;
  productionReadyBy?: string | null;
  status?: ArtworkTaskStatusEnum;
  rushReason?: string | null;
  reworkReason?: string | null;
  isRushed?: boolean;
  isExpressProduction?: boolean;
  hasRequiredItems?: boolean;
}
