/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */
import type { SortDirectionEnum } from './sortDirectionEnum';
import type { DtgTransmissionStatusEnum } from './dtgTransmissionStatusEnum';

export type HomefieldApiDtgTransmissionsGETParams = { sortDirection?: SortDirectionEnum; status?: DtgTransmissionStatusEnum[]; orderNumber?: number[]; pageNumber?: number; pageSize?: number };
