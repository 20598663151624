import React from 'react';
import PropTypes from 'prop-types';
import arrowCollapse from '@assets/arrow-collapse.svg';
import arrowExpand from '@assets/arrow-expand.svg';
import MaterialTooltip from '@sharedComponents/Tooltips/MaterialTooltip';
import Icon from '@sharedComponents/Icons/Icon';
import PrintSheetItemThumbnailGroup from './PrintSheetItemThumbnailGroup';

const PrintSheetItemThumbnails = React.memo(({
  thumbnails,
  cutFiles,
  callForModal,
  barcode,
  renderPrintSheet,
  orderDeleted,
  colorsDictionary,
  colors,
}) => {
  const items = thumbnails.map((item, key) => {
    const isActive = item.barcodes.indexOf(barcode) >= 0 ? 'is-active' : '';
    let floodColor = <div />;

    if (renderPrintSheet) {
      const flood = item.floodColorId ? colorsDictionary[item.floodColorId] : '-';
      floodColor = <span className='sheet__thumbnails-title'>{flood.code}</span>;
    }

    const cancel = (item.isCanceled || orderDeleted)
      ? <div className='sheet__thumbnails-thumbnail canceled-thumbnail' />
      : '';

    let size = null;
    if (item.decorationLocation === 'FF') {
      if (item.decorationSize && item.decorationSize.toLowerCase() === 'youth') {
        size = (
          <MaterialTooltip
            tooltipText={'These logos are smaller than standard'}
            placement={'top'}
            contentClasses={'sheet__thumbnails-size-container'}
          >
            <span className='sheet__thumbnails-size'>
              <i
                className='fa'
                style={{
                  width: '25px',
                  height: '25px',
                  backgroundImage: `url("${arrowCollapse}")`,
                }}
              />
            </span>
          </MaterialTooltip>
        );
      }
      if (item.decorationSize && item.decorationSize.toLowerCase() === 'large') {
        size = (
          <MaterialTooltip
            tooltipText={'These logos are larger than standard'}
            placement={'top'}
            contentClasses={'sheet__thumbnails-size-container'}
          >
            <span className='sheet__thumbnails-size'>
              <i
                className='fa'
                style={{
                  width: '25px',
                  height: '25px',
                  backgroundImage: `url("${arrowExpand}")`,
                }}
              />
            </span>
          </MaterialTooltip>
        );
      }
    }
    if (item.decorationHeight || item.decorationWidth) {
      const decorationHeightText = item.decorationHeight && `Height: ${item.decorationHeight}`;
      const decorationWidthText = item.decorationWidth && `Width: ${item.decorationWidth}`;
      const tooltipText = `${decorationHeightText} ${decorationWidthText}`;
      size = (
        <MaterialTooltip
          tooltipText={`These logos have a custom size: ${tooltipText} inches`}
          placement={'top'}
          contentClasses={'sheet__thumbnails-size-container'}
        >
          <span className='sheet__thumbnails-size'>
            <Icon materialIcon={'aspect_ratio'} />
          </span>
        </MaterialTooltip>
      );
    }

    return (
      <li
        className={isActive}
        key={key}
      >
        <div className='sheet__thumbnails-item'>
          <a
            onClick={callForModal.bind(null, item.previewUrl)}
            className='sheet__thumbnails-thumbnail button'
            style={{ backgroundImage: `url("${item.previewUrl}")` }}
          >
            {cancel}
          </a>
          <span className='sheet__thumbnails-quantity'>{item.quantity}</span>
          {size}
          <span className='sheet__thumbnails-title'>{item.decorationLocation}</span>
          {floodColor}
        </div>
      </li>
    );
  });

  const cuts = {};
  for (const cutFile of cutFiles) {
    if (!cuts[cutFile.cutFileColor]) {
      cuts[cutFile.cutFileColor] = [];
    }
    cuts[cutFile.cutFileColor].push(cutFile);
  }

  const enums = Object.keys(cuts);
  const cutItems = [];

  for (let i = 0; i < enums.length; i += 1) {
    cutItems.push(
      <PrintSheetItemThumbnailGroup
        orderDeleted={orderDeleted}
        cutGroup={cuts[enums[i]]}
        keyIndex={i}
        key={i}
        cutColor={enums[i]}
        callForModal={callForModal}
        barcode={barcode}
        colors={colors}
      />
    );
  }

  return (
    <ul className='sheet__thumbnails'>
      {items}
      {Object.values(cutItems)}
    </ul>
  );
});

PrintSheetItemThumbnails.propTypes = {
  thumbnails: PropTypes.array,
  cutFiles: PropTypes.array,
  callForModal: PropTypes.func.isRequired,
  barcode: PropTypes.string,
  orderDeleted: PropTypes.bool,
  renderPrintSheet: PropTypes.bool.isRequired,
  colorsDictionary: PropTypes.objectOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    dtgCode: PropTypes.string,
    hexValue: PropTypes.string,
    cmykValue: PropTypes.string.isRequired,
    dtgCmykValue: PropTypes.string.isRequired,
    brightness: PropTypes.string.isRequired,
    threadValue: PropTypes.string,
  })).isRequired,
  colors: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    dtgCode: PropTypes.string,
    cmykValue: PropTypes.string.isRequired,
    dtgCmykValue: PropTypes.string.isRequired,
    hexValue: PropTypes.string,
    threadValue: PropTypes.string,
    brightness: PropTypes.string.isRequired,
    isArtwork: PropTypes.bool,
    isFlood: PropTypes.bool,
  })).isRequired,
};

export default PrintSheetItemThumbnails;
