import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { RootState } from '@redux/index/reducers';
import * as lockerManagerActions from '@redux/lockerManager/actions';
import * as productCatalogActions from '@redux/productCatalog/actions';
import * as supportActions from '@redux/support/actions';
import { connect, ConnectedProps } from 'react-redux';
import { decorationMethodEnum } from '@constants/enums/decorationEnums';
import { keyNameEnum } from '@constants/enums/commonEnums';
import ImagePreviewCell from '@components/shared/Table/TableCells/ImagePreviewCell';
import OrderManagementAddItemQuantity from './OrderManagementAddItemQuantity';
import ItemLocationAndSizeCell from '@components/shared/LockerItems/LockerItemsTableContent/ItemLocationAndSizeCell';
import { formatDollarAmount } from '@util/numberHelpers';
import OrderManagementItemLogos from './OrderManagementItemLogos';
import OrderManagementItemSizesNew from './OrderManagementItemSizesNew';
import { variationsToLockerProducts } from '@util/VariationToLockerProductHelper';
import Table from '@sharedComponents/Table/Table';
import ImagePreviewModal from '@components/shared/Modal/ImagePreviewModal';
import MultiSelectDropdown from '@sharedComponents/Inputs/Dropdowns/MultiSelectDropdown/MultiSelectDropdown';
import LogoDropdown from '@components/shared/LogoDisplay/LogoDropdown/LogoDropdown';
import SearchFilter from '@components/shared/Inputs/SearchFilter';
import { decorationMethodsOptions } from '@constants/options/optionsValues';
import { ProductDetailDto } from '@api/productCatalog/models';
import { LockerProductsCssLogoViewModel, LockerProductViewModel, LogoByLockerModel, TeamColorViewModel } from '@api/squadlockerServices/models';
import { LockerItemWithQuantity } from '@models/common/LockerItemWithQuantity';
import { Logo as LogoDto, Product, QueriesVariationDto as VariationDto } from '@api/orders/models';
import Spinner from '@sharedComponents/Display/Spinner';
import { VariationProductModel } from '@models/Products/VariationProductModel';
import { defaultPageSizeBigTable } from '@constants/values';
import { Column } from 'react-table-6';
import { useGetHomefieldApiVariations } from '@api/orders/variations';
import MaterialTooltip from '@sharedComponents/Tooltips/MaterialTooltip';

const ItemsTable = Table<LockerProductViewModel | VariationProductModel>();

interface OwnProps {
  isOpen: boolean;
  lockerId: number;
  allLockerProducts: Array<LockerProductViewModel>;
  lockerColorCodes: Array<string>;
  lockerLogos: Array<LogoByLockerModel>;
  isOrderItemAdd: boolean;
  onItemSelected: (selectedItem: Nullable<LockerItemWithQuantity>) => void;
  onSizeSelected: (selectedSize: Nullable<{ sku: string; size: string }>) => void;
  onLogoSelected: (selectedLogo: Nullable<LogoDto | LockerProductsCssLogoViewModel>) => void;
  teamColors: Array<TeamColorViewModel>;
  synced: boolean;
  synchingError: boolean;
  orderExistsOnCore: boolean;
}

const mapStateToProps = ({
  productCatalog,
  lockerManager,
  support,
  core,
}: RootState) => ({
  allLockerProducts: lockerManager.allLockerProducts,
  lockerColorCodes: lockerManager.lockerColors,
  lockerLogos: lockerManager.lockerLogos,
  teamColors: support.teamColors,
  coloredStyleProductsDictionary: productCatalog.coloredStyleProductsDictionary,
  allVariations: core.variationsQueue.queue,
});

const mapDispatchToProps = {
  fetchLockerColors: lockerManagerActions.fetchLockerColors,
  fetchLockerLogos: lockerManagerActions.fetchLockerLogos,
  getDecorationLocationArtworkSizes: productCatalogActions.getDecorationLocationArtworkSizes,
  fetchAllLockerProducts: lockerManagerActions.fetchAllLockerProducts,
  fetchTeamColors: supportActions.fetchTeamColors,
  getProductsForColoredStylesDictionary: productCatalogActions.getProductsForColoredStylesDictionary,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = OwnProps & ConnectedProps<typeof connector>;

const OrderManagementDetailsOrderItemListNew = React.memo<Props>(({
  isOpen,
  lockerId,
  allLockerProducts,
  lockerColorCodes,
  lockerLogos,
  isOrderItemAdd,
  onItemSelected,
  onSizeSelected,
  onLogoSelected,
  teamColors,
  coloredStyleProductsDictionary,
  synced,
  synchingError,
  fetchLockerColors,
  fetchLockerLogos,
  getDecorationLocationArtworkSizes,
  fetchAllLockerProducts,
  fetchTeamColors,
  getProductsForColoredStylesDictionary,
  orderExistsOnCore,
}) => {
  const [
    searchInput,
    setSearchInput,
  ] = useState<string>('');
  const [
    logos,
    setLogos,
  ] = useState<Array<number>>([]);
  const [
    colors,
    setColors,
  ] = useState<Array<string>>([]);
  const [
    decorations,
    setDecorations,
  ] = useState<Array<string>>([]);
  const [
    selectedItem,
    setSelecteditem,
  ] = useState<Nullable<LockerItemWithQuantity | VariationDto>>(null);
  const [
    selectedSize,
    setSelectedSize,
  ] = useState<{ sku: string; size: string } | null>(null);
  const [
    selectedLogo,
    setSelectedLogo,
  ] = useState<LogoDto | LockerProductsCssLogoViewModel | null>(null);
  const [
    imageUrl,
    setImageUrl,
  ] = useState<string | undefined>(undefined);
  const [
    imageModalIsOpen,
    setImageModalIsOpen,
  ] = useState<boolean>(false);
  const [
    lockerColors,
    setLockerColors,
  ] = useState<Array<TeamColorViewModel>>(
    teamColors?.filter(
      (c: TeamColorViewModel) => (lockerColorCodes || []).includes(c.code),
    ),
  );
  const [
    currentVariationsPage,
    setCurrentVariationsPage,
  ] = useState<number>(1);
  const [
    fetchedVariations,
    setFetchedVariations,
  ] = useState<Array<VariationDto>>([]);
  const [
    fetchedVariationProductDictionary,
    setFetchedVariationProductDictionary,
  ] = useState<Record<number, Array<Product>>>({});

  const {
    data: variationsPagedList,
    refetch: fetchVariations,
    status: fetchVariationsStatus,
  } = useGetHomefieldApiVariations({
    pageNumber: currentVariationsPage,
    pageSize: defaultPageSizeBigTable,
    salesChannelsId: [lockerId],
    search: searchInput,
    categoryId: [],
    colorGroup: colors,
    decorationLocation: [],
    decorationMethod: decorations,
    logoId: logos,
  }, {
    query: {
      enabled: false,
    },
  });

  useEffect(() => {
    const record: Record<number, Array<Product>> = {};

    fetchedVariations.forEach((variation) => {
      record[variation!.coloredStyleId!] = variation.products!;
    });
    setFetchedVariationProductDictionary(record);
  }, [
    allLockerProducts,
    fetchedVariations,
  ]);

  useEffect(() => {
    if (variationsPagedList?.items && synced && orderExistsOnCore) {
      setFetchedVariations([
        ...fetchedVariations,
        ...variationsPagedList.items,
      ]);
    }
    if (variationsPagedList?.hasNextPage && synced && orderExistsOnCore) {
      setCurrentVariationsPage(currentVariationsPage + 1);
    }
  }, [variationsPagedList, synced, orderExistsOnCore, fetchedVariations, currentVariationsPage]);

  useEffect(() => {
    if (synced && !variationsPagedList && orderExistsOnCore) {
      fetchVariations();
    }
  }, [currentVariationsPage, fetchVariations, synced, orderExistsOnCore, variationsPagedList]);

  const search = useCallback(async () =>
    fetchAllLockerProducts(
      lockerId,
      logos,
      colors,
      decorations,
      searchInput,
    ), [
    fetchAllLockerProducts,
    lockerId,
    logos,
    colors,
    decorations,
    searchInput,
  ]);

  const refresh = useCallback(async () => {
    if (synced) {
      search();
      fetchLockerColors(lockerId);
      fetchLockerLogos(lockerId);
      getDecorationLocationArtworkSizes();
    }
  }, [
    synced,
    search,
    fetchLockerColors,
    lockerId,
    fetchLockerLogos,
    getDecorationLocationArtworkSizes,
  ]);

  const getProductSizes = useCallback(() => {
    const coloredStyleIds = [
      ...new Set(allLockerProducts.map(
        (lockerProduct: LockerProductViewModel) => lockerProduct.colored_style_id,
      )),
    ];

    getProductsForColoredStylesDictionary(coloredStyleIds);
  }, [
    getProductsForColoredStylesDictionary,
    allLockerProducts,
  ]);

  useEffect(() => {
    fetchTeamColors();
  }, [fetchTeamColors]);

  useEffect(() => {
    if (lockerId !== null) {
      refresh();
    }
  }, [
    lockerId,
    refresh,
  ]);

  useEffect(() => {
    getProductSizes();
  }, [getProductSizes]);

  useEffect(() => {
    if (teamColors && lockerColorCodes) {
      const newLockerColors = teamColors.filter((c: TeamColorViewModel) => lockerColorCodes.includes(c.code));
      setLockerColors(newLockerColors);
    }
  }, [
    lockerColorCodes,
    teamColors,
  ]);

  useEffect(() => {
    search();
  }, [search]);

  useEffect(() => {
    if (!isOpen) {
      setFetchedVariations([]);
    }
  }, [isOpen]);

  const openImageModal = useCallback((image: string) => {
    setImageModalIsOpen(true);
    setImageUrl(image);
  }, []);

  const closeImageModal = useCallback(() => {
    setImageModalIsOpen(false);
    setImageUrl(undefined);
  }, []);

  const canSelectLogo = (item: LockerProductViewModel) => (
    item?.decoration_location !== decorationMethodEnum.NA
  );

  const selectSize = useCallback(async (size: { sku: string; size: string }, productId: number) => {
    if (selectedSize === size) {
      setSelectedSize(null);
      onSizeSelected(null);
    } else {
      let item = !orderExistsOnCore
        ? allLockerProducts.find((alp: LockerProductViewModel) => alp.id === productId)
        : fetchedVariations?.find((alp) => alp.itemId === productId);
      if (isOrderItemAdd) {
        item = {
          ...item,
          quantity: 1,
        };
      }

      const logo = canSelectLogo(item)
        ? orderExistsOnCore
          ? item.item.logos![0]
          : item.logos[0]
        : null;

      if (orderExistsOnCore && logo) {
        logo.image = logo?.logoUrl
          ? logo.logoUrl.split('/')[logo?.logoUrl.split('/').length - 2] + '/'
            + logo.logoUrl?.split('/')[logo?.logoUrl.split('/').length - 1]
          : '';
      }

      setSelecteditem(item);
      setSelectedSize(size);
      setSelectedLogo(logo);

      onSizeSelected(size);
      onItemSelected(item);
      onLogoSelected(logo);
    }
  }, [
    allLockerProducts,
    fetchedVariations,
    isOrderItemAdd,
    onItemSelected,
    onLogoSelected,
    onSizeSelected,
    selectedSize,
    orderExistsOnCore,
  ]);

  const selectLogo = useCallback((logo: LockerProductsCssLogoViewModel) => {
    if (orderExistsOnCore) {
      const selected = selectedItem as VariationDto;
      if (selected?.item?.logos?.some((il) => il.logoId === logo.id)) {
        setSelectedLogo(logo);
        onLogoSelected(logo);
      }
    } else {
      const selected = selectedItem as LockerItemWithQuantity;
      if (selected?.logos?.includes(logo)) {
        setSelectedLogo(logo);
        onLogoSelected(logo);
      }
    }
  }, [
    onLogoSelected,
    selectedItem,
    orderExistsOnCore,
  ]);

  const colorChange = useCallback((newColors: Array<string>) => {
    if (!newColors) {
      return;
    }
    setColors(newColors);
  }, []);

  const decorationChange = useCallback((newDecorations: Array<string>) => {
    if (!newDecorations) {
      return;
    }
    setDecorations(newDecorations);
  }, []);

  const logoChange = useCallback((newLogos: Array<number>) => {
    if (!newLogos) {
      return;
    }
    setLogos(newLogos);
  }, []);

  const clearSearch = useCallback(() => {
    setSearchInput('');
  }, []);

  const filterKey = useCallback((e) => {
    if (e.key && e.key !== keyNameEnum.Enter) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();

    const newSearchInput = e.target.value;

    setSearchInput(newSearchInput);
  }, []);

  const selectQuantity = useCallback((quantity: number) => {
    if (selectedItem) {
      setSelecteditem({
        ...selectedItem,
        quantity,
      });
    }
  }, [selectedItem]);

  const getColumns = (): Array<Column<LockerProductViewModel | VariationProductModel>> => {
    let columns: Array<Column<LockerProductViewModel | VariationProductModel>>;
    columns = [
      {
        Header: 'Image',
        accessor: 'cached_image',
        className: 'no-padding',
        width: 130,
        Cell: (cellProps) => (
          <ImagePreviewCell
            imageUrl={cellProps.value}
            openModalWithUrl={openImageModal}
          />
        ),
      },
      {
        Header: 'Code & Description',
        accessor: '',
        width: 280,
        Cell: (cellProps) => {
          const coloredStyleProducts = orderExistsOnCore
            ? fetchedVariationProductDictionary?.[cellProps.value.colored_style_id] ?? []
            : coloredStyleProductsDictionary?.[cellProps.value.colored_style_id] ?? [];
          const sizes = coloredStyleProducts.map((colorStyleProduct: ProductDetailDto) => ({
            sku: colorStyleProduct.sku,
            size: colorStyleProduct.size,
          }));

          return (
            <OrderManagementItemSizesNew
              product={cellProps.value}
              selectSize={selectSize}
              selectedSize={selectedSize}
              selectedItem={selectedItem}
              sizes={sizes}
              replaceOnCore={orderExistsOnCore}
            />
          );
        },
      },
    ];

    if (isOrderItemAdd) {
      columns = [
        ...columns,
        {
          Header: 'Qty',
          accessor: '',
          width: 80,
          Cell: (cellProps) => (
            <OrderManagementAddItemQuantity
              selectQuantity={selectQuantity}
              selectedQuantity={cellProps.value.quantity}
              item={cellProps.value}
              selectedItem={selectedItem}
            />
          ),
        },
      ];
    }

    columns = [
      ...columns,
      {
        Header: 'Color',
        accessor: 'color',
        width: 80,
      },
      {
        Header: 'Decoration',
        accessor: 'decoration_method',
        width: 120,
      },
      {
        Header: 'Location & Size',
        accessor: '',
        width: 170,
        Cell: (cellProps) => <ItemLocationAndSizeCell item={cellProps.value} />,
      },
      {
        Header: 'Price',
        id: 'price',
        accessor: (item) => item,
        width: 100,
        Cell: (cellProps) => (
          <MaterialTooltip
            tooltipText={(
              <>
                {cellProps.value.pricing
                  ? <div className='pre-line'>Base Price : ${cellProps.value.pricing.basePrice}</div>
                  : <div>Base Price : -</div>}
                {cellProps.value.pricing
                  ? <div className='pre-line'>Fundraising Amount : ${cellProps.value.pricing.fundraisingAmount}</div>
                  : <div>Fundraising Amount : -</div>}
                {cellProps.value.pricing
                  ? <div className='pre-line'>Locker Fundraising Percent : {cellProps.value.pricing.salesChannelFundraisingPercent}%</div>
                  : <div>Fundraising Percent : -</div>}
                {cellProps.value.pricing
                  ? <div className='pre-line'>Locker Discount Percent : {cellProps.value.pricing.salesChannelDiscountPercent}%</div>
                  : <div>Discount Percent : -</div>}
                {cellProps.value.pricing
                  ? <div className='pre-line'>Style Discount Amount : ${cellProps.value.pricing.styleDiscountAmount}</div>
                  : <div>Discount Amount : -</div>}
                {<div>&nbsp;</div>}
                {<div className='pre-line'>Price : {formatDollarAmount(cellProps.value.price / 100)}</div>}
              </>
            )}
            placement={'top'}
            // ContentClasses={}
          >
            <div>
              {formatDollarAmount(cellProps.value.price / 100)}
            </div>
          </MaterialTooltip>
        ),
      },
      {
        Header: 'Select Logo',
        accessor: '',
        Cell: (cellProps) => (
          canSelectLogo(cellProps.value)
            ? (
              <OrderManagementItemLogos
                itemId={cellProps.value.id}
                replaceOnCoreFlag={orderExistsOnCore}
                logos={cellProps.value.logos}
                selectLogo={selectLogo}
                selectedLogo={selectedLogo}
                selectedItem={selectedItem}
              />
            )
            : (
              <div className='order-item__empty'>
                Unable to apply logo.
              </div>
            )
        ),
      },
    ];

    return columns;
  };

  const getTrProps = useCallback((rowInfo) => {
    if (!rowInfo) {
      return {};
    }

    return {
      className: `${rowInfo.original === selectedItem && 'is-active--light'}`,
    };
  }, [selectedItem]);

  const getAllLockerProductsWQ = useMemo(() => (
    isOrderItemAdd
      ? allLockerProducts.map((x: LockerProductViewModel) => {
        if (selectedItem && x.id === selectedItem.id) {
          return selectedItem;
        }

        return {
          ...x,
          quantity: 1,
        };
      })
      : orderExistsOnCore && Object.keys(fetchedVariationProductDictionary)?.length > 0
      ? variationsToLockerProducts(fetchedVariations).filter(
        (lp) => fetchedVariationProductDictionary?.[lp.colored_style_id]?.length > 0,
      )
      : allLockerProducts
  ), [
    isOrderItemAdd,
    allLockerProducts,
    fetchedVariations,
    selectedItem,
    fetchedVariationProductDictionary,
    orderExistsOnCore,
  ]);

  return (
    <div>
      {isOpen
        && (
          <div>
            <ImagePreviewModal
              modalIsOpen={imageModalIsOpen}
              closeModal={closeImageModal}
              imageUrl={imageUrl}
            />
            <div className='logo-bank__filters'>
              <div className='flex'>
                {lockerColors
                  && (
                    <MultiSelectDropdown<any, 'code'>
                      objects={lockerColors}
                      itemText={'colors'}
                      updateCallback={colorChange}
                      textKey={'code'}
                      valueKey={'code'}
                      color={true}
                    />
                  )}
                {decorationMethodsOptions
                  && (
                    <MultiSelectDropdown<any, 'value'>
                      objects={decorationMethodsOptions}
                      itemText={'decorations'}
                      updateCallback={decorationChange}
                      textKey={'name'}
                      valueKey={'value'}
                      classNames={'margin-left'}
                    />
                  )}
                {lockerLogos
                  && (
                    <LogoDropdown
                      logos={lockerLogos}
                      updateCallback={logoChange}
                      className='margin-left'
                    />
                  )}
                <SearchFilter
                  search={filterKey}
                  classes={'ml-15'}
                  clearSearch={clearSearch}
                />
              </div>
            </div>
            {(
              <div className='sheet'>
                {synced
                  && (fetchVariationsStatus === 'idle' || fetchVariationsStatus === 'success' || !orderExistsOnCore)
                  && (
                    <ItemsTable
                      columns={getColumns()}
                      data={getAllLockerProductsWQ}
                      showPagination={false}
                      getTrProps={getTrProps}
                      classNames={'scroll-table-m'}
                    />
                  )}
              </div>
            )}
            {!synced && !synchingError && orderExistsOnCore
              && (
                <div>
                  <Spinner
                    size={'50px'}
                  />
                  <div>Synching ...</div>
                </div>
              )}
            {synced && fetchVariationsStatus === 'loading' && orderExistsOnCore
              && (
                <div>
                  <Spinner
                    size={'50px'}
                  />
                  <div>Loading products ...</div>
                </div>
              )}
            {(fetchVariationsStatus === 'error' || synchingError) && orderExistsOnCore
              && (
                <div className='warning-msg--text'>
                  Error occured while synching or fetching products.
                </div>
              )}
          </div>
        )}
    </div>
  );
});

export default connector(OrderManagementDetailsOrderItemListNew);
