/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Accounts Homefield API
 * OpenAPI spec version: 1.0
 */

export interface StagingStoreDetailsDto {
  id: number;
  storeName?: string | null;
  homefieldUrl?: string | null;
  userCompleted: boolean;
}
