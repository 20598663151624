import React, { useCallback, useEffect, useRef, useState } from 'react';
import BackLink from '@components/shared/Navigation/BackLink';
import DropZoneWrapper from '@components/shared/Upload/DropZoneWrapper';
import { lockerLogoBankUrl } from '@constants/clientUrls/clientUrls';
import { makeStyles } from '@material-ui/styles';
import { extractParameterFromPath } from '@util/stringHelpers';
import { Delete } from '@material-ui/icons';
import { Button, Grid } from '@material-ui/core';
import { uploadLogoToLockerLogos } from '@APICalls/lockerManager/actions';
import LogoApproval from './LogoApproval';
import { fetchLogoWithoutDispatch } from '@redux/logos/actions';
import { connect } from 'react-redux';
import { selectionTypeEnum, servicesApiDecorationMethods } from '@constants/enums/decorationEnums';
import { updateLogo } from '@APICalls/logos/actions';
import { materialSwal } from '@util/componentHelper';
import * as supportActions from '@redux/support/actions';

const useStyles = makeStyles(() => ({
  mainContainer: {
    position: 'relative',
    width: '100%',
    margin: 16,
  },
  uploadLogoWrapper: {
    width: '100%',
  },
  textField: {
    minWidth: 'initial !important',
  },
  pointer: {
    cursor: 'pointer',
  },
}));

const clearSessionStorage = () => {
  Object.keys(sessionStorage).forEach((key) => {
    if (key.startsWith('logoApproval_')) {
      sessionStorage.removeItem(key);
    }
  });
};

const LogoBulkUpload = ({ colors, artworkColors, allDarkColors, allLightColors, fetchColors, ...props }) => {
  const [pendingUpload, setPendingUpload] = useState([]);
  const [uploadedLogos, setUploadedLogos] = useState([]);
  const [hasCopiedConfiguration, setHasCopiedConfiguration] = useState(false);
  const [copiedIndex, setCopiedIndex] = useState(null);

  const classes = useStyles();

  const logoRef = useRef();

  const lockerId = extractParameterFromPath(props, 'lockerId', 'number');

  useEffect(() => {
    fetchColors();
    clearSessionStorage();
  }, [fetchColors]);

  const handleStorageUpdate = useCallback((index) => {
    setHasCopiedConfiguration(true);
    setCopiedIndex(index);
  }, []);

  const handleDrop = useCallback((acceptedFiles) => {
    const newPendingUpload = [...pendingUpload];
    newPendingUpload.push(...acceptedFiles);
    const dupesRemoved = Array.from(new Map(newPendingUpload.map((file) => [file.name, file])).values());
    setPendingUpload(dupesRemoved);
  }, [pendingUpload]);

  const handleFileDeleteClick = useCallback((index) => () => {
    const newPendingUpload = [...pendingUpload];
    newPendingUpload.splice(index, 1);
    setPendingUpload(newPendingUpload);
  }, [pendingUpload]);

  const handleUploadFiles = useCallback(async () => {
    const currentLogos = [];
    for (const file of pendingUpload) {
      const res = await uploadLogoToLockerLogos(lockerId, [file]);
      currentLogos.push(res);
    }

    const logoData = [];
    for (const logo of currentLogos) {
      const res = await fetchLogoWithoutDispatch(logo.result);
      logoData.push(res);
    }
    setUploadedLogos(logoData);
  }, [lockerId, pendingUpload]);

  const handleSaveLogos = useCallback(async () => {
    clearSessionStorage();

    const promises = [];
    for (const logo of logoRef.current) {
      const currentLogo = logo.getData();
      const artwork = currentLogo.artworkColorsData;
      const approvedColors = currentLogo.approvedColorsData;
      const decoMethods = currentLogo.decoData;
      const floodColorIds = Array.from(
        new Set([
          ...approvedColors.approvedLightColors.map((c) => c.id),
          ...approvedColors.approvedDarkColors.map((c) => c.id),
        ]),
      );

      const customColors = [];
      const colorIds = [];
      const selectedMethods = [];

      const selectedcolors = artwork.selectedLogoColors
        .filter((c) => !artwork.deletedLogoColors.includes(c));

      for (const newColor of selectedcolors) {
        const selectedColor = colors.find((c) => c.code === newColor);
        if (selectedColor) {
          colorIds.push(selectedColor.id);
        } else {
          customColors.push(newColor);
        }
      }

      if (decoMethods.isHag) {
        selectedMethods.push(servicesApiDecorationMethods.HAG);
      }
      if (decoMethods.isEmb) {
        selectedMethods.push(servicesApiDecorationMethods.EMB);
      }
      if (decoMethods.isDtg) {
        selectedMethods.push(servicesApiDecorationMethods.DTG);
      }
      if (decoMethods.isDip) {
        selectedMethods.push(servicesApiDecorationMethods.DIP);
      }

      promises.push(updateLogo(
        currentLogo.id,
        selectionTypeEnum.UserOnly,
        colorIds,
        customColors,
        null,
        selectedMethods,
        floodColorIds,
      ));
    }

    const results = await Promise.all(promises);
    const success = results.every((res) => res?.success);
    if (success) {
      materialSwal('Success', 'All logos updated successfully', 'success');
      setTimeout(() => window.location.href = lockerLogoBankUrl(lockerId), 2000);
    } else {
      materialSwal('Error', 'There was an error updating the logos', 'error');
    }
  }, [colors, lockerId]);

  const setLogoRef = (index) => (el) => {
    if (!logoRef.current) {
      logoRef.current = [];
    }
    logoRef.current[index] = el;
  };

  const filePicker = () => (
    <>
      <Grid item={true} xs={12}>
        <div className='logo-bank__container'>
          <div className='upload-field' style={{ height: '10rem' }}>
            <DropZoneWrapper
              onDrop={handleDrop}
              className={'block-drop-zone'}
              multiple={true}
              accept={{ 'Logos': ['.png', '.jpg', '.jpeg'] }}
            >
              <div style={{ height: '10rem' }}>
                <label htmlFor='uploadFiles'>Drop files here or click to upload</label>
              </div>
            </DropZoneWrapper>
          </div>
        </div>
      </Grid>
      <Grid item={true} xs={12}>
        {pendingUpload.length > 0
          && (
            <ul className={classes.uploadLogoWrapper}>
              {[...pendingUpload].sort((a, b) => a.name.localeCompare(b.name)).map((file, index) => (
                <li key={index} className='upload-field--horizontal upload-field--big'>
                  <label htmlFor='uploadFiles'>{file.name}</label>
                  <Delete className={classes.pointer} onClick={handleFileDeleteClick(index)} />
                </li>
              ))}
            </ul>
          )}
      </Grid>
      <Grid item={true} xs={12}>
        <Button variant='contained' color='primary' onClick={handleUploadFiles}>Upload Logos To Locker</Button>
      </Grid>
    </>
  );

  const logoContainer = () => (
    <Grid item={true} xs={12}>
      <Button variant='contained' color='primary' onClick={handleSaveLogos}>Save Logo(s)</Button>
      {uploadedLogos.length > 0
        && uploadedLogos.map((logo, index) => (
          <LogoApproval
            colors={colors}
            artworkColors={artworkColors}
            allDarkColors={allDarkColors}
            allLightColors={allLightColors}
            logo={logo}
            index={index}
            key={index}
            ref={setLogoRef(index)}
            hasCopiedConfiguration={hasCopiedConfiguration}
            handleStorageUpdate={handleStorageUpdate}
            copiedIndex={copiedIndex}
          />
        ))}
      <Button variant='contained' color='primary' onClick={handleSaveLogos}>Save Logo(s)</Button>
    </Grid>
  );

  return (
    <>
      <Grid container={true} className={classes.mainContainer}>
        <Grid item={true} xs={12} style={{ marginBottom: 16 }}>
          <BackLink
            text={`Locker L${lockerId}`}
            url={lockerLogoBankUrl(lockerId)}
          />
          <h1>Bulk Logo Utility</h1>
        </Grid>
        {uploadedLogos.length === 0 && filePicker()}
        {uploadedLogos.length > 0 && logoContainer()}
      </Grid>
    </>
  );
};

const mapStateToProps = ({
  support,
}) => ({
  colors: support.colors,
  artworkColors: support.artworkColors,
  allLightColors: support.lightColors,
  allDarkColors: support.darkColors,
});

const mapDispatchToProps = {
  fetchColors: supportActions.fetchColors,
};

export default connect(mapStateToProps, mapDispatchToProps)(LogoBulkUpload);
