/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */

export type SortByEnum = 'ShipDate' | 'LockerNumber' | 'TaskCount' | 'AssignedOn' | 'SentToQcOn' | 'ProductionReadyOn';


export const SortByEnum = {
  ShipDate: 'ShipDate' as SortByEnum,
  LockerNumber: 'LockerNumber' as SortByEnum,
  TaskCount: 'TaskCount' as SortByEnum,
  AssignedOn: 'AssignedOn' as SortByEnum,
  SentToQcOn: 'SentToQcOn' as SortByEnum,
  ProductionReadyOn: 'ProductionReadyOn' as SortByEnum,
};
