/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Financials Homefield API
 * OpenAPI spec version: v1
 */

export interface OrderDto {
  id?: number;
  uuid?: string | null;
  created?: string | null;
  updated?: string | null;
  deleted?: string | null;
  placed?: string;
  canceled?: boolean;
  orderNumber?: number;
  lockerId?: number | null;
  gpDocumentId?: string | null;
  rowVersion?: string | null;
}
