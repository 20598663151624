/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Catalog Homefield API
 * OpenAPI spec version: v1
 */

export interface VendorTimelineDto {
  id?: number | null;
  uuid?: string | null;
  created?: string | null;
  updated?: string | null;
  deleted?: string | null;
  description?: string | null;
  timelineDays?: number;
  vendorId?: number;
}
