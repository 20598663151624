import React, { useCallback, useEffect, useState } from 'react';
import OrderManagementOrderItemProduct from '@components/OrderManagement/OrderManagementDetails/OrderManagementDetailsModals/OrderManagementOrderItemProductNew';
import { VariationCatalogProductDetailModel } from '@models/Products/VariationCatalogProductDetailModel';

interface OwnProps {
  products: Array<VariationCatalogProductDetailModel>;
  logoImageUrl: string;
  skuInput: string;
  handleSelectedProductChange: (variationId: number) => void;
  handleChangeSkuInput: (newSkuInput: string) => void;
  isOpen: boolean;
  orderExistsOnCore: boolean;
}

type Props = OwnProps;

const QuickViewProduct = React.memo<Props>(({
  products,
  logoImageUrl,
  skuInput,
  handleSelectedProductChange,
  handleChangeSkuInput,
  isOpen,
  orderExistsOnCore,
}) => {
  const [
    onlyOneProduct,
    setOnlyOneProduct,
  ] = useState<boolean>(true);
  const [
    selectedProductInfo,
    setSelectedProductInfo,
  ] = useState<Nullable<{ productSku: string; salesChannelId: number }>>(null);
  const [
    selectedProductVariationId,
    setSelectedProductVariationId,
  ] = useState<Nullable<number | undefined>>(null);

  const getFilteredProducts = useCallback(() => products.filter((product) => product.sku === skuInput), [
    products,
    skuInput,
  ]);

  // Initial check for number of products
  useEffect(() => {
    if (products?.length > 1 && isOpen) {
      setOnlyOneProduct(false);
    }
  }, [
    isOpen,
    products?.length,
  ]);

  useEffect(() => {
    if (getFilteredProducts()?.length === 1 && isOpen) {
      setOnlyOneProduct(true);
    }
  }, [
    getFilteredProducts,
    isOpen,
    products,
    skuInput,
  ]);

  useEffect(() => {
    const sku = products?.find(
      (product) =>
        product.sku === selectedProductInfo?.productSku
        && product.salesChannelId === selectedProductInfo?.salesChannelId,
    )?.sku;
    if (sku && isOpen) {
      handleChangeSkuInput(sku);
    }
  }, [handleChangeSkuInput, isOpen, products, selectedProductInfo]);

  useEffect(() => {
    if (!isOpen) { return; }
    const selectedProduct = getFilteredProducts()[0];
    if (selectedProduct) {
      setSelectedProductInfo({
        productSku: selectedProduct!.sku!,
        salesChannelId: selectedProduct!.salesChannelId!,
      });
      setSelectedProductVariationId(selectedProduct.variationId);
    }
  }, [
    getFilteredProducts,
    handleChangeSkuInput,
    isOpen,
    products,
    skuInput,
  ]);

  useEffect(() => {
    handleSelectedProductChange(selectedProductVariationId!);
  }, [
    handleSelectedProductChange,
    selectedProductVariationId,
  ]);

  const selectProduct = useCallback((productSku: string, salesChannelId: number, variationId: number) => {
    setSelectedProductInfo({
      productSku,
      salesChannelId,
    });
    setSelectedProductVariationId(variationId);
  }, []);

  const renderProduct = useCallback((product: VariationCatalogProductDetailModel) => (
    <OrderManagementOrderItemProduct
      key={[
        product.sku!,
        product.salesChannelId!.toString(),
        product.variationId,
        product.created,
      ].toString()}
      product={product}
      logoImageUrl={logoImageUrl}
      borderize={true}
      isSelected={selectedProductInfo?.productSku === product.sku
        && selectedProductInfo?.salesChannelId === product.salesChannelId
        && selectedProductVariationId === product.variationId}
      pickingEnabled={!onlyOneProduct}
      selectProduct={selectProduct}
      orderExistsOnCore={orderExistsOnCore}
    />
  ), [
    logoImageUrl,
    onlyOneProduct,
    orderExistsOnCore,
    selectProduct,
    selectedProductInfo,
    selectedProductVariationId,
  ]);

  const filteredProducts = getFilteredProducts();

  return (
    <div>
      {isOpen
        && (
          <div>
            {filteredProducts.length > 0
              ? filteredProducts.map(renderProduct)
              : (
                <div className='order-item__error'>
                  <div className='error-text'>Product SKU not valid</div>
                  <span>Try a different code</span>
                </div>
              )}
          </div>
        )}
    </div>
  );
});

export default QuickViewProduct;
