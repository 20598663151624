/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Catalog Homefield API
 * OpenAPI spec version: v1
 */

export interface SyncEventsResultDto {
  totalCount?: number;
  brandCount?: number;
  parentCategoryCount?: number;
  categoryCount?: number;
  sportCount?: number;
  colorCount?: number;
  vendorCount?: number;
  styleCount?: number;
  styleSportCount?: number;
  coloredStyleCount?: number;
  productCount?: number;
}
