/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */
import type { DecorationSizeEnum } from './decorationSizeEnum';

export interface PersonalizationItem {
  text?: string | null;
  number?: string | null;
  colorId?: number | null;
  outlineColorId?: number | null;
  quantity?: number;
  decorationLocation?: string | null;
  decorationSize?: DecorationSizeEnum;
  decorationHeight?: number | null;
  decorationWidth?: number | null;
}
