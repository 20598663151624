/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Accounts Homefield API
 * OpenAPI spec version: 2.0
 */
import type { PlanogramType } from './planogramType';
import type { MerchandisingEffortStatus } from './merchandisingEffortStatus';

export interface MerchandisingEffortDto {
  id?: number;
  name?: string | null;
  startDate?: string;
  endDate?: string | null;
  type?: PlanogramType;
  sourceProductCount?: number;
  targetCount?: number;
  status?: MerchandisingEffortStatus;
}
