/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Orders Homefield API
 * OpenAPI spec version: v1
 */
import type { QueriesPersonalization } from './queriesPersonalization';

export interface SpreadsheetItemDto {
  salesChannelId?: number;
  variationId?: number;
  sku?: string | null;
  quantity?: number;
  pricePaid?: number;
  personalizations?: QueriesPersonalization[] | null;
}
