/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */
import type { OrderItemStatusEnum } from './orderItemStatusEnum';

export interface OrderItemDto {
  sku?: string | null;
  quantity?: number;
  status?: OrderItemStatusEnum;
}
