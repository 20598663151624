import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  pageSizeOptionsBigTable,
  defaultPageSizeBigTable,
} from '@constants/values';
import { sortDirectionEnum } from '@constants/enums/commonEnums';
import { submitStatusEnum } from '@constants/enums/orderEnums';
import { fetchScheduledOrdersTable } from '@redux/vendorOrders/actions';
import {
  getPagingParamsFromTable,
  getSortParamsFromTable,
} from '@util/tableHelpers';
import { parseDateNumeric } from '@util/dateHandler';
import { getSelectableTableRowProps } from '@util/selectionHelpers';
import Table from '@sharedComponents/Table/Table';
import HeaderCell from '@sharedComponents/Table/TableCells/HeaderCell';
import ScheduledVendorOrdersQuickView from '../VendorOrderQuickView/ScheduledVendorOrdersQuickView';

const VendorOrdersTable = Table();

class ScheduledVendorOrdersTab extends PureComponent {
  state = {
    selectedVendorOrder: null,
    sortOrder: '',
    sortBy: '',
  };

  componentDidUpdate(prevProps) {
    const {
      searchInput,
      vendorsFilter,
      statusFilter,
    } = this.props;

    if (prevProps.searchInput !== searchInput
      || prevProps.vendorsFilter !== vendorsFilter
      || prevProps.statusFilter !== statusFilter) {
      this.search();
    }
  }

  search = () => {
    const {
      dispatch,
      searchInput,
      vendorsFilter,
      statusFilter,
    } = this.props;

    let submitStatus = statusFilter;
    let canceled = false;
    if (statusFilter === 'Canceled') {
      canceled = true;
      submitStatus = null;
    }

    const {
      pageNumber,
      pageSize,
      sortBy,
      sortOrder,
    } = this.state;

    dispatch(fetchScheduledOrdersTable(
      pageNumber,
      pageSize,
      sortBy,
      sortOrder,
      searchInput,
      vendorsFilter,
      submitStatus,
      canceled
    ));
  };

  fetchData = (state, instance) => {
    const {
      sortBy,
      sortOrder,
    } = this.state;

    const {
      page,
      pageSize,
    } = getPagingParamsFromTable(instance);

    const {
      sortColumn,
      sortDirection,
    } = getSortParamsFromTable(instance, sortDirectionEnum, sortBy, sortOrder);

    this.setState({
      sortBy: sortColumn,
      sortOrder: sortDirection,
      pageNumber: page + 1,
      pageSize,
    }, this.search);
  };

  selectVendorOrder = (selectedVendorOrder) => {
    this.setState({ selectedVendorOrder });
  };

  unselectVendorOrder = () => {
    this.setState({ selectedVendorOrder: null });
  };

  refreshData = () => {
    this.unselectVendorOrder();
    this.search();
  };

  getTrProps = (state, rowInfo) => {
    const { selectedVendorOrder } = this.state;

    return getSelectableTableRowProps(this.selectVendorOrder, rowInfo, selectedVendorOrder, 'id');
  };

  getSubmitStatus = (submitStatus, canceled) => {
    if (canceled) {
      return 'Canceled';
    }

    switch (submitStatus) {
      case submitStatusEnum.Failed:
        return 'Failed';
      case submitStatusEnum.NotScheduled:
        return 'Not Scheduled';
      case submitStatusEnum.Pending:
        return 'Pending';
      case submitStatusEnum.Done:
        return 'Done';
      default:
        return '';
    }
  };

  getColumns = () => {
    const { vendors } = this.props;

    const columns = [
      {
        Header: <HeaderCell text={'ID'} />,
        accessor: 'id',
        minWidth: 60,
        sortable: true,
        Cell: (cellProps) => cellProps.value && <span>{cellProps.value}</span>,
      },
      {
        Header: <HeaderCell text={'Vendor'} />,
        accessor: 'vendorId',
        minWidth: 60,
        sortable: true,
        Cell: (cellProps) => (cellProps.value && vendors.find((x) => x.code === cellProps.value))
          && <span><b>{vendors.find((x) => x.code === cellProps.value).name || ''}</b></span>,
      },
      {
        Header: 'Orders',
        accessor: 'ordersCount',
        minWidth: 40,
        Cell: (cellProps) => <span>{cellProps.value || 0}</span>,
      },
      {
        Header: 'VFIs',
        accessor: 'itemsCount',
        minWidth: 40,
        Cell: (cellProps) => <span>{cellProps.value || 0}</span>,
      },
      {
        Header: <HeaderCell text={'Vendor Order ID'} />,
        accessor: 'vendorOrderId',
        minWidth: 60,
        sortable: true,
        Cell: (cellProps) => cellProps.value && <span>{cellProps.value}</span>,
      },
      {
        Header: 'Submit Status',
        accessor: '',
        minWidth: 75,
        Cell: (cellProps) => cellProps.value?.submitStatus &&
          <span>{this.getSubmitStatus(cellProps.value.submitStatus, cellProps.value.canceled)}</span>,
      },
      {
        Header: 'Vendor Status',
        accessor: 'vendorStatus',
        minWidth: 75,
        Cell: (cellProps) => cellProps.value && <span>{cellProps.value}</span>,
      },
      {
        Header: <HeaderCell text={'Submission Date'} />,
        accessor: 'created',
        minWidth: 75,
        sortable: true,
        Cell: (cellProps) => <span>{parseDateNumeric(cellProps.value)}</span>,
      },
    ];

    return columns;
  };

  render() {
    const {
      queue,
      hasNextPage,
      hasPreviousPage,
      totalPages,
    } = this.props;

    const { selectedVendorOrder } = this.state;

    return (
      <div className='container'>
        <div className='master-detail'>
          <div className='lockerManager__master'>
            <div className='sheet'>
              <VendorOrdersTable
                data={queue}
                columns={this.getColumns()}
                onFetchData={this.fetchData}
                totalPages={totalPages}
                hasNextPage={hasNextPage}
                hasPreviousPage={hasPreviousPage}
                defaultPageSize={defaultPageSizeBigTable}
                pageSizeOptions={pageSizeOptionsBigTable}
                getTrProps={this.getTrProps}

                tableName={'Scheduled Orders'}
              />
            </div>
          </div>
          {
            selectedVendorOrder &&
            <ScheduledVendorOrdersQuickView
              vendorOrder={selectedVendorOrder}
              closeDetails={this.unselectVendorOrder}
              refreshData={this.refreshData}
            />
          }
        </div>
      </div>
    );
  }
}

ScheduledVendorOrdersTab.propTypes = {
  totalPages: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
  hasPreviousPage: PropTypes.bool,
  hasNextPage: PropTypes.bool,
  pageNumber: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  queue: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    vendorOrderId: PropTypes.string,
    vendorId: PropTypes.string.isRequired,
    created: PropTypes.string.isRequired,
    updated: PropTypes.string.isRequired,
    canceled: PropTypes.string,
    status: PropTypes.string,
    submitStatus: PropTypes.string,
    itemsCount: PropTypes.number.isRequired,
    ordersCount: PropTypes.number.isRequired,
  })).isRequired,
  searchInput: PropTypes.string,
  vendorsFilter: PropTypes.arrayOf(PropTypes.string),
  statusFilter: PropTypes.string,
  vendors: PropTypes.arrayOf(PropTypes.object),
};

const mapStateToProps = ({
  vendorOrders,
  productCatalog,
}) => ({
  totalPages: vendorOrders.scheduledOrdersQueue.totalPages,
  totalCount: vendorOrders.scheduledOrdersQueue.totalCount,
  hasPreviousPage: vendorOrders.scheduledOrdersQueue.hasPreviousPage,
  hasNextPage: vendorOrders.scheduledOrdersQueue.hasNextPage,
  pageNumber: vendorOrders.scheduledOrdersQueue.pageNumber,
  queue: vendorOrders.scheduledOrdersQueue.queue,
  pageSize: vendorOrders.scheduledOrdersQueue.pageSize,
  vendors: productCatalog.vendors,
});

export default connect(mapStateToProps)(ScheduledVendorOrdersTab);
