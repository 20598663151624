/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Orders Homefield API
 * OpenAPI spec version: v1
 */
import type { NewPaymentTypeEnum } from './newPaymentTypeEnum';

export interface OrderChangeDto {
  amount?: number;
  reason?: string | null;
  orderId?: number;
  orderItemSku?: string | null;
  type?: NewPaymentTypeEnum;
  user?: string | null;
  dateTime?: string | null;
}
