/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Catalog Homefield API
 * OpenAPI spec version: v1
 */

export interface CreateEnvironmentCommand {
  environmentName?: string | null;
  note?: string | null;
  chokePercent?: number | null;
  underbasePercent?: number | null;
  highlightPercent?: number | null;
  highlightGenerator?: string | null;
  fuzzinessPercent?: number | null;
  colorStrengthPercent?: number | null;
  colorBoosterPercent?: number | null;
  platenPlacementX?: number | null;
  platenPlacementY?: number | null;
  palletSizeName?: string | null;
  palletSizeWidth?: number | null;
  palletSizeHeight?: number | null;
}
