import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import FormError from '@sharedComponents/Form/FormError';
import Input from '@sharedComponents/Form/Input';
import { featureFlags } from '@constants/common';

const ScanItemInputs = React.memo(({
  fields,
  meta: { error },
  ...props
}) => {
  const itemCodeCallbackRef = useCallback((itemCodeInput) => {
    if (itemCodeInput) {
      itemCodeInput.focus();
    }
  }, []);

  const onItemCodeChange = useCallback((itemCode) => {
    const targetName = itemCode.target.name;
    const targetValueLength = itemCode.target.value.length;
    const maxItemCodeLength = +featureFlags.maxItemCodeLength;

    if (targetName === `deviceAssociations[${fields.length - 1}].itemCode` && targetValueLength === maxItemCodeLength) {
      fields.push({
        deviceCode: props.deviceCode,
        itemCode: '',
      });
    }
  }, [fields, props.deviceCode]);

  return (
    <div>
      {fields.map((field, index) => (
        <div key={index}>
          <Field
            name={`${field}.itemCode`}
            component={Input}
            placeholder={'Scan or Enter an Item Code'}
            type={'text'}
            onChange={onItemCodeChange}
            callbackRef={itemCodeCallbackRef}
          />

          <div className='mt-20'>
            <FormError
              error={error}
              enableMultiple={true}
            />
          </div>
        </div>
      ))}
    </div>
  );
});

ScanItemInputs.propTypes = {
  fields: PropTypes.array.isRequired,
  error: PropTypes.object.isRequired,
  deviceCode: PropTypes.string.isRequired,
};

export default ScanItemInputs;
