/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Financials Homefield API
 * OpenAPI spec version: v1
 */
import type { PayoutTypeEnum } from './payoutTypeEnum';
import type { PayoutStatusEnum } from './payoutStatusEnum';
import type { SubmitStatusEnum } from './submitStatusEnum';
import type { SortByEnum } from './sortByEnum';
import type { SortDirectionEnum } from './sortDirectionEnum';

export type GetHomefieldApiPayoutsParams = { PageNumber?: number; PageSize?: number; PayoutType?: PayoutTypeEnum; Status?: PayoutStatusEnum; AccountingSubmitStatus?: SubmitStatusEnum; SortBy?: SortByEnum; SortDirection?: SortDirectionEnum; Search?: string };
