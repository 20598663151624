import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { decorationMethodEnum, servicesApiDecorationMethods } from '@constants/enums/decorationEnums';
import { keyNameEnum } from '@constants/enums/commonEnums';
import { toggleArrayMember } from '@util/componentHelper';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import CheckButton from '@sharedComponents/Inputs/CheckButton';
import Button from '@sharedComponents/Buttons/Button';
import LogoColorsDropdown from './LogoColorsDropdown';
import SelectableSwatchColorField from './SelectableSwatchColorField';

class ColorDetectionEditSwatches extends Component {
  state = {
    selectedLogoColors: this.props.logoColors || [],
    newLogoColors: [],
    deletedLogoColors: [],
    customColorsInputValue: '',
    modalIsOpen: false,
    isHag: this.props.allDecorationsActive || this.props.decorationMethod.includes(decorationMethodEnum.HAG),
    isEmb: this.props.allDecorationsActive || this.props.decorationMethod.includes(decorationMethodEnum.EMB),
    isDtg: this.props.allDecorationsActive || this.props.decorationMethod.includes(decorationMethodEnum.DTG),
  };

  componentDidUpdate(oldProps) {
    const { logoColors } = this.props;
    if (logoColors && logoColors !== oldProps.logoColors) {
      this.setState(() => ({
        selectedLogoColors: logoColors,
      }));
    }
  }

  getUnusedArtworkColors = () => {
    const { artworkColors } = this.props;
    const { selectedLogoColors } = this.state;

    return artworkColors.filter((c) => !selectedLogoColors.includes(c.code));
  };

  colorsHaveChanges = () => {
    const {
      newLogoColors,
      deletedLogoColors,
    } = this.state;

    return newLogoColors.length > 0 || deletedLogoColors.length > 0;
  };

  deleteColorSwatch = (color) => {
    const {
      selectedLogoColors,
      newLogoColors,
      deletedLogoColors,
    } = this.state;

    if (newLogoColors.includes(color)) {
      this.setState(() => ({
        selectedLogoColors: toggleArrayMember(selectedLogoColors, color),
        newLogoColors: toggleArrayMember(newLogoColors, color),
      }));
    } else {
      this.setState(() => ({
        deletedLogoColors: toggleArrayMember(deletedLogoColors, color),
      }));
    }
  };

  addColorSwatches = (colors) => {
    const {
      selectedLogoColors,
      newLogoColors,
    } = this.state;

    this.setState(() => ({
      selectedLogoColors: selectedLogoColors.concat(colors),
      newLogoColors: newLogoColors.concat(colors),
    }));
  };

  updateLogo = () => {
    const {
      selectedLogoColors,
      deletedLogoColors,
      isHag,
      isEmb,
      isDtg,
    } = this.state;

    const {
      saveLogoColors,
      showDecorationCheckbox,
    } = this.props;

    const colors = selectedLogoColors.filter((c) => !deletedLogoColors.includes(c));
    if (showDecorationCheckbox) {
      const selectedMethods = [];
      if (isHag) {
        selectedMethods.push(servicesApiDecorationMethods.HAG);
      }
      if (isEmb) {
        selectedMethods.push(servicesApiDecorationMethods.EMB);
      }
      if (isDtg) {
        selectedMethods.push(servicesApiDecorationMethods.DTG);
      }
      saveLogoColors(colors, selectedMethods);
    } else {
      saveLogoColors(colors);
    }
  };

  addCustomColor = () => {
    const {
      newLogoColors,
      selectedLogoColors,
      customColorsInputValue,
    } = this.state;

    const colors = customColorsInputValue.split(',');

    const updatedNewLogoColors = newLogoColors;
    const updatedSelectedLogoColors = selectedLogoColors;
    for (const color of colors) {
      const colorName = color.trim();
      if (colorName === '') { continue; }

      if (!updatedSelectedLogoColors.includes(colorName)) {
        updatedSelectedLogoColors.push(colorName);
        updatedNewLogoColors.push(colorName);
      }
    }

    this.setState(() => ({
      selectedLogoColors: updatedSelectedLogoColors,
      newLogoColors: updatedNewLogoColors,
      customColorsInputValue: '',
    }));
  };

  handleEnterKeydown = (e) => {
    if (e.key === keyNameEnum.Enter) {
      this.addCustomColor();
    }
  };

  handleChangeCustomColorsInput = (e) => {
    const customColorsInputValue = e.target.value;
    this.setState(() => ({ customColorsInputValue }));
  };

  isAddingCustomColorsDisabled = () => {
    const { customColorsInputValue } = this.state;

    return customColorsInputValue.trim() === '';
  };

  toggleIsHag = () => {
    this.setState((prevState) => ({
      isHag: !prevState.isHag,
    }));
  };

  toggleIsEmb = () => {
    this.setState((prevState) => ({
      isEmb: !prevState.isEmb,
    }));
  };

  toggleIsDtg = () => {
    this.setState((prevState) => ({
      isDtg: !prevState.isDtg,
    }));
  };

  render() {
    const {
      cancel,
      logoUrl,
      saveAlwaysEnabled,
      allowCustomColors,
      showDecorationCheckbox,
      showLogo,
      colors,
    } = this.props;

    const {
      selectedLogoColors,
      newLogoColors,
      deletedLogoColors,
      customColorsInputValue,
      isEmb,
      isHag,
      isDtg,
    } = this.state;

    const selectableSwatches = selectedLogoColors
      ? selectedLogoColors.map((c, index) => (
        <SelectableSwatchColorField
          key={index}
          colorCode={c}
          isDeleted={deletedLogoColors.includes(c)}
          isAdded={newLogoColors.includes(c)}
          handleSelect={this.deleteColorSwatch}
          colors={colors}
        />
      ))
      : null;

    const existingColors = (selectedLogoColors || []).length > 0
      ? (
        <>
          <div className='color-swatches__container custom-scrollbar mb-20'>
            <div>
              {selectableSwatches}
            </div>
          </div>
          <div className='color-swatches__remove-color-info'>Click on an existing color to remove it</div>
          <hr className='color-swatches__divider' />
        </>
      )
      : null;

    return (
      <div>
        {showLogo
          && (
            <div className='color-detection__logo transparent'>
              <div
                className='color-detection__logo'
                style={{ backgroundImage: `url("${logoUrl}")` }}
              />
            </div>
          )}
        {existingColors}
        <div className='color-swatches__container'>
          <div className='color-swatches__input-container'>
            <div className='select-field'>
              <LogoColorsDropdown
                colors={this.getUnusedArtworkColors()}
                addColors={this.addColorSwatches}
              />
            </div>
            {allowCustomColors
              && (
                <div className='text-field'>
                  <input
                    type='text'
                    className='color-swatches__input'
                    placeholder='Add Custom Color (comma separated)'
                    onKeyDown={this.handleEnterKeydown}
                    value={customColorsInputValue}
                    onChange={this.handleChangeCustomColorsInput}
                  />
                </div>
              )}
          </div>
          {allowCustomColors
            && (
              <div className='color-swatches__add-btn-container'>
                <Button
                  type={'primary'}
                  text={'Add'}
                  onClick={this.addCustomColor}
                  disabled={this.isAddingCustomColorsDisabled()}
                />
              </div>
            )}
        </div>
        {showDecorationCheckbox
          && (
            <>
              <span className='uppercase'>decoration methods</span>
              <div className='color-swatches__checkboxes mb-30'>
                <CheckButton
                  disabled={false}
                  text={decorationMethodEnum.HAG}
                  checked={isHag}
                  onClick={this.toggleIsHag}
                />
                <CheckButton
                  disabled={false}
                  text={decorationMethodEnum.EMB}
                  checked={isEmb}
                  onClick={this.toggleIsEmb}
                />
                <CheckButton
                  disabled={false}
                  text={decorationMethodEnum.DTG}
                  checked={isDtg}
                  onClick={this.toggleIsDtg}
                />
              </div>
            </>
          )}

        <ModalButtons
          onClose={cancel}
          onConfirm={this.updateLogo}
          confirmBtnText={'Save'}
          confirmBtnDisabled={!saveAlwaysEnabled && !this.colorsHaveChanges() && (!isEmb && !isHag)}
        />
      </div>
    );
  }
}

ColorDetectionEditSwatches.propTypes = {
  decorationMethod: PropTypes.arrayOf(PropTypes.string),
  logoColors: PropTypes.arrayOf(PropTypes.string).isRequired,
  logoUrl: PropTypes.string,
  showLogo: PropTypes.bool,
  saveLogoColors: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  saveAlwaysEnabled: PropTypes.bool,
  allowCustomColors: PropTypes.bool,
  allDecorationsActive: PropTypes.bool.isRequired,
  showDecorationCheckbox: PropTypes.bool.isRequired,
  colors: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    dtgCode: PropTypes.string,
    cmykValue: PropTypes.string.isRequired,
    dtgCmykValue: PropTypes.string.isRequired,
    hexValue: PropTypes.string,
    threadValue: PropTypes.string,
    brightness: PropTypes.string.isRequired,
    isArtwork: PropTypes.bool,
    isFlood: PropTypes.bool,
  })),
  artworkColors: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    dtgCode: PropTypes.string,
    cmykValue: PropTypes.string.isRequired,
    dtgCmykValue: PropTypes.string.isRequired,
    hexValue: PropTypes.string,
    threadValue: PropTypes.string,
    brightness: PropTypes.string.isRequired,
    isArtwork: PropTypes.bool,
    isFlood: PropTypes.bool,
  })),
};

export default ColorDetectionEditSwatches;
