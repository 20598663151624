import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import expressIcon from '@assets/icon_express-production.svg';
import { parseDateNumeric } from '@util/dateHandler';
import { mapShippingCodeToName } from '@util/mappingHelper';
import { productionGuideUrl } from '@constants/common';
import CheckButton from '@sharedComponents/Inputs/CheckButton';
import InfoField from '@sharedComponents/Display/InfoField';
import Label from '@sharedComponents/Display/Label';
import { shippingAddressFromShippingInfo } from '@util/stringHelpers';

const ProductionAssemblyOrderHeaderDetails = React.memo(({
  order,
  shippingOptions,
  toggleStatusHistory,
  isStatusHistoryShown,
}) => {
  const shippingMethod = mapShippingCodeToName(order.shippingInfo.method, shippingOptions);
  const shippingAddress = shippingAddressFromShippingInfo(order.shippingInfo);
  const cartLocations = order.items?.map((item) => item.cartLocation)
    .filter((item, index, self) => item && self.indexOf(item) === index)
    .join(', ');

  return (
    <div className='order-assembly__header'>
      <div className='order-assembly__header--row align__left'>
        <InfoField
          label={'SOP Number'}
          fieldClass={'size-20'}
          value={order.gpDocumentId}
        />
        <InfoField
          label={'Status'}
          fieldClass={'size-25'}
          value={order?.status?.label}
        />
        <InfoField
          label={'Ship date'}
          fieldClass={'size-15'}
          value={(
            <div>
              {order.shipDate && parseDateNumeric(order.shipDate)}
              {
                order.isExpressProduction &&
                <i
                  className='fa'
                  style={{
                    width: '22px',
                    height: '14px',
                    marginLeft: '8px',
                    backgroundImage: `url("${expressIcon}")`,
                  }}
                />
              }
            </div>
          )}
        />
        <InfoField
          label={'Unique Items'}
          fieldClass={'size-15'}
          value={order.itemsUniqueCount}
        />
        <InfoField
          label={'Production Guide'}
          fieldClass={'size-25'}
          value={(
            <a
              href={productionGuideUrl}
              target='_blank'
              className='link'
              rel='noreferrer'
            >
              ProductionGuide.pdf
            </a>
          )}
        />
      </div>
      <div className='order-assembly__header--row align__left'>
        <InfoField
          label={'Shipping Method'}
          fieldClass={'size-20'}
          value={shippingMethod}
        />
        <InfoField
          label={(
            <div className='align__center'>
              <span>Shipping Address</span>
              {
                order.shippingInfo.poBox &&
                <Label
                  text={'PO Box'}
                  type={'lynch'}
                  classes={'ml-10'}
                />
              }
            </div>
          )}
          fieldClass={'size-30'}
          value={shippingAddress}
        />
        {
          cartLocations?.length > 0 &&
          <InfoField
            label={'Cart Locations'}
            fieldClass={'size-25'}
            value={cartLocations}
          />
        }
      </div>
      {
        order.productionNote &&
        <div className='order-assembly__header--row'>
          <InfoField
            label={'Production Note'}
            fieldClass={'size-width'}
            value={order.productionNote}
          />
        </div>
      }
      <div className='order-assembly__header--row'>
        <CheckButton
          checked={isStatusHistoryShown}
          onClick={toggleStatusHistory}
          text={'Show status history under each item'}
          clickableText={false}
        />
      </div>
    </div>
  );
});

ProductionAssemblyOrderHeaderDetails.propTypes = {
  order: PropTypes.object.isRequired,
  shippingOptions: PropTypes.arrayOf(PropTypes.shape({
    code: PropTypes.string.isRequired,
    display_name: PropTypes.string.isRequired,  
  })).isRequired,
  toggleStatusHistory: PropTypes.func.isRequired,
  isStatusHistoryShown: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ support }) => ({
  shippingOptions: support.shippingOptions,
});

export default connect(mapStateToProps)(ProductionAssemblyOrderHeaderDetails);
