/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Accounts Homefield API
 * OpenAPI spec version: 2.0
 */

export type PlanogramType = 'Locker' | 'SalesChannel';


export const PlanogramType = {
  Locker: 'Locker' as PlanogramType,
  SalesChannel: 'SalesChannel' as PlanogramType,
};
