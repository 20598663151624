import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { scanDeviceCodeForm } from '@constants/reduxForms';
import Input from '@sharedComponents/Form/Input';
import { Field } from 'redux-form';
import FormError from '@sharedComponents/Form/FormError';
import { keyNameEnum } from '@constants/enums/commonEnums';

const ScanDeviceCodeForm = React.memo(({
  openNextModal,
  error,
}) => {
  const deviceCodeCallbackRef = useCallback((deviceCodeInput) => {
    if (deviceCodeInput) {
      deviceCodeInput.focus();
    }
  }, []);

  const onKeyPress = useCallback((event) => {
    if (event.key === keyNameEnum.Enter) {
      event.preventDefault();
      openNextModal();
    }
  }, [openNextModal]);

  return (
    <form
      className='redux-form'
      onKeyPress={onKeyPress}
      id={scanDeviceCodeForm}
    >
      <div>
        <div className='ml-15 mr-10'>
          <div>
            <div className='redux-form__row'>
              <div className='redux-form__column--size-m'>
                <label className='redux-form__label'>
                    Device Code
                  <span className='required'>*</span>
                </label>
                <Field
                  name={'deviceCode'}
                  placeholder={'Scan or Enter a Device Code'}
                  component={Input}
                  type={'text'}
                  callbackRef={deviceCodeCallbackRef}
                />
              </div>
            </div>
          </div>
        </div>

        <FormError error={error} />
      </div>
    </form>
  );
});

ScanDeviceCodeForm.propTypes = {
  openNextModal: PropTypes.func.isRequired,
  error: PropTypes.object.isRequired,
};

export default ScanDeviceCodeForm;
