/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */
import type { HagPrintRoomJobPagedListDto } from './hagPrintRoomJobPagedListDto';

export interface HagPrintRoomJobPagedListDtoPagedList {
  pageNumber?: number;
  pageSize?: number;
  totalCount?: number;
  items?: HagPrintRoomJobPagedListDto[] | null;
  readonly totalPages?: number;
  readonly hasPreviousPage?: boolean;
  readonly hasNextPage?: boolean;
}
