import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import * as lockerManagerApiCalls from '@APICalls/lockerManager/actions';
import { getLockerTeamTypeOptions } from '@constants/options/options';
import { createOptionsList, generalOptions } from '@util/optionsMap';
import MaterialCheckbox from '@sharedComponents/Inputs/MaterialCheckbox';
import Modal from '@sharedComponents/Modal/Modal';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import Dropdown from '@sharedComponents/Inputs/Dropdowns/Dropdown';
import TextInput from '@sharedComponents/Inputs/TextInput';
import { RootState } from '@redux/index/reducers';
import LockerManagerAutocomplete from './LockerManagerAutocomplete';

interface OwnProps {
  isOpen: boolean;
  originalLockerId: number;

  sports: Array<any>;

  partners: Array<any>;
  initialPartnerId: number | null;
  initialPartnerTeamUid: string;
  closeModal: () => void;

  initialLockerOwner: any;
}

const mapDispatchToProps = {
  cloneLocker: lockerManagerApiCalls.cloneLocker,
};

const mapStateToProps = ({ lockerManager }: RootState) => ({
  initialPartnerId: lockerManager.lockerInfo.partnerId,
  initialPartnerTeamUid: lockerManager.lockerInfo.teamUID,
  sports: lockerManager.sportOptions,
  partners: lockerManager.partners,

  initialLockerOwner: lockerManager.lockerManagers.find((lm: any) => lm.isOwner),
});

const teamTypeOptions = generalOptions(getLockerTeamTypeOptions());

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = OwnProps & ConnectedProps<typeof connector> & RouteComponentProps;

const CloneLockerModal = React.memo<Props>(({
  isOpen,
  originalLockerId,
  sports,
  partners,
  initialPartnerId,
  initialPartnerTeamUid,
  closeModal,
  cloneLocker,
  initialLockerOwner,
}) => {
  const [
    newLockerName,
    setNewLockerName,
  ] = useState<string>('');

  const [
    teamType,
    setTeamType,
  ] = useState<string>('');

  const [
    sportId,
    setSportId,
  ] = useState<string>('');

  const [
    ignoreLogos,
    setIgnoreLogos,
  ] = useState<boolean>(false);

  const [
    ignoreCollections,
    setIgnoreCollections,
  ] = useState<boolean>(false);

  const [
    partnerId,
    setPartnerId,
  ] = useState<number | null>(initialPartnerId);

  const [
    partnerTeamUid,
    setPartnerTeamUid,
  ] = useState<string>(initialPartnerTeamUid);

  const [
    lockerManagerId,
    setLockerManagerId,
  ] = useState<number | null>(initialLockerOwner?.id);

  useEffect(() => {
    setPartnerId(initialPartnerId);
  }, [initialPartnerId]);

  useEffect(() => {
    setPartnerTeamUid(initialPartnerTeamUid);
  }, [initialPartnerTeamUid]);

  useEffect(() => {
    setLockerManagerId(initialLockerOwner?.id);
  }, [initialLockerOwner]);

  const toggleIgnoreLogos = useCallback(() => {
    setIgnoreLogos((prev) => !prev);
  }, []);

  const toggleIgnoreCollections = useCallback(() => {
    setIgnoreCollections((prev) => !prev);
  }, []);

  const updateLockerName = useCallback((e) => {
    setNewLockerName(e.target.value);
  }, []);

  const updateTeamType = useCallback((e) => {
    setTeamType(e.target.value);
  }, []);

  const updateSport = useCallback((e) => {
    setSportId(e.target.value);
  }, []);

  const updatePartnerId = useCallback((e) => {
    if (!e.target.value) {
      setPartnerId(null);
      setPartnerTeamUid('');
    } else {
      setPartnerId(e.target.value);
    }
  }, []);

  const updatePartnerTeamUid = useCallback((e) => {
    setPartnerTeamUid(e.target.value);
  }, []);

  const updateManagerSelected = useCallback((value) => {
    setLockerManagerId(value?.id);
  }, []);

  const submitIsEnabled = useMemo(() => newLockerName && teamType && sportId, [
    newLockerName,
    sportId,
    teamType,
  ]);

  const sportOptions = useMemo(() =>
    createOptionsList({
      list: sports,
      key: 'id',
      value: 'id',
      name: 'name',
      emptyOption: {
        disabled: true,
        name: 'Choose Sport',
      },
    }), [sports]);

  const partnerOptions = useMemo(() =>
    createOptionsList({
      list: partners,
      key: 'id',
      value: 'id',
      name: 'name',
      emptyOption: {
        disabled: false,
        name: 'No Partner',
      },
    }), [partners]);

  const resetProps = useCallback(() => {
    setNewLockerName('');
    setTeamType('');
    setSportId('');
    setIgnoreLogos(false);
    setIgnoreCollections(false);
    setPartnerId(initialPartnerId);
    setPartnerTeamUid(initialPartnerTeamUid);
  }, [initialPartnerId, initialPartnerTeamUid]);

  const onClose = useCallback(() => {
    resetProps();
    closeModal();
  }, [
    closeModal,
    resetProps,
  ]);

  const submitClone = useCallback(() => {
    cloneLocker(
      originalLockerId,
      newLockerName,
      teamType,
      sportId,
      ignoreLogos,
      ignoreCollections,
      partnerId,
      partnerTeamUid,
      lockerManagerId,
    );
    onClose();
  }, [
    cloneLocker,
    ignoreCollections,
    ignoreLogos,
    newLockerName,
    onClose,
    originalLockerId,
    sportId,
    teamType,
    partnerId,
    partnerTeamUid,
    lockerManagerId,
  ]);

  return (
    <Modal
      title={'Clone Locker'}
      modalHeight={'xl'}
      modalWidth={'m'}
      modalClass={'lockerManagerEdit__clone-modal'}
      isOpen={isOpen}
      closeModal={onClose}
      buttons={(
        <ModalButtons
          confirmBtnText={'Submit'}
          cancelBtnText={'Cancel'}
          onConfirm={submitClone}
          onClose={onClose}
          confirmBtnDisabled={!submitIsEnabled} // TODO check
        />
      )}
    >
      <>
        <TextInput
          placeholder={'Enter a New Name'}
          onChange={updateLockerName}
        />
        <div className='text-field'>
          <Dropdown
            options={teamTypeOptions as any}
            onChange={updateTeamType}
          />
        </div>
        <div className='text-field'>
          <Dropdown
            options={sportOptions as any}
            defaultValue={sportId}
            onChange={updateSport}
          />
        </div>
        <div className='text-field'>
          <Dropdown
            options={partnerOptions as any}
            defaultValue={partnerId}
            onChange={updatePartnerId}
          />
        </div>
        <TextInput
          placeholder={'Partner Team UID'}
          initialValue={partnerTeamUid}
          onChange={updatePartnerTeamUid}
          controlledValue={partnerTeamUid}
        />
        <LockerManagerAutocomplete
          defaultValue={initialLockerOwner}
          onManagerSelected={updateManagerSelected}
        />
        <div className='text-field'>
          <MaterialCheckbox
            text={'Ignore Logos'}
            checked={ignoreLogos}
            onClick={toggleIgnoreLogos}
          />
        </div>
        <div className='text-field'>
          <MaterialCheckbox
            text={'Ignore Collections'}
            checked={ignoreCollections}
            onClick={toggleIgnoreCollections}
          />
        </div>
      </>
    </Modal>
  );
});

export default withRouter(connector(CloneLockerModal));
