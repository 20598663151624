/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Financials Homefield API
 * OpenAPI spec version: v1
 */

export type PayoutMethodEnum = 'Undefined' | 'Check';


export const PayoutMethodEnum = {
  Undefined: 'Undefined' as PayoutMethodEnum,
  Check: 'Check' as PayoutMethodEnum,
};
