/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */
import type { OrderItemStatusEnumStatusModel } from './orderItemStatusEnumStatusModel';
import type { LogoItemDto } from './logoItemDto';
import type { PersonalizationItemDto } from './personalizationItemDto';

export interface QueriesOrderItemDto {
  id?: number;
  sku?: string | null;
  imageUrl?: string | null;
  imageUrlFront?: string | null;
  imageUrlRight?: string | null;
  imageUrlLeft?: string | null;
  imageUrlBack?: string | null;
  requiredItem?: boolean;
  lockerItemId?: number | null;
  inventoryAvailable?: boolean | null;
  promisedDate?: string | null;
  waterproofedStepRequired?: boolean;
  barcode?: string | null;
  cartLocation?: string | null;
  status?: OrderItemStatusEnumStatusModel;
  logos?: LogoItemDto[] | null;
  personalizations?: PersonalizationItemDto[] | null;
}
