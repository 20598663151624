import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import PermissionsEnum from '@constants/enums/permissionsEnum';
import {
  getProductAgeOptions,
  getProductGenderOptions,
  getTrueOrFalseOptions,
} from '@constants/options/options';
import {
  getCategories,
  getBrands,
  fetchVendorSuggestions,
} from '@redux/productCatalog/actions';
import {
  generalOptions,
  createOptionsList,
} from '@util/optionsMap';
import { parseStringToBoolean } from '@util/valueHelpers';
import Input from '@sharedComponents/Form/Input';
import Textarea from '@sharedComponents/Form/Textarea';
import Select from '@sharedComponents/Form/Select';
import AutocompleteInput from '@sharedComponents/Inputs/AutocompleteInput';
import ImagePreviewCell from '@sharedComponents/Table/TableCells/ImagePreviewCell';
import Icon from '@sharedComponents/Icons/Icon';
import DropZoneWrapper from '@sharedComponents/Upload/DropZoneWrapper';
import PermissionHandler from '@sharedComponents/Authorization/PermissionHandler';
import InventoryAlwaysAvailableCheckbox from './InventoryAlwaysAvailableCheckbox';

const acceptedFileType = { 'Custom Files': ['.pdf'] };
const productAgeOptions = generalOptions(getProductAgeOptions(false));
const productGenderOptions = generalOptions(getProductGenderOptions(false));
const trueOrFalseOptions = generalOptions(getTrueOrFalseOptions());
const newStoresOptions = generalOptions(getTrueOrFalseOptions('Available', 'Unavailable'));
const websiteVisibilityOptions = generalOptions(getTrueOrFalseOptions('Disabled', 'Enabled'));
const productAvailabilityOptions = generalOptions(getTrueOrFalseOptions('Discontinued', 'Available'));
const prdOnlyOptions = generalOptions(getTrueOrFalseOptions());

class BasicInfoTabContent extends PureComponent {
  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(getCategories());
    dispatch(getBrands());
  }

  displayVendor = (vendor) => `${vendor.code}`;

  fetchVendorSuggestions = (searchInput) => {
    const { dispatch } = this.props;

    dispatch(fetchVendorSuggestions(searchInput));
  };

  render() {
    const {
      disabled = {},
      sampleImage,
      categories,
      brands,
      vendors,
      vendorChanged,
      vendorId,
      loadingPdfFile,
      messagePdfFile,
      sizeChartUrl,
      removeSizeChartPdf,
      onDropSizeChartPdf,
      inventoryAlwaysAvailable,
      changeVendor,
      change,
    } = this.props;

    const selectedVendor = vendorId && { code: vendorId };

    const brandOptions = createOptionsList({
      list: brands,
      key: 'id',
      value: 'id',
      name: 'name',
      emptyOption: {
        name: 'None',
      },
    });
    const categoryOptions = createOptionsList({
      list: categories,
      key: 'id',
      value: 'id',
      name: 'name',
      emptyOption: {
        name: 'None',
      },
    });

    const dropzoneContentSizeChartPdf = loadingPdfFile
      ? (
        <label className='button'>
          <Icon
            fontAwesomeIcon={'spinner'}
            classes={'fa-spin'}
          />
          Uploading...
        </label>
      )
      : <label className={`button message-${messagePdfFile.type}`}>{messagePdfFile.body}</label>;

    return (
      <>
        <div className='redux-form__container'>
          <PermissionHandler permissions={PermissionsEnum.ProductCatalogEdit}>
            <div className='catalog-details__modal--row'>
              <div className='redux-form__column--size-xl'>
                <div className='tableView__details--header--title mb-30'>
                  <span>Basic Information</span>
                </div>
                <div className='redux-form__column--size-xl'>
                  <div className='ml-15 mr-30 mb-30'>
                    <div className='redux-form__row'>
                      <div className='redux-form__column--size-l'>
                        <label className='redux-form__label'>
                          Style Name
                          <span className='required'>*</span>
                        </label>
                        <Field
                          name={'name'}
                          placeholder={'Enter style name'}
                          component={Input}
                          type={'text'}
                          disabled={disabled.name}
                        />
                      </div>

                      <div className='redux-form__column--size-s ml-20'>
                        <label className='redux-form__label'>
                          Parent SKU
                          <span className='required'>*</span>
                        </label>
                        <Field
                          name={'code'}
                          placeholder={'Enter Parent SKU'}
                          component={Input}
                          type={'text'}
                          disabled={disabled.code}
                        />
                      </div>
                    </div>

                    <div className='redux-form__column--size-xl'>
                      <label className='redux-form__label'>
                        Description
                        <span className='required'>*</span>
                      </label>
                      <Field
                        name={'description'}
                        placeholder={'Enter style description'}
                        component={Textarea}
                        type={'text'}
                        disabled={disabled.description}
                      />
                    </div>

                    <div className='redux-form__row'>
                      <div className='redux-form__column--size-s'>
                        <label className='redux-form__label'>
                          Category
                        </label>
                        <Field
                          name={'category.id'}
                          component={Select}
                          disabled={disabled.category}
                          parse={Number}
                        >
                          {categoryOptions}
                        </Field>
                      </div>
                      <div className='redux-form__column--size-s ml-10'>
                        <label className='redux-form__label'>
                          Brand
                        </label>
                        <Field
                          name={'brand.id'}
                          component={Select}
                          disabled={disabled.brand}
                          parse={Number}
                        >
                          {brandOptions}
                        </Field>
                      </div>
                      <div className='redux-form__column--size-s ml-10'>
                        <label className='redux-form__label'>
                          Class ID
                        </label>
                        <Field
                          name={'classId'}
                          placeholder={'Enter Class ID'}
                          component={Input}
                          type={'text'}
                          disabled={disabled.classId}
                        />
                      </div>
                    </div>

                    <div className='redux-form__row'>
                      <div className='redux-form__column--size-s'>
                        <label className='redux-form__label'>
                          Gender
                        </label>
                        <Field
                          name={'gender'}
                          component={Select}
                          disabled={disabled.gender}
                        >
                          {productGenderOptions}
                        </Field>
                      </div>
                      <div className='redux-form__column--size-s'>
                        <label className='redux-form__label'>
                          Age
                        </label>
                        <Field
                          name={'age'}
                          component={Select}
                          disabled={disabled.age}
                        >
                          {productAgeOptions}
                        </Field>
                      </div>
                      <div className='redux-form__column--size-s'>
                        <label className='redux-form__label'>
                          Tax Code
                          <span className='required'>*</span>
                        </label>
                        <Field
                          name={'taxCode'}
                          type={'text'}
                          placeholder={'Enter Tax Code'}
                          component={Input}
                        />
                      </div>
                    </div>

                    <div className='redux-form__row-aligned'>
                      <div className='redux-form__column--size-s'>
                        <label className='redux-form__label'>
                          Price
                          <span className='required'>*</span>
                        </label>
                        <div className='redux-form__input-wrapper'>
                          <span className='icon-left'>$</span>
                          <Field
                            name={'price'}
                            placeholder={'00.00'}
                            component={Input}
                            type={'number'}
                            parse={Number}
                            disabled={disabled.price}
                            className={'w-100 has-icon'}
                          />
                        </div>
                      </div>
                      <div className='basic-info--column redux-form__column--size-s'>
                        <label className='redux-form__label'>
                          Discount
                        </label>
                        <div className='redux-form__input-wrapper'>
                          <span className='icon-left'>$</span>
                          <Field
                            name={'discount'}
                            placeholder={'00.00'}
                            component={Input}
                            type={'number'}
                            parse={Number}
                            disabled={disabled.discount}
                            className={'w-100 has-icon'}
                          />
                        </div>
                      </div>

                      <div className='basic-info--column redux-form__column--size-s'>
                        <div className='redux-form__input-wrapper'>
                          <div className='w-100 mt-10'>
                            <label className='redux-form__label'>
                              Waterproof
                            </label>
                            <Field
                              name={'waterproof'}
                              component={Select}
                              disabled={disabled.waterproof}
                              parse={parseStringToBoolean}
                            >
                              {trueOrFalseOptions}
                            </Field>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='redux-form__row'>
                      <div className='redux-form__column--size-l'>
                        <label className='redux-form__label'>
                          Size Chart (PDF)
                        </label>
                        {
                          sizeChartUrl
                            ? (
                              <div className='flex'>
                                <Icon materialIcon={'attach_file'} />
                                <a
                                  href={sizeChartUrl}
                                  className='catalog-details__link'
                                >
                                  {sizeChartUrl}
                                </a>
                                <Icon
                                  materialIcon={'cancel'}
                                  onClick={removeSizeChartPdf}
                                  classes={'catalog-details__remove'}
                                />
                              </div>
                            )
                            : (
                              <div className='redux-form__row'>
                                <div className='upload-field--horizontal m-t-16 w-100'>
                                  <div className='upload-field'>
                                    <DropZoneWrapper
                                      onDrop={onDropSizeChartPdf}
                                      className={'block-drop-zone'}
                                      multiple={false}
                                      accept={acceptedFileType}
                                    >
                                      {dropzoneContentSizeChartPdf}
                                    </DropZoneWrapper>
                                  </div>
                                </div>
                              </div>
                            )
                        }
                      </div>
                    </div>

                  </div>
                </div>
              </div>

              <div className='catalog-details__modal--container catalog-details__modal--column--image'>
                <div className='tableView__details--header--title mb-30'>
                  <span>Style preview</span>
                </div>
                <div className='catalog-details__image ml-10'>
                  <ImagePreviewCell
                    imageUrl={sampleImage}
                    height={'220px'}
                    width={'220px'}
                  />
                </div>
              </div>
            </div>
          </PermissionHandler>
        </div>

        <PermissionHandler permissions={PermissionsEnum.ProductCatalogEdit}>
          <div className='redux-form__column--size-l'>
            <div className='tableView__details--header--title mb-30'>
              <span>Vendors</span>
            </div>
            <div className='redux-form__column--size-xl'>
              <div className='ml-15 mr-30 mb-30'>
                <div className='redux-form__row-aligned'>
                  <div className='redux-form__column--size-m mr-15'>
                    <label className='redux-form__label'>
                      Vendor ID
                    </label>
                    <AutocompleteInput
                      suggestions={vendors}
                      fetchSuggestions={this.fetchVendorSuggestions}
                      displayItem={this.displayVendor}
                      displaySuggestionText={this.displayVendor}
                      selectedItem={selectedVendor}
                      selectItem={changeVendor}
                      placeholder={'Enter vendor ID'}
                      classes={'mb-10'}
                    />
                  </div>
                  <div className='redux-form__column--size-m mr-10'>
                    <label className='redux-form__label'>
                      Minimum Vendor Quantity
                      <span className='required'>*</span>
                    </label>
                    <Field
                      name={'qtyVendorMinimum'}
                      placeholder={'Enter minimum vendor quantity'}
                      component={Input}
                      type={'number'}
                      parse={Number}
                      disabled={disabled.qtyVendorMinimum || inventoryAlwaysAvailable}
                    />
                  </div>
                  <div className='mt-10'>
                    <InventoryAlwaysAvailableCheckbox
                      change={change}
                      disabled={disabled.inventoryAlwaysAvailable}
                    />
                  </div>
                </div>
                {
                  vendorChanged &&
                  <div className='redux-form__row-aligned'>
                    <div className='redux-form__column--size-xl mr-15'>
                      <div className='warning-msg'>
                        <Icon
                          materialIcon={'warning'}
                          classes={'mr-10'}
                        />
                        <div className='warning-msg--text'>
                          Changing the vendor will update primary vendor for all products of this style.
                        </div>
                      </div>
                    </div>
                  </div>
                }
                <div className='redux-form__row-aligned mt-10'>
                  <div className='redux-form__column--size-s'>
                    <label className='redux-form__label'>
                      VFI
                    </label>
                    <Field
                      name={'vendorFulfilledItem'}
                      component={Select}
                      disabled={disabled.vendorFulfilledItem}
                      parse={parseStringToBoolean}
                    >
                      {trueOrFalseOptions}
                    </Field>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </PermissionHandler>

        <PermissionHandler permissions={PermissionsEnum.ProductCatalogEdit}>
          <div className='redux-form__column--size-l'>
            <div className='tableView__details--header--title mb-30'>
              <span>Status</span>
            </div>
            <div className='redux-form__column--size-xl'>
              <div className='ml-15 mr-30 mb-30'>
                <div className='redux-form__row'>
                  <div className='redux-form__column--size-m'>
                    <label className='redux-form__label'>
                      Recommended
                    </label>
                    <Field
                      name={'recommended'}
                      component={Select}
                      disabled={disabled.recommended}
                      parse={parseStringToBoolean}
                    >
                      {trueOrFalseOptions}
                    </Field>
                  </div>
                  <div className='redux-form__column--size-m ml-20'>
                    <label className='redux-form__label'>
                      Available for New Stores
                    </label>
                    <Field
                      name={'availableForNewStores'}
                      component={Select}
                      disabled={disabled.availableForNewStores}
                      parse={parseStringToBoolean}
                    >
                      {newStoresOptions}
                    </Field>
                  </div>
                </div>
                <div className='redux-form__row'>
                  <div className='redux-form__column--size-m'>
                    <label className='redux-form__label'>
                      Website Visibility
                    </label>
                    <Field
                      name={'disabled'}
                      component={Select}
                      disabled={disabled.disabled}
                      parse={parseStringToBoolean}
                    >
                      {websiteVisibilityOptions}
                    </Field>
                  </div>

                  <div className='redux-form__column--size-m ml-20'>
                    <label className='redux-form__label'>
                      Product Availability
                    </label>
                    <Field
                      name={'discontinued'}
                      component={Select}
                      disabled={disabled.discontinued}
                      parse={parseStringToBoolean}
                    >
                      {productAvailabilityOptions}
                    </Field>
                  </div>
                </div>
                <div className='redux-form__row'>
                  <div className='redux-form__column--size-m'>
                    <label className='redux-form__label'>
                      PRD Only
                    </label>
                    <Field
                      name={'prdOnly'}
                      component={Select}
                      disabled={disabled.prdOnly}
                      parse={parseStringToBoolean}
                    >
                      {prdOnlyOptions}
                    </Field>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </PermissionHandler>
      </>
    );
  }
}

BasicInfoTabContent.propTypes = {
  disabled: PropTypes.object,
  sampleImage: PropTypes.string,
  categories: PropTypes.arrayOf(PropTypes.object).isRequired,
  brands: PropTypes.arrayOf(PropTypes.object).isRequired,
  vendors: PropTypes.arrayOf(PropTypes.object).isRequired,
  vendorChanged: PropTypes.bool,
  vendorId: PropTypes.string,
  loadingPdfFile: PropTypes.bool.isRequired,
  messagePdfFile: PropTypes.shape({
    type: PropTypes.string,
    body: PropTypes.string,
  }),
  sizeChartUrl: PropTypes.string,
  removeSizeChartPdf: PropTypes.func.isRequired,
  onDropSizeChartPdf: PropTypes.func.isRequired,
  changeVendor: PropTypes.func.isRequired,
  change: PropTypes.func,
  inventoryAlwaysAvailable: PropTypes.bool,
};

const mapStateToProps = ({ productCatalog }) => ({
  categories: productCatalog.categories,
  brands: productCatalog.brands,
  vendors: productCatalog.vendorsQueue.queue,
});

export default connect(mapStateToProps)(BasicInfoTabContent);
