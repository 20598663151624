/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */
import type { DecorationMethodEnum } from './decorationMethodEnum';
import type { OrderInventoryStatusEnumStatusModel } from './orderInventoryStatusEnumStatusModel';
import type { OrderStatusEnumStatusModel } from './orderStatusEnumStatusModel';

export interface WarehouseOrderPagedListDto {
  orderId?: number;
  orderNumber?: number;
  shipDate?: string;
  decorationMethods?: DecorationMethodEnum[] | null;
  inventoryStatus?: OrderInventoryStatusEnumStatusModel;
  partnerName?: string | null;
  orgPartnerName?: string | null;
  status?: OrderStatusEnumStatusModel;
  gpDocumentId?: string | null;
  qtyAvailableAwaitingInventory?: number;
  qtyWarehouseStatus?: number;
  qtyTotalInScheduling?: number;
  lockerId?: number | null;
  organizationId?: number | null;
  gpCustomerId?: string | null;
  hasRequiredItems?: boolean;
}
