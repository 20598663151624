/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */
import type { ShippingInfoDto } from './shippingInfoDto';
import type { LockerInfoDto } from './lockerInfoDto';

export interface NotScheduledOrderDto {
  id?: number;
  orderNumber?: number;
  allItemsCount?: number;
  created?: string;
  lockerId?: number | null;
  lockerTeamName?: string | null;
  organizationId?: number | null;
  organizationName?: string | null;
  shippingInfo?: ShippingInfoDto;
  vendorIds?: string[] | null;
  vfiItemIds?: number[] | null;
  lockers?: LockerInfoDto[] | null;
}
