/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */
import type { DecorationFlagTypeEnum } from './decorationFlagTypeEnum';

export type HomefieldApiPrintroomPrintAssemblyQueueFlagPUTBody = {
  printAssemblyQueueId?: number;
  ids?: number[];
  reason?: string;
  note?: string;
  flagType?: DecorationFlagTypeEnum;
  fileName?: string;
  File?: Blob;
};
