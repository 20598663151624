import { FormErrors } from 'redux-form';

export const validateDeviceCode = (
  values: any
): FormErrors<any, string[]> => {
  const errors: FormErrors<any, string[]> = {};

  if (!values.deviceCode) {
    errors.deviceCode = ['Device Code is required'];
  }

  return errors;
};

export const validateItemCodes = (values: any) => {
  const errors: { [key: string]: { [itemCode: string]: string[]; }[]; } = {};

  const itemCodeArrayErrors: { [itemCode: string]: string[]; }[] = [];
  const deviceAssociations = values.deviceAssociations || [];

  deviceAssociations.forEach((association: any, index: number) => {
    const itemCodeErrors: { [itemCode: string]: string[]; } = {};

    // Validate only the first input
    if (!association || !association.itemCode && index === 0) {
      itemCodeErrors.itemCode = ['Item Code is Required'];
      itemCodeArrayErrors[index] = itemCodeErrors;
    }

    if (itemCodeArrayErrors.length) {
      errors.deviceAssociations = itemCodeArrayErrors;
    }
  });

  return errors;
};
