import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Swiper from 'swiper';
import { slidesPerView } from '@constants/values';
import { tagNameEnum } from '@constants/enums/tagNameEnums';
import { keyNameEnum } from '@constants/enums/commonEnums';
import { updateArtworkFileTaskDetails } from '@redux/artworkFileTasks/actions';
import { taskStatusToEnum } from '@util/statusHandler';
import ArtworkTaskDetailsItems from './ArtworkTaskDetailsItems';

const ignoreTargetTagNamesOnKeyDown = [
  tagNameEnum.TextArea,
  tagNameEnum.Input,
];

class ArtworkTaskItemsContainer extends Component {
  state = {
    activePage: 1,
    swiper: null,
  };

  componentDidMount() {
    const {
      tasks,
      status,
      isCreatingTasks,
    } = this.props;

    if (!isCreatingTasks) {
      const swiper = new Swiper('.swiper-container', {
        slidesPerView: 'auto',
        observeSlideChildren: true,
        observer: true,
        navigation: {
          nextEl: '.details__arrow--next',
          prevEl: '.details__arrow--previous',
        },
      });

       
      this.setState({ swiper });

      let selectedItemFind = tasks.items[0];
      if (status) {
        const enumStatus = taskStatusToEnum(status);
        const foundItem = tasks.items.find((item) => item.status && item.status.status.toLowerCase() === enumStatus);
        if (foundItem) {
          selectedItemFind = foundItem;
        }
      }

      this.handleSelectItem(selectedItemFind);
      if (tasks && selectedItemFind) {
        const { items } = tasks;
        if (items) {
          const index = items.findIndex((item) => item.id === selectedItemFind.id);
          if (index >= 0) {
            swiper.slideTo(index);
          }
        }
      }

      document.addEventListener('keydown', this.handleKeyDown);
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown);
  }

  componentDidUpdate(prevProps) {
    const { swiper } = this.state;

    const {
      tasks,
      selectedItem,
    } = this.props;

    if (!tasks || !selectedItem || !swiper) return;

    if (selectedItem.id === prevProps.selectedItem.id) {
      const currentTaskIndex = tasks.items.findIndex((x) => x.id === selectedItem.id);
      const previousTaskIndex = prevProps.tasks.items.findIndex((x) => x.id === selectedItem.id);

      if (currentTaskIndex !== previousTaskIndex) {
        swiper.slideTo(currentTaskIndex);
      }

      return;
    }

    const { items } = tasks;
    if (items && swiper) {
      const index = items.findIndex((item) => item.id === selectedItem.id);

      if (index < 0) return;

      if (index < swiper.activeIndex || (index - swiper.activeIndex) >= 3 || index === swiper.activeIndex) {
        swiper.slideTo(index);
      }
    }
  }

  handleSelectItem = (task) => {
    const { showDetails } = this.props;
    showDetails(task);
  };

  handlePageChange = (pageNumber) => {
    const { activePage } = this.state;

    if (activePage !== pageNumber) {
      this.setState({
        activePage: pageNumber,
      });
    }
  };

  getLeftItemIndex = () => {
    const {
      tasks: { items },
      selectedItem,
    } = this.props;

    return items.findIndex((item) => item.id === selectedItem.id) - 1;
  };

  getRightItemIndex = () => {
    const {
      tasks: { items },
      selectedItem,
    } = this.props;

    return items.findIndex((item) => item.id === selectedItem.id) + 1;
  };

  handleKeyDown = (e) => {
    if (!e.key) return;

    if (ignoreTargetTagNamesOnKeyDown.includes(e.target.tagName)) {
      return;
    }

    const { swiper } = this.state;

    const {
      tasks: { items },
      showDetails,
    } = this.props;

    let index;
    if (e.key === keyNameEnum.ArrowLeft) {
      index = this.getLeftItemIndex();
    } else if (e.key === keyNameEnum.ArrowRight) {
      index = this.getRightItemIndex();
    } else {
      return;
    }

    if (index < 0 || index >= items.length) return;

    showDetails(items[index]);

    if (index < swiper.activeIndex || (index - swiper.activeIndex) >= slidesPerView - 1) {
      swiper.slideTo(index);
    }
  };

  render() {
    const {
      tasks,
      isCreatingTasks = false,
      toggleCreateTask,
      checkedTasks,
    } = this.props;
    const { selectedItem } = this.props;
    const size = tasks.items.length;

    return (
      <ArtworkTaskDetailsItems
        items={tasks.items}
        handleSelectItem={this.handleSelectItem}
        selectedTaskId={selectedItem.id}
        size={size}
        isCreatingTasks={isCreatingTasks}
        toggleCreateTask={toggleCreateTask}
        checkedTasks={checkedTasks}
      />
    );
  }
}

ArtworkTaskItemsContainer.propTypes = {
  tasks: PropTypes.object.isRequired,
  selectedItem: PropTypes.object.isRequired,
  status: PropTypes.string,
  showDetails: PropTypes.func.isRequired,
  isCreatingTasks: PropTypes.bool,
  toggleCreateTask: PropTypes.func,
  checkedTasks: PropTypes.array,
};

const mapStateToProps = ({ artworkFileTasks }) => ({
  tasks: artworkFileTasks.artworkFileTasks,
  selectedItem: artworkFileTasks.artworkFileTaskDetails,
});

const matchDispatchToProps = (dispatch) => (
  bindActionCreators({
    showDetails: updateArtworkFileTaskDetails,
  }, dispatch)
);

export default connect(mapStateToProps, matchDispatchToProps)(ArtworkTaskItemsContainer);
