import React, { useCallback } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { RootState } from '@redux/index/reducers';
import { checkPermissions, roleMapping } from '@util/roleCheck';

interface OwnProps extends RouteProps<string> {
  roles: string[];
  user: any;
  permissions: any;
  component: any;
}

const mapStateToProps = ({ oidc }: RootState) => ({
  user: oidc?.user?.profile,
  roles: roleMapping(oidc),
});

const connector = connect(mapStateToProps);

type Props = OwnProps & ConnectedProps<typeof connector>;

const PrivateRoute = React.memo(({
  roles,
  user,
  permissions,
  component: Component,
  ...options
}: Props) => {
  const renderRoute = useCallback((props) =>
    user && (
      checkPermissions(roles, permissions)
        ? (
          <Component
            {...props}
            {...options}
          />
        )
        : <Redirect to='/' />
    ), [Component, options, permissions, roles, user]);

  return (
    <Route
      {...options}
      render={renderRoute}
    />
  );
});

export default connector(PrivateRoute);
