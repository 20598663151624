import React, { useCallback, useState } from 'react';
import { OrderItemFileJobItem } from '@api/fulfillment/models/orderItemFileJobItem';
import ItemInfoModal from '@components/shared/ItemInfo/ItemInfoModal';
import Image from '@sharedComponents/Display/Image';
import Tag from '@components/shared/Display/Tag';
import Button from '@components/shared/Buttons/Button';
import { UploadFileHandlerType } from './OrderItemFilesSharedTypes';

const getFileName = (url: string) => {
  const parts = url.split('/');

  return parts[parts.length - 1];
};

type Props = {
  jobId: number;
  orderNumber: number;
  item: OrderItemFileJobItem;
  onUpload: UploadFileHandlerType;
};

export const OrderItemFileJobDetailsItem: React.FC<Props> = ({ jobId, item, onUpload }) => {
  const [
    isItemModalOpen,
    setIsItemModalOpen,
  ] = useState(false);
  const openItemModal = useCallback(() => setIsItemModalOpen(true), []);
  const closeItemModal = useCallback(() => setIsItemModalOpen(false), []);

  const [file, setFile] = useState<File | null>(null);

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = useCallback((e) => {
    setFile(e?.target?.files?.[0] ?? null);
  }, []);

  const handleUpload = useCallback(() => {
    if (file) {
      onUpload([
        {
          jobId,
          orderItemId: item.orderItemId!,
          file,
        },
      ]);
    }
  }, [file, item.orderItemId, jobId, onUpload]);

  const uploadSingleLabel = `${jobId}-${item.orderItemId}-single-upload`;

  return (
    <div
      key={item.orderItemId}
      className={'order__item'}
    >
      <ItemInfoModal
        isOpen={isItemModalOpen}
        closeModal={closeItemModal}
        sku={item.sku}
      />
      <div className='order__item-column scheduling__details--content__items-image'>
        <Image
          url={item.previewUrl}
          showImageInModal={true}
          classes='image order__item-thumbnail button'
          openInModal={openItemModal}
        />
      </div>
      <div className='order__item-column'>
        {/* <div className={'order__item--sku-info'}>
          <div className='flex'>
            <span>{item.sku}</span>
          </div>
        </div> */}
        <div className='order__item--style-name'>{item.sku ?? ''}</div>
        <ul className='order__item-tags'>
          <li>
            <Tag
              label={'Item ID'}
              value={item.orderItemId}
            />
          </li>
          <li>
            <Tag
              label={'Design ID'}
              value={item.designId}
            />
          </li>
          <li>
            <Tag
              label={'Text'}
              value={item.text}
            />
          </li>
          <li>
            <Tag
              label={'Number'}
              value={item.number}
            />
          </li>
        </ul>
        <br />
        <div className={'order__item--sku-info'}>
          <div className='flex'>
            <span>Uploaded Files</span>
          </div>
        </div>
        <ul>
          {item.uploaded?.map((filePath) => (
            <li key={filePath}>
              <a href={filePath} target='_blank' rel='noreferrer'>{getFileName(filePath)}</a>
            </li>
          )) ?? 'No Files'}
        </ul>
        <br />
        <label htmlFor={uploadSingleLabel}>Upload Single File:</label>
        <input type='file' id={uploadSingleLabel} onChange={handleChange} />
        <Button
          type={'primary'}
          text={'Upload File'}
          onClick={handleUpload}
          disabled={!file}
        />
      </div>
    </div>
  );
};
