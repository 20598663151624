/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */
import type { ArtworkTaskItemCreateDto } from './artworkTaskItemCreateDto';

export interface CreateArtworkTaskCommand {
  items?: ArtworkTaskItemCreateDto[] | null;
}
