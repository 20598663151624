/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Financials Homefield API
 * OpenAPI spec version: v1
 */
import type { OrderItemPayoutStatusEnum } from './orderItemPayoutStatusEnum';

export interface OrderItemDto {
  id?: number;
  uuid?: string | null;
  created?: string | null;
  updated?: string | null;
  deleted?: string | null;
  orderId?: number;
  readonly orderNumber?: number | null;
  lockerId?: number;
  storefrontId?: number | null;
  sku?: string | null;
  styleName?: string | null;
  canceled?: boolean;
  fundraisingAmount?: number;
  status?: OrderItemPayoutStatusEnum;
  payoutId?: number | null;
  payoutAmount?: number | null;
  rowVersion?: string | null;
}
