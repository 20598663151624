/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */
import type { HagPrintRoomJobStatusEnum } from './hagPrintRoomJobStatusEnum';
import type { HagPrintRoomJobTypeEnum } from './hagPrintRoomJobTypeEnum';

export type GetHomefieldApiHagPrintroomclaimedstatusParams = { status?: HagPrintRoomJobStatusEnum; statuses?: HagPrintRoomJobStatusEnum[]; targetMomentInTimeStatus?: HagPrintRoomJobStatusEnum; jobType?: HagPrintRoomJobTypeEnum };
