import React from 'react'
import LinearProgress, { LinearProgressProps } from "@material-ui/core/LinearProgress";
import { withStyles } from "@material-ui/core/styles";

const StyledLinearProgress = withStyles({
  root: {

  },
  barColorPrimary: {
    backgroundColor: 'rgb(25,135,84)',
  },
  barColorSecondary: {
    backgroundColor: 'rgb(255,193,7)',
  }
})(LinearProgress);

const Progress: React.FC<LinearProgressProps> = (props) => {
  return <StyledLinearProgress {...props} variant="determinate" />;
};

export default Progress;