import { SubmissionError } from 'redux-form';
import { toast, ToastOptions } from 'react-toastify';
import { errorSeverityEnum } from '@constants/enums/commonEnums';
import { ApiResponse } from '@models/common/ApiResponse';
import { materialSwal } from './componentHelper';
import store from 'store';
import { endBlockingRequest } from '@redux/api/actions';

interface ErrorInfo {
  message?: string;
  title?: string;
  severity?: keyof typeof errorSeverityEnum;
}

export const parseError = async (error: any) => {
  let err = error;
  if (error.response) {
    if (error.response.data) {
      try {
        err = await error.response.data;
      } catch (e) {
        console.error(e);
      }

      return err;
    }

    try {
      err = await error.response.json();
    } catch (e) {
      console.error(e);
    }
  }

  return err;
};

const capitalize = (s: string) => {
  if (typeof s !== 'string') { return ''; }

  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const getErrorSeverity = (error: any): (typeof errorSeverityEnum)[keyof typeof errorSeverityEnum] => {
  if (!error.response || !error.response.status) {
    return errorSeverityEnum.error;
  }

  const { status } = error.response;

  if (status >= 400 && status < 500) {
    return errorSeverityEnum.warning;
  }

  return errorSeverityEnum.error;
};

export const getErrorInfo = (error: any, fullError: any): ErrorInfo => {
  const severity = getErrorSeverity(fullError);
  const errorInfo: ErrorInfo = {};
  errorInfo.message = error.message || 'Something went wrong';
  errorInfo.title = capitalize(severity);
  errorInfo.severity = severity;

  if (fullError.message && fullError.message !== error.message) {
    errorInfo.title = fullError.message;
  }

  return errorInfo;
};

export const handleApiError = async (error: any): Promise<ReturnType<typeof materialSwal>> => {
  if (!error) {
    return materialSwal('Error', 'Something went wrong', 'error');
  }

  const err = await parseError(error);
  const errorInfo: ErrorInfo = getErrorInfo(err, error);
  console.error(err);

  return materialSwal(errorInfo.title ?? 'Error', errorInfo.message, errorInfo.severity!);
};

export const handleApiSubmissionError = async (e: any, mappingCallback?: any): Promise<void> => {
  const error = await parseError(e);

  if (!error.result?.data) {
    handleApiError(error);

    return;
  }

  const validationErrors = error.error.result?.data || {};
  if (mappingCallback) {
    throw new SubmissionError(mappingCallback(error, validationErrors));
  }

  throw new SubmissionError({
    _error: error.message,
    ...validationErrors,
  });
};

export const handleApiErrorWithToast = async (
  error: any,
  successMessage: string,
  position = 'bottom-left',
  autoClose = 3000,
): Promise<ReturnType<typeof toast.error>> => {
  if (!error) {
    return toast.success(successMessage);
  }

  const err = await parseError(error);
  const errorInfo = getErrorInfo(err, error);
  console.error(err);

  return toast.error(errorInfo.message, {
    position,
    autoClose,
  } as ToastOptions);
};

export const makeApiCall = async <T>(call: Promise<T>): Promise<T | undefined> => {
  try {
    return await call;
  } catch (e) {
    store.dispatch(endBlockingRequest());
    console.error(e);
  }
};

export const makeApiCallWithErrorModal = async <T extends ApiResponse = ApiResponse>(
  call: Promise<T>,
): Promise<T | AwaitReturnType<typeof handleApiError> | undefined> => {
  try {
    return await call;
  } catch (e) {
    store.dispatch(endBlockingRequest());
    handleApiError(e);
  }
};

export const makeApiCallWithErrorToast = async <T extends ApiResponse = ApiResponse>(
  call: Promise<T>,
  successMessage: string,
  toastPosition: string,
  autoClose: number,
): Promise<T | undefined> => {
  try {
    return await call;
  } catch (error) {
    store.dispatch(endBlockingRequest());
    handleApiErrorWithToast(error, successMessage, toastPosition, autoClose);
  }
};

export const makeApiCallWithSubmissionError = async <T extends ApiResponse = ApiResponse>(
  call: Promise<T>,
  mappingCallback?: any,
): Promise<T | void> => {
  try {
    return await call;
  } catch (e) {
    const error = await parseError(e);
    store.dispatch(endBlockingRequest());

    if (!error.result) {
      handleApiError(error);

      return;
    }

    const validationErrors = error.result || {};
    if (mappingCallback) {
      throw new SubmissionError(mappingCallback(error, validationErrors));
    }

    throw new SubmissionError({
      _error: error.message,
      ...validationErrors,
    });
  }
};

export const makeApiCallWithCallback = async <T extends ApiResponse = ApiResponse>(
  call: Promise<T>,
  onSuccess: any,
  onError: any,
): Promise<void> => {
  try {
    const res: any = await call;
    if (res?.success) {
      onSuccess(res);
    }

    return res;
  } catch (e) {
    const error = await parseError(e);
    store.dispatch(endBlockingRequest());
    onError(error);
  }
};

export const makeMultipleApiCallsWithCallback = <T extends ApiResponse = ApiResponse>(
  ...calls: Promise<T>[]
) =>
async (onSuccess: any, onError: any): Promise<T[] | undefined> => {
  try {
    const responses: T[] = await Promise.all(calls);
    if (responses?.every((x: any) => x.success)) {
      onSuccess(responses);
    }

    return responses;
  } catch (e) {
    const error = await parseError(e);
    store.dispatch(endBlockingRequest());
    onError(error);
  }
};

export const isApiResponse = (obj: unknown): obj is ApiResponse => {
  if (!obj || typeof obj !== 'object') {
    return false;
  }

  if (!('message' in obj) || !('success' in obj)) {
    return false;
  }

  const {
    message,
    success,
  } = obj as ApiResponse;

  if (typeof (message ?? 'test') !== 'string') {
    return false;
  }

  if (typeof (success ?? true) !== 'boolean') {
    return false;
  }

  return true;
};
