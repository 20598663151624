/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Catalog Homefield API
 * OpenAPI spec version: v1
 */
import type { DtgEnvironmentDto } from './dtgEnvironmentDto';

export interface DtgEnvironmentDtoPagedList {
  pageNumber?: number;
  pageSize?: number;
  totalCount?: number;
  items?: DtgEnvironmentDto[] | null;
  readonly totalPages?: number;
  readonly hasPreviousPage?: boolean;
  readonly hasNextPage?: boolean;
}
