/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */

export type PrepressJobOrderIndicatorEnum = 'Undefined' | 'BlueCircle' | 'RedCircle' | 'GreenCircle' | 'YellowCircle' | 'BlueSquare' | 'RedSquare' | 'GreenSquare' | 'YellowSquare' | 'BlueTriangle' | 'RedTriangle' | 'GreenTriangle' | 'YellowTriangle' | 'BlueMinus' | 'RedMinus' | 'GreenMinus' | 'YellowMinus' | 'BluePlus' | 'RedPlus' | 'GreenPlus' | 'YellowPlus';


export const PrepressJobOrderIndicatorEnum = {
  Undefined: 'Undefined' as PrepressJobOrderIndicatorEnum,
  BlueCircle: 'BlueCircle' as PrepressJobOrderIndicatorEnum,
  RedCircle: 'RedCircle' as PrepressJobOrderIndicatorEnum,
  GreenCircle: 'GreenCircle' as PrepressJobOrderIndicatorEnum,
  YellowCircle: 'YellowCircle' as PrepressJobOrderIndicatorEnum,
  BlueSquare: 'BlueSquare' as PrepressJobOrderIndicatorEnum,
  RedSquare: 'RedSquare' as PrepressJobOrderIndicatorEnum,
  GreenSquare: 'GreenSquare' as PrepressJobOrderIndicatorEnum,
  YellowSquare: 'YellowSquare' as PrepressJobOrderIndicatorEnum,
  BlueTriangle: 'BlueTriangle' as PrepressJobOrderIndicatorEnum,
  RedTriangle: 'RedTriangle' as PrepressJobOrderIndicatorEnum,
  GreenTriangle: 'GreenTriangle' as PrepressJobOrderIndicatorEnum,
  YellowTriangle: 'YellowTriangle' as PrepressJobOrderIndicatorEnum,
  BlueMinus: 'BlueMinus' as PrepressJobOrderIndicatorEnum,
  RedMinus: 'RedMinus' as PrepressJobOrderIndicatorEnum,
  GreenMinus: 'GreenMinus' as PrepressJobOrderIndicatorEnum,
  YellowMinus: 'YellowMinus' as PrepressJobOrderIndicatorEnum,
  BluePlus: 'BluePlus' as PrepressJobOrderIndicatorEnum,
  RedPlus: 'RedPlus' as PrepressJobOrderIndicatorEnum,
  GreenPlus: 'GreenPlus' as PrepressJobOrderIndicatorEnum,
  YellowPlus: 'YellowPlus' as PrepressJobOrderIndicatorEnum,
};
