/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Catalog Homefield API
 * OpenAPI spec version: v1
 */

export type ProductGenderEnum = 'Undefined' | 'M' | 'F' | 'Unisex';


export const ProductGenderEnum = {
  Undefined: 'Undefined' as ProductGenderEnum,
  M: 'M' as ProductGenderEnum,
  F: 'F' as ProductGenderEnum,
  Unisex: 'Unisex' as ProductGenderEnum,
};
