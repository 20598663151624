/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */

export type HagTransmissionImageStatusEnum = 'Undefined' | 'Sending' | 'Processing' | 'Printing' | 'Done' | 'Canceled' | 'Failed';


export const HagTransmissionImageStatusEnum = {
  Undefined: 'Undefined' as HagTransmissionImageStatusEnum,
  Sending: 'Sending' as HagTransmissionImageStatusEnum,
  Processing: 'Processing' as HagTransmissionImageStatusEnum,
  Printing: 'Printing' as HagTransmissionImageStatusEnum,
  Done: 'Done' as HagTransmissionImageStatusEnum,
  Canceled: 'Canceled' as HagTransmissionImageStatusEnum,
  Failed: 'Failed' as HagTransmissionImageStatusEnum,
};
