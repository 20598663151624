import React, { useCallback, useState } from 'react';
import Box from '@material-ui/core/Box';
import { useGetHomefieldApiV2MerchandisingAll } from '@api/accounts/merchandising';
import { Button, makeStyles, Paper, ThemeProvider } from '@material-ui/core';
import { DataGrid, GridCellParams, GridColDef, GridSortModel, GridValueFormatterParams } from '@material-ui/data-grid';
import enumHelper from '@constants/enums/enumHelper';
import { merchandisingEffortStatusEnum } from '@constants/enums/merchandisingEffortEnums';
import { muiTheme } from '@constants/values';
import Link from '@components/shared/Navigation/Link';
import EditMerchandisingEffortDialog from './EditMerchandisingEffortDialog';
import PermissionHandler from '@components/shared/Authorization/PermissionHandler';
import PermissionsEnum from '@constants/enums/permissionsEnum';

const useStyles = makeStyles((theme) => ({
  titleBox: {
    marginBottom: theme.spacing(2),
  },
}));

const MerchandisingEffortsTable = () => {
  const classes = useStyles();
  const [createModalIsOpen, setCreateModalIsOpen] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(25);
  const [sortModel, setSortModel] = React.useState<GridSortModel>(
    [
      {
        field: 'startDate',
        sort: 'desc',
      },
    ],
  );

  const openCreateModal = useCallback(() => {
    setCreateModalIsOpen(true);
  }, [setCreateModalIsOpen]);

  const closeCreateModal = useCallback(() => {
    setCreateModalIsOpen(false);
  }, [setCreateModalIsOpen]);

  const { data: efforts, isLoading, refetch } = useGetHomefieldApiV2MerchandisingAll(
    {
      pageNumber: 1,
      pageSize: 5000,
    },
    { query: { enabled: true } },
  );

  const handleCreateFormSubmit = useCallback(() => {
    refetch();
  }, [refetch]);

  const handleSetPageNumber = useCallback((newPageNumber: number) => {
    setPageNumber(newPageNumber + 1);
  }, []);

  const handleSortModelChange = useCallback((newSortModel: GridSortModel) => {
    if (JSON.stringify(newSortModel) !== JSON.stringify(sortModel)) {
      setSortModel(newSortModel);
    }
  }, [sortModel]);

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      description: 'ID',
      type: 'number',
      align: 'center',
      minWidth: 90,
      headerAlign: 'center',
      renderCell: (params: GridCellParams) => (
        <>
          <Link
            style={{
              fontWeight: 'bold',
              color: '#607d8b',
              textDecoration: 'underline',
            }}
            url={`/merchandising/${params.value}`}
            text={params.value as string}
          />
        </>
      ),
    },
    {
      field: 'name',
      headerName: 'Name',
      description: 'Name',
      flex: 1,
    },
    {
      field: 'startDate',
      headerName: 'Start Date',
      description: 'Start Date',
      minWidth: 145,
      type: 'date',
      valueFormatter: (params: GridValueFormatterParams) => new Date(params.value as string).toLocaleDateString(),
    },
    {
      field: 'endDate',
      headerName: 'End Date',
      description: 'End Date',
      minWidth: 140,
      type: 'date',
      valueFormatter: (params: GridValueFormatterParams) => params.value ? new Date(params.value as string).toLocaleDateString() : '-',
    },
    {
      field: 'status',
      headerName: 'Status',
      description: 'Status',
      minWidth: 120,
      valueFormatter: (params: GridValueFormatterParams) =>
        params.value
          ? enumHelper.getDisplayName(
            merchandisingEffortStatusEnum,
            enumHelper.getValueFromDisplayName(
              merchandisingEffortStatusEnum,
              params.value as string,
            ),
          )
          : '-',
      sortComparator: enumHelper.getSortComparator(merchandisingEffortStatusEnum),
    },
    {
      field: 'type',
      headerName: 'Type',
      description: 'Type',
      minWidth: 120,
    },
    {
      field: 'sourceProductCount',
      headerName: 'Product Count',
      description: 'Product Count',
      minWidth: 170,
    },
    {
      field: 'targetCount',
      headerName: 'Target Count',
      description: 'Target Count',
      minWidth: 160,
    },
  ];

  return (
    <ThemeProvider theme={muiTheme}>
      <Box display='flex' flexDirection='column' alignItems='center' style={{ width: '90rem' }}>
        <Box className={classes.titleBox} style={{ alignSelf: 'flex-end' }}>
          <PermissionHandler
            permissions={PermissionsEnum.MerchandisingEdit}
          >
            <Button variant='contained' color='primary' onClick={openCreateModal}>Add New</Button>
          </PermissionHandler>
        </Box>
        <Paper
          style={{
            width: '100%',
          }}
        >
          <DataGrid
            rows={efforts?.items ?? []}
            columns={columns}
            pageSize={pageSize}
            page={pageNumber - 1}
            rowsPerPageOptions={[25, 50, 100]}
            rowCount={efforts?.totalCount}
            autoHeight={true}
            pagination={true}
            // paginationMode='server'
            onPageSizeChange={setPageSize}
            onPageChange={handleSetPageNumber}
            // sortingMode='server'
            sortModel={sortModel}
            onSortModelChange={handleSortModelChange}
            loading={isLoading}
            disableSelectionOnClick={true}
          />
          <EditMerchandisingEffortDialog
            isOpen={createModalIsOpen}
            closeModal={closeCreateModal}
            onSubmit={handleCreateFormSubmit}
            item={{}}
            isCreate={true}
          />
        </Paper>
      </Box>
    </ThemeProvider>
  );
};

export default MerchandisingEffortsTable;
