/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Orders Homefield API
 * OpenAPI spec version: v1
 */
import type { ItemPricingSetup } from './itemPricingSetup';
import type { ItemsQueriesPersonalization } from './itemsQueriesPersonalization';
import type { Logo } from './logo';

export interface QueriesItemDto {
  id?: number;
  uuid?: string | null;
  name?: string | null;
  pricing?: ItemPricingSetup;
  price?: number;
  oldPrice?: number | null;
  disabled?: boolean;
  custom?: boolean;
  essential?: boolean;
  salesChannelId?: number | null;
  personalizations?: ItemsQueriesPersonalization[] | null;
  logos?: Logo[] | null;
}
