/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Catalog Homefield API
 * OpenAPI spec version: v1
 */

export type PersonalizationColorTypeEnum = 'Fill' | 'FillAndOutline';


export const PersonalizationColorTypeEnum = {
  Fill: 'Fill' as PersonalizationColorTypeEnum,
  FillAndOutline: 'FillAndOutline' as PersonalizationColorTypeEnum,
};
