/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */
import type { UpdateOrderItemPriceCalculationDto } from './updateOrderItemPriceCalculationDto';

export interface UpdateOrderItemPriceCalculationDtoApiResponse {
  success?: boolean;
  message?: string | null;
  result?: UpdateOrderItemPriceCalculationDto;
}
