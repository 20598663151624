import React, { useCallback, useEffect, useState } from 'react';
import { decorationTypeEnum } from '@constants/enums/decorationEnums';
import { orderArtworkForm } from '@constants/reduxForms';
import { validateOrderArtworkChange } from '@redux/orderManagement/validations';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { createDecorationLocationOptions } from '@util/optionsMap';
import Select from '@sharedComponents/Form/Select';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import Icon from '@sharedComponents/Icons/Icon';
import Modal from '@sharedComponents/Modal/Modal';
import OrderManagementChangeArtworkLogoImage from './OrderManagementChangeArtworkLogoImage';
import { connect, ConnectedProps } from 'react-redux';
import { OrderArtworkFormData } from '@models/forms/OrderManagement/OrderArtworkFormData';
import { DecorationLocationDto, DecorationMethodEnum, ProductDetailDto } from '@api/productCatalog/models';
import { LockerLogoDto, LogoItemDetailsModel } from '@api/fulfillment/models';

interface OwnProps {
  isOpen: boolean;
  closeModal: () => void;
  handleSubmit: () => void;
  product: ProductDetailDto;
  decorationLocations: Array<DecorationLocationDto>;
  logo: LogoItemDetailsModel;
  lockerArtworks: Array<LockerLogoDto>;
  change: (formLogoProperty: string, logoId: number) => void;
}

const connector = connect(null);

type FormProps = OwnProps & ConnectedProps<typeof connector>;

type Props =
  & FormProps
  & InjectedFormProps<OrderArtworkFormData, FormProps, string[]>;

const OrderManagementChangeArtworkModal = React.memo<Props>(({
  isOpen,
  closeModal,
  handleSubmit,
  product,
  decorationLocations,
  logo,
  lockerArtworks,
  change,
}) => {
  const [
    selectedLogoId,
    setSelectedLogoId,
  ] = useState<Nullable<number>>(null);
  const [
    warningMessageVisible,
    setWarningMessageVisible,
  ] = useState<boolean>(false);
  const [
    decorationLocationOptions,
    setDecorationLocationOptions,
  ] = useState<Nullable<Array<any>>>(null);
  const [
    refreshWarningMessage,
    setRefreshWarningMessage,
  ] = useState<boolean>(false);

  const formLogoProperty = 'logoId';

  const selectLogo = useCallback((logoId: number) => {
    setSelectedLogoId(logoId);
    setRefreshWarningMessage(true);
    change(formLogoProperty, logoId);
  }, [change]);

  const refreshWarningMessageVisibility = useCallback(() => {
    const selectedArtwork = lockerArtworks?.find((a) => a.id === selectedLogoId);
    let warningMessageVisibility = false;

    if (
      selectedArtwork
      && product?.coloredStyle?.style?.decorationMethod
    ) {
      warningMessageVisibility =
        // TODO temporarily cast to any, fix FF model to send camel case after ENH-251 merges to dev and remove cast
        !(selectedArtwork as any)?.decorationMethods?.includes(product.coloredStyle.style.decorationMethod);
    }

    setWarningMessageVisible(warningMessageVisibility);
  }, [
    lockerArtworks,
    product?.coloredStyle?.style?.decorationMethod,
    selectedLogoId,
  ]);

  const getDecorationLocationOptions = useCallback(() => {
    if (
      !product?.coloredStyle?.style
      || !decorationLocations
    ) {
      return [];
    }

    const productDecorationLocationsIds = product?.coloredStyle?.style?.decorationLocations?.filter(
      (l) => l.type === decorationTypeEnum.Logo,
    ).map(
      (l) => l.locationId,
    );

    const itemDecorationLocations = decorationLocations.filter((l) => productDecorationLocationsIds?.includes(l.id));

    return createDecorationLocationOptions(itemDecorationLocations, 'code');
  }, [
    decorationLocations,
    product?.coloredStyle?.style,
  ]);

  useEffect(() => {
    if (!refreshWarningMessage) { return; }

    refreshWarningMessageVisibility();
    setRefreshWarningMessage(false);
  }, [
    refreshWarningMessage,
    refreshWarningMessageVisibility,
    decorationLocationOptions,
  ]);

  useEffect(() => {
    selectLogo(logo?.logoId ?? 0);
  }, [
    logo,
    selectLogo,
  ]);

  useEffect(() => {
    setDecorationLocationOptions(getDecorationLocationOptions());
    setRefreshWarningMessage(true);
  }, [
    decorationLocations,
    getDecorationLocationOptions,
    product,
  ]);

  const decorationMethod = product?.coloredStyle?.style?.decorationMethod ?? DecorationMethodEnum.Undefined;

  const renderArtwork = (artwork: LockerLogoDto, index: number) => (
    <OrderManagementChangeArtworkLogoImage
      key={index}
      artwork={artwork}
      selectLogo={selectLogo}
      isSelected={selectedLogoId === artwork.id}
      /*
       * TODO temporarily cast to any
       * fix FF model to send camel case after ENH-251 merges to dev and remove cast
       */
      decorationMethods={(artwork as any).decorationMethods!}
    />
  );

  return (
    <Modal
      isOpen={isOpen}
      closeModal={closeModal}
      modalSize={'l'}
      title={'Modify Logo'}
      buttons={(
        <ModalButtons
          confirmBtnText={'Update'}
          cancelBtnText={'Cancel'}
          onClose={closeModal}
          formSubmission={true}
          formId={orderArtworkForm}
        />
      )}
    >
      <div>
        <form
          className='redux-form'
          id={orderArtworkForm}
          onSubmit={handleSubmit}
        >
          <div>
            <div className='redux-form__section pb-5'>
              <div className='redux-form__row'>
                <div className='redux-form__column--size-m'>
                  <label className='redux-form__label'>
                    Logo Location
                    <span className='required'>*</span>
                  </label>
                  <Field
                    name={'decorationLocation'}
                    component={Select as any}
                  >
                    {decorationLocationOptions}
                  </Field>
                </div>
              </div>
              <div className='redux-form__row'>
                <div className='redux-form__column--size-xl'>
                  <div className='order__change-artwork--logos-container'>
                    {lockerArtworks?.map(renderArtwork)}
                  </div>
                </div>
              </div>
            </div>
            {warningMessageVisible
              && (
                <div className='warning-message mt-20'>
                  <Icon materialIcon={'warning'} />
                  <span className='warning-text'>
                    Selected logo is not approved for {decorationMethod} decoration method which is set for this SKU.
                  </span>
                </div>
              )}
          </div>
        </form>
      </div>
    </Modal>
  );
});

export default connector(
  reduxForm<FormProps, any, Props>({
    form: orderArtworkForm,
    validate: validateOrderArtworkChange,
    shouldError: () => true,
    enableReinitialize: true,
  })(OrderManagementChangeArtworkModal),
);
